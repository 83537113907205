import {Injectable} from '@angular/core';
import {UpdateUser, UpdateUserSettings} from '@app/actions/user.actions';
import {User} from '@app/core/models/user/user';
import {Action, State, StateContext} from '@ngxs/store';

@State<User>({
  name: 'user',
  defaults: null,
})
@Injectable()
export class UserState {
  @Action(UpdateUser)
  public change({setState}: StateContext<User>, {payload}: UpdateUser): void {
    setState(payload);
  }

  @Action(UpdateUserSettings)
  public updateSettings({patchState}: StateContext<User>, {payload}: UpdateUserSettings): void {
    patchState({
      settings: payload,
    });
  }
}
