<mat-form-field #searchInputWrapper class="wrapper-select-instrument">
  <input
    #searchInput
    [(ngModel)]="searchValue"
    [placeholder]="placeholder || 'Select instrument' | translate"
    matInput
    type="text"
    autocomplete="off"
  />

  <app-icon *ngIf="!searchValue" icon="search"></app-icon>
  <button
    *ngIf="searchValue"
    matSuffix
    type="button"
    class="button-cancel"
    (touchstart)="clearSearch()"
    (click)="clearSearch()"
  >
    <app-icon icon="cancel"></app-icon>
  </button>
</mat-form-field>

<ng-container *ngIf="(hasEmbedded && searchValue) || !hasEmbedded">
  <ng-container *ngIf="isVirtualScrollUsed">
    <!--  TODO(msuvorov): move to styles  -->
    <cdk-virtual-scroll-viewport [itemSize]="48" [style.height]="'100%'">
      <button
        *cdkVirtualFor="let i of instruments | filter: instrumentFilter; trackBy: trackByInstrumentId"
        [value]="i.symbolWithSeparator"
        type="button"
        class="instrument-button"
        (click)="selectInstrument(i)"
      >
        <ng-container *ngTemplateOutlet="buttonContentTemplate; context: {instrument: i}"></ng-container>
      </button>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-container *ngIf="!isVirtualScrollUsed">
    <button
      *ngFor="let i of instruments | filter: instrumentFilter; trackBy: trackByInstrumentId"
      [value]="i.symbolWithSeparator"
      type="button"
      class="instrument-button"
      (click)="selectInstrument(i)"
    >
      <ng-container *ngTemplateOutlet="buttonContentTemplate; context: {instrument: i}"></ng-container>
    </button>
  </ng-container>
</ng-container>

<div [class.hidden]="(hasEmbedded && searchValue) || !hasEmbedded" class="groups-wrapper">
  <ng-container #groupsContainer></ng-container>
</div>

<ng-template #buttonContentTemplate let-i="instrument">
  <span [innerHTML]="i.symbolWithSeparator | highlight: searchValue:isSearchSkipSeparator" class="instrument"></span>

  <div *ngIf="i.isNewInstrument" class="new-instrument-badge">
    <div class="new-instrument-badge-tooltip">
      {{ 'TradingBoard.Components.SelectInstrument.List.NewInstrument' | translate }}
    </div>
  </div>
  <span *ngIf="isTickInfoShow" [class.ask]="isAsk(i)" [class.bid]="isBid(i)" class="tick">
    {{ ticks.get(i.id)?.rolling24HrPxChange || '-&nbsp;' }}%
  </span>
</ng-template>
