import {ADataStoreModel} from '@app/trading-board/models/data-store-model';

import {ESide} from '../enum/side';
import {DecimalHelper} from './decimal-helper';

export abstract class AOrder<T = unknown> extends ADataStoreModel<T> {
  public static isBuy(side: string): boolean {
    return side?.toLowerCase() === ESide.Buy.toLowerCase();
  }

  public orderId: number;
  public symbolWithSeparator: string;
  public side: string;
  public time: Date;
  public type: string;
  public quantity: DecimalHelper;
  public price: DecimalHelper;
  public status: string;
  public filledSize: DecimalHelper;

  public isBuy(): boolean {
    return AOrder.isBuy(this.side);
  }

  public isSell(): boolean {
    return !this.isBuy();
  }
}
