import {TradingSessionTO} from '@b2broker/b2margin-trade-models';
import {Expose, plainToInstance, Transform, Type} from 'class-transformer';

export class TradingSession {
  public sessionType: TradingSessionTO.SessionTypeEnum;

  @Type(() => Date)
  public from: Date;

  @Type(() => Date)
  public to: Date;

  public isRegular(): boolean {
    return this.sessionType === TradingSessionTO.SessionTypeEnum.REGULAR;
  }
}

export class InstrumentSession {
  @Expose()
  @Transform(({value}) => value.map(plainToInstance.bind(null, TradingSession)))
  public tradingSessions: Array<TradingSession[]>;

  @Expose()
  @Type(() => TradingSession)
  public nextTradingSession: TradingSession;

  @Expose()
  public symbol: string;
}
