import {Directive, Input, OnInit} from '@angular/core';

import {IExceptions} from '../core/contracts/i.exceptions';
import {BatchItem} from './batch.item';
import {IBatchExtendService} from './contracts/i.batch.extend.service';

@Directive()
export abstract class AWorkflow implements OnInit {
  /**
   * Мапинг шагов компонентов.
   */
  public extendWizard: {[key: string]: BatchItem};

  /**
   * Мапинг для Wizard от АПИ в шаги.
   */
  public mapWizard: {[key: string]: string};

  /**
   * Oбъект Workflow.
   */
  public initWorkflow: AWorkflow;

  public uuid?: string;

  @Input()
  public isOutput = false;

  protected batchExtendService: IBatchExtendService;

  protected constructor(batchExtendService: IBatchExtendService) {
    this.batchExtendService = batchExtendService;
  }

  public ngOnInit(): void {
    this.extendWizard = this.batchExtendService.extend();
    this.mapWizard = this.batchExtendService.mapping();
    this.initWorkflow = this;
  }

  /**
   * Иницилизация Workflow.
   *
   * @param params
   * @returns {Promise<IExceptions>}
   */
  public abstract init(params?): Promise<IExceptions>;

  /**
   * Обработчик общий.
   *
   * @param {IExceptions} result
   */
  public abstract handle(result: IExceptions): void;
}
