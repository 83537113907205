import {SECOND_IN_MS} from '@app/core/constants/common';

import {IB2TraderApiData} from '../interfaces/b2trader-api-data';
import {IB2TraderTokenResponseDto} from '../interfaces/b2trader-token-response.dto';

export function mapTokenResponse(res: IB2TraderTokenResponseDto): IB2TraderApiData {
  const apiUrl = res.host?.endsWith('/') ? res.host.slice(0, -1) : res.host;
  return {
    url: apiUrl,
    expirationTimestamp: Number(res.ttl) * SECOND_IN_MS + new Date().getTime(),
    headers: {
      [res.header]: res.token,
    },
    refreshToken: res.refreshToken,
  };
}
