import {Model, Casts, CastTypes} from '@app/core/model';

export class InvestmentChartPoint extends Model {
  public point: number;
  public timestamp: number;

  public readonly casts: Casts = {
    point: [CastTypes.Number],
    timestamp: [CastTypes.Number],
  };
}
