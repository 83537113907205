export class B2marginChartRequest {
  private static readonly SECONDS_IN_DAY = 24 * 60 * 60;

  private static mapResolution(resolution: string): number {
    switch (resolution) {
      case '1':
      case '5':
      case '15':
      case '30':
      case '60':
      case '120':
      case '240':
      case '720':
        return Number(resolution) * 60;
      case '1D':
        return B2marginChartRequest.SECONDS_IN_DAY;
      case '1W':
        return 7 * B2marginChartRequest.SECONDS_IN_DAY;
      case '1M':
        return 30 * B2marginChartRequest.SECONDS_IN_DAY;
      default:
        throw Error('Unexpected resolution');
    }
  }

  public aggregationPeriodSeconds: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public extendedSession = true;
  public forexPriceField = 'bid';
  public maxBarsCount = 35000;
  public range = 86400000;
  public studySubscription = [];

  constructor(public id: number, public subtopic: string, public symbol: string, resolution: string) {
    this.aggregationPeriodSeconds = B2marginChartRequest.mapResolution(resolution);
  }
}
