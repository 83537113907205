import {Injectable} from '@angular/core';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';

import {Level2} from '../../models/level2';
import {AEventHandler} from '../event-handler.abstract';
import {EMoexEventHandlersReceived} from './moex-event-handlers.enum';

@Injectable()
export class MoexLevel2Service extends AEventHandler<Level2> {
  public readonly type = EMoexEventHandlersReceived.Level2;

  constructor(private readonly moexDatafeedService: MoexDatafeedService) {
    super();
  }

  public handleMessage(resource: Level2): void {
    this.moexDatafeedService.orderBookStreamMap.get(resource.symbolWithSeparator)?.stream.next(resource);
  }
}
