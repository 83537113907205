/* eslint-disable @typescript-eslint/naming-convention */
import {Decimal} from '@app/core/decimal';
import {Model} from '@app/core/model';
import {Currency} from '@app/core/models/account/currency';

export class Limit extends Model {
  public auto_withdraw: Decimal;
  public currency: Currency;
  public daily_deposit: Decimal;
  public daily_withdraw: Decimal;
  public monthly_withdraw: Decimal;

  public casts = {
    auto_withdraw: [Decimal],
    currency: [Currency],
    daily_deposit: [Decimal],
    daily_withdraw: [Decimal],
    monthly_withdraw: [Decimal],
  };
}
