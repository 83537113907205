import {MarketFee} from '@app/trading-board/models/b2trader/market-fee';
import {plainToInstance, Transform, Type} from 'class-transformer';
import * as _ from 'lodash-es';

export class TradeFees {
  @Type(() => Date)
  public timestamp: Date;

  @Transform(({value}) => _.mapValues(value, tier => plainToInstance(MarketFee, tier)))
  public marketsFee: Record<string, MarketFee>;
}
