import {EHistoricalOrderStatus} from '@app/trading-board/enum/moex/historical-order-status.enum';
import {EOrderSide} from '@app/trading-board/enum/moex/order-side';
import {EOrderStatus} from '@app/trading-board/enum/moex/order-status';
import {EOrderType} from '@app/trading-board/enum/moex/order-type';
import {Expose, Transform} from 'class-transformer';

import {LazyDecimalHelper} from '../lazy-decimal/lazy-decimal-helper';

export class HistoricalOrder {
  @Expose()
  public id: number;

  @Expose({name: 'client_id'})
  public clientId: number;

  @Expose({name: 'order_datetime'})
  @Transform(({value}) => new Date(value))
  public orderDatetime: Date;

  @Expose({name: 'order_num'})
  public orderNum: number;

  @Expose({name: 'order_status'})
  public historicalOrderStatus: EHistoricalOrderStatus;

  @Expose({name: 'asset_isin'})
  public assetIsin: string;

  @Expose({name: 'security_code'})
  public securityCode: string;

  @Expose({name: 'asset_name'})
  public assetName: string;

  @Expose({name: 'sub_acc_an_code'})
  public subAccountAnCode: string;

  @Expose({name: 'sub_acc_an_name'})
  public subAccountAnName: string;

  @Expose({name: 'sub_acc_code'})
  public subAccountCode: string;

  @Expose({name: 'sub_acc_name'})
  public subAccountName: string;

  @Expose({name: 'buy_sell'})
  public side: EOrderSide;

  @Expose()
  public quantity: number;

  @Expose()
  @Transform(({value}) => LazyDecimalHelper.from(value))
  public volume: LazyDecimalHelper;

  @Expose()
  public currency: string;

  @Expose({name: 'order_type'})
  public orderType: EOrderType;

  @Expose({name: 'as_price'})
  @Transform(({value}) => LazyDecimalHelper.from(value))
  public asPrice: LazyDecimalHelper;

  @Expose({name: 'lot_size'})
  public lotSize: number;

  @Expose({name: 'lots_quantity'})
  public lotsQuantity: number;

  @Expose()
  public exchange: string;

  public get status(): EOrderStatus {
    switch (this.historicalOrderStatus) {
      case EHistoricalOrderStatus.Active:
      case EHistoricalOrderStatus.Exposed:
      case EHistoricalOrderStatus.Generated:
        return EOrderStatus.Working;

      case EHistoricalOrderStatus.Executed:
        return EOrderStatus.Filled;

      case EHistoricalOrderStatus.Removed:
      case EHistoricalOrderStatus.Deniled:
        return EOrderStatus.Rejected;
      case EHistoricalOrderStatus.RemovedByUser:
        return EOrderStatus.Canceled;

      default:
        throw new Error(`Unknown historical order status: ${this.historicalOrderStatus}`);
    }
  }
}
