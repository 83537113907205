import {IOrderBookInfoDto} from '@app/trading-board/interfaces/b2trader/order-book-info-dto.interface';
import {IOrderBookLevelDto} from '@app/trading-board/interfaces/b2trader/order-book-level-dto.interface';
import {ADataStoreModel} from '@app/trading-board/models/data-store-model';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {OrderBookSideRowTO} from '@b2broker/b2margin-trade-models';
import {Expose, plainToInstance, Transform, Type} from 'class-transformer';
import * as _ from 'lodash-es';

import {ESide} from '../enum/side';
import {IB2TraderInstrument} from '../interfaces/b2trader/instrument';
import {IInstrument} from '../interfaces/instrument';
import {ILevel2} from '../interfaces/level2';
import {IOrder} from '../interfaces/order';
import {DecimalHelper} from './decimal-helper';

export class Order extends ADataStoreModel<IOrder> {
  public static adaptFromB2Margin(orders: OrderBookSideRowTO[], side: ESide, instrument?: IInstrument): IOrder[] {
    return orders.map(order => ({
      price: order.price.toString(),
      quantity: order.size.toString(),
      side,
      priceScale: instrument?.priceScale,
      amountScale: instrument?.amountScale,
      quantityScale: instrument?.quantityScale,
      symbolWithSeparator: instrument?.symbolWithSeparator,
    }));
  }

  public static adaptFromB2Trader(
    orders: IOrderBookLevelDto[],
    side: ESide,
    instrument?: IB2TraderInstrument,
  ): IOrder[] {
    return orders.map(
      (order: IOrderBookLevelDto): IOrder => ({
        price: order.price.toString(),
        quantity: order.amount.toString(),
        total: order.total.toString(),
        side,
        priceScale: instrument?.priceScale,
        amountScale: instrument?.amountScale,
        symbolWithSeparator: instrument?.symbolWithSeparator,
      }),
    );
  }

  public get fromBestPricePercent(): DecimalHelper {
    if (_.isNil(this._fromBestPricePercent)) {
      this._fromBestPricePercent = this.price.multiply(this.quantity).divide(this.maxValuePercent).setScale(2);
    }
    return this._fromBestPricePercent;
  }

  private _fromBestPricePercent: DecimalHelper;
  public maxValuePercent: DecimalHelper;

  @Transform(({value, obj}: {value: string; obj: IOrder}) => DecimalHelper.from(value, obj.priceScale))
  public price: DecimalHelper;

  @Transform(({value, obj}: {value: string; obj: IOrder}) => DecimalHelper.from(value, obj.quantityScale))
  public quantity: DecimalHelper;

  @Transform(({value, obj}: {value: string; obj: IOrder}) => DecimalHelper.from(value ?? 0, obj.quantityScale))
  public yield: DecimalHelper;

  public side: ESide;

  public symbolWithSeparator: string;

  @Expose()
  @Transform(({obj}: {obj: IOrder}) =>
    (_.isNil(obj.total)
      ? DecimalHelper.from(obj.quantity).multiply(obj.price)
      : DecimalHelper.from(obj.total)
    ).setScale(obj.priceScale > obj.quantityScale ? obj.priceScale : obj.quantityScale),
  )
  public total: DecimalHelper;

  public volume: DecimalHelper;

  public update(obj: IOrder): void {
    this._fromBestPricePercent = null;
    this.maxValuePercent = null;

    this.price = DecimalHelper.from(obj.price, obj.priceScale);
    this.quantity = DecimalHelper.from(obj.quantity, obj.quantityScale);
    this.side = obj.side as ESide;
    this.symbolWithSeparator = obj.symbolWithSeparator;
  }
}

export class Level2 extends ADataStoreModel<ILevel2> {
  private static getMaxPrice(orders: Order[]): DecimalHelper {
    if (!orders.length) {
      return DecimalHelper.from(0);
    }
    const prices = orders.map(o => o.price);
    return DecimalHelper.max(...prices);
  }

  private static getMaxVolume(orders: Order[]): DecimalHelper {
    if (!orders.length) {
      return DecimalHelper.from(0);
    }
    const volumes = orders.map(o => o.price.multiply(o.quantity));
    return DecimalHelper.max(...volumes);
  }

  private static getMinPrice(orders: Order[]): DecimalHelper {
    return DecimalHelper.min(...orders.map(({price}) => price));
  }

  private static getMinVolume(orders: Order[]): DecimalHelper {
    if (!orders.length) {
      return DecimalHelper.from(0);
    }
    const volumes = orders.map(o => o.price.multiply(o.quantity));
    return DecimalHelper.min(...volumes);
  }

  public static fromB2MarginMap(
    items: B2MarginModels.OrderBookTO[],
    instruments: Map<string, IInstrument>,
  ): Map<string, ILevel2> {
    return items.reduce((acc, {bids, asks, orderBookSymbol}: B2MarginModels.OrderBookTO) => {
      const instrument = instruments.get(orderBookSymbol.symbol);

      acc.set(orderBookSymbol.symbol, {
        asks: Order.adaptFromB2Margin(asks, ESide.Sell, instrument),
        bids: Order.adaptFromB2Margin(bids, ESide.Buy, instrument),
        symbolWithSeparator: orderBookSymbol.symbol,
      });

      return acc;
    }, new Map<string, ILevel2>());
  }

  public static fromB2Trader(book: Partial<IOrderBookInfoDto>, instrument?: IB2TraderInstrument): ILevel2 {
    return {
      bids: Order.adaptFromB2Trader(book.bids, ESide.Buy, instrument),
      asks: Order.adaptFromB2Trader(book.asks, ESide.Sell, instrument),
      symbolWithSeparator: instrument?.symbolWithSeparator,
    };
  }

  public get minBid(): DecimalHelper {
    if (_.isNil(this._minBid)) {
      this._minBid = Level2.getMinPrice(this.bidsClear);
    }

    return this._minBid;
  }

  public get maxAsk(): DecimalHelper {
    if (_.isNil(this._maxAsk)) {
      this._maxAsk = Level2.getMaxPrice(this.asksClear);
    }

    return this._maxAsk;
  }

  public get maxVolumeBid(): DecimalHelper {
    if (_.isNil(this._maxVolumeBid)) {
      this._maxVolumeBid = Level2.getMaxVolume(this.bidsClear);
    }

    return this._maxVolumeBid;
  }
  public get maxVolumeAsk(): DecimalHelper {
    if (_.isNil(this._maxVolumeAsk)) {
      this._maxVolumeAsk = Level2.getMaxVolume(this.asksClear);
    }

    return this._maxVolumeAsk;
  }
  public get bidsClear(): Order[] {
    if (_.isNil(this._bidsClear)) {
      this._bidsClear = this.bids.filter(o => o.quantity.greaterThanZero());
    }

    return this._bidsClear;
  }

  public get asksClear(): Order[] {
    if (_.isNil(this._asksClear)) {
      this._asksClear = this.asks.filter(o => o.quantity.greaterThanZero());
    }

    return this._asksClear;
  }
  public get spread(): DecimalHelper {
    if (_.isNil(this._spread)) {
      const [bestAsk] = this.asksClear.reverse();
      const [bestBid] = this.bidsClear;

      this._spread =
        bestAsk?.price && bestBid?.price ? bestAsk.price.minus(bestBid.price).abs() : DecimalHelper.from(0);
    }

    return this._spread;
  }

  public get maxAskValuePercent(): DecimalHelper {
    if (_.isNil(this._maxAskValuePercent)) {
      this._maxAskValuePercent = this.maxVolumeAsk.divide(100);
    }

    return this._maxAskValuePercent;
  }

  public get maxBidValuePercent(): DecimalHelper {
    if (_.isNil(this._maxBidValuePercent)) {
      this._maxBidValuePercent = this.maxVolumeBid.divide(100);
    }

    return this._maxBidValuePercent;
  }

  public get maxBid(): DecimalHelper {
    if (_.isNil(this._maxBid)) {
      this._maxBid = Level2.getMaxPrice(this.bidsClear);
    }

    return this._maxBid;
  }

  public get minAsk(): DecimalHelper {
    if (_.isNil(this._minAsk)) {
      this._minAsk = Level2.getMinPrice(this.asks);
    }

    return this._minAsk;
  }

  public static afterTransformItem(level2: Level2): Level2 {
    level2.asks.map(order => {
      order.maxValuePercent = level2.maxAskValuePercent;
      return order;
    });

    level2.bids.map(order => {
      order.maxValuePercent = level2.maxBidValuePercent;
      return order;
    });

    return level2;
  }

  public static afterTransform(levels2: Level2[]): Level2[] {
    return levels2.map(level2 => Level2.afterTransformItem(level2));
  }

  public static sortOrders(value: Order[]): Order[] {
    return value.sort((a, b) => (b.price.minus(a.price).greaterThanZero() ? 1 : -1));
  }

  private _minAsk: DecimalHelper;

  private _minBid: DecimalHelper;

  private _maxAsk: DecimalHelper;
  private _maxBid: DecimalHelper;

  private _asksClear: Order[];
  private _bidsClear: Order[];

  private _maxVolumeAsk: DecimalHelper;
  private _maxVolumeBid: DecimalHelper;

  private _spread: DecimalHelper;

  private _maxAskValuePercent: DecimalHelper;
  private _maxBidValuePercent: DecimalHelper;

  @Type(() => Order)
  @Transform(({value}) => Level2.sortOrders(value))
  public bids: Order[];

  @Type(() => Order)
  @Transform(({value}) => Level2.sortOrders(value))
  public asks: Order[];

  public symbolWithSeparator: string;

  public getAccumulatedAsks(): Order[] {
    let currentAskPosition = 0;
    return this.asks.reduceRight((acc, order) => {
      const prev: DecimalHelper = acc[currentAskPosition - 1]?.volume ?? DecimalHelper.from(0);

      order.volume = prev.plus(order.quantity);

      acc.push(order);
      currentAskPosition++;

      return acc;
    }, []);
  }

  public getAccumulatedBids(): Order[] {
    return this.bids.reduce((acc, order, index) => {
      const prev: DecimalHelper = acc[index - 1]?.volume ?? DecimalHelper.from(0);

      order.volume = prev.plus(order.quantity);

      acc.push(order);

      return acc;
    }, []);
  }

  public update(level2: ILevel2): void {
    if (this.asks.length > level2.asks.length) {
      this.asks = this.asks.slice(0, level2.asks.length);
    }

    if (this.bids.length > level2.bids.length) {
      this.bids = this.bids.slice(0, level2.bids.length);
    }

    this.asks.forEach((value: Order, index: number) => value.update(level2.asks[index]));
    this.bids.forEach((value: Order, index: number) => value.update(level2.bids[index]));

    if (level2.asks.length > this.asks.length) {
      const newAsks = plainToInstance(Order, level2.asks);

      this.asks.push(...newAsks);
    }

    if (level2.bids.length > this.bids.length) {
      const newBids = plainToInstance(Order, level2.bids);

      this.bids.push(...newBids);
    }

    this.asks = Level2.sortOrders(this.asks);
    this.bids = Level2.sortOrders(this.bids);

    this.symbolWithSeparator = level2.symbolWithSeparator;

    this._minBid = null;
    this._maxAsk = null;
    this._bidsClear = null;
    this._asksClear = null;
    this._maxVolumeBid = null;
    this._maxVolumeAsk = null;
    this._spread = null;
    this._maxAskValuePercent = null;
    this._maxBidValuePercent = null;
    this._maxBid = null;
    this._minAsk = null;
  }
}
