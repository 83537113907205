import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction,
} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormField} from '@app/core/form/entities/form.field';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class GroupListComponent implements OnInit {
  @Input()
  public addTranslateKey = '';

  @Input()
  public deleteTranslateKey = '';

  @Input()
  public formArray: UntypedFormArray;

  @Input()
  public fields: Record<string, FormField>;

  @Input()
  public isFirstItemHidden = false;

  @Input()
  public template: TemplateRef<ElementRef> | undefined;

  @Input()
  public data = [];

  @Input()
  public isShowDeleteButton = true;

  @Input()
  public isAddButtonVisible = true;

  @Output()
  public readonly hasNewFormGroupAdded = new EventEmitter<UntypedFormGroup>();

  public fieldsList: Record<string, FormField>[] = [];
  public controlsToMap: {[p: string]: AbstractControl} | undefined;

  public readonly trackByControl: TrackByFunction<AbstractControl> = (index, control) => control;

  public ngOnInit(): void {
    this.fieldsList.push(this.fields);
    this.controlsToMap = (this.formArray.controls[0] as UntypedFormGroup)?.controls;

    if (this.isFirstItemHidden && !this.data?.length) {
      this.formArray.clear();
      this.fieldsList.splice(0);
    }

    if (this.data) {
      this.fillFormArrayWithData(this.data);
    }
  }

  public addNewFormGroup(): void {
    if (!this.controlsToMap) {
      return;
    }

    const fields = {...this.fields};
    const group = new UntypedFormGroup({});

    Object.keys(this.controlsToMap).forEach(key => {
      group.addControl(key, new UntypedFormControl(''));
    });

    this.formArray.push(group);
    this.fieldsList.push(fields);
    this.hasNewFormGroupAdded.next(group);
  }

  public clearFormGroup(): void {
    this.formArray.controls.forEach((_, index) => this.deleteFormGroupByIndex(index));
  }

  public deleteFormGroupByIndex(index: number): void {
    this.formArray.removeAt(index);
    this.fieldsList.splice(index, 1);
  }

  private fillFormArrayWithData(data: unknown[]): void {
    if (data.length > 1) {
      for (let i = 1; i < data.length; i++) {
        this.addNewFormGroup();
      }
    }

    this.formArray.patchValue(data);
  }
}
