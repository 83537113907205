import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {OrderStatus} from '@app/trading-board/models/b2margin/order-status';
import {OrderTO} from '@b2broker/b2margin-trade-models/dist/models/orderTO';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class B2marginOrderStatusService extends AEventHandler<OrderStatus> {
  public type = EEventHandlersReceived.OrderStatus;

  constructor(private readonly snackbar: SnackbarService, private readonly translate: TranslateService) {
    super();
  }

  public handleMessage(resource: OrderStatus): void {
    switch (resource.status) {
      case 'FILLED':
        this.snackbar.success(this.translate.get('Order successfully filled'));
        break;
      case OrderTO.StatusEnum.REJECTED:
        this.snackbar.error(this.translate.get('Order rejected'));
        break;
      case OrderTO.StatusEnum.PLACED:
        this.snackbar.success(this.translate.get('Order successfully placed'));
        break;
      default:
        break;
    }
  }
}
