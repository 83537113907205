import {AccountsTO} from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class AccountsInternalEventHandler extends AEventHandler<IB2marginMessage<AccountsTO>> {
  public type = [EB2MarginEventType.AccountsUpdate, EB2MarginEventType.Accounts];

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<AccountsTO>): void {
    this.store.accounts$.next(resource.body);
  }
}

b2MarginInternalEventHandlers.push(AccountsInternalEventHandler);
