import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ConnectionService} from '@app/core/connection.service';
import {TFileConfig} from '@app/core/form/components/file/types/file-config';
import {FormField, FormFieldRule, FormFieldRuleValue} from '@app/core/form/entities/form.field';
import {FormWrapper} from '@app/core/form/entities/form.wrapper';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {Observable, Subject, firstValueFrom, filter, takeUntil, tap} from 'rxjs';

import {CustomValidators} from '../../entities/custom.validators';

@Component({
  selector: 'app-form-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent implements OnInit, OnDestroy {
  @Input()
  public group: UntypedFormGroup;

  @Input()
  public field: FormField;

  @Input()
  public filesToMap: TFileConfig[] = [];

  @Input()
  public isHideBackgroundImageAfterFileAdding = false;

  public errors: Record<string, ValidationErrors>;

  public files: File[] = [];

  public readonly customControl = new FormControl<File[] | null>(null);

  protected readonly destroyer$ = new Subject<void>();

  protected validator: ValidatorFn | ValidatorFn[];

  public get label(): Observable<string> {
    return this.labelsService.getLabel(this.field.label.value);
  }

  public isRequired = false;
  public rules: FormFieldRule[] | undefined;
  public acceptTypes: string;

  constructor(
    protected readonly labelsService: LabelService,
    protected readonly errorsService: ErrorsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly connectionService: ConnectionService,
  ) {}

  public ngOnInit(): void {
    this.setValidators();
    this.mapFilesToFormGroup().pipe(takeUntil(this.destroyer$)).subscribe();

    this.group.statusChanges.pipe(takeUntil(this.destroyer$)).subscribe(() => {
      this.errors = Object.keys(this.group.controls).reduce((r: Record<string, ValidationErrors>, key: string) => {
        const control = this.group.get(key);
        if (control.errors) {
          r[key] = control.errors;
        }
        return r;
      }, {});

      this.cdr.detectChanges();
    });
  }

  protected mapFilesToFormGroup(): Observable<unknown> {
    if (this.filesToMap) {
      void Promise.all(
        this.filesToMap.map(async item => {
          return await this.getFileByConfig(item);
        }),
      ).then(files => {
        const definedFiles = files.filter(Boolean);

        this.customControl.patchValue(definedFiles);

        if (definedFiles.length === 0) {
          this.customControl.markAsUntouched();

          this.cdr.detectChanges();
        }
      });
    }

    return this.customControl.valueChanges.pipe(
      filter(v => !!v),
      takeUntil(this.destroyer$),
      tap((files: File[]) => {
        this.addFilesToFormGroup(files, this.group);

        this.group.updateValueAndValidity();
        this.cdr.detectChanges();
      }),
    );
  }

  public setValidators(): void {
    const fields = this.field?.fields;

    this.rules = fields && Object.keys(fields[0])?.length ? fields[0].rules : this.field.rules;
    this.validator = FormWrapper.reduceValidators(this.rules);
    this.isRequired = this.rules?.some(rule => rule[0] === 'required');

    if (this.isRequired) {
      this.group.addValidators(CustomValidators.empty());
    }

    const mimetypesRule = this.rules?.find(i => i[0] === 'mimetypes');

    if (mimetypesRule) {
      this.acceptTypes = mimetypesRule[1].join(',');
    }

    this.cdr.detectChanges();
  }

  protected addFilesToFormGroup(files: File[], formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach(key => formGroup.removeControl(key));

    files.forEach((file, i) => {
      const control = new UntypedFormControl(file, this.validator);
      formGroup.addControl(String(i), control);
      control.markAsTouched();
      formGroup.updateValueAndValidity();
    });
  }

  protected async getFileByConfig(fileConfig: TFileConfig): Promise<File | undefined> {
    if (!fileConfig.id) {
      return undefined;
    }

    const result = await firstValueFrom(
      this.connectionService.getBlob(`/api/v1/documents/${fileConfig.id}`).pipe(takeUntil(this.destroyer$)),
    );

    return new File([result.blob], fileConfig.name);
  }

  public getErrorGroup(key: string, value?: FormFieldRuleValue): Observable<string> | undefined {
    if (!this.group.touched) {
      return;
    }

    return this.errorsService.getError(key, value ?? this.group.errors[key]);
  }

  public getErrorControl(key: string, value?: FormFieldRuleValue): Observable<string> | undefined {
    if (!this.group.touched) {
      return;
    }

    return this.errorsService.getError(key, value ?? this.customControl.errors[key]);
  }

  public getError(key: string, value?: FormFieldRuleValue): Observable<string> {
    return this.getErrorGroup(key, value);
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
