import {OrderTO, ProtectionOrderTO} from '@b2broker/b2margin-trade-models';

import {IOrderModelParam} from '../../interfaces/b2margin/order-model-param';
import {B2MarginInstrument} from '../instrument';
import {Tick} from '../level1';
import {AOrder} from '../order';

export abstract class AB2marginOrder<T> extends AOrder<T> {
  /* eslint-disable @typescript-eslint/naming-convention */
  public static mapProtectionOrderTo({
    orderType,
    fixedOffset,
    fixedPrice,
    removed,
  }: Partial<ProtectionOrderTO>): ProtectionOrderTO {
    const order: ProtectionOrderTO = {
      fixedOffset: Number(fixedOffset),
      fixedPrice: Number(fixedPrice),
      orderType,
      priceFixed: true,
      removed: !!removed,
    };

    return order;
  }
  /* eslint-enable @typescript-eslint/naming-convention */

  public static findInstrumentId(raw: OrderTO): string {
    return raw.legs[0]?.instrumentId.toString();
  }

  public static makeOrderParam<T extends OrderTO>(
    resource: T[],
    instruments: Map<string, B2MarginInstrument>,
    level1: Map<string, Tick>,
  ): IOrderModelParam<T>[] {
    return resource.map(order => {
      const instrument = instruments.get(AB2marginOrder.findInstrumentId(order));
      const tick = level1.get(instrument?.id);

      return {id: order.orderId, raw: order, instrument, tick};
    });
  }

  public static makeOrderParamMap<T extends OrderTO>(
    resource: T[],
    instruments: Map<string, B2MarginInstrument>,
    level1: Map<string, Tick>,
  ): Map<number, IOrderModelParam<T>> {
    return resource.reduce((acc: Map<number, IOrderModelParam<T>>, order: T) => {
      const instrument = instruments.get(AB2marginOrder.findInstrumentId(order));
      const tick = level1.get(instrument?.id);

      acc.set(order.orderId, {id: order.orderId, raw: order, instrument, tick});

      return acc;
    }, new Map<number, IOrderModelParam<T>>());
  }
}
