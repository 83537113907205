import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccessService} from '@app/access/access.service';
import {ActivityTrackerService, GridsterActivityHandlerService} from '@app/activity-tracker';
import {AppRoutingModule} from '@app/app-routing.module';
import {AppComponent} from '@app/app.component';
import {ngxsConfig} from '@app/core/configs/ngxs.config';
import {CustomerScriptsProvider} from '@app/core/customer-scripts/customer-scripts.provider';
import {ErrorsModule} from '@app/core/modules/errors/errors.module';
import {
  SettingsStorageProvider,
  SettingsStorageService,
} from '@app/core/modules/settings-storage/settings-storage.service';
import {ErrorHandlerProvider} from '@app/core/services/errors-handler.service';
import {ExceptionService} from '@app/core/services/exception.service';
import {SidenavService} from '@app/core/services/sidenav.service';
import {TitleService} from '@app/core/services/title.service';
import {UserService} from '@app/core/services/user.service';
import {WebSocketService} from '@app/core/services/web-socket.service';
import {TranslationLoader} from '@app/core/translation.loader';
import {initLanguageProvider} from '@app/language/helpers/init-language';
import {LanguageModule} from '@app/language/language.module';
import {LanguageState} from '@app/language/language.state';
import {ErrorLayoutComponent} from '@app/layouts/error-layout/error-layout.component';
import {LogoModule} from '@app/layouts/logo/logo.module';
import {DirectionModule} from '@app/shared/modules/direction/direction.module';
import {SnackbarModule} from '@app/snackbar/snackbar.module';
import {ResetPluginModule} from '@app/state/plugins/logout/reset.plugin.module';
import {UserState} from '@app/state/user.state';
import {OfflineModule} from '@app/ui/offline/offline.module';
import {EllipsisContainerModule} from '@b2broker/ellipsis-container';
import {Environment} from '@env/environment.entities';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxsModule} from '@ngxs/store';
import {NgxMaskModule} from 'ngx-mask';

import {LogoutProvider} from './core/logout-interceptor.service';
import {SYNC_AUTH_STATE_FROM_LS_PROVIDER} from './core/providers/sync-auth-state-from-ls.provider';
import {SYNC_DESIGN_VERSION_PROVIDER} from './core/providers/sync-design-version.provider';
import {InternalLayoutComponent} from './internal-layout/internal-layout.component';
import {SigningFormModule} from './ui/signing-form/signing-form.module';

const AppTranslateModule = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: TranslationLoader.getFactory(),
    deps: [Environment],
  },
});

@NgModule({
  declarations: [AppComponent, ErrorLayoutComponent, InternalLayoutComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-Xsrf-Cookie',
      headerName: 'My-Xsrf-Header',
    }),
    AppTranslateModule,
    OfflineModule,
    SnackbarModule,
    ErrorsModule,
    NgxsModule.forRoot([UserState, LanguageState], ngxsConfig),
    ResetPluginModule.forRoot(),
    LanguageModule,
    LogoModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    DirectionModule,
    SigningFormModule,
    EllipsisContainerModule,
  ],
  providers: [
    CustomerScriptsProvider,
    UserService,
    TitleService,
    ErrorHandlerProvider,
    SidenavService,
    ExceptionService,
    AccessService,
    WebSocketService,
    LogoutProvider,
    initLanguageProvider,
    ActivityTrackerService,
    GridsterActivityHandlerService,
    SettingsStorageProvider,
    SettingsStorageService,
    SYNC_DESIGN_VERSION_PROVIDER,
    SYNC_AUTH_STATE_FROM_LS_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
