/* eslint-disable @typescript-eslint/naming-convention */
import {Casts, CastTypes, Model} from '@app/core/model';
import {Attachment} from '@app/helpdesk/models/attachment';

export class Message extends Model {
  public attachments?: Attachment[];
  public created_at: number;
  public updated_at: number;
  public excerpt: string;
  public id: number;
  public ticket_id: number;
  public text: string;
  public purified_text: string;
  public by: 0 | 1;

  public readonly casts: Casts = {
    attachments: [CastTypes.Null, [Attachment]],
    created_at: [CastTypes.Number],
    updated_at: [CastTypes.Null, CastTypes.Number],
    excerpt: [CastTypes.String],
    id: [CastTypes.Number],
    ticket_id: [CastTypes.Number],
    text: [CastTypes.String],
    purified_text: [CastTypes.String],
    by: [CastTypes.Number],
  };
}
