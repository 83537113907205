import {RudderAnalytics} from '@rudderstack/analytics-js/*';

import {EEventName} from '../../shared/enums/event-name.enum';
import {AEventLogger} from '../../shared/event-logger/event-logger.abstract';
import {TEventProperties} from '../../shared/types/event-properties.type';

export class RudderStackEventLogger extends AEventLogger {
  constructor(private readonly rudderAnalytics: RudderAnalytics) {
    super();
  }

  public logEvent(eventName: EEventName, properties?: TEventProperties): void {
    this.rudderAnalytics.track(eventName, properties);
  }
}
