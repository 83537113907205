import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {FormFieldOption, OptionsType} from '@app/core/form/entities/form.field';
import {snakeCaseToCamelCase} from '@app/core/utils/case-change';
import {getCountryNameByIso2} from '@app/core/utils/get-name-by-country-code.function';
import {Environment} from '@env/environment.entities';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class OptionsService {
  public optionsTypeDictionary: Record<string, (value) => FormFieldOption> = {
    countries: country => ({
      name: getCountryNameByIso2(country.iso_3166_2) ?? country.name,
      value: `${country.country_code}`,
    }),
    currencies: currency => ({name: `${currency.alpha} - ${currency.name}`, value: `${currency.code}`}),
    isoCodeCountries: country => ({name: country.name, value: country.iso_3166_2}),
    dictionaryCountries: country => ({name: country.name, value: country.code_iso_numeric}),
    dictionaryRegions: region => ({name: region.name, value: region.id}),
    dictionaryCities: city => ({name: city.name, value: city.id}),
    dictionaryCurrencies: currency => ({
      name: `${currency.short_name} - ${currency.name}`,
      value: currency.id,
    }),
    dictionaryLegalForms: form => ({name: form.name, value: form.id}),
    platforms: platform => ({name: platform.caption, value: platform.id}),
    default: option => ({name: option.caption, value: option.id}),
  };

  constructor(private readonly connectionService: ConnectionService, private readonly environment: Environment) {}

  public getOptions(optionsType: OptionsType, value?: string | number): Observable<FormFieldOption[]> {
    if (!optionsType) {
      return of<FormFieldOption[]>([]);
    }

    const url = value ? optionsType.endpoint.slice(0, optionsType.endpoint.indexOf('{')) + value : optionsType.endpoint;
    const locale = this.environment.isPbsVendor ? 'ru' : 'en';

    return this.connectionService.get<{data}>(url).pipe(
      map(res => {
        const typeFromConfig = snakeCaseToCamelCase(optionsType.type);
        const isOptionsTypeExist = Object.keys(this.optionsTypeDictionary).includes(typeFromConfig);

        return Object.values(res.data)
          .map(this.optionsTypeDictionary[isOptionsTypeExist ? typeFromConfig : 'default'])
          .sort((a, b) => Intl.Collator(locale).compare(a.name, b.name));
      }),
      catchError(() => {
        console.error(`Options type [${optionsType.type}] for endpoint ${optionsType.endpoint} doesn't exist`);
        return of<FormFieldOption[]>([]);
      }),
    );
  }
}
