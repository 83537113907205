import {Injectable, OnDestroy} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {mapException} from '@app/core/exceptions/client.exception';
import {ResponseException} from '@app/core/exceptions/response-exception';
import {Account} from '@app/core/models/account/account';
import {EPlatformClass} from '@app/core/models/account/enum/platform-class';
import {EProductGroupType} from '@app/core/models/account/enum/product-group-type';
import {AdminWidgetRules, GridsterProviders} from '@app/core/models/gridster';
import {AccountService} from '@app/core/services/account.service';
import {AGridsterDatafeedService} from '@app/gridster/datafeeds/gridster-datafeed-service.abstract';
import {plainToInstance} from 'class-transformer';
import {Observable, ReplaySubject, firstValueFrom, map, shareReplay} from 'rxjs';

@Injectable()
export class DashboardDatafeedService extends AGridsterDatafeedService implements OnDestroy {
  private readonly accounts$ = this.accountsService.getAccountsAsync().pipe(shareReplay(1));

  private isInitialized = false;

  public provider = GridsterProviders.dashboard;
  public adminWidgetRules = new ReplaySubject<Record<string, AdminWidgetRules>>(1);

  constructor(private readonly accountsService: AccountService, private readonly connectionService: ConnectionService) {
    super();

    void firstValueFrom(this.connectionService.get('/api/v1/widgets').pipe(mapException)).then(
      (res: IExceptions<AdminWidgetRules[]>) => {
        if (res instanceof ResponseException) {
          const rules = plainToInstance<AdminWidgetRules, unknown[]>(AdminWidgetRules, res.getData());

          this.adminWidgetRules.next(AdminWidgetRules.reduceRules(rules));
        } else {
          this.adminWidgetRules.next({});
        }
      },
    );
  }

  public hasConnect(): boolean {
    return this.isInitialized;
  }

  public initialize(): void {
    this.isInitialized = true;
  }

  public afterConnect(): void {
    return;
  }

  public terminate(): void {
    this.isInitialized = false;
  }

  public getAccountsByPlatform(
    platformClass: EPlatformClass,
    productGroupType: EProductGroupType[],
    productType?: number,
  ): Observable<Account[]> {
    return this.accounts$.pipe(
      map(accounts => {
        return accounts.filter(account => {
          return (
            !account.archive &&
            (!productType || productType === account.product.type) &&
            productGroupType.includes(account.product.group.type) &&
            platformClass === account.product.platform.class
          );
        });
      }),
    );
  }

  public getAccountsService(): AccountService {
    return this.accountsService;
  }

  public ngOnDestroy(): void {
    this.adminWidgetRules.complete();
  }
}
