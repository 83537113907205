import * as B2MarginModels from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class TradesInternalEventHandler extends AEventHandler<IB2marginMessage<B2MarginModels.TradeHistoryTO[]>> {
  public type = EB2MarginEventType.TradeHistory;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<B2MarginModels.TradeHistoryTO[]>): void {
    this.store.tradeHistory$.next(resource.body);
  }
}

b2MarginInternalEventHandlers.push(TradesInternalEventHandler);
