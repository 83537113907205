import {Type} from '@angular/core';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {MoexAccountsService} from '@app/trading-board/event-handlers/moex/moex-accounts.service';
import {MoexErrorHandlerService} from '@app/trading-board/event-handlers/moex/moex-error-handler.service';
import {MoexQuoteService} from '@app/trading-board/event-handlers/moex/moex-quote.service';
import {SharedRefreshTokensHandlerService} from '@app/trading-board/event-handlers/shared/shared-refresh-tokens-handler.service';

import {MoexCancelOrderService} from './moex-cancel-order.service';
import {MoexCancelStopOrderService} from './moex-cancel-stop-order.service';
import {MoexLevel2Service} from './moex-level2.service';
import {MoexSubscribeChartService} from './moex-subscribe-chart.service';
import {MoexTradingStatusDataService} from './moex-trading-status-data-internal-event-handler';

export const MoexEventHandlersServices: Type<AEventHandler<unknown>>[] = [
  MoexSubscribeChartService,
  MoexLevel2Service,
  MoexQuoteService,
  MoexAccountsService,
  MoexErrorHandlerService,
  MoexCancelOrderService,
  MoexCancelStopOrderService,
  SharedRefreshTokensHandlerService,
  MoexTradingStatusDataService,
];
