import {IResultApi} from '../contracts/i.result.api';
import {Exceptions} from './exceptions';

export class CreatedException extends Exceptions {
  public static readonly STATUS_CODE = 201;

  constructor(body: IResultApi) {
    super(body);
  }
}
