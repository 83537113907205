import {ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CustomValidators} from '@app/core/form/entities/custom.validators';
import {FormInput} from '@app/core/form/entities/form.input';
import {FormWrapper} from '@app/core/form/entities/form.wrapper';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {IPhoneNumberCountryCode} from '@app/core/models/phone-number-country-code';
import {TNilable} from '@app/core/types/types';
import {PhoneNumberHelper} from '@app/core/utils/phone-number/phone-number-helper';
import {Environment} from '@env/environment.entities';
import {CountryCode} from 'libphonenumber-js';
import * as _ from 'lodash-es';
import {Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';

function onlyDigits(text?: string): string {
  return (text ?? '').replace(/[\s+]/g, '');
}

@Component({
  selector: 'app-form-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PhoneComponent extends FormInput implements OnInit {
  private static readonly MIN_LENGTH_NUMBER_PHONE = 6;

  private readonly destroyer$ = new Subject<void>();

  private selectedDialCode: string | null = null;

  public readonly defaultCountryCodeIso = this.environment.projectInfo.customCountryIso || 'us';
  public readonly mask = {
    pattern: '+000000000999999',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dropSpecialCharacters: false,
  };
  public readonly trackByCountry = PhoneNumberHelper.trackByCountry;
  public readonly countries = PhoneNumberHelper.getCountries();

  public required: boolean;
  public selectedCountry: CountryCode | null = null;
  public defaultCountry = PhoneNumberHelper.getCountryByIso(this.defaultCountryCodeIso);

  @ViewChild('phoneInput', {read: ElementRef, static: true})
  public phoneInput: ElementRef<HTMLInputElement>;

  constructor(labelService: LabelService, errorsService: ErrorsService, public readonly environment: Environment) {
    super(labelService, errorsService);
  }

  private findBestCountryMatch(phoneNumber: string): IPhoneNumberCountryCode {
    let bestCodeMatchLength = 0;

    return this.countries.reduce((bestMatch, country) => {
      const isMatched = phoneNumber.startsWith(country.dialCode);

      if (!isMatched) {
        return bestMatch;
      }

      if (country.dialCode.length > bestCodeMatchLength) {
        bestCodeMatchLength = country.dialCode.length;
        bestMatch = country;
      }

      return bestMatch;
    }, null);
  }

  public ngOnInit(): void {
    this.required = this.isRequired();

    // FDP-14325 setTimeout is used to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => this.setUpCountry(this.defaultCountry));

    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        map(v => onlyDigits(v)),
        takeUntil(this.destroyer$),
      )
      .subscribe((phoneNumber: string | null) => {
        const bestCountryMatch = this.findBestCountryMatch(phoneNumber);
        this.setUpCountry(bestCountryMatch);
      });
  }

  public setUpCountry(country: IPhoneNumberCountryCode | null, isManualChange = false): void {
    const controlValueBeforePatch: TNilable<string> = this.control.value;
    const countryCode = country?.iso2.toUpperCase() as CountryCode | undefined;
    const isDialCodesEqual = this.selectedDialCode === country?.dialCode;
    if (isDialCodesEqual) {
      return;
    }

    const validators = FormWrapper.reduceValidators(this.field.rules);

    if (countryCode) {
      validators.push(CustomValidators.minDigitsLength([PhoneComponent.MIN_LENGTH_NUMBER_PHONE]));

      // There is a case where a phone needs to be initialized with already known value,
      // in order to prevent dialCode from overwriting that value, we need to check that the control already contains a value that is a phone.
      // To do this, we use the check for the length of the value, dialCode is always shorter than the phone.
      const isPatchWithDialCode =
        _.isNil(controlValueBeforePatch) || controlValueBeforePatch.length < PhoneComponent.MIN_LENGTH_NUMBER_PHONE;

      if (isManualChange || isPatchWithDialCode) {
        this.control.patchValue(country.dialCode);
      }
    }

    this.selectedCountry = countryCode ?? null;
    this.selectedDialCode = country?.dialCode ?? null;
    this.control.addValidators(validators);
    this.control.updateValueAndValidity();
  }

  public compareCountry(option1: IPhoneNumberCountryCode | null, option2: IPhoneNumberCountryCode | null): boolean {
    return option1?.dialCode === option2?.dialCode;
  }
}
