import {createInstance} from '@amplitude/analytics-browser';
import {BrowserClient, DefaultTrackingOptions} from '@amplitude/analytics-types';
import {Injectable} from '@angular/core';

import {AAnalyticsService} from '../shared/analytics-service.abstract';
import {EUserProperties} from '../shared/enums/user-properties.enum';
import {IAmplitudeConfig} from '../shared/interfaces/amplitude-config.interface';
import {TEvent} from '../shared/types/event.type';
import {TUserProperties} from '../shared/types/user-properties.type';
import {DEFAULT_API_KEY} from './consts/default-api-key.const';
import {AmplitudeEventLogger} from './event-logger/amplitude-event-logger';
import {UserPropertiesEnrichmentPlugin} from './plugins/user-properties-enrichment-plugin';

@Injectable({providedIn: 'root'})
export class AmplitudeService extends AAnalyticsService<IAmplitudeConfig> {
  private readonly defaultTrackingOptions: DefaultTrackingOptions = {
    ['pageViews']: false,
    ['attribution']: false,
    ['formInteractions']: false,
    ['fileDownloads']: false,
  };

  /**
   * Our instance, used on all vendors.
   */
  private defaultInstance: BrowserClient | null = null;

  /**
   * Specific vendor instance, initialized if the API key is present.
   */
  private vendorInstance: BrowserClient | null = null;

  private get instances(): Array<BrowserClient> {
    return [this.defaultInstance, this.vendorInstance].filter(Boolean);
  }

  protected eventLogger: AmplitudeEventLogger | null = null;

  public initialize(config: IAmplitudeConfig, email: string): void {
    if (this.isTestMode) {
      this.initDefaultInstance(email);
      this.initEventLogger();
    } else if (this.isProd) {
      this.initDefaultInstance(email);
      this.initVendorInstance(config, email);
      this.initEventLogger();
    }
  }

  public logEvent(event: TEvent): void {
    if (this.eventLogger) {
      this.eventLogger.handleEvent(event);
    }
  }

  private initDefaultInstance(email: string): void {
    const userProperties: TUserProperties = {
      [EUserProperties.Vendor]: this.projectName,
      [EUserProperties.AppEnvironmentType]: this.appEnvironment,
      [EUserProperties.AppVersion]: this.appVersion,
      [EUserProperties.AppSource]: 'v2',
    };

    this.defaultInstance = this.createNewInstance(DEFAULT_API_KEY, email, userProperties);
  }

  private initVendorInstance(config: IAmplitudeConfig, email: string): void {
    const vendorApiKey = config.vendorApiKey;

    if (vendorApiKey) {
      const userProperties: TUserProperties = {
        [EUserProperties.AppVersion]: this.appVersion,
        [EUserProperties.AppSource]: 'v2',
      };

      this.vendorInstance = this.createNewInstance(vendorApiKey, email, userProperties);
    }
  }

  private initEventLogger(): void {
    this.eventLogger = new AmplitudeEventLogger(this.instances);
  }

  private createNewInstance(apiKey: string, email: string, userProperties: TUserProperties): BrowserClient {
    const instance = createInstance();

    this.setPlugins(instance, userProperties);

    const encryptedEmail = this.getEncryptedEmail(email);
    instance.init(apiKey, encryptedEmail, {
      defaultTracking: this.defaultTrackingOptions,
    });

    return instance;
  }

  private setPlugins(instance: BrowserClient, userProperties: TUserProperties): void {
    instance.add(new UserPropertiesEnrichmentPlugin(instance, userProperties));
  }
}
