import {BrowserClient} from '@amplitude/analytics-types';
import {EEventName} from '@app/analytic-systems/shared/enums/event-name.enum';

import {AEventLogger} from '../../shared/event-logger/event-logger.abstract';
import {TEventProperties} from '../../shared/types/event-properties.type';

export class AmplitudeEventLogger extends AEventLogger {
  constructor(private readonly instances: BrowserClient[]) {
    super();
  }

  public logEvent(eventName: EEventName, eventProperties?: TEventProperties): void {
    this.instances.forEach(instance => {
      instance.logEvent(eventName, eventProperties);
    });
  }
}
