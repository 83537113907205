import {IInstrument} from 'src/app/trading-board/interfaces/instrument';

export class InstrumentGroup {
  public subgroups: InstrumentSubgroup[] = [];

  constructor(public title: string, public index?: number) {}
}

export class InstrumentSubgroup {
  public instruments: IInstrument[] = [];

  constructor(public title: string, public index: number | undefined) {}
}
