import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IconModule} from '@app/icon/icon.module';
import {SharedModule} from '@app/shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

import {AppFileInputComponent} from './app.file.input/app.file.input.component';
import {FileInputImagesComponent} from './file.input.images/file.input.images.component';

@NgModule({
  declarations: [AppFileInputComponent, FileInputImagesComponent],
  imports: [CommonModule, TranslateModule, SharedModule, FormsModule, IconModule],
  exports: [AppFileInputComponent, FileInputImagesComponent],
})
export class AppFileInputModule {}
