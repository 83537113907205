import {IOrder} from '@app/trading-board/interfaces/b2trader/order';

import {EB2traderEventTypes} from '../../../enum/b2trader/b2trader-event-types';
import {B2traderStateStore} from '../../../facades/b2trader-state-store';
import {b2TraderInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class B2traderOrdersInternalEventHandler extends AEventHandler<IOrder[]> {
  public type = EB2traderEventTypes.Orders;

  constructor(private readonly store: B2traderStateStore) {
    super();
  }

  public handleMessage(resource: IOrder[] | null): void {
    if (resource === null) {
      this.store.orders$.next(null);
      return;
    }

    this.store.orders$.next(resource.reduce((acc, item) => acc.set(item.orderId, item), this.store.orders$.getValue()));
  }
}

b2TraderInternalEventHandlers.push(B2traderOrdersInternalEventHandler);
