import {ADataStoreModel} from '@app/trading-board/models/data-store-model';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {FxPositionMetricsTO, OrderTemplateTO, PositionTO} from '@b2broker/b2margin-trade-models';
import {Expose, Transform, Type} from 'class-transformer';
import {Decimal} from 'decimal.js';

import {IPositionCombined} from '../../interfaces/b2margin/position';
import {DecimalHelper} from '../decimal-helper';
import {B2MarginInstrument} from '../instrument';
import {Tick} from '../level1';

import OrderSideEnum = OrderTemplateTO.OrderSideEnum;

export type TPositionsMapType = Map<string, Position[]>;

export class Position extends ADataStoreModel<IPositionCombined> {
  private static readonly SCALE = 8;

  private static getSide(obj: IPositionCombined): OrderSideEnum {
    return obj.position.quantity > 0 ? OrderSideEnum.BUY : OrderSideEnum.SELL;
  }

  private static isBuy(side: OrderSideEnum): boolean {
    return side === OrderSideEnum.BUY;
  }

  public static fromB2MarginMap(
    positions: Map<string, PositionTO>,
    ticks: Map<string, Tick>,
    instruments: Map<string, B2MarginInstrument>,
    metrics: FxPositionMetricsTO[],
  ): Map<string, IPositionCombined> {
    return Array.from(positions.values()).reduce((acc: Map<string, IPositionCombined>, position: PositionTO) => {
      const metric = metrics.find(({uid}) => uid === position.uid);
      const instrument = instruments.get(position.positionKey.instrumentId.toString());
      const tick = ticks.get(instrument?.id);

      acc.set(position.uid, {position, tick, instrument, metric});

      return acc;
    }, new Map<string, IPositionCombined>());
  }

  public static getPositionQuantity(position: B2MarginModels.PositionTO, size?: string, lotSize?: number): number {
    if (size) {
      const quantity = DecimalHelper.from(size)
        .multiply(lotSize ?? 1)
        .toNumber();

      return position.quantity > 0 ? quantity * -1 : quantity;
    }
    return position.quantity * -1;
  }

  public static reduceByInstrument(positions: Position[]): TPositionsMapType {
    return positions.reduce((acc, position) => {
      const instrumentId = position.instrumentId;

      if (!acc.has(instrumentId)) {
        acc.set(instrumentId, []);
      }

      acc.get(instrumentId).push(position);
      return acc;
    }, new Map() as TPositionsMapType);
  }

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => obj.position.uid)
  public id: string;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => obj.instrument?.symbol)
  public instrument: string;

  @Transform(({obj}: {obj: IPositionCombined}) => obj.instrument?.symbolWithSeparator)
  public instrumentWithSeparator: string;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => Position.getSide(obj))
  public side: OrderSideEnum;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    DecimalHelper.from(obj.position.quantity)
      .divide(obj.instrument?.lotSize ?? 1)
      .abs()
      .setScale(Position.SCALE),
  )
  public size: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    DecimalHelper.from(obj.metric?.plOpen ?? 0)
      .setScale(Position.SCALE)
      .toDecimalPlaces(Position.SCALE, Decimal.ROUND_DOWN),
  )
  public plOpen: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => DecimalHelper.from(obj.metric?.plOpen ?? 0).isNegative())
  public isPlNegative: boolean;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    DecimalHelper.from(obj.position.costBasis ?? 0).setScale(obj.instrument?.priceScale ?? Position.SCALE),
  )
  public fillPrice: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    LazyDecimalHelper.from(
      (Position.isBuy(Position.getSide(obj)) ? obj.tick?.bestBid : obj.tick?.bestOffer) ?? 0,
    ).setScale(obj.instrument?.priceScale ?? Position.SCALE),
  )
  public currentPrice: LazyDecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => new Date(obj.position.time))
  public date: Date;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    obj.metric?.totalCommissions ? DecimalHelper.from(obj.metric?.totalCommissions ?? 0).abs() : null,
  )
  public totalCommissions: DecimalHelper | null;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    obj.metric?.totalFinancing ? DecimalHelper.from(obj.metric?.totalFinancing ?? 0).abs() : null,
  )
  public totalFinancing: DecimalHelper | null;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    obj.position.stopLoss
      ? DecimalHelper.from(obj.position.stopLoss?.fixedPrice ?? 0).setScale(
          obj.instrument?.priceScale ?? Position.SCALE,
        )
      : null,
  )
  public stopLoss: DecimalHelper | null;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    obj.position.takeProfit
      ? DecimalHelper.from(obj.position.takeProfit?.fixedPrice ?? 0).setScale(
          obj.instrument?.priceScale ?? Position.SCALE,
        )
      : null,
  )
  public takeProfit: DecimalHelper | null;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => obj.instrument?.id)
  public instrumentId: string;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => obj.position.modifiedTime)
  @Type(() => Date)
  public modifiedTime: number;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => LazyDecimalHelper.from(obj.tick?.bestBid ?? 0))
  public bid: LazyDecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => LazyDecimalHelper.from(obj.tick?.bestOffer ?? 0))
  public ask: LazyDecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => DecimalHelper.from(obj.position.marginRate ?? 0))
  public marginRate?: DecimalHelper;

  @Transform(({obj}: {obj: IPositionCombined}) =>
    obj.position.marginRate ? LazyDecimalHelper.from(1).divide(obj.position.marginRate) : null,
  )
  public positionLeverage?: LazyDecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => obj.position.positionKey.positionCode)
  public code: string;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) =>
    Position.isBuy(Position.getSide(obj)) ? obj.tick?.isBidIncrease : obj.tick?.isOfferIncrease,
  )
  public isPriceIncrease: boolean;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => {
    const quantity = DecimalHelper.from(obj.position.quantity ?? 0);
    return Position.isBuy(Position.getSide(obj)) ? quantity : quantity.negated();
  })
  public quantity: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IPositionCombined}) => {
    const isBuy = Position.isBuy(Position.getSide(obj));
    const change = LazyDecimalHelper.from((isBuy ? obj.tick?.bestBid : obj.tick?.bestOffer) ?? 0)
      .divide(LazyDecimalHelper.from(obj.position.costBasis).setScale(Position.SCALE))
      .minus(1)
      .multiply(100)
      .setScale(2);

    return isBuy ? change : change.negated();
  })
  public change: LazyDecimalHelper;

  public isBuy(): boolean {
    return this.side === OrderSideEnum.BUY;
  }

  public update(obj: IPositionCombined): void {
    const isBuy = Position.isBuy(Position.getSide(obj));

    this.instrument = obj.instrument?.symbol;
    this.instrumentWithSeparator = obj.instrument?.symbolWithSeparator;
    this.size = DecimalHelper.from(obj.position.quantity)
      .divide(obj.instrument?.lotSize ?? 1)
      .abs()
      .setScale(Position.SCALE);
    this.plOpen = DecimalHelper.from(obj.metric?.plOpen ?? 0)
      .setScale(Position.SCALE)
      .toDecimalPlaces(Position.SCALE, Decimal.ROUND_DOWN);
    this.isPlNegative = DecimalHelper.from(obj.metric?.plOpen ?? 0).isNegative();
    this.fillPrice = DecimalHelper.from(obj.position.costBasis).setScale(Position.SCALE);
    this.currentPrice = LazyDecimalHelper.from((isBuy ? obj.tick?.bestBid : obj.tick?.bestOffer) ?? 0).setScale(
      Position.SCALE,
    );
    this.date = new Date(obj.position.time);
    this.totalCommissions = obj.metric?.totalCommissions
      ? DecimalHelper.from(obj.metric?.totalCommissions).abs()
      : null;
    this.totalFinancing = obj.metric?.totalFinancing ? DecimalHelper.from(obj.metric?.totalFinancing).abs() : null;
    this.stopLoss = obj.position.stopLoss ? DecimalHelper.from(obj.position.stopLoss?.fixedPrice) : null;
    this.takeProfit = obj.position.takeProfit ? DecimalHelper.from(obj.position.takeProfit?.fixedPrice) : null;
    this.instrumentId = obj.instrument?.id;
    this.modifiedTime = Number(obj.position.modifiedTime);
    this.bid = LazyDecimalHelper.from(obj.tick?.bestBid ?? 0);
    this.ask = LazyDecimalHelper.from(obj.tick?.bestOffer ?? 0);
    this.marginRate = DecimalHelper.from(obj.position.marginRate ?? 0);
    this.isPriceIncrease = isBuy ? obj.tick?.isBidIncrease : obj.tick?.isOfferIncrease;
    this.quantity = isBuy
      ? DecimalHelper.from(obj.position.quantity)
      : DecimalHelper.from(obj.position.quantity).negated();
    this.positionLeverage = obj.position.marginRate
      ? LazyDecimalHelper.from(1).divide(obj.position.marginRate).setScale(0)
      : null;
    const change = this.currentPrice.divide(this.fillPrice).minus(1).multiply(100).setScale(2);
    this.change = isBuy ? change : change.negated();
  }
}
