import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {IIconConfig} from './icon-config.interface';
import {TIcon} from './icon.type';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgComponent {
  private static readonly ICON_LIST: Record<TIcon, IIconConfig> = {
    language: {
      path: 'assets/img/icons/lang/lang-',
      suffix: 'png',
      plugPath: 'assets/img/icons/lang/lang-empty.png',
    },
    emoji: {
      path: 'assets/img/icons/emojis/',
      suffix: 'png',
      plugPath: null,
    },
    paymethod: {
      path: 'assets/img/icons/paymethod/',
      suffix: 'svg',
      plugPath: null,
    },
    moex: {
      path: 'assets/img/icons/moex/',
      suffix: 'svg',
      plugPath: 'assets/img/icons/moex/default-currency.svg',
    },
  };

  @Input()
  public type: TIcon = 'paymethod';

  @Input()
  public iconName: string;

  public get iconPath(): string {
    const {path, suffix} = ImgComponent.ICON_LIST[this.type];

    return path + this.iconName.toLowerCase() + '.' + suffix;
  }

  public get iconPlug(): string {
    return ImgComponent.ICON_LIST[this.type].plugPath ?? '';
  }
}
