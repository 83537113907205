import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-required-indicator',
  templateUrl: './required-indicator.component.html',
  styleUrls: ['./required-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequiredIndicatorComponent {
  @Input()
  public isShowIndicator = false;

  @Input()
  public isInvalid = false;
}
