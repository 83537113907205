import {Injectable} from '@angular/core';
import {Settings} from '@app/core/models/user/settings';
import {blobToDataUrl} from '@app/core/utils/file-helpers/blob-to-dataurl';
import {BehaviorSubject, firstValueFrom, from, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import {ConnectionService} from '../connection.service';
import {IExceptions} from '../contracts/i.exceptions';
import {mapException} from '../exceptions/client.exception';
import {IUpdateUserInfoDto} from '../models/user/update-user-info.dto';

@Injectable()
export class UserService {
  private readonly avatarUrlState$ = new BehaviorSubject('');

  public get userAvatar(): string {
    return this.avatarUrlState$.value;
  }

  public set userAvatar(value: string) {
    this.avatarUrlState$.next(value);
  }

  public readonly userAvatar$ = this.avatarUrlState$.pipe();

  constructor(private readonly connectionService: ConnectionService) {}

  public user(withList?: string[]): Observable<IExceptions> {
    return this.connectionService
      .get('/api/v1/me', {
        with: withList ? withList.join(',') : '',
      })
      .pipe(mapException);
  }

  public meObservable(withList?: string[]): Observable<IExceptions> {
    return this.user(withList);
  }

  public me(withList?: string[]): Promise<IExceptions> {
    return firstValueFrom(this.meObservable(withList));
  }

  public getType(withList?: string[]): Promise<IExceptions> {
    return firstValueFrom(
      this.connectionService
        .get('/api/v1/me/migrate', {
          with: withList ? withList.join(',') : '',
        })
        .pipe(mapException),
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public setType(params: {type_id: number; comment: string}): Promise<IExceptions> {
    return firstValueFrom(this.connectionService.post('/api/v1/me/migrate', params).pipe(mapException));
  }

  public settings(data: Partial<Settings>): Promise<IExceptions> {
    return firstValueFrom(this.connectionService.put('/api/v1/settings', {settings: data}).pipe(mapException));
  }

  public settingsBeacon(settings: Partial<Settings>): boolean {
    return this.connectionService.sendBeacon('/api/v1/settings', {settings});
  }

  public getAvatarBase64(size: number = 80): Observable<string> {
    return this.connectionService
      .getBlob('/api/v1/me/profile_picture', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        length_side_square: size,
      })
      .pipe(
        switchMap(result => (result.blob.type.includes('image') ? from(blobToDataUrl(result.blob)) : of(''))),
        catchError(() => of('')),
      );
  }

  public updateUserInfo(clientId: number, params: Partial<IUpdateUserInfoDto>): Observable<IExceptions> {
    return this.connectionService.post(`/api/v1/client/${clientId}`, params).pipe(mapException);
  }
}
