import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, Injector, NgZone, Provider} from '@angular/core';
import {Router} from '@angular/router';
import {ForbiddenErrorException} from '@app/core/exceptions/forbidden-error-exception';
import {MaintenanceException} from '@app/core/exceptions/maintenance-exception';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ErrorsHandlerService implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  public handleError(error: Error): void {
    if (error instanceof ForbiddenErrorException) {
      const router: Router = this.injector.get(Router);
      const ngZone: NgZone = this.injector.get(NgZone);
      void ngZone.run(() => router.navigateByUrl('/'));

      return;
    }

    if (error instanceof HttpErrorResponse && error.status !== MaintenanceException.STATUS_CODE) {
      const snackBar = this.injector.get(SnackbarService);

      if (!navigator.onLine) {
        return snackBar.message(this.injector.get(TranslateService).get('No Internet Connection'));
      }

      return snackBar.error(`${error.status} - ${error.message}`);
    }

    throw error;
  }
}

export const ErrorHandlerProvider: Provider = {
  provide: ErrorHandler,
  useClass: ErrorsHandlerService,
};
