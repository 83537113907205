<ng-container [formGroup]="formGroup">
  <div class="question-field-wrapper">
    <div *ngIf="field.label" class="question-label">{{ getLabel() | async }}</div>

    <app-radio-input [control]="control" [options]="options" [field]="field"></app-radio-input>

    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>
  </div>
</ng-container>
