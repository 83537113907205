import {CastTypes, Model} from '@app/core/model';
import {EAccountStatus} from '@app/pbsr/enums/account-status.enum';
import {EMarket} from '@app/trading-board/enum/moex/market.enum';

export class Options extends Model {
  public subAccId: string;
  public isAnalytical?: boolean;
  public isIis?: boolean;
  public market?: EMarket;
  public parentSubAccId?: string;
  public status?: EAccountStatus;
  public tradeCode: string;

  public readonly casts = {
    isAnalytical: [CastTypes.Null, CastTypes.Boolean],
    isIis: [CastTypes.Null, CastTypes.Boolean],
    subAccId: [CastTypes.String],
    market: [CastTypes.Null, CastTypes.String],
    parentSubAccId: [CastTypes.Null, CastTypes.String],
    tradeCode: [CastTypes.Null, CastTypes.String],
    status: [CastTypes.Null, CastTypes.Number],
  };
}
