import {ATradeDatafeed} from '@app/trading-board/datafeed/trade-datafeed.abstract';
import {Bar} from '@app/trading-board/models/bar';
import {
  HistoryCallback,
  IBasicDataFeed,
  LibrarySymbolInfo,
  OnReadyCallback,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {
  DatafeedConfiguration,
  PeriodParams,
  SearchSymbolsCallback,
} from '@b2broker/trading.view.charting.library/charting_library/datafeed-api';
import {Subscription, firstValueFrom, take} from 'rxjs';

export class ChartDataFeed implements IBasicDataFeed {
  protected static readonly DEFAULT_SUPPORTED_RESOLUTION: ResolutionString[] = [
    '1' as ResolutionString,
    '5' as ResolutionString,
    '15' as ResolutionString,
    '30' as ResolutionString,
    '60' as ResolutionString,
    '360' as ResolutionString,
    '720' as ResolutionString,
    'D' as ResolutionString,
    'W' as ResolutionString,
    'M' as ResolutionString,
  ];

  protected lastBar: Bar;
  protected readonly subscriptionIntervalMs = 100;
  private emptyDataCounter = 0;

  protected readonly listenerGuidToSymbolSubscription = new Map<string, Subscription>();

  constructor(protected readonly datafeed: ATradeDatafeed, protected readonly configuration: DatafeedConfiguration) {}

  public onReady(callback: OnReadyCallback): void {
    window.setTimeout(() => callback(this.configuration));
  }

  public async resolveSymbol(symbolName: string, onResolve: ResolveCallback): Promise<void> {
    const instruments = await firstValueFrom(this.datafeed.instruments$.pipe(take(1)));
    const instrument = instruments.find(({symbolWithSeparator}) => symbolName === symbolWithSeparator);

    window.setTimeout(() =>
      onResolve({
        name: symbolName,
        description: symbolName,
        type: 'crypto',
        session: '24x7',
        exchange: '',
        timezone: 'Etc/UTC',
        pricescale: Math.pow(10, instrument?.priceScale),
        minmov: 1,
        format: 'price',
        ticker: symbolName,
        /* eslint-disable @typescript-eslint/naming-convention */
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly: true,
        data_status: 'streaming',
        full_name: instrument?.symbolWithSeparator,
        listed_exchange: '',
        supported_resolutions: this.configuration.supported_resolutions || ChartDataFeed.DEFAULT_SUPPORTED_RESOLUTION,
        /* eslint-enable @typescript-eslint/naming-convention */
      }),
    );
  }

  public subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
  ): void {
    const barsSubscription = this.datafeed.getBar$(symbolInfo.full_name, resolution).subscribe(bar => {
      if (this.lastBar && bar.time - this.subscriptionIntervalMs < this.lastBar.time) {
        bar.time = this.lastBar.time;
      }

      if (!this.lastBar || bar.time >= this.lastBar.time) {
        onTick(bar);
        this.lastBar = bar;
      }
    });

    this.listenerGuidToSymbolSubscription.set(listenerGuid, barsSubscription);
  }

  public getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
  ): void {
    const {
      to,
      from,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      firstDataRequest,
    } = periodParams;

    // symbolInfo.full_name, resolution, from, to, firstDataRequest
    this.datafeed
      .getBarsData$({
        symbol: symbolInfo.full_name,
        resolution,
        rangeStartDate: from,
        rangeEndDate: to,
        isFirstCall: firstDataRequest,
      })
      .subscribe((bars: Bar[]) => {
        // We should update lastBar if there is no bar or the last bar from socket is newer than current lastBar
        // To update chart properly with new data in the subscribeBars function
        const isCurrentLastBarExpired = this.lastBar?.time < bars[bars.length - 1]?.time;
        if (!this.lastBar || isCurrentLastBarExpired) {
          this.lastBar = bars.length ? bars[bars.length - 1] : null;
        }

        // If we receive empty data several times, then we will think that there is no further data for the chart
        this.emptyDataCounter = bars.length ? 0 : this.emptyDataCounter + 1;

        onResult(bars, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          noData: this.emptyDataCounter === 5,
        });
      });
  }

  public unsubscribeBars(listenerGuid: string): void {
    const subscription = this.listenerGuidToSymbolSubscription.get(listenerGuid);
    subscription?.unsubscribe();
    this.listenerGuidToSymbolSubscription.delete(listenerGuid);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
  public searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback,
  ): void {}
  /* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
}
