import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {InternalServerErrorException} from '@app/core/exceptions/internal-server-error-exception';
import {TerminateException} from '@app/core/exceptions/terminate-exception';
import {SigningFormService} from '@app/ui/signing-form/services/signing-form.service';
import {AWorkflow} from '@app/wizard/a.workflow';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, tap} from 'rxjs';

import {BatchExtendService} from './services/batch.extend.service';

@Component({
  selector: 'app-signing-form',
  templateUrl: './signing-form.component.html',
  styleUrls: ['./signing-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigningFormComponent extends AWorkflow {
  constructor(
    protected readonly batchExtendService: BatchExtendService,
    protected readonly translate: TranslateService,
    private readonly dialogRef: MatDialogRef<SigningFormComponent>,
    private readonly signingFormService: SigningFormService,
  ) {
    super(batchExtendService);
  }

  public init(): Promise<IExceptions> {
    return firstValueFrom(
      this.signingFormService.init().pipe(
        tap(exception => {
          if (exception instanceof InternalServerErrorException) {
            this.dialogRef.close(false);
          }
        }),
      ),
    );
  }

  public handle(result: IExceptions): void {
    if (result instanceof TerminateException) {
      this.dialogRef.close(false);
    }
  }
}
