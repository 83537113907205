import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ACCEPT_TYPES_FORMAT, MAX_FILE_SIZE} from '@app/core/form/entities/file-input-default-restrictors';
import {FormFieldRule} from '@app/core/form/entities/form.field';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-file-message',
  templateUrl: './file-message.component.html',
  styleUrls: ['./file-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileMessageComponent implements OnInit {
  @Input()
  private readonly rules: FormFieldRule[] | undefined;

  @Input()
  public acceptTypes: string | undefined;

  public maxFileSize = MAX_FILE_SIZE;

  public get acceptTypesFormat(): string {
    return (
      this.acceptTypes
        ?.split(',')
        .map((mimeType: string) => {
          const [, type]: string[] = mimeType.split('/');
          return type.toUpperCase();
        })
        .join(', ') || ACCEPT_TYPES_FORMAT
    );
  }

  public ngOnInit(): void {
    this.setMessage();
  }

  private setMessage(): void {
    const maxFileSizeRule = this.rules?.find(i => i[0] === 'max');

    if (maxFileSizeRule && maxFileSizeRule[1]?.length && !_.isNaN(Number(maxFileSizeRule[1][0]))) {
      const maxFileSize: string = (Number(maxFileSizeRule[1][0]) / 1.024 / 1000).toFixed(1);

      this.maxFileSize = Number(maxFileSize).toString();
    }
  }
}
