import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  TrackByFunction,
  ViewContainerRef,
} from '@angular/core';
import {GridsterEventType} from '@app/core/models/gridster';
import {IWidgetSettings} from '@app/core/models/gridster/gridster-item';
import {GridsterWidgetSettings} from '@app/core/models/gridster/gridster-widget-settings';
import {GridsterWidgetsEventsService} from '@app/gridster/events/gridster-widgets-events.service';
import {MANAGE_VIEW_REF_TOKEN} from '@app/gridster/tokens/manage-view-ref-token';
import {AGridsterViewPortWidgetComponent} from '@app/gridster/widgets/gridster-view-port-widget-component.abstract';
import {ECommonWidgetKeys} from '@app/gridster/widgets/gridster-widgets';
import {ATradeDatafeed} from '@app/trading-board/datafeed/trade-datafeed.abstract';
import {IInstrument} from '@app/trading-board/interfaces/instrument';
import {Instrument} from '@app/trading-board/models/instrument';
import {Level1Collector} from '@app/trading-board/models/level1-collector';
import {AComponentResolver, IReplaceableComponent} from '@app/trading-board/services/component-resolver.abstract';
import {ParentComponentProvider} from '@app/trading-board/services/parent-component-provider';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {WatchListSettings} from './watch-list-settings';

@Component({
  styleUrls: ['./watch-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export class WatchListComponent
  extends AGridsterViewPortWidgetComponent<WatchListSettings>
  implements OnInit, OnDestroy
{
  public static getPairs(settings: WatchListSettings | null): string[] {
    return settings?.pairs;
  }

  public ticks?: Level1Collector;
  public pairs: string[];
  public isAddedInstrument = false;

  public viewPort: CdkVirtualScrollViewport;

  constructor(
    private readonly tradeDatafeed: ATradeDatafeed,
    private readonly widgetsEventsService: GridsterWidgetsEventsService,
    protected readonly cdr: ChangeDetectorRef,
    public container: ViewContainerRef,
    private readonly injector: Injector,
    @Optional() private readonly componentResolver?: AComponentResolver,
    @Optional() @Inject(MANAGE_VIEW_REF_TOKEN) protected attached$?: Subject<boolean>,
  ) {
    super(attached$);
  }

  public async ngOnInit(): Promise<void> {
    this.tradeDatafeed.level1$.pipe(takeUntil(this.destroyer$)).subscribe(ticks => {
      this.ticks = ticks;
      this.cdr.detectChanges();
    });

    this.widgetsEventsService
      .getEvent({type: GridsterEventType.watch})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(({symbolWithSeparator}: IInstrument) => this.addInstrument(symbolWithSeparator));

    this.widgetsEventsService
      .getEvent({type: GridsterEventType.removeFromWatchlist})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(({symbolWithSeparator}: IInstrument) => this.removeInstrument(symbolWithSeparator));

    this.settings$.pipe(map(WatchListComponent.getPairs), takeUntil(this.destroyer$)).subscribe((pairs: string[]) => {
      this.pairs = pairs;
    });

    await this.providePlatformView();
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public addInstrument(symbol: string): void {
    const settings = this.settings$.value;

    if (settings.pairs.includes(symbol)) {
      return;
    }

    this.settings$.next({...settings, pairs: [...settings.pairs, symbol]});
    this.isAddedInstrument = false;
  }

  public changeInstrument(symbol1: string, symbol2: string): void {
    if (symbol1 === symbol2) {
      return;
    }

    const settings = this.settings$.value;
    settings.pairs = settings.pairs.map(pair => (pair === symbol1 ? symbol2 : pair));
    this.settings$.next(settings);
  }

  public removeInstrument(symbol: string, $event?: MouseEvent): void {
    $event?.stopPropagation();

    const settings: WatchListSettings = this.settings$.value;

    settings.pairs = settings.pairs.filter(value => value !== symbol);
    this.settings$.next(settings);
  }

  public syncInstrument(id: string): void {
    this.widgetsEventsService.sendEvent({
      type: GridsterEventType.syncInstrument,
      payload: id,
      widget: ECommonWidgetKeys.watchList,
    });
  }

  public isValueChangeColumnEmpty(id: string): boolean {
    return !this.ticks.get(id)?.rolling24HrChangeQuantity || !this.ticks.get(id)?.rolling24HrPxChange;
  }

  public async providePlatformView(): Promise<void> {
    const factoryFn = this.componentResolver?.getComponent<IReplaceableComponent>(WatchListComponent);

    if (factoryFn) {
      const {entryPoint} = await factoryFn();

      const component = this.container.createComponent(entryPoint, {
        index: undefined,
        injector: Injector.create({
          providers: [{provide: ParentComponentProvider, useExisting: forwardRef(() => WatchListComponent)}],
          parent: this.injector,
        }),
      });
      component.changeDetectorRef.detectChanges();
    }
  }

  public getDefaultSettings(): GridsterWidgetSettings {
    return WatchListSettings.getInstance();
  }

  public makeSettings(config: IWidgetSettings): GridsterWidgetSettings {
    return WatchListSettings.makeInstance(config);
  }

  public readonly trackbySymbolWithSeparator: TrackByFunction<Instrument> = (_, i) => i.symbolWithSeparator;
}
