import {Direction} from '@angular/cdk/bidi';
import {ChangeDetectorRef, Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {DirectionService} from '@app/shared/modules/direction/services/direction.service';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Directive({
  selector: '[appDir]',
  exportAs: 'directionDirective',
})
export class DirDirective implements OnInit, OnDestroy {
  private sub: Subscription = Subscription.EMPTY;

  public value?: Direction;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
    private readonly dirService: DirectionService,
  ) {}

  public ngOnInit(): void {
    this.sub = this.dirService.dir.pipe(distinctUntilChanged()).subscribe((dir: Direction): void => {
      this.elementRef.nativeElement.dir = dir;
      this.value = dir;
      this.cdr.markForCheck();
    });
  }

  public isRtl(): boolean {
    return this.value === 'rtl';
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
