<div class="file-input-panel">
  <div *ngIf="isShowBackgroundImage" class="file-input-bg-img">
    <app-icon icon="file-upload"></app-icon>
  </div>
  <div class="file-input-title" [innerHTML]="'Core.Ui.AppFileInput.Title' | translate"></div>

  <input
    class="file-input"
    type="file"
    #inputFiles
    [multiple]="isMultiple"
    [accept]="accept"
    [disabled]="disabled"
    [class.adding-files-not-available]="isAddingFilesNotAvailable"
    (drop)="drop($event)"
    (change)="change($event)"
  />

  <app-file-input-images
    [urls]="keeper.safeUrls"
    [errors]="errors"
    [files]="files"
    [isCenteredContent]="isAddingFilesNotAvailable"
    (remove)="rmFile($event)"
  ></app-file-input-images>
</div>
