import {SummaryTO} from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class SummaryInternalEventHandler extends AEventHandler<IB2marginMessage<SummaryTO[]>> {
  public type = EB2MarginEventType.Summary;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<SummaryTO[]>): void {
    const value = resource.body.reduce((state, i) => state.set(i.symbol, i), this.store.summary$.value);
    this.store.summary$.next(value);
  }
}

b2MarginInternalEventHandlers.push(SummaryInternalEventHandler);
