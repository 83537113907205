import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {Subscription, timer} from 'rxjs';

@Component({
  selector: '<app-captcha>',
  templateUrl: './captcha.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaComponent extends FormInput implements OnInit, OnDestroy {
  private element: HTMLScriptElement | undefined;
  private timerSub = Subscription.EMPTY;
  private statusChangeSub = Subscription.EMPTY;
  public hasCaptchaError = false;
  public readonly responseHandlerName = 'handleResponseCaptcha';
  public siteKey = '';

  constructor(labelService: LabelService, errorsService: ErrorsService, private readonly cdr: ChangeDetectorRef) {
    super(labelService, errorsService);
  }

  public ngOnInit(): void {
    if (!this.field.data || !this.field.data.enabled || !this.field.data.key) {
      return;
    }

    this.siteKey = this.field.data.key;

    this.updateCaptcha();

    this.statusChangeSub = this.control.statusChanges.subscribe((value: 'VALID' | 'INVALID') => {
      if (value === 'INVALID') {
        this.updateCaptcha();
      }
    });

    window[this.responseHandlerName] = this.handleResult.bind(this);
  }

  public ngOnDestroy(): void {
    this.statusChangeSub.unsubscribe();
    this.timerSub.unsubscribe();
    this.clearScript();

    delete window[this.responseHandlerName];
  }

  private clearScript(): void {
    window.grecaptcha = null;

    const grecaptchaBadges = Array.from(document.getElementsByClassName('grecaptcha-badge'));
    Array.prototype.forEach.call(grecaptchaBadges, (item: Element) => item.remove());

    if (this.element) {
      this.element.remove();
    }
  }

  public updateCaptcha(): void {
    this.addScript()
      .then(() => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.reset();
          void window.grecaptcha.execute();
        });
      })
      .catch(() => (this.hasCaptchaError = true));
  }

  private handleResult(responseToken: string): void {
    this.control.setValue(responseToken);
    this.cdr.detectChanges();

    this.timerSub.unsubscribe();
    this.timerSub = timer(120000, 120000).subscribe(() => {
      this.clearScript();
      this.updateCaptcha();
    });
  }

  public addScript(): Promise<Event> {
    return new Promise((resolve: (value?: PromiseLike<Event> | Event) => void, reject: () => void): void => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      this.element = document.body.appendChild(script);
      script.onload = resolve;
      script.onerror = reject;
    });
  }
}

export function resetCaptcha(captchaControl?: AbstractControl): void {
  if (captchaControl) {
    captchaControl.setValue(null);
  }
}
