import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToggleButtonComponent extends FormInput implements OnInit {
  public required: boolean;
  public disabled: boolean;

  constructor(labelsService: LabelService, errorsService: ErrorsService) {
    super(labelsService, errorsService);
  }

  public ngOnInit(): void {
    this.required = this.isRequired();
    this.disabled = this.isDisabled();
  }
}
