import {Inject, Injectable} from '@angular/core';
import {InjectionToken} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {mapException} from '@app/core/exceptions/client.exception';
import {firstValueFrom} from 'rxjs';

export const WizardsUrlToken = new InjectionToken<TWizardsUrl>('Base URL for wizards');

enum EWizardsUrl {
  Public = '/api/v1/public/wizards/',
  Private = '/api/v1/wizards/',
}

type TWizardsUrl = EWizardsUrl.Public | EWizardsUrl.Private;

interface IWizardUrl {
  provide: InjectionToken<TWizardsUrl>;
  useValue: TWizardsUrl;
}

export const publicWizardTokenProvider: IWizardUrl = {
  provide: WizardsUrlToken,
  useValue: EWizardsUrl.Public,
};

export const privateWizardTokenProvider: IWizardUrl = {
  provide: WizardsUrlToken,
  useValue: EWizardsUrl.Private,
};

@Injectable()
export class WorkflowService {
  constructor(
    private readonly connection: ConnectionService,
    @Inject(WizardsUrlToken) private readonly wizardsUrl: string,
  ) {}

  public step(uuid: string): Promise<IExceptions> {
    return firstValueFrom(this.connection.get(this.wizardsUrl + uuid).pipe(mapException));
  }

  public send(uuid: string, data): Promise<IExceptions> {
    return firstValueFrom(this.connection.post(this.wizardsUrl + uuid, data).pipe(mapException));
  }
}
