import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Environment} from '@env/environment.entities';

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(private readonly environment: Environment, private readonly router: Router) {}

  public canActivate(): boolean {
    if (this.environment.isPublic) {
      return true;
    }

    void this.router.navigateByUrl('/');

    return false;
  }
}
