import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {AuthCardContentComponent} from '@app/auth/auth-card/auth-card-content/auth-card-content.component';
import {AuthCardHeaderComponent} from '@app/auth/auth-card/auth-card-header/auth-card-header.component';
import {AuthCardComponent} from '@app/auth/auth-card/auth-card.component';
import {IconModule} from '@app/icon/icon.module';
import {TranslateModule} from '@ngx-translate/core';

import {SuccessConfirmMessageComponent} from './success-confirm-message/success-confirm-message.component';

@NgModule({
  declarations: [AuthCardComponent, AuthCardHeaderComponent, AuthCardContentComponent, SuccessConfirmMessageComponent],
  imports: [CommonModule, MatButtonModule, RouterModule, TranslateModule, IconModule],
  exports: [AuthCardComponent, AuthCardHeaderComponent, AuthCardContentComponent, SuccessConfirmMessageComponent],
})
export class AuthCardModule {}
