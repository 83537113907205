import {Injectable, OnDestroy} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {Token} from '@app/core/models/auth/token';
import {TokenResponse} from '@app/core/models/auth/token-response';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {ESharedEventHandlers} from '@app/trading-board/event-handlers/shared/shared-event-handlers.enum';
import {WorkerConnectionService} from '@app/trading-board/services/worker-connection.service';
import {plainToInstance} from 'class-transformer';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable()
export class SharedRefreshTokensHandlerService extends AEventHandler<void> implements OnDestroy {
  private readonly destroyer$ = new Subject<void>();

  public readonly type = ESharedEventHandlers.StartRefreshTokens;

  constructor(
    private readonly connectionService: ConnectionService,
    private readonly workerConnectionService: WorkerConnectionService,
  ) {
    super();
  }

  public handleMessage(): void {
    const tokenResponse = plainToInstance(Token, JSON.parse(ALocaleStorage.ACCESS_TOKEN.get()) as TokenResponse);

    if (this.connectionService.isExpiredToken(tokenResponse.data)) {
      this.connectionService
        .refreshTokenInformation()
        .pipe(takeUntil(this.destroyer$))
        .subscribe(newTokenResponse => {
          this.workerConnectionService.sendMessage({
            type: ESharedEventHandlers.EndRefreshTokens,
            payload: newTokenResponse,
          });
        });

      return;
    }

    this.workerConnectionService.sendMessage({
      type: ESharedEventHandlers.EndRefreshTokens,
      payload: tokenResponse,
    });
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
