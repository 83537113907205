import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';

import {AEventHandler} from '../event-handler.abstract';
import {EEventHandlersReceived} from '../event-handlers.enum';

@Injectable()
export class B2TraderErrorHandlerService extends AEventHandler<string> {
  public readonly type = EEventHandlersReceived.Error;

  constructor(private readonly translate: TranslateService, private readonly snackBarService: SnackbarService) {
    super();
  }

  public handleMessage(error: string): void {
    this.snackBarService.errorTranslate(
      error || marker('TradingBoard.EventHandlers.B2Trader.ErrorHandlerService.SomethingWentWrong'),
    );
  }
}
