import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {MarginImpactTO} from '@b2broker/b2margin-trade-models';
import * as _ from 'lodash-es';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class MarginImpactInternalEventHandler extends AEventHandler<IB2marginMessage<B2MarginModels.MarginImpactTO[]>> {
  public type = EB2MarginEventType.MarginImpact;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<MarginImpactTO[]>): void {
    this.store.marginImpact$.next(
      resource.body
        .filter(item => !_.isNil(item.instrumentSymbol))
        .reduce((acc, item) => acc.set(item.instrumentSymbol, item), this.store.marginImpact$.getValue()),
    );

    this.store.positionMarginImpact$.next(
      resource.body
        .filter(item => !_.isNil(item.uid))
        .reduce((acc, item) => acc.set(item.uid, item), this.store.positionMarginImpact$.getValue()),
    );
  }
}

b2MarginInternalEventHandlers.push(MarginImpactInternalEventHandler);
