import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EThemeMode} from '@app/shared/modules/theme/entities/theme-mode';
import {ThemeService} from '@app/shared/modules/theme/services/theme.service';
import {Environment} from '@env/environment.entities';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();

  public fullLogoUrl: string;
  public currentTheme: EThemeMode;
  public projectInfo = this.environment.projectInfo;

  @Input()
  public isMin: boolean;

  constructor(
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
    public environment: Environment,
  ) {}

  public ngOnInit(): void {
    this.themeService.currentTheme$.pipe(takeUntil(this.destroyer$)).subscribe(theme => {
      this.currentTheme = theme;
      this.fullLogoUrl = `/assets/images/logo-${theme}.${this.environment.appBuild.clientFullLogoFormat}?cacheOff=${this.environment.appBuild.cacheOff}`;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
