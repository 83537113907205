import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isObservable, Observable, of, ReplaySubject} from 'rxjs';

export enum ESnackMessageTypes {
  Error = 'error',
  Success = 'success',
  Message = 'message',
}

export interface ISnackData {
  type?: ESnackMessageTypes;
  message: string;
  duration?: number;
}

@Injectable()
export class SnackbarService {
  public info = new ReplaySubject<ISnackData>();

  constructor(private readonly translate: TranslateService) {}

  public next(config: ISnackData): void {
    this.info.next(config);
  }

  public success(m: string | Observable<string>): void {
    SnackbarService.fix(m).subscribe(message => this.next({message, type: ESnackMessageTypes.Success}));
  }

  public error(m: string | Observable<string>): void {
    SnackbarService.fix(m).subscribe(message => this.next({message, type: ESnackMessageTypes.Error}));
  }

  public message(m: string | Observable<string>): void {
    SnackbarService.fix(m).subscribe(message => this.next({message, type: ESnackMessageTypes.Message}));
  }

  public errorTranslate(m: string): void {
    this.translate.get(m).subscribe((message: string) => this.next({message, type: ESnackMessageTypes.Error}));
  }

  public successTranslate(m: string): void {
    this.translate.get(m).subscribe((message: string) => this.next({message, type: ESnackMessageTypes.Success}));
  }

  public messageTranslate(m: string): void {
    this.translate.get(m).subscribe((message: string) => this.next({message, type: ESnackMessageTypes.Message}));
  }

  private static fix(m: string | Observable<string>): Observable<string> {
    return isObservable(m) ? m : of(m);
  }
}
