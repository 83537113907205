import {OverlayRef, Overlay, OverlayPositionBuilder} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';
import {Directive, Input, OnInit, ElementRef, HostListener, ViewContainerRef} from '@angular/core';
import {ATariffTooltip} from '@app/pbsr/shared/tariff-tooltip/tariff-tooltip.abstract';

@Directive({
  selector: '[htmlTooltip]',
})
export class HtmlTooltipDirective implements OnInit {
  @Input('htmlTooltip')
  public tooltipContent: ComponentType<ATariffTooltip>;

  @Input()
  public optionId!: number;

  @Input()
  public isMobile = false;

  private overlayRef: OverlayRef;

  constructor(
    private readonly overlay: Overlay,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  public ngOnInit(): void {
    if (!this.tooltipContent) {
      return;
    }

    const position = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'center',
        overlayX: 'center',
        overlayY: 'top',
        offsetX: 0,
        offsetY: 8,
      },
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'center',
        offsetX: 0,
        offsetY: -8,
      },
    ]);

    this.overlayRef = this.overlay.create({
      positionStrategy: position,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: 'pbsr-tooltip',
    });
  }

  @HostListener('mouseenter')
  public show(): void {
    if (this.overlayRef && !this.isMobile) {
      const containerPortal = new ComponentPortal(this.tooltipContent, this.viewContainerRef);
      const componentRef = this.overlayRef.attach(containerPortal);

      componentRef.instance.optionId = this.optionId;
    }
  }

  @HostListener('mouseout')
  public hide(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
