import {Injectable} from '@angular/core';
import {WarpInstrumentHelper} from '@app/pbsr/helpers/warp-instrument.helper';
import {EBaseCurrency} from '@app/trading-board/enum/moex/base-currency';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import * as _ from 'lodash-es';
import {Observable, combineLatest, map, of, shareReplay} from 'rxjs';

import {WarpApiService} from '../warp-api.service';

@Injectable({providedIn: 'root'})
export class WarpInstrumentsStoreService {
  private readonly instrumentsRequestsMap = new Map<string, Observable<WarpInstrument>>();

  constructor(private readonly warpApiService: WarpApiService) {}

  public getInstrumentsForPortfolio(portfolioSymbols: string[]): Observable<WarpInstrument[]> {
    const symbolsWithoutRubPosition = portfolioSymbols.filter(symbol => symbol !== EBaseCurrency.Rub);
    const symbols = [...symbolsWithoutRubPosition, ...WarpInstrumentHelper.CURRENCIES_TO_GET_RATE];

    return this.getInstruments(symbols);
  }

  public getInstruments(symbols: string[]): Observable<WarpInstrument[]> {
    if (symbols.length === 0) {
      return of([]);
    }

    const instrumentsStreams = _.uniq(symbols).map(symbol => this.getInstrument(symbol));

    return combineLatest(instrumentsStreams).pipe(
      map(instruments => instruments.filter(instrument => Boolean(instrument))),
    );
  }

  public getInstrument(symbol: string): Observable<WarpInstrument | undefined> {
    if (!this.instrumentsRequestsMap.has(symbol)) {
      const instrument$ = this.fetchInstrument(symbol).pipe(shareReplay(1));

      this.instrumentsRequestsMap.set(symbol, instrument$);
    }

    return this.instrumentsRequestsMap.get(symbol);
  }

  public clear(): void {
    this.instrumentsRequestsMap.clear();
  }

  private fetchInstrument(symbol: string): Observable<WarpInstrument> {
    return this.warpApiService.getInstrumentsBySymbol(symbol);
  }
}
