import {Injectable} from '@angular/core';
import {AGridsterDatafeedService} from '@app/gridster/datafeeds/gridster-datafeed-service.abstract';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {ESharedEventHandlers} from '@app/trading-board/event-handlers/shared/shared-event-handlers.enum';

@Injectable()
export class SharedAfterConnectService extends AEventHandler<void> {
  public type = ESharedEventHandlers.AfterConnect;

  constructor(private readonly datafeed: AGridsterDatafeedService) {
    super();
  }

  public handleMessage(): void {
    this.datafeed.afterConnect();
  }
}
