import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {OrderHistory} from '@app/trading-board/models/b2margin/order-history';

@Injectable()
export class B2MarginOrderHistoryService extends AEventHandler<OrderHistory[]> {
  public type = EEventHandlersReceived.OrderHistory;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: OrderHistory[]): void {
    this.b2marginDatafeedService.orderHistory$.next(
      this.b2marginDatafeedService.orderHistory$
        .getValue()
        .concat(resource)
        .sort((a, b) => b.time.getTime() - a.time.getTime()),
    );
  }
}
