import {TrackByFunction} from '@angular/core';
import {IPhoneNumberCountryCode} from '@app/core/models/phone-number-country-code';
import {CountryCode, getCountryCallingCode, getExampleNumber} from 'libphonenumber-js';
import * as examples from 'libphonenumber-js/examples.mobile.json';

import {countries} from './country-codes';

export class PhoneNumberHelper {
  public static getCountries(): IPhoneNumberCountryCode[] {
    return countries;
  }

  public static getExamplePhoneNumber(countryCode: string): string | undefined {
    const phoneNumber = getExampleNumber(countryCode as CountryCode, examples.default);
    if (phoneNumber) {
      return phoneNumber.formatInternational();
    }
  }

  public static getPhoneCode(countryCode: string): string {
    return getCountryCallingCode(countryCode as CountryCode).toString();
  }

  public static isPhoneCodesEqual(code1: string, code2: string): boolean {
    return PhoneNumberHelper.getPhoneCode(code1) === PhoneNumberHelper.getPhoneCode(code2);
  }

  public static getCountryByIso(iso: string): IPhoneNumberCountryCode | undefined {
    return PhoneNumberHelper.getCountries().find(country => country.iso2 === iso);
  }

  public static readonly trackByCountry: TrackByFunction<IPhoneNumberCountryCode> = (i, country) => country.name;
}
