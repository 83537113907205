import {Casts, CastTypes, Model} from '@app/core/model';
import {Limit} from '@app/core/models/verification/limit';

export class Level extends Model {
  public application;
  public caption: string;
  public description: string;
  public id: number;
  public index: number;
  public limits?: Limit[];
  public localizedCaption: string;
  public localizedDescription: string;

  public readonly casts: Casts = {
    application: [CastTypes.Any, CastTypes.Null],
    caption: [CastTypes.String],
    description: [CastTypes.String],
    id: [CastTypes.Number],
    index: [CastTypes.Number],
    limits: [[Limit], CastTypes.Null],
    localizedCaption: [CastTypes.String],
    localizedDescription: [CastTypes.String],
  };
}

export enum EAliasBlocked {
  Deposits = 'deposits_blocked',
  Withdrawals = 'withdrawals_blocked',
  Converter = 'converter_blocked',
  InternalTransfer = 'internal_transfer_blocked',
  Verification = 'verification_blocked',
}
