import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'protectString',
})
export class ProtectStringPipe implements PipeTransform {
  public transform(phone: string, startNumber = 6, endNumber = 2): string {
    if (!phone) {
      return;
    }

    const phoneLength = phone.length;
    const starsStart = phoneLength - startNumber;
    const starsEnd = phoneLength - endNumber;

    return phone.substring(0, starsStart) + '****' + phone.substring(starsEnd);
  }
}
