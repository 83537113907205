import {Settings} from '@app/core/models/user/settings';
import {User} from '@app/core/models/user/user';

export class UpdateUser {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly type = '[User] change';

  constructor(public payload: User) {}
}

export class UpdateUserSettings {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly type = '[User] update settings';

  constructor(public payload: Settings) {}
}
