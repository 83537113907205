import * as _ from 'lodash-es';
import {OperatorFunction, Observable, MonoTypeOperatorFunction} from 'rxjs';
import {filter, map} from 'rxjs/operators';

/**
 * Filter a stream value with undefined and null values and return the value wrapper into NonNullable util type.
 *
 * @returns Operator function.
 */
export function filterNil<T>(): OperatorFunction<T, NonNullable<T>> {
  return (source): Observable<NonNullable<T>> =>
    source.pipe(
      filter(val => !_.isNil(val)),
      map(val => val as NonNullable<T>),
    );
}

/**
 * Filter all values not equal to specific value.
 *
 * @param notEqualValue - Not equal value.
 * @returns Mono type operator function.
 */
export function filterNeq<P, T extends P>(notEqualValue: T): MonoTypeOperatorFunction<P> {
  return filter(val => val !== notEqualValue);
}
