<ng-container [formGroup]="formGroup">
  <div class="radio-group-wrapper">
    <mat-label *ngIf="field.label">{{ getLabel() | async }}</mat-label>

    <mat-radio-group [formControl]="control">
      <ng-container *ngFor="let option of options; trackBy: trackByValue">
        <mat-radio-button [value]="option.value">
          {{ getOptionLabel(option.name) | async }}
        </mat-radio-button>

        <mat-hint *ngIf="option.hint">
          {{ getOptionLabel(option.hint) | async }}
        </mat-hint>
      </ng-container>
    </mat-radio-group>

    <ng-container *ngIf="control.touched">
      <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
        {{ getError(err) | async }}
      </mat-error>
    </ng-container>
  </div>
</ng-container>
