import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-error-dialog',
  templateUrl: 'error-dialog.component.html',
  styleUrls: ['error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  public isTimerFinished = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: IErrorDialogData,
    private readonly dialogRef: MatDialogRef<void>,
  ) {}

  public onFinalTimer(): void {
    this.isTimerFinished = true;

    if (this.data.countdownFinal) {
      this.data.countdownFinal();
    }
  }

  public accentClick(): void {
    this.data.onAccentButton();
    this.dialogRef.close(true);
  }

  public primaryClick(): void {
    this.data.onPrimaryButton();
    this.dialogRef.close(false);
  }
}

export interface IErrorDialogData {
  header: Observable<string>;
  text?: Observable<string>;
  emoji?: string;
  textAccentButton?: Observable<string>;
  textPrimaryButton?: Observable<string>;
  onAccentButton?: () => void;
  onPrimaryButton?: () => void;
  countdownText?: Observable<string>;
  countdownTime?: number;
  countdownFinal?: () => void;
}
