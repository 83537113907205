import {Injectable, Type} from '@angular/core';
import {ListComponent} from '@app/trading-board/components/select-instrument/list/list.component';
import {ETradingBoardProviderAlias} from '@app/trading-board/enum/provider-alias';
import {AComponentResolver, TEntryPoint} from '@app/trading-board/services/component-resolver.abstract';
import {OpenOrdersComponent} from '@app/trading-board/widgets/open-orders/open-orders.component';
import {OrderHistoryComponent} from '@app/trading-board/widgets/order-history/order-history.component';
import {TradesHistoryComponent} from '@app/trading-board/widgets/trades-history/trades-history.component';
import {TradesComponent} from '@app/trading-board/widgets/trades/trades.component';
import {WatchListComponent} from '@app/trading-board/widgets/watch-list/watch-list.component';

import {SymbolLibraryComponent} from '../components/symbol-library/symbol-library.component';
import {B2marginSpotOpenOrdersComponent} from '../widgets/open-orders/b2marginSpot/b2marginSpot-open-orders.component';
import {B2MarginSpotOrderHistoryComponent} from '../widgets/order-history/b2marginSpot/b2marginSpot-order-history.component';
import {B2marginSpotTradesHistoryComponent} from '../widgets/trades-history/b2marginSpot/b2marginSpot-trades-history.component';
import {B2marginSpotTradesComponent} from '../widgets/trades/b2marginSpot/b2marginSpot-trades.component';
import {B2marginSpotWatchListComponent} from '../widgets/watch-list/b2marginSpot/b2marginSpot-watch-list.component';

@Injectable()
export class B2marginSpotComponentsResolverService extends AComponentResolver {
  private static readonly symbolLibrary = (): Promise<{entryPoint: typeof SymbolLibraryComponent}> =>
    import('../components/symbol-library/symbol-library.component').then(m => ({
      entryPoint: m.SymbolLibraryComponent,
    }));

  private static readonly watchList = (): Promise<{entryPoint: typeof B2marginSpotWatchListComponent}> =>
    import('../widgets/watch-list/b2marginSpot/b2marginSpot-watch-list.component').then(m => ({
      entryPoint: m.B2marginSpotWatchListComponent,
    }));

  private static readonly openOrders = (): Promise<{entryPoint: typeof B2marginSpotOpenOrdersComponent}> =>
    import('../widgets/open-orders/b2marginSpot/b2marginSpot-open-orders.component').then(m => ({
      entryPoint: m.B2marginSpotOpenOrdersComponent,
    }));

  private static readonly orderHistory = (): Promise<{entryPoint: typeof B2MarginSpotOrderHistoryComponent}> =>
    import('../widgets/order-history/b2marginSpot/b2marginSpot-order-history.component').then(m => ({
      entryPoint: m.B2MarginSpotOrderHistoryComponent,
    }));

  private static readonly tradesHistory = (): Promise<{entryPoint: typeof B2marginSpotTradesHistoryComponent}> =>
    import('../widgets/trades-history/b2marginSpot/b2marginSpot-trades-history.component').then(m => ({
      entryPoint: m.B2marginSpotTradesHistoryComponent,
    }));

  private static readonly trades = (): Promise<{entryPoint: typeof B2marginSpotTradesComponent}> =>
    import('../widgets/trades/b2marginSpot/b2marginSpot-trades.component').then(m => ({
      entryPoint: m.B2marginSpotTradesComponent,
    }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly components = new Map<Type<unknown>, TEntryPoint<any>>([
    [ListComponent, B2marginSpotComponentsResolverService.symbolLibrary],
    [WatchListComponent, B2marginSpotComponentsResolverService.watchList],
    [OpenOrdersComponent, B2marginSpotComponentsResolverService.openOrders],
    [OrderHistoryComponent, B2marginSpotComponentsResolverService.orderHistory],
    [TradesHistoryComponent, B2marginSpotComponentsResolverService.tradesHistory],
    [TradesComponent, B2marginSpotComponentsResolverService.trades],
  ]);

  public readonly type = ETradingBoardProviderAlias.se;

  public getComponent<T>(type: Type<unknown>): TEntryPoint<T> | undefined {
    return this.components.get(type);
  }
}
