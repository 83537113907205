import {Type} from '@angular/core';
import '../event-handlers/b2margin/internal';
import {TokenResponse} from '@app/core/models/auth/token-response';
import {MarginRatesInternalEventHandler} from '@app/trading-board/event-handlers/b2margin/internal';
import {ESharedEventHandlers} from '@app/trading-board/event-handlers/shared/shared-event-handlers.enum';
import {B2marginAuth} from '@app/trading-board/models/b2margin/b2margin-auth';
import {B2marginAuthService} from '@app/trading-board/services/b2margin-auth.service';
import {TradingBoardLoadingService} from '@app/trading-board/services/trading-board-loading.service';
import {InstrumentInfoTO, ProtectionOrderTO} from '@b2broker/b2margin-trade-models';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
// TODO: revert to using atmosphere.js (https://b2btech.atlassian.net/browse/FDP-12464)
// import * as Atmosphere from 'atmosphere.js';
import {plainToClass} from 'class-transformer';
import {Observable, Subject, firstValueFrom} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

import {EChartRequestAction} from '../enum/b2margin/chart-request-action';
import {EWorkerEventTypes} from '../enum/worker-event-types';
import {b2MarginInternalEventHandlers, AEventHandler} from '../event-handlers/event-handler.abstract';
import {EEventHandlersReceived, EEventHandlersRequest} from '../event-handlers/event-handlers.enum';
import {B2marginStateStore} from '../facades/b2margin-state-store';
import {IB2marginMessage} from '../interfaces/b2margin/b2margin-message';
import {IChartRequest} from '../interfaces/b2margin/chart-request';
import {ICloseInstrumentPositionsOptions} from '../interfaces/b2margin/close-instrument-positions-options';
import {IClosePositionOptions} from '../interfaces/b2margin/close-position-options';
import {IOrderData} from '../interfaces/b2margin/order-data';
import {IDataEvent} from '../interfaces/data-event.ts';
import {ISingleRequestMessage} from '../interfaces/single-request-message';
import {B2MarginOpenOrder} from '../models/b2margin/b2-margin-open-order';
import {B2marginChartRequest} from '../models/b2margin/b2margin-chart-request';
import {AB2marginOrder} from '../models/b2margin/b2margin-order';
import {InstrumentSession} from '../models/b2margin/instrument-session';
import {Position} from '../models/b2margin/position';
import {ADatafeedFacade} from './datafeed-facade.abstract';

export class B2marginDatafeedFacade extends ADatafeedFacade {
  private socket: Atmosphere.Atmosphere;
  private atmosphereRequest: Atmosphere.Request;
  private connectionId: string;
  private authData?: B2marginAuth;
  private get headers(): HeadersInit {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Atmosphere-tracking-id': this.connectionId?.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-type': 'application/json',
    };
  }

  protected readonly store = new B2marginStateStore();
  protected eventHandlers: AEventHandler<unknown>[];

  public get messages$(): Subject<IDataEvent> {
    return this.store.messages$;
  }

  constructor(
    protected readonly tradingBoardLoadingService: TradingBoardLoadingService,
    protected readonly b2marginAuthService: B2marginAuthService,
  ) {
    super();

    this.eventHandlers = b2MarginInternalEventHandlers.map(
      b2MarginInternalEventHandler => new b2MarginInternalEventHandler(this.store, this.findHandlerInstance.bind(this)),
    );

    this.store.visibleInstruments$.subscribe(instruments => {
      const symbolsToAdd = instruments.map(({symbolWithSeparator}) => symbolWithSeparator);
      void this.requestMarketData(symbolsToAdd);
    });
  }

  private async selectAccount(accountId: string): Promise<void> {
    await this.saveAccount({accountId});
    await firstValueFrom(this.post<void>(`accounts/switch?accountId=${accountId}`, {}));

    this.store.tradeHistory$.next([]);
    this.store.historyOrders$.next([]);
    await this.requestOrdersHistory();
  }

  private async requestOrdersHistory(): Promise<void> {
    await firstValueFrom(this.post<void>('orders/history', {}));
    await firstValueFrom(this.post<void>('history', {}));
    await firstValueFrom(this.post<void>(`trades/history?from=1573419600000&to=${new Date().getTime()}`, {}));
    await firstValueFrom(this.post<void>('tradingcalendar', {}));
  }

  private saveAccount(settings: Record<string, string>): Promise<void> {
    return firstValueFrom(this.put<void>('users/settings', settings));
  }

  private messageHandler(response: Atmosphere.Response): void {
    const message = JSON.parse(response.responseBody) as IB2marginMessage;

    this.eventHandlers.forEach(handler => {
      const isType = Array.isArray(handler.type) ? handler.type.includes(message.type) : handler.type === message.type;

      if (isType) {
        handler.handleMessage(message);
      }
    });
  }

  private async afterConnect(): Promise<void> {
    this.connectionId = this.atmosphereRequest.getUUID() as unknown as string;

    await this.requestOrdersHistory();
    this.tradingBoardLoadingService.finish();
  }

  private requestMarketDepth(data?: Partial<B2MarginModels.UpdateMarketSymbolsSubscriptionTO>): void {
    this.put<void>('marketdepth', {
      symbolsToAdd: (data?.symbolsToAdd ?? []).map(symbol => ({modelFilter: 'AGGREGATE', symbol})),
      symbolsToRemove: (data?.symbolsToRemove ?? []).map(symbol => ({modelFilter: 'AGGREGATE', symbol})),
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe();
  }

  private requestMarginImpact(payload: Partial<B2MarginModels.UpdateMarketSymbolsSubscriptionTO>): Promise<void> {
    return firstValueFrom(
      this.post<void>('marginImpacts/symbols', {
        symbolsToAdd: payload.symbolsToAdd || [],
        symbolsToRemove: payload.symbolsToRemove || [],
      }),
    );
  }

  private createOrder(payload: ISingleRequestMessage<IOrderData>): void {
    this.post<{
      // eslint-disable-next-line @typescript-eslint/naming-convention
      success: boolean;
    }>('orders/single', B2MarginOpenOrder.adaptToCreate(payload.data))
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response =>
        this.messages$.next({
          type: EEventHandlersRequest.CreateOrder,
          payload: {
            id: payload.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            data: response.success,
          },
        }),
      );
  }

  private requestRates(payload: Partial<B2MarginModels.UpdateMarketSymbolsSubscriptionTO>): void {
    this.put<void>('conversionrates', payload).pipe(takeUntil(this.destroyer$)).subscribe();
  }

  private requestPositionMarginImpact(payload: Partial<B2MarginModels.UpdatePositionKeysSubscriptionTO>): void {
    this.post<void>('marginImpacts/positions', {
      positionKeysToAdd: payload.positionKeysToAdd || [],
      positionKeysToRemove: payload.positionKeysToRemove || [],
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe();
  }

  private closePosition(payload: ISingleRequestMessage<IClosePositionOptions>): void {
    const {data, id} = payload;
    const position = this.store.positions$.getValue().get(data.positionId);
    const instrument = this.store.instruments$.getValue().get(position.positionKey.instrumentId);

    const quantity = Position.getPositionQuantity(position, data.amount, instrument.lotSize);

    this.post<void>('positions/close', {
      legs: [
        {
          instrumentId: instrument.id,
          positionCode: position.positionKey.positionCode,
          positionEffect: 'CLOSING',
          ratioQuantity: 1,
          symbol: instrument.symbol,
        },
      ],
      limitPrice: data.currentPrice,
      orderType: 'MARKET',
      quantity,
      timeInForce: 'GTC',
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() => this.messages$.next({type: EEventHandlersRequest.ClosePosition, payload: {id}}));
  }

  private modifyProtectionOrders(
    payload: ISingleRequestMessage<{positionId: string; protectionOrders: ProtectionOrderTO[]}>,
  ): void {
    const {
      data: {positionId, protectionOrders},
      id,
    } = payload;

    const position: B2MarginModels.PositionTO = this.store.positions$.getValue().get(positionId);

    this.put<void>('positions/protections', {
      positionKey: position?.positionKey,
      protectionOrders: protectionOrders.map(p => AB2marginOrder.mapProtectionOrderTo(p)),
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() => this.messages$.next({type: EEventHandlersRequest.ModifyProtectionOrders, payload: {id}}));
  }

  private requestChart({subtopic, symbol, resolution, id, action}: IChartRequest): void {
    if (action === EChartRequestAction.Subscribe) {
      const data: B2MarginModels.UpdateChartDataSubscriptionTO = {
        chartIds: [subtopic],
        requests: [new B2marginChartRequest(id, subtopic, symbol, resolution)],
      };

      void firstValueFrom(this.put('charts', data));

      return;
    }

    void firstValueFrom(this.put('charts', {chartIds: [subtopic], requests: []}));
  }

  private closeOrder(payload: ISingleRequestMessage<{orderId: number; accountId: string}>): void {
    this.delete<{
      // eslint-disable-next-line @typescript-eslint/naming-convention
      success: boolean;
    }>('orders/cancel', {
      orderChainId: payload.data.orderId.toString(),
      accountId: payload.data.accountId,
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response =>
        this.messages$.next({
          type: EEventHandlersRequest.CloseOrder,
          payload: {
            id: payload.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            data: response.success,
          },
        }),
      );
  }

  private getInstrumentSession(symbol: string): void {
    this.get<InstrumentInfoTO>('instruments/info', {
      symbol,
      timezoneOffset: new Date().getTimezoneOffset().toString(),
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe(session =>
        this.messages$.next({
          type: EEventHandlersReceived.InstrumentSessions,
          payload: plainToClass(InstrumentSession, session),
        }),
      );
  }

  private modifyOrder(payload: ISingleRequestMessage<IOrderData>): void {
    this.put<{
      // eslint-disable-next-line @typescript-eslint/naming-convention
      success: boolean;
    }>('orders/single', B2MarginOpenOrder.adaptToCreate(payload.data))
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response =>
        this.messages$.next({
          type: EEventHandlersRequest.ModifyOrder,
          payload: {
            id: payload.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            data: response.success,
          },
        }),
      );
  }

  private getTrades(payload: Partial<B2MarginModels.UpdateMarketSymbolsSubscriptionTO>): void {
    this.put<void>('timeandsale', {
      symbolsToAdd: payload.symbolsToAdd || [],
      symbolsToRemove: payload.symbolsToRemove || [],
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe();
  }

  private requestMarginRates(symbol: string): void {
    this.get<Partial<B2MarginModels.InstrumentMarginRatesTO>>('instruments/marginRates', {symbol})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(data => this.findHandlerInstance(MarginRatesInternalEventHandler)?.handleMessage(data));
  }

  private closeAllPositions({id}: ISingleRequestMessage): void {
    this.post<void>('positions/liquidate', {})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() => this.messages$.next({type: EEventHandlersRequest.CloseAllPositions, payload: {id}}));
  }

  private closeAllOrders({id}: ISingleRequestMessage): void {
    this.post<void>('orders/liquidate', {})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() => this.messages$.next({type: EEventHandlersRequest.CloseAllOrders, payload: {id}}));
  }

  protected requestMarketData(symbolsToAdd: string[]): Promise<void> {
    return firstValueFrom(this.put<void>('marketdata', {symbolsToAdd, symbolsToRemove: []}));
  }

  protected findHandlerInstance<T>(type: Type<T>): AEventHandler<unknown> {
    return this.eventHandlers.find(item => item instanceof type);
  }

  protected async connect(): Promise<void> {
    this.tradingBoardLoadingService.start();
    this.authData = await firstValueFrom(this.b2marginAuthService.apiData$.pipe(first()));

    if (!this.authData) {
      this.messages$.next({type: EEventHandlersReceived.Error, payload: 'No b2margin accounts'});

      return;
    }

    this.baseUrl = this.authData.requestUrl;
    this.apiUrl = `${this.baseUrl}api`;

    return new Promise((resolve, reject) => {
      // TODO: revert to using atmosphere.js (https://b2btech.atlassian.net/browse/FDP-12464)
      this.socket = null;
      this.atmosphereRequest = this.socket.subscribe({
        url: `${this.baseUrl}client/connector`,
        transport: 'websocket',
        headers: {sessionState: 'dx-new'},
        // eslint-disable-next-line @typescript-eslint/naming-convention
        trackMessageLength: true,
        maxReconnectOnClose: 3,
        reconnectInterval: 1000,
        withCredentials: true,
        onMessage: m => this.messageHandler(m),
        onOpen: () => {
          void this.afterConnect();
          this.messages$.next({type: ESharedEventHandlers.AfterConnect});
          resolve();
        },
        onError: error => {
          this.store.reset();
          this.tradingBoardLoadingService.finish();
          this.messages$.next({type: EEventHandlersReceived.Error, payload: error});
          reject();
        },
        onClose: message => {
          this.store.reset();
          this.messages$.next({type: EEventHandlersReceived.Close, payload: message});
        },
      });
    });
  }

  protected get<T>(url: string, params?: {[param: string]: string}): Observable<T> {
    return super.get<T>(url, {params, headers: this.headers});
  }

  protected post<T>(url: string, body: unknown): Observable<T> {
    return super.post<T>(url, body, {headers: this.headers});
  }

  protected put<T>(url: string, body: unknown): Observable<T> {
    return super.put<T>(url, body, {headers: this.headers});
  }

  protected delete<T>(url: string, params?: {[param: string]: string}): Observable<T> {
    return super.delete<T>(url, {params, headers: this.headers});
  }

  public close(): void {
    this.destroyer$.next();
    this.socket?.unsubscribe();
    this.atmosphereRequest?.disconnect();
  }

  public closeInstrumentPositions({id, data}: ISingleRequestMessage<ICloseInstrumentPositionsOptions>): void {
    void firstValueFrom(this.post<void>(`positions/${data.instrumentId}/liquidate`, {})).then(() => {
      this.messages$.next({
        type: EEventHandlersRequest.CloseInstrumentPositions,
        payload: {
          id,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          data: true,
        },
      });
    });
  }

  public postMessage(message: IDataEvent): void {
    switch (message.type) {
      case EWorkerEventTypes.Connect:
        void this.connect();
        break;

      case EEventHandlersRequest.Marketdepth:
        this.requestMarketDepth(message.payload);
        break;

      case EEventHandlersRequest.SelectAccount:
        void this.selectAccount(message.payload as string);
        break;

      case EEventHandlersRequest.MarginImpact:
        void this.requestMarginImpact(message.payload);
        break;

      case EEventHandlersRequest.PositionMarginImpact:
        this.requestPositionMarginImpact(message.payload);
        break;

      case EEventHandlersRequest.MarginRates:
        this.requestMarginRates(message.payload as string);
        break;

      case EEventHandlersRequest.CreateOrder:
        this.createOrder(message.payload as ISingleRequestMessage<IOrderData>);
        break;

      case EEventHandlersRequest.Rates:
        this.requestRates(message.payload);
        break;

      case EEventHandlersRequest.ClosePosition:
        this.closePosition(message.payload as ISingleRequestMessage<IClosePositionOptions>);
        break;

      case EEventHandlersRequest.ModifyProtectionOrders:
        this.modifyProtectionOrders(
          message.payload as ISingleRequestMessage<{positionId: string; protectionOrders: ProtectionOrderTO[]}>,
        );
        break;

      case EEventHandlersRequest.Chart:
        this.requestChart(message.payload as IChartRequest);
        break;

      case EEventHandlersRequest.CloseOrder:
        this.closeOrder(message.payload as ISingleRequestMessage<{orderId: number; accountId: string}>);
        break;

      case EEventHandlersRequest.InstrumentSessions:
        this.getInstrumentSession(message.payload as string);
        break;

      case EEventHandlersRequest.ModifyOrder:
        this.modifyOrder(message.payload as ISingleRequestMessage<IOrderData>);
        break;

      case EEventHandlersRequest.Trades:
        this.getTrades(message.payload);
        break;

      case EEventHandlersRequest.CloseAllPositions:
        this.closeAllPositions(message.payload as ISingleRequestMessage<unknown>);
        break;

      case EEventHandlersRequest.CloseAllOrders:
        this.closeAllOrders(message.payload as ISingleRequestMessage<unknown>);
        break;

      case EEventHandlersRequest.CloseInstrumentPositions:
        this.closeInstrumentPositions(message.payload as ISingleRequestMessage<ICloseInstrumentPositionsOptions>);
        break;

      case ESharedEventHandlers.EndRefreshTokens:
        this.messages$.next({
          type: ESharedEventHandlers.EndRefreshTokens,
          payload: message.payload as TokenResponse,
        });
        break;

      default:
        return;
    }
  }
}
