import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  public transform<T>(value: T[], ...filterFunctions: ((item: T) => boolean)[]): T[] {
    if (!value || !value.filter) {
      return [];
    }
    if (!filterFunctions) {
      return value;
    }
    return value.filter(i => !filterFunctions.find(f => !f(i)));
  }
}
