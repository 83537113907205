import {CommonModule} from '@angular/common';
import {Directive, HostListener, Input, NgModule} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import * as _ from 'lodash-es';

@Directive({
  selector: '[tooltipIfLongLabel]',
})
export class TooltipIfLongLabelDirective extends MatTooltip {
  @Input('tooltipIfLongLabel')
  public label: HTMLElement;

  @Input()
  public hasReqiredIfRule = false;

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.message = this.isShowTooltip() ? this.getTooltipText() : '';

    this.show();
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.hide();
  }

  private isShowTooltip(): boolean {
    return (this.label.offsetParent as HTMLElement).offsetWidth < this.label.offsetWidth;
  }

  private getTooltipText(): string {
    const textContent = this.label.textContent;
    const text = this.hasReqiredIfRule && _.last(textContent) === '*' ? textContent.slice(0, -1) : textContent;

    return text;
  }
}

@NgModule({
  declarations: [TooltipIfLongLabelDirective],
  imports: [CommonModule],
  exports: [TooltipIfLongLabelDirective],
})
export class TooltipIfLongLabelModule {}
