import {Injectable} from '@angular/core';
import {IMenuPointDto} from '@app/core/menu/models/menu-point.dto';
import {AdminMenuSettingsApiService} from '@app/core/services/configs/api/admin-menu-settings-api.service';
import {shareReplayWithRef} from '@app/core/utils/share-replay-with-ref';
import {Environment} from '@env/environment.entities';
import {BehaviorSubject, iif, of} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeneralConfigService {
  private readonly configState$ = new BehaviorSubject<IMenuPointDto | null>(null);

  public readonly generalConfigInfo$ = this.configState$.pipe(
    mergeMap(data =>
      iif(
        () => !!data || this.environment.isB2TraderStandalone,
        of(data),
        this.adminMenuSettingsApiService.getGeneralInfo().pipe(tap(info => this.configState$.next(info))),
      ),
    ),
    shareReplayWithRef(),
  );

  public readonly isInternalTransferAvailable = new BehaviorSubject<boolean | null>(null);

  constructor(
    private readonly adminMenuSettingsApiService: AdminMenuSettingsApiService,
    private readonly environment: Environment,
  ) {}

  public refreshGeneralConfigInfo(): void {
    this.adminMenuSettingsApiService.getGeneralInfo().subscribe(info => this.configState$.next(info));
  }
}
