import DeviceFingerprintBuilder from '@app/core/device-fingerprint/classes/device-fingerprint-builder';
import {Base64} from 'js-base64';

export abstract class ADeviceFingerprint {
  public static build(): string {
    const fingerprint = new DeviceFingerprintBuilder()
      .addCanvasFingerprint()
      .addNavigatorInfo()
      .addTimezoneInfo()
      .addWebGlInfo()
      .build();

    return Base64.encode(JSON.stringify(fingerprint));
  }
}
