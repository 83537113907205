import {Bar as IBar} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {Expose, Transform} from 'class-transformer';

export class Bar implements IBar {
  @Expose()
  public time: number;

  @Expose()
  @Transform(({value}) => Number(value))
  public open: number;

  @Expose()
  @Transform(({value}) => Number(value))
  public high: number;

  @Expose()
  @Transform(({value}) => Number(value))
  public low: number;

  @Expose()
  @Transform(({value}) => Number(value))
  public close: number;

  @Expose()
  @Transform(({value}) => Number(value))
  public volume?: number;
}
