export enum EB2TraderEventHandlersRequest {
  Book = 'book',
  SubscribeChart = 'subscribeChart',
  UnsubscribeChart = 'unsubscribeChart',
  ChartHistory = 'chartHistory',
  CloseOrder = 'closeOrder',
  CloseStopOrder = 'closeStopOrder',
  HistoryOrder = 'historyOrder',
  StopOrders = 'stopOrders',
  Trades = 'trades',
  CreateOrder = 'createOrder',
  CreateStopOrder = 'createStopOrder',
  SubscribeOrderBook = 'subscribeOrderBook',
  UnsubscribeOrderBook = 'unsubscribeOrderBook',
  PublicLogin = 'publicLogin',
  PublicLogout = 'publicLogout',
}

export enum EB2TraderEventHandlersReceived {
  Instruments = 'instruments',
  Level1 = 'level1',
  Level2 = 'level2',
  SubscribeChart = 'subscribeChart',
  ChartHistory = 'chartHistory',
  Orders = 'orders',
  StopOrders = 'stopOrders',
  Trades = 'trades',
  Balances = 'balances',
  Tiers = 'tiers',
  RedirectHome = 'redirectHome',
  Connected = 'connected',
  ServerTime = 'serverTime',
}
