import {Casts, CastTypes, Model} from '@app/core/model';
import {Message} from '@app/helpdesk/models/message';
import {MessageNotificationEvent} from '@app/helpdesk/models/message-notification-event';
import {Ticket} from '@app/helpdesk/models/ticket';

export class HelpdeskData extends Model {
  public message?: string;
  public item?: Message | Ticket | MessageNotificationEvent;

  public readonly casts: Casts = {
    message: [CastTypes.Null, CastTypes.String],
    item: [CastTypes.Null, Message, Ticket, MessageNotificationEvent, CastTypes.Any],
  };
}
