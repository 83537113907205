import {Expose, Type} from 'class-transformer';

import {LanguageFormat} from './language-format';

export class Language {
  @Expose()
  public locale: string;

  @Expose()
  public lang: string;

  @Expose()
  public caption: string;

  @Expose()
  public localizedCaption: string;

  @Expose()
  @Type(() => LanguageFormat)
  public format: LanguageFormat;

  @Expose()
  public rtl: number;

  @Expose()
  public priority?: number;
}
