import {Injectable, Provider} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {Environment} from '@env/environment.entities';

import {SettingsManager} from './settings-manager';
import {ApiStorageService} from './storages/api-storage.service';
import {LocalStorageService} from './storages/local-storage.service';
import {AStorage} from './storages/storage';

@Injectable()
export class SettingsStorageService {
  constructor(private readonly storage: AStorage) {}

  public get<T>(id: string): SettingsManager<T> {
    return new SettingsManager<T>(id, this.storage);
  }
}

export const SettingsStorageProvider: Provider = {
  provide: AStorage,
  useFactory: (env: Environment, connection: ConnectionService) => {
    return env.isPublic || env.isB2TraderStandalone ? new LocalStorageService() : new ApiStorageService(connection);
  },
  deps: [Environment, ConnectionService],
};
