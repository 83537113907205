import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {MarginRate} from '@app/trading-board/models/b2margin/margin-rate';

@Injectable()
export class MarginRateService extends AEventHandler<MarginRate> {
  public type = EEventHandlersReceived.MarginRates;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: MarginRate): void {
    this.b2marginDatafeedService.marginRates.get(resource.symbol)?.stream.next(resource);
  }
}
