import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {betterThrottle} from '@app/core/utils/better-throttle';
import {PORTFOLIO_META_UPDATE_INTERVAL_MS} from '@app/pbsr/consts/portfolio-meta-update-interval.const';
import {WarpInstrumentHelper} from '@app/pbsr/helpers/warp-instrument.helper';
import {IAccountChangesInfo} from '@app/pbsr/interfaces/account-changes-info.interface';
import {TPbsAccount} from '@app/pbsr/types/pbs-account.type';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {EBaseCurrency} from '@app/trading-board/enum/moex/base-currency';
import {IParentAccount} from '@app/trading-board/interfaces/moex/moex-accounts-info';
import {IPortfolioMeta} from '@app/trading-board/interfaces/moex/portfolio-meta';
import {ISymbolData} from '@app/trading-board/interfaces/moex/symbol-info';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import {Tick} from '@app/trading-board/models/level1';
import {PortfolioMeta} from '@app/trading-board/models/moex/portfolio-meta';
import {PortfolioPosition} from '@app/trading-board/models/moex/portfolio-position';
import {plainToInstance} from 'class-transformer';
import * as _ from 'lodash-es';
import {combineLatest, defer, Observable} from 'rxjs';
import {delay, filter, map, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class MyAccountsPortfolioService {
  private readonly baseCurrencies = Object.values(EBaseCurrency);

  private portfolioMeta: PortfolioMeta[] = [];
  private tradeCodeToPositions: Record<string, PortfolioPosition[]>;

  constructor(private readonly datafeed: MoexDatafeedService, private readonly connectionService: ConnectionService) {}

  public updatePortfoliosTotals(
    parentAccounts: IParentAccount[],
    quotes: Map<string, Tick>,
    insruments: WarpInstrument[],
  ): IParentAccount[] {
    const eurUsdInstrument = insruments.find(insrument => insrument.symbol === WarpInstrumentHelper.EUR_USD_PAIR);
    const eurUsdLastPrice = quotes.get(eurUsdInstrument?.symbol)?.lastTradedPx?.value ?? 1;

    parentAccounts.forEach(parentAccount => {
      const accountQuoteInstrument = insruments.find(
        insrument => insrument.symbol === WarpInstrumentHelper.getCurrencySymbol(parentAccount.baseCurrency),
      );
      const accountCurrencyLastPrice = quotes.get(accountQuoteInstrument?.symbol)?.lastTradedPx;
      let totalPortfolioPrice = DecimalHelper.from(0);

      parentAccount.subAccounts.forEach(subAccount => {
        const subAccountQuoteInstrument = insruments.find(
          insrument => insrument.symbol === WarpInstrumentHelper.getCurrencySymbol(subAccount.baseCurrency),
        );
        const subAccountCurrencyLastPrice = quotes.get(subAccountQuoteInstrument?.symbol)?.lastTradedPx;
        let totalSubAccountPrice = DecimalHelper.from(0);

        subAccount.symbolsInfo?.data.forEach(data => {
          if (
            subAccount.symbolsInfo.portfolioId === subAccount.subAccId ||
            subAccount.symbolsInfo.portfolioId === subAccount.tradeCode
          ) {
            const price = this.getPriceAccordingToFacevalue(data, insruments);
            const qtyUnitsMultiplyLastPrice = data.qtyUnits.multiply(price);

            totalPortfolioPrice = totalPortfolioPrice.plus(
              this.getUpdateForTotalPrice(
                parentAccount,
                data,
                eurUsdLastPrice,
                accountCurrencyLastPrice,
                qtyUnitsMultiplyLastPrice,
              ),
            );

            totalSubAccountPrice = totalSubAccountPrice.plus(
              this.getUpdateForTotalPrice(
                subAccount,
                data,
                eurUsdLastPrice,
                subAccountCurrencyLastPrice,
                qtyUnitsMultiplyLastPrice,
              ),
            );
          }
        });

        const subAccountMeta = this.portfolioMeta.find(
          portfolioMeta => portfolioMeta.subAccountId === subAccount.subAccId,
        );
        subAccount.totalPrice = totalSubAccountPrice;
        subAccount.totalChanged = subAccount.totalPrice.isZero()
          ? subAccount.totalPrice
          : subAccount.totalPrice.minus(subAccountMeta?.summary[subAccount.baseCurrency].toString() ?? 0);

        const difference = subAccount.totalPrice.minus(subAccount.totalChanged);
        subAccount.totalChangedInPercent = difference.isZero()
          ? subAccount.totalChanged
          : subAccount.totalChanged.divide(subAccount.totalPrice.minus(subAccount.totalChanged)).multiply(100);
      });

      const portfolioMeta = this.portfolioMeta.filter(meta => meta.portfolioId === parentAccount.subAccId);
      const portfolioMetaSum = portfolioMeta.reduce((acc, value) => {
        return acc.plus(value.summary[parentAccount.baseCurrency].toString());
      }, DecimalHelper.from(0));

      parentAccount.totalPrice = totalPortfolioPrice;
      parentAccount.totalChanged = parentAccount.totalPrice.minus(portfolioMetaSum);

      const difference = parentAccount.totalPrice.minus(parentAccount.totalChanged);
      parentAccount.totalChangedInPercent = difference.isZero()
        ? parentAccount.totalPrice
        : parentAccount.totalChanged.divide(difference).multiply(100);
    });

    return parentAccounts;
  }

  public updatePortfolioPositions(accounts: IParentAccount[]): Observable<Map<string, Tick>> {
    const subAccountTradesCodes = accounts.flatMap(parentAccount =>
      parentAccount.subAccounts?.map(subAccount => subAccount.tradeCode),
    );
    const portfolioStreams: Record<string, Observable<PortfolioPosition[]>> = subAccountTradesCodes.reduce(
      (account, tradesCodes) => {
        account[tradesCodes] = defer(() =>
          this.datafeed
            .subscribeToPortfolioPositions(tradesCodes)
            .pipe(map(positions => Array.from(positions.values()))),
        );

        return account;
      },
      {},
    );

    return combineLatest(portfolioStreams).pipe(
      tap(positionsByPortfolio => {
        this.tradeCodeToPositions = positionsByPortfolio;
      }),
      // We get elements into the socket sequentially, we need to wait for all positions to be filled
      delay(500),
      map(positionsByPortfolio => {
        const symbols = _.uniq(
          Object.values(positionsByPortfolio).flatMap(positions => positions.map(position => position.quoteSymbol)),
        );

        return symbols;
      }),
      tap(symbols => {
        this.datafeed.updateQuotesSubscriptions([], [...symbols, ...WarpInstrumentHelper.CURRENCIES_TO_GET_RATE]);
      }),
      switchMap(() => this.datafeed.quotes$),
      filter(quotes => quotes.size > 0),
      betterThrottle(PORTFOLIO_META_UPDATE_INTERVAL_MS),
      switchMap(quotes =>
        this.getPortfolioMeta().pipe(
          map(portfolioMeta => {
            return {portfolioMeta, quotes};
          }),
        ),
      ),
      map(({portfolioMeta, quotes}) => {
        this.portfolioMeta = portfolioMeta;

        accounts.forEach(parentAccount =>
          parentAccount.subAccounts.forEach(subAccount => {
            subAccount.symbolsInfo = this.generateSymbolData(
              subAccount.tradeCode,
              this.tradeCodeToPositions[subAccount.tradeCode],
              quotes,
            );
          }),
        );

        return quotes;
      }),
    );
  }

  public getAccountChangesInfo(
    accounts: IParentAccount[],
    quotes: Map<string, Tick>,
    instruments: WarpInstrument[],
  ): IAccountChangesInfo {
    const allSubAccounts = _.flatten(accounts.map(account => account.subAccounts));

    let totalChangedInPercent = DecimalHelper.from(0);
    let totalChanged = DecimalHelper.from(0);

    allSubAccounts.forEach(subAccount => {
      const subAccountQuoteInstrument = instruments.find(
        instrument => instrument.symbol === WarpInstrumentHelper.getCurrencySymbol(subAccount.baseCurrency),
      );
      const subAccountCurrencyLastPrice = quotes.get(subAccountQuoteInstrument?.symbol)?.lastTradedPx;
      let totalSubAccountPrice = DecimalHelper.from(0);

      subAccount.symbolsInfo?.data.forEach(data => {
        if (
          subAccount.symbolsInfo.portfolioId === subAccount.subAccId ||
          subAccount.symbolsInfo.portfolioId === subAccount.tradeCode
        ) {
          const price = this.getPriceAccordingToFacevalue(data, instruments);
          const qtyUnitsMultiplyLastPrice = data.qtyUnits.multiply(price);

          totalSubAccountPrice = totalSubAccountPrice.plus(
            qtyUnitsMultiplyLastPrice.divide(subAccountCurrencyLastPrice?.value ?? 1),
          );
        }
      });

      const subAccountMeta = this.portfolioMeta.find(
        portfolioMeta => portfolioMeta.subAccountId === subAccount.subAccId,
      );

      totalChanged = totalSubAccountPrice.isZero()
        ? totalChanged
        : totalSubAccountPrice.minus(subAccountMeta?.summary[subAccount.baseCurrency].toString() ?? 0);

      const difference = totalSubAccountPrice.isZero()
        ? totalSubAccountPrice
        : totalSubAccountPrice.minus(totalChanged);
      totalChangedInPercent = difference.isZero()
        ? totalChangedInPercent
        : totalChanged.divide(totalSubAccountPrice.minus(totalChanged)).multiply(100);
    });

    return {totalChanged: totalChanged.getFixed(2), totalChangedInPercent: totalChangedInPercent.getFixed(2)};
  }

  public getPortfolioMeta(): Observable<PortfolioMeta[]> {
    return this.connectionService.get<IPortfolioMeta>('/backoffice/api/v1/portfolioMeta').pipe(
      map(result => result.data.map(portfolioMeta => plainToInstance(PortfolioMeta, portfolioMeta.attributes))),
      tap(p => (this.portfolioMeta = p)),
    );
  }

  private generateSymbolData(
    portfolioId: string,
    portfolioPositions: PortfolioPosition[],
    symbolsQuotes: Map<string, Tick>,
  ): {portfolioId: string; data: ISymbolData[]} {
    const symbolsData: ISymbolData[] = [];

    portfolioPositions.forEach(portfolioPosition => {
      const symbolsQuote = symbolsQuotes.get(portfolioPosition.quoteSymbol);
      if (_.isNil(symbolsQuote) && portfolioPosition.symbol !== 'RUB') {
        return;
      }

      symbolsData.push({
        symbol: portfolioPosition.symbol,
        lastPrice: DecimalHelper.from(symbolsQuote ? symbolsQuote?.lastTradedPx.toString() : 0),
        avgPrice: DecimalHelper.from(portfolioPosition.avgPrice.toString()),
        qtyUnits: DecimalHelper.from(portfolioPosition.qtyUnits.toString()),
        isCurrency: portfolioPosition.isCurrency,
      });
    });

    return {
      portfolioId,
      data: symbolsData,
    };
  }

  private isPositionEqualsAccountBaseCurrency(symbol: string, accountBaseCurrency: string): boolean {
    return this.baseCurrencies.some(baseCurrency => baseCurrency === symbol && baseCurrency === accountBaseCurrency);
  }

  private isEurAndUsdAccount(account: TPbsAccount): boolean {
    return account.baseCurrency === EBaseCurrency.Eur || account.baseCurrency === EBaseCurrency.Usd;
  }

  private getUpdateForTotalPrice(
    account: TPbsAccount,
    data: ISymbolData,
    eurUsdLastPrice: string | number,
    accountCurrencyLastPrice: LazyDecimalHelper,
    qtyUnitsMultiplyLastPrice: DecimalHelper,
  ): DecimalHelper {
    let totalPrice: DecimalHelper;

    if (this.isPositionEqualsAccountBaseCurrency(data.symbol, account.baseCurrency)) {
      totalPrice = data.qtyUnits;
    } else if (
      (data.symbol === EBaseCurrency.Usd && account.baseCurrency === EBaseCurrency.Eur) ||
      (data.symbol === EBaseCurrency.Eur && account.baseCurrency === EBaseCurrency.Usd)
    ) {
      totalPrice =
        account.baseCurrency === EBaseCurrency.Usd
          ? data.qtyUnits.multiply(eurUsdLastPrice)
          : data.qtyUnits.divide(eurUsdLastPrice);
    } else {
      totalPrice = this.getAccountAssetPrice(
        account,
        accountCurrencyLastPrice,
        qtyUnitsMultiplyLastPrice,
        data.qtyUnits,
        data.isCurrency,
      );
    }

    return totalPrice;
  }

  private getAccountAssetPrice(
    account: TPbsAccount,
    accountCurrencyLastPrice: LazyDecimalHelper,
    qtyUnitsMultiplyLastPrice: DecimalHelper,
    qtyUnits: DecimalHelper,
    isCurrency = false,
  ): DecimalHelper {
    return this.isEurAndUsdAccount(account) && isCurrency
      ? qtyUnits.divide(accountCurrencyLastPrice?.value ?? 1)
      : qtyUnitsMultiplyLastPrice.divide(accountCurrencyLastPrice?.value ?? 1);
  }

  private getPriceAccordingToFacevalue(symbolData: ISymbolData, instruments: WarpInstrument[]): DecimalHelper {
    const lastPrice = symbolData.lastPrice;
    const insrument = instruments.find(instrument => instrument.symbol === symbolData.symbol);

    return insrument?.isBond ? this.calculateBondPrice(lastPrice, insrument.facevalue) : lastPrice;
  }

  private calculateBondPrice(lastPrice: DecimalHelper, facevalue: number): DecimalHelper {
    return lastPrice.multiply(facevalue).divide(100);
  }
}
