import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {mapException} from '@app/core/exceptions/client.exception';
import {isResponse} from '@app/core/exceptions/response-exception';
import {Announcement, ECaption} from '@app/core/models/announcement';
import {WebSocketService, EWsEvents} from '@app/core/services/web-socket.service';
import {firstValueFrom, merge, OperatorFunction, ReplaySubject, Subject} from 'rxjs';
import {filter, map, shareReplay, takeUntil} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AnnouncementService {
  private readonly destroyer$ = new Subject<void>();

  private _announcements: Announcement[] = [];
  private readonly announcements = new ReplaySubject<Announcement[]>(1);

  public optional = this.announcements.pipe(mapTo(ECaption.Optional), shareReplay(1));
  public required = this.announcements.pipe(mapTo(ECaption.Required), shareReplay(1));
  public unread = this.optional.pipe(
    map(v => v.filter(a => !a.read).length),
    shareReplay(1),
  );

  constructor(
    private readonly connectionService: ConnectionService,
    private readonly webSocketService: WebSocketService,
  ) {}

  private getReadAnnouncement(id: number): Promise<IExceptions> {
    return firstValueFrom(this.connectionService.put(`/api/v1/announcements/read/${id}`).pipe(mapException));
  }

  public connect(): void {
    this.connectionService
      .get('/api/v1/announcements')
      .pipe(
        mapException,
        filter((e: IExceptions<Announcement[]>) => isResponse(e)),
        map(e => e.getData().map(a => Announcement.Make(a))),
        takeUntil(this.destroyer$),
      )
      .subscribe(newAnnouncements => {
        this._announcements = this._announcements.concat(newAnnouncements);
        this.announcements.next(this._announcements);
      });

    merge(
      this.webSocketService.public(EWsEvents.AnnouncementCreated).pipe(map(r => r.data as Announcement)),
      this.webSocketService.private(EWsEvents.AnnouncementCreated).pipe(map(r => r.data as Announcement)),
    )
      .pipe(takeUntil(this.destroyer$))
      .subscribe(upd => {
        const index = this._announcements.findIndex(a => a.id === upd.id);

        if (index === -1) {
          this._announcements.push(upd);
        } else {
          this._announcements[index] = upd;
        }

        this.announcements.next(this._announcements);
      });
  }

  public async read(id: number): Promise<IExceptions> {
    const e = await this.getReadAnnouncement(id);

    if (isResponse(e)) {
      const index = this._announcements.findIndex(a => a.id === id);

      const announcement = this._announcements[index];
      switch (announcement.type.caption) {
        case ECaption.Required:
          this._announcements.splice(index, 1);
          break;

        case ECaption.Optional:
        default:
          announcement.read = true;
          break;
      }
      this.announcements.next(this._announcements);
    }

    return e;
  }

  public disconnect(): void {
    this._announcements = [];
    this.announcements.next([]);
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public readAll(): void {
    this._announcements.forEach(a => {
      if (a.type.caption === ECaption.Optional) {
        void this.getReadAnnouncement(a.id);
        a.read = true;
      }
    });
    this.announcements.next(this._announcements);
  }
}

export function mapTo(caption: ECaption): OperatorFunction<Announcement[], Announcement[]> {
  return map(v => v.filter(a => a.type.caption === caption));
}
