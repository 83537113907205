import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TileComponent} from '@app/snackbar/tile/tile.component';
import {Environment} from '@env/environment.entities';
import {Subscription} from 'rxjs';

import {ISnackData, SnackbarService, ESnackMessageTypes} from './snackbar.service';

@Component({
  selector: 'app-snackbar',
  template: '',
})
export class SnackbarComponent implements OnDestroy, OnInit {
  private infoSub = Subscription.EMPTY;

  constructor(
    private readonly matSnackBar: MatSnackBar,
    private readonly zone: NgZone,
    private readonly snackbarService: SnackbarService,
    private readonly environment: Environment,
  ) {}

  public ngOnInit(): void {
    this.infoSub = this.snackbarService.info.subscribe(data => {
      this.showComponent(data);
    });
  }

  public showComponent({
    message,
    type = ESnackMessageTypes.Message,
    duration = this.environment.snackBarDurationInMs,
  }: ISnackData): void {
    this.zone.run(() =>
      this.matSnackBar.openFromComponent(TileComponent, {
        duration,
        data: {message, type, duration},
      }),
    );
  }

  public ngOnDestroy(): void {
    this.infoSub.unsubscribe();
  }
}
