import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {privateWizardTokenProvider, WorkflowService} from '@app/wizard/services/workflow.service';

import {ManagerComponent} from './manager.component';
import {WizardDirective} from './wizard.directive';

@NgModule({
  imports: [CommonModule],
  providers: [WorkflowService, privateWizardTokenProvider],
  declarations: [ManagerComponent, WizardDirective],
  exports: [ManagerComponent],
})
export class WizardModule {}
