<ng-container [formGroup]="formGroup">
  <div class="toggle">
    <mat-slide-toggle [formControl]="control" [required]="required" [disabled]="disabled"></mat-slide-toggle>
    <mat-label>{{ getLabel() | async }}</mat-label>
  </div>

  <ng-container *ngIf="control.touched">
    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>
  </ng-container>
</ng-container>
