import {Decimal} from 'decimal.js';

export function from(value: string | number): Decimal {
  return new Decimal(clear(value));
}

export function clear(value: string | number): number {
  return Number(value) || 0;
}

export function getAbsValue(value: string | number): string {
  return from(value || 0)
    .abs()
    .toString();
}

export function getAbsOrDash(v: string | number): string {
  const value = Number(v);
  return Number.isNaN(value) ? '-' : getAbsValue(value);
}
