import {Casts, CastTypes, Model} from '@app/core/model';

import {ETicketStatusName} from '../enum/ticket-status-name.enum';

export class Status extends Model {
  public id: number;
  public name: ETicketStatusName;

  public readonly casts: Casts = {
    id: [CastTypes.Number],
    name: [CastTypes.String],
  };
}
