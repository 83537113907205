import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export const productTypeLabelMap = {
  /* eslint-disable @typescript-eslint/naming-convention */
  Personal: marker('Personal'),
  Real: marker('Live'),
  Demo: marker('Demo'),
  Partner: marker('Partner'),
  Exchange: marker('Exchange'),
  External: marker('External'),
  Master: marker('Master'),
  Follower: marker('Investment'),
  PrimeXm: marker('PrimeXm'),
  OneZero: marker('OneZero'),
  /* eslint-enable @typescript-eslint/naming-convention */
};
