import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BadRequestException} from '@app/core/exceptions/bad-request-exception';
import {InternalServerErrorException} from '@app/core/exceptions/internal-server-error-exception';
import {NotFoundException} from '@app/core/exceptions/not-found-exception';
import {ServiceUnavailableException} from '@app/core/exceptions/service-unavailable-exception';
import {UnauthorizedException} from '@app/core/exceptions/unauthorized-exception';
import {LogoutService} from '@app/core/services/logout.service';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class ExceptionInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService,
    private readonly logoutService: LogoutService,
  ) {}

  private badRequest(): void {
    this.snackbarService.error(this.translateService.get('Core.Services.ExceptionService.BadRequestException'));
  }

  private notFound(): void {
    this.snackbarService.error(this.translateService.get('Core.Services.ExceptionService.NotFoundException'));
  }

  private serviceUnavailable(): void {
    this.snackbarService.error(this.translateService.get('Core.Services.ExceptionService.ServiceUnavailableException'));
  }

  private unauthorized(): void {
    ALocaleStorage.ACCESS_TOKEN.remove();
    ALocaleStorage.REFRESH_TOKEN.remove();

    void this.logoutService.logout();
  }

  public intercept<Req>(req: HttpRequest<Req>, next: HttpHandler): Observable<HttpEvent<{status?: number}>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);

      return next.handle(req.clone({headers}));
    }

    const request: HttpRequest<Req> = req.clone({
      withCredentials: req.withCredentials || false,
      params: req.params || null,
      headers: req.headers || null,
    });

    return next.handle(request).pipe(
      tap((event: HttpEvent<{status?: number}>) => {
        if (event instanceof HttpResponse) {
          if (
            event.status === InternalServerErrorException.STATUS_CODE ||
            event.body?.status === InternalServerErrorException.STATUS_CODE
          ) {
            return;
          }

          if (
            event.status === BadRequestException.STATUS_CODE ||
            event.body?.status === BadRequestException.STATUS_CODE
          ) {
            return this.badRequest();
          }

          if (event.status === NotFoundException.STATUS_CODE || event.body?.status === NotFoundException.STATUS_CODE) {
            return this.notFound();
          }

          if (
            event.status === UnauthorizedException.STATUS_CODE ||
            event.body?.status === UnauthorizedException.STATUS_CODE
          ) {
            return this.unauthorized();
          }
        }

        if (event instanceof HttpErrorResponse) {
          switch (event.status) {
            case BadRequestException.STATUS_CODE:
              return this.badRequest();

            case UnauthorizedException.STATUS_CODE:
              return this.unauthorized();

            case NotFoundException.STATUS_CODE:
              return this.notFound();

            case InternalServerErrorException.STATUS_CODE:
            case ServiceUnavailableException.STATUS_CODE:
            default:
              return this.serviceUnavailable();
          }
        }
      }),
    );
  }
}

export const ExceptionService = {
  provide: HTTP_INTERCEPTORS,
  useClass: ExceptionInterceptor,
  multi: true,
};

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
