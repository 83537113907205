import {CastTypes, Model} from '@app/core/model';

export class ServerError extends Model {
  public type = 'success';
  public code: number;
  public file: string;
  public line: string;
  public localizedMessage: string;
  public message: string;

  public readonly casts: {[p: string]: (CastTypes | CastTypes[] | typeof Model | typeof Model[])[]} = {
    type: [CastTypes.String, CastTypes.Null],
    code: [CastTypes.Number],
    file: [CastTypes.String, CastTypes.Null],
    line: [CastTypes.String, CastTypes.Number, CastTypes.Null],
    localizedMessage: [CastTypes.String, CastTypes.Null],
    message: [CastTypes.String],
  };
}
