import {Injectable} from '@angular/core';
import {MetaDataModel} from '@app/state/plugins/logout/internals';
import {Reset, ResetAll} from '@app/state/plugins/logout/reset.actions';
import {getMetaData} from '@app/state/plugins/logout/reset.helper';
import {ResetService} from '@app/state/plugins/logout/reset.service';
import {getActionTypeFromInstance, getValue, NgxsPlugin, setValue} from '@ngxs/store';

@Injectable()
export class ResetPlugin implements NgxsPlugin {
  constructor(private readonly resetService: ResetService) {}

  private overwriteStates(state, statesToOverwrite: MetaDataModel[], values: string[]): unknown {
    statesToOverwrite.forEach(
      (meta: MetaDataModel, index: number) => (state = setValue(state, getPath(meta), values[index])),
    );

    return state;
  }

  private resetStates(state: unknown, statesToReset: MetaDataModel[]): unknown {
    statesToReset.forEach((meta: MetaDataModel) => {
      state = setValue(state, getPath(meta), typeof meta.defaults === 'undefined' ? {} : meta.defaults);

      if (meta.children) {
        state = this.resetStates(state, meta.children.map(getMetaData) as MetaDataModel[]);
      }
    });

    return state;
  }

  public resetStatesAll(state, statesToKeep: MetaDataModel[]): unknown {
    const values: string[] = statesToKeep.map((meta: MetaDataModel) => getValue(state, getPath(meta)));

    return this.overwriteStates(this.resetService.initialState, statesToKeep, values);
  }

  public handle(state, action, next: (state, mutation) => unknown): unknown {
    const type: string = getActionTypeFromInstance(action);

    switch (type) {
      case Reset.type:
        state = this.resetStates(state, (action as Reset).statesToReset);
        break;
      case ResetAll.type:
        state = this.resetStatesAll(state, (action as ResetAll).statesToKeep);
        break;
      default:
        break;
    }

    return next(state, action);
  }
}

function getPath(meta: MetaDataModel): string {
  return meta.path;
}
