import {Router} from '@angular/router';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {ADeviceFingerprint} from '@app/core/device-fingerprint/device-fingerprint.abstract';
import {mapException} from '@app/core/exceptions/client.exception';
import {UnauthorizedException} from '@app/core/exceptions/unauthorized-exception';
import {Token} from '@app/core/models/auth/token';
import {TokenData} from '@app/core/models/auth/token-data';
import {TokenResponse} from '@app/core/models/auth/token-response';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {Environment} from '@env/environment.entities';
import {plainToClass} from 'class-transformer';
import {Observable, of} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';

import {THttpHeaders} from './types/types';

/**
 * Service to provide authorization headers and refresh token information if token expired.
 */
export abstract class ATokenService {
  /** Request to refresh token. */
  private refreshTokenRequest$: Observable<TokenResponse> | null = null;

  protected readonly apiUrl = this.environment.apiUrl;

  protected constructor(private readonly environment: Environment, private readonly router: Router) {}

  public getAuthHeaders(): Observable<THttpHeaders> {
    const headers: THttpHeaders = {};

    return of(undefined).pipe(
      switchMap(() => {
        const accessTokenResponse = JSON.parse(ALocaleStorage.ACCESS_TOKEN.get()) as TokenResponse | null;
        const accessTokenData = accessTokenResponse ? plainToClass(Token, accessTokenResponse).data : null;

        const refreshTokenResponse = JSON.parse(ALocaleStorage.REFRESH_TOKEN.get()) as TokenResponse | null;
        const refreshTokenData = refreshTokenResponse ? plainToClass(Token, refreshTokenResponse).data : null;

        if (!accessTokenData || !refreshTokenData || this.isExpiredToken(refreshTokenData)) {
          ALocaleStorage.ACCESS_TOKEN.remove();
          ALocaleStorage.REFRESH_TOKEN.remove();
          return of(null);
        }

        return this.isExpiredToken(accessTokenData) ? this.refreshTokenInformation() : of(accessTokenResponse);
      }),
      first(),
      map((tokenRes: TokenResponse | null) => {
        if (tokenRes?.token) {
          headers.Authorization = `Bearer ${tokenRes.token}`;
        }

        return headers;
      }),
    );
  }

  public isExpiredToken(tokenData: TokenData): boolean {
    return new Date() >= tokenData.expiresTime;
  }

  public refreshTokenInformation(): Observable<TokenResponse | null> {
    // If at this moment request already exists, do not create a new request.
    if (this.refreshTokenRequest$) {
      return this.refreshTokenRequest$;
    }

    const refreshTokenResponse = JSON.parse(ALocaleStorage.REFRESH_TOKEN.get()) as TokenResponse | null;
    const refreshTokenData = refreshTokenResponse ? plainToClass(Token, refreshTokenResponse).data?.token : undefined;

    // Create a request to refresh token info
    this.refreshTokenRequest$ = fromFetch(this.apiUrl + '/api/v1/refresh', {
      selector: res => res.json(),
      method: 'POST',
      credentials: 'include',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: {'content-type': 'application/json'},
      body: JSON.stringify({
        ['device_fingerprint']: ADeviceFingerprint.build(),
        ['refresh_token']: refreshTokenData,
      }),
    }).pipe(
      mapException,
      map(
        (
          res: IExceptions<{
            tokens: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              access_token: TokenResponse;
              // eslint-disable-next-line @typescript-eslint/naming-convention
              refresh_token: TokenResponse;
            };
          }>,
        ) => {
          if (res instanceof UnauthorizedException) {
            ALocaleStorage.ACCESS_TOKEN.remove();
            ALocaleStorage.REFRESH_TOKEN.remove();
            void this.router.navigateByUrl('/login');
            return null;
          }

          const {access_token, refresh_token} = res.getData().tokens;
          ALocaleStorage.ACCESS_TOKEN.set(JSON.stringify(access_token));
          ALocaleStorage.REFRESH_TOKEN.set(JSON.stringify(refresh_token));

          // Clear the request, to not use old result in future requests
          this.refreshTokenRequest$ = null;

          return access_token;
        },
      ),
      shareReplay({refCount: true, bufferSize: 1}),
    );

    return this.refreshTokenRequest$;
  }
}
