import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconModule} from '@app/icon/icon.module';
import {CardComponent} from '@app/shared/modules/card/card.component';
import {CardContentComponent} from '@app/shared/modules/card/card.content.component';
import {CardHeaderComponent} from '@app/shared/modules/card/card.header.component';
import {CardHeaderAsideComponent} from '@app/shared/modules/card/card.headeraside.component';

@NgModule({
  declarations: [CardComponent, CardHeaderComponent, CardHeaderAsideComponent, CardContentComponent],
  imports: [CommonModule, IconModule],
  exports: [CardComponent, CardHeaderComponent, CardContentComponent, CardHeaderAsideComponent],
})
export class CardModule {}
