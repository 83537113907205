import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, TrackByFunction} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConnectionService} from '@app/core/connection.service';
import {downloadByBlob} from '@app/core/utils/file-helpers/download-by-blob';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {SigningFormComponent} from '@app/ui/signing-form/signing-form.component';
import {TSigningDocument} from '@app/ui/signing-form/types/signing-document';
import {ESigningFormDocument} from '@app/ui/signing-form/wizards/get-documents/enums/signing-form-document';
import {IManagerComponent} from '@app/wizard/contracts/i.wizard.component';
import {ManagerComponent} from '@app/wizard/manager.component';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-resign-profiles',
  templateUrl: './get-documents.component.html',
  styleUrls: ['./get-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetDocumentsComponent implements OnInit, OnDestroy, IManagerComponent {
  private readonly destroyer$ = new Subject<void>();
  public readonly manager!: ManagerComponent;
  public documents: TSigningDocument[] = [];

  constructor(
    private readonly dialogRef: MatDialogRef<SigningFormComponent>,
    private readonly connectionService: ConnectionService,
    protected readonly snackbar: SnackbarService,
    protected readonly translate: TranslateService,
    protected readonly router: Router,
  ) {}

  public readonly trackByDocumentId: TrackByFunction<{id: number; text: string}> = (i, item) => item.id;

  public ngOnInit(): void {
    this.mapDocuments(this.manager.data);
  }

  private mapDocuments(data: {id: number; type: ESigningFormDocument}[]): void {
    this.documents = data.map(item => {
      return {
        id: item.id,
        text: this.getTextDependingOnType(item.type),
      };
    });
  }

  private getTextDependingOnType(type): string {
    switch (type) {
      case ESigningFormDocument.PhysicalPersonApplication:
        return marker('Ui.SigningForm.PhysicalPersonApplication.Download');
      case ESigningFormDocument.JuridicalPersonAndBeneficiaryApplication:
        return marker('Ui.SigningForm.JuridicalPersonAndBeneficiaryApplication.Download');
      default:
        return '';
    }
  }

  public redirectToLogin(): void {
    this.dialogRef.close(true);
  }

  public async goToSigning(): Promise<void> {
    await this.manager.send({action: 'send'});
  }

  public download(id: number): void {
    this.connectionService
      .getBlob(`/api/v1/profiles/download/${id}`)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(result => downloadByBlob(result.blob, result.name));
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
