import {DecimalHelper} from '../../models/decimal-helper';
import {TMarginImpactFn} from '../../types/margin-impact-fn';

export class MarginImpact {
  public static getMarginImpactFn({formula}: MarginImpact): TMarginImpactFn {
    if (!formula) {
      return;
    }

    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (quantity: number, customMarginRate?: number): DecimalHelper => {
      const result: number = eval(formula.replace('return', ''));

      return DecimalHelper.from(result);
    };
  }

  /**
   * Position code.
   */
  public uid?: string;
  public instrumentSymbol?: string;
  public arguments?: string;
  public formula?: string;
}
