import {Injectable, Injector} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ErrorDialogComponent, IErrorDialogData} from '@app/ui/error-dialog/error-dialog.component';
import {Subscription} from 'rxjs';

@Injectable()
export abstract class ABaseFullscreenErrorService {
  private dialogRef?: MatDialogRef<ErrorDialogComponent, IErrorDialogData>;
  private dialogSub = Subscription.EMPTY;

  protected constructor(private readonly injector: Injector) {}

  protected closeDialog(): void {
    this.dialogRef?.close();
  }

  public showDialog(config: MatDialogConfig<IErrorDialogData>): void {
    if (this.dialogRef) {
      return;
    }

    this.dialogRef = this.injector.get(MatDialog).open(ErrorDialogComponent, config);
    this.dialogSub.unsubscribe();
    this.dialogSub = this.dialogRef.afterClosed().subscribe(() => delete this.dialogRef);
  }
}
