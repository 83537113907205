import * as B2MarginModels from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class AccountMetricsInternalEventHandler extends AEventHandler<
  IB2marginMessage<B2MarginModels.AccountMetricsTO>
> {
  public type = EB2MarginEventType.AccountMetrics;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage({body}: IB2marginMessage<B2MarginModels.AccountMetricsTO>): void {
    const state = this.store.accountsMetrics$.value;
    state.set(body.accountId, body);
    this.store.accountsMetrics$.next(state);
  }
}

b2MarginInternalEventHandlers.push(AccountMetricsInternalEventHandler);
