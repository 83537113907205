import {DecimalHelper} from '@b2broker/decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class BondCoupon {
  @Expose()
  @Transform(({value}) => new Date(value))
  public beginPeriod: Date;

  @Expose()
  @Transform(({value}) => new Date(value))
  public endPeriod: Date;

  @Expose()
  @Transform(({value}: {value}) => DecimalHelper.from(value ?? 0))
  public couponRate: DecimalHelper;
}
