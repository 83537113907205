import {Injectable, Type} from '@angular/core';
import {ListComponent} from '@app/trading-board/components/select-instrument/list/list.component';
import {ETradingBoardProviderAlias} from '@app/trading-board/enum/provider-alias';
import {AComponentResolver, TEntryPoint} from '@app/trading-board/services/component-resolver.abstract';
import {InactiveOrdersComponent} from '@app/trading-board/widgets/inactive-orders/inactive-orders.component';
import {OpenOrdersComponent} from '@app/trading-board/widgets/open-orders/open-orders.component';
import {OrderHistoryComponent} from '@app/trading-board/widgets/order-history/order-history.component';
import {TradesHistoryComponent} from '@app/trading-board/widgets/trades-history/trades-history.component';
import {TradesComponent} from '@app/trading-board/widgets/trades/trades.component';

import {SymbolLibraryComponent} from '../components/symbol-library/symbol-library.component';
import {B2marginInactiveOrdersComponent} from '../widgets/inactive-orders/b2margin/b2margin-inactive-orders.component';
import {B2marginOpenOrdersComponent} from '../widgets/open-orders/b2margin/b2margin-open-orders.component';
import {B2TraderOrderBookComponent} from '../widgets/order-book/b2trader-order-book/b2trader-order-book.component';
import {OrderBookComponent} from '../widgets/order-book/order-book.component';
import {B2marginOrderHistoryComponent} from '../widgets/order-history/b2margin/b2margin-order-history.component';
import {B2marginTradesHistoryComponent} from '../widgets/trades-history/b2margin/b2margin-trades-history.component';
import {B2MarginTradesComponent} from '../widgets/trades/b2margin/b2margin-trades.component';
import {B2marginTradingViewComponent} from '../widgets/trading-view/b2margin-trading-view/b2margin-trading-view.component';
import {TradingViewComponent} from '../widgets/trading-view/trading-view.component';
import {B2marginWatchListComponent} from '../widgets/watch-list/b2margin/b2margin-watch-list.component';
import {WatchListComponent} from '../widgets/watch-list/watch-list.component';

@Injectable()
export class B2marginComponentsResolverService extends AComponentResolver {
  private static readonly symbolLibrary = (): Promise<{entryPoint: typeof SymbolLibraryComponent}> =>
    import('../components/symbol-library/symbol-library.component').then(m => ({
      entryPoint: m.SymbolLibraryComponent,
    }));

  private static readonly watchList = (): Promise<{entryPoint: typeof B2marginWatchListComponent}> =>
    import('../widgets/watch-list/b2margin/b2margin-watch-list.component').then(m => ({
      entryPoint: m.B2marginWatchListComponent,
    }));

  private static readonly tradingView = (): Promise<{entryPoint: typeof B2marginTradingViewComponent}> =>
    import('../widgets/trading-view/b2margin-trading-view/b2margin-trading-view.component').then(m => ({
      entryPoint: m.B2marginTradingViewComponent,
    }));

  private static readonly openOrders = (): Promise<{entryPoint: typeof B2marginOpenOrdersComponent}> =>
    import('../widgets/open-orders/b2margin/b2margin-open-orders.component').then(m => ({
      entryPoint: m.B2marginOpenOrdersComponent,
    }));

  private static readonly inactiveOrders = (): Promise<{entryPoint: typeof B2marginInactiveOrdersComponent}> =>
    import('../widgets/inactive-orders/b2margin/b2margin-inactive-orders.component').then(m => ({
      entryPoint: m.B2marginInactiveOrdersComponent,
    }));

  private static readonly orderHistory = (): Promise<{entryPoint: typeof B2marginOrderHistoryComponent}> =>
    import('../widgets/order-history/b2margin/b2margin-order-history.component').then(m => ({
      entryPoint: m.B2marginOrderHistoryComponent,
    }));

  private static readonly tradesHistory = (): Promise<{entryPoint: typeof B2marginTradesHistoryComponent}> =>
    import('../widgets/trades-history/b2margin/b2margin-trades-history.component').then(m => ({
      entryPoint: m.B2marginTradesHistoryComponent,
    }));

  private static readonly trades = (): Promise<{entryPoint: typeof B2MarginTradesComponent}> =>
    import('../widgets/trades/b2margin/b2margin-trades.component').then(m => ({
      entryPoint: m.B2MarginTradesComponent,
    }));

  // TODO(Maxim Alkeev) If we return b2margin, rename this accordingly.
  private static readonly orderBook = (): Promise<{entryPoint: typeof B2TraderOrderBookComponent}> =>
    import('../widgets/order-book/b2trader-order-book/b2trader-order-book.component').then(m => ({
      entryPoint: m.B2TraderOrderBookComponent,
    }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly components = new Map<Type<any>, TEntryPoint<any>>([
    [ListComponent, B2marginComponentsResolverService.symbolLibrary],
    [WatchListComponent, B2marginComponentsResolverService.watchList],
    [TradingViewComponent, B2marginComponentsResolverService.tradingView],
    [OpenOrdersComponent, B2marginComponentsResolverService.openOrders],
    [InactiveOrdersComponent, B2marginComponentsResolverService.inactiveOrders],
    [OrderHistoryComponent, B2marginComponentsResolverService.orderHistory],
    [TradesHistoryComponent, B2marginComponentsResolverService.tradesHistory],
    [TradesComponent, B2marginComponentsResolverService.trades],
    [OrderBookComponent, B2marginComponentsResolverService.orderBook],
  ]);

  public readonly type = ETradingBoardProviderAlias.me;

  public getComponent<T>(type: Type<unknown>): TEntryPoint<T> | undefined {
    return this.components.get(type);
  }
}
