import {Pipe, PipeTransform} from '@angular/core';
import {TNilable} from '@app/core/types/types';
import {DEFAULT_PRICE_SCALE} from '@app/pbsr/consts/default-price-scale.const';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import * as _ from 'lodash-es';

import {IPbsThousandsSeparatorPipeParams} from './interfaces/pbs-thousands-separator-pipe-params.interface';

/**
 * @example
 * {{ 15567 | pbsThousandsSeparator }} ---> '15 567'
 * {{ 15567.78 | pbsThousandsSeparator }} ---> '15 567,78'
 * {{ '15567.78' | pbsThousandsSeparator }} ---> '15 567,78'
 * {{ '15567.7891234' | pbsThousandsSeparator }} ---> '15 567,78'
 * {{ LazyDecimalHelper.from('12345.56') | pbsThousandsSeparator }} ---> '12 345,56'
 * {{ '15567.78' | pbsThousandsSeparator: {sign: '+'} }} ---> '+15 567,78'
 * {{ '15567.7891234' | pbsThousandsSeparator: {priceScale: 4} }} ---> '+15 567,7891'
 *
 * {{ '' | pbsThousandsSeparator }} ---> '0'
 * {{ null | pbsThousandsSeparator }} ---> '0,00'
 * {{ undefined | pbsThousandsSeparator }} ---> '0,00'
 * @returns String with thousands separated by a space, decimal part with comma and awith a sign in front of the number if necessary.
 */
@Pipe({
  name: 'pbsThousandsSeparator',
})
export class PbsThousandsSeparatorPipe implements PipeTransform {
  public transform(
    value: TNilable<string | number | LazyDecimalHelper>,
    params?: IPbsThousandsSeparatorPipeParams,
  ): string {
    if (_.isNil(value)) {
      return '0,00';
    }

    if (value instanceof LazyDecimalHelper) {
      value = value.value;
    }

    const [integer, fraction] = String(value).split('.');
    const number = Number(integer);

    if (isNaN(number)) {
      return value.toString();
    }

    const separateInteger = number.toLocaleString('en-GB');
    const resultDueToFraction = fraction
      ? this.getFraction(separateInteger, fraction, params?.priceScale)
      : separateInteger;
    const result = resultDueToFraction.replace(/,/g, ' ').replace(/\./g, ',');

    return params?.sign ? `${params?.sign}${result}` : result;
  }

  private getFraction(separateInteger: string, fraction: string, priceScale: number | undefined): string {
    const currentPriceScale = priceScale || DEFAULT_PRICE_SCALE;

    return `${separateInteger}.${fraction.slice(0, currentPriceScale)}`;
  }
}
