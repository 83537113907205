import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ThemeService} from '@app/shared/modules/theme/services/theme.service';
import {Environment} from '@env/environment.entities';

@Component({
  selector: 'app-error-layout',
  templateUrl: './error-layout.component.html',
  styleUrls: ['./error-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorLayoutComponent implements OnInit {
  constructor(private readonly themeService: ThemeService, private readonly environment: Environment) {}

  public get isLanguageSelectEnabled(): boolean {
    return this.environment.isLanguageSelectEnabled;
  }

  public ngOnInit(): void {
    this.themeService.setupTheme();
  }
}
