import {HistoryTO, OrderTO} from '@b2broker/b2margin-trade-models';
import {Expose, Transform, Type} from 'class-transformer';

import {DecimalHelper} from '../decimal-helper';

import SideEnum = HistoryTO.SideEnum;
import TimeInForceEnum = OrderTO.TimeInForceEnum;

export class OrderHistory {
  private static readonly SCALE = 8;

  @Expose({name: 'orderChainId'})
  public orderId: number;

  @Expose({name: 'symbol'})
  public symbolWithSeparator: string;
  public type: string;
  public status: string;

  public side: SideEnum;

  @Transform(({value}) => DecimalHelper.from(value).abs().setScale(OrderHistory.SCALE))
  public size: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value).abs())
  public quantity: DecimalHelper;

  @Transform(({value, obj}) => DecimalHelper.from(value).setScale(obj.instrument?.precision ?? OrderHistory.SCALE))
  public price: DecimalHelper;

  @Transform(({value, obj}) => DecimalHelper.from(value).setScale(obj.instrument?.precision ?? OrderHistory.SCALE))
  public fillPrice: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value).setScale(OrderHistory.SCALE))
  public triggerPrice: DecimalHelper;

  public timeInForce: TimeInForceEnum;

  @Expose({name: 'expireAt'})
  @Type(() => Date)
  public expiration: Date;

  @Transform(({value}) => (value ? DecimalHelper.from(value).abs().setScale(OrderHistory.SCALE) : null))
  public commission: DecimalHelper | null;

  @Expose({name: 'takeProfitPrice'})
  @Transform(({value, obj}) =>
    value
      ? DecimalHelper.from(value)
          .abs()
          .setScale(obj.instrument?.precision ?? OrderHistory.SCALE)
      : null,
  )
  public takeProfit: DecimalHelper | null;

  @Expose({name: 'stopLossPrice'})
  @Transform(({value, obj}) =>
    value
      ? DecimalHelper.from(value)
          .abs()
          .setScale(obj.instrument?.precision ?? OrderHistory.SCALE)
      : null,
  )
  public stopLoss: DecimalHelper | null;

  @Expose({name: 'lastStatusChange'})
  @Type(() => Date)
  public time: Date;

  @Transform(({value}) => (value ? DecimalHelper.from(value).setScale(OrderHistory.SCALE) : null))
  public filledQuantity: DecimalHelper | null;

  public isBuy(): boolean {
    return this.side === SideEnum.BUY;
  }
}
