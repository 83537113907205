import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OfflineService} from '@app/ui/offline/offline.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineComponent implements OnInit, OnDestroy {
  public isOnline: boolean;

  public sub = Subscription.EMPTY;

  public data = this._data || {};

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly _data: IOfflineComponentData,
    private readonly cdr: ChangeDetectorRef,
    private readonly offlineService: OfflineService,
  ) {}

  public ngOnInit(): void {
    this.sub = this.offlineService.state.subscribe(isOnline => {
      this.isOnline = isOnline;
      this.cdr.detectChanges();
    });
  }

  public reconnect(): void {
    return location.reload();
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

export interface IOfflineComponentData {
  reconnectTitle?: string;
}
