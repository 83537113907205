import {DATA_FEED_DEBOUNCE_IN_MS} from '@app/core/constants/common';
import {betterThrottle} from '@app/core/utils/better-throttle';
import {mapDataStoreUpdate} from '@app/trading-board/facades/data-store';
import {IOrderModelParam} from '@app/trading-board/interfaces/b2margin/order-model-param';
import {IPositionCombined} from '@app/trading-board/interfaces/b2margin/position';
import {ILevel2} from '@app/trading-board/interfaces/level2';
import {ITick} from '@app/trading-board/interfaces/tick';
import {B2marginLiquidationMessage} from '@app/trading-board/models/b2margin/b2margin-liquidation-message';
import {AB2marginPopupMessage} from '@app/trading-board/models/b2margin/b2margin-popup-message';
import {B2marginRiskLevelAlertMessage} from '@app/trading-board/models/b2margin/b2margin-risk-level-alert-message';
import {AccountTO, MarginImpactTO, MessageTO} from '@b2broker/b2margin-trade-models';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {plainToInstance} from 'class-transformer';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay} from 'rxjs/operators';

import {EEventHandlersReceived} from '../event-handlers/event-handlers.enum';
import {IUserSettings} from '../interfaces/b2margin/user-settings';
import {IDataEvent} from '../interfaces/data-event.ts';
import {IInstrument} from '../interfaces/instrument';
import {Account, AccountCollector} from '../models/b2margin/account';
import {B2MarginHistoryOrder} from '../models/b2margin/b2-margin-history-order';
import {B2MarginOpenOrder} from '../models/b2margin/b2-margin-open-order';
import {MarginImpact} from '../models/b2margin/margin-impact';
import {Position} from '../models/b2margin/position';
import {TradeHistory} from '../models/b2margin/trade-history';
import {B2MarginInstrument} from '../models/instrument';
import {Tick} from '../models/level1';
import {Level2} from '../models/level2';

export class B2marginStateStore {
  private static reduceInstruments(
    instruments: B2MarginInstrument[],
    isSymbol?: boolean,
  ): Map<string, B2MarginInstrument> {
    return B2MarginInstrument.reduceListToMap(instruments, isSymbol);
  }

  private static reduceAccounts(accounts: B2MarginModels.AccountTO[]): Map<string, B2MarginModels.AccountTO> {
    return accounts.reduce((acc, curr) => {
      acc.set(curr.accountCode, curr);

      return acc;
    }, new Map<string, B2MarginModels.AccountTO>());
  }

  private static arrayToMap<T>(a: T[], key: string): Map<string, T> {
    return a.reduce((acc, item) => acc.set(item[key], item), new Map<string, T>());
  }

  private static diff(a: string[], b: string[]): string[] {
    return a.filter(value => !b.includes(value)).concat(b.filter(value => !a.includes(value)));
  }

  public readonly validCashType = AccountTO.CashTypeEnum.MARGIN;
  public readonly invalidCashType = AccountTO.CashTypeEnum.CASH;

  public historyOrders$ = new BehaviorSubject<B2MarginModels.HistoryOrderTO[]>([]);

  public connectionServerTimestamp: number;

  public accounts$ = new BehaviorSubject<B2MarginModels.AccountsTO>(undefined);
  public accountsMap$ = this.accounts$.pipe(
    filter(accounts => !!accounts),
    map(({availableAccounts}: B2MarginModels.AccountsTO) => B2marginStateStore.reduceAccounts(availableAccounts)),
  );
  public validAccounts$ = this.accounts$.pipe(
    filter(accounts => !!accounts),
    map(accounts => this.mapAccounts(accounts)),
  );
  public accountsMetrics$ = new BehaviorSubject<Map<string, B2MarginModels.AccountMetricsTO>>(new Map());
  public userSettings$ = new BehaviorSubject<IUserSettings>(undefined);

  public marginImpact$ = new BehaviorSubject(new Map<string, MarginImpactTO>());
  public positionMarginImpact$ = new BehaviorSubject(new Map<string, MarginImpactTO>());
  public level2Info$ = new Subject<B2MarginModels.OrderBookTO[]>();
  public instruments$ = new BehaviorSubject<Map<number, B2MarginModels.InstrumentTO>>(new Map());
  public visibleInstrumentIds$ = new BehaviorSubject<Set<number>>(new Set());
  public visibleInstruments$ = combineLatest([this.instruments$, this.visibleInstrumentIds$]).pipe(
    map(([instruments, visibilities]) => Array.from(instruments.values()).filter(({id}) => visibilities.has(id))),
    map(items => B2MarginInstrument.adapt(items)),
    map(items => (items.length ? plainToInstance(B2MarginInstrument, items) : [])),
    distinctUntilChanged<B2MarginInstrument[]>((a, b) => {
      const prev = Array.from(B2marginStateStore.arrayToMap(a, 'id').keys());
      const next = Array.from(B2marginStateStore.arrayToMap(b, 'id').keys());
      return !B2marginStateStore.diff(prev, next).length;
    }),
    filter(instruments => !!instruments.length),
    shareReplay(1),
  );
  public visibleInstrumentsSymbolMap$ = this.visibleInstruments$.pipe(
    map(instruments => B2marginStateStore.reduceInstruments(instruments, true)),
    shareReplay(1),
  );
  public visibleInstrumentsIdMap$ = this.visibleInstruments$.pipe(
    map(instruments => B2marginStateStore.reduceInstruments(instruments)),
    shareReplay(1),
  );

  public openOrders$ = new BehaviorSubject<B2MarginModels.OrderTO[]>([]);

  public summary$ = new BehaviorSubject<Map<string, B2MarginModels.SummaryTO>>(new Map());
  public quote$ = new BehaviorSubject<Map<string, B2MarginModels.QuoteTO>>(new Map());
  public positions$ = new BehaviorSubject<Map<string, B2MarginModels.PositionTO>>(new Map());
  public positionsMetrics$ = new BehaviorSubject<B2MarginModels.FxPositionMetricsTO[]>([]);

  public level1Map$ = new BehaviorSubject<Map<string, Tick>>(new Map());
  public level1$ = this.level1Map$.pipe(
    map(t => Tick.convertMapToArray(t)),
    shareReplay(1),
  );
  public tradeHistory$ = new BehaviorSubject<B2MarginModels.TradeHistoryTO[]>([]);

  public messages$ = new Subject<IDataEvent>();

  public popupMessage$ = new Subject<MessageTO>();

  constructor() {
    this.visibleInstruments$.subscribe(instruments => {
      this.messages$.next({type: EEventHandlersReceived.Instruments, payload: instruments});
    });

    const level1Data$ = combineLatest([this.summary$, this.quote$, this.visibleInstrumentsSymbolMap$]);
    const level2Data$ = combineLatest([this.level2Info$, this.visibleInstrumentsSymbolMap$]);

    level1Data$
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        map(([summary, quotes, instruments]) => Tick.fromB2Margin(summary, quotes, instruments)),
        filter((ticks: Map<string, ITick>) => !!ticks.size),
        mapDataStoreUpdate<ITick, Tick>(Tick, false, 'instrumentId'),
      )
      .subscribe((ticks: Map<string, Tick>) => {
        this.level1Map$.next(ticks);
      });

    this.level1$.subscribe(payload => {
      this.messages$.next({type: EEventHandlersReceived.Ticks, payload});
    });

    level2Data$
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        map(([items, instruments]: [B2MarginModels.OrderBookTO[], Map<string, IInstrument>]) =>
          Level2.fromB2MarginMap(items, instruments),
        ),
        mapDataStoreUpdate<ILevel2, Level2>(Level2, false, 'symbolWithSeparator'),
        map(level2 => Level2.afterTransform(Array.from(level2.values()))),
      )
      .subscribe((level2: Level2[]) => {
        this.messages$.next({type: EEventHandlersReceived.Level2, payload: level2});
      });

    combineLatest([this.validAccounts$, this.accountsMetrics$])
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        filter(([accounts]) => !!accounts),
      )
      .subscribe(([accounts, accountsMetrics]) => {
        this.messages$.next({
          type: EEventHandlersReceived.Accounts,
          payload: plainToInstance(AccountCollector, AccountCollector.adapt(accounts, accountsMetrics)),
        });
      });

    this.marginImpact$
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        map(value => plainToInstance(MarginImpact, Array.from(value.values()))),
      )
      .subscribe((payload: MarginImpact[]) => {
        this.messages$.next({type: EEventHandlersReceived.MarginImpact, payload});
        this.messages$.next({type: EEventHandlersReceived.PositionMarginImpact, payload});
      });

    this.userSettings$.subscribe(payload => this.messages$.next({type: EEventHandlersReceived.UserSettings, payload}));

    combineLatest([this.positions$, this.level1Map$, this.visibleInstrumentsIdMap$, this.positionsMetrics$])
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        map(values => Position.fromB2MarginMap(...values)),
        mapDataStoreUpdate<IPositionCombined, Position>(Position, true),
      )
      .subscribe((positions: Map<string, Position>) => {
        this.messages$.next({
          type: EEventHandlersReceived.Positions,
          payload: Array.from(positions.values()),
        });
      });

    combineLatest([this.openOrders$, this.visibleInstrumentsIdMap$, this.level1Map$])
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        map(([resource, instruments, level1]) => B2MarginOpenOrder.makeOrderParamMap(resource, instruments, level1)),
        mapDataStoreUpdate<IOrderModelParam<B2MarginModels.OrderTO>, B2MarginOpenOrder>(
          B2MarginOpenOrder,
          true,
          'orderId',
        ),
      )
      .subscribe((openOrders: Map<string | number, B2MarginOpenOrder>) => {
        this.messages$.next({
          type: EEventHandlersReceived.OpenOrders,
          payload: Array.from(openOrders.values()),
        });
      });

    combineLatest([this.historyOrders$, this.visibleInstrumentsIdMap$, this.level1Map$])
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        map(([resource, instruments, level1]) => B2MarginOpenOrder.makeOrderParamMap(resource, instruments, level1)),
        mapDataStoreUpdate<IOrderModelParam<B2MarginModels.HistoryOrderTO>, B2MarginHistoryOrder>(
          B2MarginHistoryOrder,
          true,
        ),
      )
      .subscribe(historyOrders => {
        this.messages$.next({
          type: EEventHandlersReceived.HistoryOrders,
          payload: historyOrders,
        });
      });

    combineLatest([this.tradeHistory$, this.visibleInstrumentsSymbolMap$])
      .pipe(betterThrottle(DATA_FEED_DEBOUNCE_IN_MS))
      .subscribe(([trades, visibleInstrumentsSymbolMap]) => {
        const tradeHistories = TradeHistory.fromB2Margin(trades, visibleInstrumentsSymbolMap);

        this.messages$.next({
          type: EEventHandlersReceived.TradesHistory,
          payload: tradeHistories.length ? plainToInstance(TradeHistory, tradeHistories) : [],
        });
      });

    combineLatest([this.popupMessage$, this.accountsMap$])
      .pipe(
        distinctUntilChanged(([mes1], [mes2]) => mes1 === mes2),
        map(([message, accounts]) => {
          switch (message.messageType) {
            case B2MarginModels.MessageTO.MessageTypeEnum.RISKLEVELALERT:
              return plainToInstance(
                B2marginRiskLevelAlertMessage,
                B2marginRiskLevelAlertMessage.adapt(message, accounts),
              );
            case B2MarginModels.MessageTO.MessageTypeEnum.LIQUIDATION:
              return plainToInstance(B2marginLiquidationMessage, B2marginLiquidationMessage.adapt(message, accounts));
            default:
              console.warn(`Unavailable Popup Message: ${message.messageType}`);
          }
        }),
        filter(message => !!message),
      )
      .subscribe((payload: AB2marginPopupMessage) => {
        this.messages$.next({type: EEventHandlersReceived.PopupMessages, payload});
      });
  }

  private mapAccounts(accounts: B2MarginModels.AccountsTO): B2MarginModels.AccountsTO | null {
    const accountList = accounts.availableAccounts.map(a => plainToInstance(Account, a));
    const currentAccount = plainToInstance(Account, accounts.currentAccount);

    if (
      currentAccount.cashType === this.invalidCashType &&
      accountList.every(account => account.cashType !== this.validCashType)
    ) {
      this.messages$.next({type: EEventHandlersReceived.Error, payload: 'No b2margin accounts'});
      return null;
    }

    return accounts;
  }

  public reset(): void {
    this.historyOrders$.next([]);
    this.accounts$.next(undefined);
    this.userSettings$.next(undefined);
    this.marginImpact$.next(new Map());
    this.positionMarginImpact$.next(new Map());
    this.instruments$.next(new Map());
    this.visibleInstrumentIds$.next(new Set());
    this.openOrders$.next([]);
    this.summary$.next(new Map());
    this.quote$.next(new Map());
    this.positions$.next(new Map());
    this.positionsMetrics$.next([]);
    this.level1Map$.next(new Map());
    this.connectionServerTimestamp = null;
  }
}
