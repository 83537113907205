import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {noop} from '@app/state/plugins/logout/internals';
import {ResetHandler} from '@app/state/plugins/logout/reset.handler';
import {ResetPlugin} from '@app/state/plugins/logout/reset.plugin.service';
import {ResetService} from '@app/state/plugins/logout/reset.service';
import {NGXS_PLUGINS} from '@ngxs/store';

@NgModule()
export class ResetPluginModule {
  public static forRoot(): ModuleWithProviders<ResetPluginModule> {
    return {
      ngModule: ResetPluginModule,
      providers: [
        ResetService,
        ResetHandler,
        {
          provide: APP_INITIALIZER,
          useFactory: noop,
          deps: [ResetHandler],
          multi: true,
        },
        {
          provide: NGXS_PLUGINS,
          useClass: ResetPlugin,
          multi: true,
        },
      ],
    };
  }
}
