import * as B2MarginModels from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class AccountSwitchedInternalEventHandler extends AEventHandler<IB2marginMessage<{accountId: string}>> {
  public type = EB2MarginEventType.AccountSwitched;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage({body: {accountId}}: IB2marginMessage<{accountId: string}>): void {
    const currentAccount = this.store.accounts$
      .getValue()
      ?.availableAccounts.find(({id}: B2MarginModels.AccountTO) => id === accountId);

    const updatedAccounts = Object.assign({}, this.store.accounts$.getValue(), {
      currentAccount: currentAccount ?? null,
    });

    this.store.accounts$.next(updatedAccounts);
  }
}

b2MarginInternalEventHandlers.push(AccountSwitchedInternalEventHandler);
