import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LogoComponent} from '@app/layouts/logo/logo.component';
import {DirectionModule} from '@app/shared/modules/direction/direction.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule, DirectionModule],
  declarations: [LogoComponent],
  exports: [LogoComponent],
})
export class LogoModule {}
