import {PositionTO} from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class PositionsInternalEventHandler extends AEventHandler<IB2marginMessage<PositionTO[]>> {
  public type = EB2MarginEventType.Positions;
  public currentAcoountId;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<PositionTO[]>): void {
    let {value} = this.store.positions$;

    if (this.currentAcoountId !== resource.accountId) {
      this.currentAcoountId = resource.accountId;
      value = new Map();
    }

    const positions = resource.body.reduce((acc, position) => {
      if (position.quantity === 0) {
        acc.delete(position.uid);
      } else {
        acc.set(position.uid, position);
      }

      return acc;
    }, value);

    this.store.positions$.next(positions);
  }
}

b2MarginInternalEventHandlers.push(PositionsInternalEventHandler);
