import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

import {EActivityEvent} from '../enums/activity-event';

/**
 * Service to track activity and share events between handlers.
 */
@Injectable()
export class ActivityTrackerService {
  private readonly _events$ = new Subject<EActivityEvent>();

  public events$ = this._events$.asObservable().pipe(shareReplay({bufferSize: 1, refCount: true}));

  public track(event: EActivityEvent): void {
    this._events$.next(event);
  }
}
