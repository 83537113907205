import {BidiModule} from '@angular/cdk/bidi';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconModule} from '@app/icon/icon.module';
import {BalanceComponent} from '@app/shared/components/balance/balance.component';
import {CountdownComponent} from '@app/shared/components/countdown/countdown.component';
import {SpinnerComponent} from '@app/shared/components/spinner/spinner.component';
import {CopyToClipboardDirective} from '@app/shared/directives/copy.directive';
import {FocusedModule} from '@app/shared/directives/focused/focused.module';
import {CardModule} from '@app/shared/modules/card/card.module';
import {ExpansionPanelModule} from '@app/shared/modules/expansion-panel/expansion-panel.module';
import {PrecisionModule} from '@app/shared/modules/precision/precision.module';
import {FilterPipe} from '@app/shared/pipes/filter/filter.pipe';
import {HighlightPipe} from '@app/shared/pipes/highlight.pipe';
import {KeysPipe} from '@app/shared/pipes/keys/keys.pipe';
import {ProtectStringPipe} from '@app/shared/pipes/protect-string.pipe';
import {ReplacePipe} from '@app/shared/pipes/replace/replace.pipe';
import {SafeHtmlPipe} from '@app/shared/pipes/safe.html/safe.html.pipe';
import {SortPipe} from '@app/shared/pipes/sort/sort.pipe';
import {ThousandsSeparatorModule} from '@app/shared/pipes/thousands-separator/thousands-separator.module';
import {ValuesPipe} from '@app/shared/pipes/values/values.pipe';
import {EllipsisContainerModule} from '@b2broker/ellipsis-container';

import {SelectPercentageComponent} from './components/select-percentage/select-percentage.component';
import {DebounceClickDirective} from './directives/debounce.click.directive';
import {LocalizationPipe} from './pipes/locale/localization.pipe';
import {TransformEmptyPipe} from './pipes/transform-empty/transform-empty.pipe';

const components = [
  CountdownComponent,
  BalanceComponent,
  SpinnerComponent,
  SafeHtmlPipe,
  ReplacePipe,
  ValuesPipe,
  FilterPipe,
  SortPipe,
  KeysPipe,
  SelectPercentageComponent,
  TransformEmptyPipe,
];

const directives = [CopyToClipboardDirective, DebounceClickDirective];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    BidiModule,
    PrecisionModule,
    FocusedModule,
    ThousandsSeparatorModule,
    CardModule,
    ExpansionPanelModule,
    EllipsisContainerModule,
  ],
  declarations: [...components, ...directives, LocalizationPipe, HighlightPipe, ProtectStringPipe],
  exports: [
    ...components,
    ...directives,
    BidiModule,
    PrecisionModule,
    LocalizationPipe,
    FocusedModule,
    HighlightPipe,
    ThousandsSeparatorModule,
    CardModule,
    ProtectStringPipe,
  ],
})
export class SharedModule {}
