import {LanguageCode} from '@b2broker/trading.view.charting.library/charting_library/charting_library';

export const tvAvailableLanguageCodes: LanguageCode[] = [
  'ar',
  'zh',
  'cs',
  'da_DK',
  'ca_ES',
  'nl_NL',
  'en',
  'et_EE',
  'fr',
  'de',
  'el',
  'he_IL',
  'hu_HU',
  'id_ID',
  'it',
  'ja',
  'ko',
  'fa',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk_SK',
  'es',
  'sv',
  'th',
  'tr',
  'vi',
  'no',
  'ms_MY',
  'zh_TW',
];
