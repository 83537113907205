import {Expose} from 'class-transformer';

export class OrganizationStatistics {
  @Expose()
  public okato: string;

  @Expose()
  public okved: string;

  @Expose()
  public oktmo: string;

  @Expose()
  public okfs: string;

  @Expose()
  public okogu: string;

  @Expose()
  public okopf: string;

  @Expose()
  public okpo: string;
}
