import {Injectable} from '@angular/core';
import {CfiCodeHelper} from '@app/pbsr/helpers/cfi-code.helper';
import * as _ from 'lodash-es';
import {combineLatest, Observable, map} from 'rxjs';

import {WarpInstrument} from '../../../trading-board/models/instrument';
import {AWarpInstrumentService} from './warp-instrument.service.abstract';

@Injectable({providedIn: 'root'})
export class WarpSearchInstrumentService extends AWarpInstrumentService {
  public getBondInstruments(): Observable<WarpInstrument[]> {
    const params = this.getQueryParams(10, CfiCodeHelper.BOND_CFI_CODE);
    const url = this.getUrl(params);

    return this.getInstruments(url);
  }

  public getStockInstruments(): Observable<WarpInstrument[]> {
    const paramsArray = CfiCodeHelper.STOCK_CFI_CODES.map(cfiCode => this.getQueryParams(10, cfiCode));
    const urls = paramsArray.map(params => this.getUrl(params));

    const instruments$ = urls.map(url => this.getInstruments(url));

    return combineLatest(instruments$).pipe(map(instruments => _.flatten(instruments)));
  }

  private getQueryParams(limit: number, cfiCode: string): URLSearchParams {
    const queryParams = {
      exchange: this.exchange,
      limit: limit.toString(),
      cfiCode,
    };

    return new URLSearchParams(queryParams);
  }

  private getUrl(params: URLSearchParams): string {
    return `${this.baseUrl}/md/v2/Securities?${params}`;
  }
}
