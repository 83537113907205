import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {mapException} from '@app/core/exceptions/client.exception';
import {Observable} from 'rxjs';

@Injectable()
export class SigningFormService {
  constructor(private readonly connection: ConnectionService) {}

  public init(): Observable<IExceptions> {
    return this.connection.get('/api/v1/profiles/resign').pipe(mapException);
  }
}
