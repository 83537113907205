import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

/**
 * This service manage loader on trading board.
 */
@Injectable()
export class TradingBoardLoadingService {
  private readonly defaultTemporaryTimeoutMs = 1000;
  private readonly isLoadingState$ = new BehaviorSubject<boolean>(false);

  private readonly isLoadingStateTemporary$ = new BehaviorSubject<boolean>(false);
  private temporaryTimeoutId: ReturnType<typeof setTimeout> | null = null;

  public readonly isLoading$ = combineLatest([this.isLoadingState$, this.isLoadingStateTemporary$]).pipe(
    map(([isL1, isL2]) => isL1 || isL2),
    distinctUntilChanged(),
  );
  public readonly displayed$ = this.isLoading$.pipe(filter(isLoad => isLoad));

  public start(): void {
    this.isLoadingState$.next(true);
  }

  public finish(): void {
    this.isLoadingState$.next(false);
  }

  /**
   * Show loader and remove this after `timeoutMs`.
   *
   * @param timeoutMs - Loader shows after this timeout in ms.
   */
  public showTemporaryLoader(timeoutMs: number = this.defaultTemporaryTimeoutMs): void {
    this.isLoadingStateTemporary$.next(true);
    if (this.temporaryTimeoutId) {
      clearTimeout(this.temporaryTimeoutId);
    }
    this.temporaryTimeoutId = setTimeout(() => {
      this.isLoadingStateTemporary$.next(false);
    }, timeoutMs);
  }
}
