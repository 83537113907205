import {CastTypes} from '@app/core/model';
import {EAccountStatus} from '@app/pbsr/enums/account-status.enum';
import {EBaseCurrency} from '@app/trading-board/enum/moex/base-currency';
import {IParentAccount, ISubAccount} from '@app/trading-board/interfaces/moex/moex-accounts-info';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {PortfolioSummary} from '@app/trading-board/models/moex/portfolio-summary';
import * as _ from 'lodash-es';

import {Account} from './account';
import {Options} from './options';

export class MoexAccount extends Account {
  private static makeParentAccount(account: MoexAccount): IParentAccount {
    const options = account.options;

    return {
      id: account.id,
      caption: account.caption,
      isAnalytical: options.isAnalytical,
      isIis: options.isIis,
      subAccId: options.subAccId,
      isSubAccountsOpened: false,
      selectedSubAccount: null,
      subAccounts: [],
      totalPrice: DecimalHelper.from(0),
      totalChanged: DecimalHelper.from(0),
      totalChangedInPercent: DecimalHelper.from(0),
      baseCurrency: EBaseCurrency.Rub,
    };
  }

  private static makeSubAccount(account: MoexAccount): ISubAccount {
    const options = account.options;

    return {
      id: account.id,
      caption: account.caption,
      market: options.market,
      parentSubAccId: options.parentSubAccId,
      subAccId: options.subAccId,
      product: account.product,
      totalPrice: DecimalHelper.from(0),
      symbolsInfo: null,
      totalChanged: DecimalHelper.from(0),
      totalChangedInPercent: DecimalHelper.from(0),
      baseCurrency: EBaseCurrency.Rub,
      tradeCode: options.tradeCode,
      isParentIis: options.isIis,
    };
  }

  public static buildMoexAccounts(accounts: MoexAccount[], isHideDuplicatesAccountId = true): IParentAccount[] {
    const parentAccounts: IParentAccount[] = [];
    const subAccounts: ISubAccount[] = [];

    accounts = accounts.filter(account => account.options.status === EAccountStatus.Active);

    if (isHideDuplicatesAccountId) {
      accounts = _.uniqBy(accounts, account => account.account_id);
    }

    accounts.forEach(account => {
      if (account.options.isAnalytical) {
        parentAccounts.push(this.makeParentAccount(account));
      } else {
        subAccounts.push(this.makeSubAccount(account));
      }
    });

    parentAccounts.forEach(parentAccount => {
      subAccounts.forEach(subAccount => {
        if (subAccount.parentSubAccId === parentAccount.subAccId) {
          subAccount.isParentIis = parentAccount.isIis;

          parentAccount.subAccounts.push(subAccount);
        }
      });

      parentAccount.selectedSubAccount = subAccounts[0]?.subAccId;
    });

    return parentAccounts;
  }

  public static getParentBySubAccount(accounts: IParentAccount[], id: number): IParentAccount | undefined {
    return accounts.find(parentAccount => parentAccount.subAccounts.find(subAccount => subAccount.id === id));
  }

  public options: Options;

  public summary: PortfolioSummary | undefined;

  public readonly casts = {...super.casts, summary: [CastTypes.Undefined, PortfolioSummary]};
}
