import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router';

@Injectable()
export class PayoutGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  public canActivate(activatedRoute: ActivatedRouteSnapshot): boolean | UrlTree {
    if (activatedRoute.queryParams['isInternalTransfer']) {
      return this.router.createUrlTree(['/tx/internal-transfer']);
    }

    return true;
  }
}
