import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';

export function inverseOfTranslation(viewPort?: CdkVirtualScrollViewport): string {
  if (!viewPort || !viewPort['_renderedContentOffset']) {
    return '-0px';
  }

  const offset = viewPort['_renderedContentOffset'];
  return `-${offset}px`;
}
