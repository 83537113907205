import {DecimalHelper} from '@b2broker/decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class RudataInstrument {
  @Expose()
  public issuersector: string;

  @Expose()
  public fintooltype: string;

  @Expose()
  @Transform(({value}: {value}) => DecimalHelper.from(value ?? 0))
  public couponrate: DecimalHelper;

  @Expose()
  public fintoolid: string;

  @Expose()
  public isincode: string;
}
