<ng-container [formGroup]="formGroup">
  <mat-form-field class="datepicker">
    <input
      matInput
      [tooltipIfLongLabel]="label"
      [hasReqiredIfRule]="isRequiredIf"
      [formControl]="dateControl"
      [mask]="mask"
      [errorStateMatcher]="errorMatcher"
      (blur)="onBlur($event)"
      (input)="onInput($event)"
      [required]="required"
    />

    <input hidden [formControl]="control" [matDatepicker]="myDatepicker" (dateChange)="dateChange($event)" />

    <mat-datepicker-toggle [disableRipple]="true" matSuffix [for]="myDatepicker"></mat-datepicker-toggle>

    <mat-datepicker #myDatepicker></mat-datepicker>

    <mat-hint *ngIf="field.label.hint" [align]="field.label.hint.position || 'start'">
      {{ getHintLabel() | async }}
    </mat-hint>

    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>

    <mat-label #label>
      {{ getLabel() | async }}
      <app-required-indicator
        [isShowIndicator]="isRequiredIf"
        [isInvalid]="dateControl.touched && control.invalid"
      ></app-required-indicator>
    </mat-label>
  </mat-form-field>
</ng-container>
