import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';

@Component({
  selector: 'app-password-input',
  templateUrl: './password.button.component.html',
  styleUrls: ['./password.button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordButtonComponent extends FormInput implements OnInit {
  public isShowPassword = true;

  public required: boolean;

  constructor(labelService: LabelService, errorsService: ErrorsService) {
    super(labelService, errorsService);
  }

  public toggleType(): void {
    this.isShowPassword = !this.isShowPassword;
  }

  public ngOnInit(): void {
    this.required = this.isRequired();
  }

  public get type(): 'password' | 'text' {
    return this.isShowPassword ? 'password' : 'text';
  }
}
