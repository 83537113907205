<app-dialog class="code-message">
  <h1 class="title">{{ 'Ui.SigningForm.Wizard.ResignProfiles.Title' | translate }}</h1>

  <p class="message">{{ 'Ui.SigningForm.Wizard.ResignProfiles.SmsCodeMessage' | translate }}</p>

  <app-form
    *ngIf="!isShowSuccess"
    [formConfig]="formConfig"
    [apiErrors]="apiErrors"
    [disabled]="isTooManyRequests"
    [submitLabel]="'Ui.SigningForm.Wizard.ResignProfiles.Confirm' | translate"
    [backLabel]="'Ui.SigningForm.Wizard.ResignProfiles.GoBack' | translate"
    (submitEmitter)="onSubmit($event)"
    (formInitiated)="formInitiated($event)"
    (backEmitter)="goToGetCode()"
  >
    <app-code-resend
      [expiredTimer]="expiredTimer"
      [isManyRequests]="isTooManyRequests"
      (blockEndEvent)="onBlockEnd()"
      (resendEvent)="resendCode()"
    ></app-code-resend>
  </app-form>

  <app-success-confirm-message *ngIf="isShowSuccess"></app-success-confirm-message>
</app-dialog>
