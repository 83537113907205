import {Injectable} from '@angular/core';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {ChartResponse} from '@app/trading-board/models/b2trader/chart-response';

import {EMoexEventHandlersReceived} from './moex-event-handlers.enum';

@Injectable()
export class MoexSubscribeChartService extends AEventHandler<ChartResponse> {
  public readonly type = EMoexEventHandlersReceived.SubscribeChart;

  constructor(private readonly moexDatafeedService: MoexDatafeedService) {
    super();
  }

  public handleMessage(resource: ChartResponse): void {
    this.moexDatafeedService.chartDataTopics.get(resource.symbol)?.subject.next(resource.bar);
  }
}
