import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {LogoutService} from '@app/core/services/logout.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LogoutInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {}

  public intercept<Req, Res>(req: HttpRequest<Req>, next: HttpHandler): Observable<HttpEvent<Res>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const logoutService = this.injector.get(LogoutService);
          logoutService.lastRequestTime$.next(Date.now());
        }
      }),
    );
  }
}

export const LogoutProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LogoutInterceptor,
  multi: true,
};
