import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'values',
})
export class ValuesPipe implements PipeTransform {
  public transform<T>(value: Record<string | number, T>): T[] {
    return value ? Object.values(value) : [];
  }
}
