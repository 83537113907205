import {DATA_FEED_DEBOUNCE_IN_MS} from '@app/core/constants/common';
import {MoexAccount} from '@app/core/models/account/moex-account';
import {betterThrottle} from '@app/core/utils/better-throttle';
import {filterNil} from '@app/core/utils/rxjs-filters';
import {ITradingStatusData} from '@app/pbsr/interfaces/trading-status-data.interface';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {EMoexEventHandlersReceived} from '@app/trading-board/event-handlers/moex/moex-event-handlers.enum';
import {IMoexQuote} from '@app/trading-board/interfaces/moex/quote';
import {ITick} from '@app/trading-board/interfaces/tick';
import {ChartResponse as B2TraderChartResponse} from '@app/trading-board/models/b2trader/chart-response';
import {Tick} from '@app/trading-board/models/level1';
import {plainToInstance} from 'class-transformer';
import {BehaviorSubject, interval, Observable, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {EMoexOpcode} from '../enum/moex/opcode';
import {ESide} from '../enum/side';
import {IDataEvent} from '../interfaces/data-event.ts';
import {IMoexMessage} from '../interfaces/moex/moex-message';
import {IRawOrderBookSlim} from '../interfaces/moex/raw-interfaces/raw-order-book-slim';
import {Level2} from '../models/level2';

export class MoexStateStore {
  public readonly messages$ = new Subject<IDataEvent>();

  public readonly chartSymbolGuidMap = new Map<string, string>();
  public readonly orderBookGuidMap = new Map<string, string>();
  public readonly webSocketMessagesGuidMap = new Map<string, EMoexOpcode>();
  public readonly intrumentTradingStatuses = new Map<string, string>();

  public readonly accounts$ = new BehaviorSubject<MoexAccount[] | null>(null);

  public readonly chart$ = new Subject<IMoexMessage>();
  public readonly level2$ = new Subject<IMoexMessage>();
  public readonly tradingStatusData$ = new Subject<IMoexMessage<ITradingStatusData>>();

  public readonly quoteStreams$ = new BehaviorSubject(new Map<string, Observable<IMoexMessage>>());
  public readonly activeQuoteSubscriptions = new Map<string, Subscription>();
  public readonly quotes = new Map<string, Tick>();

  constructor() {
    this.level2$.pipe(betterThrottle(DATA_FEED_DEBOUNCE_IN_MS)).subscribe((message: IMoexMessage<IRawOrderBookSlim>) =>
      this.messages$.next({
        type: EMoexEventHandlersReceived.Level2,
        payload: Level2.afterTransformItem(
          plainToInstance(Level2, {
            symbolWithSeparator: this.orderBookGuidMap.get(message.guid),
            bids: message.data.b.map(bid => {
              return {
                price: bid.p,
                volume: bid.v,
                quantity: bid.v,
                side: ESide.Buy,
                yield: bid.y,
              };
            }),
            asks: message.data.a.map(ask => {
              return {
                price: ask.p,
                volume: ask.v,
                quantity: ask.v,
                side: ESide.Sell,
                yield: ask.y,
              };
            }),
          }),
        ),
      }),
    );

    this.chart$.pipe(betterThrottle(DATA_FEED_DEBOUNCE_IN_MS)).subscribe((message: IMoexMessage) => {
      this.messages$.next({
        type: EMoexEventHandlersReceived.SubscribeChart,
        payload: plainToInstance(
          B2TraderChartResponse,
          {
            symbol: this.chartSymbolGuidMap.get(message.guid),
            bar: {
              ...message.data,
            },
          },
          {excludeExtraneousValues: true},
        ),
      });
    });

    this.tradingStatusData$.subscribe((message: IMoexMessage<ITradingStatusData>) => {
      this.messages$.next({
        type: EMoexEventHandlersReceived.SubscribeInstrument,
        payload: {message, symbol: this.intrumentTradingStatuses.get(message.guid)},
      });
    });

    this.quoteStreams$.subscribe(quoteStreams => {
      quoteStreams.forEach((quoteStream, symbol) => {
        if (this.activeQuoteSubscriptions.get(symbol)) {
          return;
        }

        const newQuoteSubscription = quoteStream
          .pipe(
            betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
            map((message: IMoexMessage<IMoexQuote>): ITick => Tick.fromMoex(message.data)),
            map((ticks: ITick): Tick => plainToInstance(Tick, ticks)),
          )
          .subscribe(tick => this.quotes.set(symbol, tick));

        this.activeQuoteSubscriptions.set(symbol, newQuoteSubscription);
      });
    });

    this.accounts$
      .pipe(
        filterNil(),
        map(accounts => {
          if (!accounts.length) {
            return [];
          }

          const rawAccounts = accounts.filter(account => !Array.isArray(account.options));

          return MoexAccount.buildMoexAccounts(rawAccounts);
        }),
      )
      .subscribe(accounts => {
        this.messages$.next({
          type: EMoexEventHandlersReceived.Accounts,
          payload: accounts,
        });
      });

    interval(DATA_FEED_DEBOUNCE_IN_MS).subscribe(() => {
      this.messages$.next({
        type: EEventHandlersReceived.Ticks,
        payload: this.quotes,
      });
    });
  }

  public reset(): void {
    this.activeQuoteSubscriptions.clear();
    this.webSocketMessagesGuidMap.clear();
    this.orderBookGuidMap.clear();
    this.chartSymbolGuidMap.clear();
    this.intrumentTradingStatuses.clear();

    this.quoteStreams$.next(new Map());
  }
}
