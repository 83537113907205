import {ALocaleStorage} from '@app/shared/storages/local-storage';

export class LocalStorageWorker {
  private static localData: Record<ESaveKeys, string>;

  private static save(): void {
    LocalStorageWorker.localData = Object.values(ESaveKeys).reduce((result, key) => {
      const value = ALocaleStorage.getItem(key);

      if (value) {
        result[key] = value;
      }

      return result;
    }, {} as Record<ESaveKeys, string>);
  }

  public static clear(): void {
    LocalStorageWorker.save();
    try {
      ALocaleStorage.clear();
    } catch (e) {
      if (e.name === ELocalStorageErrors.NsErrorFileCorrupted) {
        console.warn(LocalStorageErrorsMessage[ELocalStorageErrors.NsErrorFileCorrupted]);
      }
    } finally {
      Object.keys(LocalStorageWorker.localData).forEach((key: ESaveKeys) => {
        ALocaleStorage.setItem(key, LocalStorageWorker.localData[key]);
      });
      delete LocalStorageWorker.localData;
    }
  }
}

enum ELocalStorageErrors {
  NsErrorFileCorrupted = 'NS_ERROR_FILE_CORRUPTED',
}

export const LocalStorageErrorsMessage = {
  [ELocalStorageErrors.NsErrorFileCorrupted]:
    'Sorry, it looks like your browser storage has been corrupted. ' +
    'Please clear your storage by going to Tools -> ' +
    'Clear Recent History -> ' +
    "Cookies and set time range to 'Everything'. " +
    'This will remove the corrupted browser storage across all sites.',
};

export enum ESaveKeys {
  SessionMarket = 'SessionMarket',
  SessionFavorites = 'SessionFavorites',
  SessionPair = 'SessionPair',
  /* eslint-disable @typescript-eslint/naming-convention */
  cookieMessage = 'cookie-message',
  policyMessage = 'policy-message',
  lang = 'lang',
  instrumentId = 'instrumentId',
  commonTheme = 'commonTheme',
  advancedTheme = 'advancedTheme',
  /* eslint-enable @typescript-eslint/naming-convention */
  DesignVersion = 'DESIGN_VERSION',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getLSGridsterConfigKey(projectName: string, isPublic = false): string {
  return `${projectName}-gridsters-config${isPublic ? '-public' : ''}`;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getLSGridsterOptionsKey(projectName: string, isPublic = false): string {
  return `${projectName}-gridsters-options${isPublic ? '-public' : ''}`;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getLSGridsterLayoutIdKey(projectName: string, configName?: string): string {
  return configName ? `${projectName}-${configName}-gridsters-layoutId` : '';
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getLSLeaderboardConfigKey(projectName: string): string {
  return `${projectName}-leaderboard-config`;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getLSIntroductionTourFinishedKey(projectName: string): string {
  return `${projectName}-is-introduction-tour-finished`;
}
