import {Casts, CastTypes, Model} from '@app/core/model';

import {InvestmentChartPoint} from './investment-chart-point';

export class InvestmentChartData extends Model {
  public count: number;
  public points: InvestmentChartPoint[] = [];

  public readonly casts: Casts = {
    count: [CastTypes.Number],
    points: [[InvestmentChartPoint]],
  };
}
