import {Injectable} from '@angular/core';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {B2traderStopOrder} from '@app/trading-board/models/b2trader/b2trader-stop-order';

@Injectable()
export class B2traderStopOrdersService extends AEventHandler<B2traderStopOrder[]> {
  public readonly type = EB2TraderEventHandlersReceived.StopOrders;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: B2traderStopOrder[] | null): void {
    if (resource === null) {
      this.b2traderDatafeedService.stopOrders$.next(new Map());
      return;
    }

    this.b2traderDatafeedService.stopOrders$.next(
      resource.reduce((acc, item) => acc.set(item.orderId, item), this.b2traderDatafeedService.stopOrders$.getValue()),
    );
  }
}
