import {DOCUMENT} from '@angular/common';
import {HttpStatusCode} from '@angular/common/http';
import {Inject} from '@angular/core';
import {DATA_FEED_DEBOUNCE_IN_MS} from '@app/core/constants/common';
import {TokenResponse} from '@app/core/models/auth/token-response';
import {THttpParams} from '@app/core/types/types';
import {betterThrottle} from '@app/core/utils/better-throttle';
import {B2traderAuthPublicStandalone} from '@app/trading-board/b2trader-auth/b2trader-auth-public-standalone';
import {B2traderAuthStandalone} from '@app/trading-board/b2trader-auth/b2trader-auth-standalone';
import {AB2TraderAuth} from '@app/trading-board/b2trader-auth/b2trader-auth.abstract';
import {EHistoryOrderStatus} from '@app/trading-board/enum/b2trader/history-order-status';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {ESharedEventHandlers} from '@app/trading-board/event-handlers/shared/shared-event-handlers.enum';
import {IApiMarketInfo} from '@app/trading-board/interfaces/b2trader/api-market-info';
import {IBalance} from '@app/trading-board/interfaces/b2trader/balance';
import {IHistoryOrdersResponse} from '@app/trading-board/interfaces/b2trader/history-orders-response';
import {IMarketsSummaryDto} from '@app/trading-board/interfaces/b2trader/markets-summary-dto.interface';
import {IOrder} from '@app/trading-board/interfaces/b2trader/order';
import {IOrderBookInfoDto} from '@app/trading-board/interfaces/b2trader/order-book-info-dto.interface';
import {IOrderBookLevelDto} from '@app/trading-board/interfaces/b2trader/order-book-level-dto.interface';
import {IOrderCreateRequest} from '@app/trading-board/interfaces/b2trader/order-create-request';
import {IStopOrderResponse} from '@app/trading-board/interfaces/b2trader/stop-order-response';
import {ISubscribeChartRequest} from '@app/trading-board/interfaces/b2trader/subscribe-chart-request';
import {IApiOptions} from '@app/trading-board/interfaces/connection-params';
import {SignalRWrapper} from '@app/trading-board/models/b2trader/wrapper';
import {Bar} from '@app/trading-board/models/bar';
import {TradingBoardLoadingService} from '@app/trading-board/services/trading-board-loading.service';
import {
  ApiLibraryApiMarketInfo,
  ApiLibraryApiOrderInfoRequest,
  B2brokerAccountTradeHistoryItem,
  QuoteInfo,
} from '@b2broker/b2trader-trade-models';
import * as B2Trader from '@b2broker/b2trader-trade-models';
import {plainToClass, plainToInstance} from 'class-transformer';
import {Observable, ReplaySubject, Subject, firstValueFrom} from 'rxjs';
import {first, map, switchMap, takeUntil} from 'rxjs/operators';

import {EB2traderEventTypes} from '../enum/b2trader/b2trader-event-types';
import {EWorkerEventTypes} from '../enum/worker-event-types';
import {
  EB2TraderEventHandlersReceived,
  EB2TraderEventHandlersRequest,
} from '../event-handlers/b2trader/b2trader-event-handlers.enum';
import {b2TraderInternalEventHandlers, AEventHandler} from '../event-handlers/event-handler.abstract';
import {IChartHistoryRequest} from '../interfaces/b2trader/chart-history-request';
import {IHistoryOrdersRequest} from '../interfaces/b2trader/history-orders-request';
import {IOrderCreateResponse} from '../interfaces/b2trader/order-create-response';
import {IPublicLoginDialogData} from '../interfaces/b2trader/public-login-dialog-data';
import {IFacadeSubscription} from '../interfaces/facade-subscription';
import {ISingleRequestMessage} from '../interfaces/single-request-message';
import {ChartRequest} from '../models/b2trader/chart-request';
import {ChartResponse} from '../models/b2trader/chart-response';
import {TradeFees} from '../models/b2trader/trade-fees';
import {B2TraderInstrument} from '../models/instrument';
import {IDataEvent} from './../interfaces/data-event.ts';
import {B2traderStateStore} from './b2trader-state-store';
import {ADatafeedFacade} from './datafeed-facade.abstract';

import '../event-handlers/b2trader/internal';

export class B2traderDatafeedFacade extends ADatafeedFacade {
  private readonly store = new B2traderStateStore();
  private readonly eventHandlers: AEventHandler<unknown>[];
  private readonly connected$ = new ReplaySubject<void>(1);

  private isReconnectToMarketDataHub = false;
  private isPublicIndicator: boolean;
  private isStandaloneIndicator: boolean;

  private hub: SignalRWrapper;
  private privateHub: SignalRWrapper;
  private stopOrdersHub: SignalRWrapper;

  private isHubClosedManually = false;
  private isPrivateHubClosedManually = false;

  private readonly frontOfficeUrl = 'frontoffice/api';
  private readonly apiData$ = this.b2TraderAuth.apiData$;

  public get messages$(): Subject<IDataEvent> {
    return this.store.messages$;
  }

  public readonly destroyer$ = new Subject<void>();

  constructor(
    private readonly tradingBoardLoadingService: TradingBoardLoadingService,
    private readonly b2TraderAuth: AB2TraderAuth,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();

    this.eventHandlers = b2TraderInternalEventHandlers.map(
      b2TraderInternalEventHandler => new b2TraderInternalEventHandler(this.store),
    );
  }

  private requestOrderBook({symbolsToAdd, symbolsToRemove}: IFacadeSubscription): void {
    if (symbolsToAdd) {
      symbolsToAdd.forEach((symbol: string) => {
        const state: Partial<IOrderBookInfoDto> = {bids: [], asks: [], instrument: symbol};
        const destroyer$: Subject<void> = new Subject();

        this.hub
          .stream('Book', symbol)
          .pipe(
            map((update: Partial<IOrderBookInfoDto>) => {
              let stateBids: IOrderBookLevelDto[] = state.bids;
              let stateAsks: IOrderBookLevelDto[] = state.asks;

              update.bids.forEach(order => {
                const newBids = stateBids.filter(o => o.price !== order.price);
                stateBids = order.amount > 0 ? newBids.concat(order) : newBids;
              });

              update.asks.forEach(order => {
                const newAsks = stateAsks.filter(o => o.price !== order.price);
                stateAsks = order.amount > 0 ? newAsks.concat(order) : newAsks;
              });

              state.bids = stateBids;
              state.asks = stateAsks;

              return state;
            }),
            betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
            takeUntil(destroyer$),
          )
          .subscribe((book: Partial<IOrderBookInfoDto>) => {
            this.store.level2$.next(book);
          });

        this.store.level2Destroyers.set(symbol, destroyer$);
      });
    }

    if (symbolsToRemove) {
      symbolsToRemove.forEach((symbol: string) => {
        const destroyer$ = this.store.level2Destroyers.get(symbol);

        destroyer$?.next();
        destroyer$?.complete();

        this.store.level2$.next({instrument: symbol, bids: [], asks: []});
      });
    }
  }

  private requestTicks(): void {
    this.hub
      .stream<IMarketsSummaryDto>('Summary')
      .pipe(takeUntil(this.destroyer$))
      .subscribe(summary => {
        this.store.level1$.next(Object.values(summary.summaryByInstrument));
      });
  }

  private requestOrders(): void {
    this.privateHub
      ?.stream('OpenOrders')
      .pipe(takeUntil(this.destroyer$))
      .subscribe((update: IOrder[]) => {
        this.dispatch(EB2traderEventTypes.Orders, update);
      });
  }

  private requestStopOrders(): void {
    this.stopOrdersHub
      ?.stream('OpenStopOrders')
      .pipe(takeUntil(this.destroyer$))
      .subscribe((update: IStopOrderResponse[]) => {
        this.dispatch(EB2traderEventTypes.StopOrders, update);
      });
  }

  private requestFullBalance(): void {
    this.privateHub
      ?.stream('FullBalance', {})
      .pipe(takeUntil(this.destroyer$))
      .subscribe((update: IBalance[]) => {
        this.dispatch(EB2traderEventTypes.Balance, update);
      });
  }

  private dispatch(type: string, message: unknown): void {
    this.eventHandlers.forEach(handler => {
      const isType = Array.isArray(handler.type) ? handler.type.includes(type) : handler.type === type;

      if (isType) {
        handler.handleMessage(message);
      }
    });
  }

  private async startPrivate(): Promise<void> {
    if (!this.privateHub) {
      return Promise.resolve();
    }

    await this.stopOrdersHub?.start();

    return this.privateHub.start();
  }

  private requestTiers(): void {
    this.store.orderStore$
      .pipe(
        switchMap(() => this.getTiers()),
        takeUntil(this.destroyer$),
      )
      .subscribe(tiers => this.dispatch(EB2traderEventTypes.Tiers, tiers));
  }

  private afterConnect(): void {
    this.requestTicks();
    this.requestOrders();

    if (this.privateHub?.isConnected) {
      this.requestStopOrders();
      this.requestTiers();
      this.requestFullBalance();
    }

    if (!this.isPublicIndicator) {
      this.startPing();
    }

    this.connected$.next();
    this.tradingBoardLoadingService.finish();
    this.messages$.next({type: EB2TraderEventHandlersReceived.Connected});
  }

  private async getInstruments(): Promise<void> {
    const info = await firstValueFrom(this.get<IApiMarketInfo>(`${this.frontOfficeUrl}/info`));

    this.store.serverTime$.next({
      exchangeTimeOffset: (info.exchangeTimeZone.startsWith('-')
        ? info.exchangeTimeZone
        : `+${info.exchangeTimeZone}`
      ).slice(0, -3),
      serverTime: info.serverTime * 1000,
    });

    const availableInstruments = info
      ? Object.values<ApiLibraryApiMarketInfo>(info.pairs).filter(pair => !pair.hidden)
      : [];
    this.store.instruments$.next(availableInstruments.map(i => B2TraderInstrument.adapt(i)));
  }

  private subscribeChart({symbol, resolution}: ISubscribeChartRequest): void {
    const subscription = this.connected$
      .pipe(
        switchMap(() =>
          this.hub.stream('Chart', `${ChartRequest.mapSymbol(symbol)}@${ChartRequest.mapResolution(resolution)}`),
        ),
      )
      .subscribe((candle: QuoteInfo) => {
        this.messages$.next({
          type: EB2TraderEventHandlersReceived.SubscribeChart,
          payload: plainToClass(
            ChartResponse,
            {symbol, bar: {...candle, time: new Date(candle.start).getTime()}},
            {excludeExtraneousValues: true},
          ),
        });
      });

    this.store.charts.set(symbol, subscription);
  }

  private unsubscribeChart(symbol: string): void {
    this.store.charts.get(symbol)?.unsubscribe();
    this.store.charts.delete(symbol);
  }

  private requestChartHistory(payload: IChartHistoryRequest): void {
    const symbol = ChartRequest.mapSymbol(payload.symbol);
    const params = ChartRequest.getInstrumentParams(payload.rangeStartDate, payload.rangeEndDate, payload.resolution);

    this.get<B2Trader.ChartHistoryResponse>(`marketdata/instruments/${symbol}/history`, {params})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        const data = plainToInstance(
          Bar,
          response.data.map(item => ({...item, time: new Date(item.start).getTime()})),
        );

        this.messages$.next({
          type: EB2TraderEventHandlersReceived.ChartHistory,
          payload: {
            id: payload.id,
            data,
          },
        });
      });
  }

  private closeOrder(payload: ISingleRequestMessage<string>): void {
    this.delete<void>(`orders/${payload.data}`)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() =>
        this.messages$.next({
          type: EB2TraderEventHandlersRequest.CloseOrder,
          payload: {id: payload.id},
        }),
      );
  }

  private closeStopOrder(payload: ISingleRequestMessage<string>): void {
    this.delete<void>(`stoporders/${payload.data}`)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() =>
        this.messages$.next({
          type: EB2TraderEventHandlersRequest.CloseOrder,
          payload: {id: payload.id},
        }),
      );
  }

  private async getPublicHistoryOrder({id}: ISingleRequestMessage<IHistoryOrdersRequest>): Promise<void> {
    const isLastPage = true;

    /**
     * We need to add it to microtasks queue because we listen to it via WorkerConnectionService.messages$.
     *
     * @see B2TraderDatafeedService.getFilledOrders
     */
    await new Promise(resolve => resolve(void 0));

    this.messages$.next({
      type: EB2TraderEventHandlersRequest.HistoryOrder,
      payload: {
        id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data: isLastPage,
      },
    });

    this.dispatch(EB2traderEventTypes.Orders, []);
  }

  private async getHistoryOrder({id, data}: ISingleRequestMessage<IHistoryOrdersRequest>): Promise<void> {
    const orders = await firstValueFrom(
      this.get<IHistoryOrdersResponse>(`${this.frontOfficeUrl}/v2/orders`, {
        params: {
          ordersOnPage: data.ordersOnPage,
          status: data.status,
          ...(data.from && {startDate: data.from.toISOString()}),
          ...(data.to && {endDate: data.to.toISOString()}),
          page: data.page ?? 1,
        },
      }),
    );

    this.messages$.next({
      type: EB2TraderEventHandlersRequest.HistoryOrder,
      payload: {
        id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data: !orders.data.length,
      },
    });

    this.dispatch(EB2traderEventTypes.Orders, orders.data);
  }

  private getTrades(payload: IFacadeSubscription): void {
    payload.symbolsToAdd.forEach(symbol => {
      if (!this.store.trades.has(symbol)) {
        const sub = this.connected$
          .pipe(switchMap(() => this.hub.stream('Trades', symbol)))
          .subscribe((trades: B2brokerAccountTradeHistoryItem[]) => {
            this.dispatch(EB2traderEventTypes.Trades, trades);
          });

        this.store.trades.set(symbol, sub);
      }
    });

    payload.symbolsToRemove.forEach(symbol => {
      this.store.trades.get(symbol)?.unsubscribe();
      this.store.trades.delete(symbol);
    });
  }

  private createOrder(payload: ISingleRequestMessage<IOrderCreateRequest>): void {
    this.post<IOrderCreateResponse>('v2/orders', {order: payload.data})
      .pipe(
        map((response: IOrderCreateResponse) => ({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          success: !response.errors && response.order?.status !== EHistoryOrderStatus.Rejected,
          status: response.order?.status,
        })),
        takeUntil(this.destroyer$),
      )
      .subscribe(response =>
        this.messages$.next({
          type: EB2TraderEventHandlersRequest.CreateOrder,
          payload: {id: payload.id, data: response},
        }),
      );
  }

  private createStopOrder(payload: ISingleRequestMessage<ApiLibraryApiOrderInfoRequest>): void {
    this.post<IOrderCreateResponse>('stoporders', {order: payload.data})
      .pipe(
        map((response: IOrderCreateResponse) => ({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          success: !response.errors && response.order?.status !== EHistoryOrderStatus.Rejected,
          status: response.order?.status,
        })),
        takeUntil(this.destroyer$),
      )
      .subscribe(response =>
        this.messages$.next({
          type: EB2TraderEventHandlersRequest.CreateStopOrder,
          payload: {id: payload.id, data: response},
        }),
      );
  }

  private async publicStandaloneLogin(payload: ISingleRequestMessage<IPublicLoginDialogData>): Promise<void> {
    if (!(this.b2TraderAuth instanceof B2traderAuthPublicStandalone)) {
      return;
    }

    await firstValueFrom(this.b2TraderAuth.signIn(payload.data))
      .then(() =>
        this.reconnect({
          apiUrl: this.apiUrl,
          isPublic: this.isPublicIndicator,
          isStandalone: this.isStandaloneIndicator,
        }),
      )
      .catch((err: Error) => {
        this.messages$.next({type: EEventHandlersReceived.Error, payload: err.message});
      });
  }

  private async publicStandaloneLogout(): Promise<void> {
    if (!(this.b2TraderAuth instanceof B2traderAuthPublicStandalone)) {
      return;
    }

    await firstValueFrom(this.b2TraderAuth.signOut())
      .then(() => this.privateHub?.stop())
      .then(() => this.stopOrdersHub?.stop())
      .then(() => {
        this.privateHub = undefined;
        this.stopOrdersHub = undefined;
        this.dispatch(EB2traderEventTypes.Orders, null);
        this.dispatch(EB2traderEventTypes.StopOrders, null);
        this.dispatch(EB2traderEventTypes.Balance, null);

        return this.reconnect({
          apiUrl: this.apiUrl,
          isPublic: this.isPublicIndicator,
          isStandalone: this.isStandaloneIndicator,
        });
      })
      .catch((err: Error) => {
        this.messages$.next({type: EEventHandlersReceived.Error, payload: err.message});
      });
  }

  /**
   * Wrapper for fromFetch function, which adds base url and auth header.
   *
   * @param url - Base url.
   * @param options - Request options.
   * @returns - Requse result observable.
   */
  private fromB2TraderFetch<T>(url: string, options: RequestInit): Observable<T> {
    return this.b2TraderAuth.fromFetch(url, options);
  }

  private reconnect(options: IApiOptions): Promise<void> {
    if (this.hub && !this.document.hidden) {
      void this.hub.stop();
    } else {
      void this.privateHub?.stop();
      return this.connect(options);
    }
  }

  private startWatchB2CoreApiUnauthorized(): void {
    this.b2TraderAuth.b2coreUnauthorized$.pipe(takeUntil(this.destroyer$)).subscribe(() => {
      this.messages$.next({type: ESharedEventHandlers.Unauthorized});
      this.messages$.next({type: EB2TraderEventHandlersReceived.RedirectHome});
    });
  }

  protected async connect({apiUrl, isPublic, isStandalone}: IApiOptions): Promise<void> {
    this.tradingBoardLoadingService.start();
    this.baseUrl = apiUrl;
    this.apiUrl = apiUrl;
    this.isPublicIndicator = isPublic;
    this.isStandaloneIndicator = isStandalone;
    this.destroyer$.next();

    // Sometimes, with takeUntil(destroyer$) this request cancelled, and we don't go next
    // TODO: This behaviour will be researched in https://b2btech.atlassian.net/browse/FDP-9388
    this.startWatchB2CoreApiUnauthorized();
    const apiData = await firstValueFrom(this.apiData$.pipe(first()));

    if (!apiData) {
      return;
    }

    this.isHubClosedManually = false;
    this.hub = new SignalRWrapper(`${apiData.url}/marketdata/v2/info/`, apiData.headers ?? {});

    if (apiData.headers) {
      this.isPrivateHubClosedManually = false;
      this.privateHub = new SignalRWrapper(`${apiData.url}/frontoffice/ws/v2/account`, apiData.headers ?? {});
      this.stopOrdersHub = new SignalRWrapper(`${apiData.url}/frontoffice/ws/stoporders`, apiData.headers ?? {});

      this.privateHub.onclose(() => {
        if (!this.isPrivateHubClosedManually && this.isReconnectToMarketDataHub) {
          this.isReconnectToMarketDataHub = false;
          this.destroyer$.next();
          return;
        }

        this.destroyer$.next();
        this.store.reset();
        void this.hub.stop();
        void this.stopOrdersHub.stop();
      });
    }

    this.hub.onclose(() => {
      if (!this.isHubClosedManually && !this.document.hidden) {
        this.isReconnectToMarketDataHub = true;
        void this.privateHub?.stop();
        void this.connect({apiUrl, isPublic, isStandalone});
        return;
      }

      this.destroyer$.next();
      this.store.reset();
      void this.privateHub?.stop();
    });

    this.messages$.next({type: ESharedEventHandlers.AfterConnect});
    await this.getInstruments();

    return this.hub
      .start()
      .then(() => this.startPrivate())
      .then(() => this.afterConnect())
      .catch(e => {
        if (e.statusCode === HttpStatusCode.Unauthorized) {
          this.b2TraderAuth.reset();
          void this.reconnect({apiUrl, isPublic, isStandalone});
        } else {
          this.destroyer$.next();
          this.store.reset();
          this.tradingBoardLoadingService.finish();
          this.messages$.next({type: EB2TraderEventHandlersReceived.RedirectHome});
        }
      });
  }

  protected override startPing(): void {
    if (this.isStandaloneIndicator && this.b2TraderAuth instanceof B2traderAuthStandalone) {
      this.b2TraderAuth.startPing();
    } else {
      super.startPing();
    }
  }

  protected get<T>(url: string, options?: {params?: THttpParams; headers?: HeadersInit}): Observable<T> {
    return this.fromB2TraderFetch(`${url}?${new URLSearchParams(options?.params).toString()}`, {
      method: 'GET',
      headers: options?.headers,
    });
  }

  protected post<T>(url: string, body: unknown, options?: {headers?: HeadersInit}): Observable<T> {
    return this.fromB2TraderFetch(`${this.frontOfficeUrl}/${url}`, {
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'content-type': 'application/json',
        ...options?.headers,
      },
      body: JSON.stringify(body),
    });
  }

  protected put<T>(url: string, body: unknown, options?: {headers?: HeadersInit}): Observable<T> {
    return this.fromB2TraderFetch(url, {
      method: 'PUT',
      headers: options?.headers,
      body: JSON.stringify(body),
    });
  }

  protected delete<T>(url: string, options?: {params?: THttpParams}): Observable<T> {
    return this.fromB2TraderFetch(`${this.frontOfficeUrl}/${url}?${new URLSearchParams(options?.params).toString()}`, {
      method: 'DELETE',
    });
  }

  public getTiers(): Observable<TradeFees> {
    return this.get<TradeFees>(`${this.frontOfficeUrl}/trade-fees`);
  }

  public async close(): Promise<void> {
    this.destroyer$.next();
    this.store.reset();
    this.isHubClosedManually = true;
    this.isPrivateHubClosedManually = true;
    await this.hub?.stop();
    await this.privateHub?.stop();
    await this.stopOrdersHub?.stop();
  }

  public postMessage(message: IDataEvent): void {
    switch (message.type) {
      case EWorkerEventTypes.Connect:
        void this.connect(message.payload as IApiOptions);
        break;

      case EB2TraderEventHandlersRequest.Book:
        this.requestOrderBook(message.payload as IFacadeSubscription);
        break;

      case EB2TraderEventHandlersRequest.SubscribeChart:
        this.subscribeChart(message.payload as ISubscribeChartRequest);
        break;

      case EB2TraderEventHandlersRequest.UnsubscribeChart:
        this.unsubscribeChart(message.payload as string);
        break;

      case EB2TraderEventHandlersRequest.ChartHistory:
        this.requestChartHistory(message.payload as IChartHistoryRequest);
        break;

      case EB2TraderEventHandlersRequest.CloseOrder:
        this.closeOrder(message.payload as ISingleRequestMessage<string>);
        break;
      case EB2TraderEventHandlersRequest.CloseStopOrder:
        this.closeStopOrder(message.payload as ISingleRequestMessage<string>);
        break;

      case EB2TraderEventHandlersRequest.HistoryOrder:
        if (this.privateHub?.isConnected) {
          void this.getHistoryOrder(message.payload as ISingleRequestMessage<IHistoryOrdersRequest>);
        } else {
          void this.getPublicHistoryOrder(message.payload as ISingleRequestMessage<IHistoryOrdersRequest>);
        }
        break;

      case EB2TraderEventHandlersRequest.Trades:
        this.getTrades(message.payload as IFacadeSubscription);
        break;

      case EB2TraderEventHandlersRequest.CreateOrder:
        this.createOrder(message.payload as ISingleRequestMessage<IOrderCreateRequest>);
        break;

      case EB2TraderEventHandlersRequest.CreateStopOrder:
        this.createStopOrder(message.payload as ISingleRequestMessage<ApiLibraryApiOrderInfoRequest>);
        break;

      case ESharedEventHandlers.EndRefreshTokens:
        this.messages$.next({
          type: ESharedEventHandlers.EndRefreshTokens,
          payload: message.payload as TokenResponse,
        });
        break;

      case EB2TraderEventHandlersRequest.PublicLogin:
        void this.publicStandaloneLogin(message.payload as ISingleRequestMessage<IPublicLoginDialogData>);
        break;
      case EB2TraderEventHandlersRequest.PublicLogout:
        void this.publicStandaloneLogout();
        break;

      default:
        return;
    }
  }
}
