import {IResultApi} from '../contracts/i.result.api';
import {Block} from '../models/block';
import {Exceptions} from './exceptions';

export class TooManyRequestsException extends Exceptions {
  public static readonly STATUS_CODE = 429;

  constructor(body: IResultApi) {
    super(body);
  }

  public getData(): Block {
    // return Model.make(new Block(), this.body.data.data.block) as Block;
    return Block.Make(this.body.data['data']['block']);
  }
}
