import {BreakpointObserver} from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {IWidgetSettings} from '@app/core/models/gridster/gridster-item';
import {GridsterWidgetSettings} from '@app/core/models/gridster/gridster-widget-settings';
import {SettingsStorageService} from '@app/core/modules/settings-storage/settings-storage.service';
import {filterNil} from '@app/core/utils/rxjs-filters';
import {WIDGET_ID_TOKEN} from '@app/gridster/tokens/widget-id-token';
import {AGridsterWidgetComponent} from '@app/gridster/widgets/gridster-widget.component.abstract';
import {ECommonWidgetKeys} from '@app/gridster/widgets/gridster-widgets';
import {DEFAULT_PRICE_SCALE} from '@app/pbsr/consts/default-price-scale.const';
import {WarpInstrumentsStoreService} from '@app/pbsr/services/warp-instruments-store/warp-instruments-store.service';
import {EThemeMode} from '@app/shared/modules/theme/entities/theme-mode';
import {ThemeService} from '@app/shared/modules/theme/services/theme.service';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {ATradeDatafeed} from '@app/trading-board/datafeed/trade-datafeed.abstract';
import {ETradingViewDisplayLinesOption} from '@app/trading-board/enum/b2margin/trading-view-display-lines-options';
import {ESynchronizationColors} from '@app/trading-board/enum/moex/synchronization-colors';
import {ETradingBoardProviderAlias} from '@app/trading-board/enum/provider-alias';
import {ICustomMAInterface} from '@app/trading-board/interfaces/b2margin/custom-ma';
import {ITradingViewState} from '@app/trading-board/interfaces/trading-view-state';
import {Instrument, WarpInstrument} from '@app/trading-board/models/instrument';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import {Tick} from '@app/trading-board/models/level1';
import {AComponentResolver, IReplaceableComponent} from '@app/trading-board/services/component-resolver.abstract';
import {MoexSynchronizeInstrumentService} from '@app/trading-board/services/moex-synchronize-instrument.service';
import {ParentComponentProvider} from '@app/trading-board/services/parent-component-provider';
import {ChartConfig} from '@app/trading-board/widgets/trading-view/chart-config';
import {ChartDataFeed} from '@app/trading-board/widgets/trading-view/datafeeds/chart-data-feed';
import {TradingViewSettings} from '@app/trading-board/widgets/trading-view/trading-view-settings';
import {
  IChartingLibraryWidget,
  IChartWidgetApi,
  ISubscription,
  LanguageCode,
  SeriesStyle,
  Timezone,
} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {widget as ChartingLibraryWidget} from '@b2broker/trading.view.charting.library/charting_library/charting_library.esm.js';
import {
  DatafeedConfiguration,
  ResolutionString,
} from '@b2broker/trading.view.charting.library/charting_library/datafeed-api';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Environment} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';
import {BehaviorSubject, combineLatest, firstValueFrom, from, merge, Observable, ReplaySubject, Subject} from 'rxjs';
import {
  buffer,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  shareReplay,
  skip,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {tvAvailableLanguageCodes} from './const/tv-available-language-codes';
import {WarpChartDatafeed} from './datafeeds/warp-chart-datafeed';
import {IBidAskPair, IPrice} from './instrfaces/bid-ask-pair.interface';
import {TMobileResolution} from './mobile-resolution';
import {TradingViewStateManager} from './state-manager/trading-view-state-manager';

@Component({
  templateUrl: './trading-view.component.html',
  styleUrls: ['./trading-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MatDialog, MoexSynchronizeInstrumentService],
})
export class TradingViewComponent extends AGridsterWidgetComponent<TradingViewSettings> implements OnInit, OnDestroy {
  private static readonly DEFAULT_CHART_RESOLUTION = '15' as ResolutionString;
  private static readonly DEFAULT_WARP_CHART_RESOLUTION = '1' as ResolutionString;

  private static readonly DEFAULT_CHART_SERIES_STYLE = SeriesStyle.HollowCandles;
  private static readonly DEFAULT_WARP_CHART_SERIES_STYLE = SeriesStyle.Candles;

  private readonly destroyer$ = new Subject<void>();
  private readonly chartResubscribe$ = new Subject<void>();
  private readonly chartCreated$ = new ReplaySubject<void>(1);

  private readonly isFirefox = window.navigator.userAgent.indexOf('Firefox') !== -1;

  private readonly settingsManager = this.settingsStorageService.get<string>(this.widgetId);
  private readonly loadStateTimeoutMs = 300;
  private readonly initialBidAskPair: IBidAskPair = {
    bid: {value: '-', isColoured: false},
    ask: {value: '-', isColoured: false},
  };

  private readonly themeColors =
    this.environment.widget.colors[this.themeService.getTheme()] || this.environment.widget.colors[EThemeMode.Dark];
  private readonly customMa: ICustomMAInterface[] = [
    {
      name: 'Moving Average',
      inputs: {length: 7},
      overrides: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'Plot.color': this.themeColors.MA_7,
        'Plot.linewidth': 1,
        /* eslint-enable @typescript-eslint/naming-convention */
      },
    },
    {
      name: 'Moving Average',
      inputs: {length: 21},
      overrides: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'Plot.color': this.themeColors.MA_21,
        'Plot.linewidth': 1,
        /* eslint-enable @typescript-eslint/naming-convention */
      },
    },
    {
      name: 'Moving Average',
      inputs: {length: 100},
      overrides: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'Plot.color': this.themeColors.MA_100,
        'Plot.linewidth': 1,
        /* eslint-enable @typescript-eslint/naming-convention */
      },
    },
  ];

  private isStateApplied = false;
  private isApplyStydies = false;

  private chartConfig: ChartConfig;
  private intervalChanges: ISubscription<(v: string) => void>;
  private chartTypeChanges: ISubscription<(chartType: SeriesStyle) => void>;

  private get widgetStateKey(): string {
    return `${this.widgetId}_${this.themeService.getTheme()}`;
  }

  public readonly pair$ = this.settings$.pipe(map(s => AGridsterWidgetComponent.getPair(s)));
  public readonly bidAskPair$ = new BehaviorSubject<IBidAskPair>(this.initialBidAskPair);

  public readonly containerId = `app-trading-view-chart-${Math.random()}`;

  public readonly isB2marginComponent = this.componentResolver.type === ETradingBoardProviderAlias.me;
  public readonly isMoexComponent = this.componentResolver.type === ETradingBoardProviderAlias.moex;

  public chart?: IChartingLibraryWidget;
  public activeChart: IChartWidgetApi;
  public activeInstrument: Instrument;
  public activeColor: ESynchronizationColors;

  public readonly currentInstrument$ = new ReplaySubject<Instrument>(1);
  public readonly displayOptionsWithTranslate = {
    [ETradingViewDisplayLinesOption.All]: marker('TradingBoard.Widgets.TradingView.DisplayOptions.PositionsWithOrders'),
    [ETradingViewDisplayLinesOption.Positions]: marker('TradingBoard.Widgets.TradingView.DisplayOptions.Positions'),
    [ETradingViewDisplayLinesOption.Limit]: marker('TradingBoard.Widgets.TradingView.DisplayOptions.LimitOrders'),
    [ETradingViewDisplayLinesOption.Stop]: marker('TradingBoard.Widgets.TradingView.DisplayOptions.StopOrders'),
  };

  public displayLinesState: Record<ETradingViewDisplayLinesOption, boolean> = {
    [ETradingViewDisplayLinesOption.All]: true,
    [ETradingViewDisplayLinesOption.Positions]: true,
    [ETradingViewDisplayLinesOption.Limit]: true,
    [ETradingViewDisplayLinesOption.Stop]: true,
  };

  public readonly isMobile$: Observable<TMobileResolution> = this.breakpointObserver
    .observe(['(max-width: 769px)'])
    .pipe(
      map(matcher => matcher.matches),
      startWith(null as boolean),
      map(isMatches => (isMatches ? 'mobile' : undefined)),
    );

  public readonly activeSymbol$ = this.settings$.pipe(
    debounceTime(10),
    map(settings => settings?.pair),
    switchMap(pair =>
      this.isMoexComponent
        ? this.getWarpInsrumentStream(pair)
        : this.datafeed.instruments$.pipe(
            map(
              instruments =>
                instruments.find(({symbolWithSeparator}) => pair === symbolWithSeparator)?.symbolWithSeparator,
            ),
          ),
    ),
    filterNil(),
  );

  public readonly activeSymbolChange$ = this.activeSymbol$.pipe(
    distinctUntilChanged(),
    takeUntil(this.destroyer$), // TODO(Dontsov): Need to check, and maybe just replace this with shareReplayWithRef()
    shareReplay(1),
  );
  public readonly initialize$ = combineLatest([this.activeSymbol$, this.themeService.currentTheme$]);

  public readonly resetChart$ = this.datafeed.finishedInitialize$;
  public readonly tvState$ = this.settingsManager.settings$.pipe(takeUntil(this.destroyer$), shareReplay(1));
  public readonly displayOptions = ETradingViewDisplayLinesOption;
  public readonly priceScale$ = this.currentInstrument$.pipe(
    map(instrument => instrument.priceScale ?? DEFAULT_PRICE_SCALE),
  );

  @ViewChild('settingsTemplate', {static: false})
  public widgetSettingsTemplate: TemplateRef<void>;

  constructor(
    private readonly datafeed: ATradeDatafeed,
    private readonly breakpointObserver: BreakpointObserver,
    public readonly container: ViewContainerRef,
    private readonly injector: Injector,
    private readonly translate: TranslateService,
    private readonly themeService: ThemeService,
    private readonly environment: Environment,
    private readonly settingsStorageService: SettingsStorageService,
    private readonly synchronizeInstrumentService: MoexSynchronizeInstrumentService,
    private readonly tradingViewStateManager: TradingViewStateManager,
    private readonly warpInstrumentsStoreService: WarpInstrumentsStoreService,
    @Inject(WIDGET_ID_TOKEN) private readonly widgetId: string,
    @Optional() private readonly componentResolver?: AComponentResolver,
  ) {
    super();
  }

  private saveState(): void {
    if (!this.chart) {
      return;
    }

    this.chart.save((state: ITradingViewState) => this.saveStateToSettings(_.cloneDeep(state)));
  }

  private saveStateToSettings(state?: ITradingViewState): void {
    if (!this.isStateApplied) {
      return;
    }

    const delta = this.tradingViewStateManager.getDeltaState(this.widgetStateKey, state);
    const encodedState = this.tradingViewStateManager.encodeState(delta as ITradingViewState);
    void firstValueFrom(this.settingsManager.set(encodedState));
  }

  private setChartEventHandlers(): void {
    this.chart.subscribe('onAutoSaveNeeded', this.saveState.bind(this));
  }

  private async subscribeToChartCreated(
    resolve: (value: void | PromiseLike<void>) => void,
    activeSymbol: string,
  ): Promise<void> {
    this.chartCreated$
      .pipe(
        first(),
        switchMap(() => this.activeSymbolChange$),
        skip(1),
        takeUntil(merge(this.destroyer$, this.chartResubscribe$)),
      )
      .subscribe(symbol => this.changeSymbol(symbol));

    this.chartReady();
    this.setChartEventHandlers();

    this.chart.save((currentState: ITradingViewState) => {
      this.tradingViewStateManager.setState(this.widgetStateKey, currentState);
    });
    const updatedState = await this.getUpdatedState();
    await this.loadState(updatedState, activeSymbol);
    this.isStateApplied = true;

    resolve();
  }

  private getChartLocale(): LanguageCode {
    const currentLocale = this.translate.currentLang;

    let resultLocale: LanguageCode;

    switch (currentLocale) {
      case 'da':
        resultLocale = 'da_DK';
        break;
      case 'nl':
        resultLocale = 'nl_NL';
        break;
      case 'et':
        resultLocale = 'et_EE';
        break;
      case 'he':
        resultLocale = 'he_IL';
        break;
      case 'hu':
        resultLocale = 'hu_HU';
        break;
      case 'id':
        resultLocale = 'id_ID';
        break;
      case 'sk':
        resultLocale = 'sk_SK';
        break;
      case 'ms':
        resultLocale = 'ms_MY';
        break;
      case 'zh':
        resultLocale = 'zh_TW';
        break;
      default:
        resultLocale = currentLocale as LanguageCode;
    }

    return tvAvailableLanguageCodes.includes(resultLocale) ? resultLocale : 'en';
  }

  private async getUpdatedState(): Promise<ITradingViewState> {
    const encodedState = await firstValueFrom(this.tvState$.pipe(first()));
    this.isApplyStydies = !encodedState;
    let updatedState: ITradingViewState | undefined = undefined;
    if (!_.isNil(encodedState)) {
      updatedState = this.tradingViewStateManager.decodeState(encodedState);

      if (_.isNil(updatedState)) {
        this.isApplyStydies = true;
      }
    }
    updatedState = this.tradingViewStateManager.getMergedState(this.widgetStateKey, updatedState);

    return updatedState;
  }

  public get interval(): ResolutionString {
    const defaultResolution = this.isMoexComponent
      ? TradingViewComponent.DEFAULT_WARP_CHART_RESOLUTION
      : TradingViewComponent.DEFAULT_CHART_RESOLUTION;

    return this.settings$.value?.currentInterval ?? defaultResolution;
  }

  public get chartType(): SeriesStyle {
    const seriesStyle = this.isMoexComponent
      ? TradingViewComponent.DEFAULT_WARP_CHART_SERIES_STYLE
      : TradingViewComponent.DEFAULT_CHART_SERIES_STYLE;

    return this.settings$.value?.seriesStyle ?? seriesStyle;
  }

  public changeSymbol(symbol: string): void {
    try {
      this.chart?.setSymbol(symbol, this.interval, void 0);
    } catch (e) {
      console.warn(e);
    }
  }

  public async changeTheme(theme: EThemeMode): Promise<void> {
    try {
      if (this.chart?.getTheme() !== this.chartConfig?.theme) {
        if (theme === EThemeMode.Light) {
          await this.chart?.changeTheme('Light');
          this.chartConfig.theme = 'Light';
        } else {
          await this.chart?.changeTheme('Dark');
          this.chartConfig.theme = 'Dark';
        }

        this.chartConfig.setOverrides(this.isMoexComponent);

        const chartType = this.activeChart.chartType();
        this.chart?.applyOverrides(this.chartConfig.overrides);
        this.activeChart.setChartType(chartType);

        this.chart.save((currentState: ITradingViewState) => {
          this.tradingViewStateManager.setState(this.widgetStateKey, currentState);
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  private getWarpInsrumentStream(symbol: string): Observable<string> {
    return this.warpInstrumentsStoreService.getInstrument(symbol).pipe(
      filterNil(),
      tap(instrument => this.currentInstrument$.next(instrument)),
      map(instrument => instrument.symbolWithSeparator),
    );
  }

  public async createChart(
    configuration: DatafeedConfiguration,
    symbol: string,
    preset: TMobileResolution = undefined,
  ): Promise<void> {
    this.removeChart();

    const chartReadyPromise = new Promise<void>(resolve => {
      this.chartConfig = ChartConfig.create(
        {
          preset,
          interval: this.interval,
          locale: this.getChartLocale(),
          container: this.containerId,
          theme: this.themeService.getTheme(),
          symbol,
          datafeed: this.isMoexComponent
            ? new WarpChartDatafeed(
                this.datafeed as MoexDatafeedService,
                configuration,
                this.warpInstrumentsStoreService,
              )
            : new ChartDataFeed(this.datafeed, configuration),
          supportResolutions: configuration.supported_resolutions,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
          seriesStyle: this.chartType,
        },
        this.environment,
        this.isMoexComponent,
      );

      this.chart = new ChartingLibraryWidget(this.chartConfig);

      this.chart
        .headerReady()
        .then(async () => await this.subscribeToChartCreated(resolve, symbol))
        .catch(() => {
          if (_.isNil(this.chart)) {
            return;
          }

          this.chart.onChartReady(async () => await this.subscribeToChartCreated(resolve, symbol));
        });
    });

    this.themeService.themeChange$
      .pipe(
        buffer(from(chartReadyPromise)),
        switchMap(buf => this.themeService.themeChange$.pipe(startWith(buf[buf.length - 1]))),
        filterNil(),
        takeUntil(this.destroyer$),
      )
      .subscribe(theme => this.changeTheme(theme));

    return chartReadyPromise;
  }

  public loadState(oldState: ITradingViewState, symbol: string): Promise<void> {
    if (this.isFirefox) {
      this.hideSkeleton();
    }

    return new Promise(resolve => {
      this.chart.save((currentState: ITradingViewState) => {
        setTimeout(() => {
          if (!this.chart) {
            return;
          }

          try {
            oldState = this.tradingViewStateManager.patchTradingViewStateSymbol(oldState, symbol);
            this.chart.load(oldState);
            this.tradingViewStateManager.setState(this.widgetStateKey, oldState ?? currentState);
          } catch (error) {
            console.warn('Failed to load TV state', error);
            this.chart.load(currentState);
            this.tradingViewStateManager.setState(this.widgetStateKey, currentState);
          } finally {
            if (this.isApplyStydies && !this.environment.isPbsVendor) {
              this.createStudies(this.customMa);
            }
            this.saveState();
            this.hideSkeleton();
            resolve();
          }
        }, this.loadStateTimeoutMs);
      });
    });
  }

  public createStudies(studies: ICustomMAInterface[]): void {
    studies.forEach(data => {
      void this.activeChart.createStudy(data.name, false, false, data.inputs, null, data.overrides);
    });
  }

  public removeChart(): void {
    this.chart?.remove();
    this.chartResubscribe$.next();
    this.chart = undefined;
  }

  public toggleOption(isActive: boolean, option: string): void {
    this.displayLinesState = {...this.displayLinesState, [option]: isActive};
  }

  public isDisplayLineOptionHidden(optionKey: string): boolean {
    return !this.displayLinesState.ALL && optionKey !== ETradingViewDisplayLinesOption.All;
  }

  public selectInstrument(instrument: Instrument, isSynchronizeInstrument = true): void {
    const settings: TradingViewSettings = this.settings$.value;

    this.currentInstrument$.next(instrument);
    if (settings.pair === instrument.symbolWithSeparator) {
      return;
    }

    settings.pair = instrument.symbolWithSeparator;
    this.settings$.next(settings);

    if (this.isMoexComponent && isSynchronizeInstrument) {
      this.synchronizeInstrumentService.synchronizeInstrument(
        instrument as WarpInstrument,
        ECommonWidgetKeys.tradingView,
        this.activeColor,
      );
    }
  }

  public async ngOnInit(): Promise<void> {
    await this.providePlatformView();

    if (this.isMoexComponent) {
      this.subscribeOnBidAskPairUpdates();
    }
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();

    this.chartResubscribe$.next();
    this.chartResubscribe$.complete();
    this.settingsManager.destroy();

    this.container.remove();
    this.removeChart();
  }

  public chartReady(): void {
    this.activeChart = this.chart.activeChart();

    this.chartCreated$.next();
    this.intervalChanges?.unsubscribeAll(null);
    this.chartTypeChanges?.unsubscribeAll(null);
    this.intervalChanges = this.activeChart.onIntervalChanged();
    this.chartTypeChanges = this.activeChart.onChartTypeChanged();
    this.intervalChanges.subscribe(
      null,
      (currentInterval: ResolutionString) => {
        const settings = this.settings$.value;

        settings.currentInterval = currentInterval;
        this.settings$.next(settings);
      },
      false,
    );

    this.chartTypeChanges.subscribe(
      null,
      (chartType: SeriesStyle) => {
        const settings = this.settings$.value;

        settings.seriesStyle = chartType;
        this.settings$.next(settings);
      },
      false,
    );
  }

  public async providePlatformView(): Promise<void> {
    const factoryFn = this.componentResolver?.getComponent<IReplaceableComponent>(TradingViewComponent);

    if (factoryFn) {
      const {entryPoint} = await factoryFn();

      const component = this.container.createComponent(entryPoint, {
        index: undefined,
        injector: Injector.create({
          providers: [{provide: ParentComponentProvider, useExisting: forwardRef(() => TradingViewComponent)}],
          parent: this.injector,
        }),
      });
      component.changeDetectorRef.detectChanges();
    }
  }

  public getDefaultSettings(): GridsterWidgetSettings {
    return TradingViewSettings.getInstance();
  }

  public makeSettings(config: IWidgetSettings): GridsterWidgetSettings {
    return TradingViewSettings.makeInstance(config);
  }

  public trackByDisplayOption = (index: number): number => index;

  public unsortedOrder = (): number => 0;

  public isMenuOpen(trigger: MatMenuTrigger): boolean {
    return trigger.menuOpen;
  }

  private subscribeOnBidAskPairUpdates(): void {
    this.settings$
      .pipe(
        map(setting => setting?.pair),
        filter(symbol => Boolean(symbol)),
        pairwise(),
        switchMap(([prev, next]) => {
          const symbolsToRemove = prev ? [prev] : [];
          this.datafeed.updateQuotesSubscriptions(symbolsToRemove, [next]);

          return this.datafeed.quotes$.pipe(map(data => data.get(next)));
        }),
        takeUntil(this.destroyer$),
      )
      .subscribe(tick => this.updateBidAskPair(tick));
  }

  private updateBidAskPair(tick: Tick): void {
    const bidAskPair = tick ? this.getBidAskPair(tick) : this.initialBidAskPair;

    this.bidAskPair$.next(bidAskPair);
  }

  private getBidAskPair(tick: Tick): IBidAskPair {
    return {
      bid: this.getPrice(tick.bestBid),
      ask: this.getPrice(tick.bestOffer),
    };
  }

  private getPrice(value: LazyDecimalHelper): IPrice {
    const isZero = value.isZero();

    return {
      value: isZero ? '-' : value.toString(),
      isColoured: !isZero,
    };
  }
}
