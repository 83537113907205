import {Injectable} from '@angular/core';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import {Level1Collector} from '@app/trading-board/models/level1-collector';

import {B2MarginDatafeedService} from '../../datafeed/b2margin-datafeed.service';
import {Tick} from '../../models/level1';
import {AEventHandler} from '../event-handler.abstract';
import {EEventHandlersReceived} from '../event-handlers.enum';

export interface ILevel1Price {
  bestOffer: LazyDecimalHelper;
  bestBid: LazyDecimalHelper;
}

@Injectable()
export class B2marginLevel1Service extends AEventHandler<Tick[]> {
  public type = EEventHandlersReceived.Ticks;
  public pricesMap = new Map<string, ILevel1Price>();

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public reduceTicks(ticks: Tick[], level1Collector: Level1Collector): Level1Collector {
    return ticks.reduce((acc, item) => {
      if (this.pricesMap.has(item.instrumentId)) {
        const prices = this.pricesMap.get(item.instrumentId);

        item.setPriceChangesB2Margin(prices.bestOffer, prices.bestBid);

        prices.bestOffer = item.bestOffer;
        prices.bestBid = item.bestBid;
      } else {
        const {instrumentId, bestOffer, bestBid} = item;

        this.pricesMap.set(instrumentId, {bestOffer, bestBid});
      }

      return acc.set(item.instrumentId, item);
    }, level1Collector);
  }

  public handleMessage(resource: Tick[]): void {
    const level1Collector = this.b2marginDatafeedService.level1$.getValue();
    const level1: Level1Collector = this.reduceTicks(resource, level1Collector);

    this.b2marginDatafeedService.level1$.next(level1);

    if (this.pricesMap.size !== level1.size) {
      this.pricesMap.forEach((price: ILevel1Price, instrumentId: string) => {
        if (!level1.has(instrumentId)) {
          this.pricesMap.delete(instrumentId);
        }
      });
    }

    level1Collector.update();
  }
}
