export enum ETicketStatusName {
  Open = 'Open',
  NeedToReply = 'Need to reply',
  AwaitingReply = 'Awaiting Reply',
  InProgress = 'In-Progress',
  Pending = 'Pending',
  Resolved = 'Resolved',
  Duplicate = 'Duplicate',
  Declined = 'Declined',
  Closed = 'Closed',
  NeedToProcess = 'Need to process',

  // TODO: Remove in https://b2btech.atlassian.net/browse/FDP-17439
  OpenRu = 'Новый',
  NeedToReplyRu = 'Нужен ответ',
  InProgressRu = 'В процессе',
  AwaitingReplyRu = 'Ожидает ответа',
  PendingRu = 'В ожидании',
  ResolvedRu = 'Решен',
  ClosedRu = 'Закрыт',
  DuplicateRu = 'Дублирован',
  DeclinedRu = 'Отклонен',
}
