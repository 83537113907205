import {InstrumentTO} from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class InstrumentsInternalEventHandler extends AEventHandler<IB2marginMessage<InstrumentTO[]>> {
  public type = EB2MarginEventType.Instruments;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<InstrumentTO[]>): void {
    const {value: instruments} = this.store.instruments$;

    resource.body.forEach(instrument =>
      instrument.open ? instruments.set(instrument.id, instrument) : instruments.delete(instrument.id),
    );

    this.store.instruments$.next(instruments);
  }
}

b2MarginInternalEventHandlers.push(InstrumentsInternalEventHandler);
