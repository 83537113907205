import {IChartRequestParams} from '@app/trading-board/interfaces/b2trader/chart-request-params';

export class ChartRequest {
  public static mapResolution(resolution: number | string): string {
    switch (resolution) {
      case '360':
        return '6h';
      case '240':
        return '4h';
      case '720':
        return '12h';
      case '60':
        return '1h';
      case '1D':
      case 'D':
        return '1d';
      case 'W':
      case '1W':
        return '1w';
      case 'M':
      case '1M':
        return '1M';
      case '1':
      case '5':
      case '15':
      case '30':
        return String(resolution) + 'm';
      default:
        throw new Error('Unknown resolution');
    }
  }

  public static getResolutionInMinutes(resolution: string): number {
    switch (resolution) {
      case '1':
      case '5':
      case '15':
      case '30':
      case '60':
      case '240':
      case '360':
      case '720':
        return Number(resolution);
      case '1D':
      case 'D':
        return 1440;
      case 'M':
      case '1M':
        return 43800;
      case 'W':
      case '1W':
        return 10080;
      default:
        throw new Error('Unknown resolution');
    }
  }

  public static mapSymbol(symbolWithSeparator: string, separator: string = '_'): string {
    return symbolWithSeparator.replace('/', separator).toLowerCase();
  }

  public static getInstrumentParams(from: number, to: number, resolution: string): IChartRequestParams {
    return {
      startDate: new Date(from * 1000).toISOString().split('.')[0],
      endDate: new Date(to * 1000).toISOString().split('.')[0],
      type: ChartRequest.mapResolution(resolution),
      count: '960',
    };
  }
}
