import {Casts, CastTypes, Model} from '@app/core/model';

export class Variants extends Model {
  public leverage: string[];
  public instruments: string[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public start_amounts: string[];

  public readonly casts: Casts = {
    leverage: [[CastTypes.String], [CastTypes.Number]],
    instruments: [[CastTypes.String], CastTypes.Undefined],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_amounts: [[CastTypes.String], [CastTypes.Number], CastTypes.Undefined],
  };
}
