import {Type} from '@angular/core';
import {B2traderBalancesService} from '@app/trading-board/event-handlers/b2trader/b2trader-balances.service';
import {B2TraderErrorHandlerService} from '@app/trading-board/event-handlers/b2trader/b2trader-error-handler.service';
import {B2traderInstrumentsService} from '@app/trading-board/event-handlers/b2trader/b2trader-instruments.service';
import {B2traderLevel1Service} from '@app/trading-board/event-handlers/b2trader/b2trader-level1.service';
import {B2traderLevel2Service} from '@app/trading-board/event-handlers/b2trader/b2trader-level2.service';
import {B2traderOrdersService} from '@app/trading-board/event-handlers/b2trader/b2trader-orders.service';
import {B2traderRedirectHomeService} from '@app/trading-board/event-handlers/b2trader/b2trader-redirect-home.service';
import {B2traderServerTimeService} from '@app/trading-board/event-handlers/b2trader/b2trader-server-time.service';
import {B2traderStopOrdersService} from '@app/trading-board/event-handlers/b2trader/b2trader-stop-orders.service';
import {B2traderSubscribeChartService} from '@app/trading-board/event-handlers/b2trader/b2trader-subscribe-chart.service';
import {B2traderTiersService} from '@app/trading-board/event-handlers/b2trader/b2trader-tiers.service';
import {B2traderTradesService} from '@app/trading-board/event-handlers/b2trader/b2trader-trades.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {SharedAfterConnectService} from '@app/trading-board/event-handlers/shared/shared-after-connect.service';
import {SharedRefreshTokensHandlerService} from '@app/trading-board/event-handlers/shared/shared-refresh-tokens-handler.service';
import {SharedUnauthorizedService} from '@app/trading-board/event-handlers/shared/shared-unauthorized.service';

export const B2TraderEventHandlersServices: Type<AEventHandler<unknown>>[] = [
  B2traderInstrumentsService,
  B2traderLevel1Service,
  B2traderLevel2Service,
  B2traderOrdersService,
  B2traderStopOrdersService,
  B2traderTradesService,
  B2traderBalancesService,
  B2traderTiersService,
  B2traderServerTimeService,
  B2traderRedirectHomeService,
  B2TraderErrorHandlerService,
  SharedUnauthorizedService,
  SharedAfterConnectService,
  B2traderSubscribeChartService,
  SharedRefreshTokensHandlerService,
];
