import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'keys',
})
export class KeysPipe<T> implements PipeTransform {
  public transform(value: Record<string, T>): string[] {
    return value ? Object.keys(value) : [];
  }
}
