<ng-container [formGroup]="formGroup">
  <mat-form-field class="password-input">
    <input
      matInput
      autocomplete="”off”"
      [type]="type"
      [tooltipIfLongLabel]="label"
      [formControl]="control"
      [required]="required"
      [appFieldAttributes]="field.attributes"
    />

    <mat-hint *ngIf="field.label.hint" [align]="field.label.hint.position || 'start'">
      {{ getHintLabel() | async }}
    </mat-hint>

    <button type="button" class="toggle-password-button" (click)="toggleType()">
      <app-icon *ngIf="!isShowPassword" icon="eye-opened"></app-icon>

      <app-icon *ngIf="isShowPassword" icon="eye-closed"></app-icon>
    </button>

    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>

    <mat-label #label>
      {{ getLabel() | async }}
    </mat-label>
  </mat-form-field>
</ng-container>
