export class Rights {
  /**
   * Enabled account.
   */
  public static readonly RIGHTS_ENABLED = 1 << 0;

  /**
   * Allow trade - account.
   */
  public static readonly RIGHTS_TRADE_ENABLED = 1 << 1;

  /**
   * Allow deposit.
   */
  public static readonly RIGHTS_DEPOSIT = 1 << 2;

  /**
   * Allow withdraw.
   */
  public static readonly RIGHTS_WITHDRAW = 1 << 3;

  /**
   * Allow show account.
   */
  public static readonly RIGHTS_VISIBLE = 1 << 4;

  /**
   * Allow transfer to account.
   */
  public static readonly RIGHTS_TRANSFER_DEPOSIT = 1 << 5;

  /**
   * Allow transfer from account.
   */
  public static readonly RIGHTS_TRANSFER_WITHDRAW = 1 << 6;

  /**
   * Allow exchange.
   */
  public static readonly RIGHTS_EXCHANGE = 1 << 7;

  /**
   * Deny account creation from TR.
   */
  public static readonly RIGHTS_DENY_ACCOUNT_CREATE_TR = 1 << 8;

  /**
   * Allow bidirectional transfer.
   */
  public static readonly RIGHTS_TRANSFER = Rights.RIGHTS_TRANSFER_DEPOSIT | Rights.RIGHTS_TRANSFER_WITHDRAW;
}

export function checkRight(entity: {rights: number}, rights: number): boolean {
  return (entity.rights & rights) === rights;
}
