import {Pipe, PipeTransform} from '@angular/core';
import {TPreciseFlag, precise} from '@app/shared/utils/precision';

@Pipe({
  name: 'precision',
})
export class PrecisionPipe implements PipeTransform {
  public transform(value: string | number, precision?: number, flag?: TPreciseFlag): string {
    return precise(value, precision, flag);
  }
}
