import {Decimal} from 'decimal.js';
import * as _ from 'lodash-es';

export class DecimalHelper {
  public static locale: string;

  public get decimalInstance(): Decimal {
    return this.init;
  }

  public get value(): string {
    return this.init.toFixed();
  }

  public set value(value: string) {
    this.init = new Decimal(value);
  }
  public static max(...n: DecimalHelper[]): DecimalHelper {
    let [base] = n;

    for (let i = 1; i < n.length; i++) {
      if (base.init.lessThan(n[i].init)) {
        base = n[i];
      }
    }

    return base;
  }

  public static from(value: Decimal.Value | DecimalHelper, scale?: number): DecimalHelper {
    let dec: DecimalHelper;

    if (value instanceof DecimalHelper) {
      dec = new DecimalHelper(value.decimalInstance);
      dec.scale = value.scale;
    } else {
      dec = new DecimalHelper(value);
    }

    if (!_.isNil(scale)) {
      dec.scale = scale;
    }

    return dec;
  }

  public static min(...n: DecimalHelper[]): DecimalHelper {
    let [base] = n;

    for (let i = 1; i < n.length; i++) {
      if (base.init.greaterThan(n[i].init)) {
        base = n[i];
      }
    }

    return base;
  }

  private init: Decimal;

  public scale = 8;

  protected constructor(value: Decimal.Value = 0) {
    this.init = new Decimal(value);
  }

  public equals(n: DecimalHelper | Decimal.Value): boolean {
    if (n instanceof DecimalHelper) {
      n = n.init;
    }

    return this.init.equals(n);
  }

  public negated(): DecimalHelper {
    this.init = this.init.negated();

    return this;
  }

  public pow(n: Decimal.Value): DecimalHelper {
    this.init = this.init.pow(n);
    return this;
  }

  public setValue(value: Decimal.Value = 0): void {
    this.init = new Decimal(value);
  }

  public getValue(): string {
    return this.scale ? this.getFixed(this.scale) : this.value;
  }

  public getFixed(scale: number): string {
    return this.init.toFixed(scale, Decimal.ROUND_DOWN);
  }

  public plus(addend: string | number | DecimalHelper): DecimalHelper {
    const result = new DecimalHelper();

    if (!(addend instanceof DecimalHelper)) {
      addend = new DecimalHelper(addend);
    }

    result.scale = Math.min(this.scale, addend.scale);
    result.init = this.init.plus(addend.init);
    return result;
  }

  public minus(subtrahend: string | number | DecimalHelper): DecimalHelper {
    const result = new DecimalHelper();

    if (!(subtrahend instanceof DecimalHelper)) {
      subtrahend = new DecimalHelper(subtrahend);
    }

    result.scale = Math.min(this.scale, subtrahend.scale);
    result.init = this.init.minus(subtrahend.init);
    return result;
  }

  public multiply(multiplier: string | number | DecimalHelper): DecimalHelper {
    const result = new DecimalHelper();

    if (!(multiplier instanceof DecimalHelper)) {
      multiplier = new DecimalHelper(multiplier);
    }

    result.scale = Math.min(this.scale, multiplier.scale);
    result.init = this.init.mul(multiplier.init);
    return result;
  }

  public divide(divider: Decimal.Value | DecimalHelper): DecimalHelper {
    const result = new DecimalHelper();

    if (!(divider instanceof DecimalHelper)) {
      divider = new DecimalHelper(divider);
    }

    result.scale = Math.min(this.scale, divider.scale);
    result.init = this.init.div(divider.init);
    return result;
  }

  public isPositive(): boolean {
    return this.init.isPositive();
  }

  public isNegative(): boolean {
    return this.init.isNegative();
  }

  public isZero(): boolean {
    return this.init.isZero();
  }

  public parseFloat(): number {
    return Number.parseFloat(this.value);
  }

  public toString(): string {
    if (this.scale) {
      return this.init.toDecimalPlaces(this.scale, Decimal.ROUND_DOWN).toFixed(this.scale);
    }

    return this.init.toFixed(this.scale);
  }

  public toDecimalPlaces(places: number, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): DecimalHelper {
    this.init = this.init.toDecimalPlaces(places, rounding);

    return this;
  }

  public abs(): DecimalHelper {
    this.init = this.init.abs();

    return this;
  }

  public toJson(): string {
    if (this.scale) {
      return this.init.toDecimalPlaces(this.scale, Decimal.ROUND_DOWN).toJSON();
    }

    return this.init.toJSON();
  }

  public isFinite(): boolean {
    return this.init.isFinite();
  }

  public greaterThan(n: Decimal.Value | DecimalHelper): boolean {
    if (n instanceof DecimalHelper) {
      return this.init.greaterThan(n.init);
    }

    return this.init.greaterThan(n);
  }

  public lessThan(n: Decimal.Value | DecimalHelper): boolean {
    if (n instanceof DecimalHelper) {
      return this.init.lessThan(n.init);
    }

    return this.init.lessThan(n);
  }

  public toNumber(): number {
    if (this.scale) {
      return this.init.toDecimalPlaces(this.scale, Decimal.ROUND_DOWN).toNumber();
    }

    return this.init.toNumber();
  }

  public setScale(scale: number): DecimalHelper {
    this.scale = scale;
    this.init = this.init.toDecimalPlaces(this.scale, Decimal.ROUND_DOWN);
    return this;
  }

  public lessThanOrEqualTo(value: Decimal.Value | DecimalHelper): boolean {
    return this.init.lessThanOrEqualTo(value instanceof DecimalHelper ? value.init : value);
  }

  public isNotNaN(): boolean {
    return !this.init.isNaN();
  }

  public isNaN(): boolean {
    return this.init.isNaN();
  }

  public round(): DecimalHelper {
    this.init = this.init.round();

    return this;
  }

  public decimalPlaces(): number {
    return this.init.decimalPlaces();
  }

  public greaterThanOrEqualTo(n: Decimal.Value | DecimalHelper): boolean {
    if (n instanceof DecimalHelper) {
      n = n.init;
    }

    return this.init.greaterThanOrEqualTo(n);
  }

  public [Symbol.toPrimitive](hint: 'string' | 'default'): string;
  public [Symbol.toPrimitive](hint: 'number'): number;
  public [Symbol.toPrimitive](hint: string): string | number {
    if (!_.isNil(this.scale)) {
      switch (hint) {
        case 'number':
          return this.init.toDecimalPlaces(this.scale, Decimal.ROUND_DOWN).toNumber();

        case 'string':
        case 'default':
        default:
          // eslint-disable-next-line no-case-declarations
          let n = this.init.toDecimalPlaces(this.scale, Decimal.ROUND_DOWN);
          if (!n.isFinite()) {
            return 0;
          }

          if (n.isZero() && n.isNegative()) {
            n = n.abs();
          }

          return n.toNumber().toLocaleString(DecimalHelper.locale, {minimumFractionDigits: n.dp()});
      }
    }

    switch (hint) {
      case 'number':
        return this.init.toNumber();
      case 'string':
      case 'default':
      default:
        return this.init.toFixed();
    }
  }

  public isNotZero(): boolean {
    return !this.init.isZero();
  }

  public greaterThanZero(): boolean {
    return this.init.greaterThan(0);
  }

  public lessThanZero(): boolean {
    return this.init.lessThan(0);
  }

  public getInstanceWithNanFallback<T>(param: T): T | this {
    if (this.isNotNaN()) {
      return this;
    }

    return param;
  }
}
