import {SafeUrl} from '@angular/platform-browser';

export class FileKeeper {
  public files: File[] = [];

  public readonly safeUrls: SafeUrl[] = [];
  private readonly unsafeUrls: string[] = [];

  constructor(private readonly sanitizer: (str: string) => SafeUrl) {}

  public addFile(file: File): void {
    this.files = this.files.concat([file]);
    const unsafeUrl: string = FileKeeper.getFileUrl(file);
    this.unsafeUrls.push(unsafeUrl);
    const safeUrl: SafeUrl = this.sanitizer(unsafeUrl);
    this.safeUrls.push(safeUrl);
  }

  public addFiles(files: File[]): void {
    files.forEach((f: File) => this.addFile(f));
  }

  public removeFile(i: number): void {
    this.files.splice(i, 1);
    this.safeUrls.splice(i, 1);
    const url: string = this.unsafeUrls.splice(i, 1)[0];
    FileKeeper.clearUrlMemory(url);
  }

  public clear(): void {
    this.files.length = 0;
    this.safeUrls.length = 0;
    this.unsafeUrls.forEach(FileKeeper.clearUrlMemory);
    this.unsafeUrls.length = 0;
  }

  private static getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  private static clearUrlMemory(url: string): void {
    URL.revokeObjectURL(url);
  }
}
