import {ETradingViewDisplayLinesOption} from '@app/trading-board/enum/b2margin/trading-view-display-lines-options';
import {ETradingViewLineType} from '@app/trading-board/enum/b2margin/trading-view-line-type';
import {ITradingViewLine} from '@app/trading-board/interfaces/b2margin/trading-view-line';
import {Position} from '@app/trading-board/models/b2margin/position';
import {ADataStoreModel} from '@app/trading-board/models/data-store-model';
import {OrderTemplateTO} from '@b2broker/b2margin-trade-models';
import {OrderTO} from '@b2broker/b2margin-trade-models/dist/models/orderTO';

import {B2MarginOpenOrder} from './b2margin/b2-margin-open-order';

import OrderSideEnum = OrderTemplateTO.OrderSideEnum;

export class TradingViewLine extends ADataStoreModel<ITradingViewLine> {
  public static adaptPosition(position: Position, plLabel: string): ITradingViewLine {
    return {
      id: position.id,
      price: position.fillPrice.getValue(),
      size: position.size.value,
      instrumentId: position.instrumentId,
      text: `${plLabel} ${position.plOpen.value}`,
      side: position.side,
      isTextNegative: position.isPlNegative,
      lineType: ETradingViewLineType.Position,
      displayType: ETradingViewDisplayLinesOption.Positions,
    };
  }

  public static adaptOrder(order: B2MarginOpenOrder): ITradingViewLine {
    return {
      id: `${order?.orderId}`,
      price: order.fillPrice.getValue(),
      size: order.size.value,
      instrumentId: order.instrumentId,
      side: order.side,
      text: order.type,
      type: order.type,
      lineType: ETradingViewLineType.Order,
      displayType:
        order.type === OrderTO.TypeEnum.LIMIT
          ? ETradingViewDisplayLinesOption.Limit
          : ETradingViewDisplayLinesOption.Stop,
    };
  }

  public static reducePosition(positions: Position[], plLabel: string): Map<string, ITradingViewLine> {
    return positions.reduce((acc: Map<string, ITradingViewLine>, position: Position) => {
      if (position.plOpen.isNaN()) {
        return acc;
      }
      return acc.set(position.id, TradingViewLine.adaptPosition(position, plLabel));
    }, new Map<string, ITradingViewLine>());
  }

  public static reduceOrder(orders: B2MarginOpenOrder[]): Map<string, ITradingViewLine> {
    return orders.reduce((acc: Map<string, ITradingViewLine>, order: B2MarginOpenOrder) => {
      return acc.set(order.orderId.toString(), TradingViewLine.adaptOrder(order));
    }, new Map<string, ITradingViewLine>());
  }

  public id: string;
  public price: number;
  public instrumentId?: string;
  public text: string;
  public size: string;
  public isTextNegative?: boolean;
  public currentPrice?: string;
  public side: OrderSideEnum;
  public lineType: ETradingViewLineType;
  public displayType: ETradingViewDisplayLinesOption;
  public type?: string;

  public update(item: ITradingViewLine): void {
    this.text = item.text;
  }
}
