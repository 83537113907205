import {Casts, CastTypes, Model} from '@app/core/model';
import {Message} from '@app/helpdesk/models/message';
import {Ticket} from '@app/helpdesk/models/ticket';

export class MessageNotificationEvent extends Model {
  public ticket?: Ticket;
  public message?: Message;

  public readonly casts: Casts = {
    ticket: [CastTypes.Null, Ticket],
    message: [CastTypes.Null, Message],
  };
}
