<ng-container *ngFor="let formGroup of formArray.controls; let index = index; trackBy: trackByControl">
  <div *ngIf="isShowDeleteButton && (isFirstItemHidden || index > 0)" class="wrap-add-stroke wrapp-delete-btn">
    <button mat-button type="button" (click)="deleteFormGroupByIndex(index)">
      <app-icon icon="delete"></app-icon>
      <span>{{ deleteTranslateKey || 'GroupList.Button.Delete' | translate }}</span>
    </button>
  </div>

  <ng-container
    *ngTemplateOutlet="
      template ?? defaultTemplate;
      context: {formGroup: formGroup, fields: fieldsList[index], index: index}
    "
  ></ng-container>
</ng-container>

<div class="wrap-add-stroke">
  <button *ngIf="isAddButtonVisible" mat-button type="button" color="accent" (click)="addNewFormGroup()">
    <app-icon icon="add-stroke"></app-icon>
    <span>{{ addTranslateKey || 'GroupList.Button.Add' | translate }}</span>
  </button>
</div>

<!--#region Templates-->
<ng-template #defaultTemplate let-formGroup="formGroup" let-fields="fields">
  <app-form-group [form]="formGroup" [fields]="fields"></app-form-group>
</ng-template>
<!--#endregion Templates-->
