import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {ITransferParams} from '@app/core/contracts/i.trasnsfer.params';
import {mapException} from '@app/core/exceptions/client.exception';
import {isResponse} from '@app/core/exceptions/response-exception';
import {Account} from '@app/core/models/account/account';
import {IB2copyAccountCreatedData} from '@app/core/models/account/investment/b2copy-account-created-data.interface';
import {Report} from '@app/core/models/account/report';
import {RatesPairs} from '@app/core/models/rates-pairs';
import {IPayoutParams} from '@app/payout/payout.helpers';
import {Public} from '@app/shared/decorators/public.decorator';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash-es';
import {BehaviorSubject, EMPTY, Observable, of, retry, Subject, throwError, timer} from 'rxjs';
import {catchError, map, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {IFailedReportData} from '../models/account/interfaces/failed-report-data.interface';
import {bufferDebounceTime} from '../utils/buffer-debounce-time';
import {EWsEvents, WebSocketService} from './web-socket.service';

@Injectable({providedIn: 'root'})
export class AccountService {
  private static readonly ACCOUNTS_DEBOUNCE_TIME_IN_MS = 500;

  private readonly report$ = new Subject<Report>();
  private readonly failedReport$ = new BehaviorSubject<IFailedReportData | null>(null);
  private readonly reloadAsyncAccounts$ = new BehaviorSubject<{retryUntilFoundAccountId: number} | undefined>(
    undefined,
  );

  public readonly isCustomPasswordWizardAvailable$ = this.isCustomPasswordWizardAvailable().pipe(shareReplay(1));

  constructor(
    private readonly connection: ConnectionService,
    private readonly router: Router,
    private readonly webSocketService: WebSocketService,
  ) {
    this.webSocketService
      .private(EWsEvents.ReportUpdated)
      .pipe(
        map(res => {
          const data = res.data as Report;
          return Report.Make(data);
        }),
      )
      .subscribe(report => this.report$.next(report));

    this.webSocketService
      .private(EWsEvents.ReportUpdateFailed)
      .subscribe(result => this.failedReport$.next(result.data as IFailedReportData));

    this.webSocketService.private(EWsEvents.B2CopyAccountCreated).subscribe(response => {
      const data = response.data as IB2copyAccountCreatedData;
      this.reloadAsyncAccount({retryUntilFoundAccountId: data.id});
    });
  }

  private getAccounts(): Observable<Account[]> {
    return this.connection.get('/api/v1/accounts/async').pipe(
      mapException,
      map((res: IExceptions<Account[]>) => (isResponse(res) ? res.getData().map(a => Account.Make(a)) : [])),
      catchError(() => of([])),
    );
  }

  /**
   * Reload list of async accounts.
   *
   * @param param - Additional reload parameters.
   * @param param.retryUntilFoundAccountId - GetAccounts() request will be retried 3 times or until account with this id is found.
   */
  public reloadAsyncAccount(param?: {retryUntilFoundAccountId: number}): void {
    this.reloadAsyncAccounts$.next(param);
  }

  /**
   * Get accounts stream with auto-updated reports.
   *
   * When report for any account is updated, the whole list of updated accounts will be emitted.
   *
   * @returns Accounts array.
   */
  @Public(of([]))
  public getAccountsAsync(): Observable<Account[]> {
    const fnLoadAccountsPipe = (
      reloadParams: {retryUntilFoundAccountId: number} | undefined,
    ): Observable<Account[]> => {
      return of(reloadParams).pipe(
        switchMap(params => {
          if (params?.retryUntilFoundAccountId) {
            const delays = [2000, 3000, 5000];
            return this.getAccounts().pipe(
              switchMap(accounts => {
                const hasRequiredAccount = accounts.some(
                  account => account.id === reloadParams.retryUntilFoundAccountId,
                );
                return hasRequiredAccount
                  ? of(accounts)
                  : throwError(() => new Error('Account not found in the response'));
              }),
              retry({
                delay: (err, retryCount) => {
                  const delay = delays[retryCount - 1];
                  if (_.isNil(delay)) {
                    // account not found after 3 retries, then let's just trigger reload once again to get latest changes
                    this.reloadAsyncAccounts$.next(undefined);
                  }
                  return delay ? timer(delay) : EMPTY;
                },
              }),
            );
          }
          return this.getAccounts();
        }),
        map(accounts => Account.createMap(accounts)),
        switchMap(accountsMap =>
          this.failedReport$.pipe(
            map(failedReport => {
              _.set(accountsMap.get(failedReport?.report_id), 'isReportUnavailable', true);
              return accountsMap;
            }),
          ),
        ),
        switchMap(cachedAccountsMap =>
          this.report$.pipe(
            startWith(null),
            bufferDebounceTime(AccountService.ACCOUNTS_DEBOUNCE_TIME_IN_MS),
            map(bufferedReports => bufferedReports.filter(report => report !== null)),
            map(bufferedReports => Report.removeDuplicatedReports(bufferedReports)),
            switchMap(bufferedReports => {
              for (const report of bufferedReports) {
                const account = cachedAccountsMap.get(report.id);

                if (!account) {
                  this.reloadAsyncAccounts$.next(undefined);
                  return EMPTY;
                }

                account.isReportUnavailable = false;

                const accountCopy = _.cloneDeep(account);
                accountCopy.report = report;
                cachedAccountsMap.set(accountCopy.id, accountCopy);
              }

              return of(Array.from(cachedAccountsMap.values()));
            }),
          ),
        ),
      );
    };

    return this.reloadAsyncAccounts$.pipe(switchMap(reloadParams => fnLoadAccountsPipe(reloadParams)));
  }

  public one(id: number): Observable<IExceptions> {
    return this.connection.get(`/api/v1/accounts/${id}`, {with: 'product,report'}).pipe(mapException);
  }

  public createAccount(productId: number, leverage?: string, startAmount?: string): Observable<IExceptions> {
    return this.connection
      .post('/api/v1/accounts', {
        ['product_id']: productId,
        leverage,
        ['start_amount']: startAmount,
      })
      .pipe(mapException);
  }

  public deposit(trade: Account): Promise<boolean> {
    if (trade.rightsDeposit) {
      return this.router.navigate([`/tx/payment/${trade.id}`]);
    }
    return this.transfer(trade, {
      ['destination_id']: trade.id,
    });
  }

  public transfer(trade: Account, params: ITransferParams): Promise<boolean> {
    if (trade.rightsTransferDeposit) {
      return this.router.navigate(['/tx/transfer'], {queryParams: params});
    }
    return Promise.resolve(false);
  }

  public withdraw(trade: Account): Promise<boolean> {
    if (trade.rightsTransferWithdraw) {
      return this.transfer(trade, {
        ['source_id']: trade.id,
      });
    }

    if (trade.rightsWithdraw) {
      const params: IPayoutParams = {id: `${trade.id}`};
      return this.router.navigate(['/tx/payout'], {queryParams: params});
    }

    return Promise.resolve(false);
  }

  public archive(account: Account, fallbackId?: number): Observable<IExceptions> {
    return this.connection
      .post(`/api/v1/accounts/${account.id}/archive`, {
        ['fallback_id']: fallbackId,
      })
      .pipe(mapException);
  }

  /**
   * New method instead of `resetPassword` for investment platform.
   *
   * @param id - Numeric account id.
   * @returns - V1 response.
   */
  public resetInvestmentPlatformPassword(id: number): Observable<IExceptions> {
    return this.connection.post(`/api/v1/investment/common/accounts/reset_password/${id}`, {}).pipe(mapException);
  }

  public resetPassword(id: number, formData: FormData | null): Observable<IExceptions> {
    return this.connection.post(`/api/v1/accounts/${id}/password`, formData).pipe(mapException);
  }

  public renameCaption(params: {id: string; caption: string}): Observable<IExceptions> {
    return this.connection.put(`/api/v1/accounts/${params.id}`, params).pipe(mapException);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public favorite(params: {id: number; favorite: boolean}): Observable<IExceptions> {
    return this.connection.put(`/api/v1/accounts/${params.id}`, params).pipe(mapException);
  }

  public ratesPairs(params: {pairs: string[]}): Observable<RatesPairs> {
    return this.connection.post('/api/v1/rates/pairs', params).pipe(
      mapException,
      map((value: IExceptions) => plainToClass(RatesPairs, value.getData())),
    );
  }

  private isCustomPasswordWizardAvailable(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.connection.get<{status: boolean}>('/api/v1/accounts/check_custom_password_wizard').pipe(
      map(response => response.status),
      catchError(() => of(false)),
    );
  }
}
