/* eslint-disable @typescript-eslint/naming-convention */
import {ECommonWidgetKeys} from '@app/gridster/widgets/gridster-widgets';

export enum GridsterEventType {
  syncInstrument = 'syncInstrument',
  selectOrder = 'selectOrder',
  watch = 'watch',
  removeFromWatchlist = 'removeFromWatchlist',
  syncAccount = 'syncAccount',
  moexSyncInstrument = 'moexSyncInstrument',
}

export interface IGridsterEvent {
  type: GridsterEventType;
  widget: ECommonWidgetKeys;
  payload: IGridsterEventPayload;
}

export interface IGridsterEventParam {
  type?: GridsterEventType;
  widget?: ECommonWidgetKeys;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGridsterEventPayload {}
