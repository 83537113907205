import {DefaultHttpClient, HttpResponse} from '@microsoft/signalr';
import {createLogger} from '@microsoft/signalr/dist/esm/Utils';
import {HttpRequest} from '@microsoft/signalr/src/HttpClient';

export class B2traderSignalrHttpClient extends DefaultHttpClient {
  constructor(private readonly authHeaders: Record<string, string>) {
    super(createLogger());
  }

  public post(url: string, _?: HttpRequest): Promise<HttpResponse> {
    return super.post(url, {headers: {...this.authHeaders}});
  }

  public get(url: string): Promise<HttpResponse> {
    return super.get(url, {headers: {...this.authHeaders}});
  }
}
