import {Transform} from 'class-transformer';

import {TokenData} from './token-data';
import {TokenResponse} from './token-response';

export class Token {
  private static readonly TIME_DELTA_IN_MS = 10_000;

  private readonly token: string;

  @Transform(({obj}: {obj: TokenResponse}) => new Date(obj.expires_at))
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly expires_at: Date;

  public get data(): TokenData {
    const expiresTime = this.expires_at.getTime() - Token.TIME_DELTA_IN_MS;

    return {
      token: this.token,
      expiresTime: new Date(expiresTime),
    };
  }
}
