import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';

@Injectable()
export class TradingCalendarService extends AEventHandler<number> {
  public type = EEventHandlersReceived.TradingCalendar;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: number): void {
    this.b2marginDatafeedService.endOfDay$.next(resource);
  }
}
