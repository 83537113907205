import {EDeviceType} from '../enums/device-types.enum';

export const isDeviceTypeMobileOrTablet = (userAgent: string): boolean => {
  const deviceType = getDeviceType(userAgent);

  return deviceType === EDeviceType.Mobile || deviceType === EDeviceType.Tablet;
};

const getDeviceType = (userAgent: string): EDeviceType => {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return EDeviceType.Tablet;
  }

  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent,
    )
  ) {
    return EDeviceType.Mobile;
  }

  return EDeviceType.Desktop;
};
