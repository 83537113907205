import {Pipe, PipeTransform} from '@angular/core';
import {LanguageStateModel} from '@app/language/language.state';
import {Store} from '@ngxs/store';

@Pipe({
  name: 'localization',
})
export class LocalizationPipe implements PipeTransform {
  private static readonly LOCALE_DEFAULT = 'en_GB';

  private getLocale(): string {
    const {language}: {language: LanguageStateModel} = this.store.snapshot();

    const lang = language.allLanguages?.find(languageItem => language.currentLanguage === languageItem.lang);

    if (lang) {
      return lang.locale;
    }

    return LocalizationPipe.LOCALE_DEFAULT;
  }

  private readonly locale: string;

  constructor(public store: Store) {
    this.locale = this.getLocale();
  }

  public transform(value: Record<string, string> | undefined, fallback: string): string {
    return (!!value && value[this.locale]) || fallback;
  }
}
