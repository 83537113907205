<ng-container [formGroup]="formGroup">
  <div class="radio-chips-wrapper">
    <div *ngIf="field.label?.value" class="form-label">{{ getLabel() | async }}</div>

    <app-radio-input
      [control]="control"
      [options]="options"
      [field]="field"
      [tooltipLayouts]="tooltipLayouts"
    ></app-radio-input>

    <ng-container *ngIf="control.touched">
      <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
        {{ getError(err) | async }}
      </mat-error>
    </ng-container>
  </div>
</ng-container>
