import {Router} from '@angular/router';
import {ATokenService} from '@app/core/token.service';
import {THttpHeaders} from '@app/core/types/types';
import {Environment} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export abstract class ABaseConnectionService extends ATokenService {
  public abstract get<T>(
    url: string,
    params?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [param: string]: any;
    },
  ): Observable<T>;
  public abstract post<T>(url: string, body): Observable<T>;
  public abstract put<T>(url: string, body?): Observable<T>;
  public abstract patch<T>(url: string, body?): Observable<T>;
  public abstract delete<T>(url: string): Observable<T>;

  constructor(environment: Environment, router: Router, private readonly translateService: TranslateService) {
    super(environment, router);
  }

  protected getHttpHeaders(): Observable<THttpHeaders> {
    return this.getAuthHeaders().pipe(
      map(authHeaders => ({
        ...authHeaders,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'accept-language': this.translateService.currentLang,
      })),
    );
  }

  public sendBeacon(url: string, data?): boolean {
    const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});

    return window.navigator.sendBeacon(this.apiUrl + url, blob);
  }
}
