import {INDEFINITELY_ORDER_END_YEAR} from '@app/pbsr/consts/indefinitely-order-end-year.const';
import {EOrderSide} from '@app/trading-board/enum/moex/order-side';
import {EOrderStatus} from '@app/trading-board/enum/moex/order-status';
import {EOrderType} from '@app/trading-board/enum/moex/order-type';
import {ETriggerCondition} from '@app/trading-board/enum/moex/trigger-condition.enum';
import {DecimalHelper} from '@b2broker/decimal-helper';
import {Expose, Transform} from 'class-transformer';
import * as _ from 'lodash-es';
import moment from 'moment';

export class WarpOrder {
  @Expose()
  public id: string;

  @Expose()
  public exchangeOrderId: string | undefined;

  @Expose()
  public symbol: string;

  @Expose()
  public brokerSymbol: string;

  @Expose()
  public exchange: string;

  @Expose()
  public type: EOrderType;

  @Expose()
  public side: EOrderSide;

  @Expose()
  public status: EOrderStatus;

  @Expose()
  public transTime: string;

  @Expose()
  public endTime: string;

  @Expose()
  @Transform(({value}) => (_.isNil(value) ? undefined : DecimalHelper.from(value)))
  public qtyUnits?: DecimalHelper;

  @Expose()
  @Transform(({value}) => (_.isNil(value) ? undefined : DecimalHelper.from(value)))
  public qtyBatch?: DecimalHelper;

  @Expose()
  @Transform(({value}) => (_.isNil(value) ? undefined : DecimalHelper.from(value)))
  public qty?: DecimalHelper;

  @Expose()
  @Transform(({value}) => (_.isNil(value) ? undefined : DecimalHelper.from(value)))
  public filledQtyUnits?: DecimalHelper;

  @Expose()
  @Transform(({value}) => (_.isNil(value) ? undefined : DecimalHelper.from(value)))
  public filledQtyBatch?: DecimalHelper;

  @Expose()
  @Transform(({value}) => (_.isNil(value) ? undefined : DecimalHelper.from(value)))
  public filled?: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public price: DecimalHelper;

  @Expose()
  @Transform(({value}) => (_.isNil(value) ? undefined : DecimalHelper.from(value)))
  public stopPrice?: DecimalHelper;

  @Expose({name: 'existing'})
  public isExist: boolean;

  @Expose()
  public subAccountId: string;

  @Expose()
  public subAccountCaption: string;

  @Expose()
  public parentAccountId: string;

  @Expose()
  public parentAccountCaption: string;

  @Expose()
  public tradeCode: string;

  @Expose()
  public condition?: ETriggerCondition;

  public isin: string;

  public instrumentName: string;

  public get isCanceledOrRejected(): boolean {
    return this.status === EOrderStatus.Canceled || this.status === EOrderStatus.Rejected;
  }

  public get isIndefinitelyOrder(): boolean {
    const endTime = moment(this.endTime).utc().format('YYYY-MM-DD');
    const endYear = new Date(endTime).getFullYear();

    return endYear === INDEFINITELY_ORDER_END_YEAR;
  }
}
