export function downloadByBlob(blob: Blob, fileName?: string): void {
  const a = document.createElement('a');
  const objectUrl = URL.createObjectURL(blob);

  a.href = objectUrl;

  if (fileName) {
    a.download = fileName;
  }

  a.click();
  URL.revokeObjectURL(objectUrl);
}
