import {Injectable, Type} from '@angular/core';
import {ETradingBoardProviderAlias} from '@app/trading-board/enum/provider-alias';
import {AComponentResolver, TEntryPoint} from '@app/trading-board/services/component-resolver.abstract';
import {TradingViewComponent} from '@app/trading-board/widgets/trading-view/trading-view.component';
import {WatchListComponent} from '@app/trading-board/widgets/watch-list/watch-list.component';

import {MoexOrderBookComponent} from '../widgets/order-book/moex-order-book/moex-order-book.component';
import {OrderBookComponent} from '../widgets/order-book/order-book.component';
import {MoexTradingViewComponent} from '../widgets/trading-view/moex-trading-view/moex-trading-view.component';
import {MoexWatchListComponent} from '../widgets/watch-list/moex/moex-watch-list.component';

@Injectable()
export class MoexComponentResolverService extends AComponentResolver {
  private static readonly tradingView = (): Promise<{entryPoint: typeof MoexTradingViewComponent}> =>
    import('../widgets/trading-view/moex-trading-view/moex-trading-view.component').then(m => ({
      entryPoint: m.MoexTradingViewComponent,
    }));

  private static readonly orderBook = (): Promise<{entryPoint: typeof MoexOrderBookComponent}> =>
    import('../widgets/order-book/moex-order-book/moex-order-book.component').then(m => ({
      entryPoint: m.MoexOrderBookComponent,
    }));

  private static readonly watchList = (): Promise<{entryPoint: typeof MoexWatchListComponent}> =>
    import('../widgets/watch-list/moex/moex-watch-list.component').then(m => ({
      entryPoint: m.MoexWatchListComponent,
    }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly components = new Map<Type<any>, TEntryPoint<any>>([
    [TradingViewComponent, MoexComponentResolverService.tradingView],
    [OrderBookComponent, MoexComponentResolverService.orderBook],
    [WatchListComponent, MoexComponentResolverService.watchList],
  ]);

  public readonly type = ETradingBoardProviderAlias.moex;

  public getComponent<T>(type: Type<unknown>): TEntryPoint<T> | undefined {
    return this.components.get(type);
  }
}
