import {Injectable, Type} from '@angular/core';
import {ETradingBoardProviderAlias} from '@app/trading-board/enum/provider-alias';
import {AComponentResolver, TEntryPoint} from '@app/trading-board/services/component-resolver.abstract';
import {InactiveOrdersComponent} from '@app/trading-board/widgets/inactive-orders/inactive-orders.component';
import {OpenOrdersComponent} from '@app/trading-board/widgets/open-orders/open-orders.component';
import {B2traderOrderHistoryComponent} from '@app/trading-board/widgets/order-history/b2trader/b2trader-order-history.component';
import {OrderHistoryComponent} from '@app/trading-board/widgets/order-history/order-history.component';
import {TradesHistoryComponent} from '@app/trading-board/widgets/trades-history/trades-history.component';
import {TradingViewComponent} from '@app/trading-board/widgets/trading-view/trading-view.component';
import {WatchListComponent} from '@app/trading-board/widgets/watch-list/watch-list.component';

import {B2traderFilledOrdersComponent} from '../widgets/filled-orders/b2trader/b2trader-filled-orders.component';
import {FilledOrdersComponent} from '../widgets/filled-orders/filled-orders.component';
import {B2traderInactiveOrdersComponent} from '../widgets/inactive-orders/b2trader/b2trader-inactive-orders.component';
import {B2traderOpenOrdersComponent} from '../widgets/open-orders/b2trader/b2trader-open-orders.component';
import {B2TraderOrderBookComponent} from '../widgets/order-book/b2trader-order-book/b2trader-order-book.component';
import {OrderBookComponent} from '../widgets/order-book/order-book.component';
import {B2traderTradesHistoryComponent} from '../widgets/trades-history/b2trader/b2trader-trades-history.component';
import {B2traderTradingViewComponent} from '../widgets/trading-view/b2trader-trading-view/b2trader-trading-view.component';
import {B2traderWatchListComponent} from '../widgets/watch-list/b2trader/b2trader-watch-list.component';

@Injectable()
export class B2traderComponentResolverService extends AComponentResolver {
  private static readonly watchList = (): Promise<{entryPoint: typeof B2traderWatchListComponent}> =>
    import('../widgets/watch-list/b2trader/b2trader-watch-list.component').then(m => ({
      entryPoint: m.B2traderWatchListComponent,
    }));

  private static readonly tradingView = (): Promise<{entryPoint: typeof B2traderTradingViewComponent}> =>
    import('../widgets/trading-view/b2trader-trading-view/b2trader-trading-view.component').then(m => ({
      entryPoint: m.B2traderTradingViewComponent,
    }));

  private static readonly openOrders = (): Promise<{entryPoint: typeof B2traderOpenOrdersComponent}> =>
    import('../widgets/open-orders/b2trader/b2trader-open-orders.component').then(m => ({
      entryPoint: m.B2traderOpenOrdersComponent,
    }));

  private static readonly orderHistory = (): Promise<{entryPoint: typeof B2traderOrderHistoryComponent}> =>
    import('../widgets/order-history/b2trader/b2trader-order-history.component').then(m => ({
      entryPoint: m.B2traderOrderHistoryComponent,
    }));

  private static readonly inactiveOrders = (): Promise<{entryPoint: typeof B2traderInactiveOrdersComponent}> =>
    import('../widgets/inactive-orders/b2trader/b2trader-inactive-orders.component').then(m => ({
      entryPoint: m.B2traderInactiveOrdersComponent,
    }));

  private static readonly filledOrders = (): Promise<{entryPoint: typeof B2traderFilledOrdersComponent}> =>
    import('../widgets/filled-orders/b2trader/b2trader-filled-orders.component').then(m => ({
      entryPoint: m.B2traderFilledOrdersComponent,
    }));

  private static readonly tradesHistory = (): Promise<{entryPoint: typeof B2traderTradesHistoryComponent}> =>
    import('../widgets/trades-history/b2trader/b2trader-trades-history.component').then(m => ({
      entryPoint: m.B2traderTradesHistoryComponent,
    }));

  private static readonly orderBook = (): Promise<{entryPoint: typeof B2TraderOrderBookComponent}> =>
    import('../widgets/order-book/b2trader-order-book/b2trader-order-book.component').then(m => ({
      entryPoint: m.B2TraderOrderBookComponent,
    }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly components = new Map<Type<any>, TEntryPoint<any>>([
    [WatchListComponent, B2traderComponentResolverService.watchList],
    [TradingViewComponent, B2traderComponentResolverService.tradingView],
    [OpenOrdersComponent, B2traderComponentResolverService.openOrders],
    [OrderHistoryComponent, B2traderComponentResolverService.orderHistory],
    [InactiveOrdersComponent, B2traderComponentResolverService.inactiveOrders],
    [FilledOrdersComponent, B2traderComponentResolverService.filledOrders],
    [TradesHistoryComponent, B2traderComponentResolverService.tradesHistory],
    [OrderBookComponent, B2traderComponentResolverService.orderBook],
  ]);

  public readonly type = ETradingBoardProviderAlias.ce;

  public getComponent<T>(type: Type<unknown>): TEntryPoint<T> | undefined {
    return this.components.get(type);
  }
}
