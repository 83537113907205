import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, TrackByFunction} from '@angular/core';
import {PayoutService} from '@app/core/services/payout.service';
import {combineLatest, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {FormFieldOption} from '../../entities/form.field';
import {FormInput} from '../../entities/form.input';
import {ErrorsService} from '../../services/errors/errors.service';
import {LabelService} from '../../services/label.service';
import {feeLevelDescription} from './const/fee-level-description';

@Component({
  selector: 'app-fee-select',
  templateUrl: './fee-select.component.html',
  styleUrls: ['./fee-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeeSelectComponent extends FormInput implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();

  public feeLevels: FormFieldOption[];

  public selectedFee: FormFieldOption;

  public currencyAlpha: string = null;

  public readonly trackByIndex: TrackByFunction<FormFieldOption> = index => index;

  constructor(
    labelService: LabelService,
    errorsService: ErrorsService,
    private readonly payoutService: PayoutService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(labelService, errorsService);
  }

  public ngOnInit(): void {
    this.feeLevels = this.field?.options;
    combineLatest([this.payoutService.payoutFeeInfo, this.payoutService.operationInfo])
      .pipe(
        filter(([feeInfo, operationInfo]) => Boolean(feeInfo) && Boolean(operationInfo)),
        takeUntil(this.destroyer$),
      )
      .subscribe(([feeInfo, operationInfo]) => {
        this.feeLevels = feeInfo;
        this.currencyAlpha = operationInfo.source.currency.alpha;
        this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public selectFee(fee: FormFieldOption): void {
    this.selectedFee = fee;
    this.control.setValue(this.selectedFee.value);
  }

  public getFeeLevelDescription(feeLevel: FormFieldOption): string {
    return feeLevelDescription[feeLevel.name];
  }
}
