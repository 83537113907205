import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {SECOND_IN_MS} from '@app/core/constants/common';
import {FormConfig, FormWrapper, IFormConfigParams} from '@app/core/form/entities/form.wrapper';
import {mergeRules} from '@app/core/form/services/config.form.service';
import {LabelService} from '@app/core/form/services/label.service';
import {DISABLE_SUBMIT_TOKEN} from '@app/core/tokens';
import {NEVER, Observable, of, Subject} from 'rxjs';
import {switchMap, takeUntil, throttleTime} from 'rxjs/operators';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormComponent<T> implements OnDestroy {
  private static readonly THROTTLE_TIME = 3 * SECOND_IN_MS;

  private readonly destroyer$ = new Subject<void>();

  public formWrapper: FormWrapper;

  @Input()
  public submitLabel: string;

  @Input()
  public cancelLabel: string;

  @Input()
  public backLabel: string;

  @Input()
  public set formConfigParams(params: IFormConfigParams<T>) {
    if (!params) {
      return;
    }

    this.formConfig = params.rules ? mergeRules(params.config, params.rules) : params.config;

    if (params.initialValue) {
      this.initialValue = params.initialValue;
    }
  }

  @Input()
  public set initialValue(v: T) {
    if (v) {
      this.formWrapper?.form?.patchValue(v);
    }
    this._initialValue = v;
  }

  private _initialValue: T;

  @Input()
  public set apiErrors(errors: Record<string, string[]>) {
    if (!errors || !this.formWrapper) {
      return;
    }
    spreadErrors(this.formWrapper.form, errors);
  }

  @Input()
  public set formConfig(formConfig: FormConfig) {
    if (formConfig) {
      this.init(formConfig);
    }
  }

  @Input()
  public formTemplate: TemplateRef<ElementRef>;

  @Input()
  public formGroupTemplate: TemplateRef<ElementRef>;

  @Input()
  public formButtonsTemplate: TemplateRef<ElementRef>;

  @Input()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public withoutButtons: boolean;

  @Input()
  public set externalSubmit(emitter: EventEmitter<void>) {
    if (emitter === this._externalSubmit) {
      return;
    }

    this._externalSubmit = emitter;

    if (!this._externalSubmit) {
      return;
    }

    this._externalSubmit
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() => this.submitHandler.emit(this.formWrapper.form.value));
  }

  @Input()
  public isFocusFirstInput = false;

  @Output()
  public readonly submitEmitter = new EventEmitter<T>();

  @Output()
  public readonly cancelEmitter = new EventEmitter<void>();

  @Output()
  public readonly backEmitter = new EventEmitter<void>();

  @Output()
  public readonly formInitiated = new EventEmitter<FormWrapper>();

  private _externalSubmit: EventEmitter<void>;
  private readonly submitHandler = new EventEmitter<T>();

  @Input()
  public set registerLabels(value: Record<string, () => Observable<string>>) {
    this.labelService.registerLabels(value);
  }

  @Input()
  public disabled = false;

  public onSubmit(): void {
    if (this.formWrapper.form.invalid) {
      return;
    }

    this.submitHandler.emit(this.formWrapper.form.value);
  }

  public onCancel(): void {
    this.cancelEmitter.emit();
  }

  private init(formConfig: FormConfig): void {
    this.formWrapper = null;
    this.formWrapper = new FormWrapper(formConfig);
    if (this._initialValue) {
      this.formWrapper.form.patchValue(this._initialValue);
    }
    this.formInitiated.emit(this.formWrapper);
  }

  constructor(
    private readonly labelService: LabelService,
    @Optional() @Inject(DISABLE_SUBMIT_TOKEN) disableSubmit$: Observable<boolean> | null,
  ) {
    (disableSubmit$ ?? of(false))
      .pipe(
        switchMap(disabled => (disabled ? NEVER : this.submitHandler.pipe(throttleTime(FormComponent.THROTTLE_TIME)))),
        takeUntil(this.destroyer$),
      )
      .subscribe(val => this.submitEmitter.emit(val));
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}

export function getControl(path: string, form: UntypedFormGroup): AbstractControl | undefined {
  return path.split('.').reduce((result: UntypedFormGroup | undefined, key) => {
    if (result) {
      return result.controls[key];
    }
  }, form);
}

export function spreadErrors(form: UntypedFormGroup, errors: Record<string, string[]>): void {
  if (!errors) {
    return;
  }

  Object.keys(errors).forEach(key => {
    let control: AbstractControl;

    try {
      control = getControl(key, form);
    } catch {
      form.setErrors({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        contactUs: true,
      });
    }

    if (control) {
      errors[key].forEach(error => {
        control.setErrors({[error]: error});
        control.markAsTouched();
      });
    } else {
      const errorsByKey = errors[key];
      const firstError = errorsByKey[0];

      form.setErrors({
        [firstError]: firstError,
      });

      form.markAsTouched();
    }
  });
}
