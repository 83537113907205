import {APP_INITIALIZER, Provider} from '@angular/core';
import {ALocaleStorage} from '@app/shared/storages/local-storage';

export const SYNC_AUTH_STATE_FROM_LS_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: () => {
    return () => {
      const accessTokenFromNewVersion = ALocaleStorage.NEW_VERSION_ACCESS_TOKEN.get();
      const accessTokenExpiredAtFromNewVersion = ALocaleStorage.NEW_VERSION_ACCESS_TOKEN_EXPIRED_AT.get();

      const refreshTokenFromNewVersion = ALocaleStorage.NEW_VERSION_REFRESH_TOKEN.get();
      const refreshTokenExpiredAtFromNewVersion = ALocaleStorage.NEW_VERSION_REFRESH_TOKEN_EXPIRED_AT.get();

      if (
        !accessTokenFromNewVersion ||
        !accessTokenExpiredAtFromNewVersion ||
        !refreshTokenFromNewVersion ||
        !refreshTokenExpiredAtFromNewVersion
      ) {
        return Promise.resolve();
      }

      ALocaleStorage.ACCESS_TOKEN.set(
        JSON.stringify({
          /* eslint-disable @typescript-eslint/naming-convention */
          token: accessTokenFromNewVersion,
          expires_at: accessTokenExpiredAtFromNewVersion,
          created_at: new Date(Date.now()).toISOString(),
          /* eslint-disable @typescript-eslint/naming-convention */
        }),
      );

      ALocaleStorage.REFRESH_TOKEN.set(
        JSON.stringify({
          /* eslint-disable @typescript-eslint/naming-convention */
          token: refreshTokenFromNewVersion,
          expires_at: refreshTokenExpiredAtFromNewVersion,
          created_at: new Date(Date.now()).toISOString(),
          /* eslint-disable @typescript-eslint/naming-convention */
        }),
      );

      ALocaleStorage.NEW_VERSION_ACCESS_TOKEN.remove();
      ALocaleStorage.NEW_VERSION_ACCESS_TOKEN_EXPIRED_AT.remove();

      ALocaleStorage.NEW_VERSION_REFRESH_TOKEN.remove();
      ALocaleStorage.NEW_VERSION_REFRESH_TOKEN_EXPIRED_AT.remove();

      return Promise.resolve();
    };
  },
  multi: true,
  deps: [],
};
