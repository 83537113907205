import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {Decimal} from 'decimal.js';
import * as _ from 'lodash-es';

export type TLazyDecimalHelperValue = string | number | DecimalHelper | LazyDecimalHelper;

export abstract class ALazyOperation {
  protected prev: ALazyOperation;
  protected predicate: TLazyDecimalHelperValue;

  public value: DecimalHelper;

  protected getRight(): string | number | DecimalHelper {
    return this.predicate instanceof LazyDecimalHelper ? this.predicate.operation.execute() : this.predicate;
  }

  public abstract execute(): DecimalHelper;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/naming-convention
function InitializeLazyValue(): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]): any {
      const operations = [this.operation].concat(
        args.map((arg: any) => arg instanceof LazyDecimalHelper && arg.operation),
      );

      for (const operation of operations) {
        if (!!operation && !operation.value) {
          operation.execute();
        }
      }

      return originalMethod.apply(this, args);
    };

    return descriptor;
  };
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export class LazyDecimalHelper {
  public static max(...n: LazyDecimalHelper[]): LazyDecimalHelper {
    let [base] = n;

    for (let i = 1; i < n.length; i++) {
      if (base.lessThan(n[i])) {
        base = n[i];
      }
    }

    return base;
  }

  public static min(...n: LazyDecimalHelper[]): LazyDecimalHelper {
    let [base] = n;

    for (let i = 1; i < n.length; i++) {
      if (base.greaterThan(n[i])) {
        base = n[i];
      }
    }

    return base;
  }

  public static from(n: number | string | LazyDecimalHelper, scale?: number): LazyDecimalHelper {
    return new LazyDecimalHelper(new ResultOperation(n, scale));
  }

  protected constructor(public operation: ALazyOperation) {}

  public get value(): string {
    return this.operation.execute().value;
  }

  public minus(n: TLazyDecimalHelperValue): LazyDecimalHelper {
    return new LazyDecimalHelper(new MinusOperation(this.operation, n));
  }

  public plus(n: TLazyDecimalHelperValue): LazyDecimalHelper {
    return new LazyDecimalHelper(new PlusOperation(this.operation, n));
  }

  public multiply(n: TLazyDecimalHelperValue): LazyDecimalHelper {
    return new LazyDecimalHelper(new MultiplyOperation(this.operation, n));
  }

  public divide(n: TLazyDecimalHelperValue): LazyDecimalHelper {
    return new LazyDecimalHelper(new DivideOperation(this.operation, n));
  }

  public pow(n: string | number): LazyDecimalHelper {
    return new LazyDecimalHelper(new PowOperation(this.operation, n));
  }

  public negated(): LazyDecimalHelper {
    return new LazyDecimalHelper(new NegatedOperation(this.operation));
  }

  public abs(): LazyDecimalHelper {
    return new LazyDecimalHelper(new AbsOperation(this.operation));
  }

  public setScale(scale: number): LazyDecimalHelper {
    return new LazyDecimalHelper(new SetScaleOperation(this.operation, scale));
  }

  public [Symbol.toPrimitive](hint: 'string' | 'default'): string;
  public [Symbol.toPrimitive](hint: 'number'): number;

  @InitializeLazyValue()
  public [Symbol.toPrimitive](hint: string): string | number {
    if (!_.isNil(this.operation.value.scale)) {
      switch (hint) {
        case 'number':
          return this.toDecimalPlaces(this.operation.value.scale, Decimal.ROUND_DOWN).toNumber();

        case 'string':
        case 'default':
        default:
          // eslint-disable-next-line no-case-declarations
          let n = this.operation.value.decimalInstance.toDecimalPlaces(this.operation.value.scale, Decimal.ROUND_DOWN);
          if (!n.isFinite()) {
            return 0;
          }

          if (n.isZero() && n.isNegative()) {
            n = n.abs();
          }

          return n.toNumber().toLocaleString(DecimalHelper.locale, {minimumFractionDigits: n.dp()});
      }
    }

    switch (hint) {
      case 'number':
        return this.toNumber();
      case 'string':
      case 'default':
      default:
        return this.operation.value.decimalInstance.toFixed();
    }
  }

  @InitializeLazyValue()
  public isPositive(): boolean {
    return this.operation.value.isPositive();
  }

  @InitializeLazyValue()
  public equals(n: TLazyDecimalHelperValue): boolean {
    if (n instanceof LazyDecimalHelper) {
      return this.operation.value.equals(n.operation.value);
    }

    return this.operation.value.equals(n);
  }

  @InitializeLazyValue()
  public getValue(): string {
    return this.operation.value.getValue();
  }

  @InitializeLazyValue()
  public getFixed(scale: number): string {
    return this.operation.value.getFixed(scale);
  }

  @InitializeLazyValue()
  public isNegative(): boolean {
    return this.operation.value.isNegative();
  }

  @InitializeLazyValue()
  public isZero(): boolean {
    return this.operation.value.isZero();
  }

  @InitializeLazyValue()
  public parseFloat(): number {
    return this.operation.value.parseFloat();
  }

  @InitializeLazyValue()
  public toString(): string {
    return this.operation.value.toString();
  }

  @InitializeLazyValue()
  public toDecimalPlaces(places: number, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): LazyDecimalHelper {
    this.operation.value = this.operation.value.toDecimalPlaces(places, rounding);

    return this;
  }

  @InitializeLazyValue()
  public isFinite(): boolean {
    return this.operation.value.isFinite();
  }

  @InitializeLazyValue()
  public greaterThan(n: TLazyDecimalHelperValue): boolean {
    if (n instanceof LazyDecimalHelper) {
      return this.operation.value.greaterThan(n.operation.value);
    }

    return this.operation.value.greaterThan(n);
  }

  @InitializeLazyValue()
  public lessThan(n: TLazyDecimalHelperValue): boolean {
    if (n instanceof LazyDecimalHelper) {
      return this.operation.value.lessThan(n.operation.value);
    }

    return this.operation.value.lessThan(n);
  }

  @InitializeLazyValue()
  public toNumber(): number {
    return this.operation.value.toNumber();
  }

  @InitializeLazyValue()
  public lessThanOrEqualTo(value: TLazyDecimalHelperValue): boolean {
    return this.operation.value.lessThanOrEqualTo(value instanceof LazyDecimalHelper ? value.operation.value : value);
  }

  @InitializeLazyValue()
  public isNotNaN(): boolean {
    return !this.operation.value.isNaN();
  }

  @InitializeLazyValue()
  public isNaN(): boolean {
    return this.operation.value.isNaN();
  }

  @InitializeLazyValue()
  public decimalPlaces(): number {
    return this.operation.value.decimalPlaces();
  }

  @InitializeLazyValue()
  public greaterThanOrEqualTo(n: TLazyDecimalHelperValue): boolean {
    if (n instanceof LazyDecimalHelper) {
      n = n.operation.value;
    }

    return this.operation.value.greaterThanOrEqualTo(n);
  }

  @InitializeLazyValue()
  public isNotZero(): boolean {
    return !this.operation.value.isZero();
  }

  @InitializeLazyValue()
  public greaterThanZero(): boolean {
    return this.operation.value.greaterThan(0);
  }

  @InitializeLazyValue()
  public lessThanZero(): boolean {
    return this.operation.value.lessThan(0);
  }

  public getInstanceWithNanFallback<T>(param: T): T | this {
    if (this.isNotNaN()) {
      return this;
    }

    return param;
  }
}

export class AbsOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }

    return (this.value = this.prev.execute().abs());
  }
}

export class DivideOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation, public predicate: TLazyDecimalHelperValue) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }

    const right = this.getRight();
    const left = this.prev.execute();

    return (this.value = left.divide(right));
  }
}

export class MinusOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation, public predicate: TLazyDecimalHelperValue) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }
    const right = this.getRight();
    const left = this.prev.execute();

    return (this.value = left.minus(right));
  }
}

export class MultiplyOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation, public predicate: TLazyDecimalHelperValue) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }

    const right = this.getRight();
    const left = this.prev.execute();

    return (this.value = left.multiply(right));
  }
}

export class NegatedOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }

    return (this.value = this.prev.execute().negated());
  }
}

export class PlusOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation, public predicate: TLazyDecimalHelperValue) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }

    const left = this.prev.execute();
    const right = this.getRight();

    return (this.value = left.plus(right));
  }
}

export class PowOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation, public predicate: string | number) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }

    return (this.value = this.prev.execute().pow(this.predicate));
  }
}

export class ResultOperation extends ALazyOperation {
  public value: DecimalHelper;
  public scale?: number;

  constructor(value: number | string | LazyDecimalHelper = 0, scale?: number) {
    super();

    this.predicate = value;
    this.scale = scale;
  }

  public execute(): DecimalHelper {
    if (this.predicate instanceof LazyDecimalHelper) {
      return (this.value = DecimalHelper.from(this.predicate.operation.execute(), this.scale));
    }

    return (this.value = DecimalHelper.from(this.predicate, this.scale));
  }
}

export class SetScaleOperation extends ALazyOperation {
  public value: DecimalHelper;

  constructor(public prev: ALazyOperation, public predicate: number) {
    super();
  }

  public execute(): DecimalHelper {
    if (!_.isNil(this.value)) {
      return this.value;
    }

    return (this.value = this.prev.execute().setScale(this.predicate));
  }
}
