import {Casts, CastTypes, Model} from '@app/core/model';

import {InvestmentChartData} from './investment-chart-data';

export class InvestmentData extends Model {
  public accountId: string;
  public accountName: string;
  public followers: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public followersAUM: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public has_subscription_code: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public maxDD: number;
  public miniChart: InvestmentChartData;
  public profit: number;
  public subscriptionsCount: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public show_in_client_ui: boolean;

  public readonly casts: Casts<InvestmentData> = {
    accountId: [CastTypes.String],
    accountName: [CastTypes.String],
    followers: [CastTypes.Number],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    followersAUM: [CastTypes.Number],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    maxDD: [CastTypes.Number],
    miniChart: [CastTypes.Null, InvestmentChartData],
    profit: [CastTypes.Number],
    subscriptionsCount: [CastTypes.Number],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    show_in_client_ui: [CastTypes.Boolean],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    has_subscription_code: [CastTypes.Boolean],
  };
}
