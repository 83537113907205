<div class="percentage-wrap">
  <button
    *ngFor="let item of percentageConfig; trackBy: trackByPercentageId"
    class="percentage-item"
    mat-button
    type="button"
    (click)="selectPercentage(item)"
  >
    {{ item.label }}
  </button>
</div>
