import {Type} from '@angular/core';
import {IManagerComponent} from '@app/wizard/contracts/i.wizard.component';

import {ManagerComponent} from './manager.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class BatchItem<T = any> {
  constructor(
    public component: Type<IManagerComponent>,
    public data: T,
    public isDialog?: boolean,
    public manager?: ManagerComponent,
  ) {}
}
