<div [formGroup]="formGroup">
  <mat-checkbox [formControl]="control" [required]="required">
    <ng-container *ngIf="!field.label.raw">
      {{ getLabel() | async }}
    </ng-container>

    <ng-container *ngIf="field.label.raw">
      <span [innerHTML]="getLabel() | async | safeHtml"></span>
    </ng-container>

    <ng-container *ngIf="control.touched">
      <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
        {{ getError(err) | async }}
      </mat-error>
    </ng-container>
  </mat-checkbox>
</div>
