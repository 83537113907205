import {Injectable} from '@angular/core';
import {MarginImpact} from 'src/app/trading-board/models/b2margin/margin-impact';

import {B2MarginDatafeedService} from '../../datafeed/b2margin-datafeed.service';
import {EEventHandlersReceived} from '../../event-handlers/event-handlers.enum';
import {AEventHandler} from '../event-handler.abstract';

@Injectable()
export class PositionMarginImpactService extends AEventHandler<MarginImpact[]> {
  public type = EEventHandlersReceived.PositionMarginImpact;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: MarginImpact[]): void {
    resource.forEach(item => {
      const fn = MarginImpact.getMarginImpactFn(item);

      if (!fn) {
        return;
      }

      this.b2marginDatafeedService.positionMarginImpact.get(item.uid)?.stream.next(fn);
    });
  }
}
