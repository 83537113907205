import {Directive, ElementRef, forwardRef, HostListener, Input, Renderer2} from '@angular/core';
import {AbstractControl, AbstractControlDirective, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as _ from 'lodash-es';

@Directive({
  selector: '[appPrecision]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrecisionDirective),
      multi: true,
    },
  ],
})
export class PrecisionDirective extends AbstractControlDirective implements ControlValueAccessor {
  @Input() public set appPrecision(precision: number) {
    this._appPrecision = precision || 0;
    const pattern: string =
      precision && precision !== 0
        ? `(^[0-9]([0-9]+)?)([.]?)([0-9]{0,${precision}})?|(^[0])+(([.]([0-9]{0,${precision}}))|([.]))|(^[0])`
        : '(^[0-9]([0-9]+)?)|^[0]';
    this._regExp = new RegExp(pattern, 'g');
    if (this.onChange && this._value) {
      this.writeValueFromViewToModel(this._value);
    }
  }

  private get currentCaretPosition(): number {
    return this.elementRef.nativeElement.selectionEnd;
  }

  private set currentCaretPosition(newValue: number) {
    const input = this.elementRef.nativeElement;
    input.selectionEnd = newValue;
    input.selectionStart = newValue;
  }

  private static readonly NOT_NUMBER_REG_EXP = /([^0-9.])/g;

  private _appPrecision?: number;

  private _value = '';

  private _regExp?: RegExp;

  private previousCaretPosition = 0;

  public control: AbstractControl;

  public onChange: (_: string) => void;
  public onTouched: () => void;

  constructor(protected renderer: Renderer2, protected elementRef: ElementRef<HTMLInputElement>) {
    super();
  }

  private setCaretPosition(newValue: number): void {
    if (newValue > this._value.length) {
      this.previousCaretPosition = this._value.length;
      return;
    }
    if (newValue < 0) {
      this.previousCaretPosition = 0;
      return;
    }
    this.previousCaretPosition = newValue;
  }

  private setToNgModel(value: string): void {
    this.elementRef.nativeElement.value = value;
    this.onChange(value);
    this.onTouched?.call(this);
  }

  public writeValue(value: string): void {
    this.renderViewValue(value);
  }

  public registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => boolean): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.renderViewDisabled(isDisabled);
  }

  @HostListener('input', ['$event.target.value'])
  public handleInput(value: string): void {
    this.writeValueFromViewToModel(value);
  }

  public writeValueFromViewToModel(value: string): void {
    const currentCaretPosition: number = this.currentCaretPosition;

    let isMoveToLastCaretPosition = false;

    if (value[this.previousCaretPosition] === 'e') {
      value = value.replace('e', '');
      isMoveToLastCaretPosition = true;
    }

    if (value[0] === '.') {
      value = value.slice(1);
      this.previousCaretPosition = 0;
      isMoveToLastCaretPosition = true;
    }

    if (isNaN(Number(value))) {
      if (value.indexOf('.') !== value.lastIndexOf('.')) {
        value = removeDotsAfterFirst(value);
        isMoveToLastCaretPosition = true;
      }

      value = value.replace(/\n/g, '');

      if (value.includes(',')) {
        value = value.replace(/,/g, '');
        isMoveToLastCaretPosition = true;
      }

      if (value.includes(' ')) {
        value = value.replace(/ /g, '');
        isMoveToLastCaretPosition = true;
      }

      if (value.length - 1 === this._value.length) {
        value = value.replace(PrecisionDirective.NOT_NUMBER_REG_EXP, '');
        isMoveToLastCaretPosition = true;
      }
    }

    const firstDotIndex: number = value.indexOf('.');

    if (firstDotIndex !== -1) {
      const valueAfterDot: string = value.slice(firstDotIndex + 1);

      if (valueAfterDot.length > this._appPrecision) {
        if (
          valueAfterDot[valueAfterDot.length - 1] !== '0' &&
          currentCaretPosition !== value.length &&
          this.previousCaretPosition > firstDotIndex &&
          currentCaretPosition > firstDotIndex
        ) {
          value = this._value;
          isMoveToLastCaretPosition = true;
        }
      }
    }

    if (value[0] === '0' && (currentCaretPosition !== 1 || value[1] === '0')) {
      value = removeFirstZeros(value);
    }

    value = this.matchValue(value);

    this._value = value;
    if (!isMoveToLastCaretPosition) {
      this.setCaretPosition(currentCaretPosition);
    }

    this.setToNgModel(value);
    this.currentCaretPosition = this.previousCaretPosition;
  }

  public matchValue(value: string): string {
    const matchedValue: RegExpMatchArray = value.match(this._regExp);
    return matchedValue ? matchedValue.join('') : '';
  }

  public renderViewValue(value: string): void {
    const normalizedValue = _.isNil(value) ? '' : value;
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.matchValue(normalizedValue.toString()));
  }

  public renderViewDisabled(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}

function removeFirstZeros(value: string): string {
  const [int, dec]: string[] = value.split('.');
  const fixedInt: string = Number(int).toString();
  const fixedDec: string = dec || value.includes('.') ? `.${dec}` : '';
  return fixedInt + fixedDec;
}

function removeDotsAfterFirst(value: string): string {
  const firstDotIndex: number = value.indexOf('.');

  let valueAfterDot: string = value.slice(firstDotIndex + 1);

  while (valueAfterDot.includes('.')) {
    valueAfterDot = valueAfterDot.replace('.', '');
  }

  return value.slice(0, firstDotIndex + 1) + valueAfterDot;
}
