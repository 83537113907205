import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {BackofficeQueryParamsHelper} from '@app/core/helpers/backoffice-query-params.helper';
import {MOSCOW_OFFSET_MINUTES} from '@app/pbsr/consts/moscow-time-offset.const';
import {IBackofficeData, IBackofficeDataWrapper} from '@app/pbsr/interfaces/backoffice-data.interface';
import {IBackofficeOperationsRequestParams} from '@app/pbsr/interfaces/backoffice-operations-request-params.interface';
import {IHistoricalOrderDto} from '@app/pbsr/models/dto/historical-orders.dto';
import {IHistoricalTradesDto} from '@app/pbsr/models/dto/historical-trades.dto';
import {HistoricalOrder} from '@app/trading-board/models/moex/historical-order';
import {HistoricalTrade} from '@app/trading-board/models/moex/historical-trade';
import {plainToInstance} from 'class-transformer';
import moment from 'moment';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HistoricalDataApiService {
  constructor(private readonly connectionService: ConnectionService) {}

  public getHistoricalTrades(
    params: IBackofficeOperationsRequestParams,
  ): Observable<{data: HistoricalTrade[]; total: number}> {
    if (!params.options) {
      params.options = {};
    }
    params.options.filterTradeDateTo = this.getEndOfYesterdayDay();

    return this.connectionService
      .get<IBackofficeDataWrapper<IBackofficeData<IHistoricalTradesDto>[]>>(
        '/backoffice/api/v1/tradesList',
        BackofficeQueryParamsHelper.getParams(params),
      )
      .pipe(
        map(result => {
          const historicalTrades = result.data.map(tradeBody => plainToInstance(HistoricalTrade, tradeBody.attributes));

          return {data: historicalTrades, total: result.meta.total};
        }),
        catchError(() => of({data: [], total: 0})),
      );
  }

  public getHistoricalOrders(
    params: IBackofficeOperationsRequestParams,
  ): Observable<{data: HistoricalOrder[]; total: number}> {
    if (!params.options) {
      params.options = {};
    }
    params.options.filterOrderDateTo = this.getEndOfYesterdayDay();

    return this.connectionService
      .get<IBackofficeDataWrapper<IBackofficeData<IHistoricalOrderDto>[]>>(
        '/backoffice/api/v1/ordersList',
        BackofficeQueryParamsHelper.getParams(params),
      )
      .pipe(
        map(result => {
          const historicalOrders = result.data.map(tradeBody => plainToInstance(HistoricalOrder, tradeBody.attributes));

          return {data: historicalOrders, total: result.meta.total};
        }),
        catchError(() => of({data: [], total: 0})),
      );
  }

  private getEndOfYesterdayDay(): string {
    return moment().utcOffset(MOSCOW_OFFSET_MINUTES).subtract(1, 'day').format('YYYY-MM-DD');
  }
}
