import {Exceptions} from '@app/core/exceptions/exceptions';

export class MaintenanceException extends Exceptions<JSON> {
  public static readonly STATUS_CODE = 503;

  private readonly _body: JSON;

  constructor(body: JSON) {
    super(null);
    this._body = body;
  }

  public getData(): JSON {
    return super.getData();
  }
}
