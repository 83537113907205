import {Identify} from '@amplitude/analytics-browser';
import {EnrichmentPlugin, BrowserClient} from '@amplitude/analytics-types';
import {TUserProperties} from '@app/analytic-systems/shared/types/user-properties.type';

export class UserPropertiesEnrichmentPlugin implements EnrichmentPlugin {
  constructor(private readonly instance: BrowserClient, private readonly userProperties: TUserProperties) {}

  public async setup(): Promise<void> {
    const identify = new Identify();

    Object.entries(this.userProperties).map(([key, value]) => {
      identify.set(key, value);
    });

    await this.instance.identify(identify).promise;
  }
}
