import {from, NEVER, Observable, of, timer} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {distinctUntilChanged, first, map, switchMap, take, tap} from 'rxjs/operators';

import {AB2TraderAuth} from './b2trader-auth.abstract';
import {IB2TraderApiData} from './interfaces/b2trader-api-data';

export class B2TraderAuthPublic extends AB2TraderAuth {
  public readonly apiData$ = this.apiDataValue$.asObservable().pipe(
    distinctUntilChanged(),
    tap(data => {
      if (!data) {
        this.reset$.next();
      }
    }),
    switchMap(v => (v ? of(v) : NEVER)),
  );

  constructor(protected readonly apiUrl: string, protected readonly platformType: string) {
    super(apiUrl, platformType);
  }

  protected getApiData(): Observable<IB2TraderApiData> {
    const url = `${this.apiUrl}/api/v1/b2trader/host?name=${this.platformType}`;
    return fromFetch<{host: string}>(url, {
      selector: res => res.json(),
      method: 'GET',
      credentials: 'include',
    }).pipe(
      map(res => {
        const apiUrl = res.host?.endsWith('/') ? res.host.slice(0, -1) : res.host;

        return {
          url: apiUrl,
          expirationTimestamp: null,
        };
      }),
    );
  }

  /**
   * Wrapper for fromFetch function, which adds base url and auth header.
   *
   * @param url - Request url.
   * @param options - Request options.
   * @returns Request result.
   */
  public fromFetch<T>(url: string, options?: RequestInit): Observable<T> {
    return this.apiData$.pipe(
      first(),
      switchMap(apiData =>
        this.fromApiFetch(apiData, url, options).pipe(
          // Process the response
          switchMap(res => {
            // If not 401 response - all fine
            if (res.status !== 401) {
              return from(res.json().catch(err => err as T)) as Observable<T>;
            }

            this.reset();
            return timer(300).pipe(switchMap(() => this.fromFetch<T>(url, options)));
          }),
        ),
      ),
      take(1),
    );
  }
}
