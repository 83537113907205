<app-dialog>
  <h1 class="title">{{ 'Ui.SigningForm.Wizard.ResignProfiles.Title' | translate }}</h1>

  <div>
    <p class="message">
      {{ 'Ui.SigningForm.Wizard.ResignProfiles.Message' | translate }}

      <ng-container *ngFor="let document of documents; let i = index; trackBy: trackByDocumentId">
        <span *ngIf="i > 0"> {{ 'Ui.SigningForm.Wizard.ResignProfiles.Сonjunction' | translate }}</span>
        <a (click)="download(document.id)">{{ document.text | translate }} </a>
      </ng-container>
    </p>
  </div>

  <div class="actions">
    <button mat-flat-button color="accent" (click)="goToSigning()">
      {{ 'Ui.SigningForm.Wizard.ResignProfiles.GetCode' | translate }}
    </button>
    <button mat-button (click)="redirectToLogin()">
      {{ 'Ui.SigningForm.Wizard.ResignProfiles.Close' | translate }}
    </button>
  </div>
</app-dialog>
