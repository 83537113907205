import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {ESharedEventHandlers} from '@app/trading-board/event-handlers/shared/shared-event-handlers.enum';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class SharedUnauthorizedService extends AEventHandler<void> {
  public type = ESharedEventHandlers.Unauthorized;

  constructor(
    private readonly snackbar: SnackbarService,
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  public handleMessage(): void {
    this.snackbar.message(this.translate.get('Please log in'));
  }
}
