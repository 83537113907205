import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import * as B2Margin from '@b2broker/b2margin-trade-models';
import {OrderTemplateTO, OrderTO} from '@b2broker/b2margin-trade-models';
import {Expose, Transform} from 'class-transformer';

import {IOrderData} from '../../interfaces/b2margin/order-data';
import {IOrderModelParam} from '../../interfaces/b2margin/order-model-param';
import {DecimalHelper} from '../decimal-helper';
import {AB2marginOrder} from './b2margin-order';

import OrderSideEnum = OrderTemplateTO.OrderSideEnum;
import TimeInForceEnum = OrderTO.TimeInForceEnum;

export class B2MarginOpenOrder extends AB2marginOrder<IOrderModelParam<OrderTO>> {
  private static getSide(raw: OrderTO): OrderSideEnum {
    return raw.quantity > 0 ? OrderSideEnum.BUY : OrderSideEnum.SELL;
  }

  public static filterOpenOrders(order: OrderTO): boolean {
    switch (order.status) {
      case 'SENDING':
      case 'PENDING':
      case 'PLACED':
      case 'WORKING':
      case 'WAITING_CONDITION':
      case 'WAITING_TRIGGER':
      case 'WAITING_LIMIT':
      case 'WAITING_STOP':
        return true;
      default:
        return false;
    }
  }

  public static adaptToCreate(params: IOrderData): B2Margin.OrderTemplateTO {
    const instrumentId = Number(params.instrumentId);
    const isBuy = params.orderSide === OrderSideEnum.BUY;
    const quantity = Number(params.quantity);

    /* eslint-disable @typescript-eslint/naming-convention */
    const data: B2Margin.OrderTemplateTO = {
      refOrderChainId: params.orderId,
      accountId: params.accountId,
      directExchange: false,
      legs: [
        {
          instrumentId,
          positionEffect: 'OPENING',
          ratioQuantity: 1,
        },
      ],
      orderSide: params.orderSide,
      orderType: params.orderType,
      quantity: isBuy ? quantity : -quantity,
      timeInForce: params.duration,
      additionalParameters: {},
      requestId: params.accountId,
      traderSelectedMarginRate: !params.orderId && params.marginRate ? params.marginRate : null,
      expireAt: params.expiredAt,
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    if (params.orderType === OrderTO.TypeEnum.LIMIT) {
      data.limitPrice = Number(params.limitPrice);
    }
    if (params.orderType === OrderTO.TypeEnum.STOP) {
      data.stopPrice = Number(params.stopPrice);
    }
    if (params.stopLoss) {
      data.stopLoss = B2MarginOpenOrder.mapProtectionOrderTo(params.stopLoss);
    }
    if (params.takeProfit) {
      data.takeProfit = B2MarginOpenOrder.mapProtectionOrderTo(params.takeProfit);
    }

    return data;
  }

  public static calculateMarginRate(leverage: number | string, scale: number): number {
    return DecimalHelper.from(1).divide(leverage).decimalInstance.toDecimalPlaces(scale, 1).toNumber();
  }

  @Expose()
  @Transform(({obj: {raw, tick}}: {obj: IOrderModelParam<OrderTO>}) =>
    B2MarginOpenOrder.isBuy(B2MarginOpenOrder.getSide(raw)) ? tick?.isBidIncrease : tick?.isOfferIncrease,
  )
  public isPriceIncrease: boolean;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => raw.orderId)
  public orderId: number;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => B2MarginOpenOrder.findInstrumentId(raw))
  public instrumentId: string;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) =>
    raw.quantity > 0 ? OrderSideEnum.BUY : OrderSideEnum.SELL,
  )
  public side: OrderSideEnum;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam<OrderTO>}) =>
    DecimalHelper.from(raw.quantity ?? 0)
      .divide(instrument.lotSize)
      .abs(),
  )
  public size: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam<OrderTO>}) =>
    DecimalHelper.from(
      Number(raw.fillPrice) || Number(raw.limitPrice) || Number(raw.stopPrice) || 0,
      instrument?.priceScale,
    ),
  )
  public price: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => raw.type)
  public type: OrderTO.TypeEnum;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => raw.status)
  public status: string;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam<OrderTO>}) =>
    raw.stopLoss?.fixedPrice
      ? DecimalHelper.from(raw.stopLoss?.fixedPrice, instrument?.priceScale).multiply(instrument.lotSize)
      : null,
  )
  public stopLoss: DecimalHelper | null;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam<OrderTO>}) =>
    raw.takeProfit?.fixedPrice
      ? DecimalHelper.from(raw.takeProfit?.fixedPrice, instrument?.priceScale).multiply(instrument.lotSize)
      : null,
  )
  public takeProfit: DecimalHelper | null;

  @Expose()
  @Transform(({obj: {tick}}: {obj: IOrderModelParam<OrderTO>}) => tick?.bestBid)
  public bid: LazyDecimalHelper;

  @Expose()
  @Transform(({obj: {tick}}: {obj: IOrderModelParam<OrderTO>}) => tick?.bestOffer)
  public ask: LazyDecimalHelper;

  @Expose()
  @Transform(({obj: {raw, tick}}: {obj: IOrderModelParam<OrderTO>}) =>
    B2MarginOpenOrder.isBuy(B2MarginOpenOrder.getSide(raw)) ? tick?.bestBid : tick?.bestOffer,
  )
  public currentPrice: LazyDecimalHelper;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) =>
    raw.triggerPrice ? DecimalHelper.from(raw.triggerPrice) : null,
  )
  public triggerPrice: DecimalHelper | null;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => raw.expireAt)
  public expiration?: Date;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => raw.timeInForce)
  public timeInForce: TimeInForceEnum;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => DecimalHelper.from(raw.remainingQuantity).abs())
  public quantity: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam<OrderTO>}) =>
    DecimalHelper.from(raw.fillPrice, instrument?.priceScale),
  )
  public fillPrice: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => DecimalHelper.from(raw.filledQuantity))
  public filledSize: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => new Date(raw.createdTime))
  public time: Date;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => new Date(raw.modifiedTime))
  public modifiedTime: Date;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<OrderTO>}) => DecimalHelper.from(raw.marginRate))
  public marginRate: DecimalHelper;

  @Expose()
  @Transform(({obj: {instrument}}: {obj: IOrderModelParam<OrderTO>}) => instrument?.symbolWithSeparator)
  public symbolWithSeparator: string;

  public update({tick, raw, instrument}: IOrderModelParam<OrderTO>): void {
    this.isPriceIncrease = B2MarginOpenOrder.isBuy(B2MarginOpenOrder.getSide(raw))
      ? tick?.isBidIncrease
      : tick?.isOfferIncrease;
    this.size = DecimalHelper.from(raw.quantity ?? 0)
      .divide(instrument.lotSize)
      .abs();
    this.price = DecimalHelper.from(
      Number(raw.fillPrice) || Number(raw.limitPrice) || Number(raw.stopPrice) || 0,
      instrument?.priceScale,
    );
    this.status = raw.status;
    this.stopLoss = raw.stopLoss?.fixedPrice
      ? DecimalHelper.from(raw.stopLoss?.fixedPrice, instrument?.priceScale)
      : null;
    this.takeProfit = raw.takeProfit?.fixedPrice
      ? DecimalHelper.from(raw.takeProfit?.fixedPrice, instrument?.priceScale)
      : null;
    this.bid = tick?.bestBid;
    this.ask = tick?.bestOffer;
    this.currentPrice = B2MarginOpenOrder.isBuy(B2MarginOpenOrder.getSide(raw)) ? tick?.bestBid : tick?.bestOffer;
    this.triggerPrice = raw.triggerPrice ? DecimalHelper.from(raw.triggerPrice) : null;
    this.expiration = raw.expireAt;
    this.timeInForce = raw.timeInForce;
    this.quantity = DecimalHelper.from(raw.remainingQuantity).abs();
    this.fillPrice = DecimalHelper.from(raw.fillPrice, instrument?.priceScale);
    this.filledSize = DecimalHelper.from(raw.filledQuantity);
    this.time = new Date(raw.createdTime);
    this.modifiedTime = new Date(raw.modifiedTime);
    this.marginRate = DecimalHelper.from(raw.marginRate);
  }
}
