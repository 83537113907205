import {Type} from '@angular/core';
import {ESelectTypeId} from '@app/core/enums/select-type-id';
import {CaptchaComponent} from '@app/core/form/components/captcha/captcha.component';
import {CheckboxComponent} from '@app/core/form/components/checkbox/checkbox.component';
import {CodeComponent} from '@app/core/form/components/code/code.component';
import {DateComponent} from '@app/core/form/components/date/date.component';
import {InputComponent} from '@app/core/form/components/input/input.component';
import {PasswordButtonComponent} from '@app/core/form/components/password.button/password.button.component';
import {PhoneComponent} from '@app/core/form/components/phone/phone.component';
import {QuestionComponent} from '@app/core/form/components/question/question.component';
import {RadioButtonComponent} from '@app/core/form/components/radio.button/radio.button.component';
import {RadioChipsComponent} from '@app/core/form/components/radio.chips/radio.chips.component';
import {RadioComponent} from '@app/core/form/components/radio/radio.component';
import {SelectComponent} from '@app/core/form/components/select/select.component';
import {TextfieldComponent} from '@app/core/form/components/textfield/textfield.component';
import {ToggleButtonComponent} from '@app/core/form/components/toggle-button/toggle-button.component';

import {FeeSelectComponent} from '../components/fee-select/fee-select.component';
import {FormField, FormFieldType} from './form.field';
import {FormInput} from './form.input';

export function formFieldFactory(field: FormField): Type<FormInput> {
  switch (field.type) {
    case null:
    case undefined:
    case FormFieldType.input:
      return InputComponent;
    case FormFieldType.select:
    case FormFieldType.multiSelect:
    case FormFieldType.selectAutocomplete:
      if (field.id === ESelectTypeId.SelectButtons) {
        return FeeSelectComponent;
      }
      return SelectComponent;
    case FormFieldType.boolean:
      return CheckboxComponent;
    case FormFieldType.date:
      return DateComponent;
    case FormFieldType.radio:
      return RadioComponent;
    case FormFieldType.radioChips:
      return RadioChipsComponent;
    case FormFieldType.radioButton:
      return RadioButtonComponent;
    case FormFieldType.code:
      return CodeComponent;
    case FormFieldType.password:
    case FormFieldType.passwordButton:
      return PasswordButtonComponent;
    case FormFieldType.question:
      return QuestionComponent;
    case FormFieldType.captcha:
      return CaptchaComponent;
    case FormFieldType.text:
      return TextfieldComponent;
    case FormFieldType.phone:
      return PhoneComponent;
    case FormFieldType.toggle:
      return ToggleButtonComponent;
    default:
      return InputComponent;
  }
}
