import {Type} from '@angular/core';

import {ETradingBoardProviderAlias} from '../enum/provider-alias';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IReplaceableComponent {}

export type TEntryPoint<T = IReplaceableComponent> = () => Promise<{entryPoint: Type<T>}>;

export abstract class AComponentResolver {
  public abstract readonly type: ETradingBoardProviderAlias;

  public abstract getComponent<T>(type: Type<unknown>): TEntryPoint<T> | undefined;
}
