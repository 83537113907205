import {TradingCalendarTO} from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';
import {EEventHandlersReceived} from '../../event-handlers.enum';

export class TradingCalendarInternalEventHandler extends AEventHandler<IB2marginMessage<TradingCalendarTO>> {
  public type = EB2MarginEventType.TradingCalendar;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<TradingCalendarTO>): void {
    this.store.messages$.next({type: EEventHandlersReceived.TradingCalendar, payload: resource.body.endOfDay});
  }
}

b2MarginInternalEventHandlers.push(TradingCalendarInternalEventHandler);
