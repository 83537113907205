import {Injectable} from '@angular/core';
import {MenuService} from '@app/core/menu/services/menu.service';
import {shareReplayWithRef} from '@app/core/utils/share-replay-with-ref';
import {AccessAlias} from '@env/environment.entities';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

type TCheckType = 'every' | 'some';

type TParamsForCheckAccess = Readonly<{
  readonly accessKeysToCheck: AccessAlias[];
  readonly availableAccessKeys: Set<AccessAlias>;
  readonly checkType?: TCheckType;
}>;

@Injectable()
export class AccessService {
  private readonly accessAliasesFromApi = new Set<AccessAlias>();

  constructor(private readonly menuService: MenuService) {}

  private isAccessAllowed({accessKeysToCheck, availableAccessKeys, checkType}: TParamsForCheckAccess): boolean {
    if (!accessKeysToCheck) {
      return false;
    }

    if (checkType === 'some') {
      return accessKeysToCheck.some(a => availableAccessKeys.has(a));
    }

    return accessKeysToCheck.every(a => availableAccessKeys.has(a));
  }

  private setAccessKeys(keys: AccessAlias[]): void {
    this.accessAliasesFromApi.clear();
    keys.forEach(k => this.accessAliasesFromApi.add(k));
  }

  public checkAccess(aliases: AccessAlias[], checkType: TCheckType = 'every'): boolean {
    if (aliases.find(alias => alias === AccessAlias.ForceEnabled)) {
      return true;
    }

    return this.isAccessAllowed({
      accessKeysToCheck: aliases,
      availableAccessKeys: this.accessAliasesFromApi,
      checkType,
    });
  }

  public checkAccessAsync(aliases: AccessAlias[], checkType: TCheckType = 'every'): Observable<boolean> {
    if (aliases.find(alias => alias === AccessAlias.ForceEnabled)) {
      return of(true);
    }

    return this.menuService.getAccessAliasKeysFromApi().pipe(
      tap(availableAliases => this.setAccessKeys(availableAliases)),
      map(availableAliases =>
        this.isAccessAllowed({accessKeysToCheck: aliases, availableAccessKeys: new Set(availableAliases), checkType}),
      ),
      shareReplayWithRef(),
    );
  }
}
