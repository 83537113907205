<div class="container page">
  <header class="header">
    <app-logo></app-logo>

    <app-language *ngIf="isLanguageSelectEnabled"></app-language>
  </header>

  <div class="content">
    <main class="error-view">
      <div class="error-id">404</div>

      <h1>{{ "Oops, you're lost" | translate }}</h1>

      <p>{{ 'The page you are looking for was not found' | translate }}</p>

      <a mat-stroked-button color="accent" routerLink="/" qa-id="goto home" class="body-bg">
        {{ 'Error.Button.GoToHomePage' | translate }}</a
      >
    </main>
  </div>
</div>
