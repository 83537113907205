import {Direction, Directionality} from '@angular/cdk/bidi';
import {Injectable} from '@angular/core';
import {Language} from '@app/language/models/language';
import {LanguageService} from '@app/language/services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DirectionService {
  private languages?: Language[];

  public readonly dir = new BehaviorSubject<Direction>(null);

  constructor(
    private readonly directionality: Directionality,
    private readonly translate: TranslateService,
    private readonly langService: LanguageService,
  ) {
    // notice: order of subscription is important
    this.directionality.change.pipe(distinctUntilChanged()).subscribe(dir => this.dir.next(dir));

    this.langService.all().subscribe(languages => {
      this.languages = languages;
      this.resolveByLang(langService.selected);
    });

    this.translate.onLangChange.pipe(distinctUntilChanged()).subscribe(event => this.resolveByLang(event.lang));
  }

  private resolveByLang(lang: string): void {
    const currentLang: Language | undefined = this.languages?.find(i => i.lang === lang);
    const dir: Direction = currentLang?.rtl ? 'rtl' : 'ltr';
    this.setDir(dir);
  }

  public setDir(value: Direction = 'ltr'): void {
    this.directionality.change.emit(value);
    document.documentElement.dir = value;
  }
}
