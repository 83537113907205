import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash-es';

@Pipe({
  name: 'transformEmpty',
})
export class TransformEmptyPipe implements PipeTransform {
  public transform(value: unknown, args?: string): unknown {
    return _.isNil(value) ? args : value;
  }
}
