import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {AEventHandler} from '../event-handler.abstract';
import {EEventHandlersReceived} from '../event-handlers.enum';

@Injectable()
export class B2marginCloseHandlerService extends AEventHandler<Atmosphere.Response> {
  public type = EEventHandlersReceived.Close;

  constructor(private readonly router: Router) {
    super();
  }

  public handleMessage(resource: Atmosphere.Response): void {
    if (!resource.error) {
      return;
    }

    void this.router.navigateByUrl('/');
  }
}
