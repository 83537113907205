import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormFieldOption, FormFieldType} from '@app/core/form/entities/form.field';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {OptionsService} from '@app/core/form/services/options.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {SelectSearchComponent} from './select-search/select-search.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent extends FormInput implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private originalOptions: FormFieldOption[] = [];

  public readonly formFieldTypesEnum = FormFieldType;
  public filteredOptions: FormFieldOption[];
  public required: boolean;
  public isRequiredIf: boolean;
  public name: string;

  @ViewChild(SelectSearchComponent)
  public selectSearchComponent: SelectSearchComponent;

  public get isSelectAutocompleteType(): boolean {
    return this.field.type === FormFieldType.selectAutocomplete;
  }

  public get isShowRequiredIndicator(): boolean {
    return this.isTouchedAndInvalid();
  }

  constructor(
    labelService: LabelService,
    errorsService: ErrorsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly optionsService: OptionsService,
  ) {
    super(labelService, errorsService);
  }

  public ngOnInit(): void {
    this.getOptions();

    this.required = this.isRequired();
    this.isRequiredIf = this.isRequiredIfValidator();
    this.name = this.field.name?.toLowerCase();
  }

  public resetControl(event: Event): void {
    this.control.reset();

    event.stopPropagation();
  }

  public searchStringChanged(searchValue: string): void {
    this.filteredOptions = this.originalOptions.filter(option =>
      option.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }

  public selectOpened(): void {
    this.selectSearchComponent?.focus();
  }

  public selectClosed(): void {
    this.selectSearchComponent?.resetControl();

    this.filteredOptions = this.originalOptions;
  }

  private getOptions(): void {
    if (this.field?.fieldToWatch) {
      const fieldToWatchGroup = this.formGroup.get(this.field.fieldToWatch);

      if (fieldToWatchGroup.value) {
        this.loadOptions(fieldToWatchGroup.value);
      }

      fieldToWatchGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
        if (!value) {
          this.control.setValue('');
          this.setOptions([]);

          return;
        }

        this.loadOptions(value);
      });

      return;
    }

    if (this.field.options && this.field.options.length) {
      this.setOptions(this.field.options);

      return;
    }

    this.loadOptions();
  }

  private loadOptions(value?: string | number): void {
    this.optionsService.getOptions(this.field.optionsType, value).subscribe((options: FormFieldOption[]): void => {
      this.setOptions(options);

      if (!options?.length) {
        this.control.setValue('');
      }

      if (this.field.preselect_single_option && options?.length === 1) {
        this.control.setValue(options[0].value);
      }

      this.cdr.detectChanges();
    });
  }

  private setOptions(options: FormFieldOption[]): void {
    this.filteredOptions = options;
    this.originalOptions = options;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
