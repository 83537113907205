import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Environment} from '@env/environment.entities';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(private readonly environment: Environment, private readonly router: Router) {}

  public canActivate(): boolean {
    if (this.environment.isPublic) {
      void this.router.navigate(['/public'], {
        queryParamsHandling: 'merge',
        queryParams: this.router.getCurrentNavigation()?.finalUrl.queryParams,
      });

      return false;
    }

    return true;
  }
}
