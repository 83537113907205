import {DecimalHelper} from '@b2broker/decimal-helper';
import {Transform, Type} from 'class-transformer';

class Fee {
  @Transform(({value}) => DecimalHelper.from(value))
  public percentFee: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public minimalFee: DecimalHelper;
}

export class MarketFee {
  @Type(() => Fee)
  public maker: Fee;

  @Type(() => Fee)
  public taker: Fee;
}
