import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {Rate} from '@app/trading-board/models/b2margin/rate';

@Injectable()
export class RatesService extends AEventHandler<Rate[]> {
  public type = EEventHandlersReceived.Rates;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: Rate[]): void {
    resource.forEach(item => {
      this.b2marginDatafeedService.rates.get(item.symbolWithSeparator)?.stream.next(item.value);
    });
  }
}
