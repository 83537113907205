export class StatementB2Margin {
  public token: string;

  public account: string;

  public statementUrl: string;

  public getLinkStatementB2Margin(from: number, to: number, locale: string, format: string): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
      `${this.statementUrl}?` +
      `token=${this.token}&` +
      `account=${this.account}&` +
      `from=${from}&` +
      `to=${to}&` +
      `locale=${locale}&` +
      `timeZone=${timeZone}&` +
      `format=${format}`
    );
  }
}
