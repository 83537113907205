import {Tick} from './level1';

export class Level1Collector extends Map<string, Tick> {
  public hasPrices: boolean;
  public hasVolume: boolean;
  public hasLastTradedPx: boolean;
  public hasChangeQuantity: boolean;

  public update(): void {
    const ticks = Array.from(this.values());
    this.hasPrices = ticks.some(tick => tick.bestBid.greaterThanZero() && tick.bestOffer.greaterThanZero());
    this.hasVolume = ticks.some(tick => tick.rolling24HrVolume.greaterThanZero());
    this.hasLastTradedPx = ticks.some(tick => !!tick.lastTradedPx);
    this.hasChangeQuantity = ticks.some(tick => tick.rolling24HrChangeQuantity.greaterThanZero());
  }

  public tickByInstrument(instrumentId: string): Tick | undefined {
    return Array.from(this.values()).find(item => item.instrumentId === instrumentId);
  }
}
