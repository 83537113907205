import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConnectionNativeService} from '@app/core/connection-native.service';
import {IResultApi} from '@app/core/contracts/i.result.api';
import {getLSGridsterConfigKey, getLSGridsterOptionsKey, LocalStorageWorker} from '@app/core/local-storage.worker';
import {AuthService} from '@app/core/services/auth.service';
import {NotificationsService} from '@app/core/services/notifications.service';
import {UserService} from '@app/core/services/user.service';
import {WebSocketService} from '@app/core/services/web-socket.service';
import {ComplexProductCategoryCheckerService} from '@app/pbsr/services/complex-product-category-checker/complex-product-category-checker.service';
import {WarpInstrumentsStoreService} from '@app/pbsr/services/warp-instruments-store/warp-instruments-store.service';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {ResetAll} from '@app/state/plugins/logout/reset.actions';
import {WarpAuthService} from '@app/trading-board/services/warp-auth.service';
import {Environment} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  interval,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';

import {CurrentUserService} from './current-user.service';
import {VerificationService} from './verification.service';

@Injectable({providedIn: 'root'})
export class LogoutService {
  private static readonly TIMEOUT_CHECKING_IN_MS = 10_000;

  private sub: Subscription = Subscription.EMPTY;
  private readonly _showProgressBar$ = new Subject<boolean>();

  public showProgressBar$ = this._showProgressBar$.pipe(distinctUntilChanged());
  public lastRequestTime$ = new ReplaySubject<number>();

  constructor(
    private readonly environment: Environment,
    private readonly router: Router,
    private readonly store: Store,
    private readonly notificationsService: NotificationsService,
    private readonly webSocketService: WebSocketService,
    private readonly connectionNativeService: ConnectionNativeService,
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly verificationService: VerificationService,
    private readonly currentUserService: CurrentUserService,
    private readonly dialog: MatDialog,
    private readonly warpAuthService: WarpAuthService,
    private readonly warpInstrumentsStoreService: WarpInstrumentsStoreService,
    private readonly complexProductCategoryCheckerService: ComplexProductCategoryCheckerService,
  ) {}

  public clearResources(): void {
    this.notificationsService.unsubscribe();
    this.webSocketService.disconnect();
    this.store.dispatch(new ResetAll());
    this.verificationService.clearUserRights();
    this.currentUserService.clear();
    this.warpAuthService.clear();
    this.warpInstrumentsStoreService.clear();
    this.dialog.closeAll();
    this.complexProductCategoryCheckerService.clear();
  }

  public async logout(options?: {isDoNotNavigateToLoginAfterLogout?: boolean}): Promise<void> {
    const theOptions = {
      isDoNotNavigateToLoginAfterLogout: options?.isDoNotNavigateToLoginAfterLogout === true,
    };
    try {
      if (!this.authService.isLoggedIn()) {
        this.clearResources();
        if (!theOptions.isDoNotNavigateToLoginAfterLogout) {
          void this.router.navigateByUrl('/login');
        }
        return;
      }

      await this.userService.settings({
        gridsters: ALocaleStorage.getItem(getLSGridsterConfigKey(this.environment.projectInfo.name)),
        gridstersOptions: ALocaleStorage.getItem(getLSGridsterOptionsKey(this.environment.projectInfo.name)),
      });

      await firstValueFrom(this.connectionNativeService.post<IResultApi>('/api/v1/logout', []));

      this.clearResources();
      LocalStorageWorker.clear();

      if (!theOptions.isDoNotNavigateToLoginAfterLogout) {
        void this.router.navigateByUrl('/login');
      }
    } catch (error) {
      this.snackbarService.error(this.translateService.get('Logout.FailLogoutMessage'));
    }
  }

  public reset(): void {
    this.startChecking();
  }

  public startChecking(): void {
    this.sub.unsubscribe();

    this.sub = combineLatest([this.lastRequestTime$, interval(LogoutService.TIMEOUT_CHECKING_IN_MS)]).subscribe(
      ([lastRequestTime]) =>
        this._showProgressBar$.next(Date.now() - lastRequestTime > this.environment.logoutTimeoutInMs),
    );
  }

  public stopChecking(): void {
    this.sub.unsubscribe();
  }
}
