import {IUserSettings} from '@app/trading-board/interfaces/b2margin/user-settings';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class UserSettingsInternalEventHandler extends AEventHandler<IB2marginMessage<B2MarginModels.UserSettingsTO>> {
  public type = EB2MarginEventType.UserSettings;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage({body}: IB2marginMessage<B2MarginModels.UserSettingsTO>): void {
    this.store.userSettings$.next(body.settings as IUserSettings);
  }
}

b2MarginInternalEventHandlers.push(UserSettingsInternalEventHandler);
