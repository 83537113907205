import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {MessageTO} from '@b2broker/b2margin-trade-models';
import {plainToInstance} from 'class-transformer';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {OrderStatus} from '../../../models/b2margin/order-status';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';
import {EEventHandlersReceived} from '../../event-handlers.enum';

export class OrderStatusesInternalEventHandler extends AEventHandler<IB2marginMessage<MessageTO>> {
  private readonly positionNotifications = new Map<string, Date[]>();

  public type = EB2MarginEventType.OrderStatuses;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public static isOrder(resource: MessageTO): boolean {
    return resource.messageType === B2MarginModels.MessageTO.MessageTypeEnum.ORDER;
  }

  public handleMessage(resource: IB2marginMessage<MessageTO>): void {
    const {positionCode, timeStamp, orderStatus} = resource.body.parametersTO;
    const accountOrdersNotifications = this.positionNotifications.get(positionCode) ?? [];

    if (!accountOrdersNotifications.includes(timeStamp)) {
      switch (orderStatus) {
        case 'FILLED':
        case B2MarginModels.OrderTO.StatusEnum.REJECTED:
        case B2MarginModels.OrderTO.StatusEnum.PLACED:
          this.store.messages$.next({
            type: EEventHandlersReceived.OrderStatus,
            payload: plainToInstance(OrderStatus, {
              status: orderStatus,
              positionCode,
            }),
          });
          break;
        default:
          break;
      }
    }

    accountOrdersNotifications.push(timeStamp);
    this.positionNotifications?.set(positionCode, accountOrdersNotifications);
  }
}

b2MarginInternalEventHandlers.push(OrderStatusesInternalEventHandler);
