import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {apiDateFormat, MY_FORMATS, userDateFormat} from '@app/core/helpers/date.formats';
import moment, {Moment} from 'moment';
import {asyncScheduler, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, observeOn, filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DateComponent extends FormInput implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();

  public readonly mask = '09/09/0099';

  public dateControl: UntypedFormControl;
  public errorMatcher: ErrorStateMatcher;
  public required: boolean;
  public isRequiredIf: boolean;

  public get isShowRequiredIndicator(): boolean {
    return this.isTouchedAndInvalid();
  }

  constructor(labelsService: LabelService, errorsService: ErrorsService) {
    super(labelsService, errorsService);
  }

  public ngOnInit(): void {
    let initData: moment.Moment | null;
    if (this.control.value) {
      initData = moment(this.control.value, apiDateFormat);
    }

    this.dateControl = new UntypedFormControl((initData && initData.format(userDateFormat)) || '');
    this.errorMatcher = new CustomErrorStateMatcher(this.control);
    this.required = this.isRequired();
    this.isRequiredIf = this.isRequiredIfValidator();

    this.control.statusChanges
      .pipe(
        distinctUntilChanged(),
        filter(status => status === 'INVALID'),
        // FDP-14325 async scheduler is used to prevent ExpressionChangedAfterItHasBeenCheckedError
        observeOn(asyncScheduler),
        takeUntil(this.destroyer$),
      )
      .subscribe(() => this.updateDateControlState());
  }

  public dateChange(event: MatDatepickerInputEvent<Moment>): void {
    this.spreadValue(event.value);
    this.updateDateControlState();
  }

  public onInput(event: Event): void {
    const date = getMoment(event);
    this.control.setValue(date.isValid() ? date.format(apiDateFormat) : '');
  }

  public onBlur(event: FocusEvent): void {
    const date = getMoment(event);
    this.dateControl.setValue(date.format(userDateFormat));
    this.updateDateControlState();
  }

  public getError(error: string): Observable<string> {
    return super.getError(error);
  }

  private spreadValue(value: Moment): void {
    this.control.setValue(value.format(apiDateFormat));
    this.dateControl.setValue(value.format(userDateFormat));
  }

  private updateDateControlState(): void {
    this.dateControl.markAsTouched();
    this.dateControl.markAsDirty();
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  constructor(private readonly linkedControl: UntypedFormControl) {}

  public isErrorState(control: UntypedFormControl | null): boolean {
    return control.dirty && control.touched && this.linkedControl.invalid;
  }
}

export function getMoment(event: Event, format?: string): Moment {
  const value = (event.target as HTMLInputElement).value;

  return moment(value, format || userDateFormat);
}
