import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {MenuService} from '@app/core/menu/services/menu.service';
import {AuthService} from '@app/core/services/auth.service';
import {Environment} from '@env/environment.entities';
import {Observable, of} from 'rxjs';
import {first, map} from 'rxjs/operators';

@Injectable()
export class EqwireAccessGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly environment: Environment,
    private readonly router: Router,
    private readonly menuService: MenuService,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    if (this.environment.isEqwireProject && this.authService.isLoggedIn()) {
      return of(true);
    }

    return this.menuService.getHomeUrl().pipe(
      first(),
      map(url => this.router.createUrlTree([url])),
    );
  }
}
