import {ITradingStatusData} from '@app/pbsr/interfaces/trading-status-data.interface';
import {EMoexOpcode} from '@app/trading-board/enum/moex/opcode';
import {MoexStateStore} from '@app/trading-board/facades/moex-state-store';
import {IMoexMessage} from '@app/trading-board/interfaces/moex/moex-message';

import {AEventHandler, moexInternalEventHandlers} from '../../event-handler.abstract';

export class MoexTradingStatusDataInternalEventHandler extends AEventHandler<IMoexMessage<ITradingStatusData>> {
  public readonly type = EMoexOpcode.InstrumentsGetAndSubscribeV2;

  constructor(private readonly store: MoexStateStore) {
    super();
  }

  public handleMessage(message: IMoexMessage<ITradingStatusData>): void {
    this.store.tradingStatusData$.next(message);
  }
}

moexInternalEventHandlers.push(MoexTradingStatusDataInternalEventHandler);
