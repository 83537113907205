import {InstrumentMarginRatesTO} from '@b2broker/b2margin-trade-models';
import {plainToInstance} from 'class-transformer';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {MarginRate} from '../../../models/b2margin/margin-rate';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';
import {EEventHandlersReceived} from '../../event-handlers.enum';

export class MarginRatesInternalEventHandler extends AEventHandler<InstrumentMarginRatesTO> {
  public type = EB2MarginEventType.MarginRates;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: InstrumentMarginRatesTO): void {
    this.store.messages$.next({
      type: EEventHandlersReceived.MarginRates,
      payload: plainToInstance(MarginRate, resource),
    });
  }
}

b2MarginInternalEventHandlers.push(MarginRatesInternalEventHandler);
