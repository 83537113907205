import {MetaDataModel} from '@ngxs/store/src/internal/internals';

export {MetaDataModel};

/**
 * A simplified implementation of NGXS StateClass interface.
 */
export interface IStateClass<T = object> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NGXS_META?: MetaDataModel;

  new (...args: unknown[]): T;
}

export function noop(): () => void {
  return () => void 0;
}
