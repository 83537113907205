import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {IBackofficeData, IBackofficeDataWrapper} from '../interfaces/backoffice-data.interface';
import {IComplexProductCategoriesDto} from '../models/dto/complex-product-categories.dto';

@Injectable({providedIn: 'root'})
export class ComplexProductCategoriesApiService {
  constructor(private readonly connectionService: ConnectionService) {}

  public getComplexProductCategories(): Observable<string[]> {
    return this.connectionService
      .get<IBackofficeDataWrapper<IBackofficeData<IComplexProductCategoriesDto>[]>>(
        '/backoffice/api/v1/complexProductCategories',
      )
      .pipe(
        map(result => result.data.map(item => item.id)),
        catchError(() => of([])),
      );
  }
}
