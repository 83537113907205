import {GridsterProviders} from '@app/core/models/gridster/gridster-providers';
import {ESynchronizationColors} from '@app/trading-board/enum/moex/synchronization-colors';
import {GridsterItem as _GridsterItem} from 'angular-gridster2';
import {Exclude, Expose, Transform} from 'class-transformer';
import {IsBoolean, IsDefined, IsObject, IsOptional, IsString} from 'class-validator';
import * as _ from 'lodash-es';
import {nanoid} from 'nanoid';
import {BehaviorSubject, Subject} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWidgetSettings {}

export interface IGridsterItem {
  id: string;
  extraSegment?: string;
  name: string;
  icon?: string;
  label: string;
  sort?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  closeDisable?: boolean;
  provider?: GridsterProviders;
  settings?: IWidgetSettings;
  overrides?: Partial<IGridsterItem>;
  isFreezAbilityEnabled?: boolean;
  sizes: IWidgetSizes;
}

export interface IWidgetSizes extends _GridsterItem {
  minItemCols?: number;
  maxItemCols?: number;
  minItemRows?: number;
  maxItemRows?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  resizeEnabled?: boolean;
  layerIndex?: number;
  cols: number;
  rows: number;
  y: number;
  x: number;
}

export class GridsterItem implements IGridsterItem {
  public static hasPairSetting(settings: IWidgetSettings): settings is IWidgetSettings & {pair: string} {
    return (settings as IWidgetSettings & {pair: string}).pair !== undefined;
  }

  public static hasColorSetting(
    settings: IWidgetSettings,
  ): settings is IWidgetSettings & {color: ESynchronizationColors} {
    if (_.isNil(settings)) {
      return false;
    }
    return (settings as IWidgetSettings & {color: ESynchronizationColors}).color !== undefined;
  }

  public static hasSymbolSetting(settings: IWidgetSettings): settings is IWidgetSettings & {symbol: string} {
    return (settings as IWidgetSettings & {symbol: string}).symbol !== undefined;
  }

  @Expose()
  @IsDefined()
  @IsString()
  public id: string;

  @Expose()
  @IsDefined()
  @IsString()
  public name: string;

  @Expose()
  @IsDefined()
  @IsString()
  public label: string;

  @Expose()
  @IsDefined()
  @IsString()
  public provider: GridsterProviders;

  @Expose()
  @IsOptional()
  @IsObject()
  public settings: IWidgetSettings;

  @Expose()
  @IsDefined()
  @IsObject()
  public sizes: IWidgetSizes;

  @Expose()
  @IsBoolean()
  @IsOptional()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public closeDisable?: boolean;

  @Expose()
  @IsString()
  @IsOptional()
  public icon?: string;

  @Expose()
  @IsString()
  @IsOptional()
  public extraSegment?: string;

  @Expose()
  @IsString()
  @Transform(({value, obj}: {value: string; obj: GridsterItem}) => value ?? `${obj.id}[${nanoid()}]`)
  public settingsId: string;

  @Exclude({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    toPlainOnly: true,
  })
  public changes$ = new Subject<void>();

  @Expose()
  @IsOptional()
  public sort?: number;

  @Exclude({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    toPlainOnly: true,
  })
  public attached$ = new BehaviorSubject<boolean>(false);

  public reset(config: GridsterItem, id: number): void {
    this.name = config.name;
    this.label = config.label;
    this.settings = config.settings;
    this.sizes = Object.assign(this.sizes, {...config.sizes, layerIndex: id});
    this.closeDisable = config.closeDisable;
    this.changes$.next();
  }

  public setSettings(settings: IWidgetSettings, isEmit?: boolean): void {
    this.settings = settings;

    if (!_.isNil(isEmit)) {
      this.changes$.next();
    }
  }
}
