import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FileComponent} from '@app/core/form/components/file/file.component';
import {FormFieldRuleValue} from '@app/core/form/entities/form.field';
import * as _ from 'lodash-es';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-multiple-files',
  templateUrl: './multiple-files.component.html',
  styleUrls: ['./multiple-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleFilesComponent extends FileComponent implements OnInit {
  @Output()
  public readonly formArrayChanged = new EventEmitter<UntypedFormArray>();

  public filesArray: UntypedFormArray | undefined;

  public ngOnInit(): void {
    if (_.isNil(this.group)) {
      this.group = new UntypedFormGroup({});
    }

    this.group.addControl('files', new UntypedFormArray([]));

    this.filesArray = this.group.get('files') as UntypedFormArray;

    // TODO: Now requiredIf processed like required, it is wrong behaviour, fix it in https://b2btech.atlassian.net/browse/FDP-12579
    this.isRequired = this.field.rules?.some(
      i => i[0] === 'required' || i[0] === 'requiredString' || i[0] === 'requiredIf',
    );
    if (this.isRequired) {
      this.filesArray.setValidators(Validators.required);
    }

    // We no need array rule here, because always only one file will be checked.
    this.field.rules = this.field.rules.filter(x => x[0] !== 'array');

    this.setValidators();

    this.mapFilesToFormGroup()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() => this.formArrayChanged.emit(this.filesArray));

    this.group.statusChanges.pipe(takeUntil(this.destroyer$)).subscribe(() => {
      this.errors = this.filesArray.controls.reduce((result, control, index) => {
        if (control.errors) {
          result[index] = control.errors;
        }

        return result;
      }, {});

      this.cdr.detectChanges();
    });

    this.formArrayChanged.emit(this.filesArray);
  }

  protected override addFilesToFormGroup(files: File[], formGroup: UntypedFormGroup): void {
    this.filesArray.clear();

    files.forEach(file => {
      const control = new UntypedFormControl(file, this.validator);
      this.filesArray.push(control);
      control.markAsTouched();
      formGroup.updateValueAndValidity();
    });
  }

  public override getError(key: string, value?: FormFieldRuleValue): Observable<string> {
    return this.errorsService.getError(key, value ?? this.filesArray.errors[key]);
  }
}
