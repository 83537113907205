import {Injectable, Optional} from '@angular/core';
import {AccessService} from '@app/access/access.service';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {mapException} from '@app/core/exceptions/client.exception';
import {isResponse} from '@app/core/exceptions/response-exception';
import {UserRights} from '@app/core/models/user/user-rights';
import {IUserRightsDto} from '@app/core/models/user/user-rights.dto';
import {EAliasBlocked} from '@app/core/models/verification/level';
import {AccessAlias} from '@env/environment.entities';
import {plainToClass} from 'class-transformer';
import {BehaviorSubject, NEVER, Observable, of} from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  private readonly loadRights$ = new BehaviorSubject(true);

  public readonly userRights$: Observable<UserRights> = this.loadRights$.pipe(
    switchMap(isLoadRights => (isLoadRights ? this.getCurrentUserRights() : NEVER)),
    shareReplay(1),
  );

  constructor(
    private readonly connection: ConnectionService,
    @Optional() private readonly accessService?: AccessService,
  ) {
    this.loadUserRights();
  }

  private getCurrentUserRights(): Observable<UserRights> {
    return this.connection.get('/api/v1/me/rights').pipe(
      mapException,
      map((e: IExceptions<IUserRightsDto>) => (isResponse(e) ? e.getData() : {})),
      catchError(() => of({})),
      map((dto: IUserRightsDto) => plainToClass(UserRights, dto, {excludeExtraneousValues: true})),
    );
  }

  public checkAccess(requiredRights: AccessAlias[], aliasBlocked: EAliasBlocked): Observable<IAccessResponse> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const hasRights = this.accessService?.checkAccess(requiredRights);

    if (!hasRights) {
      return of({hasRights});
    }

    return this.userRights$.pipe(
      map(userRights => {
        const isBlocked = userRights ? userRights.getBlocked(aliasBlocked) : true;

        return {
          hasRights,
          isVerified: !isBlocked,
        };
      }),
    );
  }

  public loadUserRights(): void {
    this.loadRights$.next(true);
  }

  public clearUserRights(): void {
    this.loadRights$.next(false);
  }
}

export interface IAccessResponse {
  hasRights: boolean;
  isVerified?: boolean;
}
