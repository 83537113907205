import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();

  public readonly searchControl = new UntypedFormControl();

  @ViewChild('inputId')
  public input!: ElementRef;

  @Output()
  public readonly searchStringChanged = new EventEmitter<string>();

  public ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.destroyer$))
      .subscribe(value => this.searchStringChanged.next(value));
  }

  public focus(): void {
    this.input.nativeElement.focus();
  }

  public resetControl(): void {
    this.searchControl.setValue('');
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
