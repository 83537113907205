import {CastTypes, Model} from '@app/core/model';

export class Workflow extends Model {
  public uuid: string;
  public type: string;
  public workflow: string;
  public status: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public done: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public canceled: boolean;
  public rules: TFormRules;
  public data;

  public readonly casts = {
    uuid: [CastTypes.String],
    type: [CastTypes.String],
    workflow: [CastTypes.String],
    status: [CastTypes.Number],
    done: [CastTypes.Boolean],
    canceled: [CastTypes.Boolean],
    rules: [CastTypes.Object],
    data: [CastTypes.Any],
  };
}

export type TInputRule = [string, string[]];

export type TFormRules = Record<string, TInputRule[]>;
