import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {TradeHistory} from '@app/trading-board/models/b2margin/trade-history';

@Injectable()
export class B2marginTradesHistoryService extends AEventHandler<TradeHistory[]> {
  public type = EEventHandlersReceived.TradesHistory;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: TradeHistory[]): void {
    this.b2marginDatafeedService.tradesHistory$.next(
      resource.concat(this.b2marginDatafeedService.tradesHistory$.value),
    );
  }
}
