import {EThemeMode} from '@app/shared/modules/theme/entities/theme-mode';
import {IChartConfigParams} from '@app/trading-board/widgets/trading-view/chart-config-params';
import {SeriesStyle} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {
  ChartingLibraryFeatureset,
  ChartingLibraryWidgetOptions,
  IBasicDataFeed,
  IDatafeedQuotesApi,
  LanguageCode,
  Overrides,
  ResolutionString,
  ThemeName,
  TimeFrameItem,
} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {Timezone} from '@b2broker/trading.view.charting.library/charting_library/datafeed-api';
import {Environment, IWidgetTheme} from '@env/environment.entities';

export class ChartConfig implements ChartingLibraryWidgetOptions {
  private get colorsSet(): IWidgetTheme {
    const theme = this.theme === 'Dark' ? EThemeMode.Dark : EThemeMode.Light;
    return this.environment.widget.colors[theme] || this.environment.widget.colors[EThemeMode.Dark];
  }

  private static readonly CHART_THEME_CONFIG_DICTIONARY: Record<EThemeMode, Partial<ChartingLibraryWidgetOptions>> = {
    [EThemeMode.Light]: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      drawings_access: {
        type: 'black',
        tools: [{name: 'Regression Trend'}],
      },
      theme: 'Light',
    },
    [EThemeMode.Dark]: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      drawings_access: {
        type: 'black',
        tools: [{name: 'Regression Trend'}],
      },
      theme: 'Dark',
    },
  };

  public static create(params: IChartConfigParams, environment: Environment, isMoexComponent: boolean): ChartConfig {
    return new ChartConfig(params, isMoexComponent, environment);
  }

  public container: HTMLElement | string;
  public datafeed: IBasicDataFeed | (IBasicDataFeed & IDatafeedQuotesApi);
  public interval: ResolutionString;
  public locale: LanguageCode;
  public symbol: string;
  public theme: ThemeName;

  /* eslint-disable @typescript-eslint/naming-convention */
  public library_path = '/assets/trading-view/charting_library/';
  public autosize = true;
  /* eslint-enable @typescript-eslint/naming-convention */

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public enabled_features: ChartingLibraryFeatureset[] = [
    'dont_show_boolean_study_arguments',
    'hide_last_na_study_output',
    'move_logo_to_main_pane',
    'side_toolbar_in_fullscreen_mode',
  ];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public disabled_features: ChartingLibraryFeatureset[] = [
    'header_symbol_search',
    'symbol_info',
    'header_compare',
    'use_localstorage_for_settings',
    'save_chart_properties_to_local_storage',
    'display_market_status',
    'symbol_search_hot_key',
    'border_around_the_chart',
    'remove_library_container_border',
    'show_interval_dialog_on_key_press',
    'control_bar',
    'volume_force_overlay',
    'timezone_menu',
  ];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public time_frames: TimeFrameItem[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public studies_overrides = {
    /* eslint-disable @typescript-eslint/naming-convention */
    'volume.volume.color.0': this.colorsSet.downColor,
    'volume.volume.color.1': this.colorsSet.upColor,
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public custom_css_url = '/assets/styles/trading-view/trading-view.css';

  public overrides: Overrides;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public disableLogo = true;
  public height = 600;
  public timezone: 'exchange' | Timezone = 'Etc/UTC';
  public preset: 'mobile' | undefined;
  public seriesStyle = SeriesStyle.HollowCandles;

  constructor(
    {datafeed, container, interval, locale, symbol, theme, preset, timezone, seriesStyle}: IChartConfigParams,
    isMoexComponent: boolean,
    private readonly environment: Environment,
  ) {
    this.datafeed = datafeed;
    this.container = container;
    this.interval = interval;
    this.locale = locale;
    this.symbol = symbol;
    this.preset = preset;
    this.timezone = timezone ?? this.timezone;
    this.seriesStyle = seriesStyle ?? this.seriesStyle;

    if (isMoexComponent) {
      this.time_frames = [
        {text: '1D', resolution: '1' as ResolutionString},
        {text: '5D', resolution: '5' as ResolutionString},
        {text: '1M', resolution: '60' as ResolutionString},
        {text: '3M', resolution: '1D' as ResolutionString},
        {text: '1Y', resolution: '1M' as ResolutionString},
      ];
    } else {
      this.time_frames = [
        {text: '1D', resolution: '15' as ResolutionString},
        {text: '5D', resolution: '30' as ResolutionString},
        {text: '1M', resolution: '60' as ResolutionString},
        {text: '3M', resolution: '720' as ResolutionString},
      ];
    }

    if (this.environment.widget.tvSettings?.hideVolume) {
      this.disabled_features.push('create_volume_indicator_by_default');
    }

    Object.assign(
      this,
      ChartConfig.CHART_THEME_CONFIG_DICTIONARY[theme] ?? ChartConfig.CHART_THEME_CONFIG_DICTIONARY[EThemeMode.Dark],
    );

    this.setOverrides(isMoexComponent);
  }

  public setOverrides(isMoexComponent: boolean): void {
    this.overrides = {
      /* eslint-disable @typescript-eslint/naming-convention */
      'paneProperties.backgroundType': 'solid',
      'paneProperties.background': this.colorsSet.mainBackground,
      'paneProperties.vertGridProperties.color': this.colorsSet.gridColor,
      'paneProperties.horzGridProperties.color': this.colorsSet.gridColor,
      'symbolWatermarkProperties.transparency': 100,
      'symbolWatermarkProperties.color': this.colorsSet.watermarkColor,
      'scalesProperties.textColor': this.colorsSet.scalesProperties_textColor,
      'scalesProperties.lineColor': this.colorsSet.scalesProperties_lineColor,
      'scalesProperties.backgroundColor': this.colorsSet.scalesProperties_background,
      volumePaneSize: 'small',
      'mainSeriesProperties.candleStyle.upColor': this.colorsSet.upColor,
      'mainSeriesProperties.candleStyle.downColor': this.colorsSet.downColor,
      'mainSeriesProperties.lineStyle.linewidth': 4,
      'mainSeriesProperties.style': this.seriesStyle,
      'mainSeriesProperties.haStyle.upColor': this.colorsSet.upColor,
      'mainSeriesProperties.haStyle.downColor': this.colorsSet.downColor,
      'mainSeriesProperties.barStyle.upColor': this.colorsSet.upColor,
      'mainSeriesProperties.barStyle.downColor': this.colorsSet.downColor,
      'mainSeriesProperties.lineStyle.color': this.colorsSet.lineStyle_color,
      'mainSeriesProperties.areaStyle.color1': this.colorsSet.areaStyle_color1,
      'mainSeriesProperties.areaStyle.color2': this.colorsSet.areaStyle_color2,
      'mainSeriesProperties.areaStyle.linecolor': this.colorsSet.areaStyle_lineColor,
      'mainSeriesProperties.hollowCandleStyle.upColor': this.colorsSet.upColor,
      'mainSeriesProperties.hollowCandleStyle.downColor': this.colorsSet.downColor,
      'linetoolposition.bodyFontFamily': '"Rubic", sans-serif',
      'linetoolposition.bodyFontSize': 12,
      'linetoolposition.quantityTextColor': this.colorsSet.bodyTextColor,
      'linetoolposition.closeButtonIconSellColor': this.colorsSet.bodyTextColor,
      'linetoolposition.closeButtonIconBuyColor': this.colorsSet.bodyTextColor,
      'linetoolposition.bodyTextNeutralColor': this.colorsSet.bodyTextColor,
      'mainSeriesProperties.visible': true,
      'mainSeriesProperties.showPriceLine': true,
      'mainSeriesProperties.hollowCandleStyle.drawBorder': true,
      'mainSeriesProperties.candleStyle.drawWick': true,
      'mainSeriesProperties.hollowCandleStyle.drawWick': false,
      'mainSeriesProperties.haStyle.drawWick': false,
      ...(isMoexComponent && {
        'paneProperties.legendProperties.showSeriesOHLC': false,
        'paneProperties.legendProperties.showBarChange': false,
        'paneProperties.legendProperties.showSeriesTitle': false,
        'paneProperties.legendProperties.showStudyTitles': false,
        'paneProperties.legendProperties.showStudyValues': false,
        'paneProperties.crossHairProperties.color': this.colorsSet.crossHairProperties_color,
        'paneProperties.crossHairProperties.transparency': 70,
        'scalesProperties.fontSize': 10,
        'mainSeriesProperties.candleStyle.borderUpColor': this.colorsSet.upColor,
        'mainSeriesProperties.candleStyle.borderDownColor': this.colorsSet.downColor,
        'mainSeriesProperties.candleStyle.wickUpColor': this.colorsSet.upColor,
      }),
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}
