import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {Position} from '@app/trading-board/models/b2margin/position';

@Injectable()
export class B2marginPositionService extends AEventHandler<Position[]> {
  public type = EEventHandlersReceived.Positions;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: Position[]): void {
    this.b2marginDatafeedService.positions$.next(resource);
  }
}
