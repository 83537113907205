import {CastTypes, Model} from '@app/core/model';

export class Block extends Model {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public expired_at: string;
  public ip: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public created_at: string;
  public id: number;

  public readonly casts = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expired_at: [CastTypes.String],
    ip: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_at: [CastTypes.String],
    id: [CastTypes.Number],
  };
}
