import {APP_INITIALIZER, Provider} from '@angular/core';

import {NewInterfaceInteractionService} from '../services/new-interface-interaction.service';

export const SYNC_DESIGN_VERSION_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (newInterfaceInteractionService: NewInterfaceInteractionService) => {
    return () => {
      newInterfaceInteractionService.syncCurrentState();

      return Promise.resolve();
    };
  },
  multi: true,
  deps: [NewInterfaceInteractionService],
};
