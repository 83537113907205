import {B2brokerAccountTradeHistoryItem} from '@b2broker/b2trader-trade-models';
import {plainToInstance} from 'class-transformer';

import {EB2traderEventTypes} from '../../../enum/b2trader/b2trader-event-types';
import {EB2TraderEventHandlersReceived} from '../../../event-handlers/b2trader/b2trader-event-handlers.enum';
import {B2traderStateStore} from '../../../facades/b2trader-state-store';
import {Trade} from '../../../models/trade';
import {b2TraderInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class B2traderTradesInternalEventHandler extends AEventHandler<B2brokerAccountTradeHistoryItem[]> {
  public type = EB2traderEventTypes.Trades;

  constructor(private readonly store: B2traderStateStore) {
    super();
  }

  public handleMessage(resource: B2brokerAccountTradeHistoryItem[]): void {
    this.store.messages$.next({
      type: EB2TraderEventHandlersReceived.Trades,
      payload: plainToInstance(Trade, Trade.fromB2Trader(resource)),
    });
  }
}

b2TraderInternalEventHandlers.push(B2traderTradesInternalEventHandler);
