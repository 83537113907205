import {Injectable} from '@angular/core';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {Trade} from '@app/trading-board/models/trade';

@Injectable()
export class B2traderTradesService extends AEventHandler<Trade[]> {
  public type = EB2TraderEventHandlersReceived.Trades;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: Trade[]): void {
    Array.from(this.b2traderDatafeedService.trades.entries()).forEach(([key, obj]) => {
      const trades = resource
        .filter(({symbolWithSeparator}) => key === symbolWithSeparator)
        .sort((a, b) => b.tradeTime.getTime() - a.tradeTime.getTime());

      if (trades.length) {
        obj.stream.next(trades.concat(obj.stream.getValue()));
      }
    });
  }
}
