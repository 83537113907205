import {Injectable, OnDestroy} from '@angular/core';
import {GridsterProviders} from '@app/core/models/gridster';
import {AGridsterDatafeedService} from '@app/gridster/datafeeds/gridster-datafeed-service.abstract';

@Injectable()
export class PartnerBoardDatafeedService extends AGridsterDatafeedService implements OnDestroy {
  public provider: GridsterProviders = GridsterProviders.partner;

  public afterConnect(): void {
    return;
  }

  public hasConnect(): boolean {
    return true;
  }

  public initialize(): void {
    return;
  }

  public terminate(): void {
    return;
  }

  public ngOnDestroy(): void {
    return;
  }
}
