import {Injectable} from '@angular/core';
import {Level1Collector} from '@app/trading-board/models/level1-collector';

import {B2TraderDatafeedService} from '../../datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '../../event-handlers/b2trader/b2trader-event-handlers.enum';
import {Tick} from '../../models/level1';
import {AEventHandler} from '../event-handler.abstract';

@Injectable()
export class B2traderLevel1Service extends AEventHandler<Tick[]> {
  public readonly type = EB2TraderEventHandlersReceived.Level1;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: Tick[]): void {
    const level1Collector = this.b2traderDatafeedService.level1$.getValue();

    this.b2traderDatafeedService.level1$.next(
      resource.reduce((acc: Level1Collector, item: Tick) => {
        item.setPriceChangesB2Trader(acc.get(item.instrumentId));

        return acc.set(item.instrumentId, item);
      }, level1Collector),
    );

    level1Collector.update();
  }
}
