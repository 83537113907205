import {DATA_FEED_DEBOUNCE_IN_MS} from '@app/core/constants/common';
import {MoexAccount} from '@app/core/models/account/moex-account';
import {TokenResponse} from '@app/core/models/auth/token-response';
import {AccountService} from '@app/core/services/account.service';
import {betterThrottle} from '@app/core/utils/better-throttle';
import {IAccountTradingData} from '@app/pbsr/interfaces/account-trading-data.interface';
import {ESharedEventHandlers} from '@app/trading-board/event-handlers/shared/shared-event-handlers.enum';
import {IMoexOrderData} from '@app/trading-board/interfaces/moex/moex-order-data';
import {IPortfolioSummary} from '@app/trading-board/interfaces/moex/portfolio-summary';
import {IRawRudataInstrument} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-rudata-instrument';
import {ISingleRequestMessage} from '@app/trading-board/interfaces/single-request-message';
import {PortfolioSummary} from '@app/trading-board/models/moex/portfolio-summary';
import {RudataInstrument} from '@app/trading-board/models/moex/rudata-instrument';
import {plainToInstance} from 'class-transformer';
import {nanoid} from 'nanoid';
import {BehaviorSubject, EMPTY, NEVER, Observable, ReplaySubject, Subject, firstValueFrom} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {catchError, map, take, takeUntil} from 'rxjs/operators';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

import {EMoexOpcode} from '../enum/moex/opcode';
import {EWorkerEventTypes} from '../enum/worker-event-types';
import {AEventHandler, moexInternalEventHandlers} from '../event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '../event-handlers/event-handlers.enum';
import {EMoexEventHandlersReceived, EMoexEventHandlersRequest} from '../event-handlers/moex/moex-event-handlers.enum';
import {IChartHistoryRequest} from '../interfaces/b2trader/chart-history-request';
import {IApiOptions} from '../interfaces/connection-params';
import {IDataEvent} from '../interfaces/data-event.ts';
import {IBarsHistoryRequest} from '../interfaces/moex/bars-history-request.interface';
import {IChartHistoryWithTimeLinksResponse} from '../interfaces/moex/chart-history-with-time-links-response.interface';
import {IWarpInstrument} from '../interfaces/moex/instrument';
import {IMoexMessage} from '../interfaces/moex/moex-message';
import {IPayloadIdToAccounts} from '../interfaces/moex/payload-id-to-accounts';
import {IMoexQuote} from '../interfaces/moex/quote';
import {IRawBondCoupon} from '../interfaces/moex/raw-interfaces/raw-bond-coupon';
import {IRawPortfolioPosition} from '../interfaces/moex/raw-interfaces/raw-portfolio-position';
import {Bar} from '../models/bar';
import {Tick} from '../models/level1';
import {BondCoupon} from '../models/moex/bond-coupon';
import {MoexChartRequest} from '../models/moex/moex-chart-request';
import {WarpOrder} from '../models/moex/order';
import {SymbolQuote} from '../models/moex/symbol-quote';
import {WarpTrade} from '../models/moex/warp-trade';
import {TradingBoardLoadingService} from '../services/trading-board-loading.service';
import {WarpAuthService} from '../services/warp-auth.service';
import {ADatafeedFacade} from './datafeed-facade.abstract';
import {MoexStateStore} from './moex-state-store';

import '../event-handlers/moex/internal';

export class MoexDatafeedFacade extends ADatafeedFacade {
  private readonly store = new MoexStateStore();
  private readonly eventHandlers: AEventHandler<unknown>[];

  private websocket$: WebSocketSubject<unknown>;
  private baseAlorUrl = '';

  private readonly connected$ = new ReplaySubject<void>(1);
  public readonly instruments$ = new BehaviorSubject<IWarpInstrument[]>([]);

  public get messages$(): Subject<IDataEvent> {
    return this.store.messages$;
  }

  constructor(
    private readonly tradingBoardLoadingService: TradingBoardLoadingService,
    private readonly warpAuthService: WarpAuthService,
  ) {
    super();

    this.eventHandlers = moexInternalEventHandlers.map(item => new item(this.store));
  }

  private dispatch(type: EMoexOpcode, message: unknown): void {
    this.eventHandlers.forEach(handler => {
      const isType = Array.isArray(handler.type) ? handler.type.includes(type) : handler.type === type;

      if (isType) {
        handler.handleMessage(message);
      }
    });
  }

  private messageHandler(message: IMoexMessage): void {
    if (!message.guid) {
      return;
    }
    const webSocketMessageType = this.store.webSocketMessagesGuidMap.get(message.guid);
    if (!webSocketMessageType) {
      console.warn('There is no such type');
      return;
    }

    this.dispatch(webSocketMessageType, message);
  }

  private unsubscribeInstrumentTradingStatus(payload: {symbol: string}): void {
    const {symbol} = payload;
    const guid = this.getByValue(this.store.intrumentTradingStatuses, symbol);

    if (!guid) {
      return;
    }

    this.websocket$.next({
      opcode: EMoexOpcode.Unsubscribe,
      token: this.warpAuthService.token,
      guid,
    });

    this.store.intrumentTradingStatuses.delete(guid);
  }

  private subscribeInstrument(payload: {symbol: string}): void {
    if (!this.websocket$) {
      return;
    }

    const {symbol} = payload;

    const guid = nanoid();

    this.store.intrumentTradingStatuses.set(guid, symbol);
    this.store.webSocketMessagesGuidMap.set(guid, EMoexOpcode.InstrumentsGetAndSubscribeV2);

    this.websocket$.next({
      opcode: EMoexOpcode.InstrumentsGetAndSubscribeV2,
      code: symbol,
      exchange: 'MOEX',
      format: 'Simple',
      guid,
      token: this.warpAuthService.token,
    });
  }

  private subscribeQuotes(symbol: string): void {
    if (!this.websocket$) {
      return;
    }

    const guid = nanoid();
    this.store.webSocketMessagesGuidMap.set(guid, EMoexOpcode.QuotesSubscribe);

    const quoteStreams = new Map(this.store.quoteStreams$.value);
    if (quoteStreams.get(symbol)) {
      return;
    }

    const quoteStream = this.websocket$.multiplex(
      () => ({
        opcode: EMoexOpcode.QuotesSubscribe,
        code: symbol,
        exchange: 'MOEX',
        format: 'Simple',
        guid,
        token: this.warpAuthService.token,
      }),
      () => ({
        opcode: EMoexOpcode.Unsubscribe,
        token: this.warpAuthService.token,
        guid,
      }),
      (message: IMoexMessage) => message.guid === guid,
    );

    quoteStreams.set(symbol, quoteStream.pipe(betterThrottle(DATA_FEED_DEBOUNCE_IN_MS)) as Observable<IMoexMessage>);
    this.store.quoteStreams$.next(quoteStreams);
  }

  private unsubscribeQuotes(symbol: string): void {
    const quoteSubscription = this.store.activeQuoteSubscriptions.get(symbol);

    if (quoteSubscription) {
      quoteSubscription.unsubscribe();

      this.store.activeQuoteSubscriptions.delete(symbol);
      this.store.quoteStreams$.value.delete(symbol);
    }
  }

  private async subscribePortfolioPositionsStream(payload: {portfolioId: string; id: string}): Promise<void> {
    if (!this.websocket$) {
      return;
    }

    this.store.webSocketMessagesGuidMap.set(payload.id, EMoexOpcode.PositionsGetAndSubscribeV2);

    const portfolioStream = this.websocket$.multiplex(
      () => ({
        opcode: EMoexOpcode.PositionsGetAndSubscribeV2,
        portfolio: payload.portfolioId,
        exchange: 'MOEX',
        format: 'Simple',
        guid: payload.id,
        token: this.warpAuthService.token,
      }),
      () => ({
        opcode: EMoexOpcode.Unsubscribe,
        token: this.warpAuthService.token,
        guid: payload.id,
      }),
      (message: IMoexMessage) => message.guid === payload.id || message.requestGuid === payload.id,
    );

    await new Promise(resolve => resolve(void 0));

    this.messages$.next({
      type: EMoexEventHandlersRequest.SubscribePortfolioPositions,
      payload: {
        id: payload.id,
        data: portfolioStream,
      },
    });
  }

  private getPortfolioPositions(payload: {portfolioId: string; id: string}): void {
    const url = `${this.baseAlorUrl}/md/v2/Clients/MOEX/${payload.portfolioId}/positions`;
    this.warpAuthService
      .fromMoexFetch<IRawPortfolioPosition[]>(url, {method: 'GET'})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        this.messages$.next({
          type: EMoexEventHandlersRequest.GetPortfolioPositions,
          payload: {
            id: payload.id,
            data: response,
          },
        });
      });
  }

  private getSymbolQuotes(payload: {symbols: string; id: string}): void {
    const url = `${this.baseAlorUrl}/md/v2/Securities/${payload.symbols}/quotes`;
    this.warpAuthService
      .fromMoexFetch<SymbolQuote[]>(url, {method: 'GET'})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        const data = response.map(symbolQuote => plainToInstance(SymbolQuote, symbolQuote));

        this.messages$.next({
          type: EMoexEventHandlersRequest.GetSymbolQuotes,
          payload: {
            id: payload.id,
            data,
          },
        });
      });
  }

  private getSymbolTicks(payload: {symbols: string; id: string}): void {
    const url = `${this.baseAlorUrl}/md/v2/Securities/${payload.symbols}/quotes`;

    this.warpAuthService
      .fromMoexFetch<IMoexQuote[]>(url, {method: 'GET'})
      .pipe(
        map(ticks => ticks.map(tick => Tick.fromMoex(tick))),
        takeUntil(this.destroyer$),
      )
      .subscribe(response => {
        const data = response.map(qoute => plainToInstance(Tick, qoute));

        this.messages$.next({
          type: EMoexEventHandlersRequest.GetSymbolTicks,
          payload: {
            id: payload.id,
            data,
          },
        });
      });
  }

  private async subscribePortfolioSummary(payload: {portfolioId: string; id: string}): Promise<void> {
    if (!this.websocket$) {
      return;
    }

    this.store.webSocketMessagesGuidMap.set(payload.id, EMoexOpcode.PositionsGetAndSubscribeV2);

    const summaryStream = await new Promise(resolve => {
      const stream = this.websocket$.multiplex(
        () => ({
          opcode: EMoexOpcode.SummariesGetAndSubscribeV2,
          portfolio: payload.portfolioId,
          exchange: 'MOEX',
          format: 'Simple',
          guid: payload.id,
          token: this.warpAuthService.token,
        }),
        () => ({
          opcode: EMoexOpcode.Unsubscribe,
          token: this.warpAuthService.token,
          guid: payload.id,
        }),
        (message: IMoexMessage) => message.guid === payload.id,
      ) as Observable<IMoexMessage<IPortfolioSummary>>;

      resolve(stream.pipe(map(({data}) => plainToInstance(PortfolioSummary, data))));
    });

    this.messages$.next({
      type: EMoexEventHandlersRequest.SubscribePortfolioSummary,
      payload: {
        id: payload.id,
        data: summaryStream,
      },
    });
  }

  private async getAccounts(accountService: AccountService): Promise<void> {
    const accounts = await firstValueFrom(accountService.getAccountsAsync().pipe(take(1)));

    this.store.accounts$.next(accounts as MoexAccount[]);
  }

  private requestRudataBondCoupons(payload: {id: string; data: string}): void {
    fromFetch(`${this.apiUrl}/rudata/v2/Bond/Coupons`, {
      selector: res => res.json(),
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        filter: `id_fintool = ${payload.data}`,
      }),
    })
      .pipe(
        catchError(() => {
          this.messages$.next({
            type: EMoexEventHandlersRequest.GetRudataBondCoupons,
            payload: {
              id: payload.id,
              data: undefined,
            },
          });

          return EMPTY;
        }),
        takeUntil(this.destroyer$),
      )
      .subscribe((response: IRawBondCoupon[]) => {
        this.messages$.next({
          type: EMoexEventHandlersRequest.GetRudataBondCoupons,
          payload: {
            id: payload.id,
            data: response.map(bondCoupon =>
              plainToInstance(BondCoupon, {
                beginPeriod: bondCoupon.begin_period,
                endPeriod: bondCoupon.end_period,
                couponRate: bondCoupon.coupon_rate,
              }),
            ),
          },
        });
      });
  }

  private requestRudataFinToolRefData(payload: {id: string; data: Map<string, string>}): void {
    fromFetch(`${this.apiUrl}/rudata/v2/Info/IFXFintoolRefData`, {
      selector: res => res.json(),
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fields: ['fintooltype', 'isincode', 'issuersector', 'couponrate', 'fintoolid'],
        filter: `(isincode IN (${this.getRudataFilter(Array.from(payload.data.keys()))}))`,
      }),
    })
      .pipe(
        catchError(() => {
          this.messages$.next({
            type: EMoexEventHandlersRequest.GetRudataFintoolRefData,
            payload: {id: payload.id, data: undefined},
          });

          return NEVER;
        }),
        takeUntil(this.destroyer$),
      )
      .subscribe((response: IRawRudataInstrument[] | undefined) => {
        const rudataInstrumentsInfo = response.map(item => plainToInstance(RudataInstrument, item));
        const symbolToRudataInstrument = new Map<string, RudataInstrument>();

        rudataInstrumentsInfo.forEach(rudataInstrument =>
          symbolToRudataInstrument.set(payload.data.get(rudataInstrument.isincode), rudataInstrument),
        );

        this.messages$.next({
          type: EMoexEventHandlersRequest.GetRudataFintoolRefData,
          payload: {id: payload.id, data: symbolToRudataInstrument},
        });
      });
  }

  private makeChartHistoryRequest(payload: IBarsHistoryRequest): Observable<IChartHistoryWithTimeLinksResponse> {
    const timeframe = MoexChartRequest.mapResolution(payload.resolution);
    const url = `${this.baseAlorUrl}/md/v2/history/?symbol=${payload.symbol}&exchange=MOEX&tf=${timeframe}&from=${payload.rangeStartDate}&to=${payload.rangeEndDate}`;

    return this.warpAuthService.fromMoexFetch<IChartHistoryWithTimeLinksResponse>(url, {method: 'GET'});
  }

  private requestChartHistory(payload: {id: string; historyRequest: IBarsHistoryRequest}): void {
    this.makeChartHistoryRequest(payload.historyRequest).subscribe(response => {
      this.messages$.next({
        type: EMoexEventHandlersReceived.ChartHistory,
        payload: {
          id: payload.id,
          data: response,
        },
      });
    });
  }

  private requestInstrumentQuoteHistory(payload: IChartHistoryRequest): void {
    const {symbol, resolution, rangeStartDate, rangeEndDate} = payload;

    this.makeChartHistoryRequest({
      symbol,
      resolution,
      rangeStartDate,
      rangeEndDate,
      isFirstCall: false,
    }).subscribe(response => {
      if (!response.history.length && response.prev === null && response.next === null) {
        this.messages$.next({
          type: EMoexEventHandlersRequest.InstrumentQuoteHistory,
          payload: {
            id: payload.id,
            data: [],
          },
        });
        return;
      }

      if (!response.history.length && response.prev) {
        this.requestInstrumentQuoteHistory({...payload, rangeStartDate: response.prev});
        return;
      }

      if (!response.history.length && !response.prev && response.next) {
        this.requestInstrumentQuoteHistory({...payload, rangeEndDate: response.next});
        return;
      }

      const data = plainToInstance(
        Bar,
        response.history.map(item => ({...item, time: item.time * 1000})),
      );

      this.messages$.next({
        type: EMoexEventHandlersRequest.InstrumentQuoteHistory,
        payload: {
          id: payload.id,
          data,
        },
      });
    });
  }

  private unsubscribeChart(symbol: string): void {
    const guid = this.getByValue(this.store.chartSymbolGuidMap, symbol);
    if (!guid) {
      console.warn(`Can not unsubscribe of chart symbol ${symbol}`);
      return;
    }

    this.websocket$.next({
      opcode: EMoexOpcode.Unsubscribe,
      token: this.warpAuthService.token,
      guid,
    });
    this.store.chartSymbolGuidMap.delete(guid);
  }

  private afterConnect(): void {
    this.tradingBoardLoadingService.finish();
    this.messages$.next({type: EMoexEventHandlersReceived.Connected});
  }

  private subscribeChart({symbol, resolution, from}: {symbol: string; resolution: string; from: number}): void {
    const guid = nanoid();

    const timeframe = MoexChartRequest.mapResolution(resolution);
    this.store.chartSymbolGuidMap.set(guid, symbol);
    this.store.webSocketMessagesGuidMap.set(guid, EMoexOpcode.BarsGetAndSubscribe);

    this.websocket$.next({
      opcode: EMoexOpcode.BarsGetAndSubscribe,
      tf: timeframe,
      from: Math.floor(from / 1000),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      delayed: false,
      code: symbol,
      exchange: 'MOEX',
      format: 'Simple',
      guid,
      token: this.warpAuthService.token,
    });
  }

  private subscribeOrderBook(symbol: string): void {
    if (!this.websocket$) {
      return;
    }
    const guid = nanoid();
    this.store.webSocketMessagesGuidMap.set(guid, EMoexOpcode.OrderBookGetAndSubscribe);
    this.store.orderBookGuidMap.set(guid, symbol);

    this.websocket$.next({
      opcode: EMoexOpcode.OrderBookGetAndSubscribe,
      code: symbol,
      exchange: 'MOEX',
      depth: 20,
      format: 'Slim',
      guid,
      token: this.warpAuthService.token,
    });
  }

  private unsubscribeOrderBook(symbol: string): void {
    const guid = this.getByValue(this.store.orderBookGuidMap, symbol);
    if (!guid) {
      console.warn(`Can not unsubscribe of order book symbol ${symbol}`);
      return;
    }

    this.websocket$.next({
      opcode: EMoexOpcode.Unsubscribe,
      token: this.warpAuthService.token,
      guid,
    });
    this.store.orderBookGuidMap.delete(guid);
  }

  private getByValue(someMap: Map<string, string>, searchValue: string): string | undefined {
    for (const [key, value] of someMap.entries()) {
      if (value === searchValue) {
        return key;
      }
    }
  }

  private modifyOrder({data: orderData, id}: ISingleRequestMessage<IMoexOrderData>): void {
    const orderTypeInUrl = orderData.type.toLocaleLowerCase();

    this.warpAuthService
      .fromMoexFetch<{message: string}>(
        `${this.baseAlorUrl}/commandapi/warptrans/TRADE/v2/client/orders/actions/${orderTypeInUrl}/${orderData.orderId}`,
        {
          method: 'PUT',
          body: JSON.stringify(orderData),
        },
        {
          /* eslint-disable @typescript-eslint/naming-convention */
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ALOR-REQID': `${orderData.user.portfolio}:${id}`,
          'X-ALOR-Originator': 'AdvancedUI',
        },
      )
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        this.messages$.next({
          type: EMoexEventHandlersRequest.ModifyOrder,
          payload: {id, data: {isSuccess: response.message === 'success', message: response.message}},
        });
      });
  }

  private createOrder({data: orderData, id}: ISingleRequestMessage<IMoexOrderData>): void {
    const orderTypeInUrl = orderData.type.toLocaleLowerCase();

    this.warpAuthService
      .fromMoexFetch<{message: string}>(
        `${this.baseAlorUrl}/commandapi/warptrans/TRADE/v2/client/orders/actions/${orderTypeInUrl}`,
        {
          method: 'POST',
          body: JSON.stringify(orderData),
        },
        {
          /* eslint-disable @typescript-eslint/naming-convention */
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ALOR-REQID': `${orderData.user.portfolio}:${id}`,
          'X-ALOR-Originator': 'AdvancedUI',
        },
      )
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        this.messages$.next({
          type: EMoexEventHandlersRequest.CreateOrder,
          payload: {id, data: {isSuccess: response.message === 'success', message: response.message}},
        });
      });
  }

  private requestPortfolioOrders(payload: {id: string; accountTradingData: IAccountTradingData}): void {
    const url = `${this.baseAlorUrl}/md/v2/clients/MOEX/${payload.accountTradingData.tradeCode}/orders`;
    this.warpAuthService
      .fromMoexFetch<WarpOrder[]>(url, {method: 'GET'})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        const data = response.map(rawOrder =>
          plainToInstance(WarpOrder, {
            ...rawOrder,
            parentAccountId: payload.accountTradingData.parentAccountId,
            parentAccountCaption: payload.accountTradingData.parentAccountCaption,
            subAccountId: payload.accountTradingData.subAccountId,
            subAccountCaption: payload.accountTradingData.subAccountCaption,
            tradeCode: payload.accountTradingData.tradeCode,
          }),
        );

        return this.messages$.next({
          type: EMoexEventHandlersRequest.GetPortfolioOrders,
          payload: {
            id: payload.id,
            data,
          },
        });
      });
  }

  private requestPortfolioStopOrders(payload: {id: string; accountTradingData: IAccountTradingData}): void {
    const url = `${this.baseAlorUrl}/md/v2/clients/MOEX/${payload.accountTradingData.tradeCode}/stoporders`;
    this.warpAuthService
      .fromMoexFetch<WarpOrder[]>(url, {method: 'GET'})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        const data = response.map(rawOrder =>
          plainToInstance(WarpOrder, {
            ...rawOrder,
            parentAccountId: payload.accountTradingData.parentAccountId,
            parentAccountCaption: payload.accountTradingData.parentAccountCaption,
            subAccountId: payload.accountTradingData.subAccountId,
            subAccountCaption: payload.accountTradingData.subAccountCaption,
            tradeCode: payload.accountTradingData.tradeCode,
          }),
        );

        return this.messages$.next({
          type: EMoexEventHandlersRequest.GetPortfolioStopOrders,
          payload: {
            id: payload.id,
            data,
          },
        });
      });
  }

  private requestPortfolioTrades(payload: {id: string; accountTradingData: IAccountTradingData}): void {
    const url = `${this.baseAlorUrl}/md/v2/Clients/MOEX/${payload.accountTradingData.tradeCode}/trades`;
    this.warpAuthService
      .fromMoexFetch<WarpTrade[]>(url, {method: 'GET'})
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response =>
        this.messages$.next({
          type: EMoexEventHandlersRequest.GetPortfolioTrades,
          payload: {
            id: payload.id,
            data: response.map(trade =>
              plainToInstance(WarpTrade, {
                ...trade,
                parentAccountId: payload.accountTradingData.parentAccountId,
                parentAccountCaption: payload.accountTradingData.parentAccountCaption,
                subAccountId: payload.accountTradingData.subAccountId,
                subAccountCaption: payload.accountTradingData.subAccountCaption,
                tradeCode: payload.accountTradingData.tradeCode,
              }),
            ),
          },
        }),
      );
  }

  private cancelStopOrder(payload: {id: string; tradeCode: string; orderId: string}): void {
    const url = `${this.baseAlorUrl}/commandapi/warptrans/TRADE/v2/client/orders/${payload.orderId}/?portfolio=${payload.tradeCode}&exchange=MOEX&stop=true`;

    this.warpAuthService
      .fromMoexFetch<string>(
        url,
        {
          method: 'DELETE',
        },
        {},
        (res: Response) => res.text(),
      )
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        this.messages$.next({
          type: EMoexEventHandlersRequest.CancelStopOrder,
          payload: {
            id: payload.id,
            data: {isSuccess: response === 'success', message: response},
          },
        });
      });
  }

  private cancelOrder(payload: {id: string; tradeCode: string; orderId: string}): void {
    const url = `${this.baseAlorUrl}/commandapi/warptrans/TRADE/v2/client/orders/${payload.orderId}?portfolio=${payload.tradeCode}&exchange=MOEX&stop=false`;

    this.warpAuthService
      .fromMoexFetch<string>(url, {method: 'DELETE'}, {}, (res: Response) => res.text())
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        this.messages$.next({
          type: EMoexEventHandlersRequest.CancelOrder,
          payload: {
            id: payload.id,
            data: {isSuccess: response === 'success', message: response},
          },
        });
      });
  }

  private async subscribePortfolioOrders(payload: IPayloadIdToAccounts): Promise<void> {
    if (!this.websocket$) {
      return;
    }

    this.store.webSocketMessagesGuidMap.set(payload.id, EMoexOpcode.OrdersGetAndSubscribeV2);

    const ordersStream = await new Promise(resolve => {
      const stream = this.websocket$.multiplex(
        () => ({
          opcode: EMoexOpcode.OrdersGetAndSubscribeV2,
          portfolio: payload.accountTradingData.tradeCode,
          exchange: 'MOEX',
          format: 'Simple',
          guid: payload.id,
          token: this.warpAuthService.token,
        }),
        () => ({
          opcode: EMoexOpcode.Unsubscribe,
          token: this.warpAuthService.token,
          guid: payload.id,
        }),
        (message: IMoexMessage) => message.guid === payload.id,
      ) as Observable<IMoexMessage<WarpOrder>>;

      resolve(
        stream.pipe(
          map(({data}) =>
            plainToInstance(WarpOrder, {
              ...data,
              parentAccountId: payload.accountTradingData.parentAccountId,
              parentAccountCaption: payload.accountTradingData.parentAccountCaption,
              subAccountId: payload.accountTradingData.subAccountId,
              subAccountCaption: payload.accountTradingData.subAccountCaption,
              tradeCode: payload.accountTradingData.tradeCode,
            }),
          ),
        ),
      );
    });

    this.messages$.next({
      type: EMoexEventHandlersRequest.SubscribePortfolioOrders,
      payload: {
        id: payload.id,
        data: ordersStream,
      },
    });
  }

  private async subscribePortfolioStopOrders(payload: IPayloadIdToAccounts): Promise<void> {
    if (!this.websocket$) {
      return;
    }

    this.store.webSocketMessagesGuidMap.set(payload.id, EMoexOpcode.StopOrdersGetAndSubscribeV2);

    const ordersStream = await new Promise(resolve => {
      const stream = this.websocket$.multiplex(
        () => ({
          opcode: EMoexOpcode.StopOrdersGetAndSubscribeV2,
          portfolio: payload.accountTradingData.tradeCode,
          exchange: 'MOEX',
          format: 'Simple',
          guid: payload.id,
          token: this.warpAuthService.token,
        }),
        () => ({
          opcode: EMoexOpcode.Unsubscribe,
          token: this.warpAuthService.token,
          guid: payload.id,
        }),
        (message: IMoexMessage) => message.guid === payload.id,
      ) as Observable<IMoexMessage<WarpOrder>>;

      resolve(
        stream.pipe(
          map(({data}) =>
            plainToInstance(WarpOrder, {
              ...data,
              parentAccountId: payload.accountTradingData.parentAccountId,
              parentAccountCaption: payload.accountTradingData.parentAccountCaption,
              subAccountId: payload.accountTradingData.subAccountId,
              subAccountCaption: payload.accountTradingData.subAccountCaption,
              tradeCode: payload.accountTradingData.tradeCode,
            }),
          ),
        ),
      );
    });

    this.messages$.next({
      type: EMoexEventHandlersRequest.SubscribePortfolioStopOrders,
      payload: {
        id: payload.id,
        data: ordersStream,
      },
    });
  }

  private async subscribePortfolioTrades(payload: IPayloadIdToAccounts): Promise<void> {
    if (!this.websocket$) {
      return;
    }

    this.store.webSocketMessagesGuidMap.set(payload.id, EMoexOpcode.TradesGetAndSubscribeV2);

    const ordersStream = await new Promise(resolve => {
      const stream = this.websocket$.multiplex(
        () => ({
          opcode: EMoexOpcode.TradesGetAndSubscribeV2,
          portfolio: payload.accountTradingData.tradeCode,
          exchange: 'MOEX',
          format: 'Simple',
          guid: payload.id,
          token: this.warpAuthService.token,
        }),
        () => ({
          opcode: EMoexOpcode.Unsubscribe,
          token: this.warpAuthService.token,
          guid: payload.id,
        }),
        (message: IMoexMessage) => message.guid === payload.id,
      ) as Observable<IMoexMessage<WarpOrder>>;

      resolve(
        stream.pipe(
          map(({data}) =>
            plainToInstance(WarpTrade, {
              ...data,
              parentAccountId: payload.accountTradingData.parentAccountId,
              parentAccountCaption: payload.accountTradingData.parentAccountCaption,
              subAccountId: payload.accountTradingData.subAccountId,
              subAccountCaption: payload.accountTradingData.subAccountCaption,
              tradeCode: payload.accountTradingData.tradeCode,
            }),
          ),
        ),
      );
    });

    this.messages$.next({
      type: EMoexEventHandlersRequest.SubscribePortfolioTrades,
      payload: {
        id: payload.id,
        data: ordersStream,
      },
    });
  }

  private getRudataFilter(values: string[]): string {
    let result = '';

    values.forEach((value, index) => (result += `'${value}'${index === values.length - 1 ? '' : ','}`));

    return result;
  }

  protected async connect(params?: IApiOptions): Promise<void> {
    this.tradingBoardLoadingService.start();
    this.apiUrl = params.apiUrl;
    this.baseAlorUrl = `${params.apiUrl}/trading`;
    this.warpAuthService.messages$ = this.messages$;
    const host = new URL(this.apiUrl || location.origin).host;
    const wsUrl = `wss://${host}/trading/ws`;

    await this.getAccounts(params.accountService);

    return new Promise((resolve, reject) => {
      this.websocket$ = webSocket({
        url: wsUrl,
        openObserver: {
          next: () => {
            this.connected$.next();
            this.afterConnect();
            resolve();
          },
        },
        closeObserver: {
          next: error => {
            console.warn(`Error: ${error}`);
            reject();
          },
        },
      });

      this.websocket$.subscribe({
        next: (message: IMoexMessage) => this.messageHandler(message),
        error: () => {
          this.tradingBoardLoadingService.finish();
          this.messages$.next({type: EEventHandlersReceived.Error});
        },
      });
    });
  }

  public close(): void {
    this.destroyer$.next();
    this.websocket$?.unsubscribe();
    this.websocket$?.complete();

    this.websocket$ = undefined;
    this.store.reset();
  }

  public postMessage(message: IDataEvent): void {
    switch (message.type) {
      case EWorkerEventTypes.Connect:
        void this.connect(message.payload as IApiOptions);
        break;
      case EMoexEventHandlersRequest.SubscribeChart:
        this.subscribeChart(message.payload as {symbol: string; resolution: string; from: number});
        break;
      case EMoexEventHandlersRequest.UnsubscribeChart:
        this.unsubscribeChart(message.payload as string);
        break;
      case EMoexEventHandlersRequest.ChartHistory:
        this.requestChartHistory(message.payload as {id: string; historyRequest: IBarsHistoryRequest});
        break;
      case EMoexEventHandlersRequest.SubscribeOrderBook:
        this.subscribeOrderBook(message.payload as string);
        break;
      case EMoexEventHandlersRequest.UnsubscribeOrderBook:
        this.unsubscribeOrderBook(message.payload as string);
        break;
      case EMoexEventHandlersRequest.SubscribeQuotes:
        this.subscribeQuotes(message.payload as string);
        break;
      case EMoexEventHandlersRequest.UnsubscribeQuotes:
        this.unsubscribeQuotes(message.payload as string);
        break;
      case EMoexEventHandlersRequest.InstrumentQuoteHistory:
        this.requestInstrumentQuoteHistory(message.payload as IChartHistoryRequest);
        break;
      case EMoexEventHandlersRequest.SubscribePortfolioPositions:
        void this.subscribePortfolioPositionsStream(message.payload as {portfolioId: string; id: string});
        break;
      case EMoexEventHandlersRequest.SubscribePortfolioSummary:
        void this.subscribePortfolioSummary(message.payload as {portfolioId: string; id: string});
        break;
      case EMoexEventHandlersRequest.SubscribeInstrument:
        this.subscribeInstrument(message.payload as {symbol: string});
        break;
      case EMoexEventHandlersRequest.UnsubscribeInstrument:
        this.unsubscribeInstrumentTradingStatus(message.payload as {symbol: string});
        break;
      case EMoexEventHandlersRequest.CreateOrder:
        this.createOrder(message.payload as ISingleRequestMessage<IMoexOrderData>);
        break;
      case EMoexEventHandlersRequest.ModifyOrder:
        this.modifyOrder(message.payload as ISingleRequestMessage<IMoexOrderData>);
        break;
      case EMoexEventHandlersRequest.GetRudataFintoolRefData:
        this.requestRudataFinToolRefData(message.payload as {id: string; data: Map<string, string>});
        break;
      case EMoexEventHandlersRequest.GetRudataBondCoupons:
        this.requestRudataBondCoupons(message.payload as {id: string; data: string});
        break;
      case EMoexEventHandlersRequest.GetPortfolioOrders:
        this.requestPortfolioOrders(message.payload as {id: string; accountTradingData: IAccountTradingData});
        break;
      case EMoexEventHandlersRequest.GetPortfolioStopOrders:
        this.requestPortfolioStopOrders(message.payload as {id: string; accountTradingData: IAccountTradingData});
        break;
      case EMoexEventHandlersRequest.GetPortfolioTrades:
        this.requestPortfolioTrades(message.payload as {id: string; accountTradingData: IAccountTradingData});
        break;
      case EMoexEventHandlersRequest.SubscribePortfolioOrders:
        void this.subscribePortfolioOrders(message.payload as IPayloadIdToAccounts);
        break;
      case EMoexEventHandlersRequest.SubscribePortfolioStopOrders:
        void this.subscribePortfolioStopOrders(message.payload as IPayloadIdToAccounts);
        break;
      case EMoexEventHandlersRequest.SubscribePortfolioTrades:
        void this.subscribePortfolioTrades(message.payload as IPayloadIdToAccounts);
        break;
      case EMoexEventHandlersRequest.CancelStopOrder:
        this.cancelStopOrder(message.payload as {id: string; tradeCode: string; orderId: string});
        break;
      case EMoexEventHandlersRequest.CancelOrder:
        this.cancelOrder(message.payload as {id: string; tradeCode: string; orderId: string});
        break;
      case EMoexEventHandlersRequest.GetPortfolioPositions:
        this.getPortfolioPositions(message.payload as {portfolioId: string; id: string});
        break;
      case EMoexEventHandlersRequest.GetSymbolQuotes:
        this.getSymbolQuotes(message.payload as {symbols: string; id: string});
        break;
      case EMoexEventHandlersRequest.GetSymbolTicks:
        this.getSymbolTicks(message.payload as {symbols: string; id: string});
        break;
      case ESharedEventHandlers.EndRefreshTokens:
        this.messages$.next({
          type: ESharedEventHandlers.EndRefreshTokens,
          payload: message.payload as TokenResponse,
        });
        break;
      default:
        return;
    }
  }
}
