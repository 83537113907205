import {Injectable, inject} from '@angular/core';

import {AmplitudeService} from './amplitude/amplitude.service';
import {RudderStackService} from './rudder-stack/rudder-stack.service';
import {IAnalyticsConfig} from './shared/interfaces/analytics-config.interface';
import {TEvent} from './shared/types/event.type';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsFacadeService {
  private readonly amplitudeService = inject(AmplitudeService);
  private readonly rudderStackService = inject(RudderStackService);

  private readonly analyticServices = [this.amplitudeService, this.rudderStackService];

  public initialize(config: IAnalyticsConfig): void {
    this.analyticServices.forEach(service => {
      if (service instanceof AmplitudeService) {
        service.initialize(config.amplitudeConfig, config.email);
      }

      if (service instanceof RudderStackService && config.rudderStackConfig) {
        service.initialize(config.rudderStackConfig, config.email);
      }
    });
  }

  public logEvent(event: TEvent): void {
    this.analyticServices.forEach(service => {
      service.logEvent(event);
    });
  }
}
