import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Decimal} from '@app/core/decimal';
import {Currency} from '@app/core/models/account/currency';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnChanges {
  public int: string;
  public end: string;

  @Input()
  public balance: Decimal;

  @Input()
  public currency: Currency;

  @Input()
  public isUpperInt = false;

  @Input()
  public isThousandsSeparator = false;

  private calcValues(): void {
    const scale = this.currency ? this.currency.precision : this.balance.scale;
    const [int, end] = this.balance.getFixed(scale).split('.');

    this.int = int ?? '0';
    this.end = end ?? '0';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.balance) {
      return;
    }

    if (changes.balance && changes.balance.currentValue === changes.balance.previousValue) {
      return;
    }

    if (changes.currency && changes.currency.currentValue === changes.currency.previousValue) {
      return;
    }

    this.calcValues();
  }
}
