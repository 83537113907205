import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ThousandsSeparatorPipe} from '@app/shared/pipes/thousands-separator/thousands-separator.pipe';

@NgModule({
  declarations: [ThousandsSeparatorPipe],
  imports: [CommonModule],
  exports: [ThousandsSeparatorPipe],
})
export class ThousandsSeparatorModule {}
