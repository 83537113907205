import {Injectable} from '@angular/core';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {Observable, of} from 'rxjs';

import {AStorage} from './storage';

@Injectable()
export class LocalStorageService extends AStorage {
  private static readonly LOCAL_STORAGE_PREFIX = 'settings';

  private itemName(key: string): string {
    return `${LocalStorageService.LOCAL_STORAGE_PREFIX}__${key}`;
  }

  public get<T>(key: string): Observable<T | undefined> {
    try {
      const item = ALocaleStorage.getItem(this.itemName(key));
      return of(item === null ? undefined : (JSON.parse(item) as T));
    } catch (e) {
      return of(undefined) as Observable<T | undefined>;
    }
  }

  public save<T>(key: string, value: T): Observable<boolean> {
    try {
      const item = JSON.stringify(value);
      ALocaleStorage.setItem(this.itemName(key), item);
      return of(true);
    } catch {
      return of(false);
    }
  }

  public remove(key: string): Observable<boolean> {
    try {
      ALocaleStorage.removeItem(this.itemName(key));
      return of(true);
    } catch {
      return of(false);
    }
  }
}
