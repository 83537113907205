import {Expose} from 'class-transformer';

export class OrganizationData {
  @Expose({name: 'shortname_ru'})
  public shortnameRu: string;

  @Expose({name: 'name_ru'})
  public nameRu: string;

  @Expose()
  public shortname: string;

  @Expose()
  public name: string;

  @Expose()
  public ogrn: string;

  @Expose()
  public kpp: string;

  @Expose()
  public bik: string;

  @Expose()
  public swift: string;

  @Expose()
  public url: string;

  @Expose()
  public phone: string;

  @Expose()
  public email: string;

  @Expose()
  public fax: string;
}
