import {Expose, plainToInstance} from 'class-transformer';
import {IsObject, IsOptional} from 'class-validator';

import {GridsterWidgetSettings} from '../../../core/models/gridster/gridster-widget-settings';
import {IOrdersSetting} from '../../components/orders-filter/orders-filter';

export class OrderSettings {
  public static makeInstance(config: GridsterWidgetSettings = {}): OrderSettings {
    return plainToInstance(OrderSettings, config, {excludeExtraneousValues: true});
  }

  public static getInstance(): OrderSettings {
    return plainToInstance(OrderSettings, {}, {excludeExtraneousValues: true});
  }

  @Expose()
  @IsObject()
  @IsOptional()
  public dateRange?: IOrdersSetting;
}
