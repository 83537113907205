<mat-expansion-panel>
  <mat-expansion-panel-header>
    <app-icon icon="portfolio"></app-icon>
    <div class="wrap-title">
      <mat-panel-title>
        {{ title }}
      </mat-panel-title>
      <mat-panel-description>
        {{ description }}
      </mat-panel-description>
    </div>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
