export enum EPlatformClass {
  B2Margin = 'B2Margin',
  B2Trade = 'B2Trade',
  B2Trader = 'B2Trader',
  Centroid = 'Centroid',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CTrader = 'cTrader',
  DxTrade = 'DXtrade',
  MetaTrader5 = 'MetaTrader5',
  MetaTrader4 = 'MetaTrader4',
  MatchTrader = 'MatchTrader',
  OneZero = 'OneZero',
  Personal = 'personal',
  PrimeXm = 'PrimeXM',
  Wrapper = 'wrapper',
  TradeLocker = 'TradeLocker',
}
