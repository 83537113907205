import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {ImgModule} from '@app/img/img.module';
import {DirectionModule} from '@app/shared/modules/direction/direction.module';
import {TranslateModule} from '@ngx-translate/core';

import {LanguageComponent} from './language.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatSelectModule, TranslateModule.forChild(), DirectionModule, ImgModule],

  declarations: [LanguageComponent],
  exports: [LanguageComponent],
})
export class LanguageModule {}
