/* eslint-disable @typescript-eslint/naming-convention */
import {Casts, CastTypes, Model} from '@app/core/model';
import {ETicketStatusName} from '@app/helpdesk/enum/ticket-status-name.enum';

import {statusNamesMap} from '../const/status-name-map';
import {Message} from './message';
import {Status} from './status';

export class Ticket extends Model {
  public id: number;
  public subject: string;
  public created_at: number;
  public status: Status;
  public last_reply: Message;
  public number: string;

  public get statusNameLabel(): string {
    return statusNamesMap[this.status.name] || '';
  }

  public get isOpened(): boolean {
    const openedStatuses = [
      ETicketStatusName.Open,
      ETicketStatusName.AwaitingReply,
      ETicketStatusName.InProgress,
      ETicketStatusName.Pending,
      ETicketStatusName.NeedToReply,
      ETicketStatusName.NeedToProcess,

      ETicketStatusName.OpenRu,
      ETicketStatusName.AwaitingReplyRu,
      ETicketStatusName.InProgressRu,
      ETicketStatusName.PendingRu,
      ETicketStatusName.NeedToReplyRu,
    ];

    return openedStatuses.includes(this.status.name);
  }

  public readonly casts: Casts = {
    id: [CastTypes.Number],
    subject: [CastTypes.String],
    created_at: [CastTypes.Number],
    status: [Status],
    last_reply: [Message],
    number: [CastTypes.String],
  };
}
