import {Expose} from 'class-transformer';

export class Address {
  @Expose()
  public index: string;

  @Expose({name: 'country_caption'})
  public countryCaption: string;

  @Expose({name: 'region_caption'})
  public regionCaption: string;

  @Expose({name: 'city_caption'})
  public cityCaption: string;

  @Expose()
  public street: string;

  @Expose()
  public house: string;

  @Expose()
  public building: string;

  @Expose()
  public construction: string;

  @Expose()
  public apartment: string;

  @Expose()
  public locality: string;
}
