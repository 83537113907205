import {PAGES_WITH_URLS} from '../constants/pages-with-urls.const';

export function createUrlForNewB2copy(chunksOfPathName: string[], language: string): string {
  const b2copyUrls = chunksOfPathName.slice(2);
  const [platformName, , platformId] = b2copyUrls;
  const b2copyPlatforms = ['copy', 'pamm-new', 'mam'];
  const isB2Copy = platformName && b2copyPlatforms.includes(platformName) && Number(platformId);

  if (!isB2Copy) {
    return '';
  }

  const alias = {platform: ':platform', platformId: ':platformId', id: ':id', tab: ':tab'};
  const feature = b2copyUrls.at(1);
  const accountId = b2copyUrls.at(4);
  const tab = b2copyUrls.at(5);
  const urlsCount = b2copyUrls.length;
  const placeholders = [alias.platform, feature, alias.platformId, 'account', alias.id, alias.tab].slice(0, urlsCount);

  const b2copyPrefix = 'b2copy';
  const routesMapKey = [b2copyPrefix, ...placeholders].join('/');
  const routeFromMap = PAGES_WITH_URLS.get(routesMapKey);

  if (!routeFromMap) {
    return '';
  }

  const path = routeFromMap
    .replace(alias.platform, platformName ?? '')
    .replace(alias.platformId, platformId ?? '')
    .replace(alias.id, accountId ?? '')
    .replace(alias.tab, tab ?? '');

  return `/${language}${path}`;
}
