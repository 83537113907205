import {EEventName} from '../enums/event-name.enum';
import {TEventProperties} from '../types/event-properties.type';
import {TEvent} from '../types/event.type';

export abstract class AEventLogger {
  public handleEvent(event: TEvent): void {
    switch (event.name) {
      case EEventName.Deposit: {
        const properties = event.properties;

        return this.logEvent(EEventName.Deposit, {
          ...(properties.amount !== undefined ? {['Amount']: properties.amount} : {}),
          ['Currency']: properties.currency,
          ['Provider name']: properties.providerName,
          ['Status']: properties.status,
        });
      }

      case EEventName.Withdrawal: {
        const properties = event.properties;

        return this.logEvent(EEventName.Withdrawal, {
          ['Amount']: properties.amount,
          ['Currency']: properties.currency,
          ['Provider name']: properties.providerName,
          ['Status']: properties.status,
        });
      }

      case EEventName.InternalWithdrawal: {
        const properties = event.properties;

        return this.logEvent(EEventName.InternalWithdrawal, {
          ['Amount']: properties.amount,
          ['Currency']: properties.currency,
          ['Status']: properties.status,
        });
      }

      case EEventName.Transfer: {
        const properties = event.properties;

        return this.logEvent(EEventName.Transfer, {
          ['Amount']: properties.amount,
          ['Currency']: properties.currency,
          ['Status']: properties.status,
        });
      }

      case EEventName.Exchange: {
        const properties = event.properties;

        return this.logEvent(EEventName.Exchange, {
          ['From currency']: properties.fromCurrency,
          ['To currency']: properties.toCurrency,
          ['Exchange rate']: properties.exchangeRate,
          ['Amount from']: properties.amountFrom,
          ['Amount to']: properties.amountTo,
          ['Status']: properties.status,
        });
      }

      case EEventName.HelpdeskTicketAction: {
        return this.logEvent(EEventName.HelpdeskTicketAction, {
          action: event.properties.action,
        });
      }

      case EEventName.SuccessfulVerificationRequest: {
        const nextVerificationIndex = event.properties.currentVerificationIndex + 1;

        return this.logEvent(EEventName.SuccessfulVerificationRequest, {
          ['desired_verification_level']: nextVerificationIndex,
        });
      }

      case EEventName.AddWidgetToDashboard: {
        return this.logEvent(EEventName.AddWidgetToDashboard, {name: event.properties.widget.name});
      }

      case EEventName.AccountCreated: {
        const product = event.properties.product;

        return this.logEvent(EEventName.AccountCreated, {
          ['platform_class']: product.platform.class,
          ['platform_currency']: product.currency.alpha,
        });
      }

      default:
        this.logEvent(event.name);
    }
  }

  protected abstract logEvent(name: EEventName, properties?: TEventProperties): void;
}
