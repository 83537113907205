import {isDevMode} from '@angular/core';
import {MetaDataModel, IStateClass} from '@app/state/plugins/logout/internals';
import {createMetaDataListReducer} from '@app/state/plugins/logout/reset.helper';

export class Reset {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly type = '[Reset plugin] reset';

  public readonly statesToReset: MetaDataModel[];

  constructor(...statesToReset: IStateClass[]) {
    const reducer: (acc: MetaDataModel[], state: IStateClass) => MetaDataModel[] = createMetaDataListReducer(
      isDevMode(),
    );
    this.statesToReset = statesToReset.reduce<MetaDataModel[]>(reducer, []);
  }
}

export class ResetAll {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly type = '[Reset plugin] reset all';

  public readonly statesToKeep: MetaDataModel[];

  constructor(...statesToKeep: IStateClass[]) {
    const reducer: (acc: MetaDataModel[], state: IStateClass) => MetaDataModel[] = createMetaDataListReducer(
      isDevMode(),
    );
    this.statesToKeep = statesToKeep.reduce<MetaDataModel[]>(reducer, []);
  }
}
