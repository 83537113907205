import {EStopOrderStatus} from '@app/trading-board/enum/b2trader/stop-order-status';
import {EStopOrderUpdateReason} from '@app/trading-board/enum/b2trader/stop-order-update-reason';
import {IStopOrderResponse} from '@app/trading-board/interfaces/b2trader/stop-order-response';

import {EB2traderEventTypes} from '../../../enum/b2trader/b2trader-event-types';
import {B2traderStateStore} from '../../../facades/b2trader-state-store';
import {AEventHandler, b2TraderInternalEventHandlers} from '../../event-handler.abstract';

export class B2traderStopOrdersInternalEventHandler extends AEventHandler<IStopOrderResponse[]> {
  public readonly type = EB2traderEventTypes.StopOrders;

  constructor(private readonly store: B2traderStateStore) {
    super();
  }

  public handleMessage(resource: IStopOrderResponse[] | null): void {
    if (resource === null) {
      this.store.stopOrders$.next(null);

      return;
    }

    this.store.stopOrders$.next(
      resource.reduce((acc, item) => {
        /*
         * The backend doesn't have a Cancel status, so we modify this manually to exclude a canceled order.
         */
        if (item.cause === EStopOrderUpdateReason.Cancel) {
          item.order.status = EStopOrderStatus.Rejected;
        }

        acc.set(item.order.orderId, item.order);

        return acc;
      }, this.store.stopOrders$.getValue()),
    );
  }
}

b2TraderInternalEventHandlers.push(B2traderStopOrdersInternalEventHandler);
