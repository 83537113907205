import {CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  TrackByFunction,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {IconModule} from '@app/icon/icon.module';
import {SharedModule} from '@app/shared/shared.module';
import {ETradingBoardProviderAlias} from '@app/trading-board/enum/provider-alias';
import {Instrument} from '@app/trading-board/models/instrument';
import {TranslateModule} from '@ngx-translate/core';
import * as _ from 'lodash-es';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ATradeDatafeed} from '../../../datafeed/trade-datafeed.abstract';
import {Tick} from '../../../models/level1';
import {AComponentResolver} from '../../../services/component-resolver.abstract';
import {ISymbolLibrary} from '../../symbol-library/symbol-library';
import {SearchValueService} from '../search-value.service';

@Component({
  selector: 'app-list-component',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit, OnDestroy {
  private readonly elementSize = 48;
  private readonly destroyer$ = new Subject<void>();

  public get instrumentFilter(): (i: Instrument) => boolean {
    const searchValue = (this.searchValue || '').toUpperCase();
    const blackList = new Set(this.blackList);
    return (i: Instrument) => {
      const isBlackListed = blackList.has(i.symbol) || blackList.has(i.symbolWithSeparator);
      const isMatched = i.matchSearchValue(searchValue, this.isSearchSkipSeparator);

      return !isBlackListed && isMatched;
    };
  }

  public hasEmbedded = false;

  public readonly isSearchSkipSeparator = this.componentResolver?.type === ETradingBoardProviderAlias.me;

  public searchValue = this.searchValueService.getValue();

  public ticks = new Map<string, Tick>();

  @Input()
  public placeholder: string | undefined;

  @Input()
  public isVirtualScrollUsed = false;

  @Input()
  public isTickInfoShow = true;

  @Output()
  public selectedInstrument$ = new EventEmitter<Instrument>();

  @Input()
  public blackList: string[];

  @Input()
  public instruments: Instrument[];

  @ViewChild('groupsContainer', {read: ViewContainerRef, static: true})
  public container: ViewContainerRef;

  @ViewChild('searchInput')
  private readonly searchInput: ElementRef<HTMLInputElement>;

  @ViewChild('searchInputWrapper', {static: true, read: ElementRef})
  private readonly searchInputWrapper: ElementRef<HTMLElement>;

  @ViewChild(CdkVirtualScrollViewport)
  public viewPort: CdkVirtualScrollViewport;

  @HostBinding('style.height.px') public get height(): number {
    const searchInputHeightInPx = this.searchInputWrapper.nativeElement.clientHeight;
    const instrumentsCount = this.instruments?.filter(this.instrumentFilter).length;

    if (this.isVirtualScrollUsed) {
      this.viewPort?.checkViewportSize();
    }

    if (!instrumentsCount) {
      return searchInputHeightInPx;
    }

    return instrumentsCount * this.elementSize + searchInputHeightInPx;
  }

  constructor(
    private readonly searchValueService: SearchValueService,
    private readonly datafeed: ATradeDatafeed,
    private readonly cdr: ChangeDetectorRef,
    private readonly injector: Injector,
    @Optional() private readonly componentResolver?: AComponentResolver,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.datafeed.level1$.pipe(takeUntil(this.destroyer$)).subscribe(ticks => {
      this.ticks = ticks;
      this.cdr.detectChanges();
    });

    this.searchValueService.searchValue$.pipe(takeUntil(this.destroyer$)).subscribe(value => {
      this.searchValue = value;
      this.cdr.detectChanges();
    });

    // Used setTimeout because focus() should be called in the next task after the input rendering
    setTimeout(() => this.searchInput.nativeElement.focus());

    const factoryFn = this.componentResolver?.getComponent<ISymbolLibrary>(ListComponent);

    if (factoryFn) {
      this.hasEmbedded = true;
      this.cdr.detectChanges();

      const {entryPoint} = await factoryFn();

      const component = this.container.createComponent(entryPoint, {index: undefined, injector: this.injector});
      component.changeDetectorRef.detectChanges();

      component.instance.instrumentChanges$.pipe(takeUntil(this.destroyer$)).subscribe(selectedId => {
        this.selectInstrument(this.instruments?.find(({id}) => id === selectedId));
      });
    }

    this.cdr.detectChanges();
  }

  public clearSearch(): void {
    this.searchValue = '';
  }

  public selectInstrument(i: Instrument): void {
    this.selectedInstrument$.emit(i);
  }

  public isAsk(instrument: Instrument): boolean {
    return (
      !_.isNil(this.ticks.get(instrument.id)?.rolling24HrPxChange) && this.ticks.get(instrument.id)?.isRollingNegative
    );
  }

  public isBid(instrument: Instrument): boolean {
    return (
      !_.isNil(this.ticks.get(instrument.id)?.rolling24HrPxChange) && !this.ticks.get(instrument.id).isRollingNegative
    );
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
    this.selectedInstrument$.complete();
  }

  public readonly trackByInstrumentId: TrackByFunction<Instrument> = (index, item) => item.id;
}

@NgModule({
  imports: [CommonModule, IconModule, TranslateModule, SharedModule, ScrollingModule, MatInputModule, FormsModule],
  declarations: [ListComponent],
  exports: [ListComponent],
})
export class ListModule {}
