import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UpdateUser} from '@app/actions/user.actions';
import {AnalyticsFacadeService} from '@app/analytic-systems/analytics-facade.service';
import {IRudderStackConfig} from '@app/analytic-systems/shared/interfaces/rudder-stack-config.interface';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {ResponseException} from '@app/core/exceptions/response-exception';
import {User} from '@app/core/models/user/user';
import {AnnouncementService} from '@app/core/services/announcement.service';
import {AuthService} from '@app/core/services/auth.service';
import {CurrentUserService} from '@app/core/services/current-user.service';
import Hooks from '@app/core/services/hooks.service';
import {LogoutService} from '@app/core/services/logout.service';
import {NotificationsService} from '@app/core/services/notifications.service';
import {UserService} from '@app/core/services/user.service';
import {VerificationService} from '@app/core/services/verification.service';
import {WebSocketService} from '@app/core/services/web-socket.service';
import {GridsterWidgetsEventsService} from '@app/gridster/events/gridster-widgets-events.service';
import {ProviderResolverService} from '@app/gridster/states/provider-resolver.service';
import {ClientApiService} from '@app/pbsr/services/client-api.service';
import {PbsrClientInfoService} from '@app/pbsr/services/pbsr-client-info.service';
import {WarpAuthService} from '@app/trading-board/services/warp-auth.service';
import {OfflineComponent} from '@app/ui/offline/offline.component';
import {SigningFormComponent} from '@app/ui/signing-form/signing-form.component';
import {Environment} from '@env/environment.entities';
import {Store} from '@ngxs/store';
import {Subject, Subscription} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-internal-layout',
  templateUrl: './internal-layout.component.html',
  providers: [UserService, GridsterWidgetsEventsService, ProviderResolverService],
})
export class InternalLayoutComponent implements OnInit, OnDestroy {
  private user: User;
  private dialogSub: Subscription;
  private announcementsSub: Subscription;
  private overlayDialogRef: MatDialogRef<OfflineComponent | SigningFormComponent>;
  private readonly destroyer$ = new Subject<void>();

  constructor(
    private readonly userService: UserService,
    private readonly notifications: NotificationsService,
    private readonly ws: WebSocketService,
    private readonly announcement: AnnouncementService,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly verificationService: VerificationService,
    private readonly currentUserService: CurrentUserService,
    private readonly authService: AuthService,
    private readonly logoutService: LogoutService,
    private readonly clientApiService: ClientApiService,
    private readonly environment: Environment,
    private readonly warpAuthService: WarpAuthService,
    private readonly connectionService: ConnectionService,
    private readonly pbsrClientInfoService: PbsrClientInfoService,
    private readonly analyticsFacadeService: AnalyticsFacadeService,
  ) {}

  public ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      void this.userService.me(['info']).then((e: IExceptions<User>) => {
        if (e instanceof ResponseException) {
          this.user = User.Make<User>(e.getData());

          Hooks.internalLayout(this.user);
          this.store.dispatch(new UpdateUser(this.user));
          this.currentUserService.setUser(this.user);

          this.ws.connect(this.user);
          this.verificationService.loadUserRights();

          this.notifications.subscribe();

          this.initAnalyticsServices();

          this.announcement.connect();
          this.announcementsSub = this.announcement.required.subscribe(announcements => {
            if (!announcements.length) {
              return;
            }

            void import('../announcement/required/required.component').then(m => {
              this.dialogSub?.unsubscribe();
              this.dialogSub = this.dialog
                .open(m.RequiredComponent, {
                  disableClose: true,
                  panelClass: ['notification-required-dialog'],
                  maxWidth: '95vw',
                })
                .afterClosed()
                .subscribe();
            });
          });
        }
      });

      if (this.environment.isPbsVendor) {
        this.clientApiService
          .getCommonInfo()
          .pipe(takeUntil(this.destroyer$))
          .subscribe(clientInfo => {
            if (clientInfo.isNeedToResignDocuments) {
              this.signingForm();
            }

            this.pbsrClientInfoService.isClientIndividual$.next(clientInfo.isIndividual);
          });

        this.initWarpAuthService();
      }
    }
  }

  private initWarpAuthService(): void {
    this.warpAuthService.setConfiguration({
      authHeadersFactory: () => this.connectionService.getAuthHeaders(),
    });

    this.warpAuthService.startWatchToUserInfo(this.environment.apiUrl);
  }

  private initAnalyticsServices(): void {
    const rudderStackConfig: IRudderStackConfig | undefined = this.environment.rudderStackConfig
      ? {
          writeKey: this.environment.rudderStackConfig.writeKey,
          dataplaneUrl: this.environment.rudderStackConfig.dataplaneUrl,
        }
      : undefined;

    this.analyticsFacadeService.initialize({
      amplitudeConfig: {
        vendorApiKey: this.environment.amplitudeApiKey,
      },
      rudderStackConfig,
      email: this.user.email,
    });
  }

  private signingForm(): void {
    this.overlayDialogRef?.close();
    this.overlayDialogRef = this.dialog.open<SigningFormComponent>(SigningFormComponent, {
      width: '100%',
      height: '100%',
      closeOnNavigation: false,
      hasBackdrop: false,
      disableClose: true,
      panelClass: 'error-dialog',
    });

    this.overlayDialogRef
      .afterClosed()
      .pipe(filter<boolean>(Boolean), takeUntil(this.destroyer$))
      .subscribe(isClose => {
        if (!isClose) {
          return;
        }

        void this.logoutService.logout();
      });
  }

  public ngOnDestroy(): void {
    this.ws.disconnect();
    if (this.user?.settings?.isSupportSendEmail) {
      this.notifications.unsubscribe();
    }
    this.announcement.disconnect();
    this.announcementsSub?.unsubscribe();
    this.dialogSub?.unsubscribe();
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
