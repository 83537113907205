import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {FormFieldMask} from '@app/core/form/entities/form.field';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {IAutocompleteItem} from '@app/pbsr/interfaces/autocomplete-item.interface';
import * as _ from 'lodash-es';
import {asyncScheduler, BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {map, observeOn, startWith, takeUntil} from 'rxjs/operators';

import {IMaskConfig} from './interfaces/mask-config.interface';

@Component({
  selector: 'app-form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent extends FormInput implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();
  private readonly isAutocompleteMenuOpened$ = new BehaviorSubject(false);
  private readonly isAutocompleteInputValueFitField$ = new BehaviorSubject(true);

  private optionsElements: QueryList<ElementRef<HTMLOptionElement>>;

  public mask: IMaskConfig;
  public required: boolean;
  public isRequiredIf: boolean;
  public customMask: IMaskConfig;

  public get isShowRequiredIndicator(): boolean {
    return this.isTouchedAndInvalid();
  }

  @ViewChildren(MatOption, {read: ElementRef})
  public matOptions: QueryList<ElementRef<HTMLOptionElement>>;

  @ViewChild('defaultInput', {read: ElementRef})
  public defaultInput: ElementRef;

  @ViewChild('autocompleteInputHelperBlock', {read: ElementRef})
  public autocompleteInputHelperBlock: ElementRef;

  @ViewChild('autocompleteInput', {read: ElementRef})
  public autocompleteInput: ElementRef;

  public readonly isShowFieldTooltip$ = combineLatest([
    this.isAutocompleteMenuOpened$,
    this.isAutocompleteInputValueFitField$,
  ]).pipe(
    map(
      ([isAutocompleteMenuOpened, isAutocompleteInputValueFitField]) =>
        !isAutocompleteMenuOpened && !isAutocompleteInputValueFitField,
    ),
  );

  public readonly trackByIndex: TrackByFunction<unknown> = index => index;

  constructor(labelService: LabelService, errorsService: ErrorsService) {
    super(labelService, errorsService);
  }

  public ngAfterViewInit(): void {
    if (this.focusField === undefined) {
      return;
    }

    if (this.focusField === this.field.name && this.defaultInput) {
      // FDP-14325 setTimeout is used to avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.defaultInput.nativeElement.focus(), 0);
    }
  }

  public autocompleteMenuOpened(): void {
    this.isAutocompleteMenuOpened$.next(true);

    this.optionsElements = this.matOptions;
  }

  public autocompleteMenuClosed(): void {
    this.isAutocompleteMenuOpened$.next(false);
  }

  public isShowOptionTooltip(matOption: MatOption): boolean {
    const option = this.optionsElements?.find(elementRef => elementRef?.nativeElement.id === matOption.id);

    if (!option) {
      return false;
    }

    const parentOffsetWidth = option.nativeElement.offsetWidth;
    const childSpanOffsetWidth = this.getSpanWithTextWidth(option);

    return parentOffsetWidth < childSpanOffsetWidth;
  }

  public ngOnInit(): void {
    this.mask = InputComponent.getMask(this.field.mask);
    this.required = this.isRequired();
    this.isRequiredIf = this.isRequiredIfValidator();

    if (this.isShowTooltipIfLongValue) {
      this.control.valueChanges
        .pipe(startWith(this.control.value), observeOn(asyncScheduler), takeUntil(this.destroyer$))
        .subscribe(() => this.isAutocompleteInputValueFitField$.next(this.isAutocompleteInputValueFitField()));
    }
  }

  public getDisplayValue(value: IAutocompleteItem | string): string {
    if (_.isNil(this.renderValue)) {
      if (typeof value === 'string') {
        return value;
      }

      return '';
    }

    return this.renderValue(value, this.field.name);
  }

  public static getMask(mask: FormFieldMask): IMaskConfig {
    const maskBuilder: (value: string) => IMaskConfig = mask && InputComponent.MASK_LIST[mask.key];

    return maskBuilder && maskBuilder(mask.value);
  }

  private isAutocompleteInputValueFitField(): boolean {
    if (!this.autocompleteInput || !this.autocompleteInputHelperBlock) {
      return true;
    }

    return (
      this.autocompleteInput.nativeElement.offsetWidth > this.autocompleteInputHelperBlock.nativeElement.offsetWidth
    );
  }

  private static readonly MASK_LIST: Record<string, (value: string) => IMaskConfig> = {
    phone: (): IMaskConfig => ({
      pattern: '+0000000000000',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dropSpecialCharacters: false,
    }),

    code: (value: string = '5'): IMaskConfig => {
      const count = parseInt(value, 10) || 5;
      const pattern = Array(count + 1).join('0');

      return {
        pattern,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dropSpecialCharacters: true,
      };
    },

    numeric: (value: string): IMaskConfig => {
      const count = parseInt(value, 10) || 8;
      const pattern = '0*.' + Array(count).fill('9');

      return {
        pattern,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dropSpecialCharacters: true,
        precision: count,
      };
    },

    digits: (value: string): IMaskConfig => {
      const count = parseInt(value, 10);
      const pattern = count ? Array(count + 1).join('0') : '0*';

      return {
        pattern,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dropSpecialCharacters: true,
      };
    },

    cardNumberDefault: (): IMaskConfig => ({
      pattern: '0000-0000-0000-0000',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dropSpecialCharacters: true,
    }),

    cardNumberFull: (): IMaskConfig => ({
      pattern: '0000-0000-0000-0999999',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dropSpecialCharacters: true,
    }),

    pbsThousands: (value: string): IMaskConfig => {
      const count = parseInt(value, 10) || 8;

      return {
        pattern: `separator.${count}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dropSpecialCharacters: true,
        thousandSeparator: ' ',
        decimalMarker: ',',
      };
    },
  };

  private getSpanWithTextWidth(elementRef: ElementRef<HTMLOptionElement>): number {
    const childSpanContainer = elementRef.nativeElement.children[0];
    const spanTextContainer = childSpanContainer.children[0] as HTMLElement;

    return spanTextContainer.offsetWidth;
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
