import * as B2MarginModels from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {B2MarginOpenOrder} from '../../../models/b2margin/b2-margin-open-order';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class OrdersInternalEventHandler extends AEventHandler<IB2marginMessage<B2MarginModels.OrderTO[]>> {
  public readonly type = EB2MarginEventType.Orders;
  public currentAccountId: number | undefined;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<B2MarginModels.OrderTO[]>): void {
    let oldValue = this.store.openOrders$.getValue();

    if (this.currentAccountId !== resource.accountId) {
      this.currentAccountId = resource.accountId;
      oldValue = [];
    }

    const orders = resource.body
      .reduce((acc, item) => {
        acc = acc.filter(order => order.orderId !== item.orderId);
        acc.push(item);

        return acc;
      }, oldValue)
      .filter(order => B2MarginOpenOrder.filterOpenOrders(order));

    this.store.openOrders$.next(orders);
  }
}

b2MarginInternalEventHandlers.push(OrdersInternalEventHandler);
