import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormConfig} from '@app/core/form/entities/form.wrapper';
import {getCodeFormConfig} from '@app/core/utils/get-code-form-config';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {AWizardWithCodeForm} from '@app/wizard/classes/wizard-with-code-form';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-confirmation',
  templateUrl: './resign-profiles.component.html',
  styleUrls: ['./resign-profiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResignProfilesComponent extends AWizardWithCodeForm implements OnInit, OnDestroy {
  protected readonly destroyer$ = new Subject<void>();
  public formConfig: FormConfig;

  constructor(
    protected readonly snackbar: SnackbarService,
    protected readonly translate: TranslateService,
    protected readonly router: Router,
  ) {
    super(snackbar, translate, router);
  }

  public ngOnInit(): void {
    this.formConfig = getCodeFormConfig(5);
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public async goToGetCode(): Promise<void> {
    await this.manager.init();
  }
}
