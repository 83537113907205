import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnDestroy {
  private timerSub = Subscription.EMPTY;

  private _timeOutput: string;
  public get timeOutput(): string {
    return this._timeOutput;
  }

  // Seconds to countdown.
  @Input()
  public set time(time: number) {
    this.timerSub.unsubscribe();

    this.timerSub = timer(1000, 1000)
      .pipe(
        take(time),
        map(value => time - 1 - value),
      )
      .subscribe(value => {
        const seconds = value % 60;
        const minutes = Math.floor(value / 60);
        this._timeOutput = `${minutes}:${(seconds < 10 ? '0' : '') + seconds}`;
        this.cdr.markForCheck();
        if (!value) {
          this.finalEvent.emit();
        }

        this.cdr.markForCheck();
      });
  }

  @Output()
  public readonly finalEvent = new EventEmitter<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    this.timerSub.unsubscribe();
  }
}
