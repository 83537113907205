import {B2TraderAuthPrivate} from '@app/trading-board/b2trader-auth/b2trader-auth-private';
import {IB2TraderApiData} from '@app/trading-board/b2trader-auth/interfaces/b2trader-api-data';
import {IB2TraderTokenResponseDto} from '@app/trading-board/b2trader-auth/interfaces/b2trader-token-response.dto';
import {mapTokenResponse} from '@app/trading-board/b2trader-auth/mappers/b2trader-token-response.mapper';
import {EB2traderStandaloneMessageTypes} from '@app/trading-board/enum/b2trader/b2trader-standalone-message-types';
import {from, interval, Observable, of, Subject} from 'rxjs';
import {first, map, switchMap, takeUntil} from 'rxjs/operators';

import {B2traderStandaloneConnection} from './b2trader-standalone-connection';

export class B2traderAuthStandalone extends B2TraderAuthPrivate {
  private readonly connectionService = new B2traderStandaloneConnection();
  private readonly destroyer$ = new Subject<void>();
  private readonly pingIntervalInMs = 60000;

  private readonly startPing$ = new Subject<void>();

  protected apiUrl = '';

  protected getApiData(): Observable<IB2TraderApiData | undefined> {
    return this.connectionService
      .get<IB2TraderTokenResponseDto>(EB2traderStandaloneMessageTypes.ReceiveApiData)
      .pipe(map(data => mapTokenResponse(data)));
  }

  constructor(protected readonly platformType: string) {
    super('', platformType);

    this.connectionService.sendMessage(EB2traderStandaloneMessageTypes.RequestApiData);
    this.startPing$
      .pipe(
        switchMap(() => interval(this.pingIntervalInMs)),
        map(() => this.connectionService.sendMessage(EB2traderStandaloneMessageTypes.Ping)),
        takeUntil(this.destroyer$),
      )
      .subscribe();
  }

  protected generateRefreshTokenRequest(): Observable<IB2TraderApiData> {
    this.connectionService.sendMessage(EB2traderStandaloneMessageTypes.RequestApiData);

    return this.connectionService.get<IB2TraderTokenResponseDto>(EB2traderStandaloneMessageTypes.ReceiveApiData).pipe(
      first(),
      map(data => {
        this.refreshTokenRequest$ = null;

        return mapTokenResponse(data);
      }),
    );
  }

  public startPing(): void {
    this.startPing$.next();
  }

  public fromFetch<T>(url: string, options?: RequestInit): Observable<T> {
    return of(undefined).pipe(
      switchMap(() => this.apiData$),
      first(),
      switchMap(apiData =>
        this.fromApiFetch(apiData, url, options).pipe(
          switchMap(res => {
            if (res.status !== 401) {
              return from(res.json().catch(err => err as T)) as Observable<T>;
            }

            this.refreshTokenRequest$ = this.generateRefreshTokenRequest();
            return this.refreshTokenRequest$.pipe(switchMap(() => this.fromFetch<T>(url, options)));
          }),
        ),
      ),
    );
  }

  public clear(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
    this.refreshTokenRequest$ = null;

    super.clear();
  }
}
