import {DOCUMENT} from '@angular/common';
import {Injectable, InjectFlags, Injector, OnDestroy, StaticProvider, Type} from '@angular/core';
import {GridsterProviders, IGridsterItem} from '@app/core/models/gridster';
import {DashboardDatafeedService} from '@app/dashboard/dashboard.datafeed.service';
import {AnalyticsService} from '@app/dashboard/widgets/pbsr/analytics/services/analytics.service';
import {AGridsterDatafeedService} from '@app/gridster/datafeeds/gridster-datafeed-service.abstract';
import {PartnerBoardDatafeedService} from '@app/ib-room/partner-board/partner-board-datafeed.service';
import {AccountManagerService} from '@app/pbsr/services/account-manager/account-manager.service';
import {MyAccountsPortfolioService} from '@app/pbsr/services/my-accounts-portfolio/my-accounts-portfolio.service';
import {AB2TraderAuth} from '@app/trading-board/b2trader-auth/b2trader-auth.abstract';
import {B2TraderAuthProvider} from '@app/trading-board/b2trader-auth/b2trader-auth.provider';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {B2MarginSpotDatafeedService} from '@app/trading-board/datafeed/b2margin-spot-datafeed.service';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {ATradeDatafeed} from '@app/trading-board/datafeed/trade-datafeed.abstract';
import {B2MarginEventHandlersServices} from '@app/trading-board/event-handlers/b2margin/b2margin-event-handlers-providers';
import {B2TraderEventHandlersServices} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers-providers';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EventHandlersToken} from '@app/trading-board/event-handlers/event-handlers-token';
import {MoexEventHandlersServices} from '@app/trading-board/event-handlers/moex/moex-event-handlers.service';
import {B2marginDatafeedFacade} from '@app/trading-board/facades/b2margin-datafeed-facade';
import {B2marginSpotDatafeedFacade} from '@app/trading-board/facades/b2margin-spot-datafeed-facade';
import {B2traderDatafeedFacade} from '@app/trading-board/facades/b2trader-datafeed-facade';
import {DatafeedFacadeToken} from '@app/trading-board/facades/facade-token';
import {MoexDatafeedFacade} from '@app/trading-board/facades/moex-datafeed-facade';
import {B2marginAuthService} from '@app/trading-board/services/b2margin-auth.service';
import {B2marginComponentsResolverService} from '@app/trading-board/services/b2margin-components-resolver.service';
import {B2marginPopupQueueService} from '@app/trading-board/services/b2margin-popup-queue.service';
import {B2marginSpotComponentsResolverService} from '@app/trading-board/services/b2margin-spot-components-resolver.service';
import {B2traderComponentResolverService} from '@app/trading-board/services/b2trader-component-resolver.service';
import {AComponentResolver} from '@app/trading-board/services/component-resolver.abstract';
import {MoexComponentResolverService} from '@app/trading-board/services/moex-component-resolver.service';
import {TradingBoardLoadingService} from '@app/trading-board/services/trading-board-loading.service';
import {WarpAuthService} from '@app/trading-board/services/warp-auth.service';
import {WorkerConnectionService} from '@app/trading-board/services/worker-connection.service';
import {WarpOrdersStateService} from '@app/trading-board/widgets/warp-orders/services/warp-orders-state/warp-orders-state.service';
import {Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

@Injectable()
export class ProviderResolverService implements OnDestroy {
  private static readonly CLOSE_DELAY_TIME = 60_000;

  private static getDashboardInjectorTree(): StaticProvider[] {
    return [
      {
        provide: AGridsterDatafeedService,
        useFactory: datafeed => datafeed,
        deps: [DashboardDatafeedService],
      },
      {
        provide: DashboardDatafeedService,
        useClass: DashboardDatafeedService,
      },
    ];
  }

  private static getMoexInjectorTree(): StaticProvider[] {
    return [
      ...ProviderResolverService.getEventHandlersProviders(MoexEventHandlersServices),
      {
        provide: AComponentResolver,
        useValue: new MoexComponentResolverService(),
      },
      {
        provide: DatafeedFacadeToken,
        useFactory: (loading, auth) => new MoexDatafeedFacade(loading, auth),
        deps: [TradingBoardLoadingService, WarpAuthService],
      },
      {
        provide: WorkerConnectionService,
        useClass: WorkerConnectionService,
      },
      {
        provide: AccountManagerService,
        useClass: AccountManagerService,
      },
      {
        provide: MyAccountsPortfolioService,
        useClass: MyAccountsPortfolioService,
      },
      {
        provide: MoexDatafeedService,
        useClass: MoexDatafeedService,
      },
      {
        provide: WarpOrdersStateService,
        useClass: WarpOrdersStateService,
      },
      {
        provide: AnalyticsService,
        useClass: AnalyticsService,
      },
      {
        provide: AGridsterDatafeedService,
        useFactory: datafeed => datafeed,
        deps: [MoexDatafeedService],
      },
      {
        provide: ATradeDatafeed,
        useFactory: datafeed => datafeed,
        deps: [MoexDatafeedService],
      },
    ];
  }

  private static getB2TraderInjectorTree(): StaticProvider[] {
    return [
      ...ProviderResolverService.getEventHandlersProviders(B2TraderEventHandlersServices),
      B2TraderAuthProvider,
      {
        provide: AComponentResolver,
        useValue: new B2traderComponentResolverService(),
      },
      {
        provide: DatafeedFacadeToken,
        useFactory: (loading: TradingBoardLoadingService, auth: AB2TraderAuth, injector: Injector) => {
          const doc = injector.get(DOCUMENT);
          return new B2traderDatafeedFacade(loading, auth, doc);
        },
        deps: [TradingBoardLoadingService, AB2TraderAuth, Injector],
      },
      {
        provide: WorkerConnectionService,
        useClass: WorkerConnectionService,
      },
      {
        provide: B2TraderDatafeedService,
        useClass: B2TraderDatafeedService,
      },
      {
        provide: AGridsterDatafeedService,
        useFactory: datafeed => datafeed,
        deps: [B2TraderDatafeedService],
      },
      {
        provide: ATradeDatafeed,
        useFactory: datafeed => datafeed,
        deps: [B2TraderDatafeedService],
      },
    ];
  }

  private static getB2MarginInjectorTree(): StaticProvider[] {
    return [
      ...ProviderResolverService.getEventHandlersProviders(B2MarginEventHandlersServices),
      {
        provide: AComponentResolver,
        useValue: new B2marginComponentsResolverService(),
      },
      {provide: B2marginAuthService, useClass: B2marginAuthService},
      {
        provide: DatafeedFacadeToken,
        useFactory: (tradingBoardLoadingService, b2marginAuthService) =>
          new B2marginDatafeedFacade(tradingBoardLoadingService, b2marginAuthService),
        deps: [TradingBoardLoadingService, B2marginAuthService],
      },
      {
        provide: WorkerConnectionService,
        useClass: WorkerConnectionService,
      },
      {
        provide: B2marginPopupQueueService,
        useClass: B2marginPopupQueueService,
      },
      {
        provide: B2MarginDatafeedService,
        useClass: B2MarginDatafeedService,
      },
      {
        provide: AGridsterDatafeedService,
        useFactory: datafeed => datafeed,
        deps: [B2MarginDatafeedService],
      },
      {
        provide: ATradeDatafeed,
        useFactory: datafeed => datafeed,
        deps: [B2MarginDatafeedService],
      },
    ];
  }

  private static getB2MarginSpotInjectorTree(): StaticProvider[] {
    return [
      ...ProviderResolverService.getEventHandlersProviders(B2MarginEventHandlersServices),
      {
        provide: AComponentResolver,
        useValue: new B2marginSpotComponentsResolverService(),
      },
      {provide: B2marginAuthService, useClass: B2marginAuthService},
      {
        provide: DatafeedFacadeToken,
        useFactory: (tradingBoardLoadingService, b2marginAuthService) =>
          new B2marginSpotDatafeedFacade(tradingBoardLoadingService, b2marginAuthService),
        deps: [TradingBoardLoadingService, B2marginAuthService],
      },
      {
        provide: WorkerConnectionService,
        useClass: WorkerConnectionService,
      },
      {
        provide: B2marginPopupQueueService,
        useClass: B2marginPopupQueueService,
      },
      {
        provide: B2MarginSpotDatafeedService,
        useClass: B2MarginSpotDatafeedService,
      },
      {
        provide: B2MarginDatafeedService,
        useFactory: datafeed => datafeed,
        deps: [B2MarginSpotDatafeedService],
      },
      {
        provide: AGridsterDatafeedService,
        useFactory: datafeed => datafeed,
        deps: [B2MarginSpotDatafeedService],
      },
      {
        provide: ATradeDatafeed,
        useFactory: datafeed => datafeed,
        deps: [B2MarginSpotDatafeedService],
      },
    ];
  }

  private static getPartnerInjectorTree(): StaticProvider[] {
    return [
      {
        provide: AGridsterDatafeedService,
        useFactory: (datafeed: PartnerBoardDatafeedService) => datafeed,
        deps: [PartnerBoardDatafeedService],
      },
      {
        provide: PartnerBoardDatafeedService,
        useClass: PartnerBoardDatafeedService,
      },
    ];
  }

  private readonly providerTrees: Record<GridsterProviders, StaticProvider[]> = {
    [GridsterProviders.dashboard]: ProviderResolverService.getDashboardInjectorTree(),
    [GridsterProviders.b2margin]: ProviderResolverService.getB2MarginInjectorTree(),
    [GridsterProviders.b2marginSpot]: ProviderResolverService.getB2MarginSpotInjectorTree(),
    [GridsterProviders.b2trader]: ProviderResolverService.getB2TraderInjectorTree(),
    [GridsterProviders.partner]: ProviderResolverService.getPartnerInjectorTree(),
    [GridsterProviders.moex]: ProviderResolverService.getMoexInjectorTree(),
  };

  private readonly destroyer$ = new Subject<void>();

  public injectors = new Map<string, Injector>();

  public bootstrappedConfigs = new Set<IGridsterItem>();

  public bootstrappedConfigs$ = new Subject<Set<IGridsterItem>>();

  public clear$ = new Subject<boolean>();

  constructor(private readonly injector: Injector) {
    this.clear$
      .pipe(
        debounceTime(ProviderResolverService.CLOSE_DELAY_TIME),
        filter(isFlag => !!isFlag),
        takeUntil(this.destroyer$),
      )
      .subscribe(() => {
        this.clearInjectors();
      });
  }

  private clearInjectors(): void {
    this.injectors.forEach(item => {
      // TODO: remove deprecation in https://b2btech.atlassian.net/browse/FDP-17217
      /* eslint-disable deprecation/deprecation */
      item.get(WorkerConnectionService, null, InjectFlags.Optional)?.ngOnDestroy();
      item.get(AGridsterDatafeedService, null, InjectFlags.Optional)?.ngOnDestroy();
      item.get(AB2TraderAuth, null, InjectFlags.Optional)?.clear();
      /* eslint-enable deprecation/deprecation */
    });
    this.injectors.clear();
  }

  public cancelClear(): void {
    this.clear$.next(false);
  }

  public static getEventHandlersProviders<T>(handlers: Type<AEventHandler<T>>[]): StaticProvider[] {
    return [
      ...handlers.map((handler: Type<AEventHandler<T>>) => ({
        provide: handler,
        useClass: handler,
      })),
      ...handlers.map((handler: Type<AEventHandler<T>>) => ({
        provide: EventHandlersToken,
        useExisting: handler,
        multi: true,
      })),
    ];
  }

  public init(): void {
    this.bootstrappedConfigs = new Set<IGridsterItem>();
    this.cancelClear();
  }

  public getInjector(
    identity: {id: string; type: GridsterProviders},
    providers: StaticProvider[] = [],
    parentInjector?: Injector,
  ): Injector {
    if (!this.injectors.has(identity.id) && identity.type in this.providerTrees) {
      const injector = Injector.create({
        parent: parentInjector ?? this.injector,
        providers: this.providerTrees[identity.type],
      });

      this.injectors.set(identity.id, injector);
    }

    return Injector.create({parent: this.injectors.get(identity.id), providers});
  }

  public getDatafeed(id: string): AGridsterDatafeedService {
    return this.injectors.get(id).get(AGridsterDatafeedService);
  }

  public clear(): void {
    this.clear$.next(true);
  }

  public ngOnDestroy(): void {
    this.clearInjectors();
    this.destroyer$.next();
  }
}
