/* eslint-disable @typescript-eslint/naming-convention */
import {Decimal} from '@app/core/decimal';
import {CastTypes, Model} from '@app/core/model';
import {isAccountForExchange} from '@app/core/services/exchange.service';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';

import {InvestmentData} from './investment/investment-data';
import {Options} from './options';
import {Product} from './product';
import {Report} from './report';
import {checkRight, Rights} from './rights';

export class Account extends Model {
  public static createMap(accounts: Account[]): Map<number, Account> {
    return accounts.reduce((acc, account) => acc.set(account.id, account), new Map<number, Account>());
  }

  private _product: Product;
  private _report?: Report;
  private readonly decimalZero = DecimalHelper.from(0);

  public get report(): Report {
    return this._report ? this._report : new Report();
  }

  public set report(value: Report) {
    this._report = value;
  }

  public get product(): Product {
    return this._product ? this._product : new Product();
  }

  public set product(value: Product) {
    this._product = value;
  }

  private _isReportUnavailable?: boolean;

  public set isReportUnavailable(isUnavailable: boolean) {
    this._isReportUnavailable = isUnavailable;
  }

  public get isReportUnavailable(): boolean {
    return this._isReportUnavailable;
  }

  public id: number;
  public show_id: boolean;
  public account_id: string;
  public caption: string;
  public archive: boolean;
  public priority: number;
  public password_reset: boolean;
  public rights: number;
  public created_at: string;
  public favorite: boolean;
  public options: Options;
  public investment_data?: InvestmentData | null;
  // override for report balance in investment platform
  public investment_data_balance?: Decimal = new Decimal(0);

  public readonly casts = {
    id: [CastTypes.Number],
    show_id: [CastTypes.Boolean, CastTypes.Undefined],
    account_id: [CastTypes.String],
    caption: [CastTypes.String],
    archive: [CastTypes.Boolean],
    priority: [CastTypes.Number],
    password_reset: [CastTypes.Boolean],
    rights: [CastTypes.Number],
    created_at: [CastTypes.String],
    favorite: [CastTypes.Boolean],
    options: [CastTypes.Any, Options],
    product: [Product],
    report: [CastTypes.Null, Report],
    investment_data: [CastTypes.Null, InvestmentData, CastTypes.Undefined],
  };

  public get rightsDeposit(): boolean {
    return this.checkRight(Rights.RIGHTS_DEPOSIT);
  }

  public get rightsVisible(): boolean {
    return this.checkRight(Rights.RIGHTS_VISIBLE);
  }

  public get rightsEnabled(): boolean {
    return checkRight(this, Rights.RIGHTS_ENABLED);
  }

  public get rightsTransferDeposit(): boolean {
    return this.checkRight(Rights.RIGHTS_TRANSFER_DEPOSIT);
  }

  public get rightsTransferWithdraw(): boolean {
    return this.checkRight(Rights.RIGHTS_TRANSFER_WITHDRAW);
  }

  public get rightsTransferExchange(): boolean {
    return this.rightsTransferWithdraw && this.rightsTransferDeposit;
  }

  public get rightsTransfer(): boolean {
    return this.rightsTransferWithdraw || this.rightsTransferDeposit;
  }

  public get rightsWithdraw(): boolean {
    return this.checkRight(Rights.RIGHTS_WITHDRAW);
  }

  public get rightsExchange(): boolean {
    return this.checkRight(Rights.RIGHTS_EXCHANGE);
  }

  private checkRight(right: number): boolean {
    return checkRight(this, right) && checkRight(this.product, right);
  }

  public get isVisibleInWallets(): boolean {
    return this.product.typePersonal || this.product.isB2Margin;
  }

  public get rightsEnabledAndVisible(): boolean {
    return this.rightsEnabled && this.rightsVisible;
  }

  public get isAvailableForTrading(): boolean {
    return this.product.isPersonalOrMargin && this.rightsEnabledAndVisible;
  }

  public get balance(): string {
    return this.report?.balance.getFixed(this.product.currency.precision) || '-';
  }

  public get freeFunds(): string {
    return this.report?.freeFunds.getFixed(this.product.currency.precision) || '-';
  }

  public get marginFree(): string {
    return this.report?.marginFree.getFixed(this.product.currency.precision) || '-';
  }

  public get margin(): string {
    return this.report?.margin.getFixed(this.product.currency.precision) || '-';
  }

  public get marginLevel(): DecimalHelper {
    if (!this.report?.marginLevel) {
      return this.decimalZero;
    }
    return DecimalHelper.from(this.report?.marginLevel.value, this.product.currency.precision);
  }

  public get pl(): DecimalHelper {
    if (!this.report?.pl) {
      return this.decimalZero;
    }
    return DecimalHelper.from(this.report?.pl.value, this.product.currency.precision);
  }

  public get hold(): string {
    return this.report?.hold.getFixed(this.product.currency.precision) || '-';
  }

  public get equity(): string {
    return this.report?.equity.getFixed(this.product.currency.precision) || '-';
  }

  public get isAvailableForExchange(): boolean {
    return (
      (this.product.typePersonal || this.product.platform.isB2Margin || this.product.typeExchange) &&
      isAccountForExchange(this)
    );
  }

  public get walletTitle(): string {
    const balance: string = this.freeFunds;
    const title = `${balance} ${this.product.currency.alpha}`;
    const isShowWithAccountId = this.product.typeReal || this.product.typeExternal;

    return isShowWithAccountId ? `${title} | ${this.account_id}` : title;
  }

  public getName(prefix: string = '', hasId?: boolean): string {
    const isShowId = hasId ?? this.show_id;

    if (this.product.typePersonal || this.product.isB2Margin || this.product.typeExchange || this.product.typePartner) {
      const alpha = `${prefix} ${this.product.currency.alpha}`;
      return isShowId ? `${alpha} | ${this.account_id}` : alpha;
    }

    return isShowId ? `${this.product.platform.caption} | ${this.account_id}` : this.product.platform.caption;
  }

  public getNameForTransferLite(): string {
    if (this.product.typePartner) {
      return this.caption;
    }
    if (this.product.typeReal) {
      return this.account_id + ' ' + this.caption;
    }
    return this.caption;
  }

  public isInvestmentMasterAccount(): boolean {
    return this.product.isInvestmentMasterType;
  }

  public isInvestmentFollowerAccount(): boolean {
    return this.product.isInvestmentFollowerType;
  }

  public get hasMultipleTypePassword(): boolean | undefined {
    return this.product.platform.has_multiple_type_password;
  }
}
