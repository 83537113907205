import {IBalance} from '@app/trading-board/interfaces/b2trader/balance';
import {Expose, Transform} from 'class-transformer';

import {DecimalHelper} from '../decimal-helper';

export class Balance {
  @Expose()
  @Transform(({obj}: {obj: IBalance}) => obj.asset?.toUpperCase())
  public product: string;

  @Expose()
  @Transform(({obj}: {obj: IBalance}) => DecimalHelper.from(obj.balance).plus(obj.locked))
  public total: DecimalHelper | null;

  @Expose()
  @Transform(({obj}: {obj: IBalance}) => DecimalHelper.from(obj.balance))
  public available: DecimalHelper | null;
}
