import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppGuard} from '@app/access/app.guard';
import {AuthorizedAccessGuard} from '@app/access/authorized.access.guard';
import {B2copyStandaloneSideLoginGuard} from '@app/access/b2copy-standalone-side-login.guard';
import {B2traderStandaloneGuard} from '@app/access/b2trader-standalone.guard';
import {EqwireAccessGuard} from '@app/access/eqwire-access.guard';
import {GuestAccessGuard} from '@app/access/guest.access.guard';
import {PayoutGuard} from '@app/access/payout.guard';
import {PublicGuard} from '@app/access/public.guard';
import {InternalLayoutComponent} from '@app/internal-layout/internal-layout.component';
import {ErrorLayoutComponent} from '@app/layouts/error-layout/error-layout.component';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {AccessAlias} from '@env/environment.entities';

const appRoutes: Routes = [
  {
    path: ':lang',
    data: {
      title: marker('PageTitle.Home'),
    },
    children: [
      {
        path: 'auth/sign-in',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'public',
        canActivate: [PublicGuard, B2traderStandaloneGuard],
        loadChildren: () => import('@app/public/public.module').then(m => m.PublicModule),
        data: {
          title: marker('PageTitle.Public'),
        },
      },
      {
        path: '',
        canActivate: [AppGuard, B2traderStandaloneGuard, B2copyStandaloneSideLoginGuard, GuestAccessGuard],
        loadChildren: () =>
          import('@app/layouts/external-layout/external-layout.module').then(m => m.ExternalLayoutModule),
        data: {
          title: marker('PageTitle.Guest'),
          access: [AccessAlias.ForceEnabled],
        },
      },
      {
        path: '',
        canActivate: [AppGuard],
        loadChildren: () =>
          import('@app/layouts/eqwire-external-layout/eqwire-external-layout.module').then(
            m => m.EqwireExternalLayoutModule,
          ),
        data: {
          title: marker('PageTitle.Eqwire'),
          access: [AccessAlias.ForceEnabled],
        },
      },
      {
        path: '',
        component: InternalLayoutComponent,
        canActivate: [AppGuard, AuthorizedAccessGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('@app/layouts/full-layout/full-layout.module').then(m => m.FullLayoutModule),
            data: {
              title: marker('PageTitle.Tr'),
              access: [AccessAlias.ForceEnabled],
            },
          },
          {
            path: 'trading-board',
            loadChildren: () => import('./trading-board/trading-board.module').then(m => m.TradingBoardModule),
            data: {
              title: marker('PageTitle.TradingBoard'),
            },
          },
        ],
      },
      {
        path: '**',
        component: ErrorLayoutComponent,
        canActivate: [AppGuard, B2traderStandaloneGuard],
        data: {
          title: marker('PageTitle.Error'),
          access: [AccessAlias.ForceEnabled],
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [
    AuthorizedAccessGuard,
    B2copyStandaloneSideLoginGuard,
    GuestAccessGuard,
    PublicGuard,
    AppGuard,
    PayoutGuard,
    B2traderStandaloneGuard,
    EqwireAccessGuard,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
