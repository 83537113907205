import {Casts, CastTypes, Model} from '@app/core/model';
import {Limit} from '@app/core/models/verification/limit';

export class VerificationLevel extends Model {
  public caption: string;
  public description: string;
  public id: number;
  public index: number;
  public localizedCaption: string;
  public localizedDescription: string;
  public limits: Limit[];

  public readonly casts: Casts = {
    caption: [CastTypes.String],
    description: [CastTypes.String],
    id: [CastTypes.Number],
    index: [CastTypes.Number],
    localizedCaption: [CastTypes.String],
    localizedDescription: [CastTypes.String],
    limits: [[Limit], CastTypes.Null],
  };
}
