import {Sort} from '@angular/material/sort';
import {ESynchronizationColors} from '@app/trading-board/enum/moex/synchronization-colors';
import {Expose, plainToInstance} from 'class-transformer';
import {IsBoolean, IsOptional, IsString} from 'class-validator';

import {GridsterWidgetSettings} from '../../../core/models/gridster/gridster-widget-settings';
import {IMoexTableColumnConfig} from './moex/shared/columns-config';
import {EMoexWatchListTabState} from './moex/shared/tab-state.enum';

export class WatchListSettings {
  public static makeInstance(config: GridsterWidgetSettings): WatchListSettings {
    if (!config) {
      throw new Error('Config is undefined');
    }

    return plainToInstance(WatchListSettings, config, {excludeExtraneousValues: true});
  }

  public static getInstance(): WatchListSettings {
    return plainToInstance(
      WatchListSettings,
      {pairs: [], color: ESynchronizationColors.DefaultColor},
      {excludeExtraneousValues: true},
    );
  }

  @Expose()
  @IsString({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    each: true,
  })
  public pairs: string[] = [];

  @Expose()
  @IsString()
  @IsOptional()
  public tabState?: EMoexWatchListTabState;

  @Expose()
  @IsBoolean()
  @IsOptional()
  public is24hChangeHidden?: boolean;

  @Expose()
  @IsOptional()
  public bondsColumnsConfig?: IMoexTableColumnConfig[];

  @Expose()
  @IsOptional()
  public stockAndCurrenciesColumnsConfig?: IMoexTableColumnConfig[];

  @Expose()
  @IsOptional()
  public bondsSort?: Sort;

  @Expose()
  @IsOptional()
  public stockAndCurrenciesSort?: Sort;

  @Expose()
  @IsString()
  @IsOptional()
  public bondsTimeIntervalUnit?: string;

  @Expose()
  @IsString()
  @IsOptional()
  public stockAndCurrenciesTimeIntervalUnit?: string;

  @Expose()
  @IsOptional()
  public color?: ESynchronizationColors;
}
