<div class="fee-header">
  {{ 'Form.Components.FeeSelect.Title' | translate }}
  <mat-error *ngFor="let err of control?.errors | keys | slice: 0:1">
    {{ getError(err) | async }}
  </mat-error>
</div>

<div class="fee-container">
  <div
    *ngFor="let item of feeLevels; trackBy: trackByIndex"
    [class.selected-fee]="selectedFee?.name === item.name"
    class="fee-level"
    (click)="selectFee(item)"
  >
    <span class="fee-title">{{ item.name }}</span>
    <div *ngIf="currencyAlpha" class="fee-currency">
      <span>{{ currencyAlpha }}: </span>
      <span>{{ item.fee }}</span>
    </div>
    <span class="fee-description">{{ getFeeLevelDescription(item) | translate }}</span>
  </div>
</div>
