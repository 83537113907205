import {ComponentType} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {FormField, FormFieldOption} from '@app/core/form/entities/form.field';
import {ITooltipLayout} from '@app/pbsr/shared/tariff-tooltip/tooltip-layout.interface';
import {ATooltip} from '@app/pbsr/shared/tooltip.abstract';
import {isDeviceTypeMobileOrTablet} from '@app/shared/utils/device-type';
import * as _ from 'lodash-es';
import {Subject} from 'rxjs';
import {filter, startWith, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioInputComponent implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();

  public currentCheckedItemId: number | undefined;

  @Input()
  public options: FormFieldOption[];

  @Input()
  public field: FormField;

  @Input()
  public control: UntypedFormControl;

  @Input()
  public tooltipLayouts: ITooltipLayout[] | undefined;

  @ViewChildren('optionContainer', {read: ViewContainerRef})
  public optionsContainers: QueryList<ViewContainerRef>;

  public readonly trackByValue: TrackByFunction<FormFieldOption> = (index, option) => option.value;

  public get isMobile(): boolean {
    return isDeviceTypeMobileOrTablet(navigator.userAgent);
  }

  public ngOnInit(): void {
    if (!this.tooltipLayouts) {
      return;
    }

    this.control.valueChanges
      .pipe(
        startWith(this.control.value),
        filter(value => !_.isNil(value)),
        takeUntil(this.destroyer$),
      )
      .subscribe((value: number) => this.updateTooltipLayout(value));
  }

  public getTooltipComponent(option: FormFieldOption): ComponentType<ATooltip> | undefined {
    if (!this.tooltipLayouts) {
      return;
    }

    const tooltipLayout = this.tooltipLayouts.find(tooltip => tooltip.id === option.value);
    return tooltipLayout?.component;
  }

  private updateTooltipLayout(id: number): void {
    const tooltipLayout = this.tooltipLayouts.find(tooltip => tooltip.id === id);

    if (!this.optionsContainers) {
      return;
    }

    const viewContainerRef = this.optionsContainers.find(
      container => container.element.nativeElement.id === id.toString(),
    );

    if (tooltipLayout && viewContainerRef && this.isMobile) {
      this.clearContainers();
      const componentRef = viewContainerRef.createComponent(tooltipLayout.component);

      componentRef.instance.optionId = id;
    }
  }

  private clearContainers(): void {
    this.optionsContainers.forEach(container => container.clear());
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
