import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EchoHelper} from '@app/core/helpers/echo.helper';
import {User} from '@app/core/models/user/user';
import {WebSocketResponse} from '@app/core/models/web-socket-response';
import {ATokenService} from '@app/core/token.service';
import {Environment} from '@env/environment.entities';
import Echo from 'laravel-echo';
import {Channel} from 'laravel-echo/dist/channel';
import {EMPTY, Observable, pairwise, ReplaySubject, Subject, switchMap} from 'rxjs';
import {map, shareReplay, startWith, takeUntil} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class WebSocketService extends ATokenService {
  private static readonly USERS_PREFIX = 'frontend_users';

  private readonly connectionDestroyer$ = new Subject<void>();
  private readonly user$ = new ReplaySubject<User | null>();
  private readonly userChannel$ = this.user$.pipe(
    map(user => (user === null ? null : this.echo.private(`${WebSocketService.USERS_PREFIX}.${user.id}`))),
    shareReplay(1),
  );
  private readonly publicChannel$ = this.user$.pipe(
    map(user => (user === null ? null : this.echo.private(WebSocketService.USERS_PREFIX))),
    shareReplay(1),
  );

  private echo: Echo;

  constructor(environment: Environment, router: Router) {
    super(environment, router);
  }

  public connect(user: User): void {
    this.disconnect();

    this.getAuthHeaders()
      .pipe(takeUntil(this.connectionDestroyer$))
      .subscribe(authHeaders => {
        this.echo = EchoHelper.create(this.apiUrl, authHeaders);

        this.user$.next(user);
      });
  }

  public private(eventName: EWsEvents): Observable<WebSocketResponse> {
    return this.userChannel$.pipe(
      startWith(null),
      pairwise(),
      switchMap(([prev, curr]) => this.listenEvent(prev, curr, eventName)),
    );
  }

  public public(eventName: EWsEvents): Observable<WebSocketResponse> {
    return this.publicChannel$.pipe(
      startWith(null),
      pairwise(),
      switchMap(([prev, curr]) => this.listenEvent(prev, curr, eventName)),
    );
  }

  public disconnect(): void {
    this.connectionDestroyer$.next();
    this.user$.next(null);
    this.echo?.disconnect();
    this.echo = null;
  }

  private listenEvent(prev: Channel, curr: Channel, eventName: EWsEvents): Observable<WebSocketResponse> {
    if (prev !== null && prev !== undefined) {
      prev.stopListening(eventName);
    }

    if (curr !== null && curr !== undefined) {
      const subject$ = new Subject<WebSocketResponse>();
      const event = `.${eventName}`;
      curr.listen(event, (res: WebSocketResponse) => subject$.next(res));

      return subject$;
    }

    return EMPTY;
  }
}

export enum EWsEvents {
  AnnouncementCreated = 'announcement_created',
  MessageCreated = 'supportpal_msg_created',
  MessageSentFail = 'supportpal_msg_sent_fail',
  MessageSentSuccess = 'supportpal_msg_sent_success',
  ReportUpdated = 'report_updated',
  ReportUpdateFailed = 'report_update_failed',
  TicketClosedFail = 'supportpal_ticket_closed_fail',
  TicketClosedSuccess = 'supportpal_ticket_closed_success',
  TicketCreatedFail = 'supportpal_ticked_created_fail',
  TicketCreatedSuccess = 'supportpal_ticked_created_success',
  TicketOpenedFail = 'supportpal_ticket_opened_fail',
  TicketOpenedSuccess = 'supportpal_ticket_opened_success',
  TicketStatusChanged = 'supportpal_ticket_status_changed',
  UserCreatedFail = 'supportpal_user_created_fail',
  UserCreatedSuccess = 'supportpal_user_created_success',
  B2CopyAccountCreated = 'b2copy_account_created',
}
