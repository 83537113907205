import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {Observable} from 'rxjs';

const DEFAULT_MIN_ROWS = 3;
const DEFAULT_MAX_ROWS = 10;

@Component({
  selector: 'app-input',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextfieldComponent extends FormInput implements OnInit {
  public required: boolean;
  public minRows: number = DEFAULT_MIN_ROWS;
  public maxRows: number = DEFAULT_MAX_ROWS;

  constructor(labelService: LabelService, errorsService: ErrorsService) {
    super(labelService, errorsService);
  }

  public getInputError(key: string): Observable<string> {
    return this.getError(key);
  }

  public ngOnInit(): void {
    this.required = this.isRequired();
    this.minRows = (this.field.data && this.field.data.minRows) || DEFAULT_MIN_ROWS;
    this.maxRows = (this.field.data && this.field.data.maxRows) || DEFAULT_MAX_ROWS;
  }
}
