import {Injectable} from '@angular/core';
import {IPayoutRequestParams} from '@app/payout/payout.helpers';
import {ICalculatePayoutResultArgs} from '@app/payout/wizards/interfaces/calculate-payout-result-args.interface';
import {BehaviorSubject, Observable, firstValueFrom} from 'rxjs';

import {ConnectionService} from '../connection.service';
import {IExceptions} from '../contracts/i.exceptions';
import {mapException} from '../exceptions/client.exception';
import {FormFieldOption} from '../form/entities/form.field';
import {Operation} from '../models/operation';

@Injectable()
export class PayoutService {
  public state: IPayoutRequestParams | undefined;

  public readonly payoutFeeInfo = new BehaviorSubject<FormFieldOption[] | null>(null);

  public readonly operationInfo = new BehaviorSubject<Operation | null>(null);

  constructor(private readonly connection: ConnectionService) {}

  public init(): Promise<IExceptions> {
    return firstValueFrom(this.connection.get('/api/v1/payouts/withdraw').pipe(mapException));
  }

  public all(): Observable<IExceptions> {
    return this.connection
      .get('/api/v1/payouts/methods', {
        with:
          'groups.methods.currencies.currency,' +
          'groups.methods.currencies.vendor_commission,' +
          'groups.methods.currencies.provider_commission',
      })
      .pipe(mapException);
  }

  public calcPayout(args: ICalculatePayoutResultArgs): Observable<IExceptions> {
    return this.connection.post('/api/v1/payouts', args).pipe(mapException);
  }
}
