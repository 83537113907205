import {Injectable} from '@angular/core';
import {ClassConstructor, plainToInstance} from 'class-transformer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ConnectionService} from '../../core/connection.service';
import {IExceptions} from '../../core/contracts/i.exceptions';
import {mapException} from '../../core/exceptions/client.exception';
import {AdditionalData} from '../models/da-data/client-data/additional-data';
import {Address} from '../models/da-data/client-data/address';
import {ClientCommonInfo} from '../models/da-data/client-data/client-common-info/client-common-info';
import {OrganizationGovernmentRegistration} from '../models/da-data/client-data/organization-government-registration';
import {OrganizationStatistics} from '../models/da-data/client-data/organization-statistics';
import {Passport} from '../models/da-data/client-data/passport';
import {ResidencePermission} from '../models/da-data/client-data/residence-permission';
import {TaxResidencies} from '../models/da-data/client-data/tax-residencies/tax-residencies';

@Injectable({
  providedIn: 'root',
})
export class ClientApiService {
  private readonly optionalPlainToInstance = <T, V>(classConstructor: ClassConstructor<T>, data: V): T => {
    if (!data) {
      return undefined;
    }

    return plainToInstance(classConstructor, data);
  };

  constructor(private readonly connectionService: ConnectionService) {}

  public getCommonInfo(): Observable<ClientCommonInfo> {
    return this.connectionService.get('/api/v1/client/common_info').pipe(
      mapException,
      map((result: IExceptions) => plainToInstance(ClientCommonInfo, result.getData())),
    );
  }

  public getPassport(): Observable<Passport | undefined> {
    return this.connectionService.get('/api/v1/client/passport').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<Passport, unknown>(Passport, data);
      }),
    );
  }

  public getResidencePermission(): Observable<ResidencePermission | undefined> {
    return this.connectionService.get('/api/v1/client/residence_permission').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<ResidencePermission, unknown>(ResidencePermission, data);
      }),
    );
  }

  public getTaxResidencies(): Observable<TaxResidencies> {
    return this.connectionService.get('/api/v1/client/tax_residencies').pipe(
      mapException,
      map((result: IExceptions) => plainToInstance(TaxResidencies, {data: result.getData()})),
    );
  }

  public getAdditionalData(): Observable<AdditionalData> {
    return this.connectionService.get('/api/v1/client/additional_data').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<AdditionalData, unknown>(AdditionalData, data);
      }),
    );
  }

  public getOrganizationTaxResidencies(): Observable<TaxResidencies> {
    return this.connectionService.get('/api/v1/client/organization_tax_residencies').pipe(
      mapException,
      map((result: IExceptions) => plainToInstance(TaxResidencies, {data: result.getData()})),
    );
  }

  public getOrganizationGovernmentRegistration(): Observable<OrganizationGovernmentRegistration> {
    return this.connectionService.get('/api/v1/client/organization_gov_registration').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<OrganizationGovernmentRegistration, unknown>(
          OrganizationGovernmentRegistration,
          data,
        );
      }),
    );
  }

  public getOrganizationStatistics(): Observable<OrganizationStatistics> {
    return this.connectionService.get('/api/v1/client/organization_stat').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<OrganizationStatistics, unknown>(OrganizationStatistics, data);
      }),
    );
  }

  public getAddressRegistration(): Observable<Address | undefined> {
    return this.connectionService.get('/api/v1/client/address/registration').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<Address, unknown>(Address, data);
      }),
    );
  }

  public getAddressActual(): Observable<Address | undefined> {
    return this.connectionService.get('/api/v1/client/address/actual').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<Address, unknown>(Address, data);
      }),
    );
  }

  public getAddressMailing(): Observable<Address | undefined> {
    return this.connectionService.get('/api/v1/client/address/mailing').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<Address, unknown>(Address, data);
      }),
    );
  }

  public getOrganizationAddressRegistration(): Observable<Address | undefined> {
    return this.connectionService.get('/api/v1/client/organization_address/registration').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<Address, unknown>(Address, data);
      }),
    );
  }

  public getOrganizationAddressActual(): Observable<Address | undefined> {
    return this.connectionService.get('/api/v1/client/organization_address/actual').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<Address, unknown>(Address, data);
      }),
    );
  }

  public getOrganizationAddressMailing(): Observable<Address | undefined> {
    return this.connectionService.get('/api/v1/client/organization_address/mailing').pipe(
      mapException,
      map((result: IExceptions) => {
        const data = result.getData();

        return this.optionalPlainToInstance<Address, unknown>(Address, data);
      }),
    );
  }
}
