/* eslint-disable @typescript-eslint/naming-convention */
export enum EMenuPointCodeApi {
  /**
   * Root point(not used as a menu item).
   */
  General = 'general',

  /**
   * Menu points(used as a menu item).
   */
  Analytics = 'analytics',
  ApiKeyManagement = 'api_key_management',
  B2Copy = 'b2copy',
  B2CopyAccountList = 'b2copy_account_list',
  B2CopyLeaderboard = 'b2copy_leaderboard',
  B2Margin = 'b2margin',
  B2Trader = 'b2trader',
  Bonuses = 'bonuses',
  Dashboard = 'dashboard',
  Deposit = 'deposit',
  Exchange = 'exchange',
  EqwireAccounts = 'eqwire_accounts',
  EqwireBeneficiaries = 'eqwire_beneficiaries',
  EqwirePaymentsTransfers = 'eqwire_payments_and_transfers',
  Finance = 'finance',
  Funds = 'funds',
  Helpdesk = 'helpdesk',
  History = 'history',
  InternalTransfer = 'internal_transfer',
  MT4 = 'mt4',
  MT5 = 'mt5',
  CTrader = 'ctrader',
  DxTrade = 'dxtrade',
  TradeLocker = 'tradelocker',
  Mam = 'mam',
  MamAccountList = 'mam_account_list',
  MamLeaderboard = 'mam_leaderboard',
  NewIBRoom = 'new_ib_room',
  NewIBPartnerBoard = 'partner_board',
  NewIBPromo = 'promo',
  NewIBPromoLinks = 'promo_links',
  NewIBPromoBanners = 'promo_banners',
  NewIBReports = 'reports',
  NewIBReportsAccounts = 'reports_accounts',
  NewIBReportsAcquisitions = 'reports_acquisitions',
  NewIBReportsClientList = 'reports_client_list',
  NewIBReportsDeposits = 'reports_deposits',
  NewIBReportsRewards = 'reports_rewards',
  NewIBReportsTrades = 'reports_trades',
  NewIBReportsTransactions = 'reports_transactions',
  NewIBReportsWithdrawals = 'reports_withdrawals',
  PammNew = 'pamm_new',
  PammNewAccountList = 'pamm_account_list',
  PammNewLeaderboard = 'pamm_leaderboard',
  Pamm = 'pamm',
  Platforms = 'platforms',
  Profile = 'profile',
  PbsrEnrolmentAndTransfers = 'pbsr_funds',
  PbsrDeposit = 'pbsr_deposit',
  PbsrTransfer = 'pbsr_transfer',
  PbsrWithdraw = 'pbsr_withdraw',
  PbsrReportsAndCertificates = 'pbsr_reports_and_certificates',
  Security = 'security',
  Settings = 'settings',
  PbsrPersonalInfo = 'pbsr_personal_info',
  Transactions = 'transactions',
  Transfer = 'transfer',
  Verification = 'verification',
  Wallets = 'wallets',
  Withdraw = 'withdraw',
  Moex = 'moex',
  MarginalPlatform = 'oz_pxm',
  PbsrCatalog = 'pbsr_catalog',
  PbsrHelpAndFaq = 'pbsr_help_and_faq',
  PbsrTradeIdeas = 'pbsr_trade_ideas',
  PbsrNewsAndOverviews = 'pbsr_news_and_overviews',
  PbsrDocuments = 'pbsr_documents',
  PbsrTesting = 'pbsr_testing',
  PbsrBrokerageAccountEventsHistory = 'pbsr_brokerage_account_events_history',
  PbsrOperationsAndOrders = 'pbsr_operations_and_orders',
  PbsrBrokerageOrders = 'pbsr_brokerage_orders',
  Briefcase = 'briefcase',
  /**
   * Custom Points(they are not in the response with api, but they are needed to build a new dto).
   */
  PlatformsMT4 = 'platformsMT4',
  PlatformsMT5 = 'platformsMT5',
  PlatformsCTrader = 'platformsCTrader',
  PlatformsDxTrade = 'platformsDXTrade',
  PlatformsMarginal = 'platformsMarginal',
  AnalyticsMT4 = 'analyticsMT4',
  AnalyticsMT5 = 'analyticsMT5',
  AnalyticsCTrader = 'analyticsCTrader',
  MatchTrader = 'match_trader',
  PlatformsMatchTrader = 'platformsMatchTrader',
  PlatformsTradeLocker = 'platformsTradeLocker',
}
