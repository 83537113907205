import {Injectable} from '@angular/core';
import {IUserSettings} from '@app/trading-board/interfaces/b2margin/user-settings';

import {B2MarginDatafeedService} from '../../datafeed/b2margin-datafeed.service';
import {EEventHandlersReceived} from '../../event-handlers/event-handlers.enum';
import {AEventHandler} from '../event-handler.abstract';

@Injectable()
export class B2marginUserSettingsService extends AEventHandler<IUserSettings> {
  public type = EEventHandlersReceived.UserSettings;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(settings: IUserSettings): void {
    this.b2marginDatafeedService.userSettings = settings;
  }
}
