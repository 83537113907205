import {Injectable} from '@angular/core';
import {IAccountTradingData} from '@app/pbsr/interfaces/account-trading-data.interface';
import {EHistoricalOrderStatus} from '@app/trading-board/enum/moex/historical-order-status.enum';
import {EOrderStatus} from '@app/trading-board/enum/moex/order-status';
import {EOrderType} from '@app/trading-board/enum/moex/order-type';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {HistoricalOrder} from '@app/trading-board/models/moex/historical-order';
import {HistoricalTrade} from '@app/trading-board/models/moex/historical-trade';
import {WarpOrder} from '@app/trading-board/models/moex/order';
import {WarpTrade} from '@app/trading-board/models/moex/warp-trade';
import {DecimalHelper} from '@b2broker/decimal-helper';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

import {IWarpOrdersData} from '../../interfaces/warp-orders-data.interface';

@Injectable({providedIn: 'root'})
export class WarpOrdersDataMapperService {
  private readonly marketPriceTranslateKey = marker(
    'TradingBoard.Widgets.WarpOrders.Services.WarpOrdersDataMapperService.StopOrder.MarketPrice',
  );
  private readonly indefinitelyOrderTranslateKey = marker(
    'TradingBoard.Widgets.WarpOrders.Services.WarpOrdersDataMapperService.StopOrder.IndefinitelyOrder',
  );

  public warpOrderToOrdersData(
    order: WarpOrder,
    instrument: WarpInstrument | undefined,
    accountsTradingData: IAccountTradingData[],
  ): IWarpOrdersData {
    const isShowMarketLabel = order.type === EOrderType.Market && order.status !== EOrderStatus.Filled;
    const account = accountsTradingData.find(
      accountTradingData => accountTradingData.subAccountId === order.subAccountId,
    );
    const rawLotPrice = instrument ? this.getWarpOrderLotPrice(order, instrument) : undefined;
    const lotPrice = rawLotPrice ? rawLotPrice.toString() : '-';
    const price = rawLotPrice ? rawLotPrice.multiply(order.qty).toString() : '-';

    return {
      id: order.id,
      date: new Date(order.transTime).toString(),
      instrumentShortName: instrument?.shortName || '-',
      side: order.side,
      lotPrice,
      qtyPrice: order.price.toString(),
      qty: order.qty.toString(),
      qtyUnits: order.qtyUnits.toString(),
      parentAccountCaption: account.parentAccountCaption,
      parentAccountId: account.parentAccountId,
      subAccountCaption: account.subAccountCaption,
      subAccountId: account.subAccountId,
      status: order.status,
      price,
      cancelledQty: this.getCancelledQty(order),
      cancelledQtyUnits: this.getCancelledQtyUnits(order),
      symbol: order.symbol,
      isin: instrument?.isin || '-',
      type: order.type,
      tradeCode: account.tradeCode,
      isTrade: false,
      isStop: false,
      instrument,
      ...(isShowMarketLabel && {
        priceLabelTranslateKey: this.marketPriceTranslateKey,
      }),
    };
  }

  public warpStopOrderToOrdersData(
    order: WarpOrder,
    instrument: WarpInstrument | undefined,
    accountsTradingData: IAccountTradingData[],
  ): IWarpOrdersData {
    const account = accountsTradingData.find(
      accountTradingData => accountTradingData.subAccountId === order.subAccountId,
    );
    const isShowMarketLabel = order.type === EOrderType.Stop;
    const isIndefinitelyOrder = order.isIndefinitelyOrder;
    const lotPrice = instrument ? order.price.multiply(instrument.lotSize).toString() : '-';

    return {
      id: order.id,
      date: new Date(order.transTime).toString(),
      instrumentShortName: instrument?.shortName || '-',
      side: order.side,
      lotPrice,
      qtyPrice: order.price.toString(),
      qty: order.qty.toString(),
      qtyUnits: order.qtyUnits.toString(),
      cancelledQty: this.getCancelledQty(order),
      cancelledQtyUnits: this.getCancelledQtyUnits(order),
      parentAccountCaption: account.parentAccountCaption,
      parentAccountId: account.parentAccountId,
      subAccountCaption: account.subAccountCaption,
      subAccountId: account.subAccountId,
      status: order.status,
      price: order.price.multiply(order.qtyUnits).toString(),
      symbol: order.symbol,
      isin: instrument?.isin || '-',
      type: order.type,
      tradeCode: account.tradeCode,
      isTrade: false,
      isStop: true,
      stopPrice: order.stopPrice.toString(),
      condition: order.condition,
      stopOrderEndTime: order.endTime,
      isIndefinitelyOrder,
      exchangeOrderId: order.exchangeOrderId,
      instrument,
      ...(isShowMarketLabel && {
        priceLabelTranslateKey: this.marketPriceTranslateKey,
      }),
      ...(isIndefinitelyOrder && {
        stopOrderEndTimeLabelTranslateKey: this.indefinitelyOrderTranslateKey,
      }),
    };
  }

  public warpTradeToOrdersData(
    trade: WarpTrade,
    instrument: WarpInstrument | undefined,
    accountsTradingData: IAccountTradingData[],
  ): IWarpOrdersData {
    const account = accountsTradingData.find(
      accountTradingData => accountTradingData.subAccountId === trade.subAccountId,
    );
    const lotPrice = instrument ? trade.price.multiply(instrument.lotSize).toString() : '-';

    return {
      id: trade.id,
      orderId: trade.orderno,
      date: new Date(trade.date).toString(),
      instrumentShortName: instrument?.shortName || '-',
      side: trade.side,
      lotPrice,
      qtyPrice: trade.price.toString(),
      qty: trade.qty.toString(),
      qtyUnits: trade.qtyUnits.toString(),
      parentAccountCaption: trade.parentAccountCaption,
      parentAccountId: account.parentAccountId,
      subAccountCaption: trade.subAccountCaption,
      subAccountId: account.subAccountId,
      price: trade.price.multiply(trade.qtyUnits).toString(),
      symbol: trade.symbol,
      isin: instrument?.isin || '-',
      tradeCode: account.tradeCode,
      isTrade: true,
      isStop: false,
      commission: trade.commission?.toString(),
      instrument,
    };
  }

  public historicalOrderToOrdersData(
    order: HistoricalOrder,
    accountsTradingData: IAccountTradingData[],
  ): IWarpOrdersData {
    const account = accountsTradingData.find(
      accountTradingData => accountTradingData.subAccountId === order.subAccountCode,
    );

    return {
      id: order.orderNum.toString(),
      date: order.orderDatetime.toString(),
      instrumentShortName: order.assetName,
      side: order.side,
      lotPrice: order.asPrice.multiply(order.lotSize).toString(),
      qtyPrice: order.asPrice.toString(),
      qty: order.lotsQuantity.toString(),
      qtyUnits: order.quantity.toString(),
      parentAccountCaption: account.parentAccountCaption,
      parentAccountId: account.parentAccountId,
      subAccountId: account.subAccountId,
      subAccountCaption: account.subAccountCaption,
      status: this.getOrderStatusFromHistoricalOrder(order.historicalOrderStatus),
      price: order.volume.toString(),
      symbol: order.securityCode,
      isin: order.assetIsin,
      type: order.orderType,
      tradeCode: account.tradeCode,
      isTrade: false,
      isStop: false,
    };
  }

  public historicalTradeToOrdersData(
    trade: HistoricalTrade,
    accountsTradingData: IAccountTradingData[],
  ): IWarpOrdersData {
    const account = accountsTradingData.find(
      accountTradingData => accountTradingData.subAccountId === trade.subAccCode,
    );

    return {
      id: trade.tradeNum,
      orderId: trade.orderNum,
      date: new Date(trade.tradedAt).toString(),
      instrumentShortName: trade.assetName,
      side: trade.side,
      lotPrice: trade.asPrice.multiply(trade.lotSize).toString(),
      qtyPrice: trade.asPrice.toString(),
      qty: trade.lotQuantity.toString(),
      qtyUnits: trade.quantity.toString(),
      parentAccountCaption: account.parentAccountCaption,
      parentAccountId: account.parentAccountId,
      subAccountId: account.subAccountId,
      subAccountCaption: account.subAccountCaption,
      price: trade.volume,
      symbol: trade.securityCode,
      isin: trade.assetIsin,
      tradeCode: account.tradeCode,
      isTrade: true,
      isStop: false,
      commission: trade.commission?.value,
    };
  }

  private getOrderStatusFromHistoricalOrder(status: EHistoricalOrderStatus): EOrderStatus {
    switch (status) {
      case EHistoricalOrderStatus.Active:
      case EHistoricalOrderStatus.Exposed:
      case EHistoricalOrderStatus.Generated:
        return EOrderStatus.Working;
      case EHistoricalOrderStatus.Deniled:
        return EOrderStatus.Rejected;
      case EHistoricalOrderStatus.Executed:
        return EOrderStatus.Filled;
      case EHistoricalOrderStatus.Removed:
      case EHistoricalOrderStatus.RemovedByUser:
        return EOrderStatus.Canceled;
      default:
        throw Error(`Unexpected status ${status}`);
    }
  }

  private getCancelledQty(order: WarpOrder): string | undefined {
    if (!order.isCanceledOrRejected || !order.filled || order.filled.isZero()) {
      return undefined;
    }

    return order.qty.minus(order.filled).toString();
  }

  private getCancelledQtyUnits(order: WarpOrder): string | undefined {
    if (!order.isCanceledOrRejected || !order.filledQtyUnits || order.filledQtyUnits.isZero()) {
      return undefined;
    }

    return order.qtyUnits.minus(order.filledQtyUnits).toString();
  }

  private getWarpOrderLotPrice(order: WarpOrder, instrument: WarpInstrument): DecimalHelper {
    return order.type === EOrderType.Limit ? order.price.multiply(instrument.lotSize) : order.price;
  }
}
