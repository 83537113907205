import {BidiModule} from '@angular/cdk/bidi';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DirDirective} from '@app/shared/modules/direction/directives/dir.directive';
import {TranslateModule} from '@ngx-translate/core';

import {IfDirDirective} from './directives/if-dir.directive';

const components = [DirDirective, IfDirDirective];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, BidiModule, TranslateModule],
  exports: [...components],
})
export class DirectionModule {}
