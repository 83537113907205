import {CastTypes, Model} from '@app/core/model';

export class Type extends Model {
  public application;
  public id: number;
  public localizedCaption: string;
  public name: string;

  public get isCorporate(): boolean {
    return this.name === 'corporate';
  }

  public readonly casts = {
    application: [CastTypes.Any, CastTypes.Null],
    id: [CastTypes.Number],
    localizedCaption: [CastTypes.String],
    name: [CastTypes.String],
  };
}
