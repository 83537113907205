/* eslint-disable @typescript-eslint/naming-convention */
import {TErrorsMap} from '@app/core/form/services/errors/errors.service';
import {TDocumentToDownload} from '@app/verification/verification-steps/components/documents-list/types/document-to-download';

export interface FormField {
  label?: {
    value: string;
    raw?: boolean;
    hint?: IFormFieldHintLabel | null;
  };
  type?: FormFieldType;
  rules?: FormFieldRule[];
  initialValue?;
  name?: string;
  options?: FormFieldOption[];
  optionsType?: OptionsType;
  description?: string;
  fields?: Record<string, FormField>;
  hidden?: boolean;
  mask?: FormFieldMask;
  affectsTo?: string;
  customErrorMap?: TErrorsMap;
  attributes?: FormFieldAttribute[];
  data?;
  readonly?: boolean;
  fieldToWatch?: string;
  documents?: TDocumentToDownload[];
  /** ID of the dropdown-field on which the display of this field depends. */
  depends_on_field_id?: number;
  id?: number | string;
  /**
   * The 2 properties below were introduced in FDP-14325. The aim is to hide the current field, which depends on
   * the master field (dropdown or radio buttons) with id = related_field_id. The value in related_option_id is matched
   * with the value in the master field. If they are equal, then the current control becomes visible in the form.
   *
   * The logic is similar to depends_on_field_id, which was created to fulfil the Helpdesk requirements on dependent
   * dropdown lists. Theoretically, the task FDP-14325 could be done using the existing approach, but Hany (a backend developer)
   * decided to introduce the new fields to prevent possible conflicts with the Helpdesk logic, since the contexts
   * are different, and it was risky to mix the logic.
   *
   * However, it still looks as code to be refactored in the future when both approaches work stable.
   */
  related_field_id?: string | number;
  related_option_id?: number;
  /** Preselect single option in select field. In the future, the option should be implemented on server side. */
  preselect_single_option?: boolean;
}

export type FormFieldRule = [string, FormFieldRuleValue[]?];
export type FormFieldRuleValue = string | boolean | number;

export interface FormFieldAttribute {
  name: string;
  value: FormFieldRuleValue;
}

export interface FormFieldOption {
  name: string;
  value;
  disabled?: boolean;
  hint?: string;
  /** List of field "names" in current group which should be shown only if this option selected. */
  related_fields?: string[];
  /** List of field IDs in current group which should be shown only if this option selected. */
  related_fields_id?: Array<string | number>;
}

export interface FormFieldMask {
  key: string;
  value?: string;
}

export interface IFormFieldHintLabel {
  value: string;
  position?: EFormFieldHintLabelPosition;
}

export enum EFormFieldHintLabelPosition {
  start = 'start',
  end = 'end',
}

export interface OptionsType {
  type: string;
  endpoint: string;
}

export enum FormFieldType {
  boolean = 'boolean',
  date = 'date',
  select = 'select',
  multiSelect = 'multiSelect',
  input = 'input',
  text = 'text',
  radio = 'radio',
  radioChips = 'radioChips',
  radioButton = 'radioButton',
  file = 'file',
  fileButton = 'fileButton',
  code = 'code',
  passwordButton = 'passwordButton',
  question = 'question',
  captcha = 'captcha',
  password = 'password',
  phone = 'phone',
  toggle = 'toggle',
  selectAutocomplete = 'selectAutocomplete',
}
