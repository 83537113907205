import {Casts, CastTypes, Model} from '@app/core/model';
import {IB2copyAccountCreatedData} from '@app/core/models/account/investment/b2copy-account-created-data.interface';

import {HelpdeskData} from '../../helpdesk/models/helpdesk-data';
import {IFailedReportData} from './account/interfaces/failed-report-data.interface';
import {Report} from './account/report';
import {Announcement} from './announcement';
import {MobileAuthData} from './mobile-auth-data';

export class WebSocketResponse extends Model {
  public event: string;
  public date: string;
  public uuid?: string;
  public data?: HelpdeskData | Announcement | Report | MobileAuthData | IFailedReportData | IB2copyAccountCreatedData;

  public readonly casts: Casts = {
    event: [CastTypes.String],
    date: [CastTypes.Undefined, CastTypes.String],
    uuid: [CastTypes.Null, CastTypes.Undefined, CastTypes.String],
    // set cast types in order of decreasing strictness
    data: [CastTypes.Null, Announcement, HelpdeskData, Report],
  };
}
