import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {B2MarginOpenOrder} from '@app/trading-board/models/b2margin/b2-margin-open-order';

@Injectable()
export class B2marginOpenOrdersService extends AEventHandler<B2MarginOpenOrder[]> {
  public readonly type = EEventHandlersReceived.OpenOrders;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(orders: B2MarginOpenOrder[]): void {
    const ordersSortedByTime = orders.sort((a, b) => b.time.getTime() - a.time.getTime());
    this.b2marginDatafeedService.openOrders$.next(ordersSortedByTime);
  }
}
