/**
 * Override global locale for Number.
 *
 * This method is used as FIX for issues with ngx-mask:
 * https://b2btech.atlassian.net/browse/FDP-17555,
 * https://b2btech.atlassian.net/browse/FDP-17697.
 *
 * When pull request https://github.com/JsDaddy/ngx-mask/pull/1052 will be merged
 * and ngx-mask will be updated, this method should be removed.
 */
// TODO: remove this method in https://b2btech.atlassian.net/browse/FDP-17714
export const overrideNumberLocale = (): void => {
  const old = Number.prototype.toLocaleString;
  Number.prototype.toLocaleString = function (locale, options) {
    return old.call(this, 'en', options);
  };
};
