import {IResultApi} from '@app/core/contracts/i.result.api';
import {Exceptions} from '@app/core/exceptions/exceptions';

export class GoneException extends Exceptions {
  public static readonly STATUS_CODE = 410;

  constructor(body: IResultApi) {
    super(body);
  }
}
