import {Injectable} from '@angular/core';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {IServerTimeInfo} from '@app/trading-board/interfaces/b2trader/server-time-info';

@Injectable()
export class B2traderServerTimeService extends AEventHandler<IServerTimeInfo> {
  public readonly type = EB2TraderEventHandlersReceived.ServerTime;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: IServerTimeInfo): void {
    this.b2traderDatafeedService.serverTimeInfo$.next(resource);
  }
}
