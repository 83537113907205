<ng-container *ngIf="allLanguages$ | async as allLanguages">
  <mat-form-field [class.only-selected-text]="allLanguages.length <= 1" appDir class="language-select">
    <mat-select
      [ngModel]="selectedLanguage"
      [tabIndex]="allLanguages > 1 ? 0 : -1"
      disableOptionCentering
      panelClass="language-panel"
      (selectionChange)="setLang($event)"
    >
      <mat-option *ngFor="let language of allLanguages" [value]="language.lang">
        <div class="language-item" appDir>
          <app-img type="language" [iconName]="language?.lang" class="language-img"></app-img>
          <div>{{ language?.lang.toUpperCase() }}</div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
