export function blobToDataUrl(blob: Blob): Promise<string> {
  if (!blob.size) {
    return Promise.resolve('');
  }
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
  });
}
