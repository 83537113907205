import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import * as _ from 'lodash-es';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {EOnboardingVerificationStatus} from '../enum';
import {EMessageType} from '../enum/message-type.enum';
import {EOnboardingStatus} from '../enum/onboarding-status.enum';
import {ESynchronizationStatus} from '../enum/synchronization-status.enum';
import {
  IErrorItem,
  IErrorResponse,
  IOnboardingRequestQuery,
  IOnboardingRequestStatus,
  IOnboardingResponse,
} from '../interfaces';

@Injectable({providedIn: 'root'})
export class OnboardingService {
  private readonly baseUrl = '/api/v2/eqwire';

  constructor(private readonly connectionService: ConnectionService) {}

  public sendOnboardingsRequest(body: IOnboardingRequestQuery): Observable<IOnboardingResponse> {
    return this.connectionService
      .post<IErrorResponse | IOnboardingRequestQuery>(`${this.baseUrl}/customers`, body)
      .pipe(
        map((result: IOnboardingRequestQuery) => this.getOnboardingResponse(result)),
        catchError((result: IRawErrorResponse) => this.handleError(result)),
      );
  }

  public updateOnboardingsRequest(body: IOnboardingRequestQuery, id: string): Observable<IOnboardingResponse> {
    return this.connectionService
      .patch<IErrorResponse | IOnboardingRequestQuery>(`${this.baseUrl}/customers/${id}`, body)
      .pipe(
        map((result: IOnboardingRequestQuery) => this.getOnboardingResponse(result)),
        catchError((result: IRawErrorResponse) => this.handleError(result)),
      );
  }

  // TODO: remove Array<unknown> after API fix
  public getRequestStatus(): Observable<IOnboardingRequestStatus | undefined> {
    return this.connectionService
      .get<IOnboardingRequestStatus | Array<unknown>>(`${this.baseUrl}/customers`)
      .pipe(map(result => (_.isArray(result) ? undefined : result)));
  }

  public isOnboardingCompleted(): boolean {
    return ALocaleStorage.EQWIRE_ONBOARDING_REQUEST_STATUS.get() === EOnboardingStatus.Completed;
  }

  public getStatus(requestStatus: IOnboardingRequestStatus): EOnboardingStatus {
    if (_.isNil(requestStatus)) {
      return EOnboardingStatus.InProgress;
    }

    if (requestStatus.verificationStatus === EOnboardingVerificationStatus.Verified) {
      return EOnboardingStatus.Completed;
    }

    if (
      requestStatus.synchronizationStatus === ESynchronizationStatus.Process ||
      requestStatus.synchronizationStatus === ESynchronizationStatus.Errors
    ) {
      return EOnboardingStatus.InProgress;
    }

    return EOnboardingStatus.Pending;
  }

  private getOnboardingResponse(response: IOnboardingRequestQuery): IOnboardingResponse {
    return {type: EMessageType.Succeed, data: response};
  }

  private handleError(result: IRawErrorResponse): Observable<IOnboardingResponse> {
    const errorResponse: IErrorResponse = {
      status: result.status,
      message: result.error.error.message,
      details: result.error.error.details,
    };

    return of({type: EMessageType.Error, data: errorResponse});
  }
}

interface IRawErrorResponse {
  status: number;
  error: {
    error: {
      message: string;
      details: IErrorItem[];
    };
  };
}
