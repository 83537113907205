import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, OnDestroy, Optional, Renderer2, RendererFactory2} from '@angular/core';
import {ResponseException} from '@app/core/exceptions/response-exception';
import {ESaveKeys} from '@app/core/local-storage.worker';
import {User} from '@app/core/models/user/user';
import {AuthService} from '@app/core/services/auth.service';
import {UserService} from '@app/core/services/user.service';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {Environment} from '@env/environment.entities';
import {BehaviorSubject} from 'rxjs';
import {filter, map, pairwise} from 'rxjs/operators';

import {EThemeMode} from '../entities/theme-mode';
import {EThemingModule} from '../entities/theming-module';

@Injectable({providedIn: 'root'})
export class ThemeService implements OnDestroy {
  private readonly renderer: Renderer2;
  private readonly head: HTMLElement;
  private themeLink: HTMLElement;

  private readonly defaultCommonTheme = this.environment.projectInfo.defaultTheme;
  private readonly defaultAdvancedTheme = this.environment.widget?.defaultTheme;

  private readonly _currentTheme$ = new BehaviorSubject<EThemeMode>(this.environment.projectInfo.defaultTheme);
  public currentTheme$ = this._currentTheme$.asObservable();

  public readonly themeChange$ = this.currentTheme$.pipe(
    pairwise(),
    filter(([prev, curr]) => prev !== curr),
    map(([, curr]) => curr),
  );

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) document: Document,
    private readonly environment: Environment,
    private readonly authService: AuthService,
    @Optional() private readonly userService?: UserService,
  ) {
    this.head = document.head;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private loadCss(filename: string): void {
    if (!this.themeLink) {
      const linkEl = this.renderer.createElement('link') as HTMLElement;
      this.renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      this.renderer.setAttribute(linkEl, 'type', 'text/css');
      this.renderer.setAttribute(
        linkEl,
        'href',
        `/assets/styles/theme/${filename}?cacheOff=${this.environment.appBuild.cacheOff}`,
      );
      this.renderer.setAttribute(linkEl, 'class', 'theme');
      this.renderer.appendChild(this.head, linkEl);
      this.themeLink = linkEl;
    }
  }

  private removeCss(): void {
    if (this.themeLink) {
      this.renderer.removeChild(this.head, this.themeLink);
      this.themeLink = null;
    }
  }

  private toggleCss(theme: EThemeMode, module: EThemingModule): void {
    if (theme === this.getDefaultTheme(module)) {
      this.removeCss();
    } else {
      this.loadCss(`${this.environment.appBuild.themeMap[theme]}.css`);
    }
  }

  private getDefaultTheme(module = EThemingModule.Common): EThemeMode {
    return module === EThemingModule.Common ? this.defaultCommonTheme : this.defaultAdvancedTheme;
  }

  private useFromUserSettings(module: EThemingModule): void {
    void this.userService?.me().then(result => {
      if (result instanceof ResponseException) {
        const savedTheme = User.Make(result.getData()).settings[module];
        const theme = savedTheme || this.getDefaultTheme(module);

        this.saveTheme(theme, module);
        return;
      }
      this.setDefaultTheme(module);
    });
  }

  private useFromLocalStorage(module: EThemingModule): void {
    const savedTheme = ALocaleStorage.getItem(ESaveKeys[module]) as EThemeMode;
    const theme = savedTheme || this.getDefaultTheme(module);
    this.saveTheme(theme, module);
  }

  private saveTheme(theme: EThemeMode, module: EThemingModule): void {
    this._currentTheme$.next(theme);
    this.toggleCss(theme, module);
    if (this.authService.isLoggedIn()) {
      void this.userService?.settings({[module]: theme}).then();
      return;
    }
    ALocaleStorage.setItem(ESaveKeys[module], theme);
  }

  // TODO: remove ThemingModule after removing old AdvUI
  public setupTheme(module = EThemingModule.Common): void {
    if (this.authService.isLoggedIn()) {
      this.useFromUserSettings(module);
      return;
    }
    this.useFromLocalStorage(module);
  }

  public getTheme(): EThemeMode {
    return this._currentTheme$.value;
  }

  public toggleTheme(module = EThemingModule.Common): void {
    const nextTheme = this.getTheme() === EThemeMode.Dark ? EThemeMode.Light : EThemeMode.Dark;
    this.saveTheme(nextTheme, module);
  }

  public setDefaultTheme(module = EThemingModule.Common): void {
    const defaultTheme = module === EThemingModule.Common ? this.defaultCommonTheme : this.defaultAdvancedTheme;
    this._currentTheme$.next(defaultTheme);
    this.removeCss();
  }

  public ngOnDestroy(): void {
    ALocaleStorage.COMMON_THEME.remove();
    ALocaleStorage.ADVANCED_THEME.remove();
  }

  public isLight(): boolean {
    return this._currentTheme$.value === EThemeMode.Light;
  }
}
