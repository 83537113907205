import {marker} from '@biesbjerg/ngx-translate-extract-marker';

import {ETicketStatusName} from '../enum/ticket-status-name.enum';

export const statusNamesMap: Record<ETicketStatusName, string> = {
  [ETicketStatusName.Open]: marker('Ticket_Opened'),
  [ETicketStatusName.Closed]: marker('Ticket_Closed'),
  [ETicketStatusName.AwaitingReply]: marker('Ticket_AwaitingReply'),
  [ETicketStatusName.InProgress]: marker('Ticket_InProgress'),
  [ETicketStatusName.Pending]: marker('Ticket_Pending'),
  [ETicketStatusName.Resolved]: marker('Ticket_Resolved'),
  [ETicketStatusName.Duplicate]: marker('Ticket_Duplicate'),
  [ETicketStatusName.Declined]: marker('Ticket_Declined'),
  [ETicketStatusName.NeedToReply]: marker('Core.Models.Helpdesk.Ticket.TicketStatusTypes.NeedToReply'),
  [ETicketStatusName.NeedToProcess]: marker('Ticket_InProgress'),

  [ETicketStatusName.OpenRu]: marker('Ticket_Opened'),
  [ETicketStatusName.ClosedRu]: marker('Ticket_Closed'),
  [ETicketStatusName.AwaitingReplyRu]: marker('Ticket_AwaitingReply'),
  [ETicketStatusName.InProgressRu]: marker('Ticket_InProgress'),
  [ETicketStatusName.PendingRu]: marker('Ticket_Pending'),
  [ETicketStatusName.ResolvedRu]: marker('Ticket_Resolved'),
  [ETicketStatusName.DuplicateRu]: marker('Ticket_Duplicate'),
  [ETicketStatusName.DeclinedRu]: marker('Ticket_Declined'),
  [ETicketStatusName.NeedToReplyRu]: marker('Core.Models.Helpdesk.Ticket.TicketStatusTypes.NeedToReply'),
};
