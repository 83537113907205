import {Expose} from 'class-transformer';

export class Passport {
  @Expose()
  public type: string;

  @Expose()
  public series: string;

  @Expose()
  public number: string;

  @Expose({name: 'date_issue'})
  public dateIssue: string;

  @Expose({name: 'issued_by'})
  public issuedBy: string;

  @Expose({name: 'code_department'})
  public codeDepartment: string;

  @Expose({name: 'date_birth'})
  public dateBirth: string;

  @Expose({name: 'place_birth'})
  public placeBirth: string;

  @Expose({name: 'is_place_birth_in_usa'})
  public isPlaceBirthInUsa: boolean;

  @Expose({name: 'date_termination'})
  public dateTermination?: string;

  @Expose()
  public citizenship?: string;
}
