import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {InstrumentLimits} from '@app/trading-board/models/b2margin/instrument-limits';

@Injectable()
export class B2marginInstrumentLimitsService extends AEventHandler<InstrumentLimits[]> {
  public type = EEventHandlersReceived.InstrumentLimits;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: InstrumentLimits[]): void {
    const limits = this.b2marginDatafeedService.instrumentLimits$.getValue();

    const reduced = resource.reduce((acc, item) => acc.set(item.instrumentId, item), limits);

    this.b2marginDatafeedService.instrumentLimits$.next(reduced);
  }
}
