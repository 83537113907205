import {ConversionRateTO} from '@b2broker/b2margin-trade-models';
import {Expose, Transform} from 'class-transformer';

export class Rate {
  @Expose()
  @Transform(({obj}: {obj: ConversionRateTO}) => `${obj.from}/${obj.to}`)
  public symbolWithSeparator: string;

  @Expose()
  public value: number;
}
