import {Injectable} from '@angular/core';
import {FormField, FormFieldRule} from '@app/core/form/entities/form.field';
import {FormConfig} from '@app/core/form/entities/form.wrapper';

@Injectable()
export class ConfigFormService {
  public static getFields(config: FormConfig, pathString: string): FormField[] {
    if (!config || !pathString) {
      return;
    }

    const paths = pathString.split('.');
    let entries: FormField[] = [config];

    paths.forEach(path => {
      if (path === '*') {
        entries = entries.reduce<FormField[]>((r, i) => r.concat(Object.values(i.fields)), []);
      } else {
        entries = entries.reduce<FormField[]>((r, i) => {
          if (i.fields && i.fields[path]) {
            r.push(i.fields[path]);
          }
          return r;
        }, []);
      }
    });

    return entries;
  }
}

export function mergeRules(config: Partial<FormField>, rules: Record<string, FormFieldRule[]> = {}): FormConfig {
  Object.keys(rules).forEach(key => {
    const fields = ConfigFormService.getFields(config, key);
    fields.forEach(field => {
      if (!Array.isArray(rules[key])) {
        rules[key] = Object.values(rules[key]);
      }
      field.rules = [...rules[key], ...(field.rules || [])];
    });
  });
  return config;
}
