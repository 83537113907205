import {Injectable} from '@angular/core';
import {SocketErrorService} from '@app/pbsr/services/socket-error.service';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';

import {AEventHandler} from '../event-handler.abstract';
import {EEventHandlersReceived} from '../event-handlers.enum';

@Injectable()
export class MoexErrorHandlerService extends AEventHandler<string> {
  public readonly type = EEventHandlersReceived.Error;

  constructor(
    private readonly socketErrorService: SocketErrorService,
    private readonly moexDatafeedService: MoexDatafeedService,
  ) {
    super();
  }

  public handleMessage(): void {
    this.moexDatafeedService.terminate();
    this.socketErrorService.show();
  }
}
