import {HttpStatusCode} from '@angular/common/http';
import {isDevMode} from '@angular/core';
import {Environment} from '@env/environment.entities';
import {TranslateLoader} from '@ngx-translate/core';
import {forkJoin, Observable, of, ReplaySubject} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {catchError, map, shareReplay, switchMap, tap} from 'rxjs/operators';

export class TranslationLoader implements TranslateLoader {
  private static readonly FALLBACK_TRANSLATES_URL = '/assets/config/local-translates.json';
  private static readonly B2TRADER_STANDALONE_TRANSLATES_URL = '/assets/config/b2trader-standalone-translates.json';

  public static getFactory(): (environment: Environment) => TranslateLoader {
    return (environment: Environment): TranslateLoader => {
      return new TranslationLoader(environment);
    };
  }

  private readonly fallbackTranslations$ = of(isDevMode()).pipe(
    switchMap(isDev => (isDev ? this.getFallbackTranslates() : of({}))),
    shareReplay({refCount: true, bufferSize: 1}),
  );

  public readonly translates$ = new ReplaySubject<Record<string, string>>(1);

  constructor(private readonly environment: Environment) {}

  private getFallbackTranslates(): Observable<Record<string, string>> {
    return fromFetch<Record<string, string>>(TranslationLoader.FALLBACK_TRANSLATES_URL, {
      method: 'GET',
      selector: response => response.json(),
    }).pipe(catchError(() => of({})));
  }

  private getB2TraderStandaloneTranslates(): Observable<Record<string, string>> {
    return fromFetch<Record<string, string>>(TranslationLoader.B2TRADER_STANDALONE_TRANSLATES_URL, {
      method: 'GET',
      selector: (response: Response) => response.json(),
    }).pipe(catchError(() => of({})));
  }

  private getWeblateTranslates(lang: string): Observable<Record<string, string>> {
    const translatesUrl = `${this.environment.weblateApiUrl}/api/v1/keys?project_uuid=${this.environment.weblateProjectUuid}&lang=${lang}&story_name=${this.environment.weblateStory}`;

    return fromFetch(translatesUrl).pipe(
      switchMap(res => {
        if (res.status !== HttpStatusCode.Ok) {
          console.warn(`Can't load translates for ${lang} (code ${res.status})`);
          return of({});
        }
        return res.json();
      }),
    );
  }

  public getTranslation(lang: string): Observable<Record<string, string>> {
    const isB2TraderStandalone = this.environment.isB2traderPublicStandalone || this.environment.isB2TraderStandalone;

    const streams = isB2TraderStandalone
      ? [this.getB2TraderStandaloneTranslates(), this.fallbackTranslations$]
      : [this.getWeblateTranslates(lang), this.fallbackTranslations$];

    return forkJoin(streams).pipe(
      map(results => Object.assign({}, ...results) as Record<string, string>),
      tap(values => this.translates$.next(values)),
    );
  }
}

/**
 * Вспомогательная функция. Нужна для использования в перечислениях(enum), чтобы можно было пометить элементы
 * перечисления, как подлежащие добавлению в файл перевода, но, в тоже время, присвоить этим элементам числовые
 * индексы. На вход подается результат, возвращаемый функцией marker(данная функция нужна просто, чтобы пометить строку
 * для скрипта ngx-translate-extract, но по факту она возвращает переданную ей строку ничего с ней не делая) и числовой
 * индекс, который нужно присвоить элементу перечисления.
 *
 * @example
 * Пример использования
 * enum MyEnum = {
 *   enumElement = getIndex(marker('enumElement'), 1)
 * }
 * @param translate - Строка-ключ, которая будет помещена в файл перевода при проходе скрипта ngx-translate-extract.
 * Эта строка возвращается функцией marker.
 * @param value - Числовое значение, которое нужно вернуть в качестве индекса перечисления.
 * @returns Числовое значение.
 */
export function getIndex<T, T1>(translate: T, value: T1): T1 {
  return value;
}
