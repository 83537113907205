import {Directive, HostListener, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive({
  selector: '[appFocused]',
})
export class FocusedDirective implements OnDestroy {
  public isFocused = false;

  public focused$ = new Subject<boolean>();

  @HostListener('focus', ['true'])
  @HostListener('blur', ['false'])
  public onFocus(isFocused: boolean): void {
    this.isFocused = isFocused;
    this.focused$.next(isFocused);
  }

  public ngOnDestroy(): void {
    this.focused$.complete();
  }
}
