export enum EFormStep {
  ChoosingRegistrationType = 'choosingRegistrationType',
  SuccessfullyRequest = 'successfullyRequest',
  OnboardingInPending = 'onboardingInPending',
  BusinessInfo = 'businessInfo',
  RegisteredAddress = 'registeredAddress',
  TradingAddress = 'tradingAddress',
  Associates = 'associates',
  AboutInfo = 'aboutInfo',
  HomeAddress = 'homeAddress',
}
