import {ConnectionPositionPair, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {DEBOUNCE_TIME_IN_MS} from '@app/core/constants/common';
import {GridsterEventType, GridsterProviders} from '@app/core/models/gridster';
import {GridsterWidgetsEventsService} from '@app/gridster/events/gridster-widgets-events.service';
import {ThemeService} from '@app/shared/modules/theme/services/theme.service';
import {Instrument} from '@app/trading-board/models/instrument';
import {TradingBoardLoadingService} from '@app/trading-board/services/trading-board-loading.service';
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, take, takeUntil} from 'rxjs/operators';

import {ATradeDatafeed} from '../../datafeed/trade-datafeed.abstract';
import {SearchValueService} from './search-value.service';

@Component({
  selector: 'app-select-instrument',
  templateUrl: './select-instrument.component.html',
  styleUrls: ['./select-instrument.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchValueService],
})
export class SelectInstrumentComponent implements OnInit, OnDestroy, AfterViewInit {
  private static readonly CLOSE_LIST_TIMEOUT = 200;
  private readonly dialogSelectInstrumentSubscription: Subscription;
  private keepInstrumentSubscription: Subscription;
  private readonly symbol$ = new BehaviorSubject<string>(null);

  private readonly destroyer$ = new Subject<void>();
  private readonly overlayDestroyer$ = new Subject<void>();
  private readonly defaultSeparationSymbol = '/';

  private blurTimeout: number;

  private overlayRef: OverlayRef;

  @ViewChild('searchWrapper')
  private readonly searchWrapper: ElementRef<HTMLElement>;

  public searchValue = '';

  public instruments: Instrument[];
  public selectedInstrument?: Instrument;
  public isInstrumentsListOpened = false;

  @Input()
  public isKeepInstrument = true;

  @Input()
  public isVirtualScrollUsed = false;

  @Input()
  public isTickInfoShow = true;

  @Output()
  public instrumentSelect = new EventEmitter<Instrument>();

  @Input()
  public placeholder: string;

  @Input()
  public blackList: string[];

  @Input()
  public widthInPx: number | undefined = 160;

  @Input()
  public set pair(pair: string) {
    this.symbol$.next(pair);
  }

  /**
   * When true - selector gets new instruments from GridsterEventType.syncInstrument event (e.g. From favorites).
   */
  @Input()
  public isSyncInstruments = true;

  /**
   * When true - emit first instrument from the list, if specified pair is not found.
   */
  @Input()
  public useFirstIfNotFound = true;

  @Input()
  public isParentComponentModalDialog = false;

  @ViewChild('input')
  public input: ElementRef<HTMLInputElement>;

  public get arrowIconType(): string {
    return this.isInstrumentsListOpened ? 'arrowup-bold' : 'arrowdown-bold';
  }

  constructor(
    private readonly datafeed: ATradeDatafeed,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
    private readonly overlay: Overlay,
    private readonly searchValueService: SearchValueService,
    private readonly injector: Injector,
    private readonly gridsterWidgetsEventsService: GridsterWidgetsEventsService,
    private readonly themeService: ThemeService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly tradingBoardLoadingService: TradingBoardLoadingService,
  ) {}

  private searchInstrument(pair: string, instruments: Instrument[]): Instrument | undefined {
    switch (this.datafeed.provider) {
      case GridsterProviders.b2margin:
      case GridsterProviders.b2marginSpot:
        return instruments.find(i => i.symbolWithSeparator === pair);
      default:
        return instruments.find(
          i => (pair.includes(this.defaultSeparationSymbol) ? i.symbolWithSeparator : i.symbol) === pair,
        );
    }
  }

  private selectInstrument(i: Instrument): void {
    this.selectedInstrument = i;
    this.instrumentSelect.emit(i);
    this.searchValue = i?.symbolWithSeparator ?? '';
    this.closeList();
  }

  public ngAfterViewInit(): void {
    // NOTE(Maxim Alkeev) In case where the parent component is material dialog,
    // after its initialization the input field in that component is focused and opens an overlay containing a instruments sheet,
    // even in the case of avoiding the logic of creating an overlay
    // it will take a double click to open the tool sheet, to avoid this asynchronous blur and overlay removal was added.
    // We need to study this behavior in more detail to make a more obvious fix.
    if (this.isParentComponentModalDialog) {
      setTimeout(() => {
        this.overlayRef?.dispose();
      }, SelectInstrumentComponent.CLOSE_LIST_TIMEOUT);
    }
  }

  public ngOnInit(): void {
    combineLatest([
      this.datafeed.instruments$.pipe(filter(v => !!v?.length)),
      this.symbol$.pipe(debounceTime(DEBOUNCE_TIME_IN_MS), distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.destroyer$))
      .subscribe(([instruments, pair]) => {
        this.instruments = instruments;

        if (!pair && this.useFirstIfNotFound) {
          this.selectInstrument(instruments[0]);
        }

        if (pair) {
          const instrument = this.searchInstrument(pair, instruments);

          if (instrument) {
            this.selectInstrument(instrument);
          } else if (this.useFirstIfNotFound && instruments.length) {
            this.selectInstrument(instruments[0]);
          }
        }

        this.keepInstrumentSubscription?.unsubscribe();

        if (this.isKeepInstrument && this.isSyncInstruments) {
          this.keepInstrumentSubscription = this.gridsterWidgetsEventsService
            .getEvent({type: GridsterEventType.syncInstrument})
            .pipe(takeUntil(this.destroyer$))
            .subscribe((id: string) => {
              this.selectInstrument(
                this.instruments?.find(instrument => instrument.id === id || instrument.symbolWithSeparator === id),
              );
              this.cdr.detectChanges();
            });
        }

        this.cdr.detectChanges();
      });
  }

  public setSearchValue(value: string): void {
    this.searchValueService.setValue(value);
  }

  public closeList(): void {
    this.isInstrumentsListOpened = false;

    this.zone.runOutsideAngular(() => {
      this.blurTimeout = window.setTimeout(() => {
        if (this.isKeepInstrument) {
          const selectedSymbol = this.selectedInstrument && this.selectedInstrument.symbolWithSeparator;
          if (this.searchValue !== selectedSymbol) {
            this.searchValue = selectedSymbol;
          }
        } else {
          this.searchValue = '';
        }
        this.cdr.detectChanges();
      }, SelectInstrumentComponent.CLOSE_LIST_TIMEOUT);
    });
  }

  public async openInstrumentList(): Promise<void> {
    this.overlayRef?.dispose();
    this.overlayDestroyer$.next();

    this.searchValueService.setValue(this.searchValue);
    const {ListComponent} = await import('./list/list.component');

    const positions = [
      new ConnectionPositionPair({originX: 'start', originY: 'bottom'}, {overlayX: 'start', overlayY: 'top'}),
      new ConnectionPositionPair({originX: 'start', originY: 'top'}, {overlayX: 'start', overlayY: 'bottom'}),
    ];

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.searchWrapper).withPositions(positions),
      width: this.searchWrapper.nativeElement.offsetWidth,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: this.themeService.getTheme(),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    const componentPortal = new ComponentPortal(ListComponent, this.viewContainerRef, this.injector);

    const componentRef = this.overlayRef.attach(componentPortal);

    componentRef.instance.isVirtualScrollUsed = this.isVirtualScrollUsed;
    componentRef.instance.isTickInfoShow = this.isTickInfoShow;
    componentRef.instance.selectedInstrument$.pipe(takeUntil(this.overlayDestroyer$)).subscribe((i: Instrument) => {
      this.selectInstrument(i);
      this.overlayRef.dispose();
    });

    componentRef.instance.instruments = this.instruments;
    componentRef.instance.blackList = this.blackList;
    componentRef.changeDetectorRef.detectChanges();

    this.isInstrumentsListOpened = true;

    // Close selection when show loading indicator
    this.tradingBoardLoadingService.displayed$.pipe(take(1), takeUntil(this.overlayDestroyer$)).subscribe(() => {
      this.overlayRef?.dispose();
      componentRef.destroy();
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.overlayDestroyer$))
      .subscribe(() => {
        this.closeList();
        componentRef.destroy();
        this.overlayRef.dispose();
      });
  }

  public ngOnDestroy(): void {
    this.overlayDestroyer$.next();
    this.overlayDestroyer$.complete();
    this.destroyer$.next();
    this.destroyer$.complete();
    this.overlayRef?.dispose();
    this.symbol$.complete();
    window.clearTimeout(this.blurTimeout);
    this.keepInstrumentSubscription?.unsubscribe();
    this.dialogSelectInstrumentSubscription?.unsubscribe();
  }
}
