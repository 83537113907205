import {B2MarginInstrument} from '@app/trading-board/models/instrument';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {HistoryTO, OrderTemplateTO, TradeHistoryTO} from '@b2broker/b2margin-trade-models';
import {Transform, Type} from 'class-transformer';

import {EUpDownState} from '../../../up-down/up-down-state.enum';
import {ITradeHistory} from '../../interfaces/b2margin/trade-history';
import {DecimalHelper} from '../../models/decimal-helper';

import OrderSideEnum = OrderTemplateTO.OrderSideEnum;
import PositionEffectEnum = TradeHistoryTO.PositionEffectEnum;
import SideEnum = HistoryTO.SideEnum;

export class TradeHistory {
  public static fromB2Margin(
    items: B2MarginModels.TradeHistoryTO[],
    instruments: Map<string, B2MarginInstrument>,
  ): ITradeHistory[] {
    return items
      .sort((a, b) => Number(b.time) - Number(a.time))
      .map(i => {
        const lotSize = instruments.get(i.symbol)?.lotSize ?? 1;

        return {
          commission: i.commission,
          positionEffect:
            i.positionEffect === PositionEffectEnum.OPENING ? PositionEffectEnum.OPENING : PositionEffectEnum.CLOSING,
          settledPl: i.settledPL,
          side: i.tradeSide === OrderSideEnum.BUY ? OrderSideEnum.BUY : OrderSideEnum.SELL,
          symbol: i.symbol,
          time: new Date(i.time),
          tradePrice: i.fillPrice,
          tradeQuantity: i.quantity,
          orderId: i.orderChainId,
          lotSize,
          size: DecimalHelper.from(i.quantity).divide(lotSize).toNumber(),
        };
      });
  }

  public orderId: number;

  public side: string;

  public symbol: string;

  public positionEffect: PositionEffectEnum;

  @Transform(({value}) => (value ? DecimalHelper.from(value).abs() : null))
  public commission: DecimalHelper | null;

  @Transform(({value}) => (Number.isNaN(Number(value)) ? null : DecimalHelper.from(value)))
  public settledPl: DecimalHelper | null;

  @Type(() => Date)
  public time: Date;

  @Transform(({value}) => DecimalHelper.from(value).abs())
  public tradePrice: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value).abs())
  public tradeQuantity: DecimalHelper;

  @Transform(({obj}: {obj: ITradeHistory}) => DecimalHelper.from(obj.tradeQuantity).divide(obj.lotSize).abs())
  public size: DecimalHelper;

  public isBuy(): boolean {
    return this.side === SideEnum.BUY;
  }

  public getSettledPlState(): EUpDownState {
    if (this.settledPl?.greaterThanZero()) {
      return EUpDownState.Up;
    }

    if (this.settledPl?.lessThanZero()) {
      return EUpDownState.Down;
    }

    return EUpDownState.Default;
  }
}
