import {Type} from 'class-transformer';

export class CustomMarginRates {
  public minRate?: number;
  public maxRate?: number;
  public rateIncrement?: number;
  public defaultRate?: number;
}

export class MarginRate {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public traderSelectedMarginRatesUsed: boolean;

  @Type(() => CustomMarginRates)
  public customMarginRates: CustomMarginRates;

  public symbol: string;
  public accountId: number;
}
