import {Injectable, Injector, OnDestroy} from '@angular/core';
import {AccountService} from '@app/core/services/account.service';
import {Environment} from '@env/environment.entities';
import {fromEvent, NEVER, Observable, ReplaySubject, Subject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';

import {EConnectionLevel} from '../enum/connection-level';
import {EWorkerEventTypes} from '../enum/worker-event-types';
import {AEventHandler} from '../event-handlers/event-handler.abstract';
import {EventHandlersToken} from '../event-handlers/event-handlers-token';
import {ADatafeedFacade} from '../facades/datafeed-facade.abstract';
import {DatafeedFacadeToken} from '../facades/facade-token';
import {IApiOptions, IConnectionOptions} from '../interfaces/connection-params';
import {IDataEvent} from '../interfaces/data-event.ts';
import {TWorkerEvent} from '../types/worker-event';

@Injectable()
export class WorkerConnectionService implements OnDestroy {
  private readonly destroyer$ = new Subject<void>();
  private readonly messagesInitialized$ = new ReplaySubject<boolean>(1);
  private internalMessages$: Observable<IDataEvent>;

  protected sharedWorker?: SharedWorker;
  protected worker?: Worker;
  protected dataFeedFacade?: ADatafeedFacade;

  public readonly messages$ = this.messagesInitialized$.pipe(
    switchMap(isInitialized => (isInitialized ? this.internalMessages$ : NEVER)),
  );

  constructor(private readonly injector: Injector, private readonly environment: Environment) {}

  public initialize({level, sharedWorkerPath, webWorkerPath}: IConnectionOptions): void {
    switch (level) {
      case EConnectionLevel.Shared:
        if (typeof SharedWorker !== 'undefined') {
          this.sharedWorker = new SharedWorker(sharedWorkerPath, {type: 'module'});
          this.internalMessages$ = fromEvent(this.sharedWorker, 'message').pipe(
            map(({data}: TWorkerEvent) => data as IDataEvent),
            takeUntil(this.destroyer$),
          );

          this.sharedWorker.port.start();

          this.sharedWorker.onerror = () => {
            this.terminate();
            this.initialize({level: EConnectionLevel.Web, sharedWorkerPath, webWorkerPath});
          };
        } else {
          this.initialize({level: EConnectionLevel.Web, webWorkerPath, sharedWorkerPath});
        }

        break;
      case EConnectionLevel.Web:
        this.worker = new Worker(webWorkerPath, {type: 'module'});
        this.internalMessages$ = fromEvent(this.sharedWorker, 'message').pipe(
          map(({data}: TWorkerEvent) => data as IDataEvent),
          takeUntil(this.destroyer$),
        );

        this.worker.onerror = () => {
          this.terminate();
          this.initialize({level: EConnectionLevel.Main, sharedWorkerPath, webWorkerPath});
        };

        break;

      default:
        this.dataFeedFacade = this.injector.get<ADatafeedFacade>(DatafeedFacadeToken);
        this.internalMessages$ = this.dataFeedFacade.messages$.pipe(takeUntil(this.destroyer$));
    }

    this.messagesInitialized$.next(true);
    this.messages$.pipe(takeUntil(this.destroyer$)).subscribe(({type, payload}) => {
      const eventHandlers = this.injector.get<AEventHandler<unknown>[]>(EventHandlersToken);

      eventHandlers.forEach(handler => {
        if (handler.type !== type) {
          return;
        }

        handler.handleMessage(payload);
      });
    });

    this.sendMessage<IApiOptions>({
      type: EWorkerEventTypes.Connect,
      payload: {
        apiUrl: this.environment.apiUrl,
        isPublic: this.environment.isPublic,
        isStandalone: this.environment.isB2TraderStandalone,
        accountService: this.injector.get(AccountService),
      },
    });
  }

  public sendMessage<T = unknown>(message: IDataEvent<T>): void {
    this.sharedWorker?.port.postMessage(message);
    this.worker?.postMessage(message);
    this.dataFeedFacade?.postMessage(message);
  }

  public terminate(): void {
    this.destroyer$.next();
    this.worker?.terminate();
    this.sharedWorker?.port.close();
    this.dataFeedFacade?.close();
  }

  public ngOnDestroy(): void {
    this.terminate();
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
