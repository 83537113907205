import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {GoneException} from '@app/core/exceptions/gone-exception';
import {InternalServerErrorException} from '@app/core/exceptions/internal-server-error-exception';
import {TooManyRequestsException} from '@app/core/exceptions/too-many-requests-exception';
import {ValidateException} from '@app/core/exceptions/validate-exception';
import {FormWrapper} from '@app/core/form/entities/form.wrapper';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {IManagerComponent} from '@app/wizard/contracts/i.wizard.component';
import {ManagerComponent} from '@app/wizard/manager.component';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime, filter, takeUntil, tap} from 'rxjs/operators';

/**
 * Class for wizard with confirm code form.
 *
 * @abstract
 * @param {boolean} isShowSuccess - Use it to show animated message after success sending.
 * @example
 * <app-success-confirm-message *ngIf="isShowSuccess"></app-success-confirm-message>
 * @param {(string | number)} confirmCode - Use it to autocomplete code form.
 * @example
 * protected confirmCode = this.activatedRoute.snapshot.params.code;
 * @param {(boolean)} hasAutomaticallySubmit - Use it to subscribe on form.statusChanges and call onSubmit() automatically.
 * @param {boolean} isTooManyRequests - If = true - resending code is blocked and warn message is visible.
 * @param {number} expiredTimer - Value for resending code timer.
 * @example
 * <app-code-resend
 *      [expiredTimer]="expiredTimer"
 *      [isManyRequests]="isTooManyRequests"
 * ></app-code-resend>
 */
export abstract class AWizardWithCodeForm implements IManagerComponent {
  // If we send requests more often, we will get a blank response
  private static readonly SEND_CODE_DEBOUNCE_TIME = 1600;
  private isReadyToSendCode = true;

  protected abstract destroyer$: Subject<void>;
  protected confirmCode?: number | string;
  protected hasAutomaticallySubmit = false;

  public manager!: ManagerComponent;
  public form?: UntypedFormGroup;

  public isLoading = false;
  public isShowSuccess = false;
  public isTooManyRequests = false;
  public readonly hasAnimation: boolean = true;

  public apiErrors: Record<string, string[]>;

  public expiredTimer: number;

  protected constructor(
    protected readonly snackbar: SnackbarService,
    protected readonly translate: TranslateService,
    protected readonly router: Router,
  ) {}

  public formInitiated(wrapper: FormWrapper): void {
    this.form = wrapper.form;
    const codeControl = this.form.get('code');

    if (!codeControl) {
      return;
    }

    if (this.hasAutomaticallySubmit) {
      this.form.statusChanges
        .pipe(
          tap(() => (this.isReadyToSendCode = false)),
          filter(() => !this.isLoading && !this.isShowSuccess && this.form.valid),
          debounceTime(AWizardWithCodeForm.SEND_CODE_DEBOUNCE_TIME),
          tap(() => {
            this.isReadyToSendCode = true;
            this.isLoading = true;
          }),
          takeUntil(this.destroyer$),
        )
        .subscribe(() => {
          this.onSubmit(this.form.getRawValue(), this.hasAnimation);
        });
    }

    if (this.confirmCode) {
      codeControl.setValue(String(this.confirmCode));
      codeControl.markAsTouched();
    }
  }

  public onSubmit(formValue: Record<string, string>, hasAnimation = true): void {
    if (this.hasAutomaticallySubmit && !this.isReadyToSendCode) {
      return;
    }

    const resultValue = {action: 'code', ...formValue};

    void this.manager.send(resultValue, {hasAnimation}).then(result => {
      this.checkResponseResult(result, hasAnimation);
    });
  }

  protected checkResponseResult(result, hasAnimation): void {
    this.isLoading = false;

    if (result instanceof InternalServerErrorException) {
      const errorMessage = result.getError().localizedMessage || marker('Something went wrong, please try again later');

      this.snackbar.error(this.translate.instant(errorMessage));
      this.manager.currentComponentRef.destroy();
      return;
    }

    if (result instanceof ValidateException) {
      this.apiErrors = result.getErrors();
      return;
    }

    if (result instanceof TooManyRequestsException) {
      this.onBlock(result.getData().expired_at);
      return;
    }

    if (result instanceof GoneException) {
      const errorMessage = marker('Wizard.CodeConfirmation.Error.TooManyTries');
      this.snackbar.error(this.translate.instant(errorMessage));

      void this.router.navigateByUrl('/');

      return;
    }

    this.isShowSuccess = hasAnimation;
  }

  public resendCode(): void {
    this.isReadyToSendCode = true;

    this.onSubmit({action: 'send'}, false);
  }

  public onBlock(expiredAt: string): void {
    this.expiredTimer = Math.ceil((new Date(expiredAt).getTime() - new Date().getTime()) / 1000);
    this.isTooManyRequests = true;
  }

  public onBlockEnd(): void {
    this.isTooManyRequests = false;
  }
}
