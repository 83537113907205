/* eslint-disable @typescript-eslint/naming-convention */
import {RulesWidget} from '@app/core/models/gridster/rules-widget';
import {TGridsterRules} from '@app/core/models/gridster/types';
import {ECommonWidgetKeys} from '@app/gridster/widgets/gridster-widgets';
import {IGridsterOption} from '@app/trading-board/components/trading-board-settings/trading-board-settings.component';
import {DisplayGrid} from 'angular-gridster2';
import {GridType} from 'angular-gridster2/lib/gridsterConfig.interface';
import {plainToClass, Transform} from 'class-transformer';

export interface IGridsterGeneralConfig {
  draggable: boolean;
  resizable: boolean;
  minCols?: number;
  maxCols?: number;
  minRows?: number;
  maxRows?: number;
  margin?: number;
  outerMarginBottom?: number;
  outerMargin?: boolean;
  layoutsMaxCount: number;
  debounceUpdateSettings?: number;
  gridType?: GridType;
  fixedColWidth?: number;
  fixedRowHeight?: number;
  keepFixedHeightInMobile?: boolean;
  setGridSize?: boolean;
  mobileBreakpoint?: number;
  swap: boolean;
  pushItems: boolean;
  displayGrid: DisplayGrid;
  adminWidgetRules?: boolean;
  componentsWithoutHeader: ECommonWidgetKeys[];
  gridOptions?: IGridsterOption[];
  marginOptions?: IGridsterOption[];
  pushResizeItems?: boolean;
  disablePushOnResize?: boolean;
  ignoreMarginInRow?: boolean;
  nameForNewSpace?: string;
  useTransformPositioning?: boolean;
  isIntroductionTourExists?: boolean;
  isChangeTimeFormatEnabled?: boolean;
}

export class GridsterGeneralConfig implements IGridsterGeneralConfig {
  public static defaultNameForNewSpace = 'Space';

  private static transformRulesWidgets(value: TGridsterRules): TGridsterRules {
    return Object.keys(value).reduce((acc: TGridsterRules, key: string) => {
      acc[key] = plainToClass(RulesWidget, value[key]);

      return acc;
    }, {});
  }

  public draggable: boolean;
  public resizable: boolean;
  public minCols?: number;
  public maxCols?: number;
  public minRows?: number;
  public maxRows?: number;
  public margin?: number;
  public outerMarginBottom?: number;
  public outerMargin?: boolean;
  public layoutsMaxCount: number;
  public debounceUpdateSettings?: number;
  @Transform(({value}) => GridsterGeneralConfig.transformRulesWidgets(value))
  public rulesWidgets: TGridsterRules;
  public gridType?: GridType;
  public fixedColWidth?: number;
  public fixedRowHeight?: number;
  public keepFixedHeightInMobile?: boolean;
  public setGridSize?: boolean;
  public mobileBreakpoint?: number;
  public swap: boolean;
  public pushItems: boolean;
  public displayGrid: DisplayGrid;
  public adminWidgetRules?: boolean;
  public componentsWithoutHeader: ECommonWidgetKeys[] = [];
  public pushResizeItems?: boolean;
  public disablePushOnResize?: boolean;
  public ignoreMarginInRow?: boolean;
  public useTransformPositioning?: boolean;
  public nameForNewSpace? = GridsterGeneralConfig.defaultNameForNewSpace;
  public isIntroductionTourExists?: boolean;
  public isChangeTimeFormatEnabled?: boolean;
}
