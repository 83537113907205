import {Environment} from '@env/environment.entities';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Public(mock: any): (target: any, propertyName: string, propertyDescriptor: PropertyDescriptor) => void {
  return (target: any, propertyName: string, propertyDescriptor: PropertyDescriptor) => {
    const method = propertyDescriptor.value;

    propertyDescriptor.value = function (...args: any[]): void {
      return Environment.getSelf().isPublic ? mock : method.apply(this, args);
    };
  };
}
