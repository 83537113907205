export function snakeCaseToCamelCase(str: string): string {
  return str.replace(/_[a-z]/g, letter => letter[1].toUpperCase());
}

export function camelCaseToSnakeCase(str: string): string {
  return str
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .join('_')
    .toLowerCase();
}
