/* eslint-disable @typescript-eslint/naming-convention */
export abstract class Model {
  public abstract casts: Casts;

  public static Make<T extends Model>(this: new () => T, data: T): T {
    return new this().make(data);
  }

  private static Reflect(model): Model {
    return Reflect.construct(model, []);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static ResolveStringCast(cast: CastTypes, value: any): any {
    if (cast === CastTypes.Null || cast === CastTypes.Undefined) {
      if (value || value === 0) {
        throw Error('field is not empty');
      }
      return value;
    }

    if (cast === CastTypes.String) {
      if (typeof value === 'string') {
        return value;
      } else {
        throw Error('wrong value');
      }
    }

    if (cast === CastTypes.Number) {
      if (typeof value === 'number') {
        return value;
      } else {
        throw Error('wrong value');
      }
    }

    if (cast === CastTypes.Boolean) {
      if (typeof value === 'boolean') {
        return value;
      } else {
        throw Error('wrong value');
      }
    }

    if ((cast === CastTypes.Object || cast === CastTypes.Enum) && typeof value === 'object') {
      return value;
    }

    if (cast === CastTypes.Any) {
      return value;
    }

    throw Error('no one match');
  }

  private make(data: this): this {
    Object.keys(this.casts).forEach((key: string) => {
      for (let i = 0; i < this.casts[key].length; i++) {
        const cast = this.casts[key][i];

        try {
          let value = data[key];

          if (typeof cast === 'string') {
            this[key] = Model.ResolveStringCast(cast, value);
            break;
          }

          if (typeof cast === 'function') {
            this[key] = Model.Reflect(cast).make(value);
            break;
          }

          if (Array.isArray(cast) && !Array.isArray(cast[0])) {
            if (!Array.isArray(value)) {
              value = value ? Object.values(value) : value;
            }

            const model: CastTypes | typeof Model = cast[0];

            if ('function' === typeof model) {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              this[key] = (value as Array<Model>).map((item, i) => {
                try {
                  return Model.Reflect(model).make(item);
                } catch (e) {
                  e.message = i + ': ' + e.message;
                  throw e;
                }
              });
            } else {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              this[key] = (value as Array<CastTypes>).map((item, i) => {
                try {
                  return Model.ResolveStringCast(model, item);
                } catch (e) {
                  e.message = i + ': ' + e.message;
                  throw e;
                }
              });
            }

            break;
          }

          if (Array.isArray(cast) && Array.isArray(cast[0])) {
            const model: CastTypes | typeof Model = cast[0][0];

            if ('function' === typeof model) {
              this[key] = (value as Array<Array<Model>>).map((arr: Model[], fi: number) =>
                arr.map((item: Model, si: number) => {
                  try {
                    return Model.Reflect(model).make(item);
                  } catch (e) {
                    e.message = `[${fi}][${si}]: ${e.message}`;
                    throw e;
                  }
                }),
              );
            } else {
              this[key] = (value as Array<Array<CastTypes>>).map((arr: CastTypes[], fi: number) =>
                arr.map((item: CastTypes, si: number) => {
                  try {
                    return Model.ResolveStringCast(model, item);
                  } catch (e) {
                    e.message = `[${fi}][${si}]: ${e.message}`;
                    throw e;
                  }
                }),
              );
            }

            break;
          }

          if (typeof cast === 'object') {
            const fieldModels: typeof Model[] = Object.values(cast);
            this[key] = {};
            Object.keys(value).forEach((ikey: string): void => {
              for (let j = 0; j < fieldModels.length; j++) {
                const model: typeof Model = fieldModels[j];
                try {
                  this[key][ikey] = Model.Reflect(model).make(value[ikey]);
                  break;
                } catch (e) {
                  if (j === fieldModels.length - 1) {
                    throw Error('no one match');
                  }
                }
              }
            });
            break;
          }

          throw Error('no one match');
        } catch (e) {
          if (!(i < this.casts[key].length - 1)) {
            e.message = key + ': ' + e.message;
            throw e;
          }
        }
      }
    });

    delete this.casts;
    return this;
  }
}

export enum CastTypes {
  Object = 'object',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Null = 'null',
  Any = 'any',
  Enum = 'enum',
  Undefined = 'undefined',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Casts<T = any> = Record<Exclude<keyof T, 'casts' | 'make'>, CastItem[]>;

export type CastItem =
  | CastTypes
  | CastTypes[]
  | CastTypes[][]
  | typeof Model
  | typeof Model[]
  | typeof Model[][]
  | Record<string, typeof Model>;

export default Model;
