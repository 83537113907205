import {THttpParams} from '@app/core/types/types';
import {IBackofficeOperationsRequestParams} from '@app/pbsr/interfaces/backoffice-operations-request-params.interface';

export class BackofficeQueryParamsHelper {
  private static readonly DEFAULT_PAGE_NUMBER = 1;
  private static readonly DEFAULT_PAGE_SIZE = 30;

  public static getParams(params: IBackofficeOperationsRequestParams): THttpParams | undefined {
    if (!params.options) {
      return;
    }

    const options = params.options;
    const isRequestWithoutPagination = params.isRequestWithoutPagination;

    const pageNumber = options.pageNumber ?? this.DEFAULT_PAGE_NUMBER;
    const pageSize = options.pageSize ?? this.DEFAULT_PAGE_SIZE;

    const filterOrderDateTo = options.filterOrderDateTo;
    const filterTradeDateTo = options.filterTradeDateTo;

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      ...(isRequestWithoutPagination ? {} : {'page[number]': pageNumber}),
      ...(isRequestWithoutPagination ? {} : {'page[size]': pageSize}),

      ...(filterOrderDateTo ? {'filter[order_date_to]': filterOrderDateTo} : {}),
      ...(filterTradeDateTo ? {'filter[trade_date_to]': filterTradeDateTo} : {}),
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}
