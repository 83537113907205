import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: 'img[srcFallback]',
})
export class FallbackImageDirective {
  @Input() public srcFallback: string;

  constructor(private readonly renderer: Renderer2, private readonly el: ElementRef) {}

  @HostListener('error')
  public onError(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.srcFallback);
  }
}
