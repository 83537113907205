import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {TranslateService} from '@ngx-translate/core';

import {AEventHandler} from '../event-handler.abstract';
import {EEventHandlersReceived} from '../event-handlers.enum';

@Injectable()
export class B2MarginErrorHandlerService extends AEventHandler<Error> {
  public type = EEventHandlersReceived.Error;

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly snackBarService: SnackbarService,
    private readonly b2marginDatafeedService: B2MarginDatafeedService,
  ) {
    super();
  }

  public handleMessage(error: Error): void {
    console.warn(error);

    this.b2marginDatafeedService.terminate();
    this.snackBarService.error(this.translate.get('Widget unavailable now'));
    void this.router.navigateByUrl('/');
  }
}
