import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormField} from '@app/core/form/entities/form.field';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {TUndefinable} from '@app/core/types/types';
import {IAutocompleteItem} from '@app/pbsr/interfaces/autocomplete-item.interface';
import {ITooltipLayout} from '@app/pbsr/shared/tariff-tooltip/tooltip-layout.interface';
import {TAutocompleteValueRenderFn} from '@app/pbsr/types/autocomplete-value-render-function.type';
import {Observable} from 'rxjs';

import {IMaskConfig} from '../components/input/interfaces/mask-config.interface';

export class FormInput {
  public field: FormField;
  public control: UntypedFormControl;
  public formGroup: UntypedFormGroup;
  public autocompleteData: IAutocompleteItem;
  public renderValue: TAutocompleteValueRenderFn;
  public customMask: IMaskConfig;
  public isShowCloseIcon = false;
  public tooltipLayouts: ITooltipLayout[] = [];
  public focusField: TUndefinable<string>;
  public isShowTooltipIfLongValue = false;

  constructor(private readonly labelsService: LabelService, private readonly errorsService: ErrorsService) {}

  protected isRequired(): boolean {
    return this.field.rules?.some(i => i[0] === 'required' || i[0] === 'requiredString');
  }

  protected isDisabled(): boolean {
    return this.field.readonly;
  }

  protected isRequiredIfValidator(): boolean {
    if (this.control.validator === null) {
      return false;
    }

    return !!this.control.validator(this.control)?.requiredIf;
  }

  protected isTouchedAndInvalid(): boolean {
    return this.control.touched && this.control.invalid;
  }

  public getLabel(): Observable<string> | undefined {
    const label = this.field.label?.value;

    if (!label) {
      return;
    }

    return this.labelsService.getLabel(label);
  }

  public getHintLabel(): Observable<string> {
    return this.labelsService.getLabel(this.field.label.hint?.value);
  }

  public getOptionLabel(name: string): Observable<string> {
    return this.labelsService.getLabel(name);
  }

  public getError(key: string): Observable<string> {
    const customErrorFactory = this.field.customErrorMap?.[key];

    return customErrorFactory
      ? customErrorFactory(this.control.errors[key])
      : this.errorsService.getError(key, this.control.errors[key]);
  }
}
