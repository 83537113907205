export class CfiCodeHelper {
  public static readonly STOCK_CFI_CODES = ['ESXXXX', 'EPXXXX'];
  public static readonly BOND_CFI_CODE = 'DBXXXX';
  public static readonly CURRENCY_CFI_CODE = 'MRCXXX';

  public static readonly ALL_ALLOWED_CFI_CODES = [
    ...CfiCodeHelper.STOCK_CFI_CODES,
    CfiCodeHelper.BOND_CFI_CODE,
    CfiCodeHelper.CURRENCY_CFI_CODE,
  ];

  public static isStock(code: string): boolean {
    return CfiCodeHelper.STOCK_CFI_CODES.includes(code);
  }

  public static isBond(code: string): boolean {
    return code === CfiCodeHelper.BOND_CFI_CODE;
  }

  public static isCurrency(code: string): boolean {
    return code === CfiCodeHelper.CURRENCY_CFI_CODE;
  }

  public static isAllowed(code: string): boolean {
    return CfiCodeHelper.ALL_ALLOWED_CFI_CODES.includes(code);
  }
}
