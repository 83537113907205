import {Expose, Type} from 'class-transformer';

import {MarketHours} from './market-hours';
import {OrganizationData} from './organization-data';
import {Tariff} from './tariff';

export class ClientCommonInfo {
  @Expose()
  public isIndividual: boolean;

  @Expose({name: 'first_name'})
  public firstName: string;

  @Expose({name: 'last_name'})
  public lastName: string;

  @Expose({name: 'middle_name'})
  public middleName: string;

  @Expose({name: 'is_qualified'})
  public isQualified: boolean;

  @Expose({name: 'need_to_resign_documents'})
  public isNeedToResignDocuments: boolean;

  @Expose({name: 'tests_count'})
  public testsCount: number;

  @Expose()
  public phone: string;

  @Expose()
  public email: string;

  @Expose({name: 'additional_contacts'})
  public additionalContacts: string;

  @Expose()
  @Type(() => Tariff)
  public tariff: Tariff;

  @Expose({name: 'market_hours'})
  @Type(() => MarketHours)
  public marketHours: MarketHours;

  @Expose({name: 'organization_data'})
  @Type(() => OrganizationData)
  public organizationData?: OrganizationData;
}
