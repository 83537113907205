import {
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TUndefinable} from '@app/core/types/types';
import {IAutocompleteItem} from '@app/pbsr/interfaces/autocomplete-item.interface';
import {ITooltipLayout} from '@app/pbsr/shared/tariff-tooltip/tooltip-layout.interface';
import {TAutocompleteValueRenderFn} from '@app/pbsr/types/autocomplete-value-render-function.type';
import * as _ from 'lodash-es';

import {formFieldFactory} from '../../entities/field.factory';
import {FormField} from '../../entities/form.field';
import {FormInput} from '../../entities/form.input';
import {IMaskConfig} from '../input/interfaces/mask-config.interface';

@Component({
  selector: 'app-render',
  styleUrls: ['./render.component.scss'],
  templateUrl: './render.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RenderComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  public readonly field: FormField;

  @Input()
  public readonly formGroup: UntypedFormGroup;

  @Input()
  public readonly control: UntypedFormControl;

  @Input()
  public readonly autocompleteData: IAutocompleteItem;

  @Input()
  public readonly renderValue: TAutocompleteValueRenderFn;

  @Input()
  public readonly autocompletedFieldsNames = new Set<string>();

  @Input()
  public readonly customMask: IMaskConfig;

  @Input()
  public readonly isShowCloseIcon = true;

  @Input()
  public readonly tooltipLayouts: ITooltipLayout[] = [];

  @Input()
  public readonly focusField: TUndefinable<string>;

  @Input()
  public readonly fieldsWithTooltip = new Set<string>();

  private componentInstance: FormInput;
  private componentRef: ComponentRef<FormInput>;

  @ViewChild('container', {read: ViewContainerRef, static: true})
  private readonly container: ViewContainerRef;

  public ngOnInit(): void {
    if (!this.control.value && this.field.initialValue) {
      this.control.setValue(this.field.initialValue);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.componentInstance && this.customMask) {
      this.componentInstance.customMask = this.customMask;
    }

    this.setAutocomplete();

    const isAutocompleteChangeNil = _.isNil(changes.autocompleteData) || _.isNil(changes.autocompleteData.currentValue);
    const isFirstAutocompleteChange = isAutocompleteChangeNil || changes.autocompleteData.isFirstChange();

    if (this.control && this.formGroup && this.field && isFirstAutocompleteChange) {
      this.createComponent();
    }
  }

  private createComponent(): void {
    this.clear();

    const control = formFieldFactory(this.field);

    this.componentRef = this.container.createComponent(control);
    this.componentInstance = this.componentRef.instance;

    this.componentInstance.control = this.control;
    this.componentInstance.formGroup = this.formGroup;
    this.componentInstance.field = this.field;
    this.componentInstance.customMask = this.customMask;
    this.componentInstance.isShowCloseIcon = this.isShowCloseIcon;
    this.componentInstance.tooltipLayouts = this.tooltipLayouts;
    this.componentInstance.focusField = this.focusField;

    this.setAutocomplete();
    this.setVisibilityOfTooltipIfLongValue();
  }

  private setAutocomplete(): void {
    if (this.componentInstance && this.autocompletedFieldsNames.has(this.componentInstance.field.name)) {
      this.componentInstance.autocompleteData = this.autocompleteData;
      this.componentInstance.renderValue = this.renderValue;
    }
  }

  private setVisibilityOfTooltipIfLongValue(): void {
    if (this.componentInstance && this.fieldsWithTooltip.has(this.componentInstance.field.name)) {
      this.componentInstance.isShowTooltipIfLongValue = true;
    }
  }

  private clear(): void {
    this.container.clear();
    this.componentInstance = null;
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  public ngOnDestroy(): void {
    this.clear();
  }
}
