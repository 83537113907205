<div appDir>
  <button mat-stroked-button color="accent" type="button" class="add-file-button">
    <app-icon [icon]="'clip'"></app-icon>
    <div>
      <input type="file" multiple (change)="fileChange($event)" [accept]="fileAccept.toLocaleLowerCase()" />
    </div>
    <span>{{ 'Attach files' | translate }}</span>
  </button>

  <div class="warning">
    <app-icon [icon]="'info'"></app-icon>
    <span
      >{{ 'Requirements' | translate }}: <span class="formats">{{ formats }}</span> | {{ maxFileSize }}&nbsp;MB
      {{ 'per file' | translate }}</span
    >
  </div>

  <div class="files">
    <app-file-input-images
      [files]="fileKeeper.files"
      [urls]="fileKeeper.safeUrls"
      [errors]="errors"
      (remove)="deleteFile($event)"
    ></app-file-input-images>
  </div>
</div>
