export enum EPlatformName {
  Mt4 = 'mt4',
  Mt5 = 'mt5',
  Centroid = 'Centroid',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CTrader = 'ctrader',
  DxTrade = 'dxtrade',
  OneZero = 'oneZero',
  PrimeXm = 'PrimeXM',
  MatchTrader = 'match_trader',
  TradeLocker = 'TradeLocker',
}
