import {Injectable} from '@angular/core';
import {InstrumentGroup, InstrumentSubgroup} from 'src/app/trading-board/models/b2margin/instrument-group';

import {GroupsConfiguration} from '../../consts/instrument-groups-configuration';
import {B2MarginDatafeedService} from '../../datafeed/b2margin-datafeed.service';
import {IB2MarginInstrument} from '../../interfaces/b2margin/instrument';
import {B2MarginInstrument} from '../../models/instrument';
import {AEventHandler} from '../event-handler.abstract';
import {EEventHandlersReceived} from '../event-handlers.enum';

@Injectable()
export class B2MarginInstruments extends AEventHandler<B2MarginInstrument[]> {
  public type = EEventHandlersReceived.Instruments;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  private reduceInstrumentGroups(instruments: IB2MarginInstrument[]): InstrumentGroup[] {
    const others = 'Others';

    const reduced = instruments.reduce<Map<string, Map<string, InstrumentSubgroup>>>((result, instrument) => {
      const [groupKey = others, subgroupKey = others] = instrument.subtype?.split('/') || [];
      const groupConfig = GroupsConfiguration[groupKey];
      const subgroupIndex = groupConfig?.subgroups && groupConfig.subgroups[subgroupKey];
      const group = result.get(groupKey) || result.set(groupKey, new Map()).get(groupKey);
      const subgroup =
        group.get(subgroupKey) ||
        group.set(subgroupKey, new InstrumentSubgroup(subgroupKey, subgroupIndex)).get(subgroupKey);
      subgroup.instruments.push(instrument);
      return result;
    }, new Map());

    return Array.from(reduced.entries())
      .map(([key, value]) => {
        const sortedSubgroups = Array.from(value.values()).sort((a, b) => a.index - b.index);
        const othersSubgroupIndex = sortedSubgroups.findIndex(v => v.title === others);
        if (othersSubgroupIndex !== -1) {
          sortedSubgroups.push(...sortedSubgroups.splice(othersSubgroupIndex, 1));
        }

        const group = new InstrumentGroup(key, GroupsConfiguration[key]?.index);
        group.subgroups = sortedSubgroups;

        return group;
      })
      .sort((a, b) => a.index - b.index);
  }

  public handleMessage(instruments: B2MarginInstrument[]): void {
    this.b2marginDatafeedService.instruments$.next(instruments);

    this.b2marginDatafeedService.instrumentGroup$.next(this.reduceInstrumentGroups(instruments));
  }
}
