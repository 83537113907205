import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConnectionService} from '@app/core/connection.service';
import {GridsterProviders} from '@app/core/models/gridster';
import {AccountService} from '@app/core/services/account.service';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {B2marginPopupQueueService} from '@app/trading-board/services/b2margin-popup-queue.service';
import {AccountTO} from '@b2broker/b2margin-trade-models/dist/models/accountTO';
import {TranslateService} from '@ngx-translate/core';

import {WorkerConnectionService} from '../services/worker-connection.service';

@Injectable()
export class B2MarginSpotDatafeedService extends B2MarginDatafeedService implements OnDestroy {
  public readonly provider = GridsterProviders.b2marginSpot;
  public readonly validCashType = AccountTO.CashTypeEnum.CASH;
  public readonly invalidCashType = AccountTO.CashTypeEnum.MARGIN;

  constructor(
    protected readonly connectionService: ConnectionService,
    protected readonly workerConnectionService: WorkerConnectionService,
    protected readonly translate: TranslateService,
    protected readonly dialog: MatDialog,
    protected readonly accountService: AccountService,
    protected readonly popupQueueService: B2marginPopupQueueService,
  ) {
    super(connectionService, workerConnectionService, translate, dialog, accountService, popupQueueService);
  }

  public ngOnDestroy(): void {
    this.terminate();
  }
}
