import {EDynamicChildren} from '@app/core/menu/enum/dynamic-children.enum';
import {EPlatformClass} from '@app/core/models/account/enum/platform-class';
import {AccessAlias, LinkIcon} from '@env/environment.entities';

import {EMenuPointCodeApi} from '../enum/menu-point-code-api';
import {TMenuPointInfo} from '../types/menu-point-info';

export const allMenuItems: Readonly<
  Record<Exclude<EMenuPointCodeApi, EMenuPointCodeApi.InternalTransfer | EMenuPointCodeApi.General>, TMenuPointInfo>
> = {
  [EMenuPointCodeApi.Dashboard]: {
    url: '/dashboard',
    label: 'Dashboard',
    icon: LinkIcon.dashboard,
    accessAliasKeys: [AccessAlias.Dashboard],
  },

  [EMenuPointCodeApi.Briefcase]: {
    url: '/briefcase',
    label: 'PbsrBriefcase',
    icon: LinkIcon.briefcase,
    accessAliasKeys: [AccessAlias.Briefcase],
  },

  [EMenuPointCodeApi.Wallets]: {
    url: '/wallet',
    label: 'Wallet',
    icon: LinkIcon.wallet,
    accessAliasKeys: [AccessAlias.Wallet],
  },

  [EMenuPointCodeApi.B2Trader]: {
    url: '/trading-board/ce',
    label: 'TradeB2Trader',
    icon: LinkIcon.trade,
    accessAliasKeys: [AccessAlias.TradeB2Trader, AccessAlias.ProfileTierFee, AccessAlias.TradingBoard],
  },

  [EMenuPointCodeApi.Moex]: {
    url: '/trading-board/moex',
    label: 'TradeMoex',
    icon: LinkIcon.sab,
    accessAliasKeys: [AccessAlias.TradingBoard],
  },

  [EMenuPointCodeApi.B2Margin]: {
    url: '/trading-board/me',
    label: 'TradeB2Margin',
    icon: LinkIcon.trade,
    accessAliasKeys: [AccessAlias.TradeB2Margin, AccessAlias.TradingBoard],
  },

  [EMenuPointCodeApi.Platforms]: {
    url: '/trade/platform',
    label: 'Platforms',
    icon: LinkIcon.mt,
    accessAliasKeys: [],
    isParent: true,
  },
  [EMenuPointCodeApi.MT4]: {
    url: '/' + EPlatformClass.MetaTrader4,
    label: 'MT4',
    icon: LinkIcon.mt4,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.AnalyticsMT4,
    ],
  },
  [EMenuPointCodeApi.PlatformsMT4]: {
    url: '/trade/platform/' + EPlatformClass.MetaTrader4,
    label: 'MT4',
    icon: LinkIcon.mt,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.AnalyticsMT4,
    ],
  },
  [EMenuPointCodeApi.MT5]: {
    url: '/' + EPlatformClass.MetaTrader5,
    label: 'MT5',
    icon: LinkIcon.mt5,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.AnalyticsMT5,
    ],
  },
  [EMenuPointCodeApi.PlatformsMT5]: {
    url: '/trade/platform/' + EPlatformClass.MetaTrader5,
    label: 'MT5',
    icon: LinkIcon.mt,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.AnalyticsMT5,
    ],
  },
  [EMenuPointCodeApi.CTrader]: {
    url: '/' + EPlatformClass.CTrader,
    label: 'cTrader',
    isNoCapitalize: true,
    icon: LinkIcon.mt5,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.AnalyticsCTrader,
    ],
  },
  [EMenuPointCodeApi.DxTrade]: {
    url: '/' + EPlatformClass.DxTrade,
    label: 'DXTrade',
    isNoCapitalize: true,
    icon: LinkIcon.mt5,
    accessAliasKeys: [AccessAlias.DxTrade],
  },
  [EMenuPointCodeApi.PlatformsCTrader]: {
    url: '/trade/platform/' + EPlatformClass.CTrader,
    label: 'cTrader',
    isNoCapitalize: true,
    icon: LinkIcon.markets,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.AnalyticsCTrader,
    ],
  },
  [EMenuPointCodeApi.PlatformsDxTrade]: {
    url: '/trade/platform/' + EPlatformClass.DxTrade,
    label: 'DXTrade',
    isNoCapitalize: true,
    icon: LinkIcon.markets,
    accessAliasKeys: [AccessAlias.Trade, AccessAlias.DxTrade],
  },
  [EMenuPointCodeApi.MarginalPlatform]: {
    url: '/marginal-accounts',
    label: 'MarginalAccounts',
    icon: LinkIcon.markets,
    accessAliasKeys: [AccessAlias.PrimeXm, AccessAlias.OneZero, AccessAlias.Centroid],
  },
  [EMenuPointCodeApi.PlatformsMarginal]: {
    url: '/trade/platform/marginal-accounts',
    label: 'MarginalAccounts',
    icon: LinkIcon.markets,
    accessAliasKeys: [AccessAlias.Trade, AccessAlias.PrimeXm, AccessAlias.OneZero, AccessAlias.Centroid],
  },
  [EMenuPointCodeApi.MatchTrader]: {
    url: '/' + EPlatformClass.MatchTrader,
    label: 'MatchTrader',
    isNoCapitalize: true,
    icon: LinkIcon.mt5,
    accessAliasKeys: [AccessAlias.MatchTrader],
  },
  [EMenuPointCodeApi.PlatformsMatchTrader]: {
    url: '/trade/platform/' + EPlatformClass.MatchTrader,
    label: 'MatchTrader',
    isNoCapitalize: true,
    icon: LinkIcon.markets,
    accessAliasKeys: [AccessAlias.Trade, AccessAlias.MatchTrader],
  },
  [EMenuPointCodeApi.TradeLocker]: {
    url: '/' + EPlatformClass.TradeLocker,
    label: 'TradeLocker',
    icon: LinkIcon.tradeLocker,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.TradeLocker,
    ],
  },
  [EMenuPointCodeApi.PlatformsTradeLocker]: {
    url: '/trade/platform/' + EPlatformClass.TradeLocker,
    label: 'TradeLocker',
    icon: LinkIcon.tradeLocker,
    accessAliasKeys: [
      AccessAlias.Trade,
      AccessAlias.TradeSettings,
      AccessAlias.TradeSettingsArchive,
      AccessAlias.TradeLocker,
    ],
  },
  [EMenuPointCodeApi.Funds]: {
    url: '/tx',
    label: 'Funds',
    icon: LinkIcon.funds,
    // TODO: remove AccessAlias.InternalTransfer in https://b2btech.atlassian.net/browse/FDP-9673
    accessAliasKeys: [AccessAlias.Conversion, AccessAlias.InternalTransfer],
    dynamicChildren: EDynamicChildren.InternalTransfer,
    isParent: true,
  },
  [EMenuPointCodeApi.Deposit]: {
    url: '/payment',
    label: 'Deposit',
    icon: LinkIcon.deposit,
    accessAliasKeys: [AccessAlias.ConversionPayment, AccessAlias.TransactionsPayment],
  },
  [EMenuPointCodeApi.Transfer]: {
    url: '/transfer',
    label: 'Transfer',
    icon: LinkIcon.transfer,
    accessAliasKeys: [AccessAlias.ConversionTransfer, AccessAlias.TransactionsTransfer],
  },
  [EMenuPointCodeApi.Withdraw]: {
    url: '/payout',
    label: 'Withdraw',
    icon: LinkIcon.withdraw,
    accessAliasKeys: [AccessAlias.ConversionPayout, AccessAlias.TransactionsPayout, AccessAlias.ProfilePayoutWhiteList],
  },

  [EMenuPointCodeApi.Exchange]: {
    url: '/exchange',
    label: 'Exchange',
    icon: LinkIcon.exchange,
    accessAliasKeys: [AccessAlias.Exchange, AccessAlias.TransactionsExchange],
  },

  [EMenuPointCodeApi.History]: {
    url: '/history',
    label: 'History',
    icon: LinkIcon.history,
    accessAliasKeys: [AccessAlias.History],
    isParent: true,
  },
  [EMenuPointCodeApi.Transactions]: {
    url: '/transactions',
    label: 'Transactions',
    icon: LinkIcon.transactions,
    accessAliasKeys: [AccessAlias.Transactions],
  },
  [EMenuPointCodeApi.Analytics]: {
    url: '/analytics/' + EPlatformClass.MetaTrader5,
    label: 'Analytics',
    icon: LinkIcon.analytics,
    accessAliasKeys: [AccessAlias.Analytics],
  },
  [EMenuPointCodeApi.AnalyticsMT4]: {
    url: '/analytics/' + EPlatformClass.MetaTrader4,
    label: 'Analytics',
    icon: LinkIcon.analytics,
    accessAliasKeys: [AccessAlias.Analytics],
  },
  [EMenuPointCodeApi.AnalyticsMT5]: {
    url: '/analytics/' + EPlatformClass.MetaTrader5,
    label: 'Analytics',
    icon: LinkIcon.analytics,
    accessAliasKeys: [AccessAlias.Analytics],
  },
  [EMenuPointCodeApi.AnalyticsCTrader]: {
    url: '/analytics/' + EPlatformClass.CTrader,
    label: 'Analytics',
    icon: LinkIcon.analytics,
    accessAliasKeys: [AccessAlias.Analytics],
  },

  [EMenuPointCodeApi.Pamm]: {
    url: '/pamm',
    label: 'PAMM',
    icon: LinkIcon.pamm,
    dynamicChildren: EDynamicChildren.Pamm,
    accessAliasKeys: [AccessAlias.Pamm],
  },

  [EMenuPointCodeApi.B2Copy]: {
    url: '/copy',
    label: 'B2Copy',
    icon: LinkIcon.b2copy,
    accessAliasKeys: [AccessAlias.B2Copy],
    isParent: true,
    dynamicChildren: EDynamicChildren.B2Copy,
  },
  [EMenuPointCodeApi.B2CopyAccountList]: {
    url: '/accounts',
    label: 'B2CopyAccountList',
    icon: LinkIcon.dashboard,
    accessAliasKeys: [AccessAlias.B2Copy],
  },
  [EMenuPointCodeApi.B2CopyLeaderboard]: {
    url: '/leaderboard',
    label: 'B2CopyLeaderboard',
    icon: LinkIcon.leaderboard,
    accessAliasKeys: [AccessAlias.B2Copy],
  },

  [EMenuPointCodeApi.PammNew]: {
    url: '/pamm-new',
    label: 'PammNew',
    icon: LinkIcon.pamm,
    accessAliasKeys: [AccessAlias.PammNew],
    isParent: true,
    dynamicChildren: EDynamicChildren.PammNew,
  },
  [EMenuPointCodeApi.PammNewAccountList]: {
    url: '/accounts',
    label: 'PammNewAccountList',
    icon: LinkIcon.dashboard,
    accessAliasKeys: [AccessAlias.PammNew],
  },
  [EMenuPointCodeApi.PammNewLeaderboard]: {
    url: '/leaderboard',
    label: 'PammNewLeaderboard',
    icon: LinkIcon.leaderboard,
    accessAliasKeys: [AccessAlias.PammNew],
  },

  [EMenuPointCodeApi.Mam]: {
    url: '/mam',
    label: 'Mam',
    icon: LinkIcon.mam,
    accessAliasKeys: [AccessAlias.Mam],
    isParent: true,
    dynamicChildren: EDynamicChildren.Mam,
  },
  [EMenuPointCodeApi.MamAccountList]: {
    url: '/accounts',
    label: 'MamAccountList',
    icon: LinkIcon.dashboard,
    accessAliasKeys: [AccessAlias.Mam],
  },
  [EMenuPointCodeApi.MamLeaderboard]: {
    url: '/leaderboard',
    label: 'MamLeaderboard',
    icon: LinkIcon.leaderboard,
    accessAliasKeys: [AccessAlias.Mam],
  },

  [EMenuPointCodeApi.Bonuses]: {
    url: '/bonuses',
    label: 'Bonuses',
    icon: LinkIcon.bonus,
    accessAliasKeys: [AccessAlias.Bonuses],
  },

  [EMenuPointCodeApi.NewIBRoom]: {
    url: '/ib-room',
    label: 'IB',
    icon: LinkIcon.partner,
    accessAliasKeys: [],
    isParent: true,
  },
  [EMenuPointCodeApi.NewIBPartnerBoard]: {
    url: '/dashboard',
    label: 'PartnerDashboard',
    icon: LinkIcon.dashboard,
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBPromo]: {
    url: '/promo',
    label: 'PartnerPromo',
    icon: LinkIcon.promo,
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBPromoLinks]: {
    url: '/promo/links',
    label: 'PartnerLinks',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBPromoBanners]: {
    url: '/promo/banners',
    label: 'PartnerBanners',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReports]: {
    url: '/reports',
    label: 'PartnerReports',
    icon: LinkIcon.analytics,
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsAccounts]: {
    url: '/reports/accounts',
    label: 'PartnerAccounts',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsDeposits]: {
    url: '/reports/deposits',
    label: 'PartnerDeposits',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsWithdrawals]: {
    url: '/reports/withdrawals',
    label: 'PartnerWithdrawals',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsAcquisitions]: {
    url: '/reports/acquisitions',
    label: 'PartnerAcquisitions',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsClientList]: {
    url: '/reports/client-list',
    label: 'PartnerClientList',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsRewards]: {
    url: '/reports/rewards',
    label: 'PartnerRewards',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsTransactions]: {
    url: '/reports/transactions',
    label: 'PartnerTransactions',
    accessAliasKeys: [AccessAlias.IB],
  },
  [EMenuPointCodeApi.NewIBReportsTrades]: {
    url: '/reports/trades',
    label: 'PartnerTrades',
    accessAliasKeys: [AccessAlias.IB],
  },

  [EMenuPointCodeApi.Profile]: {
    url: '/profile',
    label: 'Profile',
    icon: LinkIcon.profile,
    accessAliasKeys: [AccessAlias.Profile],
    isParent: true,
    dynamicChildren: EDynamicChildren.PbsrTesting,
  },
  [EMenuPointCodeApi.Settings]: {
    url: '/settings',
    label: 'Settings',
    icon: LinkIcon.settings,
    accessAliasKeys: [AccessAlias.ProfileSettings, AccessAlias.ProfileTwoFactor, AccessAlias.ProfileLanguage],
  },
  [EMenuPointCodeApi.PbsrDocuments]: {
    url: '/pbs-documents',
    label: 'PbsrDocuments',
    icon: LinkIcon.doc,
    accessAliasKeys: [AccessAlias.PbsrDocuments],
  },
  [EMenuPointCodeApi.PbsrTesting]: {
    url: '/testing',
    label: 'PbsrTesting',
    icon: LinkIcon.helpWithCircle,
    accessAliasKeys: [AccessAlias.PbsrTesting],
  },
  [EMenuPointCodeApi.Verification]: {
    url: '/verification',
    label: 'Verification',
    icon: LinkIcon.verification,
    accessAliasKeys: [
      AccessAlias.Verification,
      AccessAlias.ProfileVerificationDocument,
      AccessAlias.ProfileVerificationLevel,
      AccessAlias.ProfileVerificationInfo,
    ],
  },
  [EMenuPointCodeApi.Finance]: {
    url: '/finance',
    label: 'Finance',
    icon: LinkIcon.finance,
    accessAliasKeys: [AccessAlias.ProfileFinance],
  },
  [EMenuPointCodeApi.Security]: {
    url: '/security',
    label: 'Security',
    icon: LinkIcon.security,
    accessAliasKeys: [AccessAlias.ProfileSecurity],
  },
  [EMenuPointCodeApi.ApiKeyManagement]: {
    url: '/api-key-management',
    label: 'ApiKeyManagement',
    icon: LinkIcon.key,
    accessAliasKeys: [AccessAlias.ApiKeyManagement],
  },
  [EMenuPointCodeApi.PbsrPersonalInfo]: {
    url: '/personal-info',
    label: 'PbsrPersonalInfo',
    icon: LinkIcon.profile,
    accessAliasKeys: [AccessAlias.PbsrProfilePersonalInfo],
  },

  [EMenuPointCodeApi.Helpdesk]: {
    url: '/helpdesk',
    label: 'Helpdesk',
    icon: LinkIcon.helpdesk,
    accessAliasKeys: [AccessAlias.Helpdesk],
  },

  [EMenuPointCodeApi.PbsrEnrolmentAndTransfers]: {
    url: '/enrolment-and-transfers',
    label: 'PbsrEnrolmentAndTransfers',
    icon: LinkIcon.exchangeSquared,
    accessAliasKeys: [AccessAlias.PbsrEnrolmentAndTransfers],
    isParent: true,
  },
  [EMenuPointCodeApi.PbsrDeposit]: {
    url: '/deposit',
    label: 'PbsrDeposit',
    icon: LinkIcon.deposit,
    accessAliasKeys: [AccessAlias.PbsrDeposit],
  },
  [EMenuPointCodeApi.PbsrTransfer]: {
    url: '/transfer',
    label: 'PbsrTransfer',
    icon: LinkIcon.transfer,
    accessAliasKeys: [AccessAlias.PbsrTransfer],
  },
  [EMenuPointCodeApi.PbsrWithdraw]: {
    url: '/withdraw',
    label: 'PbsrWithdraw',
    icon: LinkIcon.withdraw,
    accessAliasKeys: [AccessAlias.PbsrWithdraw],
  },

  [EMenuPointCodeApi.PbsrCatalog]: {
    url: '/catalog',
    label: 'PbsrCatalog',
    icon: LinkIcon.references,
    accessAliasKeys: [AccessAlias.PbsrCatalog],
  },

  [EMenuPointCodeApi.PbsrHelpAndFaq]: {
    url: '/help-and-faq',
    label: 'PbsrHelpAndFaq',
    icon: LinkIcon.helpCircle,
    accessAliasKeys: [AccessAlias.PbsrHelpAndFaq],
  },

  [EMenuPointCodeApi.PbsrTradeIdeas]: {
    url: '/trade-ideas',
    label: 'PbsrTradeIdeas',
    icon: LinkIcon.bonusActive,
    accessAliasKeys: [AccessAlias.PbsrTradeIdeas],
  },

  [EMenuPointCodeApi.PbsrNewsAndOverviews]: {
    url: '/news-and-overviews',
    label: 'PbsrNewsAndOverviews',
    icon: LinkIcon.promo,
    accessAliasKeys: [AccessAlias.PbsrNewsAndOverviews],
  },

  [EMenuPointCodeApi.PbsrReportsAndCertificates]: {
    url: '/reports-and-certificates',
    label: 'PbsrReportsAndCertificates',
    icon: LinkIcon.analytics,
    accessAliasKeys: [AccessAlias.PbsrReportsAndCertificates],
  },

  [EMenuPointCodeApi.PbsrBrokerageAccountEventsHistory]: {
    url: '/brokerage-account-events-history',
    label: 'PbsrBrokerageAccountEventsHistory',
    icon: LinkIcon.history,
    accessAliasKeys: [AccessAlias.PbsrBrokerageAccountEventsHistory],
    isParent: true,
  },
  [EMenuPointCodeApi.PbsrBrokerageOrders]: {
    url: '/brokerage-orders',
    label: 'PbsrBrokerageOrders',
    icon: LinkIcon.archive,
    accessAliasKeys: [AccessAlias.PbsrBrokerageOrders],
  },
  [EMenuPointCodeApi.PbsrOperationsAndOrders]: {
    url: '/operations-and-orders',
    label: 'PbsrOperationsAndOrders',
    isNoCapitalize: true,
    icon: LinkIcon.transactionsOperation,
    accessAliasKeys: [AccessAlias.PbsrOperationsAndOrders],
  },

  [EMenuPointCodeApi.EqwireAccounts]: {
    url: '/eqwire-accounts',
    label: 'EqwireAccounts',
    icon: LinkIcon.wallet,
    accessAliasKeys: [AccessAlias.Eqwire],
  },
  [EMenuPointCodeApi.EqwireBeneficiaries]: {
    url: '/beneficiaries',
    label: 'EqwireBeneficiaries',
    icon: LinkIcon.history,
    accessAliasKeys: [AccessAlias.Eqwire],
  },
  [EMenuPointCodeApi.EqwirePaymentsTransfers]: {
    url: '/payments-and-transfers',
    label: 'EqwirePaymentsAndTransfers',
    icon: LinkIcon.funds,
    accessAliasKeys: [AccessAlias.Eqwire],
  },
};
