import {inject, isDevMode} from '@angular/core';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {Environment} from '@env/environment.entities';
import * as cryptoJs from 'crypto-js';

import {AEventLogger} from './event-logger/event-logger.abstract';
import {TAppEnvironment} from './types/app-environment.type';
import {TEvent} from './types/event.type';

export abstract class AAnalyticsService<T> {
  protected readonly environment = inject(Environment);

  protected abstract eventLogger: AEventLogger | null;

  protected abstract initialize(config: T, email: string): void;
  protected abstract logEvent(event: TEvent): void;

  protected get isProd(): boolean {
    return !isDevMode();
  }

  protected get isTestMode(): boolean {
    return isDevMode() && this.isTestingEventsEnabled();
  }

  protected get appEnvironment(): TAppEnvironment {
    return isDevMode() ? 'dev' : 'prod';
  }

  protected get appVersion(): string {
    return this.environment.appBuild.appVersion;
  }

  protected get projectName(): string {
    return this.environment.projectInfo.name;
  }

  protected getEncryptedEmail(email: string): string {
    return cryptoJs.SHA256(email).toString();
  }

  private isTestingEventsEnabled(): boolean {
    return ALocaleStorage.IS_TESTING_ANALYTICS_EVENTS_ENABLED.get() === 'true';
  }
}
