import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ABaseConnectionService} from '@app/core/base-connection.service';
import {Environment} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';
import * as qs from 'qs';
import {Observable} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ConnectionNativeService extends ABaseConnectionService {
  constructor(environment: Environment, router: Router, translateService: TranslateService) {
    super(environment, router, translateService);
  }

  public get<T>(
    url: string,
    params?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [param: string]: any;
    },
  ): Observable<T> {
    const normalizedParams = qs.stringify(params);

    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        fromFetch<T>(`${this.apiUrl}${url}?${normalizedParams}`, {
          selector: res => res.json(),
          method: 'GET',
          credentials: 'include',
          headers,
        }),
      ),
    );
  }

  public post<T>(url: string, body): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        fromFetch<T>(this.apiUrl + url, {
          selector: res => res.json(),
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify(body),
        }),
      ),
    );
  }

  public put<T>(url: string, body?): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        fromFetch<T>(this.apiUrl + url, {
          selector: res => res.json(),
          method: 'PUT',
          credentials: 'include',
          headers,
          body: JSON.stringify(body),
        }),
      ),
    );
  }

  public patch<T>(url: string, body?): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        fromFetch<T>(this.apiUrl + url, {
          selector: res => res.json(),
          method: 'PATCH',
          credentials: 'include',
          headers,
          body: JSON.stringify(body),
        }),
      ),
    );
  }

  public delete<T>(url: string): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        fromFetch<T>(this.apiUrl + url, {
          selector: res => res.json(),
          method: 'DELETE',
          credentials: 'include',
          headers,
        }),
      ),
    );
  }
}
