import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {IQuizzesProductCategory} from '@app/pbsr/interfaces/quizzes-product-category.interface';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class QuizzesProductCategoryApiService {
  constructor(private readonly connectionService: ConnectionService) {}

  public getQuizzesProductCategory(): Observable<IQuizzesProductCategory[]> {
    return this.connectionService
      .get<{data: IQuizzesProductCategory[]}>('/api/v1/pbs/questionnaire/quizzes_product_category')
      .pipe(
        map(result => result.data),
        catchError(() => of([])),
      );
  }
}
