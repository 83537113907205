import {ERiskLevelAlertType} from '@app/trading-board/models/b2margin/risk-level-alert-type';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export const AccountTranslate = marker('TradingBoard.PopupMessage.B2Margin.Account.Text [baseCurrency, accountCode]');

export const RiskLevelAlertTextTranslate = {
  [ERiskLevelAlertType.Level1]: marker(
    'TradingBoard.PopupMessage.B2Margin.RiskLevelAlert.Level1.Text [riskLevelLimit]',
  ),
  [ERiskLevelAlertType.Level2]: marker(
    'TradingBoard.PopupMessage.B2Margin.RiskLevelAlert.Level2.Text [riskLevelLimit]',
  ),
  [ERiskLevelAlertType.Liquidation]: marker(
    'TradingBoard.PopupMessage.B2Margin.RiskLevelAlert.Liquidation.Text [riskLevelLimit]',
  ),
};
export const LiquidationTranslate = marker('TradingBoard.PopupMessage.B2Margin.Liquidation.Text');
