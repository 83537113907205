import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {filterNil} from '@app/core/utils/rxjs-filters';
import {IAutocompleteItem} from '@app/pbsr/interfaces/autocomplete-item.interface';
import {ITooltipLayout} from '@app/pbsr/shared/tariff-tooltip/tooltip-layout.interface';
import {TAutocompleteValueRenderFn} from '@app/pbsr/types/autocomplete-value-render-function.type';
import * as _ from 'lodash-es';
import {merge, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, startWith, takeUntil, tap} from 'rxjs/operators';

import {FormField, FormFieldType} from '../../entities/form.field';
import {FormWrapper} from '../../entities/form.wrapper';
import {ErrorsService} from '../../services/errors/errors.service';
import {IMaskConfig} from '../input/interfaces/mask-config.interface';

@Component({
  selector: 'app-form-group',
  templateUrl: './form.group.component.html',
  styleUrls: ['./form.group.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormGroupComponent implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();

  private readonly controlVisibilityMap: Record<string, boolean> = {};

  private readonly newFieldsAssigned$ = new Subject<void>();

  @Input()
  public form: UntypedFormGroup;

  private _fields!: Record<string, FormField>;

  @Input()
  public set fields(value: Record<string, FormField>) {
    this._fields = value;
    this.newFieldsAssigned$.next();

    this.initializeDependentFieldReset();
    this.observeMasterFieldChange();
  }

  public get fields(): Record<string, FormField> {
    return this._fields;
  }

  @Input()
  public formGroupTemplate: TemplateRef<ElementRef>;

  @Input()
  public autocompleteData: IAutocompleteItem;

  @Input()
  public customsMasks = new Map<string, IMaskConfig>();

  @Input()
  public readonly renderValue: TAutocompleteValueRenderFn;

  @Input()
  public readonly autocompletedFieldsNames = new Set<string>();

  @Input()
  public readonly tooltipLayouts: ITooltipLayout[] = [];

  @Input()
  public readonly isFocusFirstInput = false;

  @Input()
  public readonly fieldsWithTooltip = new Set<string>();

  @Output()
  public readonly formArrayChanged = new Subject<string>();

  public readonly formFieldType = FormFieldType;

  constructor(private readonly errorsService: ErrorsService) {}

  public getError(key: string): Observable<string> {
    return this.errorsService.getError(key, this.form.errors[key]);
  }

  public getFirstField(): string | undefined {
    if (this.fields && this.isFocusFirstInput) {
      return _.first(Object.keys(this.fields));
    }
  }

  public ngOnInit(): void {
    if (!this.fields) {
      throw new Error('Input property "fields" is undefined');
    }
  }

  public getMask(key: string): IMaskConfig | undefined {
    if (!this.customsMasks.size) {
      return;
    }

    return this.customsMasks.get(key);
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  /**
   * Watch all main fields (fields with dependent fields) and reset dependent fields if wrong option selected.
   */
  public initializeDependentFieldReset(): void {
    const dependentConfigs = Object.entries(this.fields)
      .map(([fieldKey, fieldConfig]) => {
        const mainFieldId = fieldConfig.depends_on_field_id;
        if (!mainFieldId) {
          return null;
        }

        const mainField = this.fields[this.getFieldKeyById(mainFieldId)];
        if (!mainField) {
          return null;
        }

        return {
          mainFieldId,
          mainField,
          dependentKey: fieldKey,
          dependentId: fieldConfig.id,
          dependentName: fieldConfig.name,
        };
      })
      .filter(v => v !== null);

    // Generate streams with field key to reset
    const fieldIdToResetStreams: Observable<string | null>[] = dependentConfigs.map(
      ({mainFieldId, mainField, dependentKey, dependentId, dependentName}) => {
        const control = this.form.get(this.getFieldKeyById(mainFieldId));

        if (!control) {
          return of(null);
        }

        return control.valueChanges.pipe(
          map(selectedOptionValue => {
            const selectedOption = mainField.options?.find(option => option.value === selectedOptionValue);

            // It's possible to have relations between fields by id or name. First of all, we check relations by id.
            if (!selectedOption?.related_fields_id?.includes(dependentId)) {
              return dependentKey;
            }

            if (!selectedOption?.related_fields?.includes(dependentName)) {
              return dependentKey;
            }
            return null;
          }),
        );
      },
    );

    // Listen streams and reset fields if needed
    merge(...fieldIdToResetStreams)
      .pipe(filterNil(), takeUntil(merge(this.destroyer$, this.newFieldsAssigned$)))
      .subscribe(idToReset => this.form.patchValue({[idToReset]: null}));
  }

  public isMainOptionSelected(mainFieldKey: string, dependentFieldKey: string): boolean {
    const mainField = this.fields[mainFieldKey];
    const selectedOptionValue = this.form.value?.[mainFieldKey];
    const selectedOption = mainField?.options.find(o => o.value === selectedOptionValue);

    if (!selectedOption) {
      return false;
    }

    const dependentField = this.fields[dependentFieldKey];
    return (
      Boolean(selectedOption.related_fields_id?.includes(dependentField.id)) ||
      Boolean(selectedOption.related_fields?.includes(dependentField.name))
    );
  }

  public isRelatedFieldOptionSelected(relatedFieldKey: string, valueToCheck: number): boolean {
    const relatedField = this.fields[relatedFieldKey];
    const selectedOptionValue = this.form.value?.[relatedFieldKey];
    const selectedOption = relatedField?.options.find(o => o.value === selectedOptionValue);

    if (!selectedOption) {
      return false;
    }

    return selectedOption.value === valueToCheck;
  }

  public isSimpleFieldVisible(fieldId: string): boolean {
    return this.controlVisibilityMap[fieldId] ?? true;
  }

  /**
   * Watches master field changes and updates dependent fields visibility and validators.
   */
  private observeMasterFieldChange(): void {
    const dependentFieldIds = Object.keys(this.fields).filter(
      fieldId => !_.isNil(this.fields[fieldId].related_field_id) || !_.isNil(this.fields[fieldId].depends_on_field_id),
    );
    // map of [masterFieldId, dependentFieldIds]
    const masterFieldsMap: Record<string, string[]> = dependentFieldIds.reduce((acc, dependentFieldId) => {
      const dependentMasterFormControlId = this.getFieldKeyById(this.fields[dependentFieldId].depends_on_field_id);
      const relatedMasterFormControlId = this.getFieldKeyById(this.fields[dependentFieldId].related_field_id);

      if (dependentMasterFormControlId) {
        if (!acc[dependentMasterFormControlId]) {
          acc[dependentMasterFormControlId] = [];
        }

        if (!acc[dependentMasterFormControlId].includes(dependentFieldId)) {
          acc[dependentMasterFormControlId].push(dependentFieldId);
        }
      }

      if (relatedMasterFormControlId) {
        if (!acc[relatedMasterFormControlId]) {
          acc[relatedMasterFormControlId] = [];
        }

        if (!acc[relatedMasterFormControlId].includes(dependentFieldId)) {
          acc[relatedMasterFormControlId].push(dependentFieldId);
        }
      }

      return acc as Record<string, string[]>;
    }, {});

    // subscribe each master field change and update dependent fields validators
    Object.keys(masterFieldsMap).forEach(masterFieldId => {
      const masterField = this.form.get(masterFieldId);

      masterField.valueChanges
        .pipe(
          startWith(null as unknown),
          // we have to call in tap to prevent "jumping" of the form,
          // because method in the subscription will be called in next tick
          tap(() => this.updateControlVisibilityMap(masterFieldsMap, masterFieldId)),
          distinctUntilChanged(),
          takeUntil(merge(this.destroyer$, this.newFieldsAssigned$)),
        )
        .subscribe(() => {
          this.updateControlVisibilityMap(masterFieldsMap, masterFieldId);
        });
    });
  }

  private updateControlVisibilityMap(masterFieldsMap: Record<string, string[]>, masterFieldId: string): void {
    masterFieldsMap[masterFieldId].forEach(dependentFieldId => {
      const isFieldVisible = this.isFieldVisible(dependentFieldId);

      this.controlVisibilityMap[dependentFieldId] = isFieldVisible;
      this.updateControlValidators(dependentFieldId, isFieldVisible);
    });
  }

  private isFieldVisible(fieldKey: string): boolean {
    const field = this.fields[fieldKey];

    if (!field) {
      return false;
    }

    if (!_.isNil(field.depends_on_field_id)) {
      const mainFieldKey = this.getFieldKeyById(field.depends_on_field_id);
      return this.isMainOptionSelected(mainFieldKey, fieldKey);
    }

    if (field.related_field_id && !_.isNil(field.related_option_id)) {
      const mainFieldKey = this.getFieldKeyById(field.related_field_id);
      return this.isRelatedFieldOptionSelected(mainFieldKey, field.related_option_id);
    }

    return true;
  }

  /**.
   * Method for removing and recovering control validators
   *
   * @param fieldId - name of field in the group
   * @param isVisible - flag which shows that field is visible or not
   *
   * Method is used because of some fields can be invisible and invalid,
   * that's why all form is invalid and proceeding is enabled.
   * It helps to ignore invisible fields.
   */
  private updateControlValidators(fieldId: string, isVisible: boolean): void {
    const control = this.form.controls[fieldId];

    if (!control) {
      return;
    }

    if (isVisible) {
      const validators = FormWrapper.reduceValidators(this.fields[fieldId].rules);
      control.setValidators(validators);
    } else {
      control.reset();
      control.clearValidators();
    }

    control.updateValueAndValidity();
  }

  private getFieldKeyById(id: number | string | null | undefined): string | undefined {
    if (_.isNil(id)) {
      return undefined;
    }
    let result: string | undefined = undefined;
    for (const key in this.fields) {
      if (this.fields[key].id === id) {
        result = key;
        break;
      }
    }
    return result;
  }

  public onFormArrayChange(key: string, event: UntypedFormArray): void {
    this.form.controls[key] = event;
    this.form.updateValueAndValidity();

    this.formArrayChanged.next(key);
  }
}
