import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable, Injector, Provider} from '@angular/core';
import {TooManyRequestsException} from '@app/core/exceptions/too-many-requests-exception';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {ABaseFullscreenErrorService} from './base-fullscreen-error.service';

@Injectable({
  providedIn: 'root',
})
export class TooManyRequestsInterceptor extends ABaseFullscreenErrorService implements HttpInterceptor {
  constructor(injector: Injector, private readonly translate: TranslateService) {
    super(injector);
  }

  public intercept(req: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Response>> {
    return next.handle(req).pipe(
      tap((response: HttpResponse<Response>) => {
        if (response instanceof HttpResponse) {
          if (
            response.status === TooManyRequestsException.STATUS_CODE ||
            (response.body && response.body.status === TooManyRequestsException.STATUS_CODE)
          ) {
            this.showDialog({
              width: '100%',
              height: '100%',
              closeOnNavigation: true,
              hasBackdrop: false,
              panelClass: ['error-dialog'],
              data: {
                header: this.translate.get('You have reached the limit') as Observable<string>,
                text: this.translate.get('Please, come back later') as Observable<string>,
                emoji: 'emoji-hourglass',
                textAccentButton: this.translate.get('Refresh page') as Observable<string>,
                textPrimaryButton: this.translate.get('Close') as Observable<string>,
                onAccentButton: (): void => location.reload(),
                onPrimaryButton: this.closeDialog,
                countdownText: this.translate.get('Requests will be able after') as Observable<string>,
                countdownFinal: this.closeDialog,
                countdownTime: getExpiredCountdown(response.body as unknown as ICountdownInfo),
              },
            });
          }
        }
      }),
    );
  }
}

function getExpiredCountdown(body: ICountdownInfo): number {
  if (body.data && body.data.data && (body.data.data.block || body.data.data.expired)) {
    const data = body.data.data;
    const expiredAt = new Date(data.expired || data.block?.expired_at).getTime();
    const now = new Date().getTime();

    return Math.ceil((expiredAt - now) / 1000);
  }

  return 0;
}

interface ICountdownInfo {
  data: {
    data: ICountdownData;
  };
}

interface ICountdownData {
  block?: IBlockInfo;
  expired?: string;
}

export interface IBlockInfo {
  id: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  client_id?: number;
  ip: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  created_at: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expired_at: string;
}

export const TooManyRequestsErrorService: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TooManyRequestsInterceptor,
  multi: true,
};
