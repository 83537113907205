import {Expose, plainToInstance} from 'class-transformer';
import {IsBoolean, IsOptional, IsString} from 'class-validator';

import {GridsterWidgetSettings} from '../../../core/models/gridster/gridster-widget-settings';

export class TradesSettings {
  public static makeInstance(config: GridsterWidgetSettings): TradesSettings {
    if (!config) {
      throw new Error('Config is undefined');
    }

    return plainToInstance(TradesSettings, config, {excludeExtraneousValues: true});
  }

  public static getInstance(): TradesSettings {
    return plainToInstance(TradesSettings, {pair: '', isShowSide: true}, {excludeExtraneousValues: true});
  }

  @Expose()
  @IsOptional()
  @IsString()
  public pair?: string;

  @Expose()
  @IsBoolean()
  public isShowSide: boolean;
}
