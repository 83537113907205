import {Injectable} from '@angular/core';
import {GridsterEventType} from '@app/core/models/gridster';
import {GridsterWidgetsEventsService} from '@app/gridster/events/gridster-widgets-events.service';
import {ECommonWidgetKeys} from '@app/gridster/widgets/gridster-widgets';

import {ESynchronizationColors} from '../enum/moex/synchronization-colors';
import {IInstrumentSynchronizationEvent} from '../interfaces/moex/instrument-synchronization-event';
import {WarpInstrument} from '../models/instrument';

@Injectable()
export class MoexSynchronizeInstrumentService {
  constructor(private readonly gridsterWidgetsEventsService: GridsterWidgetsEventsService) {}

  public synchronizeInstrument(
    warpInstrument: WarpInstrument,
    widget: ECommonWidgetKeys,
    activeColor: ESynchronizationColors,
  ): void {
    if (activeColor === ESynchronizationColors.DefaultColor) {
      return;
    }

    const data: IInstrumentSynchronizationEvent = {
      instrument: warpInstrument,
      color: activeColor,
    };

    this.gridsterWidgetsEventsService.sendEvent({
      type: GridsterEventType.moexSyncInstrument,
      widget,
      payload: data,
    });
  }
}
