import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {ISnackData, ESnackMessageTypes} from '@app/snackbar/snackbar.service';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent {
  public readonly types = ESnackMessageTypes;
  public readonly message: string;

  public readonly animationDuration: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) private readonly data: ISnackData, private readonly snackBar: MatSnackBar) {
    this.message = data.message;
    this.animationDuration = `${data.duration}ms`;
  }

  public is(type: ESnackMessageTypes): boolean {
    return this.data.type === type;
  }

  public closeSnackBar(): void {
    this.snackBar.dismiss();
  }
}
