import {IMenuPointDto} from '@app/core/menu/models/menu-point.dto';
import {MenuPoint} from '@env/environment.entities';
import * as _ from 'lodash-es';

import {EMenuPointCodeApi} from '../enum/menu-point-code-api';
import {TMenuInfo} from '../types/menu-info';
import {MenuPointBuilder} from './menu-point-builder';

export class MenuBuilder {
  private readonly platformMappedKeys = new Map<EMenuPointCodeApi, EMenuPointCodeApi>([
    [EMenuPointCodeApi.MT4, EMenuPointCodeApi.PlatformsMT4],
    [EMenuPointCodeApi.MT5, EMenuPointCodeApi.PlatformsMT5],
    [EMenuPointCodeApi.CTrader, EMenuPointCodeApi.PlatformsCTrader],
    [EMenuPointCodeApi.DxTrade, EMenuPointCodeApi.PlatformsDxTrade],
    [EMenuPointCodeApi.MarginalPlatform, EMenuPointCodeApi.PlatformsMarginal],
    [EMenuPointCodeApi.MatchTrader, EMenuPointCodeApi.PlatformsMatchTrader],
  ]);

  private readonly analyticsMappedKeys = new Map<EMenuPointCodeApi, EMenuPointCodeApi>([
    [EMenuPointCodeApi.MT4, EMenuPointCodeApi.AnalyticsMT4],
    [EMenuPointCodeApi.MT5, EMenuPointCodeApi.AnalyticsMT5],
    [EMenuPointCodeApi.CTrader, EMenuPointCodeApi.AnalyticsCTrader],
  ]);

  constructor(private readonly menuItems: IMenuPointDto[]) {}

  private isPlatformMenuPoint(point: IMenuPointDto): boolean {
    return point.name === EMenuPointCodeApi.Platforms;
  }

  private isHistoryMenuPoint(point: IMenuPointDto): boolean {
    return point.name === EMenuPointCodeApi.History;
  }

  private isAnalyticsMenuPoint(point: IMenuPointDto): boolean {
    return point.name === EMenuPointCodeApi.Analytics;
  }

  private getTransformedDto(): IMenuPointDto[] {
    const platformMenuPoint = this.menuItems.find(i => this.isPlatformMenuPoint(i));
    const isAloneChildForPlatformMenuPoint = platformMenuPoint?.items.length === 1;

    if (isAloneChildForPlatformMenuPoint) {
      return this.menuItems.map(point => {
        if (this.isPlatformMenuPoint(point)) {
          return {
            name: this.platformMappedKeys.get(_.first(point.items).name),
            items: [],
            // eslint-disable-next-line @typescript-eslint/naming-convention
            new: point.new,
            id: point.id,
          };
        }

        if (this.isHistoryMenuPoint(point)) {
          return {
            name: point.name,
            items: point.items.map(childPoint => {
              if (this.isAnalyticsMenuPoint(childPoint)) {
                return {
                  name: this.analyticsMappedKeys.get(_.first(platformMenuPoint.items).name),
                  items: [],
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  new: point.new,
                  id: point.id,
                };
              }
              return childPoint;
            }),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            new: point.new,
            id: point.id,
          };
        }

        return point;
      });
    }

    return this.menuItems;
  }

  public getMenu(): TMenuInfo {
    const items = this.getTransformedDto();

    return items.reduce<TMenuInfo>(
      (menuInfo, point) => {
        const [pointInfo, accessAliasKeysPoint] = MenuPointBuilder.getRawMenuInfo(point);
        const pointInstance = MenuPoint.createInstance(pointInfo);

        pointInstance.isAvailableForHomeUrl = !!accessAliasKeysPoint?.length;

        menuInfo[0].push(pointInstance);

        const filteredAccessAliasKeysPoint = _.filter(accessAliasKeysPoint, alias => !menuInfo[1].includes(alias));
        menuInfo[1].push(...filteredAccessAliasKeysPoint);
        return menuInfo;
      },
      [[], []],
    );
  }
}
