import {ICurrencyPositionMetrics} from '@app/trading-board/interfaces/b2margin/account';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {Transform} from 'class-transformer';

export class CurrencyPositionsMetrics {
  @Transform(({value, obj}: {value: number; obj: ICurrencyPositionMetrics}) =>
    DecimalHelper.from(value, obj.currencyPrecision),
  )
  public allocatedFunds: DecimalHelper;

  @Transform(({value, obj}: {value: number; obj: ICurrencyPositionMetrics}) =>
    DecimalHelper.from(value, obj.currencyPrecision),
  )
  public availableBalanceForWithdrawal: DecimalHelper;

  @Transform(({value, obj}: {value: number; obj: ICurrencyPositionMetrics}) =>
    DecimalHelper.from(value, obj.currencyPrecision),
  )
  public availableFunds: DecimalHelper;

  @Transform(({value, obj}: {value: number; obj: ICurrencyPositionMetrics}) =>
    DecimalHelper.from(value, obj.currencyPrecision),
  )
  public balance: DecimalHelper;
  public currency: string;
  public currencyPrecision: number;
}
