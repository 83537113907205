import {Expose, plainToClass, Type} from 'class-transformer';
import {IsBoolean, IsDefined, IsOptional, IsString, ValidateNested} from 'class-validator';

import {GridsterItem, IGridsterItem} from './gridster-item';

export interface IGridsterLayout {
  id: string;
  nameSpace: string;
  isRequired?: boolean;
  widgets: Partial<IGridsterItem>[];
}

export class GridsterLayout implements IGridsterLayout {
  @Expose()
  @IsDefined()
  @IsString()
  public id: string;

  @Expose()
  @IsDefined()
  @IsString()
  public nameSpace: string;

  @Expose()
  @IsOptional()
  @IsBoolean()
  public isRequired?: boolean;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => GridsterItem)
  public widgets: GridsterItem[];

  public reset(defaultLayout: IGridsterLayout): GridsterLayout {
    this.setNamespace(defaultLayout.nameSpace);

    const newLayout: GridsterItem[] = [];

    const currentLayoutMap = this.widgets.reduce(
      (acc, widget) => acc.set(widget.id, widget),
      new Map<string, GridsterItem>(),
    );

    defaultLayout.widgets.forEach((widgetFromDefaultLayout: GridsterItem, widgetIndex) => {
      let widgetForNewLayout = currentLayoutMap.get(widgetFromDefaultLayout.id);

      if (widgetForNewLayout) {
        widgetForNewLayout.reset(widgetFromDefaultLayout, widgetIndex);
      } else {
        widgetForNewLayout = plainToClass(GridsterItem, widgetFromDefaultLayout, {excludeExtraneousValues: true});
      }

      newLayout.push(widgetForNewLayout);
    });

    this.widgets = newLayout;

    return this;
  }

  public setNamespace(spaceName: string): void {
    this.nameSpace = spaceName;
  }
}
