import {DecimalHelper} from '@b2broker/decimal-helper';
import {Transform} from 'class-transformer';

export class PortfolioSummary {
  @Transform(({value}) => DecimalHelper.from(value))
  public buyingPowerAtMorning: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public buyingPower: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public profit: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public profitRate: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public portfolioEvaluation: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public portfolioLiquidationValue: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public initialMargin: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public riskBeforeForcePositionClosing: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public commission: DecimalHelper;
}
