import {Injectable} from '@angular/core';
import {WarpInstrumentHelper} from '@app/pbsr/helpers/warp-instrument.helper';
import {IRawWarpInstrument} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-warp-instrument';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {WarpAuthService} from '@app/trading-board/services/warp-auth.service';
import {Environment} from '@env/environment.entities';
import {plainToInstance} from 'class-transformer';
import {Observable, combineLatest, map} from 'rxjs';

import {WarpApiService} from '../warp-api.service';

@Injectable({providedIn: 'root'})
export abstract class AWarpInstrumentService {
  protected readonly baseUrl = `${this.environment.apiUrl}/trading`;
  protected readonly exchange = 'MOEX';

  constructor(
    private readonly warpApiService: WarpApiService,
    private readonly environment: Environment,
    protected readonly warpAuthService: WarpAuthService,
  ) {}

  public getInstrumentsBySearchString(searchString: string): Observable<WarpInstrument[]> {
    const url = `${this.baseUrl}/md/v2/Securities?exchange=${this.exchange}&query=${searchString}`;

    return this.warpAuthService.fromMoexFetch<IRawWarpInstrument[]>(url).pipe(
      map(rawInstruments => this.getInstrumentsFromRaw(rawInstruments)),
      map(instruments =>
        instruments.filter(instrument =>
          WarpInstrumentHelper.isInstrumentAvailable(instrument.cfiCode, instrument.symbol),
        ),
      ),
    );
  }

  public getCurrencies(): Observable<WarpInstrument[]> {
    return combineLatest(
      WarpInstrumentHelper.getAvailableCurrencies().map(currency =>
        this.warpApiService.getInstrumentsBySymbol(currency),
      ),
    );
  }

  protected getInstruments(url: string): Observable<WarpInstrument[]> {
    return this.warpAuthService
      .fromMoexFetch<IRawWarpInstrument[]>(url)
      .pipe(map(rawInstruments => this.getInstrumentsFromRaw(rawInstruments)));
  }

  protected getInstrumentsFromRaw(rawInstruments: IRawWarpInstrument[]): WarpInstrument[] {
    return rawInstruments.map(rawInstrument => plainToInstance(WarpInstrument, WarpInstrument.adapt(rawInstrument)));
  }
}
