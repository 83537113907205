import {ConversionRateTO} from '@b2broker/b2margin-trade-models';
import {plainToInstance} from 'class-transformer';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {Rate} from '../../../models/b2margin/rate';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';
import {EEventHandlersReceived} from '../../event-handlers.enum';

export class RatesInternalEventHandler extends AEventHandler<IB2marginMessage<ConversionRateTO[]>> {
  public type = EB2MarginEventType.ConversionRate;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<ConversionRateTO[]>): void {
    this.store.messages$.next({
      type: EEventHandlersReceived.Rates,
      payload: plainToInstance(Rate, resource.body, {excludeExtraneousValues: true}),
    });
  }
}

b2MarginInternalEventHandlers.push(RatesInternalEventHandler);
