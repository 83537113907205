import {Account} from '@app/core/models/account/account';
import {User} from '@app/core/models/user/user';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Hooks {
  export function externalLayout(): void {
    dispatchEvent(ETradingRoomEvent.External);
  }

  export function advancedTrading(user: User): void {
    dispatchEvent(ETradingRoomEvent.Advanced, {user});
  }

  export function internalLayout(user: User): void {
    dispatchEvent(ETradingRoomEvent.Internal, {user});
  }

  export function successLogin(user: User): void {
    dispatchEvent(ETradingRoomEvent.SuccessLogin, {user});
  }

  export function regFilled(): void {
    dispatchEvent(ETradingRoomEvent.RegistrationFilled);
  }

  export function registered(): void {
    dispatchEvent(ETradingRoomEvent.Registered);
  }

  export function createDemoAccount(): void {
    dispatchEvent(ETradingRoomEvent.CreateDemoAccount);
  }

  export function createLiveAccount(): void {
    dispatchEvent(ETradingRoomEvent.CreateLiveAccount);
  }

  export function walletPage(): void {
    dispatchEvent(ETradingRoomEvent.Wallet);
  }

  export function exchangePage(): void {
    dispatchEvent(ETradingRoomEvent.Exchange);
  }

  export function paymentPage(): void {
    dispatchEvent(ETradingRoomEvent.Payment);
  }

  export function paymentProceed(account: Account): void {
    dispatchEvent(ETradingRoomEvent.PaymentProceed, {alpha: account.product.currency.alpha});
  }

  export function payoutPage(): void {
    dispatchEvent(ETradingRoomEvent.Payout);
  }

  export function payoutProceed(account: Account): void {
    dispatchEvent(ETradingRoomEvent.PayoutProceed, {alpha: account.product.currency.alpha});
  }

  export function historyAll(): void {
    dispatchEvent(ETradingRoomEvent.HistoryAll);
  }

  export function historyExchange(): void {
    dispatchEvent(ETradingRoomEvent.HistoryExchange);
  }

  export function historyPayment(): void {
    dispatchEvent(ETradingRoomEvent.HistoryPayment);
  }

  export function historyPayout(): void {
    dispatchEvent(ETradingRoomEvent.HistoryPayout);
  }

  export function profileSettings(): void {
    dispatchEvent(ETradingRoomEvent.ProfileSettings);
  }

  export function prod(): void {
    dispatchEvent(ETradingRoomEvent.Prod);
  }

  // eslint-disable-next-line no-inner-declarations
  function dispatchEvent<T>(event: ETradingRoomEvent, detail?: T): void {
    document.dispatchEvent(new CustomEvent(event, {detail}));
  }

  enum ETradingRoomEvent {
    External = 'TR_EXTERNAL',
    Internal = 'TR_INTERNAL',
    Advanced = 'TR_ADVANCED',
    SuccessLogin = 'TR_SUCCESS_LOGIN',
    RegistrationFilled = 'TR_REGISTRATION_FILLED',
    Registered = 'TR_REGISTERED',
    CreateLiveAccount = 'TR_CREATE_LIVE_ACCOUNT',
    CreateDemoAccount = 'TR_CREATE_DEMO_ACCOUNT',
    Wallet = 'TR_WALLET',
    Exchange = 'TR_EXCHANGE',
    Payment = 'TR_PAYMENT',
    PaymentProceed = 'TR_PAYMENT_PROCEED',
    Payout = 'TR_PAYOUT',
    PayoutProceed = 'TR_PAYOUT_PROCEED',
    HistoryAll = 'TR_HISTORY_ALL',
    HistoryExchange = 'TR_HISTORY_EXCHANGE',
    HistoryPayment = 'TR_HISTORY_PAYMENT',
    HistoryPayout = 'TR_HISTORY_EXCHANGE',
    ProfileSettings = 'TR_PROFILE_SETTINGS',
    Prod = 'TR_PROD',
  }
}

export default Hooks;
