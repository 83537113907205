import {Injectable} from '@angular/core';
import {LanguageStateModel} from '@app/language/language.state';

@Injectable()
export class ResetService {
  public initialState = {};

  public updateLanguage(language: LanguageStateModel): void {
    this.initialState = {...this.initialState, language};
  }
}
