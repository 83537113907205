<ng-container [formGroup]="formGroup">
  <div class="code-wrapper">
    <div class="code-container length-{{ length }}" [class.error]="control.errors" [class.long]="length > 5" appDir>
      <input pattern="[0-9]*" [class.empty]="!control.value" [formControl]="control" [autofocus] [mask]="mask" />
      <mat-hint *ngIf="field.label.hint" [align]="field.label.hint.position || 'start'">{{
        getHintLabel() | async
      }}</mat-hint>
      <div class="mirror">
        <span>{{ control.value }}</span>
      </div>
      <div class="input-underline">
        <div *ngFor="let s of symbols" class="underline-item"></div>
      </div>
    </div>
    <div class="code-error">
      <ng-container *ngIf="control.invalid && control.dirty && control.touched">
        <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
          {{ getError(err) | async }}
        </mat-error>
      </ng-container>
    </div>
  </div>
</ng-container>
