import {Injectable} from '@angular/core';
import {IRawOrder} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-order.interface';
import {IRawWarpInstrument} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-warp-instrument';
import {IRawWarpTrade} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-warp-trade.interface';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {WarpOrder} from '@app/trading-board/models/moex/order';
import {WarpTrade} from '@app/trading-board/models/moex/warp-trade';
import {WarpAuthService} from '@app/trading-board/services/warp-auth.service';
import {Environment} from '@env/environment.entities';
import {plainToInstance} from 'class-transformer';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {IAccountTradingData} from '../interfaces/account-trading-data.interface';

@Injectable({providedIn: 'root'})
export class WarpApiService {
  public readonly baseUrl = `${this.environment.apiUrl}/trading`;

  constructor(private readonly environment: Environment, private readonly warpAuthService: WarpAuthService) {}

  public getOrders(accountTradingData: IAccountTradingData): Observable<WarpOrder[]> {
    const url = `${this.baseUrl}/md/v2/clients/MOEX/${accountTradingData.tradeCode}/orders`;

    return this.requestOrders(url, accountTradingData);
  }

  public getStopOrders(accountTradingData: IAccountTradingData): Observable<WarpOrder[]> {
    const url = `${this.baseUrl}/md/v2/clients/MOEX/${accountTradingData.tradeCode}/stoporders`;

    return this.requestOrders(url, accountTradingData);
  }

  public getTrades(accountTradingData: IAccountTradingData): Observable<WarpTrade[]> {
    const url = `${this.baseUrl}/md/v2/Clients/MOEX/${accountTradingData.tradeCode}/trades`;

    return this.warpAuthService.fromMoexFetch<IRawWarpTrade[]>(url, {method: 'GET'}).pipe(
      map(response => {
        const trades = response.map(trade =>
          plainToInstance(WarpTrade, {
            ...trade,
            parentAccountId: accountTradingData.parentAccountId,
            parentAccountCaption: accountTradingData.parentAccountCaption,
            subAccountId: accountTradingData.subAccountId,
            subAccountCaption: accountTradingData.subAccountCaption,
            tradeCode: accountTradingData.tradeCode,
          }),
        );

        return trades;
      }),
    );
  }

  public getInstrumentsBySymbol(searchSymbol: string): Observable<WarpInstrument | undefined> {
    const url = `${this.baseUrl}/md/v2/Securities/MOEX/${searchSymbol}`;

    return this.requestInstruments<IRawWarpInstrument>(url).pipe(
      map(instrument => this.getSingleWarpInstrumentFromRaw(instrument)),
      catchError(() => of(undefined)),
    );
  }

  public getInstrumentsWithQueryParam(searchSymbol: string): Observable<WarpInstrument[]> {
    const url = `${this.baseUrl}/md/v2/Securities?exchange=MOEX&query=${searchSymbol}`;

    return this.requestInstruments<IRawWarpInstrument[]>(url).pipe(
      map(instrument => this.getWarpInstrumentsFromRaw(instrument)),
    );
  }

  public getInstruments(): Observable<WarpInstrument[]> {
    const url = `${this.baseUrl}/md/v2/Securities/MOEX`;

    return this.requestInstruments<IRawWarpInstrument[]>(url).pipe(
      map(instrument => this.getWarpInstrumentsFromRaw(instrument)),
    );
  }

  private requestOrders(url: string, accountTradingData: IAccountTradingData): Observable<WarpOrder[]> {
    return this.warpAuthService.fromMoexFetch<IRawOrder[]>(url, {method: 'GET'}).pipe(
      map(response => {
        const orders = response.map(rawOrder =>
          plainToInstance(WarpOrder, {
            ...rawOrder,
            parentAccountId: accountTradingData.parentAccountId,
            parentAccountCaption: accountTradingData.parentAccountCaption,
            subAccountId: accountTradingData.subAccountId,
            subAccountCaption: accountTradingData.subAccountCaption,
            tradeCode: accountTradingData.tradeCode,
          }),
        );

        return orders;
      }),
    );
  }

  private requestInstruments<T>(url: string): Observable<T> {
    return this.warpAuthService.fromMoexFetch<T>(url, {method: 'GET'});
  }

  private getWarpInstrumentsFromRaw(rawInstruments: IRawWarpInstrument[]): WarpInstrument[] {
    return rawInstruments.map(rawInstrument => this.getSingleWarpInstrumentFromRaw(rawInstrument));
  }

  private getSingleWarpInstrumentFromRaw(rawInstrument: IRawWarpInstrument): WarpInstrument {
    return plainToInstance(WarpInstrument, WarpInstrument.adapt(rawInstrument));
  }
}
