import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GridsterActivityHandlerService} from '@app/activity-tracker';
import {Token} from '@app/core/models/auth/token';
import {TokenResponse} from '@app/core/models/auth/token-response';
import {GridsterExcessiveActivityErrorService} from '@app/core/modules/errors/gridster-excessive-activity-error.service';
import Hooks from '@app/core/services/hooks.service';
import {LogoutService} from '@app/core/services/logout.service';
import {TitleService} from '@app/core/services/title.service';
import {reloadCurrentPage} from '@app/core/utils/router';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {IOfflineComponentData, OfflineComponent} from '@app/ui/offline/offline.component';
import {OfflineService} from '@app/ui/offline/offline.service';
import {environment as env} from '@env/environment';
import {Environment} from '@env/environment.entities';
import {plainToClass} from 'class-transformer';
import {fromEvent, Observable, Subject} from 'rxjs';
import {delay, filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {MenuService} from './core/menu/services/menu.service';
import {WINDOW} from './core/tokens/window.token';
import {shareReplayWithRef} from './core/utils/share-replay-with-ref';
import {SigningFormComponent} from './ui/signing-form/signing-form.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private static readonly STORAGE_UPDATE_DELAY_IN_MS = 1000;

  private readonly destroyer$ = new Subject<void>();

  private overlayDialogRef: MatDialogRef<OfflineComponent | SigningFormComponent>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly environment: Environment,
    private readonly logoutService: LogoutService,
    private readonly offlineService: OfflineService,
    private readonly titleService: TitleService,
    private readonly menuService: MenuService,
    @Inject(WINDOW) private readonly window: Window,
    gridsterActivityHandlerService: GridsterActivityHandlerService,
    gridsterExcessiveActivityErrorService: GridsterExcessiveActivityErrorService,
  ) {
    this.titleService.routerWatcher();
    this.clearExpiredTokensOnAppStart();
    this.subscribeOnStorageChange();

    gridsterActivityHandlerService.excessiveActivity$.pipe(takeUntil(this.destroyer$)).subscribe(() => {
      gridsterExcessiveActivityErrorService.displayError();
    });
  }

  private clearExpiredTokensOnAppStart(): void {
    const accessTokenResponse = JSON.parse(ALocaleStorage.ACCESS_TOKEN.get()) as TokenResponse | null;

    const refreshTokenResponse = JSON.parse(ALocaleStorage.REFRESH_TOKEN.get()) as TokenResponse | null;
    const refreshTokenData = refreshTokenResponse ? plainToClass(Token, refreshTokenResponse).data : null;

    if (!accessTokenResponse || !refreshTokenData || refreshTokenData.expiresTime <= new Date()) {
      ALocaleStorage.ACCESS_TOKEN.remove();
      ALocaleStorage.REFRESH_TOKEN.remove();
    }
  }

  private subscribeOnStorageChange(): void {
    const {writeAccessToken$, clearStorage$} = this.getStorageEvents();

    writeAccessToken$.pipe(takeUntil(this.destroyer$)).subscribe(({event, homeUrl}) => {
      if (!event.oldValue) {
        // When user login - redirects to the home url on other open tabs.
        void this.router.navigateByUrl(homeUrl);
        return;
      }

      // In other case - just reload the current page
      void reloadCurrentPage(this.router);
    });

    clearStorage$.pipe(takeUntil(this.destroyer$)).subscribe(() => void this.logoutService.logout());
  }

  private getStorageEvents(): TStorageEvents {
    const storageChange$ = fromEvent<StorageEvent>(this.window, 'storage').pipe(
      delay(AppComponent.STORAGE_UPDATE_DELAY_IN_MS),
      shareReplayWithRef(),
    );

    const writeAccessToken$ = storageChange$.pipe(
      filter(e => !!(e.key === ALocaleStorage.ACCESS_TOKEN.key && e.newValue)),
      switchMap(e => this.menuService.getHomeUrl().pipe(map(homeUrl => ({event: e, homeUrl})))),
    );

    const clearStorage$ = storageChange$.pipe(
      filter(e => !!(!e.key && !e.storageArea[ALocaleStorage.ACCESS_TOKEN.key])),
    );

    return {writeAccessToken$, clearStorage$};
  }

  private offlineWarning(): void {
    this.overlayDialogRef?.close();
    this.overlayDialogRef = this.dialog.open<OfflineComponent, IOfflineComponentData>(OfflineComponent, {
      width: '100%',
      height: '100%',
      closeOnNavigation: false,
      hasBackdrop: false,
      panelClass: 'error-dialog',
    });
  }

  public ngOnInit(): void {
    // eslint-disable-next-line no-console
    console.log(
      `%cApp version: ${this.environment.appBuild.appVersion.trim()}`,
      'background: #EBF5F8; color: gray; font-size: x-medium; border-radius: 5px; padding: 5px;',
    );

    this.offlineService.offLine.pipe(take(1), takeUntil(this.destroyer$)).subscribe(() => this.offlineWarning());

    DecimalHelper.locale = this.environment.locale;

    if (env.production) {
      Hooks.prod();
    }
  }

  public ngOnDestroy(): void {
    this.overlayDialogRef?.close();

    this.destroyer$.next();
    this.destroyer$.complete();
  }
}

type TStorageEvents = Readonly<{
  readonly writeAccessToken$: Observable<{event: StorageEvent; homeUrl: string}>;
  readonly clearStorage$: Observable<StorageEvent>;
}>;
