<a
  *ngIf="'logoUrl' | translate; else defaultTemplate"
  [href]="'logoUrl' | translate"
  target="_blank"
  class="logo"
  title="{{ projectInfo.name }}"
  [class.min]="isMin"
  appDir
>
  <ng-container [ngTemplateOutlet]="logoImage"></ng-container>
</a>

<ng-template #defaultTemplate>
  <a routerLink="/" class="logo" title="{{ projectInfo.name }}" [class.min]="isMin" appDir>
    <ng-container [ngTemplateOutlet]="logoImage"></ng-container>
  </a>
</ng-template>

<ng-template #logoImage>
  <img [src]="fullLogoUrl" [alt]="projectInfo.name" class="logo-full" />

  <img
    src="/assets/images/logomin-{{ currentTheme }}.svg?cacheOff={{ environment.appBuild.cacheOff }}"
    [alt]="projectInfo.name"
    class="logo-min"
  />
</ng-template>
