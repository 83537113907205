import {IExceptions} from '@app/core/contracts/i.exceptions';
import {MonoTypeOperatorFunction} from 'rxjs';
import {filter} from 'rxjs/operators';

import {IResultApi} from '../contracts/i.result.api';
import {Exceptions} from './exceptions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ResponseException<T = any> extends Exceptions<T> {
  public static readonly STATUS_CODE = 200;

  constructor(body: IResultApi) {
    super(body);
  }
}

export function isResponse<T>(exception: IExceptions<T>): boolean {
  return exception instanceof ResponseException;
}

export function responseFilter<T>(): MonoTypeOperatorFunction<IExceptions<T>> {
  return filter<IExceptions<T>>((response: IExceptions<T>) => isResponse(response));
}
