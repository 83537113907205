export enum EB2MarginEventType {
  Instruments = 'INSTRUMENTS',
  TradeHistory = 'TRADE_HISTORY',
  History = 'HISTORY',
  InstrumentsVisibility = 'INSTRUMENTS_VISIBILITY',
  InstrumentMetrics = 'INSTRUMENT_METRICS',
  Ladder = 'LADDER',
  GlobalChartSettings = 'GLOBAL_CHART_SETTINGS',
  PrivateLayoutNames = 'PRIVATE_LAYOUT_NAMES',
  UserSettings = 'USER_SETTINGS',
  Message = 'MESSAGE',
  Positions = 'POSITIONS',
  PositionMetrics = 'POSITION_METRICS',
  MarginImpact = 'MARGIN_IMPACT',
  ChartFeed = 'chartFeedSubtopic',
  UserLoginStatus = 'USER_LOGIN_STATUS',
  ServerInfo = 'SERVER_INFO',
  Limits = 'LIMITS',
  TieredMarkups = 'TIERED_MARKUPS',
  AccountSettings = 'ACCOUNT_SETTINGS',
  QuoteSubscriptionInitialized = 'QUOTE_SUBSCRIPTION_INITIALIZED',
  TradeStatuses = 'TRADE_STATUSES',
  Orders = 'ORDERS',
  WatchlistSubscriptionInitialized = 'WATCHLIST_SUBSCRIPTION_INITIALIZED',
  UserLoginInfo = 'USER_LOGIN_INFO',
  RestrictionRules = 'RESTRICTION_RULES',
  Accounts = 'ACCOUNTS',
  AccountSwitched = 'ACCOUNT_SWITCHED',
  AccountsUpdate = 'ACCOUNTS_UPDATE',
  AccountMetrics = 'ACCOUNT_METRICS',
  AvailableWatchlists = 'AVAILABLE_WATCHLISTS',
  Layout = 'LAYOUT',
  Trade = 'TRADE',
  PositionCashTransfers = 'POSITION_CASH_TRANSFERS',
  HistoryOrder = 'HISTORY_ORDER',
  Quote = 'QUOTE',
  Summary = 'SUMMARY',
  Profile = 'PROFILE',
  TradingCalendar = 'TRADING_CALENDAR',
  ConversionRate = 'CONVERSION_RATE',
  TimeAndSale = 'TIME_AND_SALE',

  MarginRates = 'MARGIN_RATES',
  OrderStatuses = 'ORDER_STATUSES',
  PopupMessages = 'POPUP_MESSAGE',
}
