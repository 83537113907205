import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormFieldOption} from '@app/core/form/entities/form.field';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {OptionsService} from '@app/core/form/services/options.service';

@Component({
  selector: 'app-question-field',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  providers: [OptionsService],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionComponent extends FormInput implements OnInit {
  public options: FormFieldOption[];

  constructor(
    labelService: LabelService,
    errorsService: ErrorsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly optionsService: OptionsService,
  ) {
    super(labelService, errorsService);
  }

  public ngOnInit(): void {
    this.getOptions();
  }

  private getOptions(): void {
    if (this.field.options && this.field.options.length) {
      this.options = this.field.options;
    } else {
      this.loadOptions();
    }
  }

  private loadOptions(): void {
    this.optionsService.getOptions(this.field.optionsType).subscribe(options => {
      this.options = options;
      this.cdr.detectChanges();
    });
  }
}
