import {IExceptions} from '../contracts/i.exceptions';
import {IResultApi} from '../contracts/i.result.api';

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export abstract class Exceptions<T = any> implements IExceptions {
  public static readonly STATUS_CODE: number;

  protected constructor(protected body: IResultApi | null) {}

  public getData(): T | null {
    if (this.body === null) {
      return null;
    }

    return this.body.data as T;
  }

  public getStatus(): number | null {
    if (this.body === null) {
      return null;
    }

    return this.body.status;
  }
}
