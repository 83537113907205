import {Component, ChangeDetectionStrategy, Output, EventEmitter, Input, TrackByFunction} from '@angular/core';
import {Decimal} from '@app/core/decimal';
import * as _ from 'lodash-es';

import {percentageList} from './config/percentage-list.config';
import {IPercentageItem} from './interfaces/percentage-item.interface';

@Component({
  selector: 'app-select-percentage',
  templateUrl: './select-percentage.component.html',
  styleUrls: ['./select-percentage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPercentageComponent {
  public readonly percentageConfig = percentageList;

  public readonly trackByPercentageId: TrackByFunction<IPercentageItem> = (
    index: number,
    percentageItem: IPercentageItem,
  ) => percentageItem.id;

  @Input()
  public balance: Decimal;

  @Input()
  public precision: number;

  @Output()
  public readonly calculatedValue = new EventEmitter<string>();

  public selectPercentage(percentageItem: IPercentageItem): void {
    if (!_.isNil(this.balance)) {
      const calculatedValue = this.balance.multiplyByNum(percentageItem.factor);

      this.calculatedValue.emit(calculatedValue.getFixed(this.precision ?? null));
    }
  }
}
