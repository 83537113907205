import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  public transform<T>(value: T[], sortFunc: (left: T, right: T) => number): T[] {
    if (!value || !value.filter) {
      return [];
    }
    if (!sortFunc) {
      return value;
    }
    return value.sort(sortFunc);
  }
}
