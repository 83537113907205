import {HistoryOrderTO} from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class HistoryOrderInternalEventHandler extends AEventHandler<IB2marginMessage<HistoryOrderTO[]>> {
  public type = EB2MarginEventType.HistoryOrder;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<HistoryOrderTO[]>): void {
    this.store.historyOrders$.next(
      resource.body.reduce((acc, item) => {
        if (!acc.find(order => order.orderId === item.orderId)) {
          acc.push(item);
        }

        return acc;
      }, this.store.historyOrders$.getValue()),
    );
  }
}

b2MarginInternalEventHandlers.push(HistoryOrderInternalEventHandler);
