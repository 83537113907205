import {Casts, CastTypes, Model} from '@app/core/model';

export class Currency extends Model {
  public alpha: string;
  public name?: string;
  public code: number;
  public precision?: number;
  public enroll: number;
  public writeoff: number;
  public caption: string;
  public localizedCaption: string;
  public localizedKeyCaption: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public has_wallet_param: boolean;

  public readonly casts: Casts = {
    alpha: [CastTypes.String],
    name: [CastTypes.Null, CastTypes.String],
    code: [CastTypes.Number],
    precision: [CastTypes.Null, CastTypes.Number],
    enroll: [CastTypes.Number],
    writeoff: [CastTypes.Number],
    caption: [CastTypes.String],
    localizedCaption: [CastTypes.String],
    localizedKeyCaption: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    has_wallet_param: [CastTypes.Null, CastTypes.Boolean],
  };
}
