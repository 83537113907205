import {IResultApi} from '@app/core/contracts/i.result.api';
import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';

import {B2bResponse} from './b2b-response';
import {CreatedException} from './created-exception';
import {DeniedException} from './denied-exception';
import {FailedDependencyException} from './failed-dependency-exception';
import {GoneException} from './gone-exception';
import {InternalServerErrorException} from './internal-server-error-exception';
import {MaintenanceException} from './maintenance-exception';
import {NotFoundException} from './not-found-exception';
import {ResponseException} from './response-exception';
import {ServiceUnavailableException} from './service-unavailable-exception';
import {TerminateException} from './terminate-exception';
import {TooManyRequestsException} from './too-many-requests-exception';
import {UnauthorizedException} from './unauthorized-exception';
import {ValidateException} from './validate-exception';

export class ClientException {
  constructor(private readonly body) {
    this.init();
  }

  private init(): void {
    switch (this.body.status) {
      case ResponseException.STATUS_CODE: {
        throw new ResponseException(this.body);
      }
      case TooManyRequestsException.STATUS_CODE: {
        throw new TooManyRequestsException(this.body);
      }
      case CreatedException.STATUS_CODE: {
        throw new CreatedException(this.body);
      }
      case ValidateException.STATUS_CODE: {
        throw new ValidateException(this.body);
      }
      case InternalServerErrorException.STATUS_CODE: {
        throw new InternalServerErrorException(this.body);
      }
      case TerminateException.STATUS_CODE: {
        throw new TerminateException(this.body);
      }
      case UnauthorizedException.STATUS_CODE: {
        throw new UnauthorizedException(this.body);
      }
      case NotFoundException.STATUS_CODE: {
        throw new NotFoundException(this.body);
      }
      case ServiceUnavailableException.STATUS_CODE: {
        throw new ServiceUnavailableException(this.body);
      }
      case DeniedException.STATUS_CODE: {
        throw new DeniedException(this.body);
      }
      case MaintenanceException.STATUS_CODE: {
        throw new MaintenanceException(this.body);
      }
      case GoneException.STATUS_CODE: {
        throw new GoneException(this.body);
      }
      case FailedDependencyException.STATUS_CODE: {
        throw new FailedDependencyException(this.body);
      }

      default:
        throw new B2bResponse({status: 200, data: this});
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapException: OperatorFunction<IResultApi<any>, any> = map(body => {
  try {
    throw new ClientException(body);
  } catch (e) {
    return e;
  }
});
