import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class SymbolQuote {
  @Expose()
  public symbol: string;

  @Expose()
  public exchange: string;

  @Expose()
  public description: string;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public ask: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public bid: DecimalHelper;

  @Expose({name: 'prev_close_price'})
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public prevClosePrice: DecimalHelper;

  @Expose({name: 'last_price'})
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public lastPrice: DecimalHelper;

  @Expose({name: 'last_price_timestamp'})
  public lastPriceTimestamp: number;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public change: DecimalHelper;

  @Expose({name: 'change_percent'})
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public changePercent: DecimalHelper;

  @Expose({name: 'high_price'})
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public highPrice: DecimalHelper;

  @Expose({name: 'low_price'})
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public lowPrice: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public accruedInt: DecimalHelper;

  @Expose({name: 'accrued_interest'})
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public accruedInterest: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public volume: DecimalHelper;

  @Expose({name: 'open_interest'})
  @Transform(({value}) => (value ? DecimalHelper.from(value) : null))
  public openInterest: DecimalHelper | null;

  @Expose({name: 'open_price'})
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public openPrice: DecimalHelper;

  @Expose()
  public yield: number | null;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public lotsize: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public lotvalue: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public facevalue: DecimalHelper;

  @Expose()
  public type: string;
}
