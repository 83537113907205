import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {plainToInstance} from 'class-transformer';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {InstrumentLimits} from '../../../models/b2margin/instrument-limits';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';
import {EEventHandlersReceived} from '../../event-handlers.enum';

export class InstrumentLimitsInternalEventHandler extends AEventHandler<
  IB2marginMessage<B2MarginModels.LimitsDomainTO[]>
> {
  public type = EB2MarginEventType.Limits;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<B2MarginModels.LimitsDomainTO[]>): void {
    this.store.messages$.next({
      type: EEventHandlersReceived.InstrumentLimits,
      payload: plainToInstance(InstrumentLimits, resource.body, {excludeExtraneousValues: true}),
    });
  }
}

b2MarginInternalEventHandlers.push(InstrumentLimitsInternalEventHandler);
