import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SearchValueService {
  private readonly _searchValue = new BehaviorSubject('');
  public searchValue$ = this._searchValue.asObservable();

  public setValue(value: string): void {
    this._searchValue.next(value);
  }

  public getValue(): string {
    return this._searchValue.getValue();
  }
}
