import {Injectable} from '@angular/core';
import {EMenuPointCodeApi} from '@app/core/menu/enum/menu-point-code-api';
import {GeneralConfigService} from '@app/core/services/configs/general-config.service';
import {shareReplayWithRef} from '@app/core/utils/share-replay-with-ref';
import {AccessAlias, Environment, MenuPoint} from '@env/environment.entities';
import * as _ from 'lodash-es';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {MenuBuilder} from '../classes/menu-builder';
import {IMenuPointDto} from '../models/menu-point.dto';
import {TMenuInfo} from '../types/menu-info';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private readonly menuInfo$ = this.generalConfigService.generalConfigInfo$.pipe(
    map(menuItemsDto => this.getMenuFromDto(menuItemsDto)),
    shareReplayWithRef(),
  );

  public get isShowAdditionalMenuPointsFirst(): boolean {
    return this.environment.isEqwireProject;
  }

  constructor(private readonly environment: Environment, private readonly generalConfigService: GeneralConfigService) {}

  private filterUnsupportedItems(menu: IMenuPointDto[]): IMenuPointDto[] {
    const supportedMenuNames = Object.values(EMenuPointCodeApi);

    return menu.reduce((filtered, item) => {
      if (supportedMenuNames.includes(item.name)) {
        if (item.items?.length > 0) {
          item.items = this.filterUnsupportedItems(item.items);
        }
        filtered.push(item);
      }
      return filtered;
    }, [] as IMenuPointDto[]);
  }

  private getMenuFromDto(value: IMenuPointDto): TMenuInfo {
    const filteredMenu = this.filterUnsupportedItems(value?.items ?? []);
    const menuBuilder = new MenuBuilder(filteredMenu);
    const [mainMenuPoints, accessAliasKeys] = menuBuilder.getMenu();

    const additionalMenuPoints = this.environment.menu?.filter(point => !!point.field.isRequired) ?? [];

    const allMenuPoints = this.isShowAdditionalMenuPointsFirst
      ? [...additionalMenuPoints, ...mainMenuPoints]
      : [...mainMenuPoints, ...additionalMenuPoints];

    const additionalAccessAliasKeys = this.environment.accessAliases;
    const allAccessAliasKeys = additionalAccessAliasKeys?.length
      ? [...accessAliasKeys, ...additionalAccessAliasKeys]
      : accessAliasKeys;

    return [_.uniq(allMenuPoints), _.uniq(allAccessAliasKeys)];
  }

  private getFirstAvailableHomeUrl(menuPoints: MenuPoint[]): string {
    const firstUrl = _.head(_.filter(menuPoints, {isAvailableForHomeUrl: true}));

    if (!firstUrl) {
      return '';
    }

    const {field, children} = firstUrl;

    if (children) {
      return field.url + _.first(children).field.url;
    }

    return field.url;
  }

  private menuHasHomeUrl(menuPoints: MenuPoint[]): boolean {
    const allAvailableUrls = menuPoints.reduce<string[]>((acc, menuPoint) => {
      if (!menuPoint.isAvailableForHomeUrl) {
        return acc;
      }

      const {field, children} = menuPoint;
      const childrenUrls = children?.map(childrenPoint => field.url + childrenPoint.field.url) ?? [];

      acc.push(field.url, ...childrenUrls);

      return acc;
    }, []);

    return allAvailableUrls.includes(this.environment.homeUrl);
  }

  public getMenuFromApi(): Observable<MenuPoint[]> {
    return this.menuInfo$.pipe(map(([menuPoints]) => menuPoints));
  }

  public getAccessAliasKeysFromApi(): Observable<AccessAlias[]> {
    return this.menuInfo$.pipe(map(([, accessAliasKeys]) => accessAliasKeys));
  }

  public getHomeUrl(): Observable<string> {
    return this.menuInfo$.pipe(
      map(([menuPoints]) =>
        this.menuHasHomeUrl(menuPoints) ? this.environment.homeUrl : this.getFirstAvailableHomeUrl(menuPoints),
      ),
    );
  }
}
