import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from '@app/core/services/auth.service';
import {Public} from '@app/shared/decorators/public.decorator';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {MenuService} from '../core/menu/services/menu.service';

@Injectable()
export class GuestAccessGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly menuService: MenuService,
  ) {}

  @Public(Promise.resolve(false))
  public canActivate(): boolean | Promise<boolean> | Observable<UrlTree> {
    if (!this.authService.isLoggedIn()) {
      return true;
    }

    return this.menuService.getHomeUrl().pipe(
      first(),
      map(url => this.router.createUrlTree([url])),
    );
  }
}
