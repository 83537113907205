import {Expose, Transform, Type} from 'class-transformer';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Class(sourceClass: any, isOptional = false): (target: any, key: string) => void {
  const expose = Expose();
  const transform = Transform(({value}) => value ?? new sourceClass(), {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    toClassOnly: true,
  });
  const type = Type(() => sourceClass);

  return (target: any, key: string) => {
    if (!isOptional) {
      expose(target, key);
    }
    type(target, key);
    transform(target, key);
  };
}
