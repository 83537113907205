import {EResidencePermissionType} from '@app/pbsr/enums/residence-permission-type.enum';
import {Expose} from 'class-transformer';

export class ResidencePermission {
  @Expose()
  public type: EResidencePermissionType;

  @Expose({name: 'type_caption'})
  public typeCaption: string;

  @Expose()
  public series: string;

  @Expose()
  public number: string;

  @Expose({name: 'visa_id'})
  public visaId?: string;

  @Expose({name: 'issued_by'})
  public issuedBy?: string;

  @Expose({name: 'date_start'})
  public dateStart?: string;

  @Expose({name: 'date_end'})
  public dateEnd: string;

  @Expose({name: 'date_issue'})
  public dateIssue?: string;
}
