import {Type} from '@angular/core';
import {PopupMessagesEventHandler} from '@app/trading-board/event-handlers/b2margin/internal/popup-messages-event-handler';
import {MessageTO} from '@b2broker/b2margin-trade-models';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';
import {OrderStatusesInternalEventHandler} from './order-statuses-internal-event-handler';

export class MessageInternalEventHandler extends AEventHandler<IB2marginMessage<MessageTO[]>> {
  public type = EB2MarginEventType.Message;

  constructor(
    private readonly store: B2marginStateStore,
    private readonly findInstance: <T>(type: Type<T>) => AEventHandler<unknown>,
  ) {
    super();
  }

  private isAfterConnect(resource: MessageTO): boolean {
    return Number(resource.timeStamp) > this.store.connectionServerTimestamp;
  }

  public handleMessage(resource: IB2marginMessage<MessageTO[]>): void {
    resource.body
      .filter(message => this.isAfterConnect(message))
      .forEach(message => {
        if (OrderStatusesInternalEventHandler.isOrder(message)) {
          this.findInstance(OrderStatusesInternalEventHandler)?.handleMessage(
            Object.assign({}, resource, {body: message}),
          );
        }

        if (PopupMessagesEventHandler.isPopupMessage(message.messageType)) {
          this.findInstance(PopupMessagesEventHandler)?.handleMessage(message);
        }
      });
  }
}

b2MarginInternalEventHandlers.push(MessageInternalEventHandler);
