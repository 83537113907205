import {
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ANIMATION_DELAY_FOR_WIZARD_SUCCESS_MESSAGE_IN_MS} from '@app/core/constants/common';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {Exceptions} from '@app/core/exceptions/exceptions';
import {InternalServerErrorException} from '@app/core/exceptions/internal-server-error-exception';
import {NotFoundException} from '@app/core/exceptions/not-found-exception';
import {ResponseException} from '@app/core/exceptions/response-exception';
import {TerminateException} from '@app/core/exceptions/terminate-exception';
import {TooManyRequestsException} from '@app/core/exceptions/too-many-requests-exception';
import {TFormRules, Workflow} from '@app/core/models/workflow';
import {DISABLE_SUBMIT_TOKEN} from '@app/core/tokens';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {ErrorDialogComponent} from '@app/ui/error-dialog/error-dialog.component';
import {AWorkflow} from '@app/wizard/a.workflow';
import {BatchItem} from '@app/wizard/batch.item';
import {IManagerComponent} from '@app/wizard/contracts/i.wizard.component';
import {WorkflowService} from '@app/wizard/services/workflow.service';
import {WizardDirective} from '@app/wizard/wizard.directive';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';
import {BehaviorSubject, Observable, of, Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-wizard',
  template: '<ng-template wizard></ng-template>',
  providers: [
    {
      provide: DISABLE_SUBMIT_TOKEN,
      useFactory: () => new BehaviorSubject(false),
    },
  ],
})
export class ManagerComponent implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();
  private _uuid: string;
  private _rules: TFormRules;
  private _data;
  private isHandlePossible = true;

  public currentComponentRef: ComponentRef<IManagerComponent>;

  @Input()
  public extendWizard: Record<string, BatchItem>;

  @Input()
  public mapWizard: Record<string, string>;

  @Input()
  public initWorkflow: AWorkflow;

  @Output()
  public readonly uuidChanged = new EventEmitter<string>();

  @Output()
  public readonly workflowChanged = new EventEmitter<string>();

  @Output()
  public readonly loadingStateChanged = new EventEmitter<boolean>();

  @ViewChild(WizardDirective, {static: true})
  public wizardDirective: WizardDirective;

  public get rules(): TFormRules {
    return this._rules;
  }

  public get uuid(): string {
    return this._uuid;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get data(): any {
    return this._data;
  }

  constructor(
    private readonly translate: TranslateService,
    private readonly workflowService: WorkflowService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly snackbar: SnackbarService,
    private readonly matDialog: MatDialog,
    @Inject(DISABLE_SUBMIT_TOKEN) private readonly disableSubmit$: BehaviorSubject<boolean>,
  ) {}

  private loadWizard(key: string = 'view'): void {
    const wizardItem = this.extendWizard[key];

    if (wizardItem?.isDialog) {
      this.loadComponentInDialog(wizardItem, key);
    } else {
      this.loadComponentInTemplate(wizardItem, key);
    }
  }

  private loadComponentInDialog(wizardItem: BatchItem, key: string): void {
    if (_.isEqual(this.currentComponentRef.componentType, wizardItem.component)) {
      return;
    }

    this.currentComponentRef = this.wizardDirective.viewContainerRef.createComponent(wizardItem.component);

    this.matDialog.closeAll();

    const ref = this.matDialog.open(this.currentComponentRef.componentType, {
      width: '100%',
      height: '100%',
      closeOnNavigation: false,
      hasBackdrop: false,
      panelClass: 'error-dialog',
      disableClose: true,
    });

    ref.componentInstance.data = wizardItem.data;
    ref.componentInstance.manager = this;

    this.workflowChanged.next(key);
  }

  private loadComponentInTemplate(wizardItem: BatchItem, key: string): void {
    const viewContainerRef = this.wizardDirective.viewContainerRef;
    viewContainerRef.clear();

    if (this.currentComponentRef) {
      this.currentComponentRef.destroy();
    }

    this.currentComponentRef = viewContainerRef.createComponent(wizardItem.component);

    const componentInstance = this.currentComponentRef.instance;

    componentInstance.data = wizardItem.data;
    componentInstance.manager = this;

    this.currentComponentRef.changeDetectorRef.detectChanges();

    if (componentInstance.reset) {
      componentInstance.reset.pipe(takeUntil(this.destroyer$)).subscribe(() => this.resetLoad());
    }

    if (componentInstance.resetInit) {
      componentInstance.resetInit.pipe(takeUntil(this.destroyer$)).subscribe(() => this.resetInit());
    }

    this.workflowChanged.next(key);
  }

  private getWizardMap(wizardName: string = 'view'): void {
    if (this._data?.code === 841) {
      this.snackbar.error(this.translate.get('Wizard.Manager.UserLockedMessage'));
    }

    if (Object.prototype.hasOwnProperty.call(this.mapWizard, wizardName)) {
      wizardName = this.mapWizard[wizardName];
    }

    this.loadWizard(wizardName);
  }

  private resetInit(): void {
    void this.init();
  }

  public resetLoadWizard(workflow: Workflow): void {
    this._uuid = workflow.uuid;
    this._rules = workflow.rules;
    this._data = workflow.data;
    this.getWizardMap(workflow.workflow);
  }

  private successWizardProcessing(result: IExceptions): void {
    const workflow = Workflow.Make<Workflow>(result.getData());
    this._uuid = workflow.uuid;
    this._rules = workflow.rules;
    this._data = workflow.data ? workflow.data : null;
    this.getWizardMap(workflow.workflow);
    this.disableSubmit$.next(false);
    this.uuidChanged.emit(this._uuid);
  }

  public resetLoad(): void {
    this.loadWizard();
  }

  public load(key: string): void {
    this.loadWizard(key);
  }

  public ngOnInit(): Promise<void> | void {
    if (this.initWorkflow.isOutput) {
      return this.loadWizard();
    }

    if (this.initWorkflow.uuid) {
      return this.step(this.initWorkflow.uuid).catch(() => {
        void this.init();
      });
    }

    this.activatedRoute.params.pipe(takeUntil(this.destroyer$)).subscribe(param => {
      if (!Object.prototype.hasOwnProperty.call(param, 'uuid')) {
        return this.init();
      }

      return this.step(param.uuid).catch(() => {
        void this.init();
      });
    });
  }

  public init(params?): Promise<IExceptions> {
    this.loadingStateChanged.next(true);

    return this.initWorkflow
      .init(params)
      .then(result => {
        this.loadingStateChanged.next(false);

        return result;
      })
      .then(result => {
        if (result instanceof NotFoundException) {
          throw new NotFoundException(result.getData());
        }

        return result;
      })
      .then(result => {
        if (result instanceof TooManyRequestsException) {
          this._data = {isUnavailable: true};
        }

        return result;
      })
      .then(result => {
        if (result instanceof ResponseException) {
          this.successWizardProcessing(result);
        }

        return result;
      })
      .then(result => {
        if (result instanceof InternalServerErrorException) {
          const info: {code: number; message: string} = result.getData();
          this.snackbar.error(info.message);
        }

        return result;
      });
  }

  public step(uuid?: string): Promise<void> {
    return this.workflowService
      .step(uuid ? uuid : this._uuid)
      .then(result => {
        if (result instanceof NotFoundException) {
          throw new NotFoundException(result.getData());
        }

        return result;
      })
      .then(result => {
        this.initWorkflow.handle(result);

        return result;
      })
      .then(result => {
        if (result instanceof ResponseException) {
          this.successWizardProcessing(result);
        }
      });
  }

  public send(data, {hasAnimation, data: dataForManager}: ISendConfig = {}): Promise<IExceptions> {
    this.isHandlePossible = true;
    // If submit already disabled, return promise that never resolves.
    if (this.disableSubmit$.getValue()) {
      return new Promise(() => void 0);
    }

    this.disableSubmit$.next(true);
    this.loadingStateChanged.next(true);

    return this.workflowService
      .send(this._uuid, data)
      .then(result => {
        this.loadingStateChanged.next(false);

        return result;
      })
      .then(result => {
        if (result instanceof NotFoundException) {
          throw new NotFoundException(result.getData());
        }

        if (result instanceof InternalServerErrorException) {
          const info: {code: number; message: string} = result.getData();

          if (info.code === 10 || info.code === 1 || info.code === 100) {
            this.isHandlePossible = false;
            this.snackbar.error(info.message);
            this.router.routeReuseStrategy.shouldReuseRoute = function (): boolean {
              return false;
            };

            const currentUrl = this.router.url;

            void this.router.navigateByUrl(currentUrl).then(() => {
              this.router.navigated = false;
            });
          }
        }
        return result;
      })
      .then((result: IExceptions<Workflow>) => {
        const timer$: Observable<number> = timer(hasAnimation ? ANIMATION_DELAY_FOR_WIZARD_SUCCESS_MESSAGE_IN_MS : 0);
        const isWizardStopped: boolean = result.getData()?.canceled;

        if (result instanceof TerminateException && isWizardStopped) {
          this.matDialog.open(ErrorDialogComponent, {
            width: '100%',
            height: '100%',
            closeOnNavigation: true,
            hasBackdrop: false,
            panelClass: ['error-dialog'],
            data: {
              header: of('Wizard.Manager.WizardStoppedDialog.Header'),
              text: of('Wizard.Manager.WizardStoppedDialog.Text'),
              emoji: 'emoji-hammer',
              textAccentButton: of('Wizard.Manager.WizardStoppedDialog.ButtonText.Refresh'),
              textPrimaryButton: of('Wizard.Manager.WizardStoppedDialog.ButtonText.Back'),
              onAccentButton: (): void => location.reload(),
              onPrimaryButton: (): void => window.history.back(),
            },
          });
        }

        if (result instanceof ResponseException) {
          const workflow = Workflow.Make<Workflow>(result.getData());
          this._uuid = workflow.uuid;
          this._rules = workflow.rules;
          this._data = workflow.data ? {...workflow.data, ...dataForManager} : dataForManager;

          const timerSub = timer$.subscribe({
            next: () => this.getWizardMap(workflow.workflow),
            complete: () => timerSub.unsubscribe(),
          });

          return;
        }

        const timerSub = timer$.subscribe({
          next: () => {
            if (this.isHandlePossible) {
              this.initWorkflow.handle(result);
            }
          },
          complete: () => timerSub.unsubscribe(),
        });

        return result;
      })
      .then(result => {
        if (result instanceof Exceptions) {
          const status = result.getStatus();

          if (!status || status >= 300) {
            this.disableSubmit$.next(false);
          }
        } else {
          this.disableSubmit$.next(false);
        }

        return result;
      })
      .catch(err => {
        this.disableSubmit$.next(false);

        throw err;
      });
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}

export interface ISendConfig {
  hasAnimation?: boolean;
  data?;
}
