import {Casts, CastTypes, Model} from '@app/core/model';

export class Info extends Model {
  public givenName?: string;
  public familyName?: string;
  public middleName?: string;
  public givenNameInt?: string;
  public familyNameInt?: string;
  public sex: number;
  public options;
  public name?: string;
  public birthday?: string;
  public readonly casts: Casts = {
    givenName: [CastTypes.String, CastTypes.Null],
    familyName: [CastTypes.String, CastTypes.Null],
    middleName: [CastTypes.String, CastTypes.Null],
    givenNameInt: [CastTypes.String, CastTypes.Null],
    familyNameInt: [CastTypes.String, CastTypes.Null],
    sex: [CastTypes.Number],
    options: [CastTypes.Any],
    name: [CastTypes.String, CastTypes.Null],
    birthday: [CastTypes.String, CastTypes.Null],
  };

  public getFullName(): string {
    return [this.familyName, this.givenName]
      .filter((value: string) => {
        return value ? value.trim() : '';
      })
      .join(' ');
  }
}
