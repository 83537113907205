import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {FileKeeper} from '@app/core/file-keeper';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-file-input',
  templateUrl: './app.file.input.component.html',
  styleUrls: ['./app.file.input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AppFileInputComponent,
      multi: true,
    },
  ],
})
export class AppFileInputComponent implements ControlValueAccessor, OnDestroy, OnInit {
  public onChange?;
  public onTouched?;
  public disabled: boolean;

  public files: File[] = [];

  public isShowBackgroundImage = true;

  @ViewChild('inputFiles', {static: true})
  private readonly inputFiles: ElementRef;

  private element: HTMLInputElement;

  @Input()
  public errors: Record<string, ValidationErrors> | undefined;

  @Input()
  public isMultiple = true;

  @Input()
  public accept: string;

  @Input()
  public isHideBackgroundImageAfterFileAdding = false;

  @Output()
  public readonly changeEvent = new EventEmitter<File[]>();

  public readonly keeper = new FileKeeper(this.sanitizer.bypassSecurityTrustResourceUrl);

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly errorsService: ErrorsService,
    private readonly translate: TranslateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public get isAddingFilesNotAvailable(): boolean {
    return !this.isMultiple && this.files.length >= 1;
  }

  private get isAddingFilesAvailable(): boolean {
    return this.isMultiple || this.files.length === 0;
  }

  public ngOnInit(): void {
    this.element = this.inputFiles.nativeElement;
    this.translate.get('File not selected').subscribe((value: string) => {
      this.element.title = value;
    });
  }

  public change(event: Event): void {
    const element: HTMLInputElement = event.target as HTMLInputElement;
    this.addFiles(element.files);
  }

  public drop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.onTouched();
    this.addFiles(evt.dataTransfer.files);
  }

  public addFiles(files: FileList): void {
    if (this.isAddingFilesNotAvailable) {
      return;
    }

    const addedFiles: File[] = Array.from(files);
    this.files = this.files.concat(addedFiles);
    this.keeper.addFiles(addedFiles);
    this.setValue(this.files);

    if (this.isHideBackgroundImageAfterFileAdding) {
      this.isShowBackgroundImage = false;
    }

    if (!this.isMultiple) {
      this.setDisabledState(true);
    }
  }

  public rmFile(i: number): void {
    this.files.splice(i, 1);
    this.keeper.removeFile(i);
    this.setValue(this.files);

    if (this.isAddingFilesAvailable) {
      this.isShowBackgroundImage = true;
      this.setDisabledState(false);
    }

    if (this.files.length === 0) {
      this.resetInputFiles();
    }
  }

  public writeValue(value): void {
    if (!value) {
      return;
    }

    this.addFiles(value);
  }

  public registerOnChange(fn: () => unknown): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
  }

  public ngOnDestroy(): void {
    this.keeper.clear();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private setValue(files: File[]): void {
    this.changeEvent.emit(files);

    if (this.onChange) {
      this.onTouched?.();

      this.onChange(files);
    }

    const title: string = files.reduce((t: string, f: File, index: number, array: File[]) => {
      t += f.name;
      if (index + 1 < array.length) {
        t += '\n';
      }
      return t;
    }, '');

    this.translate.get('File not selected').subscribe((value: string) => {
      this.element.title = title || value;
    });
  }

  private resetInputFiles(): void {
    this.inputFiles.nativeElement.value = null;
  }

  public getError(key: string, value): Observable<string> {
    return this.errorsService.getError(key, value);
  }
}
