import {CfiCodeHelper} from './cfi-code.helper';

export class WarpInstrumentHelper {
  private static readonly AVAILABLE_CURRENCIES = new Set([
    'USD000000TOD',
    'USD000UTSTOM',
    'EUR_RUB__TOD',
    'EUR_RUB__TOM',
    'CNYRUB_TOM',
  ]);

  private static readonly CURRENCY_POSITION_SYMBOL_TO_INSTRUMENT_SYMBOL = new Map<string, string>([
    ['USD', 'USD000UTSTOM'],
    ['EUR', 'EUR_RUB__TOM'],
    ['CNY', 'CNYRUB_TOM'],
  ]);

  public static readonly EUR_USD_PAIR = 'EURUSD_TOM';

  public static readonly CURRENCIES_TO_GET_RATE = [
    'USD000UTSTOM',
    'EUR_RUB__TOM',
    'CNYRUB_TOM',
    WarpInstrumentHelper.EUR_USD_PAIR,
  ];

  public static isInstrumentAvailable(cfiCode: string, symbol: string): boolean {
    if (cfiCode !== CfiCodeHelper.CURRENCY_CFI_CODE) {
      return true;
    }

    return WarpInstrumentHelper.AVAILABLE_CURRENCIES.has(symbol);
  }

  public static getCurrencySymbol(positionSymbol: string): string | undefined {
    return WarpInstrumentHelper.CURRENCY_POSITION_SYMBOL_TO_INSTRUMENT_SYMBOL.get(positionSymbol);
  }

  public static getAvailableCurrencies(): string[] {
    return Array.from(WarpInstrumentHelper.AVAILABLE_CURRENCIES);
  }
}
