import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';

import {Trade} from '../../models/trade';

@Injectable()
export class B2marginTradesService extends AEventHandler<Trade[]> {
  public type = EEventHandlersReceived.Trades;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: Trade[]): void {
    Array.from(this.b2marginDatafeedService.trades.entries()).forEach(([key, obj]) => {
      const newInstrumentTrades = resource.filter(({symbolWithSeparator}) => key === symbolWithSeparator);

      if (!newInstrumentTrades.length) {
        return;
      }

      const trades = obj.stream
        .getValue()
        .concat(newInstrumentTrades)
        .sort((a, b) => b.tradeTime.getTime() - a.tradeTime.getTime());
      obj.stream.next(trades);
    });
  }
}
