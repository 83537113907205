import {Observable, OperatorFunction} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';

/**
 * Accumulates values from the stream with debounce time.
 * If no new values are sent to the stream within {timeInMs} milliseconds, it returns an array of accumulated values.
 *
 * @param timeInMs - Debouncing time.
 * @returns Operator function.
 */
export function bufferDebounceTime<T>(timeInMs = 0): OperatorFunction<T, T[]> {
  return (source: Observable<T>) => {
    let bufferedValues: T[] = [];

    return source.pipe(
      tap(value => bufferedValues.push(value)),
      debounceTime(timeInMs),
      map(() => bufferedValues),
      tap(() => (bufferedValues = [])),
    );
  };
}
