import {Expose} from 'class-transformer';
import moment from 'moment';

export class MarketHours {
  @Expose({name: 'MSK'})
  public moscowUnixTime: number;

  @Expose({name: 'cu_from'})
  public currencyMarketFrom: string;

  @Expose({name: 'cu_to'})
  public currencyMarketTo: string;

  @Expose({name: 'se_from'})
  public securitiesMarketFrom: string;

  @Expose({name: 'se_to'})
  public securitiesMarketTo: string;

  public get currencyMarketFromDate(): Date | undefined {
    return this.transformToDate(this.currencyMarketFrom);
  }

  public get currencyMarketToDate(): Date | undefined {
    return this.transformToDate(this.currencyMarketTo);
  }

  public get securitiesMarketFromDate(): Date | undefined {
    return this.transformToDate(this.securitiesMarketFrom);
  }

  public get securitiesMarketToDate(): Date | undefined {
    return this.transformToDate(this.securitiesMarketTo);
  }

  private transformToDate(value: string): Date | undefined {
    if (!value) {
      return undefined;
    }

    const time = this.parseTime(value);

    return moment()
      .set('hours', parseInt(time.hours))
      .set('minutes', parseInt(time.minutes))
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toDate();
  }

  private parseTime(time: string): {hours: string; minutes: string} {
    const [hours, minutes] = time.split(':');

    return {
      hours,
      minutes,
    };
  }
}
