import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  public static highlightString(str: string): string {
    return `<span class="highlight">${str}</span>`;
  }

  /**
   * Highlight a specific part of string.
   *
   * @param value - Source string.
   * @param highlight - String to highlight.
   * @param isSkipSearchSeparator - Allow to highlight without separator (search by only letters and numbers).
   * @returns Transformed string.
   */
  public transform(value: string, highlight: string, isSkipSearchSeparator = false): string {
    if (!value) {
      return '';
    }

    if (!highlight) {
      return value;
    }

    let regExpPattern = highlight;
    if (isSkipSearchSeparator) {
      // Update search value from "STR" to "(S)[^a-zA-Z0-9]*(T)[^a-zA-Z0-9]*(R)" for smart search without separators
      const separatorPattern = '[^a-zA-Z0-9]*';
      regExpPattern = highlight
        .split('')
        .map(l => `(${l})`)
        .join(separatorPattern);
    }

    const regExp = new RegExp(regExpPattern, 'gi');
    return value.replace(regExp, s => HighlightPipe.highlightString(s));
  }
}
