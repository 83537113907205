import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CodeResendComponent} from '@app/code-resend/code-resend.component';
import {SharedModule} from '@app/shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [CodeResendComponent],
  imports: [CommonModule, SharedModule, TranslateModule],
  exports: [CodeResendComponent],
})
export class CodeResendModule {}
