import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

import {FormFieldAttribute} from './form.field';

/**
 * Directive for setting attributes for fields.
 * Gets attributes from FormConfig and sets them to element.
 *
 * Use case:
 * public attributes = [{name: 'autocomplete', value: 'username'}];
 * <input ... [appFieldAttributes]="attributes"/>.
 *
 * Attention! This directive only appends attributes, but does not remove old ones.
 */
@Directive({
  selector: '[appFieldAttributes]',
})
export class FieldAttributesDirective implements OnChanges {
  @Input()
  public appFieldAttributes: FormFieldAttribute[] | undefined;

  constructor(private readonly renderer: Renderer2, private readonly elementRef: ElementRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.appFieldAttributes) {
      this.appFieldAttributes?.forEach(attribute => {
        const {name, value} = attribute;

        this.renderer.setAttribute(this.elementRef.nativeElement, name, value.toString());
      });
    }
  }
}
