import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {DEBOUNCE_TIME_IN_MS} from '@app/core/constants/common';
import {DirectionService} from '@app/shared/modules/direction/services/direction.service';
import {ReplaySubject, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnDestroy, OnInit {
  private readonly destroyer$ = new Subject<void>();
  private readonly onStream = new ReplaySubject<boolean>();

  private _isShow = false;

  @Input()
  public set isShow(isShow: boolean) {
    this.onStream.next(isShow);
  }
  public get isShow(): boolean {
    return this._isShow ?? false;
  }

  @Input()
  public color: ThemePalette = 'accent';

  @Input()
  public debounce = DEBOUNCE_TIME_IN_MS;

  @Input()
  public isAbsolute: boolean;

  constructor(private readonly cdr: ChangeDetectorRef, public readonly directionService: DirectionService) {}

  public ngOnInit(): void {
    this.onStream.pipe(debounceTime(this.debounce), takeUntil(this.destroyer$)).subscribe(isShow => {
      this._isShow = isShow;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
