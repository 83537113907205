import {Pipe, PipeTransform} from '@angular/core';
import {TNilable} from '@app/core/types/types';
import * as _ from 'lodash-es';

/**
 * @example
 * {{ 22003 | thousandsSeparator }} ---> '22,003'
 * {{ 4299.031 | thousandsSeparator }} ---> '4,299.031'
 * {{ '11200.0034' | thousandsSeparator }} ---> '11,200.0034'
 *
 * {{ '' | thousandsSeparator }} ---> '0'
 * {{ null | thousandsSeparator }} ---> null
 * {{ undefined | thousandsSeparator }} ---> undefined
 * @returns String with thousands separated by a comma.
 */
@Pipe({
  name: 'thousandsSeparator',
})
export class ThousandsSeparatorPipe implements PipeTransform {
  public transform(value: TNilable<string | number>): TNilable<string> {
    if (_.isNil(value)) {
      return value;
    }

    const [integer, fraction] = String(value).split('.');
    const number = Number(integer);

    if (isNaN(number)) {
      return value.toString();
    }

    const separateInteger = number.toLocaleString('en-GB');

    return fraction ? `${separateInteger}.${fraction}` : separateInteger;
  }
}
