import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';
import {MaintenanceErrorService} from '@app/core/modules/errors/maintenance-error.service';
import {RequestEntityTooLargeService} from '@app/core/modules/errors/request-entity-too-large.service';
import {ErrorDialogModule} from '@app/ui/error-dialog/error-dialog.module';

import {ErrorsService} from './errors.service';
import {TooManyRequestsErrorService} from './too-many-requests-error.service';

@NgModule({
  imports: [CommonModule, MatDialogModule, HttpClientModule, RouterModule, ErrorDialogModule],
  providers: [ErrorsService, TooManyRequestsErrorService, RequestEntityTooLargeService, MaintenanceErrorService],
})
export class ErrorsModule {}
