import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {IconModule} from '@app/icon/icon.module';
import {TranslateModule} from '@ngx-translate/core';

import {ExpansionPanelComponent} from './expansion-panel.component';

@NgModule({
  imports: [CommonModule, MatExpansionModule, IconModule, TranslateModule],
  exports: [ExpansionPanelComponent],
  declarations: [ExpansionPanelComponent],
})
export class ExpansionPanelModule {}
