import {Directive, OnDestroy} from '@angular/core';
import {Account} from '@app/core/models/account/account';
import {EPlatformClass} from '@app/core/models/account/enum/platform-class';
import {EProductGroupType} from '@app/core/models/account/enum/product-group-type';
import {AdminWidgetRules, GridsterProviders} from '@app/core/models/gridster';
import {AccountService} from '@app/core/services/account.service';
import {EConnectionLevel} from '@app/trading-board/enum/connection-level';
import {Observable} from 'rxjs';

@Directive()
export abstract class AGridsterDatafeedService implements OnDestroy {
  public abstract provider: GridsterProviders;
  public adminWidgetRules?: Observable<Record<string, AdminWidgetRules>>;

  public abstract hasConnect(): boolean;
  public abstract terminate(): void;
  public abstract initialize(level: EConnectionLevel): void;
  public abstract afterConnect(): void;

  public getAccountsByPlatform?(
    platformClass: EPlatformClass,
    productGroupType?: EProductGroupType[],
    productType?: number,
  ): Observable<Account[]>;

  public getAccountsService?(): AccountService;

  public abstract ngOnDestroy(): void;
}
