<app-dialog>
  <h2>
    {{ data.header | async }}
    <app-img *ngIf="data.emoji" type="emoji" class="emoji" [iconName]="data.emoji"></app-img>
  </h2>
  <div class="content">
    <p>{{ data.text | async }}</p>
    <ng-container *ngIf="data.countdownTime && !isTimerFinished">
      <p>
        {{ data.countdownText | async }}&nbsp;
        <app-countdown [time]="data.countdownTime" (finalEvent)="onFinalTimer()"></app-countdown>
      </p>
    </ng-container>
  </div>
  <div class="buttons">
    <button mat-flat-button color="accent" *ngIf="data.onAccentButton" (click)="accentClick()">
      {{ data.textAccentButton | async }}
    </button>
    <button *ngIf="data.onPrimaryButton" mat-button color="accent" class="back" (click)="primaryClick()">
      {{ data.textPrimaryButton | async }}
    </button>
  </div>
</app-dialog>
