<ng-template #settingsTemplate>
  <ng-container *ngIf="isB2marginComponent">
    <button
      #triggerBtn="matMenuTrigger"
      [matMenuTriggerFor]="addWidgetMenu"
      (touchstart)="triggerBtn.openMenu()"
      class="settings"
    >
      <app-icon icon="settings" [class.active]="isMenuOpen(triggerBtn)"></app-icon>
    </button>
    <mat-menu class="tv-menu" #addWidgetMenu="matMenu">
      <div class="checkbox-wrapper">
        <mat-checkbox
          *ngFor="let option of displayOptionsWithTranslate | keyvalue: unsortedOrder; trackBy: trackByDisplayOption"
          [checked]="true"
          (change)="toggleOption($event.checked, option.key)"
          [class.hidden]="isDisplayLineOptionHidden(option.key)"
        >
          {{ option.value | translate }}
        </mat-checkbox>
      </div>
    </mat-menu>
  </ng-container>
</ng-template>

<ng-container *ngIf="isMoexComponent; else appSelectInstrumentTemplate">
  <div class="moex-widgets-header">
    <div *ngIf="bidAskPair$ | async as bidAskPair" class="bid-ask">
      <app-pbs-select-instrument
        [initialSymbol]="pair$ | async"
        (instrumentSelected)="selectInstrument($event)"
      ></app-pbs-select-instrument>

      <div class="bid-ask-wrap">
        <dl class="bid">
          <dt>{{ 'TradingBoard.TradingView.Bid' | translate }}</dt>
          <dd [class.coloured]="bidAskPair.bid.isColoured">
            {{ bidAskPair.bid.value | pbsThousandsSeparator: {priceScale: priceScale$ | async} }}
          </dd>
        </dl>

        <dl class="ask">
          <dt>{{ 'TradingBoard.TradingView.Ask' | translate }}</dt>
          <dd [class.coloured]="bidAskPair.ask.isColoured">
            {{ bidAskPair.ask.value | pbsThousandsSeparator: {priceScale: priceScale$ | async} }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #appSelectInstrumentTemplate>
  <app-select-instrument
    [pair]="pair$ | async"
    (instrumentSelect)="selectInstrument($event)"
    class="select-instrument"
  ></app-select-instrument>
</ng-template>
