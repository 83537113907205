export enum ECommonWidgetKeys {
  // NOTE(Maxim Alkeev) Transform to upper case in scope of FDP-9020
  /* eslint-disable @typescript-eslint/naming-convention */
  walkthrough = 'walkthrough',
  banners = 'banners',
  tradingAccounts = 'tradingAccounts',
  paymentAccounts = 'paymentAccounts',
  recentTransactions = 'recentTransactions',
  support = 'support',
  tradingAccountsFavorites = 'tradingAccountsFavorites',
  orderBook = 'orderBook',
  account = 'account',
  watchList = 'watchList',
  positions = 'positions',
  orderHistory = 'orderHistory',
  tradingView = 'tradingView',
  placeOrderClassic = 'placeOrderClassic',
  placeOrderSpotClassic = 'placeOrderSpotClassic',
  placeOrderMaxi = 'placeOrderMaxi',
  moexPlaceOrder = 'moexPlaceOrder',
  accountInfo = 'accountInfo',
  symbolLibrary = 'symbolLibrary',
  openOrders = 'openOrders',
  inactiveOrders = 'inactiveOrders',
  filledOrders = 'filledOrders',
  depth = 'depth',
  tradesHistory = 'tradesHistory',
  accountInstruments = 'accountInstruments',
  assets = 'assets',
  favorites = 'favorites',
  trades = 'trades',
  market = 'market',
  limit = 'limit',
  verification = 'verification',
  tabs = 'tabs',
  tickerWidget = 'tickerWidget',
  totalBalance = 'totalBalance',
  simpleExchange = 'simpleExchange',
  quickLimit = 'quickLimit',
  portfolio = 'portfolio',
  partnerLink = 'partnerLink',
  wallet = 'wallet',
  acquisitionReport = 'acquisitionReport',
  tradingReport = 'tradingReport',
  performanceIndicators = 'performanceIndicators',
  tradersInfo = 'tradersInfo',
  trafficSource = 'trafficSource',
  leadConversionRatio = 'leadConversionRatio',
  averageTraderLifetime = 'averageTraderLifetime',
  welcome = 'welcome',
  myAccounts = 'myAccounts',
  orders = 'orders',
  warpPortfolio = 'warpPortfolio',
  quickMarket = 'quickMarket',
  stopMarket = 'stopMarket',
  stopLimit = 'stopLimit',
  stopOrders = 'stopOrders',
  warpInstrumentsCatalog = 'warpInstrumentsCatalog',
  pbsTransactionsList = 'pbsTransactionsList',
  pbsMyBriefcase = 'pbsMyBriefcase',
  exchangeTime = 'exchangeTime',
  pbsAnalytics = 'pbsAnalytics',
  /* eslint-enable @typescript-eslint/naming-convention */
}
