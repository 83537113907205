import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {SnackbarService} from '@app/snackbar/snackbar.service';

@Directive({
  selector: '[appCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input()
  public appCopyToClipboard!: string;

  @Input()
  public copyMessage: string | undefined;

  @Output()
  public readonly copied = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public clickEvent(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.copy();
  }

  constructor(private readonly snackbar: SnackbarService) {}

  private copy(): void {
    const field = document.createElement('textarea');
    field.style.position = 'fixed';
    field.style.left = '0';
    field.style.top = '0';
    field.style.opacity = '0';
    field.value = this.appCopyToClipboard;
    document.body.appendChild(field);

    field.focus();
    field.select();

    // TODO: need fixed this deprecation api (https://b2btech.atlassian.net/browse/FDP-9022)
    // eslint-disable-next-line deprecation/deprecation
    document.execCommand('copy');
    document.body.removeChild(field);

    if (this.copyMessage) {
      this.snackbar.success(this.copyMessage);
    } else {
      this.copied.emit();
    }
  }
}
