import {StaticProvider} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {B2traderAuthPublicStandalone} from '@app/trading-board/b2trader-auth/b2trader-auth-public-standalone';
import {Environment} from '@env/environment.entities';

import {B2TraderAuthPrivate} from './b2trader-auth-private';
import {B2TraderAuthPublic} from './b2trader-auth-public';
import {B2traderAuthStandalone} from './b2trader-auth-standalone';
import {AB2TraderAuth} from './b2trader-auth.abstract';

export const B2TraderAuthProvider: StaticProvider = {
  provide: AB2TraderAuth,
  useFactory: (env: Environment, connection: ConnectionService) => {
    if (env.isPublic) {
      return env.isB2traderPublicStandalone
        ? new B2traderAuthPublicStandalone(env.apiUrl, env.b2traderPlatformName)
        : new B2TraderAuthPublic(env.apiUrl, env.b2traderPlatformName);
    }

    if (env.isB2TraderStandalone) {
      return new B2traderAuthStandalone(env.b2traderPlatformName);
    }

    return new B2TraderAuthPrivate(env.apiUrl, env.b2traderPlatformName, () => connection.getAuthHeaders());
  },
  deps: [Environment, ConnectionService],
};
