export enum EMoexOpcode {
  OrderBookGetAndSubscribe = 'OrderBookGetAndSubscribe',
  BarsGetAndSubscribe = 'BarsGetAndSubscribe',
  QuotesSubscribe = 'QuotesSubscribe',
  PositionsGetAndSubscribeV2 = 'PositionsGetAndSubscribeV2',
  SummariesGetAndSubscribeV2 = 'SummariesGetAndSubscribeV2',
  RisksGetAndSubscribe = 'RisksGetAndSubscribe',
  SpectraRisksGetAndSubscribe = 'SpectraRisksGetAndSubscribe',
  TradesGetAndSubscribeV2 = 'TradesGetAndSubscribeV2',
  StopOrdersGetAndSubscribeV2 = 'StopOrdersGetAndSubscribeV2',
  OrdersGetAndSubscribeV2 = 'OrdersGetAndSubscribeV2',
  InstrumentsGetAndSubscribeV2 = 'InstrumentsGetAndSubscribeV2',
  Unsubscribe = 'unsubscribe',
}
