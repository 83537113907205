import {Injectable} from '@angular/core';
import {ITradingStatusData} from '@app/pbsr/interfaces/trading-status-data.interface';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {IMoexMessage} from '@app/trading-board/interfaces/moex/moex-message';

import {AEventHandler} from '../event-handler.abstract';
import {EMoexEventHandlersReceived} from './moex-event-handlers.enum';

@Injectable()
export class MoexTradingStatusDataService extends AEventHandler<{
  symbol: string;
  message: IMoexMessage<ITradingStatusData>;
}> {
  public readonly type = EMoexEventHandlersReceived.SubscribeInstrument;

  constructor(private readonly moexDatafeedService: MoexDatafeedService) {
    super();
  }

  public handleMessage(payload: {symbol: string; message: IMoexMessage<ITradingStatusData>}): void {
    const {symbol, message} = payload;

    if (this.moexDatafeedService.tradingStatusDataStreams.has(symbol)) {
      this.moexDatafeedService.tradingStatusDataStreams.get(symbol).subject.next(message.data);
    }
  }
}
