import {EOrderSide} from '@app/trading-board/enum/moex/order-side';
import {DecimalHelper} from '@b2broker/decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class WarpTrade {
  @Expose()
  @Transform(({value}: {value: number}) => value.toString())
  public id: string;

  @Expose()
  public orderno: string;

  @Expose()
  public symbol: string;

  @Expose()
  public brokerSymbol: string;

  @Expose()
  public exchange: string;

  @Expose()
  public date: string;

  @Expose()
  public board: string;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyUnits: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyBatch: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qty: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public price: DecimalHelper;

  @Expose()
  public side: EOrderSide;

  @Expose({name: 'existing'})
  public isExist: boolean;

  @Expose()
  public parentAccountId: string;

  @Expose()
  public subAccountId: string;

  @Expose()
  @Transform(({value}) => (value ? DecimalHelper.from(value) : null))
  public commission: DecimalHelper | null;

  @Expose()
  public parentAccountCaption: string;

  @Expose()
  public subAccountCaption: string;
}
