import {Type} from '@angular/core';

export abstract class AEventHandler<T, R = void> {
  public type: string | string[];

  public abstract handleMessage(resource: T): R;
}

export const b2MarginInternalEventHandlers: Type<AEventHandler<unknown>>[] = [];
export const b2TraderInternalEventHandlers: Type<AEventHandler<unknown>>[] = [];
export const moexInternalEventHandlers: Type<AEventHandler<unknown>>[] = [];
