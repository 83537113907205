import {AGridsterDatafeedService} from '@app/gridster/datafeeds/gridster-datafeed-service.abstract';
import {IStreamWithRefCount} from '@app/trading-board/interfaces/stream-with-ref-count';
import {Instrument} from '@app/trading-board/models/instrument';
import {Level1Collector} from '@app/trading-board/models/level1-collector';
import {Trade} from '@app/trading-board/models/trade';
import {Bar as IBar, ResolutionString} from '@b2broker/trading.view.charting.library/charting_library/datafeed-api';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

import {ADatafeedFacade} from '../facades/datafeed-facade.abstract';
import {IInstrument} from '../interfaces/instrument';
import {IBarsHistoryRequest} from '../interfaces/moex/bars-history-request.interface';
import {IChartHistoryWithTimeLinksResponse} from '../interfaces/moex/chart-history-with-time-links-response.interface';
import {Tick} from '../models/level1';
import {Level2} from '../models/level2';

export abstract class ATradeDatafeed extends AGridsterDatafeedService {
  public static throttleTime = 500;

  protected static clearMapSubject<T>(obj: Map<string, IStreamWithRefCount<T>>): void {
    obj.forEach(value => value.stream.complete());
    obj.clear();
  }

  public abstract instruments$: BehaviorSubject<Instrument[]>;
  public abstract level1$: BehaviorSubject<Level1Collector>;

  public abstract getLevel2(instrument: IInstrument): Observable<Level2>;

  public abstract getBarsData$(request: IBarsHistoryRequest): Observable<IBar[] | IChartHistoryWithTimeLinksResponse>;

  public abstract getBar$(symbol: string, resolution: ResolutionString, from?: number): Observable<IBar>;

  public abstract getTrades$(symbol: string): Observable<Trade[]>;

  public abstract updateQuotesSubscriptions(symbolsToRemove: string[], symbolsToAdd: string[]): void;

  protected sharedWorker?: SharedWorker;
  protected worker?: Worker;
  protected dataFeedFacade?: ADatafeedFacade;

  public readonly finishedInitialize$ = new ReplaySubject<void>(1);
  public readonly connectionClosed$ = new ReplaySubject<void>(1);
  public readonly quotes$ = new BehaviorSubject(new Map<string, Tick>());
}
