import {IInstrumentSummaryDto} from '@app/trading-board/interfaces/b2trader/instrument-summary-dto.interface';
import {IInstrument} from '@app/trading-board/interfaces/instrument';
import {IMoexQuote} from '@app/trading-board/interfaces/moex/quote';
import {ADataStoreModel} from '@app/trading-board/models/data-store-model';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {Expose, Transform} from 'class-transformer';

import {IB2MarginInstrument} from '../interfaces/b2margin/instrument';
import {IB2TraderInstrument} from '../interfaces/b2trader/instrument';
import {ITick} from '../interfaces/tick';

export class Tick extends ADataStoreModel<ITick> {
  public static convertMapToArray(ticksMap: Map<string, Tick>): Tick[] {
    return Array.from(ticksMap.values());
  }

  public static getTickFromB2Margin(
    summary: B2MarginModels.SummaryTO,
    quote: B2MarginModels.QuoteTO,
    instrument: IB2MarginInstrument,
  ): ITick {
    const change = LazyDecimalHelper.from(quote.bid).minus(summary.prevDayClose);
    const {id, amountScale, priceScale} = instrument;

    return {
      bestBid: quote.bid?.toString(),
      bestOffer: quote.ask?.toString(),
      spread: LazyDecimalHelper.from(quote.ask).minus(quote.bid).toString(),
      instrumentId: id,
      rolling24HrPxChange: change.divide(summary.prevDayClose).multiply(100).setScale(2).getValue(),
      rolling24HrChangeQuantity: change.getValue(),
      isRollingNegative: change.isNegative(),
      sessionHigh: summary.dayHigh,
      sessionLow: summary.dayLow,
      volume: LazyDecimalHelper.from(quote.askSize).plus(quote.bidSize).toNumber(),
      amountScale,
      priceScale,
      lastTradedPx: summary.prevDayClose,
      rolling24HrVolume: 0,
    };
  }

  public static fromB2Margin(
    summaries: Map<string, B2MarginModels.SummaryTO>,
    quotes: Map<string, B2MarginModels.QuoteTO>,
    instruments: Map<string, IInstrument>,
  ): Map<string, ITick> {
    return Array.from(summaries.values()).reduce((acc: Map<string, ITick>, summary) => {
      const {symbol} = summary;
      const quote = quotes.get(symbol);
      const instrument = instruments.get(symbol);

      if (!quote || !instrument) {
        return acc;
      }

      acc.set(instrument.id, Tick.getTickFromB2Margin(summary, quote, instrument));

      return acc;
    }, new Map<string, ITick>());
  }

  public static fromB2Trader(ticks: IInstrumentSummaryDto[], instruments: Map<string, IB2TraderInstrument>): ITick[] {
    return ticks.map(tick => {
      const percentChange = Number(tick.percentChange);
      const instrument = instruments.get(tick.id);

      return {
        bestBid: '0',
        bestOffer: '0',
        spread: '0',
        instrumentId: tick.id.toUpperCase(),
        lastTradedPx: Number(tick.last),
        rolling24HrPxChange: Math.abs(percentChange).toString(),
        rolling24HrVolume: Number(tick.baseVolume),
        isRollingNegative: percentChange < 0,
        sessionHigh: Number(tick.high24hr),
        sessionLow: Number(tick.low24hr),
        volume: 0,
        amountScale: instrument?.amountScale ?? 8,
        priceScale: instrument?.priceScale ?? 8,
      } as ITick;
    });
  }

  public static fromMoex(tick: IMoexQuote): ITick {
    const absoluteChange =
      tick.last_price && tick.prev_close_price
        ? LazyDecimalHelper.from(tick.last_price).minus(tick.prev_close_price)
        : LazyDecimalHelper.from(0);

    return {
      bestBid: (tick.bid ?? 0).toString(),
      bestOffer: (tick.ask ?? 0).toString(),
      spread: '0', // TODO(msuvorov): calculate when you need it
      instrumentId: tick.symbol,
      lastTradedPx: tick.last_price ?? 0,
      rolling24HrPxChange: tick.prev_close_price
        ? absoluteChange.divide(tick.prev_close_price).multiply(100).getValue()
        : LazyDecimalHelper.from(0).getValue(),
      rolling24HrChangeQuantity: absoluteChange.getValue(),
      isRollingNegative: absoluteChange.isNegative(),
      sessionHigh: tick.high_price,
      sessionLow: tick.low_price,
      volume: tick.volume,
      amountScale: 8,
      priceScale: 8,
      prevClosePrice: tick.prev_close_price,
      accruedInterest: tick.accrued_interest,
      faceValue: tick.facevalue,
    };
  }

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.priceScale))
  public bestBid: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.priceScale))
  public bestOffer: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.priceScale))
  public spread: LazyDecimalHelper;

  public instrumentId: string;

  @Transform(({value}) => LazyDecimalHelper.from(value, 2).abs().getInstanceWithNanFallback(null))
  public rolling24HrPxChange: LazyDecimalHelper | null;

  @Expose()
  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.priceScale))
  public rolling24HrChangeQuantity: LazyDecimalHelper;

  public isRollingNegative: boolean;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.priceScale))
  public sessionHigh: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.priceScale))
  public sessionLow: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.amountScale))
  public volume: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.priceScale))
  public lastTradedPx: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value, obj.amountScale))
  public rolling24HrVolume: LazyDecimalHelper;

  @Expose()
  @Transform(({value}: {value}) => LazyDecimalHelper.from(value))
  public prevClosePrice?: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value ?? 0, obj.priceScale))
  public accruedInterest: LazyDecimalHelper;

  @Transform(({value, obj}: {value; obj: ITick}) => LazyDecimalHelper.from(value ?? 0, obj.priceScale))
  public faceValue: LazyDecimalHelper;

  public isOfferIncrease = true;
  public isBidIncrease = true;

  public setPriceChangesB2Trader(previousTick: Tick): void {
    this.isOfferIncrease = this.bestOffer.minus(previousTick?.bestOffer ?? 0).greaterThanZero();
    this.isBidIncrease = this.bestBid.minus(previousTick?.bestBid ?? 0).greaterThanZero();
  }

  public setPriceChangesB2Margin(previousBestOffer: LazyDecimalHelper, previousBestBid: LazyDecimalHelper): void {
    this.isOfferIncrease = this.bestOffer.greaterThanOrEqualTo(previousBestOffer);
    this.isBidIncrease = this.bestBid.greaterThanOrEqualTo(previousBestBid);
  }

  public hasPrices(): boolean {
    return (
      this.bestBid.isNotNaN() && this.bestBid.isNotZero() && this.bestOffer.isNotNaN() && this.bestOffer.isNotZero()
    );
  }

  public update({
    bestBid,
    bestOffer,
    spread,
    rolling24HrPxChange,
    rolling24HrChangeQuantity,
    isRollingNegative,
    sessionHigh,
    sessionLow,
    priceScale,
    amountScale,
    volume,
    lastTradedPx,
    rolling24HrVolume,
    prevClosePrice,
  }: ITick): void {
    this.bestBid = LazyDecimalHelper.from(bestBid, priceScale);
    this.bestOffer = LazyDecimalHelper.from(bestOffer, priceScale);
    this.spread = LazyDecimalHelper.from(spread, priceScale);
    this.rolling24HrPxChange = LazyDecimalHelper.from(rolling24HrPxChange, 2).abs().getInstanceWithNanFallback(null);
    this.rolling24HrChangeQuantity = LazyDecimalHelper.from(rolling24HrChangeQuantity, priceScale);
    this.isRollingNegative = isRollingNegative;
    this.sessionHigh = LazyDecimalHelper.from(sessionHigh, priceScale);
    this.sessionLow = LazyDecimalHelper.from(sessionLow, priceScale);
    this.volume = LazyDecimalHelper.from(volume, amountScale);
    this.lastTradedPx = LazyDecimalHelper.from(lastTradedPx, priceScale);
    this.rolling24HrVolume = LazyDecimalHelper.from(rolling24HrVolume, amountScale);
    this.prevClosePrice = LazyDecimalHelper.from(prevClosePrice);
  }
}
