import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';
import {DirectionService} from '@app/shared/modules/direction/services/direction.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeComponent extends FormInput implements OnInit, OnDestroy {
  private readonly destroyer$ = new Subject<void>();
  private static readonly DEFAULT_LENGTH = 6;

  public mask: string;
  public length: number;
  public symbols: Array<undefined>;

  constructor(
    labelService: LabelService,
    errorsService: ErrorsService,
    public directionService: DirectionService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(labelService, errorsService);
  }

  public ngOnInit(): void {
    const maskValue: string = this.field.mask && this.field.mask.value;
    this.length = maskValue ? parseInt(maskValue, 10) : CodeComponent.DEFAULT_LENGTH;
    this.mask = Array(this.length + 1).join('0');
    this.symbols = new Array(this.length);

    this.control.statusChanges.pipe(takeUntil(this.destroyer$)).subscribe(() => this.cdr.detectChanges());
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
