import {Expose, plainToInstance} from 'class-transformer';
import {IsBoolean, IsString} from 'class-validator';

import {GridsterWidgetSettings} from '../../../core/models/gridster/gridster-widget-settings';

export class TradesHistorySettings {
  public static makeInstance(config: GridsterWidgetSettings): TradesHistorySettings {
    if (!config) {
      throw new Error('Config is undefined');
    }

    return plainToInstance(TradesHistorySettings, config, {excludeExtraneousValues: true});
  }

  public static getInstance(): TradesHistorySettings {
    return plainToInstance(TradesHistorySettings, {pair: '', isShowSide: true}, {excludeExtraneousValues: true});
  }

  @Expose()
  @IsString()
  public pair: string;

  @Expose()
  @IsBoolean()
  public isShowSide: boolean;
}
