import {Injectable} from '@angular/core';
import {CfiCodeHelper} from '@app/pbsr/helpers/cfi-code.helper';
import {WarpInstrumentHelper} from '@app/pbsr/helpers/warp-instrument.helper';
import {AccountManagerService} from '@app/pbsr/services/account-manager/account-manager.service';
import {WarpInstrumentsStoreService} from '@app/pbsr/services/warp-instruments-store/warp-instruments-store.service';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {EBaseCurrency} from '@app/trading-board/enum/moex/base-currency';
import {IParentAccount} from '@app/trading-board/interfaces/moex/moex-accounts-info';
import {ISymbolData, ISymbolInfo} from '@app/trading-board/interfaces/moex/symbol-info';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';

import {IAnalyticsData} from '../interfaces/analytics-data.interface';

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  private readonly instruments = new Map<string, WarpInstrument>();

  private readonly translationKeys = {
    monetaryAssets: marker('Dashboard.Wdgets.Pbsr.Analytics.Services.Analytics.MonetaryAssets'),
    everythingElse: marker('Dashboard.Wdgets.Pbsr.Analytics.Services.Analytics.EverythingElse'),
    stock: marker('Dashboard.Wdgets.Pbsr.Analytics.Services.Analytics.Stock'),
    bond: marker('Dashboard.Wdgets.Pbsr.Analytics.Services.Analytics.Bond'),
    currency: marker('Dashboard.Wdgets.Pbsr.Analytics.Services.Analytics.Currency'),
  };

  private accountsRub: IParentAccount[];
  private symbolsInfo: ISymbolInfo[];

  public readonly total$ = new BehaviorSubject<LazyDecimalHelper | undefined>(undefined);

  public readonly assetsGroup$ = new BehaviorSubject<IAnalyticsData[]>([]);
  public readonly sectorGroup$ = new BehaviorSubject<IAnalyticsData[]>([]);
  public readonly currenciesGroup$ = new BehaviorSubject<IAnalyticsData[]>([]);

  public readonly isNoAssets$ = new BehaviorSubject<boolean | undefined>(undefined);

  constructor(
    private readonly warpInstrumentsStoreService: WarpInstrumentsStoreService,
    private readonly moexDatafeedService: MoexDatafeedService,
    private readonly translateService: TranslateService,
    private readonly accountManagerService: AccountManagerService,
  ) {}

  public updateAnalyticsStream(moexAccounts: IParentAccount[]): Observable<WarpInstrument[]> {
    this.accountManagerService.subscribeOnSingleAccountChanges(moexAccounts);

    return this.accountManagerService.parentAccountsRub$.pipe(
      filter(accountsRub => accountsRub !== undefined),
      tap(accountsRub => {
        this.accountsRub = accountsRub;
        this.symbolsInfo = this.getSymbolsInfo();
      }),
      switchMap(() => {
        const instruments = this.getInstruments(this.symbolsInfo);

        return this.requestFinToolsData(instruments);
      }),
      switchMap(() => {
        const symbols = this.getSymbols();

        return this.warpInstrumentsStoreService.getInstrumentsForPortfolio(symbols);
      }),
      tap(instruments => instruments.forEach(instrument => this.instruments.set(instrument.symbol, instrument))),
      tap(() => this.updateAnalyticsGroups()),
    );
  }

  public addWidgetId(id: string): void {
    this.accountManagerService.addWidgetId(id);
  }

  public removeWidgetId(id: string): void {
    this.accountManagerService.removeWidgetId(id);
  }

  private getSymbols(): string[] {
    return this.symbolsInfo.flatMap(info =>
      info.data.map(data => WarpInstrumentHelper.getCurrencySymbol(data.symbol) || data.symbol),
    );
  }

  private updateAnalyticsGroups(): void {
    const symbolData = this.symbolsInfo.flatMap(symbolInfo => symbolInfo.data);

    if (symbolData.length === 0) {
      this.isNoAssets$.next(true);

      return;
    }

    this.updateAssetsGroup(symbolData);
    this.updateSectorGroup(symbolData);
    this.updateCurrenciesGroup(symbolData);

    this.updateTotal(this.accountsRub);

    this.isNoAssets$.next(false);
  }

  private requestFinToolsData(instruments: WarpInstrument[]): Observable<void> {
    const isinToSymbol = new Map<string, string>();

    instruments
      .filter(instrument => !!instrument?.isin)
      .map(instrument => isinToSymbol.set(instrument.isin, instrument.symbol));

    return from(this.moexDatafeedService.getFinToolRefData(isinToSymbol));
  }

  private updateCurrenciesGroup(symbolsData: ISymbolData[]): void {
    const groupByCurrencies = this.getGroupByCurrencies(symbolsData);
    const analyticsData = this.getAnalyticsData(symbolsData, groupByCurrencies);

    this.currenciesGroup$.next(analyticsData);
  }

  private updateSectorGroup(symbolsData: ISymbolData[]): void {
    const groupByIssuersector = this.getGroupByIssuersector(symbolsData);
    const analyticsData = this.getAnalyticsData(symbolsData, groupByIssuersector);

    this.sectorGroup$.next(analyticsData);
  }

  private updateAssetsGroup(symbolsData: ISymbolData[]): void {
    const groupByIssuersector = this.getGroupByType(symbolsData);
    const analyticsData = this.getAnalyticsData(symbolsData, groupByIssuersector);

    this.assetsGroup$.next(analyticsData);
  }

  private getGroupByCurrencies(symbolsData: ISymbolData[]): {[key: string]: ISymbolData[]} {
    const group = _.groupBy(symbolsData, symbolData => {
      const isCurrency = symbolData.isCurrency;

      if (isCurrency) {
        return symbolData.symbol;
      }

      return EBaseCurrency.Rub;
    });

    return group;
  }

  private getGroupByIssuersector(symbolsData: ISymbolData[]): {[key: string]: ISymbolData[]} {
    const group = _.groupBy(symbolsData, symbolData => {
      const issuersector = this.moexDatafeedService.rudataFinToolsRefData.get(symbolData.symbol)?.issuersector;

      if (issuersector === undefined) {
        return this.getTranslation(this.translationKeys.monetaryAssets);
      }

      return issuersector;
    });

    return group;
  }

  private getGroupByType(symbolsData: ISymbolData[]): {[key: string]: ISymbolData[]} {
    const group = _.groupBy(symbolsData, symbolData => {
      const instrument = this.instruments.get(symbolData.symbol);
      const cfiCode = instrument?.cfiCode;

      if (CfiCodeHelper.isStock(cfiCode)) {
        return this.getTranslation(this.translationKeys.stock);
      }

      if (CfiCodeHelper.isBond(cfiCode)) {
        return this.getTranslation(this.translationKeys.bond);
      }

      if (symbolData.isCurrency) {
        return this.getTranslation(this.translationKeys.currency);
      }

      return this.getTranslation(this.translationKeys.everythingElse);
    });

    return group;
  }

  private getAnalyticsData(symbolsData: ISymbolData[], group: {[key: string]: ISymbolData[]}): IAnalyticsData[] {
    const allPositionsPrice = this.calculatePositionsPrice(symbolsData);

    const analyticsData = Object.keys(group)
      .map(key => {
        const positionPrice = this.calculatePositionsPrice(group[key]);
        const percent = this.calculatePercent(positionPrice, allPositionsPrice);

        const formattedPositionPrice = this.formatNumber(positionPrice);
        const formattedPercent = this.formatNumber(percent);

        return {
          data: [[key], [formattedPositionPrice]],
          title: key,
          description: '',
          amount: formattedPositionPrice,
          percent: formattedPercent,
        };
      })
      .filter(data => !LazyDecimalHelper.from(data.amount).isZero());

    return analyticsData;
  }

  private calculatePositionsPrice(positions: ISymbolData[]): LazyDecimalHelper {
    const price = positions.reduce((priceValue, symbolData) => {
      const positionQuantity = LazyDecimalHelper.from(symbolData.qtyUnits.toString());
      const instrument = this.instruments.get(symbolData.symbol);

      let positionPrice: LazyDecimalHelper;

      if (symbolData.symbol === EBaseCurrency.Rub) {
        positionPrice = positionQuantity;
      } else {
        const assetLastPrice = instrument?.isBond
          ? this.getBondPrice(symbolData.lastPrice.value, instrument).value
          : symbolData.lastPrice.value;

        positionPrice = LazyDecimalHelper.from(assetLastPrice).multiply(positionQuantity);
      }

      return priceValue.plus(positionPrice);
    }, LazyDecimalHelper.from(0));

    return price;
  }

  private getTranslation(translationKey: string): string {
    return this.translateService.instant(translationKey);
  }

  private updateTotal(moexAccounts: IParentAccount[]): void {
    this.total$.next(LazyDecimalHelper.from(this.accountManagerService.getTotalPrice(moexAccounts).toFixed(2)));
  }

  private getBondPrice(value: string, instrument: WarpInstrument): LazyDecimalHelper {
    return LazyDecimalHelper.from(value).multiply(instrument.facevalue).divide(100);
  }

  private getSymbolsInfo(): ISymbolInfo[] {
    return this.accountsRub.flatMap(account => account.subAccounts.map(subAccount => subAccount.symbolsInfo));
  }

  private getInstruments(symbolsInfo: ISymbolInfo[]): WarpInstrument[] {
    const instruments = symbolsInfo.map(symbolInfo => {
      const symbols = symbolInfo.data.map(
        symbolData => WarpInstrumentHelper.getCurrencySymbol(symbolData.symbol) || symbolData.symbol,
      );

      return symbols.map(symbol => this.instruments.get(symbol));
    });

    return _.flatten(instruments);
  }

  private calculatePercent(itemPrice: LazyDecimalHelper, totalPrice: LazyDecimalHelper): LazyDecimalHelper {
    return itemPrice.multiply(100).divide(totalPrice);
  }

  private formatNumber(value: LazyDecimalHelper): string {
    return value.toDecimalPlaces(2, 4).value;
  }
}
