import {Directive, EventEmitter, OnDestroy, Output, TemplateRef} from '@angular/core';
import {IWidgetSettings} from '@app/core/models/gridster/gridster-item';
import {BehaviorSubject, Subject} from 'rxjs';

import {GridsterWidgetSettings} from '../../core/models/gridster/gridster-widget-settings';

@Directive()
export abstract class AGridsterWidgetComponent<T = GridsterWidgetSettings> implements OnDestroy {
  public static getPair(settings: {pair?: string}): string {
    return settings?.pair ?? null;
  }

  public abstract makeSettings(config: IWidgetSettings): GridsterWidgetSettings;

  public abstract getDefaultSettings(): GridsterWidgetSettings;

  public readonly settings$ = new BehaviorSubject<T>(null);
  public readonly updateFactorySize$ = new Subject<void>();
  public readonly removeWidget$ = new Subject<void>();
  public widgetSettingsTemplate: TemplateRef<void>;

  @Output()
  public readonly skeletonStateChanged = new EventEmitter<boolean>();

  public getCurrentSettings(): T {
    return this.settings$.value;
  }

  public ngOnDestroy(): void {
    this.settings$.complete();
    this.updateFactorySize$.complete();
    this.removeWidget$.complete();
  }

  public showSkeleton(): void {
    this.skeletonStateChanged.emit(true);
  }

  public hideSkeleton(): void {
    this.skeletonStateChanged.emit(false);
  }
}
