import {CastTypes, Model} from '@app/core/model';

export class Phone extends Model {
  public id: number;
  public phone: string;
  public type?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public confirm: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public default: boolean;
  public maskedPhone: string;
  public isMasked?: boolean;

  public readonly casts = {
    id: [CastTypes.Number],
    phone: [CastTypes.String],
    type: [CastTypes.Null, CastTypes.Undefined, CastTypes.String],
    confirm: [CastTypes.Boolean],
    default: [CastTypes.Boolean],
    maskedPhone: [CastTypes.String, CastTypes.Undefined],
  };
}
