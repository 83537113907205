import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Decimal} from '@app/core/decimal';
import {apiDateFormat, userDateFormat} from '@app/core/helpers/date.formats';
import {camelCaseToSnakeCase} from '@app/core/utils/case-change';
import {convertKbToMb} from '@app/core/utils/file-helpers/convert-kb-to-mb';
import * as _ from 'lodash-es';
import moment from 'moment';

import {FormFieldRule, FormFieldRuleValue} from './form.field';

type TRuleValidatorFn = (rules?: FormFieldRuleValue[], extraRules?: FormFieldRule[]) => ValidatorFn;

export const CustomValidators: Record<string, TRuleValidatorFn> = {
  array: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value;
      const isArray: number = value && Object.keys(value).length;
      return isArray ? null : {array: {value}};
    };
  },

  digits: ([rule]) => {
    const digits = Number(rule);
    return control => {
      const value = String(control.value);
      return !value || value.length !== digits ? {digits} : null;
    };
  },

  digitsbetween: between => {
    const [min, max] = between.map(i => Number(i)).sort();
    return control => {
      if (isNaN(control.value)) {
        return {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          numeric: true,
        };
      }
      const value = String(control.value);
      if (value.length < min) {
        return {min};
      }
      if (value.length > max) {
        return {max};
      }
      return null;
    };
  },

  regex: (patterns: string[]) => {
    const regExpArr = patterns.map(pattern => regExploder(pattern));

    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }
      const mismatch = regExpArr.find(regExp => !regExp.test(control.value));
      return mismatch ? {regex: patterns} : null;
    };
  },

  min: ([ruleValue]: [FormFieldRuleValue], extraRules) => {
    const isNumeric = extraRules.find(([key]) => key === 'numeric');
    if (isNumeric) {
      return CustomValidators.minValue([ruleValue], extraRules);
    }

    return control => {
      const min = parseInt(ruleValue as string, 10);
      return control.value && control.value.length && control.value.length < min ? {min} : null;
    };
  },

  max: ([ruleValue]: [string], extraRules: FormFieldRule[]): ValidatorFn => {
    const isNumeric: boolean = extraRules.some(([key]): boolean => key === 'numeric');

    if (isNumeric) {
      return CustomValidators.maxValue([ruleValue], extraRules);
    }

    const maxValue = Number(ruleValue);

    return (control: AbstractControl): ValidationErrors => {
      const value = control.value as File;
      if (value instanceof File) {
        const maxFileSize: string = Number(convertKbToMb(maxValue)).toFixed(1);

        const maxFileSizeValue: string = Number(maxFileSize).toString();

        return value.size && value.size > (maxValue / 1.024) * 1000 ? {maxFileSize: maxFileSizeValue} : null;
      }

      return control.value && control.value.length && control.value.length > maxValue ? {maxValue} : null;
    };
  },

  minValue: ([minValue]: [FormFieldRuleValue]) => {
    const dec = Decimal.from(minValue as string);
    const reg = /^[0-9.]+$/;

    return (control: AbstractControl) => {
      if (isNaN(control.value) || !reg.test(control.value)) {
        return {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          numeric: true,
        };
      }

      const diff = dec.minusString(control.value || 0);
      const isValid = diff.isNegative() || diff.isZero();

      return isValid ? null : {minValue};
    };
  },

  maxValue: ([maxValue]: [FormFieldRuleValue]) => {
    const dec = Decimal.from(maxValue as string);

    return control => {
      if (isNaN(control.value)) {
        return {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          numeric: true,
        };
      }

      const diff = dec.minusString(control.value || 0);
      const isValid = diff.isPositive() || diff.isZero();

      return isValid ? null : {maxValue};
    };
  },

  minLength: ([length]: [FormFieldRuleValue]): ValidatorFn => {
    const minLength = Number(length);
    return (control: AbstractControl): ValidationErrors =>
      String(control.value).length >= minLength ? null : {minLength};
  },

  maxLength: ([length]: [FormFieldRuleValue]): ValidatorFn => {
    const maxLength = Number(length);
    return (control: AbstractControl): ValidationErrors =>
      String(control.value).length <= maxLength ? null : {maxLength};
  },

  required: (): ValidatorFn => Validators.required,

  notZero: (): ValidatorFn => {
    return notZero;
  },

  email: (): ValidatorFn => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (control: AbstractControl): ValidationErrors => {
      return (control.value && re.test(control.value)) || !control.value
        ? null
        : {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email: true,
          };
    };
  },

  requiredValue: ([value]: [FormFieldRuleValue]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      return control.value !== value ? {requiredValue: control.value} : null;
    };
  },

  requiredLength: ([length]: [string]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const parsedLength: number = parseInt(length, 10);
      return control.value && String(control.value).length === parsedLength ? null : {requiredLength: length};
    };
  },

  string: (): ValidatorFn => {
    return (): ValidationErrors => {
      return null;
    };
  },

  date: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      return isDateValid(control)
        ? null
        : {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date: true,
          };
    };
  },

  beforeOrEqual: ([date]: [string]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      if (!isDateValid(control)) {
        return {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          date: true,
        };
      }

      return moment(control.value) <= moment(date)
        ? null
        : {
            beforeOrEqual: moment(date).format(userDateFormat),
          };
    };
  },

  afterOrEqual: ([date]: [string]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      if (!isDateValid(control)) {
        return {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          date: true,
        };
      }

      return moment(control.value) >= moment(date)
        ? null
        : {
            afterOrEqual: moment(date).format(userDateFormat),
          };
    };
  },

  before: ([date]: [string]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      if (!isDateValid(control)) {
        return {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          date: true,
        };
      }

      return moment(control.value) < moment(date)
        ? null
        : {
            before: moment(date).format(userDateFormat),
          };
    };
  },

  after: ([date]: [string]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      if (!isDateValid(control)) {
        return {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          date: true,
        };
      }

      return moment(control.value) > moment(date)
        ? null
        : {
            after: moment(date).format(userDateFormat),
          };
    };
  },

  age: ([age]: [string]): ValidatorFn => {
    const limit: number = parseInt(age, 10);

    return (control: AbstractControl): ValidationErrors => {
      return moment(control.value, apiDateFormat).diff(new Date(), 'years') + limit <= 0 ? null : {age: age};
    };
  },

  nullable: (): ValidatorFn => (): ValidationErrors => null,

  distinct: (): ValidatorFn => (): ValidationErrors => null,

  phone: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value;

      return !value || /\+[0-9]{9,15}/.test(value)
        ? null
        : {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            phone: true,
          };
    };
  },

  postalcode: (): ValidatorFn => {
    return (): ValidationErrors => {
      return null;
    };
  },

  integer: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      return /^\d+$/.test(control.value)
        ? null
        : {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            integer: true,
          };
    };
  },

  boolean: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      return typeof control.value === 'boolean'
        ? null
        : {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            boolean: true,
          };
    };
  },

  between: (): ValidatorFn => {
    return (): ValidationErrors => {
      return null;
    };
  },

  requiredif: (rules: (number | string)[]): ValidatorFn => {
    return control => {
      const field = getField(rules[0]);

      if (control.parent) {
        const controlByField = control.parent.controls[field];

        if (!controlByField) {
          return null;
        }

        const value = controlByField.value as number | string;

        return rules.find(rule => String(rule) === String(value))
          ? control.value
            ? null
            : {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                requiredif: true,
              }
          : null;
      }

      return null;
    };
  },

  requiredIf: (rules: (number | string)[]): ValidatorFn => {
    return control => {
      const field = getField(rules[0]);

      if (control?.parent) {
        const value = getValueOfParentControl(control.parent, field);

        return rules.find(rule => String(rule) === String(value))
          ? control.value || control.value === 0
            ? null
            : {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                requiredIf: true,
              }
          : null;
      }

      return null;
    };
  },

  in: (values: (string | number | boolean)[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const value = String(control.value);
      return values.some((i: string | number | boolean) => String(i) === value) ? null : {in: values};
    };
  },

  url: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      const regExp = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

      return regExp.test(control.value)
        ? null
        : {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            url: true,
          };
    };
  },

  numeric: (): ValidatorFn => {
    const reg = /^[0-9.]+$/;
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value;

      return reg.test(value?.value ?? value)
        ? null
        : {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            numeric: true,
          };
    };
  },

  file: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value as Record<string, File>;

      if (!value) {
        return null;
      }

      return Object.values(value).find((item: File): boolean => !(item instanceof File))
        ? {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            file: true,
          }
        : null;
    };
  },

  empty: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      return _.isEmpty(control.value)
        ? {
            ['empty']: true,
          }
        : null;
    };
  },

  mimetypes: (types: string[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const file = control.value as File;
      return !file || types.some((type: string) => type === file.type) ? null : {mimetypes: types};
    };
  },

  maxFilesAmount: ([amount]: number[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value as Record<string, File>;
      return value && Object.values(value).length > amount ? {maxFilesAmount: amount} : null;
    };
  },

  requiredString: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const value: string = control.value as string;
      return value && value.trim && value.trim().length ? null : {required: true};
    };
  },

  recaptcha: (): ValidatorFn => (): ValidationErrors => null,

  same: ([key]: [string]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const same: AbstractControl = control.parent && control.parent.get(key);
      return !same || same.value === control.value ? null : {same: key};
    };
  },

  cardNumber: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value;
      return value && value.length >= 12 && value.split('').map(Number) && !luhnAlgorithm(value)
        ? {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cardNumber: true,
          }
        : null;
    };
  },

  cardHolderName: (): ValidatorFn => {
    const reg = /[-a-zA-Z'`~. ]/;
    return (control: AbstractControl): ValidationErrors => {
      return control.value && !reg.test(control.value)
        ? {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cardHolderName: true,
          }
        : null;
    };
  },

  minDigitsLength: ([length]: [FormFieldRuleValue]): ValidatorFn => {
    const minLength = Number(length);
    return (control: AbstractControl): ValidationErrors => {
      const numbers = String(control.value).replace(/[^\d]+/, '');
      return numbers.length >= minLength ? null : {minLength};
    };
  },

  referenceMessage: (): ValidatorFn => {
    const requiredCountOfDifferentAlphanumericCharacters = 2;

    return (control: AbstractControl): ValidationErrors => {
      const numbers = String(control.value).match(/[a-zA-Z0-9]/g);
      const uniqueNumbers = new Set(numbers);

      return uniqueNumbers.size >= requiredCountOfDifferentAlphanumericCharacters
        ? null
        : {invalidReferenceMessage: requiredCountOfDifferentAlphanumericCharacters};
    };
  },
};

export function regExploder(pattern: string): RegExp {
  try {
    return eval(
      pattern.replace(
        '\\pL',
        'A-Za-z\xAA\xB5\xBA\xC0-\xD6\xD8-\xF6\xF8-\u02C1\u02C6-\u02D1\u02E0-\u02E4\u02EC\u02EE\u0370-\u0374\u0376\u0377\u037A-' +
          '\u037D\u0386\u0388-\u038A\u038C\u038E-\u03A1\u03A3-\u03F5\u03F7-\u0481\u048A-\u0527\u0531-\u0556\u0559\u0561-\u0587\u05D0-' +
          '\u05EA\u05F0-\u05F2\u0620-\u064A\u066E\u066F\u0671-\u06D3\u06D5\u06E5\u06E6\u06EE\u06EF\u06FA-\u06FC\u06FF\u0710\u0712-' +
          '\u072F\u074D-\u07A5\u07B1\u07CA-\u07EA\u07F4\u07F5\u07FA\u0800-\u0815\u081A\u0824\u0828\u0840-\u0858\u08A0\u08A2-\u08AC\u0904-' +
          '\u0939\u093D\u0950\u0958-\u0961\u0971-\u0977\u0979-\u097F\u0985-\u098C\u098F\u0990\u0993-\u09A8\u09AA-\u09B0\u09B2\u09B6-' +
          '\u09B9\u09BD\u09CE\u09DC\u09DD\u09DF-\u09E1\u09F0\u09F1\u0A05-\u0A0A\u0A0F\u0A10\u0A13-\u0A28\u0A2A-' +
          '\u0A30\u0A32\u0A33\u0A35\u0A36\u0A38\u0A39\u0A59-\u0A5C\u0A5E\u0A72-\u0A74\u0A85-\u0A8D\u0A8F-\u0A91\u0A93-\u0AA8\u0AAA-' +
          '\u0AB0\u0AB2\u0AB3\u0AB5-\u0AB9\u0ABD\u0AD0\u0AE0\u0AE1\u0B05-\u0B0C\u0B0F\u0B10\u0B13-\u0B28\u0B2A-\u0B30\u0B32\u0B33\u0B35-' +
          '\u0B39\u0B3D\u0B5C\u0B5D\u0B5F-\u0B61\u0B71\u0B83\u0B85-\u0B8A\u0B8E-\u0B90\u0B92-' +
          '\u0B95\u0B99\u0B9A\u0B9C\u0B9E\u0B9F\u0BA3\u0BA4\u0BA8-\u0BAA\u0BAE-\u0BB9\u0BD0\u0C05-\u0C0C\u0C0E-\u0C10\u0C12-\u0C28\u0C2A-' +
          '\u0C33\u0C35-\u0C39\u0C3D\u0C58\u0C59\u0C60\u0C61\u0C85-\u0C8C\u0C8E-\u0C90\u0C92-\u0CA8\u0CAA-\u0CB3\u0CB5-' +
          '\u0CB9\u0CBD\u0CDE\u0CE0\u0CE1\u0CF1\u0CF2\u0D05-\u0D0C\u0D0E-\u0D10\u0D12-\u0D3A\u0D3D\u0D4E\u0D60\u0D61\u0D7A-\u0D7F\u0D85-' +
          '\u0D96\u0D9A-\u0DB1\u0DB3-\u0DBB\u0DBD\u0DC0-\u0DC6\u0E01-\u0E30\u0E32\u0E33\u0E40-' +
          '\u0E46\u0E81\u0E82\u0E84\u0E87\u0E88\u0E8A\u0E8D\u0E94-\u0E97\u0E99-\u0E9F\u0EA1-\u0EA3\u0EA5\u0EA7\u0EAA\u0EAB\u0EAD-' +
          '\u0EB0\u0EB2\u0EB3\u0EBD\u0EC0-\u0EC4\u0EC6\u0EDC-\u0EDF\u0F00\u0F40-\u0F47\u0F49-\u0F6C\u0F88-\u0F8C\u1000-\u102A\u103F\u1050-' +
          '\u1055\u105A-\u105D\u1061\u1065\u1066\u106E-\u1070\u1075-\u1081\u108E\u10A0-\u10C5\u10C7\u10CD\u10D0-\u10FA\u10FC-\u1248\u124A-' +
          '\u124D\u1250-\u1256\u1258\u125A-\u125D\u1260-\u1288\u128A-\u128D\u1290-\u12B0\u12B2-\u12B5\u12B8-\u12BE\u12C0\u12C2-' +
          '\u12C5\u12C8-\u12D6\u12D8-\u1310\u1312-\u1315\u1318-\u135A\u1380-\u138F\u13A0-\u13F4\u1401-\u166C\u166F-\u167F\u1681-' +
          '\u169A\u16A0-\u16EA\u1700-' +
          '\u170C\u170E-\u1711\u1720-\u1731\u1740-\u1751\u1760-\u176C\u176E-\u1770\u1780-\u17B3\u17D7\u17DC\u1820-\u1877\u1880-' +
          '\u18A8\u18AA\u18B0-\u18F5\u1900-\u191C\u1950-\u196D\u1970-\u1974\u1980-\u19AB\u19C1-\u19C7\u1A00-\u1A16\u1A20-' +
          '\u1A54\u1AA7\u1B05-\u1B33\u1B45-\u1B4B\u1B83-\u1BA0\u1BAE\u1BAF\u1BBA-\u1BE5\u1C00-\u1C23\u1C4D-\u1C4F\u1C5A-\u1C7D\u1CE9-' +
          '\u1CEC\u1CEE-\u1CF1\u1CF5\u1CF6\u1D00-\u1DBF\u1E00-\u1F15\u1F18-\u1F1D\u1F20-\u1F45\u1F48-\u1F4D\u1F50-' +
          '\u1F57\u1F59\u1F5B\u1F5D\u1F5F-' +
          '\u1F7D\u1F80-\u1FB4\u1FB6-\u1FBC\u1FBE\u1FC2-\u1FC4\u1FC6-\u1FCC\u1FD0-\u1FD3\u1FD6-\u1FDB\u1FE0-\u1FEC\u1FF2-\u1FF4\u1FF6-' +
          '\u1FFC\u2071\u207F\u2090-\u209C\u2102\u2107\u210A-\u2113\u2115\u2119-\u211D\u2124\u2126\u2128\u212A-\u212D\u212F-\u2139\u213C-' +
          '\u213F\u2145-\u2149\u214E\u2183\u2184\u2C00-\u2C2E\u2C30-\u2C5E\u2C60-\u2CE4\u2CEB-\u2CEE\u2CF2\u2CF3\u2D00-' +
          '\u2D25\u2D27\u2D2D\u2D30-\u2D67\u2D6F\u2D80-\u2D96\u2DA0-\u2DA6\u2DA8-\u2DAE\u2DB0-\u2DB6\u2DB8-\u2DBE\u2DC0-\u2DC6\u2DC8-' +
          '\u2DCE\u2DD0-\u2DD6\u2DD8-\u2DDE\u2E2F\u3005\u3006\u3031-\u3035\u303B\u303C\u3041-\u3096\u309D-\u309F\u30A1-\u30FA\u30FC-' +
          '\u30FF\u3105-\u312D\u3131-\u318E\u31A0-\u31BA\u31F0-\u31FF\u3400-\u4DB5\u4E00-\u9FCC\uA000-\uA48C\uA4D0-\uA4FD\uA500-' +
          '\uA60C\uA610-\uA61F\uA62A\uA62B\uA640-\uA66E\uA67F-\uA697\uA6A0-\uA6E5\uA717-\uA71F\uA722-\uA788\uA78B-\uA78E\uA790-' +
          '\uA793\uA7A0-\uA7AA\uA7F8-\uA801\uA803-\uA805\uA807-\uA80A\uA80C-\uA822\uA840-\uA873\uA882-\uA8B3\uA8F2-\uA8F7\uA8FB\uA90A-' +
          '\uA925\uA930-\uA946\uA960-\uA97C\uA984-\uA9B2\uA9CF\uAA00-\uAA28\uAA40-\uAA42\uAA44-\uAA4B\uAA60-\uAA76\uAA7A\uAA80-' +
          '\uAAAF\uAAB1\uAAB5\uAAB6\uAAB9-\uAABD\uAAC0\uAAC2\uAADB-\uAADD\uAAE0-\uAAEA\uAAF2-\uAAF4\uAB01-\uAB06\uAB09-' +
          '\uAB0E\uAB11-\uAB16\uAB20-\uAB26\uAB28-\uAB2E\uABC0-\uABE2\uAC00-\uD7A3\uD7B0-\uD7C6\uD7CB-\uD7FB\uF900-\uFA6D\uFA70-' +
          '\uFAD9\uFB00-\uFB06\uFB13-\uFB17\uFB1D\uFB1F-\uFB28\uFB2A-\uFB36\uFB38-\uFB3C\uFB3E\uFB40\uFB41\uFB43\uFB44\uFB46-' +
          '\uFBB1\uFBD3-\uFD3D\uFD50-\uFD8F\uFD92-\uFDC7\uFDF0-\uFDFB\uFE70-\uFE74\uFE76-\uFEFC\uFF21-\uFF3A\uFF41-\uFF5A\uFF66-' +
          '\uFFBE\uFFC2-\uFFC7\uFFCA-\uFFCF\uFFD2-\uFFD7\uFFDA-\uFFDC',
      ),
    );
  } catch (e) {
    return new RegExp('');
  }
}

export function getValueOfParentControl(
  parent: UntypedFormGroup | UntypedFormArray | undefined,
  field: string | number,
): string | number | boolean | undefined {
  if (!parent) {
    return;
  }

  const value = parent.controls[camelCaseToSnakeCase(field.toString())]?.value;

  return value === undefined ? getValueOfParentControl(parent?.parent, field) : value;
}

export function getField(rules: string | number): string | number {
  let field = rules;

  if (field && typeof field === 'string') {
    const fields = field.split('.', 5);

    if (fields) {
      field = fields[fields.length - 1];
    }
  }

  return field;
}

export function luhnAlgorithm(digits: number[]): boolean {
  let sum = 0;

  for (let i = 0; i < digits.length; i++) {
    let cardNum: number = Math.floor(digits[i]);

    if ((digits.length - i) % 2 === 0) {
      cardNum = cardNum * 2;

      if (cardNum > 9) {
        cardNum = cardNum - 9;
      }
    }

    sum += cardNum;
  }

  return sum % 10 === 0;
}

export function notZero(control: AbstractControl): ValidationErrors {
  return Decimal.from(control.value || '').isZero()
    ? {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        notZero: true,
      }
    : null;
}

function isDateValid(control: AbstractControl): boolean {
  return moment(control.value, apiDateFormat).isValid();
}
