import {from} from '@app/shared/utils/decimal';
import {Decimal} from 'decimal.js';
import * as _ from 'lodash-es';

export function precise(v: string | number, precision?: number, flag?: TPreciseFlag): string {
  const value = Number(v);

  if (Number.isNaN(value)) {
    return '-';
  }

  const decimal = from(value);

  return preciseDecimal(decimal, precision, flag);
}

export function preciseDecimal(value: Decimal, precision?: number, flag?: TPreciseFlag): string {
  if (_.isNil(precision)) {
    return value.toSignificantDigits().toFixed();
  }

  if (flag === undefined) {
    return value.toDecimalPlaces(precision, Decimal.ROUND_DOWN).toSignificantDigits().toFixed();
  }

  if (flag === 'roundUp') {
    return value.toDecimalPlaces(precision, Decimal.ROUND_UP).toSignificantDigits().toFixed();
  }

  if (flag === 'reduce') {
    const signFix = value.isNegative() ? 1 : 0;
    const intDigits = value.toFixed(0, Decimal.ROUND_DOWN).length - signFix;
    const reducedPrecision = Math.max(precision + 1 - intDigits, 2);
    return value.toFixed(reducedPrecision, Decimal.ROUND_DOWN);
  }

  return value.toFixed(precision, Decimal.ROUND_DOWN);
}

export type TPreciseFlag = 'reduce' | 'roundUp';
