import {IBalance} from '@app/trading-board/interfaces/b2trader/balance';
import {plainToInstance} from 'class-transformer';

import {EB2traderEventTypes} from '../../../enum/b2trader/b2trader-event-types';
import {EB2TraderEventHandlersReceived} from '../../../event-handlers/b2trader/b2trader-event-handlers.enum';
import {B2traderStateStore} from '../../../facades/b2trader-state-store';
import {Balance} from '../../../models/b2trader/balance';
import {b2TraderInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class B2traderBalanceInternalEventHandler extends AEventHandler<IBalance[]> {
  public type = EB2traderEventTypes.Balance;

  constructor(private readonly store: B2traderStateStore) {
    super();
  }

  public handleMessage(resource: IBalance[]): void {
    this.store.messages$.next({
      type: EB2TraderEventHandlersReceived.Balances,
      payload: plainToInstance(Balance, resource, {excludeExtraneousValues: true}),
    });
  }
}

b2TraderInternalEventHandlers.push(B2traderBalanceInternalEventHandler);
