<div *ngIf="!isManyRequests" class="resend-code" [class.resend-aside]="isAside">
  <span class="resend-label">{{ 'CodeResend.Label.HaveNotReceivedCode' | translate }}</span>

  <div *ngIf="!isTimeEnd" class="time-output">
    <span>{{ 'CodeResend.Label.ResendIn' | translate }}</span>

    <app-countdown [time]="timeout" (finalEvent)="onTimeEnd()"></app-countdown>
  </div>

  <div *ngIf="isTimeEnd">
    <button type="button" (click)="resend()">{{ 'CodeResend.Label.Resend' | translate }}</button>
  </div>
</div>

<div *ngIf="isManyRequests" class="resend-limit" [class.resend-aside]="isAside">
  <div class="limit-message">{{ 'CodeResend.Label.TooManyRequests' | translate }}</div>

  <div class="time-output">
    <span>{{ 'CodeResend.Label.RequestsWillBeAbleAfter' | translate }}</span>

    <app-countdown [time]="expiredTimer" (finalEvent)="onEndBlock()"></app-countdown>
  </div>
</div>
