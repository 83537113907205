import {Injectable} from '@angular/core';
import {isResponse} from '@app/core/exceptions/response-exception';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ConnectionService} from '../../../connection.service';
import {mapException} from '../../../exceptions/client.exception';
import {IMenuPointDto} from '../../../menu/models/menu-point.dto';

@Injectable({
  providedIn: 'root',
})
export class AdminMenuSettingsApiService {
  constructor(private readonly connectionService: ConnectionService) {}

  public getGeneralInfo(): Observable<IMenuPointDto | null> {
    return this.connectionService.get('/api/v1/frontend-menu/general').pipe(
      mapException,
      map(result => (isResponse(result) ? result.getData() : null)),
    );
  }
}
