import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {SafeUrl} from '@angular/platform-browser';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-file-input-images',
  templateUrl: './file.input.images.component.html',
  styleUrls: ['./file.input.images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputImagesComponent {
  @Input()
  public urls: SafeUrl[];

  @Input()
  public errors: Record<string, ValidationErrors> | undefined;

  @Input()
  public files: File[];

  @Input()
  public isCenteredContent = false;

  @Output()
  public readonly remove = new EventEmitter<number>();

  constructor(private readonly errorsService: ErrorsService) {}

  public rmFile(i: number): void {
    this.remove.emit(i);
  }

  public getError(key: string, value): Observable<string> {
    if (value.length > 5) {
      value = [];
    }

    return this.errorsService.getError(key, value);
  }

  public trackByIndex: TrackByFunction<SafeUrl> = index => index;
}
