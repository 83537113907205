import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';

import {LanguageService} from './services/language.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageComponent {
  public readonly allLanguages$ = this.languageService.allLanguages$;

  public get selectedLanguage(): string {
    return this.languageService.selected;
  }

  constructor(private readonly cdr: ChangeDetectorRef, private readonly languageService: LanguageService) {}

  public setLang(change: MatSelectChange): void {
    this.languageService.setLang(change.value);
  }
}
