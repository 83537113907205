import {Router} from '@angular/router';

/**
 * Reload current page via angular router (not window.location.reload()).
 *
 * @param router - Instance of Angular router.
 * @example
 *   reloadCurrentPage(this.router).then(() => {
 *     console.log('reloaded');
 *   });
 */
export async function reloadCurrentPage(router: Router): Promise<void> {
  const prevShouldReuseRoute = router.routeReuseStrategy.shouldReuseRoute;
  router.routeReuseStrategy.shouldReuseRoute = () => false;
  await router.navigateByUrl(router.url);
  router.routeReuseStrategy.shouldReuseRoute = prevShouldReuseRoute;
}
