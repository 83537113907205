import {interval, MonoTypeOperatorFunction} from 'rxjs';
import {throttle} from 'rxjs/operators';

export function betterThrottle<T>(time: number): MonoTypeOperatorFunction<T> {
  return throttle<T>(() => interval(time), {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    leading: true,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    trailing: true,
  });
}
