import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {mapException} from '@app/core/exceptions/client.exception';
import {Account} from '@app/core/models/account/account';
import {AccountService} from '@app/core/services/account.service';
import {B2marginAuth} from '@app/trading-board/models/b2margin/b2margin-auth';
import {plainToInstance} from 'class-transformer';
import {EMPTY, Observable} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {map, switchMap, take} from 'rxjs/operators';

@Injectable()
export class B2marginAuthService {
  public readonly apiData$ = this.auth();

  constructor(private readonly connection: ConnectionService, private readonly accountService: AccountService) {
    void this.auth();
  }

  private findRelatedAccount(accounts: Account[]): Account | undefined {
    return accounts.find(a => a.product.platform.isB2Margin());
  }

  public getAccountAuthLink(account: Account): Observable<string> {
    return this.getAccountAuthData(account).pipe(map(apiData => this.buildAuthUrl(apiData)));
  }

  private getAccountAuthData(account: Account): Observable<B2marginAuth> {
    return this.connection.get(`/api/v1/dxtrade/auth/${account.id}`).pipe(
      mapException,
      map((v: IExceptions<B2marginAuth>) => plainToInstance(B2marginAuth, v.getData())),
    );
  }

  private buildAuthUrl(apiData: B2marginAuth): string {
    return `${apiData.requestUrl}?token=${apiData.token}`;
  }

  private auth(): Observable<B2marginAuth> {
    return this.accountService.getAccountsAsync().pipe(
      take(1),
      switchMap(accounts => {
        const acc = this.findRelatedAccount(accounts);

        if (!acc) {
          return EMPTY;
        }

        return this.getAccountAuthData(acc);
      }),
      switchMap(apiData =>
        fromFetch<void>(this.buildAuthUrl(apiData), {
          selector: res => res.json().catch(() => undefined),
          method: 'GET',
          credentials: 'include',
        }).pipe(map(() => apiData)),
      ),
    );
  }
}
