import {GridsterWidgetSettings} from '@app/core/models/gridster/gridster-widget-settings';
import {ESynchronizationColors} from '@app/trading-board/enum/moex/synchronization-colors';
import {SeriesStyle} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {ResolutionString} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {Timezone} from '@b2broker/trading.view.charting.library/charting_library/datafeed-api';
import {Expose, plainToInstance} from 'class-transformer';
import {IsOptional, IsString} from 'class-validator';

export class TradingViewSettings {
  public static makeInstance(config: GridsterWidgetSettings): TradingViewSettings {
    if (!config) {
      throw new Error('Config is undefined');
    }

    return plainToInstance(TradingViewSettings, config, {excludeExtraneousValues: true});
  }

  public static getInstance(): TradingViewSettings {
    return plainToInstance(
      TradingViewSettings,
      {color: ESynchronizationColors.DefaultColor},
      {excludeExtraneousValues: true},
    );
  }

  @Expose()
  @IsOptional()
  @IsString()
  public pair?: string;

  @Expose()
  @IsOptional()
  @IsString()
  public currentInterval: ResolutionString;

  @Expose()
  @IsString()
  @IsOptional()
  public timezone: 'exchange' | Timezone;

  @Expose()
  @IsOptional()
  public state?: Record<string, unknown>;

  @Expose()
  @IsOptional()
  public color?: ESynchronizationColors;

  @Expose()
  @IsOptional()
  public seriesStyle?: SeriesStyle;
}
