import {Injectable, Optional} from '@angular/core';
import {AccountService} from '@app/core/services/account.service';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {Asset} from '@app/trading-board/models/b2trader/asset';
import {Balance} from '@app/trading-board/models/b2trader/balance';
import {Environment} from '@env/environment.entities';
import {plainToInstance} from 'class-transformer';
import {combineLatest, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class B2traderBalancesService extends AEventHandler<Balance[]> {
  public type = EB2TraderEventHandlersReceived.Balances;

  constructor(
    private readonly b2traderDatafeedService: B2TraderDatafeedService,
    private readonly environment: Environment,
    @Optional() private readonly accountService?: AccountService,
  ) {
    super();

    const accounts$ = this.environment.isB2TraderStandalone
      ? of([])
      : this.accountService?.getAccountsAsync().pipe(
          map(accounts =>
            accounts.filter(account => {
              const platform = account.product?.platform;
              return platform?.isWrapper() || platform?.isB2Trader();
            }),
          ),
        );

    combineLatest([accounts$ ?? of([]), this.b2traderDatafeedService.balances$]).subscribe(([accounts, balances]) => {
      const plainAssets =
        this.environment.isB2TraderStandalone || this.environment.isPublic
          ? Asset.getStandaloneTransformData(balances)
          : Asset.getTransformData(balances, accounts);
      const assets = plainToInstance(Asset, plainAssets, {excludeExtraneousValues: true});

      this.b2traderDatafeedService.assets$.next(assets);
    });
  }

  public handleMessage(resource: Balance[]): void {
    if (resource === null) {
      this.b2traderDatafeedService.balances$.next([]);
      return;
    }

    let balances = this.b2traderDatafeedService.balances$.value;

    resource.forEach((balance: Balance) => {
      if (resource.find(b => b.product === balance.product)) {
        balances = balances.filter((b: Balance) => b.product !== balance.product);
      }

      balances.push(balance);
    });

    this.b2traderDatafeedService.balances$.next(balances);
  }
}
