import {EDynamicChildren} from '@app/core/menu/enum/dynamic-children.enum';
import {IMenuPointDto} from '@app/core/menu/models/menu-point.dto';
import {AccessAlias} from '@env/environment.entities';
import * as _ from 'lodash-es';

import {allMenuItems} from '../const/all-menu-items';
import {TMenuPointInfo} from '../types/menu-point-info';
import {IMenuPointRawData} from '../types/menu-point-raw-data';

type TChildrenInfo = Readonly<{
  readonly childrenMenuPointList: IMenuPointRawData[];
  readonly childrenAccessAliasKeys: AccessAlias[];
}>;

export class MenuPointBuilder {
  public static getRawMenuInfo(menuPoint: IMenuPointDto): [IMenuPointRawData, AccessAlias[]] {
    const instance = new MenuPointBuilder(menuPoint);
    return [instance.getMenuPoint(), instance.accessAliasKeys];
  }

  public get accessAliasKeys(): AccessAlias[] {
    const accessAliasKeys = this.menuPointInfo?.accessAliasKeys ?? [];

    return _.uniq([...accessAliasKeys, ...this.childrenAccessAliasKeys]);
  }

  private readonly dynamicOptionalChildrenList: Record<string, EDynamicChildren> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internal_transfer: EDynamicChildren.InternalTransfer,
  };

  private readonly isNew: boolean = false;
  private readonly childrenList: IMenuPointDto[] | undefined;
  private readonly menuPointInfo: TMenuPointInfo | undefined;
  private readonly dynamicChildren: EDynamicChildren | undefined;
  private childrenAccessAliasKeys: AccessAlias[] = [];

  constructor({name, new: isNew, items}: IMenuPointDto) {
    this.isNew = isNew;
    this.childrenList = items?.length ? items : undefined;
    this.menuPointInfo = allMenuItems[name] as TMenuPointInfo | undefined;

    this.dynamicChildren = this.getDynamicChildren();
  }

  private isNotDynamicMenuPoint(point: IMenuPointDto): boolean {
    return !this.dynamicOptionalChildrenList[point.name];
  }

  private getDynamicChildren(): EDynamicChildren | undefined {
    if (this.menuPointInfo?.dynamicChildren) {
      return this.menuPointInfo.dynamicChildren;
    }

    if (this.childrenList?.length) {
      const possibleDynamicChildren = this.childrenList.find(
        children => this.dynamicOptionalChildrenList[children.name],
      );

      if (possibleDynamicChildren) {
        return this.dynamicOptionalChildrenList[possibleDynamicChildren.name];
      }
    }

    return undefined;
  }

  private getChildrenInfo(): TChildrenInfo {
    return this.childrenList
      .filter(menuPoint => this.isNotDynamicMenuPoint(menuPoint))
      .reduce<TChildrenInfo>(
        (childInfo, childMenuPoint) => {
          const [childrenMenuPoint, childrenAccessAliases] = MenuPointBuilder.getRawMenuInfo(childMenuPoint);

          childInfo.childrenMenuPointList.push(childrenMenuPoint);
          childInfo.childrenAccessAliasKeys.push(...childrenAccessAliases);

          return childInfo;
        },
        {childrenMenuPointList: [], childrenAccessAliasKeys: []},
      );
  }

  public getMenuPoint(): IMenuPointRawData {
    const menuPoint: IMenuPointRawData = {
      field: {
        url: this.menuPointInfo?.url,
        label: this.menuPointInfo?.label,
        icon: this.menuPointInfo?.icon,
        isNew: this.isNew,
      },
    };

    if (this.menuPointInfo?.isNoCapitalize) {
      menuPoint.field.isNoCapitalize = this.menuPointInfo?.isNoCapitalize;
    }

    if (this.menuPointInfo?.isParent && !this.childrenList?.length) {
      menuPoint.children = [];
    }

    if (this.childrenList) {
      const {childrenMenuPointList, childrenAccessAliasKeys} = this.getChildrenInfo();

      this.childrenAccessAliasKeys = childrenAccessAliasKeys;
      menuPoint.children = childrenMenuPointList;
    }

    if (this.dynamicChildren) {
      menuPoint.dynamicChildren = this.dynamicChildren;
    }

    if (this.menuPointInfo?.target) {
      menuPoint.field.target = this.menuPointInfo.target;
    }

    if (this.menuPointInfo?.dynamicChildren === EDynamicChildren.Pamm && !menuPoint.children?.length) {
      menuPoint.field.target = '_blank';
    }

    return menuPoint;
  }
}
