import {IUpdate} from '@app/trading-board/interfaces/b2margin/update';
import {ADataStoreModel} from '@app/trading-board/models/data-store-model';
import {ClassConstructor, plainToInstance} from 'class-transformer';
import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';

export class DataStore<T> {
  constructor(
    private readonly store: Map<string | number, ADataStoreModel<T>>,
    private readonly cls: ClassConstructor<ADataStoreModel<T>>,
    private readonly isExcludeExtraneousValues: boolean = false,
    private readonly key: string = 'id',
  ) {}

  public update(updateMap: Map<string | number, T>): Map<string | number, ADataStoreModel<T>> {
    this.store.forEach((item: ADataStoreModel<T>) => {
      const updateItem = updateMap.get(item[this.key]);

      if (!updateItem) {
        this.store.delete(item[this.key]);

        return;
      }

      item.update(updateItem);
      (updateItem as IUpdate).useUpdate = true;
    });

    if (this.store.size < updateMap.size) {
      const newItems = Array.from(updateMap.values()).filter((i: IUpdate) => !i.useUpdate);

      plainToInstance(this.cls, newItems, {
        excludeExtraneousValues: this.isExcludeExtraneousValues,
      }).forEach((item: ADataStoreModel<T>) => this.store.set(item[this.key], item));
    }

    return this.store;
  }
}

export function mapDataStoreUpdate<T, R>(
  cls: ClassConstructor<ADataStoreModel<T>>,
  isExcludeExtraneousValues: boolean = false,
  key: string = 'id',
): OperatorFunction<Map<string | number, T>, Map<string | number, R>> {
  const dataStore = new DataStore(new Map<string | number, ADataStoreModel<T>>(), cls, isExcludeExtraneousValues, key);

  return map((updateMap: Map<string | number, T>) => dataStore.update(updateMap) as unknown as Map<string | number, R>);
}
