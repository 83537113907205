import {Casts, CastTypes, Model} from '@app/core/model';

export class Announcement extends Model {
  public id: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public button_text: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public button_link?: string;
  public text: string;
  public title: string;
  public type: Type;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public created_at: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public updated_at: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public read = false; // frontend-only property to keep read messages

  public casts: Casts = {
    id: [CastTypes.Number],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    button_text: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    button_link: [CastTypes.String, CastTypes.Undefined],
    text: [CastTypes.String],
    title: [CastTypes.String],
    type: [Type],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_at: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_at: [CastTypes.String],
  };
}

export class Type extends Model {
  public caption: ECaption;
  public value: number;

  public casts: Casts = {
    caption: [CastTypes.String],
    value: [CastTypes.Number],
  };
}

export enum ECaption {
  Required = 'Required',
  Optional = 'Optional',
}
