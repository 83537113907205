import {CurrencyPositionsMetrics} from '@app/trading-board/models/b2margin/currency-positions-metrics';
import {EUpDownState} from '@app/up-down/up-down-state.enum';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {AccountTO} from '@b2broker/b2margin-trade-models/dist/models/accountTO';
import {Expose, Transform, Type} from 'class-transformer';

import {IAccounts, IAccountMetric} from '../../interfaces/b2margin/account';
import {DecimalHelper} from '../../models/decimal-helper';

export class Account {
  public static adaptToB2MarginAccount(
    accounts: B2MarginModels.AccountsTO,
    metrics: Map<string, B2MarginModels.AccountMetricsTO>,
  ): IAccountMetric[] {
    return accounts.availableAccounts
      .map((account: B2MarginModels.AccountTO) => {
        const accountMetrics: B2MarginModels.AccountMetricsTO = metrics.get(account.id);

        return {
          id: account.id,
          trId: undefined,
          name: account.baseCurrency,
          precision: account.baseCurrencyPrecision,
          balance: accountMetrics?.balance ?? 0,
          equity: accountMetrics?.equity,
          profitLose: accountMetrics?.profitLoss,
          closedPl: accountMetrics?.allMetrics?.closedPl,
          availableFunds: accountMetrics?.availableFunds ?? 0,
          initialMargin: accountMetrics?.allMetrics?.initialMargin,
          currencyPositionMetrics: accountMetrics?.allMetrics?.currencyPositionMetrics,
          isCurrentAccount: account.id === accounts.currentAccount.id,
          walletAccountId: account.accountCode,
          cashType: account.cashType,
        } as IAccountMetric;
      })
      .sort((a, b) => Number(a.id) - Number(b.id));
  }

  public id: string;
  public trId?: number;
  public name: string;
  public precision: number;
  public walletAccountId: string;
  public isCurrentAccount: boolean;

  @Expose()
  @Type(() => CurrencyPositionsMetrics)
  public currencyPositionMetrics: CurrencyPositionsMetrics[];

  @Transform(({value, obj}: {value; obj: IAccountMetric}) => DecimalHelper.from(value, obj.precision))
  public balance: DecimalHelper;

  @Transform(({value, obj}: {value; obj: IAccountMetric}) => DecimalHelper.from(value, obj.precision))
  public equity: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IAccountMetric}) => DecimalHelper.from(obj.profitLose ?? 0, obj.precision))
  public fpl: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IAccountMetric}) => DecimalHelper.from(obj.closedPl ?? 0, obj.precision))
  public cpl: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IAccountMetric}) => DecimalHelper.from(obj.initialMargin, obj.precision))
  public usedMargin: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IAccountMetric}) => DecimalHelper.from(obj.availableFunds, obj.precision))
  public usableMargin: DecimalHelper;

  @Expose()
  public cashType: AccountTO.CashTypeEnum;

  @Expose()
  @Transform(({obj}: {obj: IAccountMetric}) => {
    const usedMargin = DecimalHelper.from(obj.initialMargin, obj.precision);
    const equity = DecimalHelper.from(obj.equity, obj.precision);

    return (!usedMargin.isZero() && equity.divide(usedMargin).multiply(100).toNumber()) || 0;
  })
  @Transform(({value, obj}: {value; obj: IAccountMetric}) => DecimalHelper.from(value, obj.precision))
  public usableMarginPercents: DecimalHelper;

  public get getUpDownMarginState(): EUpDownState {
    const isDefault = this.usableMargin.isZero() || this.usableMarginPercents.toNumber() === 0;
    if (isDefault) {
      return EUpDownState.Default;
    }

    return this.usableMarginPercents.toNumber() > 100 ? EUpDownState.Up : EUpDownState.Down;
  }
}

export class AccountCollector implements Iterable<Account> {
  public static adapt(
    accounts: B2MarginModels.AccountsTO,
    metrics: Map<string, B2MarginModels.AccountMetricsTO>,
  ): IAccounts {
    const accountList = Account.adaptToB2MarginAccount(accounts, metrics);

    return {
      currentAccount: accountList.find(account => account.id === accounts.currentAccount?.id),
      list: accountList,
    };
  }

  @Expose({name: 'list'})
  @Type(() => Account)
  private readonly accountList: Account[];

  @Type(() => Account)
  public currentAccount: Account;

  public get length(): number {
    return this.accountList.length;
  }

  public *[Symbol.iterator](): Iterator<Account> {
    for (const a of this.accountList) {
      yield a;
    }
  }

  public get(id: string): Account | undefined {
    return this.accountList?.find(account => account.id === id);
  }

  public getByWalletId(walletAccountId: string): Account | undefined {
    return this.accountList?.find(account => account.walletAccountId === walletAccountId);
  }

  public getByCashType(cashType: AccountTO.CashTypeEnum): Account | undefined {
    return this.accountList?.find(account => account.cashType === cashType);
  }
}
