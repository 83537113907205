import {Account} from '@app/core/models/account/account';
import {B2marginDatafeedFacade} from '@app/trading-board/facades/b2margin-datafeed-facade';
import {B2marginSpotStateStore} from '@app/trading-board/facades/b2margin-spot-state-store';
import {B2marginAuthService} from '@app/trading-board/services/b2margin-auth.service';
import {TradingBoardLoadingService} from '@app/trading-board/services/trading-board-loading.service';

import {b2MarginInternalEventHandlers} from '../event-handlers/event-handler.abstract';

import '../event-handlers/b2margin/internal';

export class B2marginSpotDatafeedFacade extends B2marginDatafeedFacade {
  protected readonly store = new B2marginSpotStateStore();

  constructor(
    protected readonly tradingBoardLoadingService: TradingBoardLoadingService,
    protected readonly b2marginAuthService: B2marginAuthService,
  ) {
    super(tradingBoardLoadingService, b2marginAuthService);

    this.eventHandlers = b2MarginInternalEventHandlers.map(
      b2MarginInternalEventHandler => new b2MarginInternalEventHandler(this.store, this.findHandlerInstance.bind(this)),
    );

    this.store.visibleInstruments$.subscribe(async instruments => {
      const symbolsToAdd = instruments.map(({symbolWithSeparator}) => symbolWithSeparator);
      await this.requestMarketData(symbolsToAdd);
    });
  }

  protected findRelatedAccount(accounts: Account[]): Account | undefined {
    return accounts.find(a => a.product.platform.isWrapper());
  }
}
