import {Expose} from 'class-transformer';

export class OrganizationGovernmentRegistration {
  @Expose({name: 'ogrn_registration_date'})
  public ogrnRegistrationDate: string;

  @Expose({name: 'ogrn_body_state_registration'})
  public ogrnBodyStateRegistration: string;

  @Expose({name: 'ogrn_body_state_registration_address'})
  public ogrnBodyStateRegistrationAddress: string;
}
