import {Injectable, Injector} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {IErrorDialogData} from '@app/ui/error-dialog/error-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

import {ABaseFullscreenErrorService} from './base-fullscreen-error.service';

@Injectable({
  providedIn: 'root',
})
export class GridsterExcessiveActivityErrorService extends ABaseFullscreenErrorService {
  protected readonly config: MatDialogConfig<IErrorDialogData> = {
    width: '100%',
    height: '100%',
    closeOnNavigation: true,
    hasBackdrop: false,
    panelClass: ['error-dialog'],
    data: {
      header: this.translate.get('ErrorDialog.GridsterExcessiveActivity.Header') as Observable<string>,
      text: this.translate.get('ErrorDialog.GridsterExcessiveActivity.Text') as Observable<string>,
      textAccentButton: this.translate.get('ErrorDialog.GridsterExcessiveActivity.ButtonText') as Observable<string>,
      onAccentButton: () => void 0,
    },
  };

  constructor(injector: Injector, private readonly translate: TranslateService) {
    super(injector);
  }

  public displayError(): void {
    this.showDialog(this.config);
  }
}
