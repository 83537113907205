import {CfiCodeHelper} from '@app/pbsr/helpers/cfi-code.helper';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

import {ESearchInstrumentsType} from '../enums/search-instruments-type.enum';
import {IInstrumentFilter} from '../interfaces/instrument-filter.interface';

export class InstrumentsFilterService {
  public readonly filters: IInstrumentFilter[] = [
    {
      type: ESearchInstrumentsType.Currency,
      labelTranslateKey: marker(
        'TradingBoard.Components.Warp.PbsSelectInstrument.Services.InstrumentsFilterService.FilterType.Currency',
      ),
      isActive: true,
    },
    {
      type: ESearchInstrumentsType.Stock,
      labelTranslateKey: marker(
        'TradingBoard.Components.Warp.PbsSelectInstrument.Services.InstrumentsFilterService.FilterType.Stock',
      ),
      isActive: true,
    },
    {
      type: ESearchInstrumentsType.Bond,
      labelTranslateKey: marker(
        'TradingBoard.Components.Warp.PbsSelectInstrument.Services.InstrumentsFilterService.FilterType.Bond',
      ),
      isActive: true,
    },
  ];

  public activeFilterChanged(type: ESearchInstrumentsType): void {
    const activeFiltersCount = this.filters.filter(filterItem => filterItem.isActive);
    const filter = this.filters.find(filterItem => filterItem.type === type);

    filter.isActive = activeFiltersCount.length === 1 || !filter.isActive;
  }

  public isFilteredPassed(instrument: WarpInstrument): boolean {
    let isCurrencyFilterPassed = false;
    let isStockFilterPassed = false;
    let isBondFilterPassed = false;

    if (this.isFilterActive(ESearchInstrumentsType.Currency)) {
      isCurrencyFilterPassed = CfiCodeHelper.isCurrency(instrument.cfiCode);
    }

    if (this.isFilterActive(ESearchInstrumentsType.Stock)) {
      isStockFilterPassed = CfiCodeHelper.isStock(instrument.cfiCode);
    }

    if (this.isFilterActive(ESearchInstrumentsType.Bond)) {
      isBondFilterPassed = CfiCodeHelper.isBond(instrument.cfiCode);
    }

    return isCurrencyFilterPassed || isStockFilterPassed || isBondFilterPassed;
  }

  private isFilterActive(type: ESearchInstrumentsType): boolean {
    const filter = this.filters.find(filterItem => filterItem.type === type);

    return filter.isActive;
  }
}
