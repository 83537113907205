export class MoexChartRequest {
  public static mapResolution(resolution: string): string {
    switch (resolution) {
      case '15':
        return '15';
      case '1':
      case '5':
      case '60':
        return (Number(resolution) * 60).toString();
      case '1D':
        return 'D';
      case '1W':
        return 'W';
      case '1M':
        return 'M';
      case '12M':
        return 'Y';
      default:
        throw Error(`Unexpected resolution: ${resolution}`);
    }
  }
}
