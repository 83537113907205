import {Casts, CastTypes, Model} from '@app/core/model';
import {Country} from '@app/core/models/country';

export class Address extends Model {
  public type: EType;
  public city: string;
  public state: string;
  public subdivision: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public postal_code: string;
  public street: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public street_number: number;
  public address: string;

  public get country(): Country {
    return this._country || new Country();
  }

  public set country(value: Country) {
    this._country = value;
  }

  private _country?: Country;

  public readonly casts: Casts = {
    type: [CastTypes.Number],
    city: [CastTypes.String],
    state: [CastTypes.String],
    subdivision: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    postal_code: [CastTypes.String],
    street: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    street_number: [CastTypes.Number, CastTypes.String],
    address: [CastTypes.String],
    country: [Country, CastTypes.Undefined, CastTypes.Null],
  };
}

export enum EType {
  Residential = 1,
  Billing = 2,
  Mailing = 3,
}
