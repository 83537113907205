import {Casts, CastTypes, Model} from '@app/core/model';

export class CurrencyBalance extends Model {
  public balance: number;
  public currency: string;

  public readonly casts: Casts = {
    currency: [CastTypes.String],
    balance: [CastTypes.Number],
  };
}
