export enum EEventHandlersReceived {
  Instruments = 'instruments',
  Accounts = 'accounts',
  AccountMetrics = 'accountMetrics',
  UserSettings = 'userSettings',
  Level2 = 'level2',
  Error = 'error',
  Close = 'close',
  Ticks = 'ticks',
  InstrumentLimits = 'instrumentLimits',
  OpenOrders = 'openOrders',
  MarginImpact = 'marginImpact',
  MarginRates = 'marginRates',
  Rates = 'rates',
  TradingCalendar = 'tradingCalendar',
  OrderStatus = 'orderStatus',
  Positions = 'positions',
  PositionMarginImpact = 'positionMarginImpact',
  OrderHistory = 'orderHistory',
  Chart = 'chart',
  InstrumentSessions = 'instrumentSessions',
  HistoryOrders = 'historyOrders',
  Trades = 'trades',
  TradesHistory = 'tradesHistory',
  PopupMessages = 'popupMessages',
}

export enum EEventHandlersRequest {
  Marketdepth = 'marketdepth',
  SelectAccount = 'selectAccount',
  MarginImpact = 'marginImpact',
  MarginRates = 'marginRates',
  CreateOrder = 'createOrder',
  Rates = 'rates',
  PositionMarginImpact = 'positionMarginImpact',
  ClosePosition = 'closePosition',
  ModifyProtectionOrders = 'modifyProtectionOrders',
  Chart = 'chart',
  CloseOrder = 'closeOrder',
  CloseAllPositions = 'closeAllPositions',
  CloseAllOrders = 'closeAllOrders',
  CloseInstrumentPositions = 'closeInstrumentPositions',
  InstrumentSessions = 'instrumentSessions',
  ModifyOrder = 'modifyOrder',
  Trades = 'trades',
}
