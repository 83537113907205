import {Expose} from 'class-transformer';

export class LanguageFormat {
  @Expose()
  public fullDate: string;

  @Expose()
  public longDate: string;

  @Expose()
  public middleDate: string;

  @Expose()
  public shortDate: string;

  @Expose()
  public fullTime: string;

  @Expose()
  public longTime: string;

  @Expose()
  public middleTime: string;

  @Expose()
  public shortTime: string;

  @Expose()
  public decimal: string;

  @Expose()
  public thousands: string;

  @Expose()
  public name: string;
}
