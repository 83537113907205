import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {IB2MarginStatementDialogRes} from '@app/b2margin-statement-dialog/b2margin-statement-dialog-res.interface';
import {ConnectionService} from '@app/core/connection.service';
import {DATA_FEED_DEBOUNCE_IN_MS, DELAY_BEFORE_SYMBOLS_UNSUBSCRIBE} from '@app/core/constants/common';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {mapException} from '@app/core/exceptions/client.exception';
import {GridsterProviders} from '@app/core/models/gridster';
import {StatementB2Margin} from '@app/core/models/statement-b2margin';
import {AccountService} from '@app/core/services/account.service';
import {betterThrottle} from '@app/core/utils/better-throttle';
import {
  calculateAssetData,
  calculateInstrumentData,
  reduceInstrumentsData,
  sortListOfGroupedInstrumentData,
} from '@app/trading-board/business-logic/b2margin/positions-aggregation';
import {EChartRequestAction} from '@app/trading-board/enum/b2margin/chart-request-action';
import {mapDataStoreUpdate} from '@app/trading-board/facades/data-store';
import {IChartRequest} from '@app/trading-board/interfaces/b2margin/chart-request';
import {IGroupedInstrumentsData} from '@app/trading-board/interfaces/b2margin/grouped-instrument-data';
import {IUserSettings} from '@app/trading-board/interfaces/b2margin/user-settings';
import {IDataEvent} from '@app/trading-board/interfaces/data-event.ts';
import {IStreamWithRefCount} from '@app/trading-board/interfaces/stream-with-ref-count';
import {AccountCollector} from '@app/trading-board/models/b2margin/account';
import {B2MarginHistoryOrder} from '@app/trading-board/models/b2margin/b2-margin-history-order';
import {B2MarginOpenOrder} from '@app/trading-board/models/b2margin/b2-margin-open-order';
import {B2marginLiquidationMessage} from '@app/trading-board/models/b2margin/b2margin-liquidation-message';
import {AB2marginPopupMessage} from '@app/trading-board/models/b2margin/b2margin-popup-message';
import {
  AccountTranslate,
  LiquidationTranslate,
  RiskLevelAlertTextTranslate,
} from '@app/trading-board/models/b2margin/b2margin-popup-translates';
import {B2marginRiskLevelAlertMessage} from '@app/trading-board/models/b2margin/b2margin-risk-level-alert-message';
import {InstrumentLimits} from '@app/trading-board/models/b2margin/instrument-limits';
import {InstrumentSession} from '@app/trading-board/models/b2margin/instrument-session';
import {MarginRate} from '@app/trading-board/models/b2margin/margin-rate';
import {OrderHistory} from '@app/trading-board/models/b2margin/order-history';
import {Position, TPositionsMapType} from '@app/trading-board/models/b2margin/position';
import {TradeHistory} from '@app/trading-board/models/b2margin/trade-history';
import {B2marginPopupQueueService} from '@app/trading-board/services/b2margin-popup-queue.service';
import {TMarginImpactFn} from '@app/trading-board/types/margin-impact-fn';
import {ProtectionOrderTO} from '@b2broker/b2margin-trade-models';
import {AccountTO} from '@b2broker/b2margin-trade-models/dist/models/accountTO';
import {Bar as IBar} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {WalletMenu} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash-es';
import {nanoid} from 'nanoid';
import {BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, filter, finalize, first, map, shareReplay, take} from 'rxjs/operators';
import {InstrumentGroup} from 'src/app/trading-board/models/b2margin/instrument-group';

import {EConnectionLevel} from '../enum/connection-level';
import {EEventHandlersRequest} from '../event-handlers/event-handlers.enum';
import {IClosePositionOptions} from '../interfaces/b2margin/close-position-options';
import {IOrderData} from '../interfaces/b2margin/order-data';
import {ITradingViewLine} from '../interfaces/b2margin/trading-view-line';
import {IInstrument} from '../interfaces/instrument';
import {IBarsHistoryRequest} from '../interfaces/moex/bars-history-request.interface';
import {ISingleRequestMessage} from '../interfaces/single-request-message';
import {B2MarginInstrument, TB2MarginInstrumentMapType} from '../models/instrument';
import {Level1Collector} from '../models/level1-collector';
import {Level2} from '../models/level2';
import {Trade} from '../models/trade';
import {TradingViewLine} from '../models/trading-view-line';
import {WorkerConnectionService} from '../services/worker-connection.service';
import {ATradeDatafeed} from './trade-datafeed.abstract';

type TCurrenciesRatesMapType = Map<string, number>;

@Injectable()
export class B2MarginDatafeedService extends ATradeDatafeed implements OnDestroy {
  protected static sharedWorkerPath = '/assets/b2margin.shared-worker.js';
  protected static workerPath = '/assets/b2margin.web-worker.js';
  private readonly topics = new Map<string, string>();

  private getBarsRequestId = 0;
  private isInitialized = false;

  public readonly validCashType = AccountTO.CashTypeEnum.MARGIN;
  public readonly invalidCashType = AccountTO.CashTypeEnum.CASH;

  public readonly provider: GridsterProviders = GridsterProviders.b2margin;
  public instrumentSessions = new Map<string, IStreamWithRefCount<InstrumentSession>>();

  public chartDataTopics = new Map<string, ReplaySubject<IBar[]>>();

  public positions$ = new BehaviorSubject<Position[]>([]);
  public readonly positionsMap$ = this.positions$.pipe(
    map(positions => Position.reduceByInstrument(positions)),
    shareReplay({refCount: true, bufferSize: 1}),
  );
  public endOfDay$ = new ReplaySubject<number>();
  public rates = new Map<string, IStreamWithRefCount<number>>();

  public marginRates = new Map<string, IStreamWithRefCount<MarginRate>>();
  public openOrders$ = new BehaviorSubject<B2MarginOpenOrder[]>([]);
  public level1$ = new BehaviorSubject<Level1Collector>(new Level1Collector());
  public level2 = new Map<string, IStreamWithRefCount<Level2>>();

  public marginImpact = new Map<string, IStreamWithRefCount<TMarginImpactFn>>();
  public positionMarginImpact = new Map<string, IStreamWithRefCount<TMarginImpactFn>>();
  public instruments$ = new BehaviorSubject<B2MarginInstrument[]>([]);
  public readonly instrumentsMap$ = this.instruments$.pipe(
    map(instruments => B2MarginInstrument.reduceListToMap(instruments)),
    shareReplay({refCount: true, bufferSize: 1}),
  );
  public instrumentGroup$ = new BehaviorSubject<InstrumentGroup[]>([]);
  public instrumentLimits$ = new BehaviorSubject<Map<number, InstrumentLimits>>(new Map());
  public orderHistory$ = new BehaviorSubject<OrderHistory[]>([]);
  public accounts$ = new BehaviorSubject<AccountCollector>(null);
  public currentAccount$ = this.accounts$.pipe(
    filter(account => !!account),
    map(acc => acc.currentAccount),
  );
  public userSettings: IUserSettings | null = null;
  public historyOrders$ = new BehaviorSubject<Map<number, B2MarginHistoryOrder>>(new Map());
  public trades = new Map<string, IStreamWithRefCount<Trade[], BehaviorSubject<Trade[]>>>();
  public tradesHistory$ = new BehaviorSubject<TradeHistory[]>([]);
  public popupMessage$ = new Subject<AB2marginPopupMessage>();

  public watchListPairs$ = new BehaviorSubject<Map<string, number>>(new Map());

  constructor(
    protected readonly connectionService: ConnectionService,
    protected readonly workerConnectionService: WorkerConnectionService,
    protected readonly translate: TranslateService,
    protected readonly dialog: MatDialog,
    protected readonly accountService: AccountService,
    protected readonly popupQueueService: B2marginPopupQueueService,
  ) {
    super();

    this.subscribePopupMessages();
  }

  public hasConnect(): boolean {
    return this.isInitialized;
  }

  public initialize(level: EConnectionLevel = EConnectionLevel.Main): void {
    this.workerConnectionService.initialize({
      level,
      webWorkerPath: B2MarginDatafeedService.workerPath,
      sharedWorkerPath: B2MarginDatafeedService.sharedWorkerPath,
    });

    this.isInitialized = true;
    this.finishedInitialize$.next();
  }

  public afterConnect(): void {
    if (this.level2.size) {
      this.workerConnectionService.sendMessage({
        type: EEventHandlersRequest.Marketdepth,
        payload: {symbolsToAdd: Array.from(this.level2.keys()), symbolsToRemove: []},
      });
    }
  }

  public terminate(): void {
    this.workerConnectionService.terminate();

    B2MarginDatafeedService.clearMapSubject(this.rates);
    B2MarginDatafeedService.clearMapSubject(this.marginRates);
    B2MarginDatafeedService.clearMapSubject(this.marginImpact);
    B2MarginDatafeedService.clearMapSubject(this.trades);
    B2MarginDatafeedService.clearMapSubject(this.positionMarginImpact);

    this.instruments$.next([]);
    this.instrumentGroup$.next([]);
    this.positions$.next([]);

    this.openOrders$.next([]);

    this.instrumentLimits$.next(new Map());
    this.orderHistory$.next([]);
    this.accounts$.next(null);
    this.userSettings = null;
    this.topics.clear();
    this.chartDataTopics.clear();
    this.historyOrders$.next(new Map());
    this.tradesHistory$.next([]);

    this.isInitialized = false;
    this.accounts$.next(null);
  }

  public getB2MarginToken(accountId: number): Observable<IExceptions> {
    return this.connectionService.get(`/api/v1/b2margin/token/${accountId}`).pipe(mapException);
  }

  public subscribePopupMessages(): void {
    this.popupMessage$.subscribe((message: AB2marginPopupMessage) => {
      if (message instanceof B2marginRiskLevelAlertMessage) {
        const {type, baseCurrency, accountCode, riskWarningLevel, riskLevelLimit, date} = message;

        this.popupQueueService.next({
          type,
          header: this.translate.get(AccountTranslate, {baseCurrency, accountCode}),
          text: this.translate.get(RiskLevelAlertTextTranslate[riskWarningLevel], {riskLevelLimit}),
          date,
        });
      }

      if (message instanceof B2marginLiquidationMessage) {
        const {type, baseCurrency, accountCode, date} = message;

        this.popupQueueService.next({
          type,
          header: this.translate.get(AccountTranslate, {baseCurrency, accountCode}),
          text: this.translate.get(LiquidationTranslate),
          date,
        });
      }
    });
  }

  public getLevel2({symbolWithSeparator, priceScale, amountScale}: IInstrument): Observable<Level2> {
    let obj = this.level2.get(symbolWithSeparator);

    if (!obj) {
      const level2: Level2 = plainToClass(Level2, {
        asks: [],
        bids: [],
        symbolWithSeparator,
        priceScale,
        amountScale,
      });

      obj = {
        stream: new BehaviorSubject(level2),
        subscribers: 0,
      };

      this.level2.set(symbolWithSeparator, obj);
      this.workerConnectionService.sendMessage({
        type: EEventHandlersRequest.Marketdepth,
        payload: {symbolsToAdd: [symbolWithSeparator], symbolsToRemove: []},
      });
    }

    obj.subscribers++;

    return obj.stream.pipe(
      betterThrottle(ATradeDatafeed.throttleTime),
      finalize(() => {
        setTimeout(() => {
          obj.subscribers--;

          if (obj.subscribers < 1) {
            this.level2.delete(symbolWithSeparator);

            this.workerConnectionService.sendMessage({
              type: EEventHandlersRequest.Marketdepth,
              payload: {symbolsToRemove: [symbolWithSeparator], symbolsToAdd: []},
            });
          }
        }, DELAY_BEFORE_SYMBOLS_UNSUBSCRIBE);
      }),
    );
  }

  public selectAccount(accountId: string): void {
    this.tradesHistory$.next([]);
    this.historyOrders$.next(new Map());

    this.orderHistory$.next([]);

    this.workerConnectionService.sendMessage({
      type: EEventHandlersRequest.SelectAccount,
      payload: accountId,
    });
  }

  public getMarginImpact(symbolWithSeparator: string): Observable<TMarginImpactFn> {
    let obj = this.marginImpact.get(symbolWithSeparator);

    if (!obj) {
      obj = {stream: new ReplaySubject(1), subscribers: 0};

      this.marginImpact.set(symbolWithSeparator, obj);

      this.workerConnectionService.sendMessage({
        type: EEventHandlersRequest.MarginImpact,
        payload: {symbolsToAdd: [symbolWithSeparator], symbolsToRemove: []},
      });
    }

    obj.subscribers++;

    return obj.stream.pipe(
      finalize(() => {
        obj.subscribers--;

        if (obj.subscribers < 1) {
          this.marginImpact.delete(symbolWithSeparator);

          this.workerConnectionService.sendMessage({
            type: EEventHandlersRequest.MarginImpact,
            payload: {symbolsToRemove: [symbolWithSeparator], symbolsToAdd: []},
          });
        }
      }),
    );
  }

  public getPositionMarginImpact(instrumentId: string, positionCode: string): Observable<TMarginImpactFn> {
    let obj = this.positionMarginImpact.get(positionCode);

    if (!obj) {
      obj = {stream: new ReplaySubject(1), subscribers: 0};
      this.positionMarginImpact.set(positionCode, obj);

      this.workerConnectionService.sendMessage({
        type: EEventHandlersRequest.PositionMarginImpact,
        payload: {positionKeysToAdd: [{instrumentId, positionCode}]},
      });
    }

    obj.subscribers++;

    return obj.stream.pipe(
      finalize(() => {
        obj.subscribers--;

        if (obj.subscribers < 1) {
          this.positionMarginImpact.delete(positionCode);

          this.workerConnectionService.sendMessage({
            type: EEventHandlersRequest.PositionMarginImpact,
            payload: {positionKeysToRemove: [{instrumentId, positionCode}]},
          });
        }
      }),
    );
  }

  public getMarginRates(symbolWithSeparator: string): Observable<MarginRate> {
    let obj = this.marginRates.get(symbolWithSeparator);

    if (!obj) {
      obj = {stream: new Subject<MarginRate>(), subscribers: 0};
      this.marginRates.set(symbolWithSeparator, obj);
    }

    this.workerConnectionService.sendMessage({
      type: EEventHandlersRequest.MarginRates,
      payload: symbolWithSeparator,
    });

    obj.subscribers++;

    return obj.stream.pipe(
      finalize(() => {
        obj.subscribers--;

        if (obj.subscribers < 1) {
          this.marginRates.delete(symbolWithSeparator);
        }
      }),
    );
  }

  public createOrder(request: IOrderData): Promise<boolean> {
    const id = nanoid();

    this.workerConnectionService.sendMessage<ISingleRequestMessage<IOrderData>>({
      type: EEventHandlersRequest.CreateOrder,
      payload: {
        data: request,
        id,
      },
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.CreateOrder && message.payload.id === id,
        ),
        take(1),
        map(
          ({
            payload: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              data,
            },
          }) => data,
        ),
      ),
    );
  }

  public modifyOrder(request: IOrderData): Promise<boolean> {
    const id = nanoid();

    this.workerConnectionService.sendMessage<ISingleRequestMessage<IOrderData>>({
      type: EEventHandlersRequest.ModifyOrder,
      payload: {
        data: request,
        id,
      },
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.ModifyOrder && message.payload.id === id,
        ),
        take(1),
        map(
          ({
            payload: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              data,
            },
          }) => data,
        ),
      ),
    );
  }

  public getConversionRates(symbolWithSeparator: string): Observable<number> {
    let obj = this.rates.get(symbolWithSeparator);

    if (!obj) {
      obj = {stream: new ReplaySubject<number>(), subscribers: 0};
      this.rates.set(symbolWithSeparator, obj);

      this.workerConnectionService.sendMessage({
        type: EEventHandlersRequest.Rates,
        payload: {symbolsToAdd: [symbolWithSeparator], symbolsToRemove: []},
      });
    }

    obj.subscribers++;

    return obj.stream.pipe(
      finalize(() => {
        obj.subscribers--;

        if (obj.subscribers < 1) {
          this.rates.delete(symbolWithSeparator);

          this.workerConnectionService.sendMessage({
            type: EEventHandlersRequest.Rates,
            payload: {symbolsToRemove: [symbolWithSeparator], symbolsToAdd: []},
          });
        }
      }),
    );
  }

  public clearConversionRates(): void {
    const symbolsToRemove = Array.from(this.rates.keys());

    if (!symbolsToRemove.length) {
      return;
    }

    this.workerConnectionService.sendMessage({
      type: EEventHandlersRequest.Rates,
      payload: {symbolsToRemove, symbolsToAdd: []},
    });

    this.rates.clear();
  }

  public closePosition(options: IClosePositionOptions): Promise<boolean> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({type: EEventHandlersRequest.ClosePosition, payload: {id, data: options}});

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.ClosePosition && message.payload.id === id,
        ),
        take(1),
        map(
          ({
            payload: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              data,
            },
          }) => data,
        ),
      ),
    );
  }

  public closeAllInstrumentPositions(instrumentId: string): Promise<boolean> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EEventHandlersRequest.CloseInstrumentPositions,
      payload: {id, data: {instrumentId}},
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.CloseInstrumentPositions && message.payload.id === id,
        ),
        take(1),
        map(
          ({
            payload: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              data,
            },
          }) => data,
        ),
      ),
    );
  }

  public modifyProtectionOrders(positionId: string, protectionOrders: ProtectionOrderTO[]): Promise<boolean> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EEventHandlersRequest.ModifyProtectionOrders,
      payload: {id, data: {positionId, protectionOrders}},
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.ModifyProtectionOrders && message.payload.id === id,
        ),
        take(1),
        map(
          ({
            payload: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              data,
            },
          }) => data,
        ),
      ),
    );
  }

  public getBarsData$(request: IBarsHistoryRequest): Observable<IBar[]> {
    const {symbol, resolution, isFirstCall} = request;
    const id = this.getBarsRequestId++;
    if (!isFirstCall) {
      return of([] as IBar[]);
    }

    const subtopic = `BigChartComponentPresenter-${id}`;
    this.topics.set(symbol, subtopic);

    this.chartDataTopics.set(subtopic, new ReplaySubject(1));

    return new Observable(subscriber => {
      this.workerConnectionService.sendMessage<IChartRequest>({
        type: EEventHandlersRequest.Chart,
        payload: {subtopic, resolution, id, symbol, action: EChartRequestAction.Subscribe},
      });

      this.chartDataTopics
        .get(subtopic)
        .pipe(take(1))
        .subscribe(v => {
          subscriber.next(v);
          subscriber.complete();
        });
    });
  }

  public getBar$(symbol: string): Observable<IBar> {
    const subtopic = this.topics.get(symbol);

    return this.chartDataTopics.get(subtopic).pipe(
      filter((bar, i) => !!i),
      map(([v]) => v),
      filter(v => !!v),
      finalize(() => {
        this.workerConnectionService.sendMessage<IChartRequest>({
          type: EEventHandlersRequest.Chart,
          payload: {subtopic, action: EChartRequestAction.Unsubscribe},
        });
      }),
    );
  }

  public getTradingViewLines$(): Observable<Map<string | number, TradingViewLine>> {
    const positionsStream = combineLatest([this.positions$, this.translate.get('PL').pipe(first())]).pipe(
      map(([positions, translation]) => TradingViewLine.reducePosition(positions, translation)),
    );
    const ordersStream = this.openOrders$.pipe(map(openOrders => TradingViewLine.reduceOrder(openOrders)));

    return combineLatest([positionsStream, ordersStream]).pipe(
      map(([positions, orders]) => {
        return new Map<string | number, ITradingViewLine>([...positions].concat([...orders]));
      }),
      mapDataStoreUpdate<ITradingViewLine, TradingViewLine>(TradingViewLine),
    );
  }

  public closeOrder(orderId: number, accountId: string): Promise<boolean> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EEventHandlersRequest.CloseOrder,
      payload: {id, data: {orderId, accountId}},
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.CloseOrder && message.payload.id === id,
        ),
        take(1),
        map(
          ({
            payload: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              data,
            },
          }) => data,
        ),
      ),
    );
  }

  public getInstrumentSession(symbol: string): Observable<InstrumentSession> {
    let obj = this.instrumentSessions.get(symbol);
    if (!obj) {
      obj = {stream: new ReplaySubject<InstrumentSession>(1), subscribers: 0};

      this.instrumentSessions.set(symbol, obj);
      this.workerConnectionService.sendMessage({type: EEventHandlersRequest.InstrumentSessions, payload: symbol});
    }

    obj.subscribers++;

    return obj.stream.pipe(
      finalize(() => {
        obj.subscribers--;

        if (obj.subscribers < 1) {
          this.instrumentSessions.delete(symbol);
        }
      }),
    );
  }

  public getTrades$(symbol: string): Observable<Trade[]> {
    let obj = this.trades.get(symbol);

    if (!obj) {
      obj = {stream: new BehaviorSubject<Trade[]>([]), subscribers: 0};
      this.trades.set(symbol, obj);
      this.workerConnectionService.sendMessage({
        type: EEventHandlersRequest.Trades,
        payload: {symbolsToAdd: [symbol], symbolsToRemove: []},
      });
    }

    obj.subscribers++;

    return obj.stream.pipe(
      finalize(() => {
        obj.subscribers--;

        if (obj.subscribers < 1) {
          this.trades.delete(symbol);

          this.workerConnectionService.sendMessage({
            type: EEventHandlersRequest.Trades,
            payload: {symbolsToAdd: [], symbolsToRemove: [symbol]},
          });
        }
      }),
    );
  }

  public async openAccountStatementDialog(walletMenu: WalletMenu): Promise<void> {
    const allAccounts = await firstValueFrom(this.accountService.getAccountsAsync().pipe(first()));
    const destAcc = allAccounts.find(acc => this.accounts$.value.currentAccount.walletAccountId === acc.account_id);
    const dialog = await import('../../b2margin-statement-dialog/b2margin-statement-dialog.component');

    const dialogRes = await firstValueFrom<IB2MarginStatementDialogRes>(
      this.dialog
        .open(dialog.B2marginStatementDialogComponent, {
          maxWidth: '95vw',
          autoFocus: false,
        })
        .afterClosed(),
    );

    if (!dialogRes) {
      return;
    }

    const statementBuilderParams = await firstValueFrom(this.getB2MarginToken(destAcc.id));
    window.open(
      plainToClass(StatementB2Margin, statementBuilderParams.getData()).getLinkStatementB2Margin(
        dialogRes.from,
        dialogRes.to,
        this.translate.currentLang,
        dialogRes.format,
      ),
      walletMenu.statementB2Margin.target,
    );
  }

  public closeAllPositions(): Promise<IDataEvent<ISingleRequestMessage<boolean>>> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({type: EEventHandlersRequest.CloseAllPositions, payload: {id}});

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.CloseAllPositions && message.payload.id === id,
        ),
        take(1),
      ),
    );
  }

  public closeAllOrders(): Promise<IDataEvent<ISingleRequestMessage<boolean>>> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({type: EEventHandlersRequest.CloseAllOrders, payload: {id}});

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<boolean>>) =>
            message.type === EEventHandlersRequest.CloseAllOrders && message.payload.id === id,
        ),
        take(1),
      ),
    );
  }

  /**
   * Create the stream to get rates for set of currencies relates to accountCurrency.
   * The result of this function is stream with Map like { 'USD': 1, 'BTC': 0.0001 }.
   *
   * @param currenciesSet - Initial currencies.
   * @param accountCurrency - Currencies relates to accountCurrency.
   * @returns Stream with Map like { 'USD': 1, 'BTC': 0.0001 }.
   */
  public getCurrenciesMapStream(
    currenciesSet: Set<string>,
    accountCurrency: string,
  ): Observable<TCurrenciesRatesMapType> {
    const ratesMap = new Map() as TCurrenciesRatesMapType;

    if (!currenciesSet.size) {
      return of(ratesMap);
    }

    const ratesStreams = Array.from(currenciesSet).map(currency => {
      if (currency === accountCurrency) {
        return of([currency, 1] as const);
      }

      const symbol = `${currency}/${accountCurrency}`;
      return this.getConversionRates(symbol).pipe(
        distinctUntilChanged(),
        map(rate => [currency, rate] as const),
      );
    });

    return merge(...ratesStreams).pipe(
      map(([currency, rate]) => ratesMap.set(currency, rate)),
      filter(() => ratesMap.size === ratesStreams.length),
    );
  }

  /**
   * Get set of currencies from positions list
   * IMPORTANT: This method uses `symbol2` as currency.
   *
   * @param positionsMap$ - Positions map observable.
   * @param instrumentsMap$ - Initial instruments map observable.
   * @returns Set of currencies observable.
   */
  public getPositionCurrenciesSetStream(
    positionsMap$: Observable<TPositionsMapType>,
    instrumentsMap$: Observable<TB2MarginInstrumentMapType>,
  ): Observable<Set<string>> {
    return combineLatest([positionsMap$, instrumentsMap$]).pipe(
      map(([positionsMap, instrumentsMap]) => {
        const instrumentsIds = Array.from(positionsMap.keys());
        const instruments = B2MarginInstrument.getInstrumentsFromMap(instrumentsIds, instrumentsMap);

        return instruments.reduce((acc, instrument) => {
          const currency = instrument.symbol2;
          return acc.add(currency);
        }, new Set<string>());
      }),
      distinctUntilChanged((a, b) => _.isEqual(a, b)),
    );
  }

  /**
   * Convert positions map to list of GroupedInstrumentsData to display in Portfolio.
   *
   * @param positionsMap$ - Positions map observable.
   * @param instrumentsMap$ - Initial instruments map observable.
   * @param instrumentAccountRatesMap$ - Instruments of account rates map.
   * @returns List of GroupedInstrumentsData observable.
   */
  public getGroupedPositionsDataStream(
    positionsMap$: Observable<TPositionsMapType>,
    instrumentsMap$: Observable<TB2MarginInstrumentMapType>,
    instrumentAccountRatesMap$: Observable<TCurrenciesRatesMapType>,
  ): Observable<IGroupedInstrumentsData[]> {
    return combineLatest([positionsMap$, instrumentsMap$, this.level1$, instrumentAccountRatesMap$]).pipe(
      betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
      filter(([positionsMap, instrumentsMap, level1, ratesMap]) => {
        // Check that information about all position instruments exists
        const instrumentIds = Array.from(positionsMap.keys());
        return !instrumentIds.some(id => {
          const instrument = instrumentsMap.get(id);
          const hasTick = level1.has(id);
          const hasRate = instrument && ratesMap.has(instrument.symbol2);
          return !hasTick || !hasRate;
        });
      }),
      map(([positionsMap, instrumentsMap, level1, ratesMap]) => {
        return Array.from(positionsMap.entries()).map(([instrumentId, instrumentPositions]) => {
          const instrument = instrumentsMap.get(instrumentId);
          const tick = level1.get(instrumentId);
          const instrumentRate = ratesMap.get(instrument.symbol2);

          return calculateInstrumentData(instrumentPositions, instrument, tick, instrumentRate);
        });
      }),
      map(instrumentsData => reduceInstrumentsData(instrumentsData)),
      map(groupedByGroupName => Array.from(groupedByGroupName.entries())),
      map(entries => entries.map(([groupName, items]) => calculateAssetData(groupName, items))),
      map(groups => sortListOfGroupedInstrumentData(groups)),
    );
  }

  public updateQuotesSubscriptions(): void {
    throw new Error('Method not implemented.');
  }

  public ngOnDestroy(): void {
    this.terminate();
    B2MarginDatafeedService.clearMapSubject(this.level2);
  }
}
