export const DEBOUNCE_TIME_IN_MS = 300;

export const SECOND_IN_MS = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTE_IN_MS = SECONDS_IN_MINUTE * SECOND_IN_MS;
export const MINUTES_IN_HOUR = 60;
export const HOUR_IN_MS = MINUTES_IN_HOUR * MINUTE_IN_MS;

export const DATA_FEED_DEBOUNCE_IN_MS = SECOND_IN_MS;

export const DELAY_BEFORE_SYMBOLS_UNSUBSCRIBE = 5 * SECOND_IN_MS;

export const RESEND_CODE_DEFAULT_TIMEOUT_IN_SECOND = 60;

export const ANIMATION_DELAY_FOR_WIZARD_SUCCESS_MESSAGE_IN_MS = 3000;

export const HOURS_IN_DAY = 24;
export const SECONDS_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;

export const MIILLISECONDS_IN_ONE_DAY = HOURS_IN_DAY * HOUR_IN_MS;
