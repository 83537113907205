import {AdminWidgetRules, IGridsterItem, IGridsterWidgetsConfig} from '@app/core/models/gridster';
import {IGridsterBoards} from '@app/core/models/gridster/gridster-boards';
import {IGridsterWidgetGroups} from '@app/core/models/gridster/gridster-widget-groups';
import {ECommonWidgetKeys} from '@app/gridster/widgets/gridster-widgets';
import {ITabsConfig} from '@app/gridster/widgets/tabs/tabs-config';
import {TabsSettings} from '@app/gridster/widgets/tabs/tabs.settings';
import {plainToClass, Type} from 'class-transformer';
import deepmerge from 'deepmerge';
import * as _ from 'lodash-es';

import {GridsterGeneralConfig, IGridsterGeneralConfig} from './gridster-general-config';
import {GridsterLayout, IGridsterLayout} from './gridster-layout';

export interface IGridsterConfig {
  name: string;
  general: IGridsterGeneralConfig;
  layouts: IGridsterLayout[];
  widgets?: IGridsterWidgetsConfig;
  groups?: IGridsterWidgetGroups;
}

export class GridsterConfig implements IGridsterConfig {
  private static readonly QUANTITY_SEGMENTS_WITH_EXTRA_SEGMENT = 4;
  private static readonly EXTRA_SEGMENT_MAP: Record<string, string> = {
    ['[mt4]']: 'Mt4',
    ['[mt5]']: 'Mt5',
  };
  private static readonly SEGMENT_REG_EXP = /\[.*?\]/g;

  private static getWidgetExtraSegment(widgetId: string): string {
    const segments = widgetId.match(GridsterConfig.SEGMENT_REG_EXP);
    const [, , extraSegment] = segments;

    return segments.length === GridsterConfig.QUANTITY_SEGMENTS_WITH_EXTRA_SEGMENT ? extraSegment : '';
  }

  public static mergeWidgetsConfigs(
    envOptions: IGridsterWidgetsConfig,
    widgetsOptions: Record<string, Partial<IGridsterItem>>,
    adminRules: Record<string, AdminWidgetRules>,
  ): IGridsterWidgetsConfig {
    return Object.entries(envOptions).reduce((acc: IGridsterWidgetsConfig, [key, value]: [string, IGridsterItem]) => {
      if (!(value.name in ECommonWidgetKeys)) {
        return acc;
      }
      const widget: IGridsterItem = deepmerge(deepmerge({}, widgetsOptions[value.name]), value.overrides ?? {});

      widget.extraSegment = GridsterConfig.getWidgetExtraSegment(key);

      const tabSettings = widget.settings as TabsSettings;
      if (tabSettings) {
        tabSettings.isFreezAbilityEnabled = value.isFreezAbilityEnabled;
      }

      const extraSegmentValue = widget.extraSegment ? GridsterConfig.EXTRA_SEGMENT_MAP[widget.extraSegment] : '';
      widget.closeDisable = adminRules[`${value.name}${extraSegmentValue}`]?.isClosingDisabled();

      acc[key] = widget;

      return acc;
    }, {});
  }

  /**
   * Generate widgets for dashboard layouts from adminRules.
   *
   * @param layouts - List of layouts.
   * @param widgets - Object with all available widgets.
   * @param adminRules - List of rules.
   * @returns Modified list of layouts (the same reference to original).
   */
  public static updateDashboardLayoutConfigs(
    layouts: IGridsterLayout[],
    widgets: IGridsterWidgetsConfig,
    adminRules: Record<string, AdminWidgetRules>,
  ): IGridsterLayout[] {
    const widgetEntries = Object.entries(widgets ?? {});
    const widgetRules = widgetEntries.reduce((acc, [widgetId, widget]) => {
      const widgetExtraSegment = GridsterConfig.getWidgetExtraSegment(widgetId);
      const extraSegmentValue = widgetExtraSegment ? GridsterConfig.EXTRA_SEGMENT_MAP[widgetExtraSegment] : '';

      const relatedRules = adminRules[`${widget.name}${extraSegmentValue}`];
      if (relatedRules) {
        acc[widgetId] = relatedRules;
      }
      return acc;
    }, {} as Record<string, AdminWidgetRules>);

    layouts.forEach(layout => {
      // Remove all widgets
      layout.widgets = [];
      const maxLayerId = Object.keys(adminRules).length;

      Object.entries(widgetRules).forEach(([widgetId, widgetRule]) => {
        const widget: Partial<IGridsterItem> = {id: widgetId};

        if (
          !widgetRule.isShowByDefault ||
          _.isNil(widgetRule.positionX) ||
          _.isNil(widgetRule.positionY) ||
          _.isNil(widgetRule.width) ||
          _.isNil(widgetRule.height)
        ) {
          return;
        }

        _.set(widget, 'overrides.sizes', {
          x: widgetRule.positionX,
          y: widgetRule.positionY,
          cols: widgetRule.width,
          rows: widgetRule.height,
          layerIndex: maxLayerId - widgetRule.sort,
        });

        layout.widgets.push(widget);
      });
    });

    return layouts;
  }

  public static updateTabWidgetsConfigs(widgetsOptions: IGridsterWidgetsConfig): void {
    Object.values(widgetsOptions).forEach(widget => {
      if (widget.name === ECommonWidgetKeys.tabs && widget.settings) {
        const tabsSettings = widget.settings as ITabsConfig;

        if (tabsSettings?.widgets?.length) {
          tabsSettings.widgets = tabsSettings.widgets
            .filter((w: IGridsterItem) => w.id in widgetsOptions)
            .map((w: IGridsterItem) => {
              return deepmerge<IGridsterItem>(deepmerge(deepmerge({}, widgetsOptions[w.id]), w.overrides ?? {}), {
                id: w.id,
              });
            });

          widget.sizes.minItemRows = 0;
          widget.sizes.minItemCols = 0;

          tabsSettings.widgets.forEach((w: IGridsterItem) => {
            widget.sizes.minItemRows =
              w.sizes.minItemRows > widget.sizes.minItemRows ? w.sizes.minItemRows : widget.sizes.minItemRows;
            widget.sizes.minItemCols =
              w.sizes.minItemCols > widget.sizes.minItemCols ? w.sizes.minItemCols : widget.sizes.minItemCols;
          });

          widget.sizes.rows =
            widget.sizes.rows < widget.sizes.minItemRows ? widget.sizes.minItemRows : widget.sizes.rows;
          widget.sizes.cols =
            widget.sizes.cols < widget.sizes.minItemCols ? widget.sizes.minItemCols : widget.sizes.cols;
        }
      }
    });
  }

  public static getLayouts(layouts: IGridsterLayout[], widgets: IGridsterWidgetsConfig): IGridsterLayout[] {
    return layouts.reduce((acc: IGridsterLayout[], curr: IGridsterLayout) => {
      const filteredWidgets = curr.widgets?.filter((w: IGridsterItem) => {
        if (widgets[w.id]?.sizes?.minItemCols > w.overrides?.sizes?.cols) {
          w.overrides.sizes.cols = widgets[w.id].sizes.minItemCols;
          console.warn(
            `Config for ${w.id} has width less than minimal, so, the minimal width will be used (${w.overrides.sizes.cols})`,
          );
        }

        if (widgets[w.id]?.sizes?.minItemRows > w.overrides?.sizes?.rows) {
          w.overrides.sizes.rows = widgets[w.id].sizes.minItemRows;
          console.warn(
            `Config for ${w.id} has height less than minimal, so, the minimal height will be used (${w.overrides.sizes.rows})`,
          );
        }

        if (widgets[w.id]) {
          return true;
        }

        console.warn(`Config for ${w.id} not declare. Widget removed from layout`);
      });

      const mergedWidgets = filteredWidgets
        ?.map((w: IGridsterItem) =>
          deepmerge<IGridsterItem>(deepmerge(deepmerge({}, widgets[w.id]), w.overrides ?? {}), {
            id: w.id,
          }),
        )
        .map(widget => {
          if (widget.name === ECommonWidgetKeys.tabs) {
            const settings = widget.settings as ITabsConfig;
            const oldWidgetsList = settings.widgets;

            if (settings.widgets.length) {
              settings.widgets = [];

              oldWidgetsList.forEach((w: IGridsterItem) => {
                const updatedWidget = deepmerge<IGridsterItem>(
                  deepmerge(deepmerge({}, widgets[w.id]), w.overrides ?? {}),
                  {
                    id: w.id,
                  },
                );
                settings.widgets = settings.widgets.filter(i => i.name !== updatedWidget.name);

                settings.widgets.push(updatedWidget);
              });
            }
          }

          return widget;
        });

      acc.push({id: curr.id, nameSpace: curr.nameSpace, isRequired: curr.isRequired, widgets: mergedWidgets ?? []});

      return acc;
    }, []);
  }

  public static serializeConfig(config: IGridsterConfig): GridsterConfig {
    return plainToClass(GridsterConfig, config);
  }

  public static updateSettingsInWidgets(
    gridsterName: string,
    layouts: IGridsterLayout[],
    defaultWidgets: IGridsterWidgetsConfig,
    adminRules: Record<string, AdminWidgetRules>,
  ): IGridsterLayout[] {
    return layouts.map((l: IGridsterLayout) => {
      l.widgets = l.widgets.filter((widget: Partial<IGridsterItem>) => {
        if (widget.name === ECommonWidgetKeys.tabs) {
          (widget.settings as TabsSettings).widgets = (widget.settings as TabsSettings).widgets.filter(
            (w: IGridsterItem) => w.name in ECommonWidgetKeys,
          );
        }
        const widgetId = `[${gridsterName}][${widget.name}]${widget.extraSegment ?? ''}[${widget.provider}]`;

        if (defaultWidgets[widgetId]) {
          return true;
        }

        console.warn(`Config ${widgetId} not found and widget was removed`);
        return false;
      });

      l.widgets = l.widgets.map((widget: Partial<IGridsterItem>) => {
        if (!widget.settings) {
          const widgetId = `[${gridsterName}][${widget.name}]${widget.extraSegment ?? ''}[${widget.provider}]`;

          widget.settings = defaultWidgets[widgetId].settings;
        }

        const widgetNameWithExtraSegment = `${widget.name}${
          widget.extraSegment ? GridsterConfig.EXTRA_SEGMENT_MAP[widget.extraSegment] : ''
        }`;
        widget.closeDisable = adminRules[widgetNameWithExtraSegment]?.isClosingDisabled();

        widget.sort = adminRules[widget.name]?.sort;

        return widget;
      });

      return l;
    });
  }

  public name: string;
  @Type(() => GridsterGeneralConfig)
  public general: GridsterGeneralConfig;
  @Type(() => GridsterLayout)
  public layouts: GridsterLayout[];
  public widgets?: IGridsterWidgetsConfig;
  public groups?: IGridsterWidgetGroups;
}

export interface IDashboards {
  boards: IGridsterBoards;
  publicBoards?: IGridsterBoards;
}
