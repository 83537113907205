import {HistoryTO} from '@b2broker/b2margin-trade-models';
import {plainToInstance} from 'class-transformer';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {OrderHistory} from '../../../models/b2margin/order-history';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';
import {EEventHandlersReceived} from '../../event-handlers.enum';

export class OrderHistoryInternalEventHandler extends AEventHandler<IB2marginMessage<HistoryTO[]>> {
  public type = EB2MarginEventType.History;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<HistoryTO[]>): void {
    this.store.messages$.next({
      type: EEventHandlersReceived.OrderHistory,
      payload: plainToInstance(OrderHistory, resource.body),
    });
  }
}

b2MarginInternalEventHandlers.push(OrderHistoryInternalEventHandler);
