import {Injectable} from '@angular/core';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';

import {AEventHandler} from '../../event-handlers/event-handler.abstract';
import {EMoexEventHandlersReceived} from './moex-event-handlers.enum';

@Injectable()
export class MoexCancelStopOrderService extends AEventHandler<string> {
  public readonly type = EMoexEventHandlersReceived.CancelStopOrder;

  constructor(private readonly moexDatafeedService: MoexDatafeedService) {
    super();
  }

  public handleMessage(response: string): void {
    this.moexDatafeedService.cancelStopOrder$.next(response);
  }
}
