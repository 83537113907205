import {Injectable} from '@angular/core';
import {MINUTE_IN_MS} from '@app/core/constants/common';
import {bufferFloatingWindow} from '@app/core/utils/buffer-floating-window';
import {merge, Observable, Subject} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

import {EActivityEvent} from '../../enums/activity-event';
import {ActivityTrackerService} from '../activity-tracker.service';
import {ABaseActivityHandlerService} from './base-activity-handler.service';

/**
 * Handler for gridster activity events.
 * This service help to detect excessive activity.
 */
@Injectable()
export class GridsterActivityHandlerService extends ABaseActivityHandlerService {
  protected static readonly TOO_MANY_MOVES_WINDOW = MINUTE_IN_MS;
  protected static readonly TOO_MANY_MOVES_MAX_COUNT = 40;
  protected static readonly TOO_MANY_ADDS_WINDOW = MINUTE_IN_MS;
  protected static readonly TOO_MANY_ADDS_MAX_COUNT = 20;

  /**
   * Stream with events, emitted when the user is too active.
   */
  public excessiveActivity$: Observable<void>;

  constructor(activityTrackerService: ActivityTrackerService) {
    super(activityTrackerService);
  }

  protected initializeHandler(events$: Observable<EActivityEvent>): void {
    const reset$ = new Subject<void>();

    const tooManyMoves$ = events$.pipe(
      filter(ev => ev === EActivityEvent.GridsterWidgetMove || ev === EActivityEvent.GridsterWidgetResize),
      bufferFloatingWindow(GridsterActivityHandlerService.TOO_MANY_MOVES_WINDOW, reset$),
      filter(events => events.length > GridsterActivityHandlerService.TOO_MANY_MOVES_MAX_COUNT),
    );

    const tooManyAdds$ = events$.pipe(
      filter(ev => ev === EActivityEvent.GridsterWidgetAdded),
      bufferFloatingWindow(GridsterActivityHandlerService.TOO_MANY_ADDS_WINDOW, reset$),
      filter(events => events.length > GridsterActivityHandlerService.TOO_MANY_ADDS_MAX_COUNT),
    );

    this.excessiveActivity$ = merge(tooManyMoves$, tooManyAdds$).pipe(
      map(() => undefined),
      tap(() => reset$.next()),
    ) as Observable<void>;
  }
}
