import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {MarginImpact} from '@app/trading-board/models/b2margin/margin-impact';

@Injectable()
export class MarginImpactService extends AEventHandler<MarginImpact[]> {
  public type = EEventHandlersReceived.MarginImpact;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: MarginImpact[]): void {
    resource.forEach(item => {
      const fn = MarginImpact.getMarginImpactFn(item);

      if (!fn) {
        return;
      }

      this.b2marginDatafeedService.marginImpact.get(item.instrumentSymbol)?.stream.next(fn);
    });
  }
}
