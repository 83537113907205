import {Direction} from '@angular/cdk/bidi';
import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {DirectionService} from '@app/shared/modules/direction/services/direction.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appIfDir]',
})
export class IfDirDirective<T> implements OnInit, OnDestroy {
  @Input()
  public appIfDir: Direction;

  public sub = Subscription.EMPTY;

  constructor(
    private readonly templateRef: TemplateRef<T>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly directionService: DirectionService,
  ) {}

  public ngOnInit(): void {
    this.sub = this.directionService.dir.subscribe(dir => this.check(dir));
  }

  public check(dir: Direction): void {
    if (dir === this.appIfDir) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
