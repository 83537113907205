<div *ngIf="urls" class="file-list" [class.centered-content]="isCenteredContent">
  <div
    *ngFor="let url of urls; let i = index; trackBy: trackByIndex"
    class="file-item"
    [class.invalid]="errors && errors[i]"
  >
    <div class="file-inner">
      <div class="file-preview">
        <img *ngIf="files[i].type.includes('image'); else videoIcon" [src]="url" [alt]="files[i].name" />

        <ng-template #videoIcon>
          <ng-container *ngIf="files[i].type.includes('video'); else defaultIcon">
            <app-icon icon="play-button"></app-icon>
          </ng-container>
        </ng-template>

        <ng-template #defaultIcon>
          <app-icon icon="file-added"></app-icon>
        </ng-template>
      </div>

      <div class="file-info">
        <button [title]="'remove' | translate" (click)="rmFile(i)" type="button">
          <app-icon icon="close-bold"></app-icon>
        </button>
        <span [title]="files[i].name">{{ files[i].name }}</span>
      </div>
    </div>

    <ng-container *ngIf="errors && errors[i]">
      <div *ngFor="let err of errors[i] | keys | slice: 0:1" class="file-error">
        {{ getError(err, errors[i][err]) | async }}
      </div>
    </ng-container>
  </div>
</div>
