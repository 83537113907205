<ng-container
  [ngTemplateOutlet]="formGroupTemplate || defaultTemplate"
  [ngTemplateOutletContext]="{form: form, fields: fields}"
></ng-container>

<ng-template [formGroup]="form" #defaultTemplate>
  <ng-container *ngFor="let key of fields | keys">
    <ng-container *ngIf="fields[key].fields">
      <ng-container *ngIf="fields[key].type === 'file' || fields[key].type === 'fileButton'; else group">
        <app-form-file
          *ngIf="fields[key].type === 'file'; else fileButton"
          [group]="form.controls[key]"
          [field]="fields[key]"
        ></app-form-file>
        <ng-template #fileButton>
          <app-file-button [group]="form.controls[key]" [field]="fields[key]"></app-file-button>
        </ng-template>
      </ng-container>
      <ng-template #group>
        <app-form-group
          [form]="form.controls[key]"
          [fields]="fields[key].fields"
          [isFocusFirstInput]="isFocusFirstInput"
        ></app-form-group>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!fields[key].fields && isSimpleFieldVisible(key)" [ngSwitch]="fields[key].type">
      <ng-container *ngSwitchCase="formFieldType.file">
        <app-multiple-files
          [field]="fields[key]"
          [filesToMap]="form.controls[key].value"
          (formArrayChanged)="onFormArrayChange(key, $event)"
        >
          <ng-content message select="[multipleFilesMessage]"></ng-content>
        </app-multiple-files>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <app-render
          *ngIf="!fields[key].hidden"
          [formGroup]="form"
          [field]="fields[key]"
          [control]="form.controls[key]"
          [autocompleteData]="autocompleteData"
          [renderValue]="renderValue"
          [autocompletedFieldsNames]="autocompletedFieldsNames"
          [customMask]="getMask(key)"
          [tooltipLayouts]="tooltipLayouts"
          [focusField]="getFirstField()"
          [fieldsWithTooltip]="fieldsWithTooltip"
        ></app-render>
      </ng-container>
    </ng-container>
  </ng-container>

  <mat-error class="form-group--error" *ngFor="let err of form.errors | keys | slice: 0:1">
    {{ getError(err) | async }}
  </mat-error>
</ng-template>
