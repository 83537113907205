import {SECOND_IN_MS} from '@app/core/constants/common';
import {IB2traderIdentityTokenResponseDto} from '@app/trading-board/b2trader-auth/interfaces/b2trader-identity-token-response.dto';

import {IB2TraderApiData} from '../interfaces/b2trader-api-data';

export function mapIdentityTokenResponse(res: IB2traderIdentityTokenResponseDto, apiUrl: string): IB2TraderApiData {
  return {
    url: apiUrl,
    expirationTimestamp: Number(res.expires_in) * SECOND_IN_MS + Date.now(),
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `${res.token_type} ${res.access_token}`,
    },
    refreshToken: res.refresh_token,
    accessToken: res.access_token,
  };
}
