import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from '@app/core/services/auth.service';
import {LogoutService} from '@app/core/services/logout.service';
import {Public} from '@app/shared/decorators/public.decorator';
import {Environment} from '@env/environment.entities';
import * as _ from 'lodash-es';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class B2copyStandaloneSideLoginGuard {
  constructor(
    private readonly environment: Environment,
    private readonly logoutService: LogoutService,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  @Public(Promise.resolve(false))
  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Promise<boolean> | Observable<UrlTree> {
    if (_.isNil(this.environment.investmentConfig?.standaloneVersion)) {
      return true;
    }

    const tokenRegexp = /standalone-side-login\/([a-fA-F0-9]{32,64})(\/)?$/;

    if (!tokenRegexp.test(state.url)) {
      return true;
    }

    if (!this.authService.isLoggedIn()) {
      return true;
    }

    // logout before redirection to token auth
    return from(this.logoutService.logout({isDoNotNavigateToLoginAfterLogout: true})).pipe(
      map(() => this.router.createUrlTree([state.url])),
    );
  }
}
