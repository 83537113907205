import {Injectable} from '@angular/core';
import {UpdateLanguageState} from '@app/language/language.actions';
import {ResetService} from '@app/state/plugins/logout/reset.service';
import {Action, Selector, State, StateContext} from '@ngxs/store';

import {Language} from './models/language';

export class LanguageStateModel {
  public currentLanguage: string;
  public allLanguages: Language[];
}

@State<LanguageStateModel>({
  name: 'language',
  defaults: {
    currentLanguage: '',
    allLanguages: [],
  },
})
@Injectable()
export class LanguageState {
  @Selector()
  public static getAllLanguages({allLanguages}: LanguageStateModel): Language[] {
    return allLanguages;
  }

  @Selector()
  public static getCurrentLanguage({currentLanguage}: LanguageStateModel): string {
    return currentLanguage;
  }

  constructor(private readonly resetService: ResetService) {}

  @Action(UpdateLanguageState)
  public updateLanguageState(
    {setState}: StateContext<LanguageStateModel>,
    {payload: languageState}: UpdateLanguageState,
  ): void {
    this.resetService.updateLanguage(languageState);
    setState(languageState);
  }
}
