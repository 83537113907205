export enum EProductGroupType {
  Default = 0,
  Payment = 1,
  Investment = 2,
  Master = 3,
  B2CopyFollower = 4,
  B2CopyMaster = 5,
  PammFollower = 6,
  PammMaster = 7,
  MamFollower = 8,
  MamMaster = 9,
}
