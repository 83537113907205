import {Injectable} from '@angular/core';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {B2TraderOrder} from '@app/trading-board/models/b2trader/b2-trader-order';

@Injectable()
export class B2traderOrdersService extends AEventHandler<B2TraderOrder[]> {
  public type = EB2TraderEventHandlersReceived.Orders;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: B2TraderOrder[] | null): void {
    if (resource === null) {
      this.b2traderDatafeedService.orders$.next(new Map());
      return;
    }

    this.b2traderDatafeedService.orders$.next(
      resource.reduce((acc, item) => acc.set(item.orderId, item), this.b2traderDatafeedService.orders$.getValue()),
    );
  }
}
