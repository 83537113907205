import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from '@app/app.module';
import {Environment} from '@env/environment.entities';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash-es';

import {overrideNumberLocale} from '../utils/override-number-locale/override-number-locale';

void bootstrapApp();

async function bootstrapApp(): Promise<void> {
  try {
    overrideNumberLocale();

    const environment: Partial<Environment> = await fetch(
      `assets/environments/environment.json?cacheOff=${Date.now()}`,
    ).then(res => res.json());

    const overrides: Partial<Environment> = await fetch(`/assets/config/overrides.json?cacheOff=${Date.now()}`).then(
      res => res.json(),
      err => console.error('Overrides config not loaded', err),
    );

    if (!_.isNil(overrides)) {
      for (const prop in overrides) {
        if ((prop === 'apiUrl' || prop === 'weblateApiUrl') && !overrides[prop]) {
          continue;
        }

        environment[prop] = overrides[prop];
      }
    }

    platformBrowserDynamic([{provide: Environment, useValue: plainToClass(Environment, environment)}])
      .bootstrapModule(AppModule)
      .catch((err: unknown) => console.error(err));
  } catch (err) {
    console.error('Environment config not loaded', err);
  }
}
