import {Injectable} from '@angular/core';
import {User} from '@app/core/models/user/user';
import {BehaviorSubject} from 'rxjs';

/**
 * Service to store information about current user.
 */
@Injectable({providedIn: 'root'})
export class CurrentUserService {
  private readonly currentUserValue$ = new BehaviorSubject<User | null>(null);
  public readonly currentUser$ = this.currentUserValue$.asObservable();

  public setUser(user: User): void {
    this.currentUserValue$.next(user);
  }

  public clear(): void {
    this.currentUserValue$.next(null);
  }
}
