import {WarpInstrumentsStoreService} from '@app/pbsr/services/warp-instruments-store/warp-instruments-store.service';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {IBarsHistoryRequest} from '@app/trading-board/interfaces/moex/bars-history-request.interface';
import {IChartHistoryWithTimeLinksResponse} from '@app/trading-board/interfaces/moex/chart-history-with-time-links-response.interface';
import {
  HistoryCallback,
  IBasicDataFeed,
  LibrarySymbolInfo,
  ResolutionString,
  ErrorCallback,
  ResolveCallback,
  SearchSymbolsCallback,
  OnReadyCallback,
  SubscribeBarsCallback,
  DatafeedConfiguration,
  Bar,
} from '@b2broker/trading.view.charting.library/charting_library/charting_library';
import {PeriodParams} from '@b2broker/trading.view.charting.library/charting_library/datafeed-api';
import _ from 'lodash-es';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

export class WarpChartDatafeed implements IBasicDataFeed {
  private readonly lastBarTimes = new Map<string, number>();
  private readonly listenerGuidToSymbolSubscription = new Map<string, Subscription>();

  private static readonly DEFAULT_SUPPORTED_RESOLUTIONS = [
    '1',
    '5',
    '60',
    '1D',
    '1W',
    '1M',
    '12M',
  ] as ResolutionString[];

  constructor(
    protected readonly moexDatafeedService: MoexDatafeedService,
    protected readonly configuration: DatafeedConfiguration,
    private readonly warpInstrumentsStoreService: WarpInstrumentsStoreService,
  ) {}

  public onReady(callback: OnReadyCallback): void {
    setTimeout(() => callback(this.configuration));
  }

  public resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void {
    this.warpInstrumentsStoreService.getInstrument(symbolName).subscribe(instrument => {
      if (!instrument) {
        onError('Instrument not found');
        return;
      }

      const librarySymbolInfo: LibrarySymbolInfo = {
        name: instrument.symbol,
        ticker: symbolName,
        description: instrument.description,
        exchange: instrument.exchange,
        minmov: 1,
        session: '24x7',
        type: instrument.type ?? '',
        pricescale: Math.pow(10, instrument.priceScale),
        format: 'price',
        timezone: 'Europe/Moscow',
        /* eslint-disable @typescript-eslint/naming-convention */
        full_name: instrument.symbolWithSeparator,
        has_empty_bars: false,
        has_intraday: true,
        currency_code: instrument.currency,
        listed_exchange: instrument.exchange,
        has_daily: true,
        has_weekly_and_monthly: true,
        data_status: 'streaming',
        supported_resolutions:
          this.configuration.supported_resolutions || WarpChartDatafeed.DEFAULT_SUPPORTED_RESOLUTIONS,
        /* eslint-enable @typescript-eslint/naming-convention */
      };

      onResolve(librarySymbolInfo);
    });
  }

  public getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
    onError: ErrorCallback,
  ): void {
    const lastBarKey = this.getLastBarWithResolution(symbolInfo.ticker, resolution);
    if (periodParams.firstDataRequest) {
      this.lastBarTimes.delete(lastBarKey);
    }

    const request: IBarsHistoryRequest = {
      symbol: symbolInfo.ticker,
      rangeStartDate: periodParams.from,
      rangeEndDate: periodParams.to,
      resolution,
      isFirstCall: periodParams.firstDataRequest,
    };

    this.moexDatafeedService
      .getBarsData$(request)
      .pipe(take(1))
      .subscribe((response: IChartHistoryWithTimeLinksResponse) => {
        if (!response) {
          onError('History is empty');
          return;
        }

        const historyBars = response.history;
        const isHistoryEmpty = historyBars.length === 0;
        const isNoData = isHistoryEmpty && response.prev === null;

        if (periodParams.firstDataRequest) {
          this.lastBarTimes.set(lastBarKey, isHistoryEmpty ? this.getUtcLastBarDefault() : _.last(historyBars).time);
        }

        const nextTime = periodParams.firstDataRequest ? response.next : response.prev;
        onResult(
          historyBars.map(bar => this.getBarWithUpdatedTime(bar)),
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            noData: isNoData,
            nextTime: isHistoryEmpty ? nextTime : undefined,
          },
        );
      });
  }

  public subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
  ): void {
    const lastBarTime = this.lastBarTimes.get(this.getLastBarWithResolution(symbolInfo.ticker, resolution)) * 1000;
    const from = lastBarTime ?? this.getUtcLastBarDefault();

    const barsSubscription = this.moexDatafeedService.getBar$(symbolInfo.full_name, resolution, from).subscribe(bar => {
      const lastBarPointKey = this.getLastBarWithResolution(symbolInfo.ticker, resolution);
      const lastBarPoint = this.lastBarTimes.get(lastBarPointKey);
      if (!lastBarPoint || bar.time < lastBarPoint) {
        return;
      }

      this.lastBarTimes.set(lastBarPointKey, bar.time);
      onTick(this.getBarWithUpdatedTime(bar));
    });

    this.listenerGuidToSymbolSubscription.set(listenerGuid, barsSubscription);
  }

  public unsubscribeBars(listenerGuid: string): void {
    const subscription = this.listenerGuidToSymbolSubscription.get(listenerGuid);
    subscription?.unsubscribe();
    this.listenerGuidToSymbolSubscription.delete(listenerGuid);

    this.lastBarTimes.clear();
  }

  private getBarWithUpdatedTime(bar: Bar): Bar {
    return {
      ...bar,
      time: bar.time * 1000,
    };
  }

  private getLastBarWithResolution(symbol: string, resolution: ResolutionString): string {
    return `${symbol}_${resolution}`;
  }

  private getUtcLastBarDefault(): number {
    const now = new Date();

    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())).getTime() / 1000;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
  public searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback,
  ): void {}
  /* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
}
