import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {IPopupMessageDialogData} from '@app/trading-board/components/dialog.popup-message/popup-message-dialog-data.interface';
import {Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';

@Injectable()
export class B2marginPopupQueueService implements OnDestroy {
  private readonly queue: IPopupMessageDialogData[] = [];
  private readonly queue$ = new Subject<IPopupMessageDialogData>();
  private readonly destroyer$ = new Subject<void>();

  constructor(private readonly dialog: MatDialog) {
    this.queue$.subscribe(data => {
      void import('../components/dialog.popup-message/popup-message-dialog.component').then(m => {
        this.dialog
          .open(m.PopupMessageDialogComponent, {data})
          .afterClosed()
          .pipe(
            takeUntil(this.destroyer$),
            tap(() => this.queue.shift()),
            filter(() => !!this.queue.length),
          )
          .subscribe(() => this.queue$.next(this.queue[0]));
      });
    });
  }

  public next(data: IPopupMessageDialogData): void {
    this.queue.push(data);

    if (this.queue.length > 1) {
      return;
    }

    this.queue$.next(data);
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
  }
}
