/* eslint-disable @typescript-eslint/naming-convention */
import {environment} from '@env/environment';
import {NgxsModuleOptions} from '@ngxs/store';

export const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  compatibility: {
    strictContentSecurityPolicy: true,
  },
  selectorOptions: {
    suppressErrors: false,
    injectContainerState: false,
  },
};
