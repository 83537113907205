import {Injectable} from '@angular/core';
import {EComplexProductCategoryBlockReason} from '@app/pbsr/enums/сomplex-product-category-block-reason.enum';
import {IQuizzesProductCategory} from '@app/pbsr/interfaces/quizzes-product-category.interface';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {Observable, combineLatest} from 'rxjs';
import {tap} from 'rxjs/operators';

import {ComplexProductCategoriesApiService} from '../complex-product-categories-api.service';
import {QuizzesProductCategoryApiService} from '../quizzes-product-category/quizzes-product-category-api.service';

@Injectable({providedIn: 'root'})
export class ComplexProductCategoryCheckerService {
  private readonly unknownComplexProductCategory = 'Unknown';
  private readonly qualifiedInvestorComplexProductCategories = new Set(['0', '16', '17']);

  private readonly complexProductCategories = new Set<string>();
  private readonly complexProductCategoryToQuizId = new Map<string, number>();

  constructor(
    private readonly complexProductCategoriesApiService: ComplexProductCategoriesApiService,
    private readonly quizzesProductCategoryApiService: QuizzesProductCategoryApiService,
  ) {}

  public init(): Observable<[string[], IQuizzesProductCategory[]]> {
    return combineLatest([
      this.complexProductCategoriesApiService.getComplexProductCategories(),
      this.quizzesProductCategoryApiService.getQuizzesProductCategory(),
    ]).pipe(
      tap(([complexProductCategories, quizzesProductCategory]) => {
        complexProductCategories.forEach(item => this.complexProductCategories.add(item));
        quizzesProductCategory.forEach(item =>
          this.complexProductCategoryToQuizId.set(item.complexProductCategory, item.testId),
        );
      }),
    );
  }

  public checkInstrumentAvailability(instrument: WarpInstrument): EComplexProductCategoryBlockReason | undefined {
    const complexProductCategory = instrument.complexProductCategory;

    if (complexProductCategory === this.unknownComplexProductCategory) {
      return EComplexProductCategoryBlockReason.MustBeQualifiedInvestor;
    }

    const isComplexProductCategoryPass =
      complexProductCategory === '' || this.complexProductCategories.has(complexProductCategory);

    if (isComplexProductCategoryPass) {
      return undefined;
    }

    if (this.qualifiedInvestorComplexProductCategories.has(complexProductCategory)) {
      return EComplexProductCategoryBlockReason.MustBeQualifiedInvestor;
    }

    return EComplexProductCategoryBlockReason.MustPassTest;
  }

  public getQuizId(complexProductCategory: string): number {
    return this.complexProductCategoryToQuizId.get(complexProductCategory);
  }

  public clear(): void {
    this.complexProductCategories.clear();
    this.complexProductCategoryToQuizId.clear();
  }
}
