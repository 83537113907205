import {Token} from '@app/core/models/auth/token';
import {TokenResponse} from '@app/core/models/auth/token-response';
import {Public} from '@app/shared/decorators/public.decorator';
import {ESharedEventHandlers} from '@app/trading-board/event-handlers/shared/shared-event-handlers.enum';
import {IApiOptions} from '@app/trading-board/interfaces/connection-params';
import {plainToInstance} from 'class-transformer';
import {BehaviorSubject, interval, Observable, of, Subject} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {filter, first, map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';

import {IDataEvent} from '../interfaces/data-event.ts';

export abstract class ADatafeedFacade {
  public abstract messages$: Subject<IDataEvent>;

  protected abstract connect(params: IApiOptions): Promise<void>;

  public abstract close(): void;

  public abstract postMessage(message: IDataEvent): void;

  private readonly pingIntervalInMs = 60000;
  private readonly refreshTokens$ = new BehaviorSubject<string | null>(null);

  private tokenResponse: TokenResponse;
  private refreshTokenRequest$: Observable<string> | null = null;

  protected baseUrl: string;
  protected apiUrl: string;

  protected readonly destroyer$ = new Subject<void>();

  @Public(of(undefined))
  private refreshTokens(): Observable<string> {
    if (this.refreshTokenRequest$) {
      return this.refreshTokenRequest$;
    }

    this.refreshTokenRequest$ = of(undefined).pipe(
      switchMap(() => {
        this.refreshTokens$.next(null);

        this.messages$
          .pipe(
            filter(message => message.type === ESharedEventHandlers.EndRefreshTokens),
            first(),
            takeUntil(this.destroyer$),
          )
          .subscribe((message: IDataEvent<TokenResponse | null>) => {
            this.tokenResponse = message.payload;
            this.refreshTokens$.next(this.tokenResponse?.token ?? null);
            this.refreshTokenRequest$ = null;
          });

        this.messages$.next({type: ESharedEventHandlers.StartRefreshTokens});

        return this.refreshTokens$.pipe(
          filter<string>(token => token !== null),
          first(),
        );
      }),
      shareReplay({refCount: true, bufferSize: 1}),
    );

    return this.refreshTokenRequest$;
  }

  protected getAuthHeaders(): Observable<HeadersInit> {
    const headers: HeadersInit = {};

    return of(undefined).pipe(
      switchMap(() => {
        const tokenData = this.tokenResponse ? plainToInstance(Token, this.tokenResponse).data : null;
        return !tokenData || new Date() >= tokenData.expiresTime ? this.refreshTokens() : of(tokenData.token);
      }),
      map(token => {
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }

        return headers;
      }),
    );
  }

  protected get<T>(url: string, options?: {params?: {[param: string]: string}; headers?: HeadersInit}): Observable<T> {
    return this.getAuthHeaders().pipe(
      switchMap(authHeaders =>
        fromFetch<T>(`${this.apiUrl}/${url}?${new URLSearchParams(options?.params).toString()}`, {
          selector: res => res.json().catch(() => undefined),
          method: 'GET',
          credentials: 'include',
          headers: {...authHeaders, ...(options?.headers ? options.headers : {})},
        }),
      ),
    );
  }

  protected post<T>(url: string, body, options?: {headers?: HeadersInit}): Observable<T> {
    return this.getAuthHeaders().pipe(
      switchMap(authHeaders =>
        fromFetch<T>(`${this.apiUrl}/${url}`, {
          selector: res => res.json().catch(() => undefined),
          method: 'POST',
          credentials: 'include',
          headers: {...authHeaders, ...(options?.headers ? options.headers : {})},
          body: JSON.stringify(body),
        }),
      ),
    );
  }

  protected put<T>(url: string, body, options?: {headers?: HeadersInit}): Observable<T> {
    return this.getAuthHeaders().pipe(
      switchMap(authHeaders =>
        fromFetch<T>(`${this.apiUrl}/${url}`, {
          selector: res => res.json().catch(() => undefined),
          method: 'PUT',
          credentials: 'include',
          headers: {...authHeaders, ...(options?.headers ? options.headers : {})},
          body: JSON.stringify(body),
        }),
      ),
    );
  }

  protected delete<T>(
    url: string,
    options?: {params?: {[param: string]: string}; headers?: HeadersInit},
  ): Observable<T> {
    return this.getAuthHeaders().pipe(
      switchMap(authHeaders =>
        fromFetch<T>(`${this.apiUrl}/${url}?${new URLSearchParams(options?.params).toString()}`, {
          selector: res => res.json().catch(() => undefined),
          method: 'DELETE',
          credentials: 'include',
          headers: {...authHeaders, ...(options?.headers ? options.headers : {})},
        }),
      ),
    );
  }

  protected startPing(): void {
    interval(this.pingIntervalInMs)
      .pipe(
        switchMap(() => this.getAuthHeaders()),
        switchMap(headers =>
          fromFetch(`${this.baseUrl}/api/v1/me`, {
            credentials: 'include',
            method: 'GET',
            headers,
          }),
        ),
        takeUntil(this.destroyer$),
      )
      .subscribe(async response => {
        if (response.status >= 200 && response.status < 400) {
          const innerResponse = await response.json();

          if (innerResponse.status === 200) {
            return;
          }
        }

        this.messages$.next({type: ESharedEventHandlers.Unauthorized});
      });
  }
}
