import {Injectable} from '@angular/core';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {TradeFees} from '@app/trading-board/models/b2trader/trade-fees';

@Injectable()
export class B2traderTiersService extends AEventHandler<TradeFees> {
  public type = EB2TraderEventHandlersReceived.Tiers;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: TradeFees): void {
    const map = this.b2traderDatafeedService.tiers$.getValue();
    Object.entries(resource.marketsFee).forEach(([instrument, tier]) => {
      map.set(instrument, tier);
    });

    this.b2traderDatafeedService.tiers$.next(map);
  }
}
