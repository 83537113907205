import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {EActivityEvent} from '../../enums/activity-event';
import {ActivityTrackerService} from '../activity-tracker.service';

/**
 * Base class for each activity handler.
 *
 * To realize the Activity handler you need to implement `initializeHandler` method, that receives
 * stream with events. Main logic of handling should starts in this method.
 */
@Injectable()
export abstract class ABaseActivityHandlerService {
  protected abstract initializeHandler(events$: Observable<EActivityEvent>): void;

  protected constructor(protected readonly activityTrackerService: ActivityTrackerService) {
    this.initializeHandler(this.activityTrackerService.events$);
  }
}
