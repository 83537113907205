import {Casts, CastTypes, Model} from '@app/core/model';
import {preciseDecimal, TPreciseFlag} from '@app/shared/utils/precision';
import {Decimal as DecimalJS} from 'decimal.js';

export class Decimal extends Model {
  constructor(value?: string | number) {
    super();
    this.decimalJs = new DecimalJS(value || '0');
  }

  public get value(): string {
    return this.decimalJs?.toString() || '0';
  }

  public set value(value: string) {
    this.decimalJs = new DecimalJS(value || 0);
  }

  public setValue(value: string | number): Decimal {
    this.decimalJs = new DecimalJS(value || 0);
    return this;
  }

  private decimalJs: DecimalJS;

  public scale: number;

  public getObj(): DecimalJS {
    return this.decimalJs;
  }

  public getValue(): string {
    return this.scale ? this.getFixed(this.scale) : this.value;
  }

  public getFixed(scale?: number, preciseFlag?: TPreciseFlag): string {
    return preciseDecimal(this.decimalJs, scale, preciseFlag);
  }

  public plus(addend: Decimal): Decimal {
    const result = new Decimal();
    result.scale = Math.min(this.scale, addend.scale);
    result.decimalJs = this.decimalJs.plus(addend.decimalJs);
    return result;
  }

  public minus(subtrahend: Decimal): Decimal {
    const result = new Decimal();
    result.scale = Math.min(this.scale, subtrahend.scale);
    result.decimalJs = this.decimalJs.minus(subtrahend.decimalJs);
    return result;
  }

  public minusString(subtrahend: string): Decimal {
    const result = new Decimal();
    result.decimalJs = this.decimalJs.minus(subtrahend);
    return result;
  }

  public multiplyByDec(multiplier: Decimal): Decimal {
    const result = new Decimal();
    result.scale = Math.min(this.scale, multiplier.scale);
    result.decimalJs = this.decimalJs.mul(multiplier.decimalJs);
    return result;
  }

  public multiplyByNum(multiplier: string | number): Decimal {
    const result = new Decimal();
    result.scale = this.scale;
    result.decimalJs = this.decimalJs.mul(multiplier || 0);
    return result;
  }

  public divideByDec(divider: Decimal): Decimal {
    const result = new Decimal();
    result.scale = Math.min(this.scale, divider.scale);
    result.decimalJs = this.decimalJs.div(divider.decimalJs);
    return result;
  }

  public isPositive(): boolean {
    return this.decimalJs.isPositive();
  }

  public isNegative(): boolean {
    return this.decimalJs.isNegative();
  }

  public isZero(): boolean {
    return this.decimalJs.isZero();
  }

  public readonly casts: Casts = {
    value: [CastTypes.String],
    scale: [CastTypes.Number],
  };

  public static from(value: string, scale?: number): Decimal {
    const dec = new Decimal(value);

    if (scale) {
      dec.scale = scale;
    }

    return dec;
  }
}
