import {Injectable} from '@angular/core';
import {RudderAnalytics} from '@rudderstack/analytics-js';

import {AAnalyticsService} from '../shared/analytics-service.abstract';
import {EUserProperties} from '../shared/enums/user-properties.enum';
import {IRudderStackConfig} from '../shared/interfaces/rudder-stack-config.interface';
import {TEvent} from '../shared/types/event.type';
import {RudderStackEventLogger} from './event-logger/rudder-stack-event-logger';

@Injectable({providedIn: 'root'})
export class RudderStackService extends AAnalyticsService<IRudderStackConfig> {
  private readonly userProperties = {
    [EUserProperties.AppVersion]: this.appVersion,
    [EUserProperties.AppSource]: 'v2',
  };

  private rudderAnalytics: RudderAnalytics | null = null;

  protected eventLogger: RudderStackEventLogger | null = null;

  public initialize(config: IRudderStackConfig, email: string): void {
    if (this.isProd || this.isTestMode) {
      this.rudderAnalytics = new RudderAnalytics();

      const encryptedEmail = this.getEncryptedEmail(email);

      this.rudderAnalytics.identify(encryptedEmail, this.userProperties);
      this.rudderAnalytics.load(config.writeKey, config.dataplaneUrl, {});

      this.eventLogger = new RudderStackEventLogger(this.rudderAnalytics);
    }
  }

  public logEvent(event: TEvent): void {
    if (this.rudderAnalytics && this.eventLogger) {
      this.eventLogger.handleEvent(event);
    }
  }
}
