import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {ETradingBoardProviderAlias} from '@app/trading-board/enum/provider-alias';
import {Environment} from '@env/environment.entities';

@Injectable()
export class B2traderStandaloneGuard implements CanActivate {
  constructor(private readonly environment: Environment, private readonly router: Router) {}

  public canActivate(): boolean | UrlTree {
    if (!this.environment.isB2TraderStandalone) {
      return true;
    }

    return this.router.createUrlTree([`/trading-board/${ETradingBoardProviderAlias.ce}`], {
      queryParamsHandling: 'merge',
      queryParams: this.router.getCurrentNavigation()?.finalUrl.queryParams,
    });
  }
}
