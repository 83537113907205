import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '@app/core/services/auth.service';
import {OnboardingService} from '@app/eqwire/onboarding/services/onboarding.service';
import {Public} from '@app/shared/decorators/public.decorator';
import {Environment} from '@env/environment.entities';

@Injectable()
export class AuthorizedAccessGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly environment: Environment,
    private readonly onboardingService: OnboardingService,
  ) {}

  @Public(Promise.resolve(false))
  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (
      this.environment.isEqwireProject &&
      this.authService.isLoggedIn() &&
      !this.onboardingService.isOnboardingCompleted()
    ) {
      void this.router.navigate(['/onboarding']);

      return false;
    }

    if (this.authService.isLoggedIn() || this.environment.isB2TraderStandalone) {
      return true;
    }

    const referralQueryParamValue: string = route.queryParams[this.environment.referralQueryParamKey];
    if (referralQueryParamValue) {
      void this.router.navigate(['/login'], {
        queryParams: {[this.environment.referralQueryParamKey]: referralQueryParamValue},
      });
    } else {
      void this.router.navigate(['/']);
    }

    return false;
  }
}
