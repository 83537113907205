import {Casts, CastTypes, Model} from '@app/core/model';
import {CurrencyBalance} from '@app/core/models/account/currency.balance';

export class ReportOptions extends Model {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public b2margin_portfolio: CurrencyBalance[];

  public readonly casts: Casts = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    b2margin_portfolio: [CastTypes.Null, [CurrencyBalance]],
  };
}
