import {plainToInstance} from 'class-transformer';

import {EB2traderEventTypes} from '../../../enum/b2trader/b2trader-event-types';
import {EB2TraderEventHandlersReceived} from '../../../event-handlers/b2trader/b2trader-event-handlers.enum';
import {B2traderStateStore} from '../../../facades/b2trader-state-store';
import {TradeFees} from '../../../models/b2trader/trade-fees';
import {b2TraderInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class B2traderTiersInternalEventHandler extends AEventHandler<TradeFees> {
  public type = EB2traderEventTypes.Tiers;

  constructor(private readonly store: B2traderStateStore) {
    super();
  }

  public handleMessage(resource: TradeFees): void {
    this.store.messages$.next({
      type: EB2TraderEventHandlersReceived.Tiers,
      payload: plainToInstance(TradeFees, resource),
    });
  }
}

b2TraderInternalEventHandlers.push(B2traderTiersInternalEventHandler);
