import {Injectable, OnDestroy} from '@angular/core';
import {IGridsterEvent, IGridsterEventParam} from '@app/core/models/gridster';
import {IGridsterEventPayload} from '@app/core/models/gridster/gridster-events';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class GridsterWidgetsEventsService implements OnDestroy {
  private readonly events$: Subject<IGridsterEvent> = new Subject();

  public sendEvent(event: IGridsterEvent): void {
    this.events$.next(event);
  }

  public getEvent({type, widget}: IGridsterEventParam): Observable<IGridsterEventPayload> {
    return this.events$.pipe(
      filter((event: IGridsterEvent) => {
        if (type === event.type && widget === event.widget) {
          return true;
        }

        if (type === event.type) {
          return true;
        }

        return widget === event.widget;
      }),
      map(event => event.payload),
    );
  }

  public ngOnDestroy(): void {
    this.events$.complete();
  }
}
