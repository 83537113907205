import {EMoexOpcode} from '@app/trading-board/enum/moex/opcode';
import {MoexStateStore} from '@app/trading-board/facades/moex-state-store';
import {IMoexMessage} from '@app/trading-board/interfaces/moex/moex-message';

import {AEventHandler, moexInternalEventHandlers} from '../../event-handler.abstract';

export class MoexLevel2InternalEventHandler extends AEventHandler<IMoexMessage> {
  public readonly type = EMoexOpcode.OrderBookGetAndSubscribe;

  constructor(private readonly store: MoexStateStore) {
    super();
  }

  public handleMessage(message: IMoexMessage): void {
    this.store.level2$.next(message);
  }
}

moexInternalEventHandlers.push(MoexLevel2InternalEventHandler);
