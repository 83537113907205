import {Expose} from 'class-transformer';

export class InstrumentLimits {
  @Expose()
  public minOrderSize: number;

  @Expose()
  public maxOrderSize: number;

  @Expose()
  public symbol: string;

  @Expose()
  public instrumentId: number;
}
