<form *ngIf="formWrapper?.form" [formGroup]="formWrapper.form" (submit)="onSubmit()" (reset)="onCancel()">
  <ng-container
    [ngTemplateOutlet]="formTemplate || defaultTemplate"
    [ngTemplateOutletContext]="{form: formWrapper.form, fields: formWrapper.fields}"
  ></ng-container>
</form>

<ng-template #defaultTemplate>
  <app-form-group
    [form]="formWrapper.form"
    [formGroupTemplate]="formGroupTemplate"
    [fields]="formWrapper.fields"
    [isFocusFirstInput]="isFocusFirstInput"
  ></app-form-group>
  <div>
    <ng-content></ng-content>
  </div>

  <ng-container
    *ngIf="!withoutButtons"
    [ngTemplateOutlet]="formButtonsTemplate || defaultButtonsTemplate"
    [ngTemplateOutletContext]="{form: formWrapper.form, fields: formWrapper.fields}"
  ></ng-container>
</ng-template>

<ng-template #defaultButtonsTemplate>
  <div class="button-panel">
    <button
      *ngIf="backLabel"
      type="button"
      class="back-label card-bg"
      (click)="backEmitter.emit()"
      mat-stroked-button
      color="accent"
    >
      {{ backLabel }}
    </button>
    <button
      *ngIf="submitLabel"
      mat-flat-button
      color="accent"
      type="submit"
      [disabled]="disabled || formWrapper.form.invalid"
    >
      {{ submitLabel }}
    </button>
    <button *ngIf="cancelLabel" type="reset" mat-flat-button color="primary">
      {{ cancelLabel }}
    </button>
  </div>
</ng-template>
