import {IPercentageItem} from '../interfaces/percentage-item.interface';

export const percentageList: IPercentageItem[] = [
  {
    label: '25%',
    factor: 0.25,
    id: 0,
  },
  {
    label: '50%',
    factor: 0.5,
    id: 1,
  },
  {
    label: '75%',
    factor: 0.75,
    id: 2,
  },
  {
    label: '100%',
    factor: 1,
    id: 3,
  },
];
