import {SECOND_IN_MS} from '@app/core/constants/common';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {B2TraderAuthPublic} from '@app/trading-board/b2trader-auth/b2trader-auth-public';
import {mapIdentityTokenResponse} from '@app/trading-board/b2trader-auth/mappers/b2trader-identity-token-response.mapper';
import {IPublicLoginDialogData} from '@app/trading-board/interfaces/b2trader/public-login-dialog-data';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {IdentityAuth} from 'b2trader.identity.auth';
import {IB2traderIdentityErrorResponse} from 'b2trader.identity.auth/dist/interfaces/b2trader-identity-error-response';
import {IB2traderIdentityTokenResponse} from 'b2trader.identity.auth/dist/interfaces/b2trader-identity-token-response';
import {from, NEVER, Observable, of, throwError, timer} from 'rxjs';
import {map, shareReplay, startWith, switchMap, take} from 'rxjs/operators';

import {IB2TraderApiData} from './interfaces/b2trader-api-data';

export class B2traderAuthPublicStandalone extends B2TraderAuthPublic {
  private readonly timestampDelta = 30 * SECOND_IN_MS;
  private readonly identityAuth = new IdentityAuth(this.apiUrl, 'spa');

  private refreshTokenRequest$: Observable<IB2TraderApiData | null> | null = null;

  public readonly apiData$ = this.apiDataValue$.pipe(
    switchMap(data => this.refreshTokenRequest$ ?? of(data)),
    switchMap(data => {
      if (!data) {
        this.reset$.next();
        return NEVER;
      }

      if (data.expirationTimestamp) {
        const remain = data.expirationTimestamp - Date.now() - this.timestampDelta;

        if (remain <= 0) {
          this.refreshTokenRequest$ = this.generateRefreshTokenRequest(data.refreshToken);
          return this.refreshTokenRequest$;
        }

        return timer(remain).pipe(
          startWith(undefined as number),
          switchMap(res => {
            if (res === undefined) {
              return of(data);
            } else {
              this.refreshTokenRequest$ = this.generateRefreshTokenRequest(data.refreshToken);
              return this.refreshTokenRequest$;
            }
          }),
        );
      }

      return of(data);
    }),
  );

  constructor(protected readonly apiUrl: string, protected readonly platformType: string) {
    super(apiUrl, platformType);
  }

  private generateRefreshTokenRequest(refreshToken: string): Observable<IB2TraderApiData | null> {
    return from(this.identityAuth.refreshToken(refreshToken)).pipe(
      switchMap(response => response.json()),
      map((refreshResponse: IB2traderIdentityTokenResponse | IB2traderIdentityErrorResponse) => {
        this.refreshTokenRequest$ = null;

        if (!('error' in refreshResponse)) {
          const newData = mapIdentityTokenResponse(refreshResponse, this.apiUrl);
          ALocaleStorage.B2TRADER_API_DATA.set(JSON.stringify(newData));
          this.apiDataValue$.next(newData);

          return newData;
        }
        ALocaleStorage.B2TRADER_API_DATA.remove();
        this.apiDataValue$.next(null);
        this.reset$.next(null);

        return null;
      }),
      shareReplay({refCount: true, bufferSize: 1}),
    );
  }

  protected getApiData(): Observable<IB2TraderApiData> {
    const noLoginApiData = {
      url: this.apiUrl,
      expirationTimestamp: null,
    };
    let b2traderApiData: IB2TraderApiData;

    try {
      const storedApiData: IB2TraderApiData = JSON.parse(ALocaleStorage.B2TRADER_API_DATA.get());

      b2traderApiData = storedApiData ?? noLoginApiData;
    } catch (_) {
      b2traderApiData = noLoginApiData;
    }

    return of(b2traderApiData);
  }

  public signIn({email, password}: IPublicLoginDialogData): Observable<void> {
    return from(this.identityAuth.signIn(email, password)).pipe(
      switchMap(response => {
        if (response.status >= 400) {
          return throwError(() => new Error(marker('TradingBoard.B2TraderAuth.Public.UnauthorizedMessage')));
        }

        return of(response);
      }),
      switchMap(() => this.identityAuth.authorize()),
      switchMap(code => this.identityAuth.retrieveToken(code)),
      map(data => {
        if ('access_token' in data) {
          const b2TraderApiData = mapIdentityTokenResponse(data, this.apiUrl);
          ALocaleStorage.B2TRADER_API_DATA.set(JSON.stringify(b2TraderApiData));

          this.apiDataValue$.next(b2TraderApiData);
        }
      }),
      take(1),
    );
  }

  public signOut(): Observable<void> {
    return this.apiData$.pipe(
      switchMap(apiData => this.identityAuth.signOut(apiData.accessToken)),
      map(() => {
        ALocaleStorage.B2TRADER_API_DATA.remove();
        this.apiDataValue$.next(null);
        this.reset$.next(null);
      }),
      take(1),
    );
  }
}
