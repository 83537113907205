import {EAliasBlocked} from '@app/core/models/verification/level';
import {Expose, Transform} from 'class-transformer';

import {IUserRightsDto} from './user-rights.dto';

export class UserRights {
  @Expose({name: 'deposits_blocked'})
  @Transform(({obj}: {obj: IUserRightsDto}) => !obj.deposits)
  public isDepositsBlocked: boolean;

  @Expose({name: 'withdrawals_blocked'})
  @Transform(({obj}: {obj: IUserRightsDto}) => !obj.withdrawals)
  public isWithdrawalsBlocked: boolean;

  @Expose({name: 'internal_transfer_blocked'})
  @Transform(({obj}: {obj: IUserRightsDto}) => !obj.internal_transfers)
  public isInternalTransferBlocked: boolean;

  @Expose({name: 'converter_blocked'})
  @Transform(({obj}: {obj: IUserRightsDto}) => !obj.converter)
  public isConverterBlocked: boolean;

  @Expose({name: 'verification_blocked'})
  @Transform(({obj}: {obj: IUserRightsDto}) => !obj.verification)
  public isVerificationBlocked: boolean;

  public getBlocked(alias: EAliasBlocked): boolean {
    switch (alias) {
      case EAliasBlocked.Converter:
        return this.isConverterBlocked;

      case EAliasBlocked.Deposits:
        return this.isDepositsBlocked;

      case EAliasBlocked.InternalTransfer:
        return this.isInternalTransferBlocked;

      case EAliasBlocked.Withdrawals:
        return this.isWithdrawalsBlocked;

      case EAliasBlocked.Verification:
        return this.isVerificationBlocked;

      default:
        return;
    }
  }
}
