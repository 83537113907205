import {Injectable} from '@angular/core';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EMoexEventHandlersReceived} from '@app/trading-board/event-handlers/moex/moex-event-handlers.enum';
import {IParentAccount} from '@app/trading-board/interfaces/moex/moex-accounts-info';

@Injectable()
export class MoexAccountsService extends AEventHandler<IParentAccount[]> {
  public readonly type = EMoexEventHandlersReceived.Accounts;

  constructor(private readonly moexDatafeedService: MoexDatafeedService) {
    super();
  }

  public handleMessage(resource: IParentAccount[]): void {
    this.moexDatafeedService.accountsValue$.next(resource);
  }
}
