import {Exceptions} from './exceptions';

export class ValidateException extends Exceptions {
  public static readonly STATUS_CODE = 422;

  private readonly _body: {
    status: number;
    data: {[key: string]: string[]};
  };

  constructor(body: {status: number; data: {[key: string]: string[]}}) {
    super(null);
    this._body = body;
  }

  public getErrors(): Record<string, string[]> {
    return this._body.data;
  }

  /**
   * Gets first error message.
   *
   * @returns {string | null} First error message or null if there are no errors.
   */
  public getFirstError(): string | null {
    const errors = this._body.data;
    const firstErrorKey = Object.keys(errors)[0];

    return errors[firstErrorKey]?.[0] ?? null;
  }
}
