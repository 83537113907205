import {EB2MarginEventType} from '@app/trading-board/enum/b2margin/b2margin-event-type';
import {b2MarginInternalEventHandlers, AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {B2marginStateStore} from '@app/trading-board/facades/b2margin-state-store';
import {MessageTO} from '@b2broker/b2margin-trade-models';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';

export class PopupMessagesEventHandler extends AEventHandler<MessageTO> {
  public static readonly MESSAGE_TYPES = [
    B2MarginModels.MessageTO.MessageTypeEnum.LIQUIDATION,
    B2MarginModels.MessageTO.MessageTypeEnum.RISKLEVELALERT,
  ];

  public readonly type = EB2MarginEventType.PopupMessages;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public static isPopupMessage(type: B2MarginModels.MessageTO.MessageTypeEnum): boolean {
    return this.MESSAGE_TYPES.includes(type);
  }

  public handleMessage(payload: MessageTO): void {
    this.store.popupMessage$.next(payload);
  }
}

b2MarginInternalEventHandlers.push(PopupMessagesEventHandler);
