import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, Router} from '@angular/router';
import {Environment} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private readonly themeName: string = this.environment.projectInfo.fullName;

  private readonly titlesDictionary: Record<string, Observable<string>> = {
    wallet: this.translate.get('Menu_Wallet'),
    trade: this.translate.get('Menu_Trade'),
    funds: this.translate.get('Menu_Funds'),
    history: this.translate.get('Menu_History'),
    profile: this.translate.get('Menu_Profile'),
    helpdesk: this.translate.get('Menu_Helpdesk'),
    /* eslint-disable @typescript-eslint/naming-convention */
    'api key management': this.translate.get('Api_Key_Management'),
    'verification sum sub': this.translate.get('Menu_Verification_Sum_Sub'),
    'payout white list': this.translate.get('payout white list'),
    'no two fa': this.translate.get('no two fa'),
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly title: Title,
    private readonly translate: TranslateService,
    private readonly environment: Environment,
  ) {}

  public routerWatcher(): void {
    this.router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        map(() => getRouteTitle(this.activatedRoute.snapshot)),
        mergeMap((t: string) => this.titlesDictionary[t] ?? this.translate.get(t)),
      )
      .subscribe((t: string) => this.title.setTitle(`${this.themeName} | ${t}`));
  }
}

export function getRouteTitle(activatedRoute: ActivatedRouteSnapshot): string {
  let route: ActivatedRouteSnapshot = activatedRoute.firstChild;

  while (route?.firstChild?.data?.title) {
    route = route.firstChild;
  }

  return route?.data.title;
}
