import {Injectable} from '@angular/core';
import {ESigningFormWizardSteps} from '@app/ui/signing-form/enums/signing-form-wizard-steps';
import {GetDocumentsComponent} from '@app/ui/signing-form/wizards/get-documents/get-documents.component';
import {ResignProfilesComponent} from '@app/ui/signing-form/wizards/resign-profiles/resign-profiles.component';
import {BatchItem} from '@app/wizard/batch.item';
import {IBatchExtendService} from '@app/wizard/contracts/i.batch.extend.service';

@Injectable()
export class BatchExtendService implements IBatchExtendService {
  public extend(): Record<string, BatchItem> {
    return {
      getDocuments: new BatchItem(GetDocumentsComponent, null),
      resignProfiles: new BatchItem(ResignProfilesComponent, null),
    };
  }

  public mapping(): Record<string, string> {
    return {
      [ESigningFormWizardSteps.GetDocuments]: 'getDocuments',
      [ESigningFormWizardSteps.ReSignProfiles]: 'resignProfiles',
    };
  }
}
