import {Type} from '@angular/core';
import {B2MarginOrderHistoryService} from '@app/trading-board/event-handlers/b2margin/b2-margin-order-history.service';
import {B2marginAccountService} from '@app/trading-board/event-handlers/b2margin/b2margin-account.service';
import {B2marginChartService} from '@app/trading-board/event-handlers/b2margin/b2margin-chart.service';
import {B2marginCloseHandlerService} from '@app/trading-board/event-handlers/b2margin/b2margin-close-handler.service';
import {B2MarginErrorHandlerService} from '@app/trading-board/event-handlers/b2margin/b2margin-error-handler.service';
import {B2marginHistoryOrdersService} from '@app/trading-board/event-handlers/b2margin/b2margin-history-orders.service';
import {B2marginInstrumentLimitsService} from '@app/trading-board/event-handlers/b2margin/b2margin-instrument-limits.service';
import {B2marginInstrumentSessionsService} from '@app/trading-board/event-handlers/b2margin/b2margin-instrument-sessions.service';
import {B2MarginInstruments} from '@app/trading-board/event-handlers/b2margin/b2margin-instruments.service';
import {B2marginLevel1Service} from '@app/trading-board/event-handlers/b2margin/b2margin-level1.service';
import {B2MarginLevel2Service} from '@app/trading-board/event-handlers/b2margin/b2margin-level2.service';
import {B2marginOpenOrdersService} from '@app/trading-board/event-handlers/b2margin/b2margin-open-orders.service';
import {B2marginOrderStatusService} from '@app/trading-board/event-handlers/b2margin/b2margin-order-status.service';
import {B2marginPopupMessagesService} from '@app/trading-board/event-handlers/b2margin/b2margin-popup-messages.service';
import {B2marginPositionService} from '@app/trading-board/event-handlers/b2margin/b2margin-position.service';
import {B2marginTradesHistoryService} from '@app/trading-board/event-handlers/b2margin/b2margin-trades-history.service';
import {B2marginTradesService} from '@app/trading-board/event-handlers/b2margin/b2margin-trades.service';
import {B2marginUserSettingsService} from '@app/trading-board/event-handlers/b2margin/b2margin-user-settings.service';
import {MarginImpactService} from '@app/trading-board/event-handlers/b2margin/margin-impact.service';
import {MarginRateService} from '@app/trading-board/event-handlers/b2margin/margin-rate.service';
import {PositionMarginImpactService} from '@app/trading-board/event-handlers/b2margin/position-margin-impact.service';
import {RatesService} from '@app/trading-board/event-handlers/b2margin/rates.service';
import {TradingCalendarService} from '@app/trading-board/event-handlers/b2margin/trading-calendar.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {SharedAfterConnectService} from '@app/trading-board/event-handlers/shared/shared-after-connect.service';
import {SharedRefreshTokensHandlerService} from '@app/trading-board/event-handlers/shared/shared-refresh-tokens-handler.service';
import {SharedUnauthorizedService} from '@app/trading-board/event-handlers/shared/shared-unauthorized.service';

export const B2MarginEventHandlersServices: Type<AEventHandler<unknown>>[] = [
  B2MarginInstruments,
  B2MarginLevel2Service,
  B2MarginErrorHandlerService,
  B2marginCloseHandlerService,
  B2marginLevel1Service,
  B2marginInstrumentLimitsService,
  TradingCalendarService,
  RatesService,
  MarginRateService,
  MarginImpactService,
  B2marginOpenOrdersService,
  B2marginAccountService,
  B2marginOrderStatusService,
  B2marginUserSettingsService,
  PositionMarginImpactService,
  B2marginPositionService,
  B2MarginOrderHistoryService,
  B2marginChartService,
  B2marginInstrumentSessionsService,
  B2marginHistoryOrdersService,
  B2marginTradesService,
  B2marginTradesHistoryService,
  B2marginPopupMessagesService,
  SharedUnauthorizedService,
  SharedAfterConnectService,
  SharedRefreshTokensHandlerService,
];
