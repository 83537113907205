import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable, Injector, Provider} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {MaintenanceException} from '@app/core/exceptions/maintenance-exception';
import {IErrorDialogData} from '@app/ui/error-dialog/error-dialog.component';
import {Observable, ObservableInput, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ABaseFullscreenErrorService} from './base-fullscreen-error.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceErrorInterceptor extends ABaseFullscreenErrorService implements HttpInterceptor {
  protected readonly config: MatDialogConfig<IErrorDialogData> = {
    width: '100%',
    height: '100%',
    closeOnNavigation: true,
    hasBackdrop: false,
    panelClass: ['error-dialog'],
    data: {
      header: of('We are Under Maintenance'),
      text: of('Will be Back Soon!'),
      emoji: 'emoji-maintenance',
      textAccentButton: of('Refresh page'),
      onAccentButton: (): void => location.reload(),
    },
  };

  constructor(injector: Injector) {
    super(injector);
  }

  public intercept(req: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Response>> {
    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse): ObservableInput<HttpEvent<Response>> => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === MaintenanceException.STATUS_CODE) {
            this.showDialog(this.config);
          }
        }

        return throwError(() => response);
      }),
    );
  }
}

export const MaintenanceErrorService: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: MaintenanceErrorInterceptor,
  multi: true,
};
