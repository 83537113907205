import {IResultApi} from '../contracts/i.result.api';
import {Exceptions} from './exceptions';

export class ForbiddenErrorException extends Exceptions {
  public static readonly STATUS_CODE = 403;

  constructor(body: IResultApi) {
    super(body);
  }

  public getError(): IResultApi {
    return this.body;
  }
}
