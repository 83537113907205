import {Injectable} from '@angular/core';
import {NavigationStart, PRIMARY_OUTLET, Router} from '@angular/router';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {TranslateService} from '@ngx-translate/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {LanguageState} from '../language.state';
import {Language} from '../models/language';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private static onLangChange(lang: string): void {
    ALocaleStorage.LANG.set(lang);
    document.documentElement.lang = lang;
  }

  private static parseQueryParams(queryString?: string): Record<string, unknown> {
    const params = new URLSearchParams(queryString);
    return Object.fromEntries(params);
  }

  public static isLang(path?: string): boolean {
    return (path ?? '').length === LanguageService.LANG_NAME_SIZE && path !== LanguageService.SPECIFY_ROUTER;
  }

  public static readonly DEFAULT_LANGUAGE = 'en';
  public static readonly SPECIFY_ROUTER = 'tx';
  public static readonly LANG_NAME_SIZE = 2;

  public readonly changeLanguage$ = this.translate.onLangChange.pipe(map(changes => changes.lang));

  public get selected(): string {
    return ALocaleStorage.LANG.get() || LanguageService.DEFAULT_LANGUAGE;
  }

  @Select(LanguageState.getAllLanguages)
  public allLanguages$: Observable<Language[]>;

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly store: Store,
  ) {
    this.changeLanguage$.subscribe(LanguageService.onLangChange);

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      const urlTree = this.router.parseUrl(event.url);
      const [langSegment] = urlTree.root.children[PRIMARY_OUTLET]?.segments ?? [];

      const isLang = LanguageService.isLang(langSegment?.path);

      if (isLang && langSegment?.path !== this.selected) {
        if (this.hasLang(langSegment?.path)) {
          this.updateLanguage(langSegment.path);
        } else {
          langSegment.path = this.getSelectedLang();
          void this.router.navigateByUrl(urlTree.toString());
        }

        return;
      }

      if (!isLang) {
        const fullUrl = event.url === '/' ? '' : event.url;
        const [url, queryParamsString] = fullUrl.split('?');

        void this.router.navigate(['/' + this.getSelectedLang() + url], {
          queryParams: LanguageService.parseQueryParams(queryParamsString),
        });
      }
    });
  }

  private hasLang(language: string): boolean {
    const languages: Language[] = this.store.selectSnapshot(LanguageState.getAllLanguages);
    return languages.map(({lang}) => lang).includes(language);
  }

  public getSelectedLang(): string {
    return this.hasLang(this.selected) ? this.selected : LanguageService.DEFAULT_LANGUAGE;
  }

  public all(): Observable<Language[]> {
    return this.allLanguages$;
  }

  public updateLanguage(lang: string): void {
    LanguageService.onLangChange(lang);
    this.translate.use(lang);
  }

  public setLang(lang: string): void {
    const urlTree = this.router.parseUrl(this.router.url);
    const [langSegment] = urlTree.root.children[PRIMARY_OUTLET]?.segments ?? [];

    const isLang = LanguageService.isLang(langSegment?.path);

    if (isLang) {
      langSegment.path = lang;
      LanguageService.onLangChange(lang);
    }

    window.location.replace(urlTree.toString());
  }
}
