import {CastTypes, Model} from '@app/core/model';
import {Account} from '@app/core/models/account/account';
import {Product} from '@app/core/models/account/product';

import {EProductGroupType} from './enum/product-group-type';

export class Group extends Model {
  public id: number;
  public localizedCaption: string;
  public localizedDescription: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public multi_currency: boolean;
  public priority: number;
  public type?: EProductGroupType;

  public readonly casts = {
    id: [CastTypes.Number],
    localizedCaption: [CastTypes.String],
    localizedDescription: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    multi_currency: [CastTypes.Boolean],
    priority: [CastTypes.Number],
    type: [CastTypes.Number, CastTypes.Null],
  };
}

export class AccountGroup {
  public static reduce(accounts: Account[] = []): AccountGroup[] {
    const groupsDict = accounts.reduce((groups, account) => {
      if (!groups[account.product.group.id]) {
        groups[account.product.group.id] = new AccountGroup(account.product);
      }
      groups[account.product.group.id].accounts.push(account);
      return groups;
    }, {} as Record<number, AccountGroup>);

    return Object.values(groupsDict).sort(compareAccountGroups);
  }

  public accounts: Account[] = [];

  public group: Group = this.product.group;

  constructor(public product: Product) {}
}

export class ProductGroup {
  public static reduce(products: Product[] = []): ProductGroup[] {
    const groupsDict = products.reduce((groups, product: Product) => {
      if (!groups[product.group.id]) {
        groups[product.group.id] = new ProductGroup(product.group);
      }
      groups[product.group.id].products.push(product);
      return groups;
    }, {} as Record<number, ProductGroup>);

    return Object.values(groupsDict).sort(compareProductGroups);
  }

  public products: Product[] = [];

  constructor(public group: Group) {}
}

export function compareAccountGroups(l: AccountGroup, r: AccountGroup): number {
  return l.product.group.priority - r.product.group.priority;
}

export function compareProductGroups(l: ProductGroup, r: ProductGroup): number {
  return l.group.priority - r.group.priority;
}
