/* eslint-disable @typescript-eslint/naming-convention */
export enum ETradingBoardProviderAlias {
  /* Alias for b2trader provider */
  ce = 'ce',

  /* Alias for b2margin provider */
  me = 'me',

  /* Alias for b2margin spot provider */
  se = 'se',

  /* Alias for moex provider */
  moex = 'moex',
}
