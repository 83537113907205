import {HttpClient} from '@angular/common/http';
import {APP_INITIALIZER, Provider} from '@angular/core';
import {TranslationLoader} from '@app/core/translation.loader';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {Environment} from '@env/environment.entities';
import {TranslateLoader} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {plainToInstance} from 'class-transformer';
import {firstValueFrom, map, take} from 'rxjs';

import {UpdateLanguageState} from '../language.actions';
import {Language} from '../models/language';
import {LanguageService} from '../services/language.service';

const b2TraderPublicStandaloneLanguagesUrl = '/assets/config/b2trader-standalone-languages.json';

export function initLanguage(
  http: HttpClient,
  languageService: LanguageService,
  store: Store,
  translateLoader: TranslationLoader,
  environment: Environment,
): () => Promise<Language[]> {
  return async (): Promise<Language[]> => {
    const host = environment.apiUrl;
    const url = `${host}/api/v1/languages`;

    try {
      const languages = await firstValueFrom(
        http
          .get(
            environment.isB2traderPublicStandalone || environment.isB2TraderStandalone
              ? b2TraderPublicStandaloneLanguagesUrl
              : url,
          )
          .pipe(map((response: {data: Language[]}) => response.data.map(lang => plainToInstance(Language, lang)))),
      );

      let lang = LanguageService.DEFAULT_LANGUAGE;

      if (languages.length) {
        const selectedLanguage =
          ALocaleStorage.LANG.get() || environment.projectInfo.defaultLanguage || LanguageService.DEFAULT_LANGUAGE;
        const isSelectedLanguageInList = languages.some((l: Language) => l.lang === selectedLanguage);

        lang = selectedLanguage;

        if (!isSelectedLanguageInList) {
          const isDefaultLanguageInList = languages.some((l: Language) => l.lang === LanguageService.DEFAULT_LANGUAGE);

          lang = isDefaultLanguageInList ? LanguageService.DEFAULT_LANGUAGE : languages[0].lang;
        }
      }

      languageService.updateLanguage(lang);
      store.dispatch(new UpdateLanguageState({allLanguages: languages, currentLanguage: lang}));

      await firstValueFrom(translateLoader.translates$.pipe(take(1)));

      return languages;
    } catch (e) {
      languageService.updateLanguage(LanguageService.DEFAULT_LANGUAGE);
      store.dispatch(new UpdateLanguageState({allLanguages: [], currentLanguage: LanguageService.DEFAULT_LANGUAGE}));

      return [];
    }
  };
}

export const initLanguageProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initLanguage,
  multi: true,
  deps: [HttpClient, LanguageService, Store, TranslateLoader, Environment],
};
