import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PrecisionDirective} from '@app/shared/modules/precision/precision.directive';

import {PrecisionPipe} from './precision.pipe';

@NgModule({
  declarations: [PrecisionPipe, PrecisionDirective],
  imports: [CommonModule],
  exports: [PrecisionPipe, PrecisionDirective],
})
export class PrecisionModule {}
