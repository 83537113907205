import {Injectable} from '@angular/core';
import {ITradingViewState} from '@app/trading-board/interfaces/trading-view-state';
import * as _ from 'lodash-es';

@Injectable({providedIn: 'root'})
export class TradingViewStateManager {
  private readonly textEncoder = new TextEncoder();
  private readonly textDecoder = new TextDecoder();

  private readonly widgetIdToStateMap = new Map<string, ITradingViewState>();

  private getChanges(
    internalOriginalState: Record<string, unknown>,
    internalNewState: Record<string, unknown>,
  ): Record<string, unknown> {
    let arrayIndexCounter = 0;

    return _.transform(internalNewState, (result, value: Record<string, unknown>, key) => {
      const internalOriginalStateValue = internalOriginalState[key] as Record<string, unknown>;

      if (!_.isEqual(value, internalOriginalStateValue)) {
        const resultKey = _.isArray(internalOriginalState) ? arrayIndexCounter++ : key;

        result[resultKey] =
          _.isObject(value) && _.isObject(internalOriginalStateValue)
            ? this.getChanges(internalOriginalStateValue, value)
            : value;
      }
    });
  }

  private getDeltaBetweenStates(
    originalState: ITradingViewState,
    newState: ITradingViewState,
  ): Record<string, unknown> {
    return this.getChanges(originalState, newState);
  }

  public encodeState(state: ITradingViewState): string | undefined {
    const jsonState = JSON.stringify(state);
    const uint8Array = this.textEncoder.encode(jsonState);

    return uint8Array.toString();
  }

  public decodeState(encodedState: string): ITradingViewState | undefined {
    try {
      const uint8ArrayString = encodedState.split(',');
      const uint8ArrayNumber = uint8ArrayString.map(Number);
      const uint8Array = new Uint8Array(uint8ArrayNumber);
      const decodedStateString = this.textDecoder.decode(uint8Array);

      const state: ITradingViewState = JSON.parse(decodedStateString);
      return state;
    } catch {
      return undefined;
    }
  }

  /**
   * Go through all TV state fields and replace current symbol.
   *
   * @param state - Trading view state.
   * @param symbol - Current TV symbol.
   * @returns - TV state with current symbol.
   */
  public patchTradingViewStateSymbol(state: ITradingViewState, symbol: string): ITradingViewState | undefined {
    if (_.isNil(state)) {
      return undefined;
    }

    const upperSymbol = symbol.toUpperCase();

    try {
      state.charts.forEach(chart => {
        chart.panes.forEach(pane => {
          pane.sources
            .filter(source => source.type === 'MainSeries' || source.type === 'Study')
            .forEach(source => {
              if (source.state.symbol) {
                source.state.symbol = upperSymbol;
                source.state.shortName = upperSymbol;
              }
            });
        });
      });
    } catch {
      console.warn('Failed to patch TV State');
      return state;
    }

    return state;
  }

  public getDeltaState(widgetId: string, newState: ITradingViewState): Record<string, unknown> {
    const state = this.widgetIdToStateMap.get(widgetId);
    const delta = this.getDeltaBetweenStates(state, newState);

    return delta;
  }

  public setState(widgetId: string, state: ITradingViewState): void {
    if (!this.widgetIdToStateMap.has(widgetId)) {
      this.widgetIdToStateMap.set(widgetId, state);
    }
  }

  public getMergedState(widgetId: string, deltaState: Record<string, unknown>): ITradingViewState | undefined {
    const state = _.cloneDeep(this.widgetIdToStateMap.get(widgetId));
    if (!state) {
      return undefined;
    }

    const result = _.merge(state, deltaState);
    return result;
  }
}
