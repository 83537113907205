<ng-template *ngIf="!hasCaptchaError">
  <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
    {{ getError(err) | async }}
  </mat-error>
</ng-template>

<mat-error *ngIf="hasCaptchaError">
  {{ 'Captcha is invalid. Try again' | translate }}
</mat-error>

<div
  [attr.data-sitekey]="siteKey"
  [attr.data-callback]="responseHandlerName"
  data-size="invisible"
  class="g-recaptcha"
></div>
