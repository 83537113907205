import {EPlatformName} from '@app/core/models/account/enum/platform-name';
import {ECommonWidgetKeys} from '@app/gridster/widgets/gridster-widgets';
import {Expose} from 'class-transformer';

export interface IRulesWidget {
  maxCount: number;
}

export interface IAdminWidgetRuleEntity {
  source: string;
  platform: EPlatformName;
  instruments: IInstrument[];
  frontend?: string;
}

export interface IInstrument {
  symbol: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  show: boolean;
}

export class RulesWidget implements IRulesWidget {
  public maxCount: number;
}

export class AdminWidgetRules {
  public static reduceRules(rules: AdminWidgetRules[]): Record<string, AdminWidgetRules> {
    return rules.reduce((acc: Record<string, AdminWidgetRules>, curr: AdminWidgetRules) => {
      acc[curr.name] = curr;

      return acc;
    }, {});
  }

  public id: number;
  public caption: string;
  public name: ECommonWidgetKeys;
  public sort: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public delete: boolean;

  public entity?: IAdminWidgetRuleEntity;

  @Expose({name: 'position_x'})
  public positionX: number | null;
  @Expose({name: 'position_y'})
  public positionY: number | null;
  public width: number | null;
  public height: number | null;

  @Expose({name: 'show_by_default'})
  public isShowByDefault: boolean;

  public isClosingDisabled(): boolean {
    return this.delete === false;
  }
}
