import {AB2marginPopupMessage} from '@app/trading-board/models/b2margin/b2margin-popup-message';
import {EB2marginPopupType} from '@app/trading-board/models/b2margin/b2margin-popup-type';
import {ERiskLevelAlertType} from '@app/trading-board/models/b2margin/risk-level-alert-type';
import {AccountTO, MessageTO} from '@b2broker/b2margin-trade-models';

export class B2marginRiskLevelAlertMessage extends AB2marginPopupMessage {
  public static adapt(
    message: MessageTO,
    accounts: Map<string, AccountTO>,
  ): {date: Date; riskWarningLevel: unknown; accountCode: unknown; riskLevelLimit: unknown; baseCurrency: string} {
    const account = accounts.get(message.parametersTO.accountCode);

    return {
      accountCode: message.parametersTO.accountCode,
      baseCurrency: account.baseCurrency,
      riskWarningLevel: message.parametersTO.riskWarningLevel,
      riskLevelLimit: message.parametersTO.riskLevelLimit,
      date: new Date(message.timeStamp),
    };
  }

  public type = EB2marginPopupType.Warning;

  public accountCode: string;
  public baseCurrency: string;
  public riskWarningLevel: ERiskLevelAlertType;
  public riskLevelLimit: string;

  public date: Date;
}
