<ng-container [formGroup]="formGroup">
  <div class="textarea-wrapper">
    <mat-form-field>
      <textarea
        matInput
        [tooltipIfLongLabel]="label"
        [cdkTextareaAutosize]="true"
        [cdkAutosizeMinRows]="minRows"
        [cdkAutosizeMaxRows]="maxRows"
        [required]="required"
        [formControl]="control"
      >
      </textarea>

      <mat-hint *ngIf="field.label.hint" [align]="field.label.hint.position || 'start'">
        {{ getHintLabel() | async }}
      </mat-hint>

      <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
        {{ getInputError(err) | async }}
      </mat-error>

      <mat-label #label>
        {{ getLabel() | async }}
      </mat-label>
    </mat-form-field>
  </div>
</ng-container>
