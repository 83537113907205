import {ETimeInForce} from '@app/trading-board/enum/b2trader/time-in-force';
import {ESide} from '@app/trading-board/enum/side';
import {IOrder} from '@app/trading-board/interfaces/b2trader/order';
import {Expose, Transform} from 'class-transformer';

import {EHistoryOrderStatus} from '../../enum/b2trader/history-order-status';
import {EB2TraderOrderTypes} from '../../enum/b2trader/order-types';
import {IHistoryOrdersRequest} from '../../interfaces/b2trader/history-orders-request';
import {IB2TraderInstrument} from '../../interfaces/b2trader/instrument';
import {IOrderModelParam} from '../../interfaces/b2trader/order-model-param';
import {DecimalHelper} from '../../models/decimal-helper';
import {Tick} from '../level1';
import {AOrder} from '../order';

export class B2TraderOrder extends AOrder<IOrderModelParam> {
  private static readonly DEFAULT_PRECISION = 8;

  public static sortRawOrders(orders: IOrder[]): IOrder[] {
    return orders.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  public static compare(range: Partial<IHistoryOrdersRequest> | undefined, order: B2TraderOrder): boolean {
    const isLaterThanFrom = range?.from ? range.from.getTime() < order.time.getTime() : true;
    const isEarlierThanTo = range?.to ? order.time.getTime() < range.to.getTime() : true;

    return isLaterThanFrom && isEarlierThanTo;
  }

  public static makeOrderParam(
    resource: Map<string, IOrder>,
    instruments: IB2TraderInstrument[],
    level1?: Tick[],
  ): IOrderModelParam[] {
    return B2TraderOrder.sortRawOrders(Array.from(resource.values())).map(order => {
      const instrument = instruments.find(({nativeSymbol}) => order.marketId === nativeSymbol);
      const tick = level1?.find(({instrumentId}) => instrumentId === instrument.id);

      return {raw: order, instrument, tick};
    });
  }

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) => raw.orderId)
  public orderId: number;

  @Expose()
  @Transform(({obj: {instrument}}: {obj: IOrderModelParam}) => instrument?.symbolWithSeparator)
  public symbolWithSeparator: string;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) => raw.side)
  public side: ESide;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) => new Date(raw.createdAt))
  public time: Date;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) => raw.orderType)
  public type: EB2TraderOrderTypes;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam}) =>
    DecimalHelper.from(raw.requestedAmount, instrument?.priceScale ?? B2TraderOrder.DEFAULT_PRECISION),
  )
  public quantity: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam}) =>
    DecimalHelper.from(raw.requestedAmount)
      .minus(raw.remainingAmount)
      .setScale(instrument?.priceScale ?? B2TraderOrder.DEFAULT_PRECISION),
  )
  public unitsFilled: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam}) =>
    DecimalHelper.from(raw.executionPrice, instrument?.priceScale ?? B2TraderOrder.DEFAULT_PRECISION),
  )
  public executionPrice: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam}) =>
    DecimalHelper.from(raw.requestedPrice, instrument?.priceScale ?? B2TraderOrder.DEFAULT_PRECISION),
  )
  public requestedPrice: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) => raw.status)
  public status: EHistoryOrderStatus;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) => DecimalHelper.from(raw.remainingAmount))
  public remaining: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) => raw.timeInForce)
  public timeInForce: ETimeInForce;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam}) =>
    DecimalHelper.from(raw.requestedAmount).minus(raw.remainingAmount),
  )
  public filledSize: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam}) =>
    DecimalHelper.from(
      raw.commission,
      (B2TraderOrder.isBuy(raw.side) ? instrument?.priceScale : instrument?.amountScale) ??
        B2TraderOrder.DEFAULT_PRECISION,
    ),
  )
  public fee: DecimalHelper;

  public isCanceled(): boolean {
    return this.status === EHistoryOrderStatus.Cancelled;
  }

  public isFilled(): boolean {
    return this.status === EHistoryOrderStatus.Completed;
  }

  public isActive(): boolean {
    return this.status === EHistoryOrderStatus.Working;
  }

  public update(_: IOrderModelParam): void {
    //TODO B2Trader Optimization
  }
}
