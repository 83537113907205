import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {B2MarginHistoryOrder} from '@app/trading-board/models/b2margin/b2-margin-history-order';

@Injectable()
export class B2marginHistoryOrdersService extends AEventHandler<Map<number, B2MarginHistoryOrder>> {
  public type = EEventHandlersReceived.HistoryOrders;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: Map<number, B2MarginHistoryOrder>): void {
    this.b2marginDatafeedService.historyOrders$.next(resource);
  }
}
