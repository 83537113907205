/* eslint-disable @typescript-eslint/naming-convention */
import {Casts, CastTypes, Model} from '@app/core/model';

export class Country extends Model {
  public country_code: number;
  public full_name: string;
  public iso_3166_2: string;
  public iso_3166_3: string;
  public calling_code: number;
  public localizedName: string;
  public localizedFullName: string;

  public readonly casts: Casts = {
    country_code: [CastTypes.Number],
    full_name: [CastTypes.String, CastTypes.Null],
    iso_3166_2: [CastTypes.String],
    iso_3166_3: [CastTypes.String],
    calling_code: [CastTypes.String],
    localizedName: [CastTypes.String],
    localizedFullName: [CastTypes.String, CastTypes.Null],
  };

  public getName(): string {
    return this.localizedFullName || this.localizedName || this.full_name || '';
  }
}
