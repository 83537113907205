import {IResultApi} from '../contracts/i.result.api';
import {ServerError} from '../models/server.error';
import {Exceptions} from './exceptions';

export class InternalServerErrorException extends Exceptions {
  public static readonly STATUS_CODE = 500;

  constructor(public body: IResultApi) {
    super(body);
  }

  public getError(): ServerError {
    return ServerError.Make(this.getData());
  }
}
