/* eslint-disable @typescript-eslint/naming-convention */
import {Casts, CastTypes, Model} from '@app/core/model';
import {Address, EType as EAddressType} from '@app/core/models/user/address';
import {Info} from '@app/core/models/user/info';
import {Manager} from '@app/core/models/user/manager';
import {Phone} from '@app/core/models/user/phone';
import {Settings} from '@app/core/models/user/settings';
import {Type} from '@app/core/models/user/type';
import {VerificationLevel} from '@app/core/models/user/verification.level';

export class User extends Model {
  public id: number;
  public show_id: boolean;
  public email: string;
  public nickname?: string;
  public status: number;
  public country_code?: number;
  public photo?: string;
  public created_at: string;
  public logged_at: string;
  public updated_at: string;
  public verification_level?: VerificationLevel;
  public manager?: Manager;
  public allow_change_userpic?: boolean;
  public allow_change_nickname?: boolean;
  public allow_show_email?: boolean;

  private _settings?: Settings;

  public get settings(): Settings {
    if (!this._settings) {
      this._settings = new Settings();
    }
    return this._settings;
  }

  public set settings(value: Settings) {
    this._settings = value;
  }

  public get info(): Info {
    return this._info || new Info();
  }

  public set info(value: Info) {
    this._info = value;
  }

  private _info: Info;

  public get phone(): Phone {
    return this._phone || new Phone();
  }

  public set phone(value: Phone) {
    this._phone = value;
  }

  private _phone: Phone;

  public get client_type(): Type {
    return this._client_type || new Type();
  }

  public set client_type(value: Type) {
    this._client_type = value;
  }

  private _client_type: Type;
  public addresses?: Address[];
  public masked_email: string;
  public readonly casts: Casts = {
    id: [CastTypes.Number],
    show_id: [CastTypes.Boolean, CastTypes.Undefined],
    email: [CastTypes.String],
    nickname: [CastTypes.Null, CastTypes.String],
    status: [CastTypes.Number],
    country_code: [CastTypes.Undefined, CastTypes.Number],
    photo: [CastTypes.Undefined, CastTypes.String],
    client_type: [CastTypes.Undefined, Type],
    created_at: [CastTypes.String],
    logged_at: [CastTypes.String],
    updated_at: [CastTypes.String],
    settings: [CastTypes.Undefined, Settings],
    info: [CastTypes.Undefined, Info],
    phone: [Phone, CastTypes.Undefined],
    addresses: [CastTypes.Undefined, [Address]],
    masked_email: [CastTypes.String],
    verification_level: [CastTypes.Null, VerificationLevel],
    manager: [CastTypes.Undefined, Manager],
    allow_change_userpic: [CastTypes.Undefined, CastTypes.Boolean],
    allow_change_nickname: [CastTypes.Undefined, CastTypes.Boolean],
    allow_show_email: [CastTypes.Undefined, CastTypes.Boolean],
  };

  public getAddress(type = EAddressType.Residential): Address {
    const defaultAddress = new Address();

    defaultAddress.type = type;

    if (!this.addresses) {
      return defaultAddress;
    }

    return (
      this.addresses.find(address => {
        return address.type === type;
      }) || defaultAddress
    );
  }
}
