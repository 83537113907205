import {WebSocketResponse} from '@app/core/models/web-socket-response';
import {THttpHeaders} from '@app/core/types/types';
import Echo from 'laravel-echo';
import {Channel} from 'laravel-echo/dist/channel';
import {defer, Observable, Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import io from 'socket.io-client';

export class EchoHelper {
  public static create(apiUrl: string, authHeaders?: THttpHeaders): Echo {
    return new Echo({
      broadcaster: 'socket.io',
      client: io,
      host: apiUrl,
      enabledTransports: ['ws', 'wss'],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      auth: {headers: {'i-am-api': '', ...authHeaders}},
      reconnectionAttempts: 5,
    });
  }

  public static listenEvent(channel: Channel, eventName: string): Observable<WebSocketResponse> {
    return defer(() => {
      const subject$ = new Subject<WebSocketResponse>();
      const event = `.${eventName}`;
      channel.listen(event, (res: WebSocketResponse) => subject$.next(res));

      return subject$.pipe(finalize(() => subject$.complete()));
    });
  }
}
