/* eslint-disable @typescript-eslint/naming-convention */
import {EDynamicChildren} from '@app/core/menu/enum/dynamic-children.enum';
import {IMenuPointRawData} from '@app/core/menu/types/menu-point-raw-data';
import {PlatformConfig} from '@app/core/models/account/platform-config';
import {IGridsterWidgetsConfig} from '@app/core/models/gridster';
import {IDashboards} from '@app/core/models/gridster/gridster-config';
import {EInvestmentPlatformName} from '@app/investment-platform/common/enum';
import {Class} from '@app/shared/decorators/class.decorator';
import {EThemeMode} from '@app/shared/modules/theme/entities/theme-mode';
import {plainToInstance} from 'class-transformer';

export class AppBuild {
  /** @description Application version (git tag). */
  public appVersion = process.env.appVersion;

  /** @description Reset caching for requests. */
  public cacheOff = process.env.cacheOff;

  /** @description Map for themes. */
  public themeMap: {[key: string]: string};

  /** @description Add customer scripts to assets - customer-scripts.js. */
  public isAddCustomerScripts: boolean;

  /** @description Changing this flag you can use logo images in format different from svg. */
  public clientFullLogoFormat = 'svg';
}

export class CustomerFlags {
  /** @description Set custom logotype for selected payment method. */
  public isCustomPaymethodLogo?: boolean;

  /** @description Hide wallet balance on deposit page. */
  public isHideWalletBalanceInDeposit?: boolean;

  /** @description Hide rate on exchange page. */
  public isHideRateInExchange?: boolean;

  /** @description Hide delete account block. */
  public isAccountUnremovable?: boolean;

  /** @description Change wallet list view to table by default. */
  public isTableViewByDefaultForWalletList?: boolean;

  /** @description Disable change theme. */
  public isChangeThemeDisabled?: boolean;

  /** @description Set custom logotype for account in internal transfer. */
  public isCustomTransferAccountLogo?: boolean;

  /** @description Hide logo on external-layout page. */
  public isHiddenExternalLogo?: boolean;
}

export class ProjectInfo {
  public name: string;
  public fullName: string;
  public defaultTheme: EThemeMode;
  public contactsPhone?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public linkKYC?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public linkTwoFA_Instruction?: boolean;
  public customCountryIso?: string;
  public defaultLanguage = 'en';
}

export class Link {
  public url: string;
  public label: string;
  public target?: string;
  public icon?: LinkIcon;
}

export class WalletLink {
  public url?: string;
  public target?: string;
  public isOutside?: boolean;
}

export class WalletMenu {
  @Class(WalletLink, true)
  public exchange?: WalletLink;

  @Class(WalletLink, true)
  public payment?: WalletLink;

  @Class(WalletLink, true)
  public payout?: WalletLink;

  @Class(WalletLink, true)
  public statementB2Margin?: WalletLink;

  @Class(WalletLink, true)
  public tradeB2Trader?: WalletLink;

  @Class(WalletLink, true)
  public tradeB2Margin?: WalletLink;

  @Class(WalletLink, true)
  public transfer?: WalletLink;
}

export class MenuLink {
  public url: string;
  public label: number | string;
  public icon?: LinkIcon;
  public externalUrl?: string;
  public target?: string;
  public isNew?: boolean;
  public isRequired?: boolean;
}

export class MenuPoint {
  public static createInstance(data: IMenuPointRawData): MenuPoint {
    return plainToInstance(MenuPoint, data);
  }

  @Class(MenuLink)
  public field: MenuLink;

  @Class(MenuPoint, true)
  public children?: MenuPoint[];

  public dynamicChildren?: EDynamicChildren;
  public isAvailableForHomeUrl?: boolean;
}

class AnalyticsConfig {
  public limit = 10;
  public pageSize = [10, 20, 30, 40];
}

export class ChartColorScheme {
  public demo: string[];
  public live: string[];
}

export class HelpDeskConfig {
  public accept =
    '.xml, .zip, .gz, .pdf, .swf, .jpeg, .jpg, .png, .gif, .asf, .avi, .flv, .wmv, .m4v, .mov, .mp4, .mpeg, .txt,';
  public limitMessages = 10;
  public limitTickets = 10;
}

export class ExternalResources {
  public registrationUrl?: string;
  public restoreUrl?: string;
}

export class Environment {
  private static self: Environment;

  public static getSelf(): Environment {
    return Environment.self;
  }

  public isPublic = false;
  public isB2traderPublicStandalone = false;
  public isB2TraderStandalone = false;
  public isEqwireProject = false;
  public isPbsVendor = false;
  public hasPossibilityToSwitchInterface = false;

  // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
  public locale: string;

  @Class(AppBuild)
  public appBuild: AppBuild;

  public apiUrl: string;
  public homeUrl: string;

  public amplitudeApiKey?: string;
  public rudderStackConfig?: {
    writeKey: string;
    dataplaneUrl: string;
  };

  public weblateApiUrl = 'https://api-weblate.flexprotect.org';
  public weblateProjectUuid: string;
  public weblateStory = 'production';

  public registrationUrl?: string;
  public referralQueryParamKey = 'referral';
  public referralCxdTokenQueryParamKey = 'utm_cxd';
  public isSupportQrLogin = true;
  public isShowInvestmentPlatformStandaloneAgreementCheckBox = false;
  public isLanguageSelectEnabled = true;
  public pbsrIconUrl?: string;
  public ibRoomInitialPeriodOfReportsFilter: 'day' | 'week' = 'week';

  @Class(ExternalResources, true)
  public externalResources?: ExternalResources;

  @Class(CustomerFlags, true)
  public customerFlags?: CustomerFlags;

  public logoutTimeoutInMs = 20 * 60 * 1000;
  public snackBarDurationInMs = 5 * 1000;
  public requestTimeoutInMs = 30 * 1000;
  public socketConnectionTimeoutInMs = 30 * 1000;

  public accessAliases?: AccessAlias[];

  @Class(ProjectInfo)
  public projectInfo: ProjectInfo;

  @Class(Link, true)
  public socialLink?: Link[];

  @Class(WalletMenu)
  public walletMenu: WalletMenu;

  @Class(MenuPoint, true)
  public menu: MenuPoint[];

  @Class(AnalyticsConfig)
  public analyticsConfig: AnalyticsConfig;

  @Class(ChartColorScheme, true)
  public chartColors: ChartColorScheme;

  @Class(HelpDeskConfig)
  public helpDeskConfig: HelpDeskConfig;

  @Class(PlatformConfig, true)
  public platforms: PlatformConfig[];

  public investmentConfig?: IInvestmentConfig;

  public quickLinksList: MenuLink[];
  public widget: WidgetConfig;
  public dashboards?: IDashboards;

  public b2traderPlatformName = 'b2trader';

  constructor() {
    Environment.self = this;
  }

  public getGridsterConfig(provider: string): IGridsterWidgetsConfig {
    return this.dashboards[provider];
  }

  public getGridsterWidgets(provider: string): IGridsterWidgetsConfig {
    return this.dashboards[provider].widgets;
  }
}

export interface IIconsList {
  'light-theme': {
    [key: string]: string;
  };
  'dark-theme': {
    [key: string]: string;
  };
}

export enum EWalletMenuKey {
  Payment = 'payment',
  Payout = 'payout',
  Exchange = 'exchange',
  Transfer = 'transfer',
  TradeB2Trader = 'tradeB2Trader',
  TradeB2Margin = 'tradeB2Margin',
}

export enum LinkIcon {
  corporate = 'corporate',
  deposit = 'deposit',
  doc = 'doc',
  exchange = 'exchange',
  funds = 'funds',
  history = 'history',
  orders = 'orders',
  profile = 'profile',
  settings = 'settings',
  personalInfo = 'personalInfo',
  trade = 'trade',
  transactions = 'transactions',
  transfer = 'transfer',
  trendingUp = 'trending-up',
  verification = 'verification',
  wallet = 'wallet',
  withdraw = 'withdraw',
  mt = 'mt',
  mt4 = 'mt4',
  mt5 = 'mt5',
  partner = 'partner',
  partnerReferral = 'partner',
  partnerRewards = 'history',
  bonus = 'rocket',
  helpdesk = 'helpdesk',
  facebook = 'facebook',
  twitter = 'twitter',
  linkedin = 'linkedin',
  medium = 'medium',
  reddit = 'reddit',
  telegram = 'telegram',
  analytics = 'analytics',
  b2copy = 'b2copy',
  pamm = 'pamm',
  edu = 'edu',
  key = 'key',
  youtube = 'youtube',
  payoutWhiteList = 'payout-white_list',
  currency = 'currency',
  gift = 'gift',
  arrowDown = 'arrow-in-circle',
  arrowUp = 'arrow-up-in-circle',
  gear = 'settings-stroke',
  shield = 'verification-stroke',
  advui = 'advui',
  dashboard = 'dashboard',
  leaderboard = 'leaderboard',
  promo = 'promo',
  finance = 'finance',
  security = 'security',
  markets = 'markets',
  statement = 'statement',
  checkList = 'check-list',
  file = 'file',
  catalog = 'catalog',
  helpAndFaq = 'helpAndFaq',
  tradeIdeas = 'tradeIdeas',
  mam = 'sandbox',
  acquisition = 'acquisition',
  helpWithCircle = 'help-with-circle',
  archive = 'archive',
  sandbox = 'sandbox',
  briefcase = 'briefcase',
  transactionsOperation = 'transactions-operation',
  helpCircle = 'help-circle',
  exchangeSquared = 'exchange-squared',
  bonusActive = 'bonus-active',
  sab = 'sab',
  references = 'references',
  pendingMoney = 'pending-money',
  tradeLocker = 'trade-locker',
}

export enum AccessAlias {
  ApiKeyManagement = 'ApiKeyManagement',
  Analytics = 'Analytics',
  AnalyticsMT4 = 'AnalyticsMT4',
  AnalyticsMT5 = 'AnalyticsMT5',
  AnalyticsCTrader = 'AnalyticsCTrader',
  B2Copy = 'B2Copy',
  Bonuses = 'Bonuses',
  Centroid = 'Centroid',
  Conversion = 'Conversion',
  ConversionPayment = 'ConversionPayment',
  ConversionPayout = 'ConversionPayout',
  ConversionTransfer = 'ConversionTransfer',
  Eqwire = 'Eqwire',
  Documents = 'Documents',
  Dashboard = 'Dashboard',
  Exchange = 'Exchange',
  ForceEnabled = 'ForceEnabled',
  Helpdesk = 'Helpdesk',
  History = 'History',
  InternalTransfer = 'InternalTransfer',
  IB = 'IB',
  OneZero = 'OneZero',
  Mam = 'Mam',
  Pamm = 'Pamm',
  PammNew = 'PammNew',
  Payment = 'Payment',
  Payout = 'Payout',
  Profile = 'Profile',
  ProfilePayoutWhiteList = 'ProfilePayoutWhiteList',
  ProfileSettings = 'ProfileSettings',
  ProfileTwoFactor = 'ProfileTwoFactor',
  ProfileLanguage = 'ProfileLanguage',
  ProfileSettingsCorporateAccount = 'ProfileSettingsCorporateAccount',
  ProfileVerificationInfo = 'ProfileVerificationInfo',
  ProfileVerificationDocument = 'ProfileVerificationDocument',
  ProfileVerificationLevel = 'ProfileVerificationLevel',
  ProfileTierFee = 'ProfileTierFee',
  ProfileSecurity = 'ProfileSecurity',
  ProfileFinance = 'ProfileFinance',
  PbsrEnrolmentAndTransfers = 'PbsrEnrolmentAndTransfers',
  PbsrBrokerageAccountEventsHistory = 'PbsrBrokerageAccountEventsHistory',
  PbsrOperationsAndOrders = 'PbsrOperationsAndOrders',
  PbsrBrokerageOrders = 'PbsrBrokerageOrders',
  PbsrDeposit = 'PbsrDeposit',
  PbsrTransfer = 'PbsrTransfer',
  PbsrWithdraw = 'PbsrWithdraw',
  PbsrProfilePersonalInfo = 'PbsrProfilePersonalInfo',
  PrimeXm = 'PrimeXm',
  Briefcase = 'Briefcase',
  PbsrVerification = 'PbsrVerification',
  PbsrReportsAndCertificates = 'PbsrReportsAndCertificates',
  Transactions = 'Transactions',
  TransactionsPayment = 'TransactionsPayment',
  PbsrCatalog = 'PbsrCatalog',
  PbsrHelpAndFaq = 'PbsrHelpAndFaq',
  PbsrTradeIdeas = 'PbsrTradeIdeas',
  PbsrNewsAndOverviews = 'PbsrNewsAndOverviews',
  Pbsr = 'Pbsr',
  PbsrTesting = 'PbsrTesting',
  TransactionsPayout = 'TransactionsPayout',
  TransactionsTransfer = 'TransactionsTransfer',
  TransactionsExchange = 'TransactionsExchange',
  TransactionsExternal = 'TransactionsExternal',
  Transfer = 'Transfer',
  Trade = 'Trade',
  TradeSettings = 'TradeSettings',
  TradeSettingsArchive = 'TradeSettingsArchive',
  TradeB2Margin = 'TradeB2Margin',
  TradeB2Trader = 'TradeB2Trader',
  TradingBoard = 'TradingBoard',
  VerificationPbsr = 'VerificationPbsr',
  PbsrDocuments = 'PbsrDocuments',
  Verification = 'Verification',
  MatchTrader = 'MatchTrader',
  Wallet = 'Wallet',
  DxTrade = 'DxTrade',
  TradeLocker = 'TradeLocker',
}

export enum ETradingExchange {
  B2Trade = 'B2Trade',
  DX = 'DX',
}

export class WidgetConfig implements IWidgetConfig {
  public DataFeedProvider: ETradingExchange;
  public colors: IWidgetColors;
  public defaultFavorites?: string[];
  public defaultInterval?: string;
  public defaultLayout?: IWorkspace2[];
  public defaultTheme: EThemeMode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public header?: any;
  public hideFee?: boolean;
  public leverages?: number[];
  public themeBidAsk?: IColorsBidAsk;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public tvSettings?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public widgetsList?: any;
}

export interface IWidgetConfig {
  doubleClick?: boolean;
  hideFee?: boolean;
  defaultLayout?: IWorkspace2[];
  DataFeedProvider: ETradingExchange;
  colors: IWidgetColors;
  defaultTheme: EThemeMode;
  themeBidAsk?: IColorsBidAsk;
  tvSettings?: unknown;
  header?: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widgetsList?: any;
  leverages?: number[];
  defaultFavorites?: string[];
  defaultInterval?: string;
}

export interface IColorsBidAsk {
  bid: string;
  ask: string;
}

export interface IWidgetColors {
  'light-theme': IWidgetTheme;
  'dark-theme': IWidgetTheme;
}

export interface IWidgetTheme {
  loadingScreen_background: string;
  toolbarBackground: string;
  mainBackground: string;
  gridColor: string;
  watermarkColor: string;
  scalesProperties_textColor: string;
  scalesProperties_lineColor: string;
  scalesProperties_background: string;
  upColor: string;
  downColor: string;
  lineStyle_color: string;
  areaStyle_color1: string;
  areaStyle_color2: string;
  areaStyle_lineColor: string;
  lineBuyBgColor: string;
  lineSellBgColor: string;
  bodyTextColor: string;
  mutedTextColor: string;
  MA_7: string;
  MA_21: string;
  MA_25: string;
  MA_99: string;
  MA_100: string;
  crossHairProperties_color: string;
}

export interface IWorkspace2 {
  title: string;
  root: string;
  nodes: Record<string, unknown>;
}

export enum EInvestmentStandaloneVersion {
  hasPaymentAccount = 2,
  withoutPaymentAccount = 3,
}

export interface IInvestmentConfig extends Record<Partial<EInvestmentPlatformName>, PlatformConfig[]> {
  standaloneVersion?: EInvestmentStandaloneVersion;
}
