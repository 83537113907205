import {CastTypes, Model} from '@app/core/model';

export class Manager extends Model {
  public email: string;
  public localizedName: string;
  public localizedTitle: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public updated_at: string;

  public readonly casts: {[p: string]: (CastTypes | CastTypes[] | typeof Model | typeof Model[])[]} = {
    email: [CastTypes.String],
    localizedName: [CastTypes.String],
    localizedTitle: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_at: [CastTypes.String],
  };
}
