import {Injectable} from '@angular/core';
import {WebSocketResponse} from '@app/core/models/web-socket-response';
import {EWsEvents, WebSocketService} from '@app/core/services/web-socket.service';
import {HelpdeskData} from '@app/helpdesk/models/helpdesk-data';
import {MessageNotificationEvent} from '@app/helpdesk/models/message-notification-event';
import {Ticket} from '@app/helpdesk/models/ticket';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NotificationsService {
  private static readonly UNAVAILABLE_PLATFORMS = ['iPhone', 'iPad', 'iPod'];

  private readonly destroyer$ = new Subject<void>();

  public get isPermissionGranted(): boolean {
    return Notification.permission === 'granted';
  }

  public get isPermissionUnset(): boolean {
    return Notification.permission === 'default';
  }

  constructor(
    private readonly webSocketService: WebSocketService,
    private readonly translateService: TranslateService,
  ) {}

  public subscribe(): void {
    this.destroyer$.next();

    this.webSocketService
      .private(EWsEvents.MessageCreated)
      .pipe(
        filter(() => this.isPermissionGranted),
        map(res => WebSocketResponse.Make(res)),
        takeUntil(this.destroyer$),
      )
      .subscribe(res => {
        const data = res.data as HelpdeskData;

        if (data.item instanceof MessageNotificationEvent) {
          const {ticket} = data.item;

          this.translateService
            .get('Core.Services.NotificationService.NewMessage')
            .subscribe((message: string) => this.showNotification(`ID: ${ticket.number} | ${ticket.subject}`, message));
        }
      });

    this.webSocketService
      .private(EWsEvents.TicketStatusChanged)
      .pipe(
        map(res => WebSocketResponse.Make(res)),
        takeUntil(this.destroyer$),
      )
      .subscribe(res => {
        const data = res.data as HelpdeskData;
        const ticket = data.item as Ticket;

        if (ticket) {
          this.translateService
            .get('Core.Services.NotificationService.StatusUpdated')
            .subscribe((status: string) => this.showNotification(`ID: ${ticket.number} | ${ticket.subject}`, status));
        }
      });
  }

  public unsubscribe(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public requestPermission(): Promise<NotificationPermission> {
    return Notification.requestPermission();
  }

  public showNotification(title: string, body: string): Notification {
    if (this.isPermissionGranted) {
      return new Notification(title, {body});
    }
  }

  public isAvailable(): boolean {
    // TODO: fix this deprecation functional (https://b2btech.atlassian.net/browse/FDP-9109)
    // eslint-disable-next-line deprecation/deprecation
    return !NotificationsService.UNAVAILABLE_PLATFORMS.includes(window.navigator.platform);
  }
}
