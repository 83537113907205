<div
  #optionContainer
  *ngFor="let option of options; trackBy: trackByValue"
  [id]="option.value"
  [htmlTooltip]="getTooltipComponent(option)"
  [optionId]="option.value"
  [isMobile]="isMobile"
  class="radio-chip"
>
  <input
    type="radio"
    [value]="option.value"
    [formControl]="control"
    [id]="field.name + '-' + option.value"
    [name]="field.name"
  />
  <label [for]="field.name + '-' + option.value">{{ option.name }}</label>
</div>
