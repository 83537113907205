import {merge, Observable, ReplaySubject} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';

import {AStorage} from './storages/storage';

/**
 * Manage settings by specific ID
 * It allows you to save, fetch and remove settings.
 */
export class SettingsManager<T> {
  private readonly localSettings$ = new ReplaySubject<T | undefined>(1);
  private readonly remoteSettings$ = this.storage.get<T>(this.settingsId);

  public readonly settings$ = merge(this.localSettings$, this.remoteSettings$).pipe(
    shareReplay({refCount: true, bufferSize: 1}),
  );

  constructor(private readonly settingsId: string, private readonly storage: AStorage) {}

  private setLocalSettings(value: T | undefined): void {
    this.localSettings$.next(value);
  }

  public set(value: T): Observable<boolean> {
    return this.storage.save<T>(this.settingsId, value).pipe(tap(() => this.setLocalSettings(value)));
  }

  public remove(): Observable<boolean> {
    return this.storage.remove(this.settingsId).pipe(tap(() => this.setLocalSettings(undefined)));
  }

  public destroy(): void {
    this.localSettings$.complete();
  }
}
