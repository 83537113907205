import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {AGridsterWidgetComponent} from '@app/gridster/widgets/gridster-widget.component.abstract';
import {inverseOfTranslation} from '@app/trading-board/utils/inverse-translation';
import {of, Subject} from 'rxjs';
import {delay, filter} from 'rxjs/operators';

@Component({template: ''})
export abstract class AGridsterViewPortWidgetComponent<T> extends AGridsterWidgetComponent<T> implements OnDestroy {
  protected abstract cdr: ChangeDetectorRef;
  public abstract viewPort: CdkVirtualScrollViewport;

  protected readonly destroyer$ = new Subject<void>();

  protected readonly resizeObserver = new ResizeObserver(() => this.checkViewportSizeAsync());

  protected constructor(protected attached$?: Subject<boolean>) {
    super();
    this.attached$?.pipe(filter((isAttached: boolean) => !isAttached && !!this.viewPort)).subscribe(() => {
      this.viewPort.scrollToIndex(0);
      this.viewPort.setRenderedContentOffset(0);
      this.cdr.detectChanges();
    });
  }

  /**
   * Check viewport size with small delay (asynchronous).
   */
  public checkViewportSizeAsync(): void {
    of(undefined)
      .pipe(delay(10))
      .subscribe(() => this.viewPort?.checkViewportSize());
  }

  public inverse(viewPort?: CdkVirtualScrollViewport): string {
    return inverseOfTranslation(viewPort ?? this.viewPort);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.attached$?.complete();
  }
}
