import {Injectable} from '@angular/core';
import {IAccountTradingData} from '@app/pbsr/interfaces/account-trading-data.interface';
import {HistoricalDataApiService} from '@app/pbsr/services/historical-data-api/historical-data-api.service';
import {WarpInstrumentsStoreService} from '@app/pbsr/services/warp-instruments-store/warp-instruments-store.service';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {EHistoricalOrderStatus} from '@app/trading-board/enum/moex/historical-order-status.enum';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {HistoricalOrder} from '@app/trading-board/models/moex/historical-order';
import {Subscription, BehaviorSubject, switchMap, combineLatest, of} from 'rxjs';

import {IWarpOrdersData} from '../../interfaces/warp-orders-data.interface';
import {WarpOrdersDataMapperService} from '../warp-orders-data-mapper/warp-orders-data-mapper.service';

@Injectable()
export class WarpOrdersStateService {
  private readonly subscription = new Subscription();

  private readonly orders = new Map<string, IWarpOrdersData>();
  private readonly stopOrders = new Map<string, IWarpOrdersData>();
  private readonly trades = new Map<string, IWarpOrdersData>();

  private readonly instruments = new Map<string, WarpInstrument>();

  public readonly orders$ = new BehaviorSubject<Map<string, IWarpOrdersData> | undefined>(undefined);
  public readonly trades$ = new BehaviorSubject<Map<string, IWarpOrdersData> | undefined>(undefined);

  constructor(
    private readonly moexDatafeedService: MoexDatafeedService,
    private readonly historicalDataApiService: HistoricalDataApiService,
    private readonly warpOrdersDataMapperService: WarpOrdersDataMapperService,
    private readonly warpInstrumentsStoreService: WarpInstrumentsStoreService,
  ) {}

  public requestData(accountsTradingData: IAccountTradingData[]): void {
    this.requestWarpOrders(accountsTradingData);
    this.requestWarpStopOrders(accountsTradingData);
    this.requestWarpTrades(accountsTradingData);

    this.requestHistoricalOrders(accountsTradingData);
    this.requestHistoricalTrades(accountsTradingData);

    this.subscribeOnOrdersUpdates(accountsTradingData);
    this.subscribeOnStopOrdersUpdates(accountsTradingData);
    this.subscribeOnTradesUpdates(accountsTradingData);
  }

  public terminate(): void {
    this.subscription.unsubscribe();
  }

  private requestWarpOrders(accountsTradingData: IAccountTradingData[]): void {
    const subscription = new Subscription();

    accountsTradingData.forEach(accountTradingData => {
      subscription.add(
        this.moexDatafeedService
          .getPortfolioOrders(accountTradingData)
          .pipe(
            switchMap(orders => {
              const symbols = orders.map(order => order.symbol);
              const instruments$ = this.warpInstrumentsStoreService.getInstruments(symbols);

              return combineLatest([instruments$, of(orders)]);
            }),
          )
          .subscribe(([instruments, orders]) => {
            this.setInstruments(instruments);

            orders.forEach(order => {
              const orderInstrument = this.instruments.get(order.symbol);

              this.orders.set(
                order.id,
                this.warpOrdersDataMapperService.warpOrderToOrdersData(order, orderInstrument, accountsTradingData),
              );

              this.updateOrders();
            });
          }),
      );
    });

    this.subscription.add(subscription);
  }

  private requestWarpStopOrders(accountsTradingData: IAccountTradingData[]): void {
    const subscription = new Subscription();

    accountsTradingData.forEach(accountTradingData => {
      subscription.add(
        this.moexDatafeedService
          .getPortfolioStopOrders(accountTradingData)
          .pipe(
            switchMap(stopOrders => {
              const symbols = stopOrders.map(stopOrder => stopOrder.symbol);
              const instruments$ = this.warpInstrumentsStoreService.getInstruments(symbols);

              return combineLatest([instruments$, of(stopOrders)]);
            }),
          )
          .subscribe(([instruments, stopOrders]) => {
            this.setInstruments(instruments);

            stopOrders.forEach(stopOrder => {
              const stopOrderInstrument = this.instruments.get(stopOrder.symbol);

              this.stopOrders.set(
                stopOrder.id,
                this.warpOrdersDataMapperService.warpStopOrderToOrdersData(
                  stopOrder,
                  stopOrderInstrument,
                  accountsTradingData,
                ),
              );

              this.updateOrders();
            });
          }),
      );
    });

    this.subscription.add(subscription);
  }

  private requestWarpTrades(accountsTradingData: IAccountTradingData[]): void {
    const subscription = new Subscription();

    accountsTradingData.forEach(accountTradingData => {
      subscription.add(
        this.moexDatafeedService
          .getPortfolioTrades(accountTradingData)
          .pipe(
            switchMap(trades => {
              const symbols = trades.map(trade => trade.symbol);
              const instruments$ = this.warpInstrumentsStoreService.getInstruments(symbols);

              return combineLatest([instruments$, of(trades)]);
            }),
          )
          .subscribe(([instruments, trades]) => {
            this.setInstruments(instruments);

            trades.forEach(trade => {
              const tradeInstrument = this.instruments.get(trade.symbol);

              this.trades.set(
                trade.id,
                this.warpOrdersDataMapperService.warpTradeToOrdersData(trade, tradeInstrument, accountsTradingData),
              );
            });

            this.trades$.next(this.trades);
          }),
      );
    });

    this.subscription.add(subscription);
  }

  private requestHistoricalOrders(accountsTradingData: IAccountTradingData[]): void {
    this.subscription.add(
      this.historicalDataApiService.getHistoricalOrders({isRequestWithoutPagination: true}).subscribe(result => {
        result.data.forEach(order => {
          if (!this.isHistoricalOrderAppropriate(order)) {
            return;
          }

          this.orders.set(
            order.id.toString(),
            this.warpOrdersDataMapperService.historicalOrderToOrdersData(order, accountsTradingData),
          );
        });

        this.updateOrders();
      }),
    );
  }

  private requestHistoricalTrades(accountsTradingData: IAccountTradingData[]): void {
    this.subscription.add(
      this.historicalDataApiService.getHistoricalTrades({isRequestWithoutPagination: true}).subscribe(result => {
        result.data.forEach(trade => {
          this.trades.set(
            trade.id.toString(),
            this.warpOrdersDataMapperService.historicalTradeToOrdersData(trade, accountsTradingData),
          );
        });

        this.trades$.next(this.trades);
      }),
    );
  }

  private subscribeOnOrdersUpdates(accountsTradingData: IAccountTradingData[]): void {
    const subscription = new Subscription();

    accountsTradingData.forEach(accountTradingData => {
      subscription.add(
        this.moexDatafeedService
          .subscribeToPortfolioOrders(accountTradingData)
          .pipe(
            switchMap(order => {
              const instrument$ = this.warpInstrumentsStoreService.getInstrument(order.symbol);

              return combineLatest([instrument$, of(order)]);
            }),
          )
          .subscribe(([instrument, order]) => {
            this.orders.set(
              order.id,
              this.warpOrdersDataMapperService.warpOrderToOrdersData(order, instrument, accountsTradingData),
            );

            this.updateOrders();
          }),
      );
    });

    this.subscription.add(subscription);
  }

  private subscribeOnStopOrdersUpdates(accountsTradingData: IAccountTradingData[]): void {
    const subscription = new Subscription();

    accountsTradingData.forEach(accountTradingData => {
      subscription.add(
        this.moexDatafeedService
          .subscribeToPortfolioStopOrders(accountTradingData)
          .pipe(
            switchMap(stopOrder => {
              const instrument$ = this.warpInstrumentsStoreService.getInstrument(stopOrder.symbol);

              return combineLatest([instrument$, of(stopOrder)]);
            }),
          )
          .subscribe(([instrument, stopOrder]) => {
            this.stopOrders.set(
              stopOrder.id,
              this.warpOrdersDataMapperService.warpStopOrderToOrdersData(stopOrder, instrument, accountsTradingData),
            );

            this.updateOrders();
          }),
      );
    });

    this.subscription.add(subscription);
  }

  private subscribeOnTradesUpdates(accountsTradingData: IAccountTradingData[]): void {
    const subscription = new Subscription();

    accountsTradingData.forEach(accountTradingData => {
      subscription.add(
        this.moexDatafeedService
          .subscribeToPortfolioTrades(accountTradingData)
          .pipe(
            switchMap(trades => {
              const instrument$ = this.warpInstrumentsStoreService.getInstrument(trades.symbol);

              return combineLatest([instrument$, of(trades)]);
            }),
          )
          .subscribe(([instrument, trades]) => {
            this.trades.set(
              trades.id,
              this.warpOrdersDataMapperService.warpTradeToOrdersData(trades, instrument, accountsTradingData),
            );

            this.trades$.next(this.trades);
          }),
      );
    });

    this.subscription.add(subscription);
  }

  private isHistoricalOrderAppropriate(order: HistoricalOrder): boolean {
    const status = order.historicalOrderStatus;

    return (
      status === EHistoricalOrderStatus.Deniled ||
      status === EHistoricalOrderStatus.Removed ||
      status === EHistoricalOrderStatus.RemovedByUser ||
      status === EHistoricalOrderStatus.Executed
    );
  }

  private updateOrders(): void {
    this.orders$.next(new Map([...this.orders, ...this.stopOrders]));
  }

  private setInstruments(instruments: WarpInstrument[]): void {
    instruments.forEach(instrument => this.instruments.set(instrument.symbol, instrument));
  }
}
