import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {IconModule} from '@app/icon/icon.module';
import {DirectionModule} from '@app/shared/modules/direction/direction.module';
import {SnackbarService} from '@app/snackbar/snackbar.service';

import {SnackbarComponent} from './snackbar.component';
import {TileComponent} from './tile/tile.component';

@NgModule({
  imports: [CommonModule, IconModule, MatSnackBarModule, DirectionModule],
  declarations: [SnackbarComponent, TileComponent],
  providers: [SnackbarService],
  exports: [SnackbarComponent],
})
export class SnackbarModule {}
