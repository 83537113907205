import {IInstrumentSummaryDto} from '@app/trading-board/interfaces/b2trader/instrument-summary-dto.interface';
import {IOrder} from '@app/trading-board/interfaces/b2trader/order';
import {IOrderBookInfoDto} from '@app/trading-board/interfaces/b2trader/order-book-info-dto.interface';
import {IServerTimeInfo} from '@app/trading-board/interfaces/b2trader/server-time-info';
import {IStopOrder} from '@app/trading-board/interfaces/b2trader/stop-order';
import {B2traderStopOrder} from '@app/trading-board/models/b2trader/b2trader-stop-order';
import {plainToInstance} from 'class-transformer';
import {BehaviorSubject, combineLatest, interval, Subject, Subscription} from 'rxjs';
import {filter, map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';

import {DATA_FEED_DEBOUNCE_IN_MS} from '../../core/constants/common';
import {betterThrottle} from '../../core/utils/better-throttle';
import {EB2TraderEventHandlersReceived} from '../event-handlers/b2trader/b2trader-event-handlers.enum';
import {IB2TraderInstrument} from '../interfaces/b2trader/instrument';
import {IDataEvent} from '../interfaces/data-event.ts';
import {ITick} from '../interfaces/tick';
import {B2TraderOrder} from '../models/b2trader/b2-trader-order';
import {B2TraderInstrument} from '../models/instrument';
import {Tick} from '../models/level1';
import {Level2} from '../models/level2';

export class B2traderStateStore {
  private readonly exchangeTimeTickDestroyer$ = new Subject<void>();
  private readonly exchangeTimeUpdateIntervalMs = 1000;

  private static reduceInstruments(instruments: IB2TraderInstrument[]): Map<string, IB2TraderInstrument> {
    return instruments.reduce((acc, item) => {
      acc.set(item.nativeSymbol, item);

      return acc;
    }, new Map());
  }

  public instruments$ = new BehaviorSubject<IB2TraderInstrument[]>([]);
  public level1$ = new Subject<IInstrumentSummaryDto[]>();
  public level2$ = new Subject<Partial<IOrderBookInfoDto>>();
  public level2Destroyers: Map<string, Subject<void>> = new Map();
  public charts = new Map<string, Subscription>();
  public orders$ = new BehaviorSubject<Map<string, IOrder> | null>(new Map());
  public stopOrders$ = new BehaviorSubject<Map<number, IStopOrder> | null>(new Map());

  public readonly serverTime$ = new BehaviorSubject<IServerTimeInfo | null>(null);

  public trades = new Map<string, Subscription>();

  public messages$ = new Subject<IDataEvent>();

  public orderStore$ = combineLatest([this.orders$, this.instruments$]).pipe(
    map(([orders, instruments]) =>
      orders
        ? plainToInstance(B2TraderOrder, B2TraderOrder.makeOrderParam(orders, instruments), {
            excludeExtraneousValues: true,
          })
        : null,
    ),
    shareReplay(1),
  );

  public readonly stopOrderStore$ = combineLatest([this.stopOrders$, this.instruments$]).pipe(
    map(([orders, instruments]) =>
      orders
        ? plainToInstance(B2traderStopOrder, B2traderStopOrder.makeOrderParam(orders, instruments), {
            excludeExtraneousValues: true,
          })
        : null,
    ),
    shareReplay(1),
  );

  constructor() {
    this.instruments$
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),

        filter((instruments: IB2TraderInstrument[]) => !!instruments.length),
        map(instruments => plainToInstance(B2TraderInstrument, instruments)),
        shareReplay(1),
      )
      .subscribe((instruments: B2TraderInstrument[]) => {
        this.messages$.next({type: EB2TraderEventHandlersReceived.Instruments, payload: instruments});
      });

    combineLatest([this.level1$, this.instruments$])
      .pipe(
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        map(([ticks, instruments]: [IInstrumentSummaryDto[], IB2TraderInstrument[]]): ITick[] =>
          Tick.fromB2Trader(ticks, B2traderStateStore.reduceInstruments(instruments)),
        ),
        map((ticks: ITick[]): Tick[] => plainToInstance(Tick, ticks)),
      )
      .subscribe((payload: Tick[]) => this.messages$.next({type: EB2TraderEventHandlersReceived.Level1, payload}));

    combineLatest([this.level2$, this.instruments$.pipe(map(B2traderStateStore.reduceInstruments))])
      .pipe(
        map(([book, instruments]: [Partial<IOrderBookInfoDto>, Map<string, IB2TraderInstrument>]) =>
          plainToInstance(Level2, Level2.fromB2Trader(book, instruments.get(book.instrument))),
        ),
        map(Level2.afterTransformItem),
      )
      .subscribe((payload: Level2) => {
        this.messages$.next({type: EB2TraderEventHandlersReceived.Level2, payload});
      });

    this.orderStore$.subscribe(orders => {
      this.messages$.next({
        type: EB2TraderEventHandlersReceived.Orders,
        payload: orders,
      });
    });

    this.stopOrderStore$.subscribe(orders => {
      this.messages$.next({
        type: EB2TraderEventHandlersReceived.StopOrders,
        payload: orders,
      });
    });

    this.serverTime$
      .pipe(
        filter(value => value !== null),
        switchMap(serverTime =>
          interval(this.exchangeTimeUpdateIntervalMs).pipe(
            map(() => {
              serverTime.serverTime += this.exchangeTimeUpdateIntervalMs;

              return serverTime;
            }),
            takeUntil(this.exchangeTimeTickDestroyer$),
          ),
        ),
      )
      .subscribe(serverTime => {
        this.messages$.next({
          type: EB2TraderEventHandlersReceived.ServerTime,
          payload: {
            serverTime: serverTime.serverTime,
            exchangeTimeOffset: serverTime.exchangeTimeOffset,
          } as IServerTimeInfo,
        });
      });
  }

  public reset(): void {
    this.instruments$.next([]);
    this.level2Destroyers.forEach(i => {
      i.next();
      i.complete();
    });
    this.level2Destroyers.clear();
    this.charts.forEach(i => i.unsubscribe());
    this.charts.clear();

    this.orders$.next(new Map());
    this.stopOrders$.next(new Map());

    this.trades.forEach(i => i.unsubscribe());
    this.trades.clear();

    this.exchangeTimeTickDestroyer$.next();
  }
}
