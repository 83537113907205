import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Injector,
  NgModule,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import {IWidgetSettings} from '@app/core/models/gridster/gridster-item';
import {GridsterWidgetSettings} from '@app/core/models/gridster/gridster-widget-settings';
import {MANAGE_VIEW_REF_TOKEN} from '@app/gridster/tokens/manage-view-ref-token';
import {AGridsterViewPortWidgetComponent} from '@app/gridster/widgets/gridster-view-port-widget-component.abstract';
import {AComponentResolver, IReplaceableComponent} from '@app/trading-board/services/component-resolver.abstract';
import {ParentComponentProvider} from '@app/trading-board/services/parent-component-provider';
import {Subject} from 'rxjs';

import {OrderBookSettings} from './order-book-settings';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBookComponent extends AGridsterViewPortWidgetComponent<OrderBookSettings> implements OnInit {
  public readonly viewPort: CdkVirtualScrollViewport;

  public readonly askColor = 'rgba(254, 107, 107, 0.15)';
  public readonly bidColor = 'rgba(0, 162, 117, 0.15)';

  constructor(
    private readonly injector: Injector,
    public readonly container: ViewContainerRef,
    protected readonly cdr: ChangeDetectorRef,
    @Optional() private readonly componentResolver?: AComponentResolver,
    @Optional() @Inject(MANAGE_VIEW_REF_TOKEN) protected readonly attached$?: Subject<boolean>,
  ) {
    super(attached$);
  }

  public async ngOnInit(): Promise<void> {
    await this.providePlatformView();
  }

  public async providePlatformView(): Promise<void> {
    const factoryFn = this.componentResolver?.getComponent<IReplaceableComponent>(OrderBookComponent);

    if (factoryFn) {
      const {entryPoint} = await factoryFn();

      const component = this.container.createComponent(entryPoint, {
        index: undefined,
        injector: Injector.create({
          providers: [{provide: ParentComponentProvider, useExisting: forwardRef(() => OrderBookComponent)}],
          parent: this.injector,
        }),
      });

      component.changeDetectorRef.detectChanges();
    }
  }

  public getDefaultSettings(): GridsterWidgetSettings {
    return OrderBookSettings.getInstance();
  }

  public makeSettings(config: IWidgetSettings): GridsterWidgetSettings {
    return OrderBookSettings.makeInstance(config);
  }
}

@NgModule({
  declarations: [OrderBookComponent],
})
export class OrderBookModule {}
