import {Injectable} from '@angular/core';
import {fromEvent, merge} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private readonly _offline = fromEvent(window, 'offline');
  private readonly _online = fromEvent(window, 'online');

  public readonly offLine = this._offline.pipe(shareReplay());
  public readonly state = merge(this._online, this._offline).pipe(
    map(() => navigator.onLine),
    shareReplay(),
  );
}
