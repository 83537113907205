import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Environment} from '@env/environment.entities';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  constructor(public environment: Environment) {}

  @Input()
  public icon: string;
}
