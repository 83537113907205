import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {AutofocusDirective} from '@app/core/form/entities/form.autofocus';
import {OptionsService} from '@app/core/form/services/options.service';
import {AppFileInputModule} from '@app/core/ui/app.file.input/app.file.input.module';
import {IconModule} from '@app/icon/icon.module';
import {HtmlTooltipModule} from '@app/pbsr/directives/html-tooltip/html-tooltip.module';
import {TooltipIfLongLabelModule} from '@app/shared/directives/tooltip-if-long-label.directive';
import {DirectionModule} from '@app/shared/modules/direction/direction.module';
import {SharedModule} from '@app/shared/shared.module';
import {EllipsisContainerModule} from '@b2broker/ellipsis-container';
import {FlagIconModule} from '@b2broker/icons';
import {TranslateModule} from '@ngx-translate/core';
import {NgxMaskModule} from 'ngx-mask';

import {CaptchaComponent} from './components/captcha/captcha.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {CodeComponent} from './components/code/code.component';
import {DateComponent} from './components/date/date.component';
import {FeeSelectComponent} from './components/fee-select/fee-select.component';
import {FileButtonComponent} from './components/file-button/file-button.component';
import {FileMessageComponent} from './components/file/file-message/file-message.component';
import {FileComponent} from './components/file/file.component';
import {MultipleFilesComponent} from './components/file/multiple-files/multiple-files.component';
import {FormGroupComponent} from './components/form.group/form.group.component';
import {FormComponent} from './components/form/form.component';
import {GroupListComponent} from './components/group-list/group-list.component';
import {InputComponent} from './components/input/input.component';
import {PasswordButtonComponent} from './components/password.button/password.button.component';
import {PhoneComponent} from './components/phone/phone.component';
import {QuestionComponent} from './components/question/question.component';
import {RadioInputComponent} from './components/radio-input/radio-input.component';
import {RadioButtonComponent} from './components/radio.button/radio.button.component';
import {RadioChipsComponent} from './components/radio.chips/radio.chips.component';
import {RadioComponent} from './components/radio/radio.component';
import {RenderComponent} from './components/render/render.component';
import {RequiredIndicatorComponent} from './components/required-indicator/required-indicator.component';
import {SelectSearchComponent} from './components/select/select-search/select-search.component';
import {SelectComponent} from './components/select/select.component';
import {TextfieldComponent} from './components/textfield/textfield.component';
import {ToggleButtonComponent} from './components/toggle-button/toggle-button.component';
import {FieldAttributesDirective} from './entities/field-attributes.directive';
import {ConfigFormService} from './services/config.form.service';
import {LabelService} from './services/label.service';

const MaskModule = NgxMaskModule.forRoot({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  showTemplate: true,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  validation: false,
  dropSpecialCharacters: ['(', ')', '-', ' ', '@', '.', '+'],
});

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    MaskModule,
    IconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatRadioModule,
    DirectionModule,
    AppFileInputModule,
    MatIconModule,
    MatMenuModule,
    ScrollingModule,
    FlagIconModule,
    EllipsisContainerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    TooltipIfLongLabelModule,
    MatTooltipModule,
    HtmlTooltipModule,
  ],
  declarations: [
    FormGroupComponent,
    FormComponent,
    RenderComponent,
    FileComponent,
    FileButtonComponent,
    AutofocusDirective,
    CheckboxComponent,
    SelectComponent,
    InputComponent,
    PhoneComponent,
    PasswordButtonComponent,
    RadioComponent,
    RadioButtonComponent,
    RadioChipsComponent,
    DateComponent,
    CodeComponent,
    QuestionComponent,
    TextfieldComponent,
    CaptchaComponent,
    RadioInputComponent,
    ToggleButtonComponent,
    GroupListComponent,
    ToggleButtonComponent,
    MultipleFilesComponent,
    FileMessageComponent,
    SelectSearchComponent,
    FeeSelectComponent,
    FieldAttributesDirective,
    RequiredIndicatorComponent,
  ],
  providers: [
    ConfigFormService,
    LabelService,
    OptionsService,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        disableTooltipInteractivity: true,
      },
    },
  ],
  exports: [
    FormComponent,
    FormGroupComponent,
    ReactiveFormsModule,
    RenderComponent,
    FileComponent,
    FileButtonComponent,
    DateComponent,
    SelectComponent,
    GroupListComponent,
    MultipleFilesComponent,
  ],
})
export class FormModule {}
