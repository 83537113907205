import {Injectable} from '@angular/core';
import {MyAccountsPortfolioService} from '@app/pbsr/services/my-accounts-portfolio/my-accounts-portfolio.service';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {EBaseCurrency} from '@app/trading-board/enum/moex/base-currency';
import {IParentAccount} from '@app/trading-board/interfaces/moex/moex-accounts-info';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import {Tick} from '@app/trading-board/models/level1';
import {DecimalHelper} from '@b2broker/decimal-helper';
import * as _ from 'lodash-es';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {switchMap, tap, takeUntil} from 'rxjs/operators';

import {WarpInstrumentsStoreService} from '../warp-instruments-store/warp-instruments-store.service';

@Injectable({providedIn: 'root'})
export class AccountManagerService {
  private readonly parentAccountsUpdatesDestroyer$ = new Subject<void>();
  private readonly activeWidgetsIds = new Set<string>();

  private parentAccountUpdatesSubscription: Subscription;

  public readonly parentAccountsRub$ = new BehaviorSubject<IParentAccount[] | undefined>(undefined);
  public readonly parentAccountsUsd$ = new BehaviorSubject<IParentAccount[] | undefined>(undefined);
  public readonly parentAccountsEur$ = new BehaviorSubject<IParentAccount[] | undefined>(undefined);

  public readonly quotes$ = new BehaviorSubject<Map<string, Tick>>(new Map());

  constructor(
    private readonly datafeedService: MoexDatafeedService,
    private readonly myAccountsPortfolioService: MyAccountsPortfolioService,
    private readonly warpInstrumentsStoreService: WarpInstrumentsStoreService,
  ) {}

  public getTotalPrice(accounts: IParentAccount[]): DecimalHelper {
    return accounts.reduce((sum, {totalPrice}) => sum.plus(totalPrice.value), DecimalHelper.from(0));
  }

  public subscribeOnSingleAccountChanges(accounts: IParentAccount[]): void {
    if (this.parentAccountUpdatesSubscription) {
      return;
    }

    this.datafeedService.connectionClosed$
      .pipe(takeUntil(this.parentAccountsUpdatesDestroyer$))
      .subscribe(() => (this.parentAccountUpdatesSubscription = undefined));

    let currentQuotes: Map<string, Tick>;

    this.parentAccountUpdatesSubscription = this.updatePortfoliosSummary(accounts)
      .pipe(
        tap(quotes => (currentQuotes = quotes)),
        switchMap(() => this.warpInstrumentsStoreService.getInstrumentsForPortfolio(Array.from(currentQuotes.keys()))),
        tap(instrument => {
          this.parentAccountsRub$.next(
            this.getUpdatedAccountsByBaseCurrency(accounts, currentQuotes, EBaseCurrency.Rub, instrument),
          );
          this.parentAccountsUsd$.next(
            this.getUpdatedAccountsByBaseCurrency(accounts, currentQuotes, EBaseCurrency.Usd, instrument),
          );
          this.parentAccountsEur$.next(
            this.getUpdatedAccountsByBaseCurrency(accounts, currentQuotes, EBaseCurrency.Eur, instrument),
          );

          this.quotes$.next(currentQuotes);
        }),
        takeUntil(this.parentAccountsUpdatesDestroyer$),
      )
      .subscribe();
  }

  public addWidgetId(id: string): void {
    this.activeWidgetsIds.add(id);
  }

  public removeWidgetId(id: string): void {
    this.activeWidgetsIds.delete(id);

    if (this.activeWidgetsIds.size === 0) {
      this.parentAccountsUpdatesDestroyer$.next();
      this.parentAccountUpdatesSubscription = undefined;
    }
  }

  private updatePortfoliosSummary(accounts: IParentAccount[]): Observable<Map<string, Tick>> {
    return this.myAccountsPortfolioService.updatePortfolioPositions(accounts);
  }

  private getUpdatedAccountsByBaseCurrency(
    accounts: IParentAccount[],
    quotes: Map<string, Tick>,
    currency: EBaseCurrency,
    instruments: WarpInstrument[],
  ): IParentAccount[] {
    const accountsWitnBaseCurrency = this.getAccounsWithBaseCurrency(accounts, currency);

    return this.myAccountsPortfolioService.updatePortfoliosTotals(accountsWitnBaseCurrency, quotes, instruments);
  }

  private getAccounsWithBaseCurrency(accounts: IParentAccount[], currency: EBaseCurrency): IParentAccount[] {
    const clone = _.cloneDeep(accounts);

    clone.forEach(account => {
      account.baseCurrency = currency;

      account.subAccounts.forEach(subAccount => (subAccount.baseCurrency = currency));
    });

    return clone;
  }
}
