import {AB2marginPopupMessage} from '@app/trading-board/models/b2margin/b2margin-popup-message';
import {EB2marginPopupType} from '@app/trading-board/models/b2margin/b2margin-popup-type';
import {AccountTO, MessageTO} from '@b2broker/b2margin-trade-models';

export class B2marginLiquidationMessage extends AB2marginPopupMessage {
  public static adapt(
    message: MessageTO,
    accounts: Map<string, AccountTO>,
  ): {
    accountCode: unknown;
    baseCurrency: string;
    date: Date;
  } {
    const account = accounts.get(message.parametersTO.accountCode);

    return {
      accountCode: message.parametersTO.accountCode,
      baseCurrency: account.baseCurrency,
      date: new Date(message.timeStamp),
    };
  }

  public type = EB2marginPopupType.LongMessage;

  public accountCode: string;
  public baseCurrency: string;

  public date: Date;
}
