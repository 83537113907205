import {IGroupConfiguration} from '../interfaces/group-configuration';

// Because keys of the object are string information from backend
/* eslint-disable @typescript-eslint/naming-convention */
export const GroupsConfiguration: Record<string, IGroupConfiguration> = {
  Crypto: {
    index: 0,
    subgroups: {
      Bitcoin: 0,
      Ethereum: 1,
      Ripple: 2,
      Litecoin: 3,
      'Binance Coin': 4,
      'Bitcoin Cash': 5,
    },
  },
  Forex: {
    index: 1,
    subgroups: {
      Majors: 0,
      Minors: 1,
      Exotic: 2,
    },
  },
  Metals: {index: 2},
  Indices: {index: 3},
  Energy: {index: 4},
  Equities: {
    index: 5,
    subgroups: {
      'Shares US': 0,
      'Shares Europe': 1,
      'Shares Asia': 2,
      'Shares Russia': 3,
    },
  },
  ETF: {index: 6},
};
/* eslint-enable @typescript-eslint/naming-convention */
