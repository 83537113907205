import {Expose, Type} from 'class-transformer';

import {Bar} from '../../models/bar';

export class ChartResponse {
  @Expose()
  public symbol: string;

  @Expose()
  @Type(() => Bar)
  public bar: Bar;
}
