import {Injectable} from '@angular/core';
import {ConnectionService} from '@app/core/connection.service';
import {InterceptorSkipHeader} from '@app/core/services/exception.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {AStorage} from './storage';

@Injectable()
export class ApiStorageService extends AStorage {
  private readonly baseUrl = '/api/v1/ui-settings';

  constructor(private readonly connection: ConnectionService) {
    super();
  }

  private detailsUrl(key: string): string {
    return `${this.baseUrl}/${key}`;
  }

  public get<T>(key: string): Observable<T | undefined> {
    const headers = {[InterceptorSkipHeader]: ''};

    return this.connection.get<{data: string; status: number}>(this.detailsUrl(key), undefined, headers).pipe(
      map(response => {
        if (response.status !== 200) {
          return undefined;
        }
        try {
          return JSON.parse(response.data) as T;
        } catch (e) {
          return undefined;
        }
      }),
      catchError(() => of<T | undefined>(undefined)),
    );
  }

  public save<T>(key: string, value: T): Observable<boolean> {
    const payload = {
      key,
      value: JSON.stringify(value),
    };
    return this.connection.put<{status: number}>(this.baseUrl, payload).pipe(
      map(putSettingsResponse => putSettingsResponse.status === 200),
      catchError(() => of(false)),
    );
  }

  public remove(key: string): Observable<boolean> {
    return this.connection.delete<{status: number}>(this.detailsUrl(key)).pipe(
      map(putSettingsResponse => putSettingsResponse.status === 200),
      catchError(() => of(false)),
    );
  }
}
