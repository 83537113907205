import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ImgComponent} from '@app/img/img.component';
import {FallbackImageModule} from '@app/shared/directives/fallback-image/fallback.image.module';

@NgModule({
  imports: [CommonModule, FallbackImageModule],
  declarations: [ImgComponent],
  exports: [ImgComponent],
})
export class ImgModule {}
