import {ConnectionPositionPair, FlexibleConnectedPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {DEBOUNCE_TIME_IN_MS} from '@app/core/constants/common';
import {WarpSearchInstrumentService} from '@app/pbsr/services/warp-instruments/warp-search-instruments.service';
import {ThemeService} from '@app/shared/modules/theme/services/theme.service';
import {WarpInstrument} from '@app/trading-board/models/instrument';
import * as _ from 'lodash-es';
import {Observable, Subject, combineLatest, debounceTime, filter, map, takeUntil, tap} from 'rxjs';

import {InstrumentsSearchComponent as InstrumentsSearchComponentAlias} from './instruments-search/instruments-search.component';
import {InstrumentsFilterService} from './services/instruments-filter.service';

@Component({
  selector: 'app-pbs-select-instrument',
  templateUrl: './pbs-select-instrument.component.html',
  styleUrls: ['./pbs-select-instrument.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InstrumentsFilterService],
})
export class PbsSelectInstrumentComponent implements OnDestroy {
  private readonly destroyer$ = new Subject<void>();
  private readonly overlayDestroyer$ = new Subject<void>();

  private readonly connectionPositionPairs = [
    new ConnectionPositionPair({originX: 'start', originY: 'bottom'}, {overlayX: 'start', overlayY: 'top'}),
    new ConnectionPositionPair({originX: 'start', originY: 'top'}, {overlayX: 'start', overlayY: 'bottom'}),
  ];

  private defaultInstruments: WarpInstrument[] = [];
  private foundInstruments: WarpInstrument[] = [];
  private overlayRef: OverlayRef;

  public selectedInstrument: WarpInstrument;
  public isInstrumentsListOpened = false;
  public searchValue = '';

  public get arrowIconType(): string {
    return this.isInstrumentsListOpened ? 'arrowup-bold' : 'arrowdown-bold';
  }

  @Input()
  public set initialSymbol(symbol: string) {
    if (!symbol) {
      return;
    }

    this.getDefaultInstruments(symbol)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(instruments => (this.defaultInstruments = instruments));
  }

  @Input()
  public disabled = false;

  @Output()
  public readonly loadingCompleted = new EventEmitter<void>();

  @Output()
  public readonly instrumentSelected = new EventEmitter<WarpInstrument>();

  @ViewChild('searchWrapper')
  private readonly searchWrapper: ElementRef<HTMLElement>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly overlay: Overlay,
    private readonly themeService: ThemeService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly injector: Injector,
    private readonly warpInstrumentService: WarpSearchInstrumentService,
    private readonly instrumentsFilterService: InstrumentsFilterService,
  ) {}

  public async openInstrumentSearch(): Promise<void> {
    if (this.disabled) {
      return;
    }

    this.overlayRef?.dispose();
    this.overlayDestroyer$.next();

    const {InstrumentsSearchComponent} = await import('./instruments-search/instruments-search.component');

    this.overlayRef = this.getOverlayRef();

    const componentPortal = new ComponentPortal(InstrumentsSearchComponent, this.viewContainerRef, this.injector);
    const componentRef = this.overlayRef.attach(componentPortal);

    this.subscribeOnInstrumentsSearchComponentUpdates(componentRef);
    this.subscribeOnBackdropClick(componentRef);

    this.isInstrumentsListOpened = true;
  }

  private subscribeOnInstrumentsSearchComponentUpdates(
    componentRef: ComponentRef<InstrumentsSearchComponentAlias>,
  ): void {
    componentRef.instance.selectedInstrument$.pipe(takeUntil(this.overlayDestroyer$)).subscribe(instrument => {
      this.selectInstrument(instrument);
      this.overlayRef.dispose();
    });

    componentRef.instance.activeFiltersChanges$.pipe(takeUntil(this.overlayDestroyer$)).subscribe(filterType => {
      this.instrumentsFilterService.activeFilterChanged(filterType);

      componentRef.instance.filterItems$.next(this.instrumentsFilterService.filters);

      this.updateSearchedInstruments(componentRef, this.foundInstruments);
    });

    componentRef.instance.searchValueChanges$
      .pipe(debounceTime(DEBOUNCE_TIME_IN_MS), takeUntil(this.overlayDestroyer$))
      .subscribe(searchValue => this.updateFoundInstruments(componentRef, searchValue));

    componentRef.instance.setSearchValue(this.searchValue);
    componentRef.instance.filterItems$.next(this.instrumentsFilterService.filters);

    this.updateSearchedInstruments(componentRef, [this.selectedInstrument]);

    componentRef.changeDetectorRef.detectChanges();
  }

  private subscribeOnBackdropClick(componentRef: ComponentRef<InstrumentsSearchComponentAlias>): void {
    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.overlayDestroyer$))
      .subscribe(() => {
        this.closeInstrumentsSearch();

        componentRef.destroy();
        this.overlayRef.dispose();
      });
  }

  private updateFoundInstruments(
    componentRef: ComponentRef<InstrumentsSearchComponentAlias>,
    searchValue: string,
  ): void {
    if (searchValue) {
      this.warpInstrumentService
        .getInstrumentsBySearchString(searchValue)
        .pipe(
          filter(() => !componentRef.instance.isSearchInputControlValueEmpty),
          takeUntil(this.destroyer$),
        )
        .subscribe(instruments => {
          this.foundInstruments = instruments;

          this.updateSearchedInstruments(componentRef, this.foundInstruments);
        });
    } else {
      this.foundInstruments = _.cloneDeep(this.defaultInstruments);

      this.updateSearchedInstruments(componentRef, this.defaultInstruments);
    }
  }

  private closeInstrumentsSearch(): void {
    this.isInstrumentsListOpened = false;

    const selectedSymbol = this.selectedInstrument?.symbol;

    if (this.searchValue !== selectedSymbol && !!selectedSymbol) {
      this.searchValue = selectedSymbol;

      this.setFoundInstruments(selectedSymbol);
    }

    this.cdr.markForCheck();
  }

  private updateSearchedInstruments(
    componentRef: ComponentRef<InstrumentsSearchComponentAlias>,
    instruments: WarpInstrument[],
  ): void {
    const instrumentsWithCurrentInstrumentOnFirstPosition =
      this.getInstrumentsWithCurrentInstrumentOnFirstPosition(instruments);
    const filteredInstruments = this.getFilteredInstruments(instrumentsWithCurrentInstrumentOnFirstPosition);

    componentRef.instance.instruments$.next(filteredInstruments);
  }

  private getDefaultInstruments(searchValue: string): Observable<WarpInstrument[]> {
    return combineLatest([
      this.warpInstrumentService.getInstrumentsBySearchString(searchValue).pipe(
        tap(instruments => {
          const currentInstrument = instruments.find(instrument => instrument.symbol === searchValue);

          this.selectedInstrument = currentInstrument;
          this.searchValue = currentInstrument.symbol;
          this.loadingCompleted.next();

          this.cdr.markForCheck();
        }),
      ),
      this.warpInstrumentService.getCurrencies(),
      this.warpInstrumentService.getStockInstruments(),
      this.warpInstrumentService.getBondInstruments(),
    ]).pipe(
      map(instruments2D => {
        const instruments = _.flatten(instruments2D);
        const uniqInstruments = _.uniqBy(instruments, 'symbol');

        return uniqInstruments;
      }),
    );
  }

  private getFilteredInstruments(instruments: WarpInstrument[]): WarpInstrument[] {
    return instruments.filter(instrument => this.instrumentsFilterService.isFilteredPassed(instrument));
  }

  private setFoundInstruments(selectedSymbol: string): void {
    this.warpInstrumentService
      .getInstrumentsBySearchString(selectedSymbol)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(instruments => (this.foundInstruments = instruments));
  }

  private getInstrumentsWithCurrentInstrumentOnFirstPosition(instruments: WarpInstrument[]): WarpInstrument[] {
    if (instruments.length <= 1) {
      return instruments;
    }

    const sortedByRatingInstruments = instruments.sort((a, b) => b.rating - a.rating);
    const currentIndexIndex = sortedByRatingInstruments.findIndex(
      sortedByRatingInstrument => sortedByRatingInstrument.symbol === this.searchValue,
    );
    const item = sortedByRatingInstruments.find(
      sortedByRatingInstrument => sortedByRatingInstrument.symbol === this.searchValue,
    );

    if (!item) {
      return instruments;
    }

    sortedByRatingInstruments.splice(currentIndexIndex, 1);
    sortedByRatingInstruments.unshift(item);

    return sortedByRatingInstruments;
  }

  private selectInstrument(instrument: WarpInstrument): void {
    this.selectedInstrument = instrument;
    this.searchValue = this.searchValue ?? instrument.symbol ?? '';
    this.instrumentSelected.emit(instrument);

    this.closeInstrumentsSearch();
  }

  private getPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this.overlay.position().flexibleConnectedTo(this.searchWrapper).withPositions(this.connectionPositionPairs);
  }

  private getOverlayRef(): OverlayRef {
    return this.overlay.create({
      positionStrategy: this.getPositionStrategy(),
      width: this.searchWrapper.nativeElement.offsetWidth,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: this.themeService.getTheme(),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();

    this.overlayDestroyer$.next();
    this.overlayDestroyer$.complete();

    this.overlayRef?.dispose();
  }
}
