import {Injectable} from '@angular/core';
import {MatDrawerToggleResult, MatSidenav} from '@angular/material/sidenav';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SidenavService {
  public sidenav: MatSidenav;

  private _isOpened = false;
  public readonly opened$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  public isOpened(): boolean {
    return this._isOpened;
  }

  public open(): Promise<MatDrawerToggleResult> {
    this._isOpened = true;
    this.opened$.next(true);

    return this.sidenav.open();
  }

  public close(): Promise<MatDrawerToggleResult> {
    this._isOpened = false;
    this.opened$.next(false);

    return this.sidenav.close();
  }

  public toggle(): Promise<MatDrawerToggleResult> {
    this._isOpened = !this._isOpened;
    this.opened$.next(this._isOpened);

    return this.sidenav.toggle();
  }

  public setState(isOpened: boolean): void {
    this._isOpened = isOpened;
    this.opened$.next(isOpened);
  }
}
