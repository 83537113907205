import {CfiCodeHelper} from '@app/pbsr/helpers/cfi-code.helper';
import {TradingStatusHelper} from '@app/pbsr/helpers/trading-status/trading-status.helper';
import {ETradingStatus} from '@app/trading-board/enum/moex/trading-status';
import {IRawWarpInstrument} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-warp-instrument';
import * as B2MarginModels from '@b2broker/b2margin-trade-models';
import {ApiLibraryApiMarketInfo} from '@b2broker/b2trader-trade-models/dist/exchange/apiLibraryApiMarketInfo';
import {Transform} from 'class-transformer';
import moment from 'moment';

import {IB2MarginInstrument} from '../interfaces/b2margin/instrument';
import {IB2TraderInstrument} from '../interfaces/b2trader/instrument';
import {IWarpInstrument} from '../interfaces/moex/instrument';
import {DecimalHelper} from '../models/decimal-helper';

export class Instrument {
  public id: string;
  public symbol: string;
  public symbol1: string;
  public symbol2: string;
  public symbolWithSeparator: string;
  public isOpen: boolean;
  public title: string;
  public priceScale: number;
  public amountScale: number;
  public nativeSymbol: string;
  public isNewInstrument: boolean;

  public pairExist(): boolean {
    return !!this.symbol1 && !!this.symbol2;
  }

  public matchSearchValue(searchValue: string, isSearchSkipSeparator: boolean): boolean {
    if (searchValue.includes('/')) {
      return this.symbolWithSeparator.includes(searchValue);
    }

    const symbol = isSearchSkipSeparator
      ? this.symbolWithSeparator.replace(/[^a-zA-Z0-9]/g, '')
      : this.symbolWithSeparator;
    return symbol.includes(searchValue);
  }
}

export type TB2MarginInstrumentMapType = Map<string, B2MarginInstrument>;

export class B2MarginInstrument extends Instrument {
  public static adapt(items: B2MarginModels.InstrumentTO[]): IB2MarginInstrument[] {
    return items.map(
      ({
        id,
        baseCurrency,
        currency,
        symbol,
        description,
        currencyPrecision,
        precision,
        quantityPrecision,
        lotSize,
        subtype,
      }) => ({
        id: id?.toString(),
        symbol: `${baseCurrency ?? ''}${currency ?? ''}`,
        symbol1: baseCurrency,
        symbol2: currency,
        symbolWithSeparator: symbol,
        isOpen: true,
        description,
        priceScale: precision,
        amountScale: currencyPrecision,
        quantityScale: quantityPrecision,
        lotSize,
        subtype,
        nativeSymbol: symbol,
      }),
    );
  }

  public static reduceListToMap(instruments: B2MarginInstrument[], isSymbol?: boolean): TB2MarginInstrumentMapType {
    return instruments.reduce((acc, instrument) => {
      const key = isSymbol ? instrument.symbolWithSeparator : instrument.id;
      acc.set(key, instrument);
      return acc;
    }, new Map() as TB2MarginInstrumentMapType);
  }

  public static getInstrumentsFromMap(
    keys: string[],
    instrumentsMap: TB2MarginInstrumentMapType,
  ): B2MarginInstrument[] {
    return keys.map(key => instrumentsMap.get(key)).filter(i => !!i);
  }

  public lotSize?: number;
  public subtype?: string;
  public description?: string;
}

export class B2TraderInstrument extends Instrument {
  private static readonly HOW_MANY_DAYS_IS_INSTRUMENT_NEW = 7;

  public static adapt({
    baseAsset,
    quoteAsset,
    takerFee,
    makerFee,
    status,
    createdAt,
    priceScale,
    amountScale,
  }: ApiLibraryApiMarketInfo): IB2TraderInstrument {
    const symbol1 = baseAsset.toUpperCase();
    const symbol2 = quoteAsset.toUpperCase();
    const symbol = `${symbol1}_${symbol2}`;

    return {
      id: symbol,
      symbol: symbol1 + symbol2,
      symbol1,
      symbol2,
      symbolWithSeparator: `${symbol1}/${symbol2}`,
      nativeSymbol: `${baseAsset}_${quoteAsset}`,

      takerFee,
      makerFee,
      isOpen: status === 'Open',
      isNewInstrument: moment().diff(moment(createdAt), 'days') < B2TraderInstrument.HOW_MANY_DAYS_IS_INSTRUMENT_NEW,
      priceScale,
      amountScale,
      quantityScale: amountScale,
    };
  }

  public isNewInstrument: boolean;

  @Transform(({value}) => DecimalHelper.from(value))
  public takerFee: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value))
  public makerFee: DecimalHelper;
}

export class WarpInstrument extends Instrument {
  public static adapt(rawWarpInstrument: IRawWarpInstrument): IWarpInstrument {
    const {symbol} = rawWarpInstrument;

    return {
      id: symbol,
      symbol,
      symbol1: symbol,
      symbol2: symbol,
      symbolWithSeparator: symbol,
      nativeSymbol: symbol,
      isOpen: true,
      shortName: rawWarpInstrument.shortname,
      isin: rawWarpInstrument.ISIN,
      description: rawWarpInstrument.description,
      cfiCode: rawWarpInstrument.cfiCode,
      primaryBoard: rawWarpInstrument.primary_board,
      lotSize: rawWarpInstrument.lotsize,
      tradingStatus: rawWarpInstrument.tradingStatus,
      currency: rawWarpInstrument.currency,
      exchange: rawWarpInstrument.exchange,
      facevalue: rawWarpInstrument.facevalue,
      type: rawWarpInstrument.type,
      complexProductCategory: rawWarpInstrument.complexProductCategory,
      rating: rawWarpInstrument.rating,
      priceScale: WarpInstrument.getDecimalPlaces(rawWarpInstrument.minstep),
      minStep: rawWarpInstrument.minstep,
    };
  }

  private static getDecimalPlaces(minstep: number): number {
    const fraction = minstep.toString().split('.')[1];

    if (!fraction) {
      return 2;
    }

    return fraction.length;
  }

  public shortName: string;
  public isin: string | null;
  public cfiCode: string;
  public primaryBoard: string;
  public lotSize: number;
  public tradingStatus: ETradingStatus;
  public currency: string;
  public exchange: string;
  public description: string;
  public facevalue: number;
  public type: string;
  public complexProductCategory: string;
  public rating: number;
  public priceScale: number;
  public minStep: number;

  public get isCurrency(): boolean {
    return CfiCodeHelper.isCurrency(this.cfiCode);
  }

  public get isStock(): boolean {
    return CfiCodeHelper.isStock(this.cfiCode);
  }

  public get isBond(): boolean {
    return CfiCodeHelper.isBond(this.cfiCode);
  }

  public get isTradingUnavailable(): boolean {
    return !TradingStatusHelper.isTradable(this.tradingStatus);
  }
}
