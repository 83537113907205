import {Casts, CastTypes, Model} from '@app/core/model';
import {IGridsterLayout} from '@app/core/models/gridster';
import {shareReplayWithRef} from '@app/core/utils/share-replay-with-ref';
import {IEqwireSettings} from '@app/eqwire/shared/interfaces/eqwire-settings.interface';
import {IGridsterOptionsEvent} from '@app/gridster/services/gridster-options-events.service';
import {ILeaderboardUserConfig} from '@app/investment-platform/leaderboard/interfaces';
import {EThemeMode} from '@app/shared/modules/theme/entities/theme-mode';
import {EExperienceLevel} from '@app/trading-board/enum/b2trader/experience-level';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class Settings extends Model {
  private readonly leaderboardConfigJsonString$ = new BehaviorSubject<string | null>(null);

  public get isSupportSendEmail(): boolean {
    return this.supportpalSendEmail === '1';
  }

  public set isSupportSendEmail(isSupportSendEmail: boolean) {
    this.supportpalSendEmail = isSupportSendEmail ? '1' : '0';
  }
  private supportpalSendEmail?: TSupportpalSendEmail;
  public gridsters: string;
  public gridstersOptions: string;

  public locale?: string;
  public commonTheme?: EThemeMode;
  public advancedTheme?: EThemeMode;
  public advanced?: string;
  public partners?: Array<number>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public wallet_whitelist?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public helpdeskClosedTicketFlag?: boolean;

  public set leaderboardConfig(value: string | undefined | null) {
    this.leaderboardConfigJsonString$.next(value ?? null);
  }

  public get leaderboardConfig(): string | undefined | null {
    return this.leaderboardConfigJsonString$.getValue();
  }

  public eqwire?: string;

  public isIntroductionTourCompleted?: boolean;
  public experienceLevel?: EExperienceLevel | null;
  public is24hTimeFormatUsed: boolean | undefined;

  public isPbsOrderWithoutConfirmationOn: boolean;

  public readonly casts: Casts = {
    locale: [CastTypes.String, CastTypes.Undefined],
    commonTheme: [CastTypes.String, CastTypes.Undefined],
    advancedTheme: [CastTypes.String, CastTypes.Undefined],
    advanced: [CastTypes.String, CastTypes.Undefined],
    gridsters: [CastTypes.Any, CastTypes.Undefined],
    gridstersOptions: [CastTypes.Any, CastTypes.Undefined],
    widgets: [CastTypes.Any, CastTypes.Undefined],
    partners: [[CastTypes.Number], CastTypes.Null],
    supportpalSendEmail: [CastTypes.String, CastTypes.Null],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wallet_whitelist: [CastTypes.Number, CastTypes.Null],
    helpdeskClosedTicketFlag: [CastTypes.Boolean, CastTypes.Undefined],
    leaderboardConfig: [CastTypes.Any, CastTypes.Undefined],
    eqwire: [CastTypes.Any, CastTypes.Undefined],
    isIntroductionTourCompleted: [CastTypes.Boolean, CastTypes.Undefined],
    experienceLevel: [CastTypes.String, CastTypes.Null, CastTypes.Undefined],
    is24hTimeFormatUsed: [CastTypes.Boolean, CastTypes.Undefined],
    isPbsOrderWithoutConfirmationOn: [CastTypes.Boolean, CastTypes.Undefined],
  };

  public getLocal(): string {
    return this.locale ? this.locale.split('_')[0] : '';
  }

  public clone(): Settings {
    return Settings.Make(this);
  }

  public updateGridsterConfigs(config: Record<string, IGridsterLayout[]>): void {
    this.gridsters = JSON.stringify(config);
  }

  public getGridsterConfigs(): Record<string, IGridsterLayout[]> {
    try {
      return JSON.parse(this.gridsters);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  public updateGridsterOptions(config: Record<string, IGridsterOptionsEvent>): void {
    this.gridstersOptions = JSON.stringify(config);
  }

  public getGridsterOptions(): Record<string, IGridsterOptionsEvent> {
    try {
      return JSON.parse(this.gridstersOptions);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  public updateLeaderboardConfig(config: ILeaderboardUserConfig): void {
    this.leaderboardConfig = JSON.stringify(config);
  }

  public getLeaderboardConfig(): ILeaderboardUserConfig {
    try {
      return this.leaderboardConfig ? JSON.parse(this.leaderboardConfig) : {};
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  public readonly leaderboardConfig$: Observable<ILeaderboardUserConfig> = this.leaderboardConfigJsonString$.pipe(
    map(() => this.getLeaderboardConfig()),
    shareReplayWithRef(),
  );

  public updateEqwireSettings(settings: IEqwireSettings): void {
    this.eqwire = JSON.stringify(settings);
  }

  public getEqwireSettings(): IEqwireSettings | null {
    try {
      return this.eqwire ? JSON.parse(this.eqwire) : null;
    } catch (e) {
      console.warn(e);
      return null;
    }
  }
}

export type TSupportpalSendEmail = '0' | '1';
