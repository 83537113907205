import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class ServerInfoInternalEventHandler extends AEventHandler<IB2marginMessage<{serverTime: number}>> {
  public type = EB2MarginEventType.ServerInfo;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<{serverTime: number}>): void {
    if (!this.store.connectionServerTimestamp) {
      this.store.connectionServerTimestamp = resource.body.serverTime;
    }
  }
}

b2MarginInternalEventHandlers.push(ServerInfoInternalEventHandler);
