<ng-container [formGroup]="formGroup">
  <mat-form-field class="select-field">
    <mat-select
      [tooltipIfLongLabel]="label"
      [hasReqiredIfRule]="isRequiredIf"
      [value]="control.value"
      [formControl]="control"
      [required]="required"
      [multiple]="field.type === formFieldTypesEnum.multiSelect"
      [disableOptionCentering]="true"
      [panelClass]="name ? name + '-select-panel' : null"
      (closed)="selectClosed()"
      (opened)="selectOpened()"
    >
      <app-select-search
        *ngIf="isSelectAutocompleteType"
        (searchStringChanged)="searchStringChanged($event)"
      ></app-select-search>

      <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
        <ellipsis-container>{{ getOptionLabel(option.name) | async }}</ellipsis-container>
      </mat-option>

      <div *ngIf="filteredOptions?.length === 0" class="no-results">
        <span>{{ 'Form.Components.Select.SelectAutocomplete.NoResults' | translate }}</span>
      </div>
    </mat-select>

    <button
      *ngIf="control.value && isSelectAutocompleteType && isShowCloseIcon"
      matSuffix
      mat-button
      class="close-button"
      (click)="resetControl($event)"
    >
      <app-icon icon="close"></app-icon>
    </button>

    <mat-hint *ngIf="field.label.hint" [align]="field.label.hint.position || 'start'">
      {{ getHintLabel() | async }}
    </mat-hint>

    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>

    <mat-label #label>
      {{ getLabel() | async }}
      <app-required-indicator
        [isShowIndicator]="isRequiredIf"
        [isInvalid]="isShowRequiredIndicator"
      ></app-required-indicator>
    </mat-label>
  </mat-form-field>
</ng-container>
