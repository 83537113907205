import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-code-resend',
  templateUrl: './code-resend.component.html',
  styleUrls: ['./code-resend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeResendComponent {
  private static readonly DEFAULT_TIMEOUT_IN_SEC = 60;

  public isTimeEnd = false;

  @Input()
  public defaultTimeoutInSec = CodeResendComponent.DEFAULT_TIMEOUT_IN_SEC;

  @Input()
  public expiredTimer: number;

  @Input()
  public isManyRequests = false;

  @Input()
  public isAside = false;

  @Output()
  public readonly resendEvent = new EventEmitter<void>();

  @Output()
  public readonly blockEndEvent = new EventEmitter<void>();

  private _timeoutInSec = this.defaultTimeoutInSec;
  public get timeout(): number {
    return this._timeoutInSec;
  }

  public onTimeEnd(): void {
    this.isTimeEnd = true;
  }

  public resend(): void {
    this._timeoutInSec = this.defaultTimeoutInSec;
    this.isTimeEnd = false;
    this.resendEvent.emit();
  }

  public onEndBlock(): void {
    this.blockEndEvent.emit();
  }
}
