import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {AB2marginPopupMessage} from '@app/trading-board/models/b2margin/b2margin-popup-message';

@Injectable()
export class B2marginPopupMessagesService extends AEventHandler<AB2marginPopupMessage> {
  public readonly type = EEventHandlersReceived.PopupMessages;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: AB2marginPopupMessage): void {
    this.b2marginDatafeedService.popupMessage$.next(resource);
  }
}
