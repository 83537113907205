import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/snackbar/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class RequestEntityTooLargeInterceptor implements HttpInterceptor {
  constructor(private readonly snackbarService: SnackbarService, private readonly translateService: TranslateService) {}

  public intercept<Req, Res>(req: HttpRequest<Req>, next: HttpHandler): Observable<HttpEvent<Res>> {
    return next.handle(req).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          if (res.status === 413) {
            this.snackbarService.error(this.translateService.get('Files is too large'));
          }
        }
      }),
    );
  }
}

export const RequestEntityTooLargeService = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestEntityTooLargeInterceptor,
  multi: true,
};
