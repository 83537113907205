import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {IconModule} from '@app/icon/icon.module';
import {ImgModule} from '@app/img/img.module';
import {SharedModule} from '@app/shared/shared.module';
import {DialogModule} from '@app/ui/dialog/dialog.module';
import {ErrorDialogComponent} from '@app/ui/error-dialog/error-dialog.component';

@NgModule({
  declarations: [ErrorDialogComponent],
  imports: [CommonModule, MatButtonModule, DialogModule, IconModule, SharedModule, MatDialogModule, ImgModule],
  exports: [ErrorDialogComponent],
})
export class ErrorDialogModule {}
