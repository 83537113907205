import {Injectable} from '@angular/core';
import {LabelService} from '@app/core/form/services/label.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ErrorsService {
  private readonly map: TErrorsMap = {
    required: () => this.translate.get('errors.required'),
    requiredif: () => this.translate.get('errors.requiredif'),
    requiredIf: () => this.translate.get('errors.requiredif'),
    min: value => this.translate.get('errors.min {{value}}', {value}),
    max: value => this.translate.get('errors.max {{value}}', {value}),
    email: () => this.translate.get('errors.email'),
    regex: () => this.translate.get('errors.incorrect'),
    string: () => this.translate.get('errors.string'),
    date: () => this.translate.get('errors.date'),
    before: date => this.translate.get('Core.Form.Errors.Before [date]', {date}),
    after: date => this.translate.get('Core.Form.Errors.After [date]', {date}),
    beforeOrEqual: date => this.translate.get('Core.Form.Errors.BeforeOrEqual [date]', {date}),
    afterOrEqual: date => this.translate.get('Core.Form.Errors.AfterOrEqual [date]', {date}),
    matDatepickerParse: () => this.translate.get('errors.date'),
    matDatepickerMax: () => this.translate.get('Core.Form.Errors.MaxDateInvalid'),
    matStartDateInvalid: () => this.translate.get('Core.Form.Errors.StartDateInvalid'),
    matEndDateInvalid: () => this.translate.get('Core.Form.Errors.EndDateInvalid'),
    age: value => this.translate.get('errors.age {{value}}', {value}),
    postalcode: () => this.translate.get('errors.postalcode'),
    phone: () => this.translate.get('errors.phone'),
    array: () => this.translate.get('errors.array'),
    integer: () => this.translate.get('errors.integer'),
    requiredValue: () => this.translate.get('errors.requiredValue'),
    url: () => this.translate.get('errors.url'),
    requiredLength: value => this.translate.get('errors.requiredLength {{value}}', {value}),
    in: () => this.translate.get('errors.in'),
    numeric: () => this.translate.get('errors.numeric'),
    digits: () => this.translate.get('errors.digits'),
    mimetypes: value => this.translate.get('errors.mimetypes {{value}}', {value: value.join && value.join(', ')}),
    file: () => this.translate.get('errors.file'),
    noConfigForField: () => this.translate.get('errors.noConfigForField'),
    maxFilesAmount: value => this.translate.get('errors.maxFilesAmount {{value}}', {value}),
    notZero: () => this.translate.get('errors.notZero'),
    minValue: value => this.translate.get('errors.minValue {{value}}', {value}),
    maxFileSize: value => this.translate.get('errors.maxFileSize {{value}}', {value}),
    maxValue: value => this.translate.get('errors.maxValue {{value}}', {value}),
    maxLength: value => this.translate.get('errors.maxLength {{value}}', {value}),
    minLength: value => this.translate.get('errors.minLength {{value}}', {value}),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'filters.to': () => this.translate.get('errors.filters.to'),
    same: value =>
      this.labelsService
        .getLabel(value)
        .pipe(mergeMap(label => this.translate.get('errors.same as {{label}}', {label: label.toLowerCase()}))),

    sameGroup: () => this.translate.get('errors.sameGroup'),
    cardNumber: () => this.translate.get('errors.invalidCardNumber'),
    cardHolderName: () => this.translate.get('errors.invalidCardHolderName'),
    isToLessFrom: () => this.translate.get('Core.Form.Errors.isToLessFrom'),
    maxImageSizeInPx: value => this.translate.get('Core.Form.Errors.MaxFileSizeInPx [value]', {value}),

    mustUseValueFromAutosuggest: () => this.translate.get('errors.mustUseValueFromAutosuggest'),

    invalidReferenceMessage: () => this.translate.get('errors.invalidReferenceMessage'),

    dateMustBeGreaterThan: value => this.translate.get('Core.Form.Errors.dateMustBeGreaterThan [value]', {value}),
    dateMustBeLessThan: value => this.translate.get('Core.Form.Errors.dateMustBeLessThan [value]', {value}),
    hasNotEnoughFunds: () => this.translate.get('Core.Form.Errors.notEnoughFunds'),
  };

  constructor(private readonly translate: TranslateService, private readonly labelsService: LabelService) {}

  public getError(key: string, value): Observable<string> {
    const errorObservable = this.map[key];
    return errorObservable ? errorObservable(value) : this.translate.get(key);
  }
}

export type TFormErrorWorker = (value) => Observable<string>;

export type TErrorsMap = Record<string, TFormErrorWorker>;
