import {Injectable} from '@angular/core';
import {SECOND_IN_MS} from '@app/core/constants/common';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {IDataEvent} from '@app/trading-board/interfaces/data-event.ts';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {interval, Observable, ObservableInput, ReplaySubject, Subject} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {filter, first, startWith, switchMap, takeUntil} from 'rxjs/operators';

interface IApiConfiguration {
  authHeadersFactory: () => Observable<HeadersInit>;
}

@Injectable({providedIn: 'root'})
export class WarpAuthService {
  private readonly destroyer$ = new Subject<void>();
  private readonly configuration$ = new ReplaySubject<IApiConfiguration | null>(1);
  private readonly warpResponse$ = new Subject<IWarpResponse | null>();

  private warpResponse: IWarpResponse | null = null;

  public tokenInitialized$ = new ReplaySubject<void>(1);
  public messages$: Subject<IDataEvent>;

  public get token(): string {
    if (!this.warpResponse) {
      return;
    }

    return this.warpResponse.token;
  }

  public startWatchToUserInfo(apiUrl: string): void {
    let isFirstCall = true;

    this.warpResponse$
      .pipe(
        startWith(null as IWarpResponse),
        switchMap(warpResponse => {
          const refreshTimeSec = (warpResponse?.ttl / 100) * 90;
          if (!refreshTimeSec || isFirstCall) {
            isFirstCall = false;

            return this.generateTokenRequest(apiUrl);
          }

          return interval(refreshTimeSec * SECOND_IN_MS).pipe(switchMap(() => this.generateTokenRequest(apiUrl)));
        }),
        filter(warpResponse => {
          if (!warpResponse?.token) {
            this.messages$?.next({
              type: EEventHandlersReceived.Error,
              payload: marker('TradingBoard.WarpAuthorization.AuthorizationFailedMessage'),
            });
          }

          return !!warpResponse.token;
        }),
        takeUntil(this.destroyer$),
      )
      .subscribe(warpResponse => {
        this.warpResponse = warpResponse;
        this.warpResponse$.next(warpResponse);

        this.tokenInitialized$.next();
        this.tokenInitialized$.complete();
      });
  }

  public fromMoexFetch<T>(
    url: string,
    options?: RequestInit,
    headers?: HeadersInit,
    selector: (response: Response) => ObservableInput<T> = res => res.json(),
  ): Observable<T> {
    return this.tokenInitialized$.pipe(
      switchMap(() =>
        fromFetch<T>(url, {
          selector,
          ...options,
          headers: {
            ...headers,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${this.token}`,
          },
        }),
      ),
    );
  }

  public setConfiguration(config: IApiConfiguration): void {
    this.configuration$.next(config);
  }

  private generateTokenRequest(apiUrl: string): Observable<IWarpResponse> {
    return this.configuration$.pipe(
      first(),
      switchMap(config =>
        config.authHeadersFactory().pipe(
          switchMap(headers => {
            return fromFetch<IWarpResponse>(`${apiUrl}/api/v1/warp/jwt`, {
              selector: res => (res.status < 400 ? res.json() : Promise.resolve()),
              method: 'GET',
              headers: {
                ...headers,
              },
            });
          }),
        ),
      ),
    );
  }

  public clear(): void {
    this.destroyer$.next();
    this.destroyer$.complete();

    this.tokenInitialized$ = new ReplaySubject<void>(1);

    // When we logout we do not clear the previous token, because a new one will be requested for the new user when login.
    // Service will be redesigned in https://b2btech.atlassian.net/browse/FDP-18088
  }
}

interface IWarpResponse {
  token: string;
  ttl: number;
}
