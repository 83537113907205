import {Injectable} from '@angular/core';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {ChartResponseTO} from '@b2broker/b2margin-trade-models';
import {Bar as IBar} from '@b2broker/trading.view.charting.library/charting_library/charting_library';

@Injectable()
export class B2marginChartService extends AEventHandler<ChartResponseTO> {
  public type = EEventHandlersReceived.Chart;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: ChartResponseTO): void {
    this.b2marginDatafeedService.chartDataTopics.get(resource.subtopic)?.next(resource.data as IBar[]);
  }
}
