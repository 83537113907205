import {B2MarginInstrument} from '@app/trading-board/models/instrument';
import {B2brokerAccountTradeHistoryItem} from '@b2broker/b2trader-trade-models';
import {DecimalHelper} from '@b2broker/decimal-helper';
import {Expose, Transform, Type} from 'class-transformer';

import {ESide} from '../enum/side';
import {IB2MarginTrade} from '../interfaces/b2margin/trade';
import {ITrade} from '../interfaces/trade';

export class Trade {
  public static fromB2Trader(list: B2brokerAccountTradeHistoryItem[]): ITrade[] {
    return list.map(item => ({
      price: DecimalHelper.from(item.executionPrice).toString(),
      side: item.side ? ESide.Buy : ESide.Sell,
      tradeTime: new Date(item.tradeTime).getTime(),
      quantity: item.amount,
      symbolWithSeparator: item.instrument,
    }));
  }
  public static fromB2Margin(
    list: IB2MarginTrade[],
    visibleInstrumentsBySymbol: Map<string, B2MarginInstrument>,
  ): ITrade[] {
    return list.map(item => {
      const lotSize = visibleInstrumentsBySymbol.get(item.symbol)?.lotSize;

      return {
        price: DecimalHelper.from(item.price).toString(),
        side: item.size > 0 ? ESide.Buy : ESide.Sell,
        tradeTime: item.time,
        quantity: item.size,
        symbolWithSeparator: item.symbol,
        lotSize,
      };
    });
  }

  @Transform(({value}) => DecimalHelper.from(value))
  public price: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value).abs())
  public quantity: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: ITrade}) =>
    DecimalHelper.from(obj.quantity)
      .divide(obj.lotSize ?? 1)
      .abs(),
  )
  public size: DecimalHelper;

  public side: ESide;

  @Type(() => Date)
  public tradeTime: Date;

  public symbolWithSeparator: string;

  public isBuy(): boolean {
    return this.side === ESide.Buy;
  }
}
