import {Account} from '@app/core/models/account/account';
import {IAssetsTransformData} from '@app/trading-board/interfaces/b2trader/assets-transform-data';
import {Balance} from '@app/trading-board/models/b2trader/balance';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class Asset {
  public static getStandaloneTransformData(balances: Balance[]): IAssetsTransformData[] {
    return balances.reduce((acc: IAssetsTransformData[], item) => {
      acc.push({
        alpha: item.product,
        caption: '',
        total: item.total,
        available: item.available,
      });

      return acc;
    }, []);
  }

  public static getTransformData(balances: Balance[], accounts: Account[]): IAssetsTransformData[] {
    return accounts?.reduce((acc: IAssetsTransformData[], item) => {
      const balance = balances.find(({product}) => item.product.currency.alpha.toLowerCase() === product.toLowerCase());

      acc.push({
        alpha: item.product.currency.alpha,
        caption: item.product.currency.caption,
        total: balance?.total,
        available: balance?.available,
      });
      return acc;
    }, []);
  }

  @Expose()
  @Transform(({obj}: {obj: IAssetsTransformData}) => obj.alpha)
  public currency: string;

  @Expose()
  @Transform(({obj}: {obj: IAssetsTransformData}) => obj.caption)
  public caption: string;

  @Expose()
  @Transform(({obj}: {obj: IAssetsTransformData}) => obj?.total ?? DecimalHelper.from(0))
  public total: DecimalHelper;

  @Expose()
  @Transform(({obj}: {obj: IAssetsTransformData}) => obj?.available ?? DecimalHelper.from(0))
  public available: DecimalHelper;
}
