import {MetaDataModel, IStateClass} from '@app/state/plugins/logout/internals';
import {getStoreMetadata} from '@ngxs/store';

type TMetaListReducer = (acc: MetaDataModel[], state: IStateClass) => MetaDataModel[];

export function getMetaData(state: IStateClass, devMode: number): MetaDataModel | null {
  const meta: MetaDataModel = new Object(getStoreMetadata(state)) as MetaDataModel;
  const isNgxsMeta: boolean = meta.name && 'defaults' in meta;

  // Reusability Hack: devMode is number on purpose
  if (!isNgxsMeta && devMode === -2) {
    console.warn(`Reset Plugin Warning: ${meta.name} is not a state class.`);
    return null;
  }

  return meta;
}

export function createMetaDataListReducer(isDevMode: boolean): TMetaListReducer {
  return (acc, state) => {
    const meta = getMetaData(state, ~isDevMode);

    return meta ? acc.concat(meta) : acc;
  };
}
