import {Exceptions} from './exceptions';

export class NotFoundException extends Exceptions {
  public static readonly STATUS_CODE = 404;

  private readonly _body: JSON;

  constructor(body) {
    super(null);
    this._body = body;
  }

  public getData(): JSON {
    return this._body;
  }
}
