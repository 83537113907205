import {UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {apiDateFormat} from '@app/core/helpers/date.formats';
import moment from 'moment';

import {CustomValidators} from './custom.validators';
import {FormField, FormFieldRule, FormFieldType} from './form.field';

export class FormWrapper implements FormConfig {
  public fields: Record<string, FormField>;
  public form: UntypedFormGroup;

  constructor(formConfig: FormConfig) {
    this.fields = formConfig.fields;
    this.form = FormWrapper.build(this.fields);
  }

  public static reduceValidators(rules: FormFieldRule[]): ValidatorFn[] {
    if (!rules) {
      return [];
    }
    return rules.reduce((validators, rule) => {
      const [fieldName, params] = rule,
        validator = CustomValidators[fieldName] ? CustomValidators[fieldName](params, rules) : null;
      if (validator) {
        validators.push(validator);
      } else {
        console.warn('did not found validator ' + [fieldName]);
      }
      return validators;
    }, []);
  }

  private static build(fields: Record<string, FormField>, rules?: FormFieldRule[]): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({}, FormWrapper.reduceValidators(rules));

    Object.keys(fields).forEach(key => {
      const field = fields[key];

      if (field.fields) {
        const control = FormWrapper.build(field.fields, field.rules);
        formGroup.addControl(key, control);
      } else {
        let value = field.initialValue || '';

        if (field.type === FormFieldType.date && value) {
          value = moment(value).format(apiDateFormat);
        }

        const validators = FormWrapper.reduceValidators(field.rules);
        const control = new UntypedFormControl(value, validators);
        formGroup.addControl(key, control);
      }
    });

    return formGroup;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type FormConfig = Partial<FormField>;

export interface IFormConfigParams<T = unknown> {
  config: FormConfig;
  rules?: Record<string, FormFieldRule[]>;
  initialValue?: T;
}
