import {Directive, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';
import {Subject, throttleTime} from 'rxjs';

@Directive({
  selector: '[appDebounceClick]',
})
export class DebounceClickDirective implements OnDestroy {
  private readonly clicks = new Subject<void>();

  @Input() public debounceTime = 500;
  @Output() public appDebounceClick = new EventEmitter();

  public subscription = this.clicks.pipe(throttleTime(this.debounceTime)).subscribe(e => this.appDebounceClick.emit(e));

  @HostListener('click', ['$event'])
  public clickEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
