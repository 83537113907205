<ng-container [formGroup]="group">
  <div class="file-upload" appDir>
    <ng-container *ngIf="field.label">
      <div class="wrap-label">
        <mat-label>{{ label | async }}</mat-label>
        <span [class.error]="filesArray.invalid"> {{ isRequired ? '*' : '' }}</span>
      </div>
    </ng-container>

    <app-file-input
      [isHideBackgroundImageAfterFileAdding]="isHideBackgroundImageAfterFileAdding"
      [formControl]="customControl"
      [accept]="acceptTypes"
      [errors]="errors"
      [required]="isRequired && customControl.touched"
    >
    </app-file-input>

    <ng-container *ngIf="customControl.touched">
      <mat-error *ngFor="let err of filesArray.errors | keys | slice: 0:1">
        {{ getError(err) | async }}
      </mat-error>
    </ng-container>

    <app-file-message [rules]="rules" [acceptTypes]="acceptTypes"></app-file-message>
  </div>

  <ng-content select="[message]"></ng-content>
</ng-container>
