<ng-container [formGroup]="formGroup">
  <div class="text-field-wrapper">
    <mat-form-field>
      <input
        #phoneInput
        matInput
        type="text"
        [tooltipIfLongLabel]="label"
        [formControl]="control"
        [required]="required"
        [readonly]="field.readonly"
        [mask]="mask.pattern"
        [validation]="false"
        [dropSpecialCharacters]="mask.dropSpecialCharacters"
        autocomplete="off"
      />

      <mat-select
        [(value)]="defaultCountry"
        [compareWith]="compareCountry"
        (selectionChange)="setUpCountry($event.value, true)"
      >
        <mat-select-trigger>
          <lib-flag-icon [countryCode]="selectedCountry || 'default'"></lib-flag-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let country of countries; trackBy: trackByCountry" [value]="country">
          <div class="country-wrapper">
            <lib-flag-icon [countryCode]="country.iso2"></lib-flag-icon>
            <ellipsis-container>{{ country.name }} +{{ country.dialCode }}</ellipsis-container>
          </div>
        </mat-option>
      </mat-select>

      <mat-hint *ngIf="field.label.hint" [align]="field.label.hint.position || 'start'">
        {{ getHintLabel() | async }}
      </mat-hint>

      <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
        {{ getError(err) | async }}
      </mat-error>

      <mat-label #label>
        {{ getLabel() | async }}
      </mat-label>
    </mat-form-field>
  </div>
</ng-container>
