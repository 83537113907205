<ng-container [formGroup]="formGroup">
  <div class="radio-button-wrapper">
    <div *ngIf="field.label" class="form-label">{{ getLabel() | async }}</div>

    <div class="radio-buttons" [class.column-view]="isLongName">
      <div *ngFor="let option of options" class="radio-button">
        <input
          type="radio"
          [value]="option.value"
          [formControl]="control"
          [id]="field.name + '-' + option.value"
          [name]="name"
        />
        <label [for]="field.name + '-' + option.value">
          <div class="radio-button-circle"></div>
          <div class="radio-button-label">{{ option.name }}</div>
        </label>
      </div>
    </div>

    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>
  </div>
</ng-container>
