/* eslint-disable @typescript-eslint/naming-convention */
import {Casts, CastTypes, Model} from '@app/core/model';
import {AttachmentFeature} from '@app/helpdesk/models/attachment-feature';

export class Attachment extends Model {
  public id: number;
  public message_id: number;
  public created_at: number;
  public uploaded_at?: number;
  public updated_at: number;
  public direct_frontend_url: string;
  public frontend_url: string;
  public original_name: string;
  public upload: AttachmentFeature;

  public readonly casts: Casts = {
    id: [CastTypes.Number],
    message_id: [CastTypes.Number],
    created_at: [CastTypes.Number],
    uploaded_at: [CastTypes.Null, CastTypes.Number],
    updated_at: [CastTypes.Number],
    direct_frontend_url: [CastTypes.String],
    frontend_url: [CastTypes.String],
    original_name: [CastTypes.String],
    upload: [AttachmentFeature],
  };
}
