<div
  class="snackbar-inner"
  appDir
  [class.success]="is(types.Success)"
  [class.message]="is(types.Message)"
  [class.error]="is(types.Error)"
>
  <app-icon *ngIf="is(types.Success)" [icon]="'checked'"></app-icon>
  <app-icon *ngIf="is(types.Message)" [icon]="'information'"></app-icon>
  <app-icon *ngIf="is(types.Error)" [icon]="'cancel'"></app-icon>

  <span class="snackbar-message">{{ message }}</span>
  <div class="snackbar-button-container">
    <svg class="progress-ring-container" height="42" width="42">
      <circle class="progress-ring" r="19" cx="21" cy="21" [style.animation-duration]="animationDuration" />
    </svg>

    <button class="close-button" (click)="closeSnackBar()">
      <app-icon [icon]="'check-mark'"></app-icon>
    </button>
  </div>
</div>
