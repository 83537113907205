import {EMoexOpcode} from '@app/trading-board/enum/moex/opcode';
import {MoexStateStore} from '@app/trading-board/facades/moex-state-store';
import {IMoexMessage} from '@app/trading-board/interfaces/moex/moex-message';
import {Bar as IBar} from '@b2broker/trading.view.charting.library/charting_library/charting_library';

import {AEventHandler, moexInternalEventHandlers} from '../../event-handler.abstract';

export class MoexBarsInternalEventHandler extends AEventHandler<IMoexMessage<IBar>> {
  public readonly type = EMoexOpcode.BarsGetAndSubscribe;

  constructor(private readonly store: MoexStateStore) {
    super();
  }

  public handleMessage(message: IMoexMessage): void {
    this.store.chart$.next(message);
  }
}

moexInternalEventHandlers.push(MoexBarsInternalEventHandler);
