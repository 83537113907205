import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {FileKeeper} from '@app/core/file-keeper';
import {FormField, FormFieldRuleValue} from '@app/core/form/entities/form.field';
import * as _ from 'lodash-es';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {ACCEPT_TYPES_FORMAT, MAX_FILE_SIZE} from '../../entities/file-input-default-restrictors';

@Component({
  selector: 'app-file-button',
  templateUrl: 'file-button.component.html',
  styleUrls: ['file-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileButtonComponent implements OnInit, OnDestroy {
  public readonly fileKeeper = new FileKeeper(this.sanitizer.bypassSecurityTrustUrl);
  public fileAccept: string;
  public maxFileSize = MAX_FILE_SIZE;
  public formats = '';
  public errors: Record<string, ValidationErrors>;

  @Input()
  public group: UntypedFormGroup;

  @Input()
  public field: FormField;

  @Input()
  public control: UntypedFormControl;

  public readonly customControl = new UntypedFormControl();

  private readonly destroy$ = new Subject<void>();

  private validator: ValidatorFn;

  constructor(private readonly sanitizer: DomSanitizer, private readonly cdr: ChangeDetectorRef) {}

  private getFileAccept(formats: string[]): string {
    return formats.map((format: string) => (format = '.' + format)).join(', ');
  }

  public ngOnInit(): void {
    this.getValidators();

    this.group.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errors = Object.keys(this.group.controls).reduce((r: Record<string, ValidationErrors>, key: string) => {
        const control = this.group.get(key);

        if (control.errors) {
          r[key] = control.errors;
        }

        return r;
      }, {});

      this.cdr.detectChanges();
    });

    this.customControl.valueChanges
      .pipe(
        filter(v => !!v),
        takeUntil(this.destroy$),
      )
      .subscribe((files: File[]) => {
        if (this.control) {
          // TODO: Will be reworked in (https://b2btech.atlassian.net/browse/FDP-9872)
          files.forEach(file => {
            this.control.setValue(file);
            this.control.markAsTouched();
          });
        } else {
          const keys = Object.keys(this.group.controls);

          keys.forEach(key => this.group.removeControl(key));
          files.forEach((file, i) => {
            const control = new UntypedFormControl(file, this.validator);
            this.group.addControl(String(i), control);
            control.markAsTouched();
          });
        }

        this.group.markAsTouched();
        this.cdr.detectChanges();
      });
  }

  public getValidators(): void {
    const configControl = this.group.get('0');
    const rules = this.field?.fields?.length ? this.field.fields[0].rules : this.field.rules;
    this.validator = configControl?.validator;

    const maxFileSizeRule = rules?.find(i => i[0] === 'max');

    if (!_.isNil(maxFileSizeRule) && maxFileSizeRule[1]?.length && !isNaN(Number(maxFileSizeRule[1][0]))) {
      const maxFileSize: string = (Number(maxFileSizeRule[1][0]) / 1.024 / 1000).toFixed(1);

      this.maxFileSize = Number(maxFileSize).toString();
    }

    const mimetypesRule = rules?.find(i => i[0] === 'mimetypes');

    if (mimetypesRule) {
      const formats: string[] = [];
      mimetypesRule[1].forEach((format: FormFieldRuleValue) => {
        const formatValue = mapMimetypesToFormat(String(format) as EMimetypes);

        if (formatValue && !formats.includes(formatValue)) {
          formats.push(formatValue);
        }
      });

      this.formats = formats.join(', ');
      this.fileAccept = this.getFileAccept(formats);

      this.cdr.detectChanges();
    } else {
      this.formats = ACCEPT_TYPES_FORMAT;
      this.fileAccept = this.getFileAccept(ACCEPT_TYPES_FORMAT.split(', '));
    }
  }

  public fileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = Array.from(input.files);
    this.fileKeeper.addFiles(files);
    this.customControl.setValue(this.fileKeeper.files);
    this.customControl.markAsTouched();
    this.cdr.detectChanges();
    input.value = '';
  }

  public deleteFile(i: number): void {
    this.fileKeeper.removeFile(i);
    this.customControl.setValue(this.fileKeeper.files);
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.fileKeeper.clear();
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export function mapMimetypesToFormat(format: EMimetypes): EFormat {
  switch (format) {
    case EMimetypes.Xml:
    case EMimetypes.Xml2:
      return EFormat.Xml;

    case EMimetypes.Zip:
    case EMimetypes.Zip2:
      return EFormat.Zip;

    case EMimetypes.Gzip:
    case EMimetypes.Gzip2:
      return EFormat.Gzip;

    case EMimetypes.Pdf:
      return EFormat.Pdf;

    case EMimetypes.Jpeg:
      return EFormat.Jpeg;

    case EMimetypes.Png:
      return EFormat.Png;

    case EMimetypes.Jpg:
      return EFormat.Jpg;

    case EMimetypes.Gif:
      return EFormat.Gif;

    case EMimetypes.Asf:
    case EMimetypes.Asf2:
      return EFormat.Asf;

    case EMimetypes.Avi:
    case EMimetypes.Avi2:
      return EFormat.Avi;

    case EMimetypes.Flv:
      return EFormat.Flv;

    case EMimetypes.M4v:
    case EMimetypes.M4v2:
      return EFormat.M4v;

    case EMimetypes.Mov:
      return EFormat.Mov;

    case EMimetypes.Mp4:
      return EFormat.Mp4;

    case EMimetypes.Mpeg:
    case EMimetypes.Mpeg2:
      return EFormat.Mpeg;

    case EMimetypes.Swf:
      return EFormat.Swf;

    case EMimetypes.Txt:
      return EFormat.Txt;

    case EMimetypes.Wmv:
      return EFormat.Wmv;

    default:
      return;
  }
}

enum EMimetypes {
  Xml = 'application/xml',
  Xml2 = 'text/xml',
  Zip = 'application/zip',
  Zip2 = 'application/x-zip-compressed',
  Gzip = 'application/gzip',
  Gzip2 = 'application/x-gzip',
  Pdf = 'application/pdf',
  Swf = 'application/x-shockwave-flash',
  Jpeg = 'image/jpeg',
  Jpg = 'image/jpg',
  Png = 'image/png',
  Gif = 'image/gif',
  Asf = 'video/asf',
  Asf2 = 'video/x-ms-asf',
  Avi = 'video/avi',
  Avi2 = 'video/x-msvideo',
  Flv = 'video/x-flv',
  M4v = 'video/m4v',
  M4v2 = 'video/x-m4v',
  Mov = 'video/quicktime',
  Mp4 = 'video/mp4',
  Mpeg = 'video/mpeg',
  Mpeg2 = 'video/x-mpeg',
  Txt = 'text/plain',
  Wmv = 'video/x-ms-wmv',
}

enum EFormat {
  Xml = 'XML',
  Zip = 'ZIP',
  Gzip = 'GZ',
  Pdf = 'PDF',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Png = 'PNG',
  Gif = 'GIF',
  Asf = 'ASF',
  Avi = 'AVI',
  Flv = 'FLV',
  M4v = 'M4V',
  Mov = 'MOV',
  Mp4 = 'MP4',
  Mpeg = 'MPEG',
  Swf = 'SWF',
  Txt = 'TXT',
  Wmv = 'WMV',
}
