import {fromEvent, Observable} from 'rxjs';
import {filter, map, share} from 'rxjs/operators';

export class B2traderStandaloneConnection {
  private readonly windowMessages$ = fromEvent<MessageEvent<{type: string; payload: unknown}>>(window, 'message').pipe(
    map(event => event.data),
    share(),
  );

  public sendMessage(type: string, payload?: unknown): void {
    window.parent.postMessage({type, payload}, '*');
  }

  public get<T>(type: string): Observable<T> {
    return this.windowMessages$.pipe(
      filter(data => data.type === type),
      map(data => data.payload as T),
    );
  }
}
