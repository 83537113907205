import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ABaseConnectionService} from '@app/core/base-connection.service';
import {IBlobInfo} from '@app/pbsr/interfaces/blob-info.interface';
import {Environment} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';
import {Base64} from 'js-base64';
import moment, {Moment} from 'moment';
import {Observable} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';

import {THttpHeaders, THttpParams} from './types/types';

@Injectable({providedIn: 'root'})
export class ConnectionService extends ABaseConnectionService {
  private static readonly FILENAME_HEADER = 'x-base64-file-name';

  constructor(
    environment: Environment,
    router: Router,
    translateService: TranslateService,
    private readonly httpClient: HttpClient,
  ) {
    super(environment, router, translateService);
  }

  private mapHttpParams(params: THttpParams): HttpParams {
    return Object.keys(params).reduce((hp, key) => {
      let value = params[key];

      if (value instanceof moment) {
        value = (value as Moment).toISOString();
      }

      if (Array.isArray(value)) {
        const isParamKeyArray = /]$/gi.test(key);
        return value.reduce((hpInternal, v) => hpInternal.append(isParamKeyArray ? key : `${key}[]`, String(v)), hp);
      }

      return hp.append(key, String(value));
    }, new HttpParams());
  }

  public get<T>(url: string, params?: THttpParams, headers?: THttpHeaders): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(authHeaders =>
        this.httpClient.get<T>(this.apiUrl + url, {
          withCredentials: true,
          headers: {...authHeaders, ...headers},
          params: params ? this.mapHttpParams(params) : undefined,
        }),
      ),
    );
  }

  public post<T>(url: string, body): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        this.httpClient.post<T>(this.apiUrl + url, body, {
          withCredentials: true,
          headers,
        }),
      ),
    );
  }

  public put<T>(url: string, body?): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        this.httpClient.put<T>(this.apiUrl + url, body, {
          withCredentials: true,
          headers,
        }),
      ),
    );
  }

  public patch<T>(url: string, body?): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        this.httpClient.patch<T>(this.apiUrl + url, body, {
          withCredentials: true,
          headers,
        }),
      ),
    );
  }

  public delete<T>(url: string, params?: THttpParams): Observable<T> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        this.httpClient.delete<T>(this.apiUrl + url, {
          withCredentials: true,
          headers,
          params: params ? this.mapHttpParams(params) : undefined,
        }),
      ),
    );
  }

  public getBlob(url: string, params?: THttpParams): Observable<IBlobInfo> {
    return this.getHttpHeaders().pipe(
      switchMap(headers =>
        this.httpClient
          .get(this.apiUrl + url, {
            withCredentials: true,
            headers,
            responseType: 'blob',
            observe: 'response',
            params: params ? this.mapHttpParams(params) : undefined,
          })
          .pipe(
            map(value => {
              return {
                blob: value.body,
                name: this.getFilenameFromHeader(value.headers),
              };
            }),
          ),
      ),
    );
  }

  private getFilenameFromHeader(headers: HttpHeaders): string | undefined {
    const base64Filename = headers.get(ConnectionService.FILENAME_HEADER);

    try {
      return Base64.fromBase64(base64Filename);
    } catch {
      return;
    }
  }
}
