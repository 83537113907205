import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {DialogModule} from '@app/ui/dialog/dialog.module';
import {OfflineService} from '@app/ui/offline/offline.service';
import {TranslateModule} from '@ngx-translate/core';

import {OfflineComponent} from './offline.component';

@NgModule({
  declarations: [OfflineComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule, DialogModule],
  providers: [OfflineService],
  exports: [OfflineComponent],
})
export class OfflineModule {}
