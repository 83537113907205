import {IB2MarginTradesMessage} from '@app/trading-board/interfaces/b2margin/b2margin-trades-message';
import {plainToInstance} from 'class-transformer';
import {first} from 'rxjs/operators';

import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {EEventHandlersReceived} from '../../../event-handlers/event-handlers.enum';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {Trade} from '../../../models/trade';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class B2marginTradesInternalEventHandler extends AEventHandler<IB2MarginTradesMessage> {
  public type = EB2MarginEventType.TimeAndSale;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2MarginTradesMessage): void {
    const trades = resource.body.reduce((acc, record) => acc.concat(record.list), []);

    this.store.visibleInstrumentsSymbolMap$.pipe(first()).subscribe(visibleInstruments => {
      this.store.messages$.next({
        type: EEventHandlersReceived.Trades,
        payload: plainToInstance(Trade, Trade.fromB2Margin(trades, visibleInstruments)),
      });
    });
  }
}

b2MarginInternalEventHandlers.push(B2marginTradesInternalEventHandler);
