import {Injectable} from '@angular/core';
import {MoexDatafeedService} from '@app/trading-board/datafeed/moex-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {Tick} from '@app/trading-board/models/level1';

@Injectable()
export class MoexQuoteService extends AEventHandler<Map<string, Tick>> {
  public readonly type = EEventHandlersReceived.Ticks;

  constructor(private readonly moexDatafeedService: MoexDatafeedService) {
    super();
  }

  public handleMessage(resource: Map<string, Tick>): void {
    this.moexDatafeedService.quotes$.next(resource);
  }
}
