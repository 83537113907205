import {Injectable, OnDestroy} from '@angular/core';
import {DATA_FEED_DEBOUNCE_IN_MS} from '@app/core/constants/common';
import {GridsterProviders} from '@app/core/models/gridster';
import {betterThrottle} from '@app/core/utils/better-throttle';
import {filterNil} from '@app/core/utils/rxjs-filters';
import {shareReplayWithRef} from '@app/core/utils/share-replay-with-ref';
import {IAccountTradingData} from '@app/pbsr/interfaces/account-trading-data.interface';
import {ITradingStatusData} from '@app/pbsr/interfaces/trading-status-data.interface';
import {IParentAccount} from '@app/trading-board/interfaces/moex/moex-accounts-info';
import {IMoexMessage} from '@app/trading-board/interfaces/moex/moex-message';
import {IMoexOrderData} from '@app/trading-board/interfaces/moex/moex-order-data';
import {IMoexOrderResponse} from '@app/trading-board/interfaces/moex/order-response';
import {IRawPortfolioPosition} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-portfolio-position';
import {Tick} from '@app/trading-board/models/level1';
import {PortfolioSummary} from '@app/trading-board/models/moex/portfolio-summary';
import {RudataInstrument} from '@app/trading-board/models/moex/rudata-instrument';
import {DecimalHelper} from '@b2broker/decimal-helper';
import {Bar, ResolutionString} from '@b2broker/trading.view.charting.library/charting_library/datafeed-api';
import {plainToClass, plainToInstance} from 'class-transformer';
import * as _ from 'lodash-es';
import {nanoid} from 'nanoid';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  filter,
  finalize,
  last,
  map,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
  firstValueFrom,
} from 'rxjs';

import {EConnectionLevel} from '../enum/connection-level';
import {EMoexEventHandlersReceived, EMoexEventHandlersRequest} from '../event-handlers/moex/moex-event-handlers.enum';
import {IChartHistoryRequest} from '../interfaces/b2trader/chart-history-request';
import {ISubscribeChartRequest} from '../interfaces/b2trader/subscribe-chart-request';
import {IDataEvent} from '../interfaces/data-event.ts';
import {IInstrument} from '../interfaces/instrument';
import {IBarsHistoryRequest} from '../interfaces/moex/bars-history-request.interface';
import {IMoexCancelOrderResponse} from '../interfaces/moex/cancel-order-response';
import {IChartHistoryWithTimeLinksResponse} from '../interfaces/moex/chart-history-with-time-links-response.interface';
import {IReplaySubjectWithCount} from '../interfaces/replay-subject-with-count';
import {ISingleRequestMessage} from '../interfaces/single-request-message';
import {IStreamWithRefCount} from '../interfaces/stream-with-ref-count';
import {Level1Collector} from '../models/level1-collector';
import {Level2} from '../models/level2';
import {BondCoupon} from '../models/moex/bond-coupon';
import {WarpOrder} from '../models/moex/order';
import {PortfolioPosition} from '../models/moex/portfolio-position';
import {SymbolQuote} from '../models/moex/symbol-quote';
import {WarpTrade} from '../models/moex/warp-trade';
import {Trade} from '../models/trade';
import {WorkerConnectionService} from '../services/worker-connection.service';
import {ATradeDatafeed} from './trade-datafeed.abstract';

@Injectable()
export class MoexDatafeedService extends ATradeDatafeed implements OnDestroy {
  protected static readonly SHARED_WORKER_PATH = '/assets/b2trader.shared-worker.js';
  protected static readonly WORKER_PATH = '/assets/b2trader.web-worker.js';

  private readonly destroyer$ = new Subject<void>();

  private readonly portfolioPositionsStreams = new Map<string, Observable<Map<string, PortfolioPosition>>>();

  private isInitialized = false;

  public readonly provider: GridsterProviders;

  // NOTE: We do not use this field (value always null), it is only needed to preserve the possibility of extends from ATradeDatafeed,
  // work with instruments for PBSR is implemented in WarpInstrumentsStoreService.
  // More info in https://b2btech.atlassian.net/browse/FDP-18218?focusedCommentId=352630
  public readonly instruments$ = new BehaviorSubject(null);

  public readonly chartDataTopics = new Map<string, IReplaySubjectWithCount<Bar>>();
  public readonly level1$ = new BehaviorSubject<Level1Collector>(new Level1Collector());

  public readonly orderBookStreamMap = new Map<string, IStreamWithRefCount<Level2>>();
  public readonly time$ = new BehaviorSubject<number>(0);

  public readonly quotesSubscribersCount = new Map<string, number>();
  public readonly quotes$ = new BehaviorSubject(new Map<string, Tick>());
  public readonly rudataFinToolsRefData = new Map<string, RudataInstrument>();
  public readonly bondCouponsData = new Map<string, BondCoupon[]>();

  public readonly portfolioStopOrdersStreams = new Map<string, IStreamWithRefCount<WarpOrder>>();
  public readonly portfolioOrdersStreams = new Map<string, IStreamWithRefCount<WarpOrder>>();
  public readonly portfolioTradesStreams = new Map<string, IStreamWithRefCount<WarpTrade>>();
  public readonly tradingStatusDataStreams = new Map<string, IReplaySubjectWithCount<ITradingStatusData>>();

  public readonly cancelStopOrder$ = new Subject<string>();
  public readonly cancelOrder$ = new Subject<string>();

  public readonly accountsValue$ = new BehaviorSubject<IParentAccount[] | null>(null);
  public readonly accounts$ = this.accountsValue$.pipe(filterNil(), shareReplayWithRef());

  public readonly currentAccountBuyingPowerChanges$ = new ReplaySubject<string>(1);
  public readonly currentAccount$ = new ReplaySubject<IParentAccount>(1);
  public readonly currentSubAccount$ = this.currentAccount$.pipe(
    map(currentAccount =>
      currentAccount.subAccounts.find(subAccount => subAccount.subAccId === currentAccount.selectedSubAccount),
    ),
  );

  public readonly inactiveSubAccount$ = this.currentAccount$.pipe(
    map(account => account.subAccounts.find(subAccount => subAccount.subAccId !== account.selectedSubAccount)),
    shareReplayWithRef(),
  );

  public readonly accountCaptionsByAccountId$ = this.accounts$.pipe(
    map(accounts => {
      const captionsById = new Map<string, string>();
      accounts.forEach(account => {
        captionsById.set(account.subAccId, account.caption);

        account.subAccounts.forEach(subAccount => captionsById.set(subAccount.subAccId, subAccount.caption));
      });

      return captionsById;
    }),
  );

  public readonly modifyBuyOrderBuyingPower$ = new BehaviorSubject<undefined | DecimalHelper>(undefined);

  constructor(private readonly workerConnectionService: WorkerConnectionService) {
    super();

    combineLatest([this.accounts$.pipe(filterNil()), this.finishedInitialize$])
      .pipe(
        switchMap(([accounts]) => {
          const allSubAccountsStreams: Observable<PortfolioSummary>[][] = accounts.map(parentAccount =>
            parentAccount.subAccounts.reduce((acc, subAccount) => {
              acc.push(
                from(this.getPortfolioSummary(subAccount.tradeCode)).pipe(
                  switchMap(summaryStream => summaryStream),
                  tap(accountSummary => {
                    this.currentAccountBuyingPowerChanges$.next(accountSummary.buyingPower.toString());
                    subAccount.portfolioSummary = accountSummary;
                  }),
                  last(),
                ),
              );

              return acc;
            }, []),
          );

          const subStreams$ = _.flatten(allSubAccountsStreams);

          return combineLatest(subStreams$);
        }),

        takeUntil(this.destroyer$),
      )
      .subscribe();
  }

  public getPortfolioPositions(portfolioId: string): Observable<PortfolioPosition[]> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetPortfolioPositions,
      payload: {portfolioId, id},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<IRawPortfolioPosition[]>>) =>
          message.type === EMoexEventHandlersRequest.GetPortfolioPositions && message.payload.id === id,
      ),
      take(1),
      map(message => {
        const portfolioPositions = message.payload.data.map(rawPortfolioPosition => {
          return plainToInstance(PortfolioPosition, PortfolioPosition.adapt(rawPortfolioPosition));
        });

        return portfolioPositions;
      }),
    );
  }

  public getSymbolQuotes(symbols: string): Observable<SymbolQuote[]> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetSymbolQuotes,
      payload: {symbols, id},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<SymbolQuote[]>>) =>
          message.type === EMoexEventHandlersRequest.GetSymbolQuotes && message.payload.id === id,
      ),
      take(1),
      map(message => message.payload.data),
    );
  }

  public getTicks(symbols: string): Observable<Tick[]> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetSymbolTicks,
      payload: {symbols, id},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<Tick[]>>) =>
          message.type === EMoexEventHandlersRequest.GetSymbolTicks && message.payload.id === id,
      ),
      take(1),
      map(message => message.payload.data),
    );
  }

  public getPortfolioSummary(portfolioId: string): Promise<Observable<PortfolioSummary>> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.SubscribePortfolioSummary,
      payload: {portfolioId, id},
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<Observable<PortfolioSummary>>>) =>
            message.type === EMoexEventHandlersRequest.SubscribePortfolioSummary && message.payload.id === id,
        ),
        map(message => message.payload.data),
        take(1),
      ),
    );
  }

  public subscribeInstrument(symbol: string): Observable<ITradingStatusData> {
    if (!this.tradingStatusDataStreams.get(symbol)) {
      this.tradingStatusDataStreams.set(symbol, {
        count: 0,
        subject: new ReplaySubject<ITradingStatusData>(),
      });

      this.workerConnectionService.sendMessage({
        type: EMoexEventHandlersRequest.SubscribeInstrument,
        payload: {
          symbol,
        },
      });
    }

    const subscription = this.tradingStatusDataStreams.get(symbol);
    subscription.count++;

    return subscription.subject.pipe(
      finalize(() => {
        subscription.count--;

        if (subscription.count < 1) {
          this.workerConnectionService.sendMessage({
            type: EMoexEventHandlersRequest.UnsubscribeInstrument,
            payload: {
              symbol,
            },
          });

          subscription.subject.unsubscribe();
          this.tradingStatusDataStreams.delete(symbol);
        }
      }),
    );
  }

  public subscribeToPortfolioPositions(portfolioId: string): Observable<Map<string, PortfolioPosition>> {
    const streamRef = this.portfolioPositionsStreams.get(portfolioId);

    if (!streamRef) {
      const id = nanoid();
      const portfolioPositions = new Map<string, PortfolioPosition>();

      const portfolioPositionsSubs = this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<Observable<IMoexMessage<IRawPortfolioPosition>>>>) =>
            message.type === EMoexEventHandlersRequest.SubscribePortfolioPositions && message.payload.id === id,
        ),
        map(({payload: {data}}) => data),
        take(1),
        switchMap(stream => stream),
        skipWhile(message => {
          if (!message.requestGuid) {
            portfolioPositions.set(
              message.data.symbol,
              plainToInstance(PortfolioPosition, PortfolioPosition.adapt(message.data)),
            );
          }

          return !message.requestGuid;
        }),
        map(message => {
          if (message.requestGuid) {
            return portfolioPositions;
          }

          portfolioPositions.set(
            message.data.symbol,
            plainToInstance(PortfolioPosition, PortfolioPosition.adapt(message.data)),
          );

          return portfolioPositions;
        }),
        betterThrottle(DATA_FEED_DEBOUNCE_IN_MS),
        finalize(() => this.portfolioPositionsStreams.delete(portfolioId)),
        shareReplayWithRef(),
        takeUntil(this.destroyer$),
      ) as Observable<Map<string, PortfolioPosition>>;

      this.portfolioPositionsStreams.set(portfolioId, portfolioPositionsSubs);

      this.workerConnectionService.sendMessage({
        type: EMoexEventHandlersRequest.SubscribePortfolioPositions,
        payload: {portfolioId, id},
      });
    }

    return this.portfolioPositionsStreams.get(portfolioId);
  }

  public updateQuotesSubscriptions(symbolsToRemove: string[], symbolsToAdd: string[]): void {
    symbolsToRemove.forEach(symbol => {
      const quoteSubscribersCount = this.quotesSubscribersCount.get(symbol);
      if (!quoteSubscribersCount) {
        return;
      }
      this.quotesSubscribersCount.set(symbol, quoteSubscribersCount - 1);
    });

    symbolsToAdd.forEach(symbol => {
      const quoteSubscribersCount = this.quotesSubscribersCount.get(symbol);
      if (!quoteSubscribersCount) {
        this.quotesSubscribersCount.set(symbol, 1);

        return;
      }
      this.quotesSubscribersCount.set(symbol, quoteSubscribersCount + 1);
    });

    this.quotesSubscribersCount.forEach((count, symbol) => {
      if (count <= 0) {
        this.workerConnectionService.sendMessage<string>({
          type: EMoexEventHandlersRequest.UnsubscribeQuotes,
          payload: symbol,
        });

        this.quotesSubscribersCount.delete(symbol);
        return;
      }

      this.workerConnectionService.sendMessage<string>({
        type: EMoexEventHandlersRequest.SubscribeQuotes,
        payload: symbol,
      });
    });
  }

  public getBondCoupons(fintoolId: string): Promise<BondCoupon[] | undefined> {
    if (this.bondCouponsData.has(fintoolId)) {
      return Promise.resolve(this.bondCouponsData.get(fintoolId));
    }

    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetRudataBondCoupons,
      payload: {id, data: fintoolId},
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<BondCoupon[] | undefined>>) =>
            message.type === EMoexEventHandlersRequest.GetRudataBondCoupons && message.payload.id === id,
        ),
        take(1),
        map(({payload: {data}}) => {
          if (!data) {
            return undefined;
          }

          this.bondCouponsData.set(fintoolId, data);
          return data;
        }),
      ),
    );
  }

  public getFinToolRefData(isinToSymbol: Map<string, string>): Promise<void> {
    const cachedIsins = Array.from(this.rudataFinToolsRefData.values()).map(item => item.isincode);
    const newIsins = _.difference(Array.from(isinToSymbol.keys()), cachedIsins);

    if (newIsins.length === 0) {
      return Promise.resolve();
    }

    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetRudataFintoolRefData,
      payload: {id, data: isinToSymbol},
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<Map<string, RudataInstrument> | undefined>>) =>
            message.type === EMoexEventHandlersRequest.GetRudataFintoolRefData && message.payload.id === id,
        ),
        take(1),
        map(({payload: {data}}) => {
          if (!data) {
            return;
          }

          data.forEach((value, key) => this.rudataFinToolsRefData.set(key, value));
        }),
      ),
    );
  }

  public getHistoryInstrumentClose(
    symbolWithSeparator: string,
    resolution: ResolutionString,
    rangeStartDate: number,
    rangeEndDate: number,
  ): Promise<Bar[]> {
    const id = nanoid();

    this.workerConnectionService.sendMessage<IChartHistoryRequest>({
      type: EMoexEventHandlersRequest.InstrumentQuoteHistory,
      payload: {
        id,
        resolution,
        symbol: symbolWithSeparator,
        rangeEndDate,
        rangeStartDate,
      },
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<Bar[]>>) =>
            message.type === EMoexEventHandlersRequest.InstrumentQuoteHistory && message.payload.id === id,
        ),
        take(1),
        map((response: IDataEvent<ISingleRequestMessage<Bar[]>>) => response.payload.data),
      ),
    );
  }

  public getLevel2({symbolWithSeparator}: IInstrument): Observable<Level2> {
    let streamRef = this.orderBookStreamMap.get(symbolWithSeparator);

    if (!streamRef) {
      const initialOrderBookValues: Level2 = plainToClass(Level2, {
        symbolWithSeparator,
        asks: [],
        bids: [],
      });

      streamRef = {subscribers: 0, stream: new BehaviorSubject(initialOrderBookValues)};

      this.orderBookStreamMap.set(symbolWithSeparator, streamRef);

      this.workerConnectionService.sendMessage<string>({
        type: EMoexEventHandlersRequest.SubscribeOrderBook,
        payload: symbolWithSeparator,
      });
    }

    streamRef.subscribers++;

    return streamRef.stream.pipe(
      finalize(() => {
        streamRef.subscribers--;

        if (streamRef.subscribers < 1) {
          this.workerConnectionService.sendMessage<string>({
            type: EMoexEventHandlersRequest.UnsubscribeOrderBook,
            payload: symbolWithSeparator,
          });

          streamRef.stream.unsubscribe();
          this.orderBookStreamMap.delete(symbolWithSeparator);
        }
      }),
    );
  }

  public getBarsData$(request: IBarsHistoryRequest): Observable<IChartHistoryWithTimeLinksResponse> {
    const id = nanoid();

    this.workerConnectionService.sendMessage<{id: string; historyRequest: IBarsHistoryRequest}>({
      type: EMoexEventHandlersRequest.ChartHistory,
      payload: {
        id,
        historyRequest: request,
      },
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<IChartHistoryWithTimeLinksResponse>>) =>
          message.type === EMoexEventHandlersRequest.ChartHistory && message.payload.id === id,
      ),
      take(1),
      map((response: IDataEvent<ISingleRequestMessage<IChartHistoryWithTimeLinksResponse>>) => response.payload.data),
    );
  }

  public getBar$(symbol: string, resolution: ResolutionString, rangeStartDate?: number): Observable<Bar> {
    if (!this.chartDataTopics.get(symbol)) {
      this.chartDataTopics.set(symbol, {
        count: 0,
        subject: new ReplaySubject<Bar>(),
      });

      this.workerConnectionService.sendMessage<ISubscribeChartRequest>({
        type: EMoexEventHandlersRequest.SubscribeChart,
        payload: {
          symbol,
          resolution,
          from: rangeStartDate ?? Date.now(),
        },
      });
    }

    const subscription = this.chartDataTopics.get(symbol);
    subscription.count++;

    return subscription.subject.pipe(
      filter(bar => !!bar),
      finalize(() => {
        subscription.count--;

        if (subscription.count < 1) {
          this.workerConnectionService.sendMessage({
            type: EMoexEventHandlersRequest.UnsubscribeChart,
            payload: symbol,
          });

          subscription.subject.unsubscribe();
          this.chartDataTopics.delete(symbol);
        }
      }),
    );
  }

  public subscribeToPortfolioOrders(accountTradingData: IAccountTradingData): Observable<WarpOrder> {
    let streamRef = this.portfolioOrdersStreams.get(accountTradingData.subAccountId);
    let portfolioPositionsSubs = Subscription.EMPTY;

    if (!streamRef) {
      const id = nanoid();
      streamRef = {subscribers: 0, stream: new ReplaySubject(1)};
      this.portfolioOrdersStreams.set(accountTradingData.subAccountId, streamRef);

      portfolioPositionsSubs = this.workerConnectionService.messages$
        .pipe(
          filter(
            (message: IDataEvent<ISingleRequestMessage<Observable<WarpOrder>>>) =>
              message.type === EMoexEventHandlersRequest.SubscribePortfolioOrders && message.payload.id === id,
          ),
          map(({payload: {data}}) => data),
          take(1),
          switchMap(stream$ => stream$),
          tap(portfolioPosition => streamRef.stream.next(portfolioPosition)),
          takeUntil(this.destroyer$),
        )
        .subscribe();

      this.workerConnectionService.sendMessage({
        type: EMoexEventHandlersRequest.SubscribePortfolioOrders,
        payload: {
          id,
          accountTradingData,
        },
      });
    }

    streamRef.subscribers++;

    return streamRef.stream.pipe(
      finalize(() => {
        streamRef.subscribers--;

        if (streamRef.subscribers < 1) {
          portfolioPositionsSubs.unsubscribe();
          streamRef.stream.unsubscribe();
          this.portfolioOrdersStreams.delete(accountTradingData.subAccountId);
        }
      }),
    );
  }

  public subscribeToPortfolioStopOrders(accountTradingData: IAccountTradingData): Observable<WarpOrder> {
    let streamRef = this.portfolioStopOrdersStreams.get(accountTradingData.subAccountId);
    let portfolioPositionsSubs = Subscription.EMPTY;

    if (!streamRef) {
      const id = nanoid();
      streamRef = {subscribers: 0, stream: new ReplaySubject(1)};
      this.portfolioStopOrdersStreams.set(accountTradingData.subAccountId, streamRef);

      portfolioPositionsSubs = this.workerConnectionService.messages$
        .pipe(
          filter(
            (message: IDataEvent<ISingleRequestMessage<Observable<WarpOrder>>>) =>
              message.type === EMoexEventHandlersRequest.SubscribePortfolioStopOrders && message.payload.id === id,
          ),
          map(({payload: {data}}) => data),
          take(1),
          switchMap(stream$ => stream$),
          tap(portfolioPosition => streamRef.stream.next(portfolioPosition)),
          takeUntil(this.destroyer$),
        )
        .subscribe();

      this.workerConnectionService.sendMessage({
        type: EMoexEventHandlersRequest.SubscribePortfolioStopOrders,
        payload: {
          id,
          accountTradingData,
        },
      });
    }

    streamRef.subscribers++;

    return streamRef.stream.pipe(
      finalize(() => {
        streamRef.subscribers--;

        if (streamRef.subscribers < 1) {
          portfolioPositionsSubs.unsubscribe();
          streamRef.stream.unsubscribe();
          this.portfolioStopOrdersStreams.delete(accountTradingData.subAccountId);
        }
      }),
    );
  }

  public subscribeToPortfolioTrades(accountTradingData: IAccountTradingData): Observable<WarpTrade> {
    let streamRef = this.portfolioTradesStreams.get(accountTradingData.subAccountId);
    let portfolioPositionsSubs = Subscription.EMPTY;

    if (!streamRef) {
      const id = nanoid();
      streamRef = {subscribers: 0, stream: new ReplaySubject(1)};
      this.portfolioTradesStreams.set(accountTradingData.subAccountId, streamRef);

      portfolioPositionsSubs = this.workerConnectionService.messages$
        .pipe(
          filter(
            (message: IDataEvent<ISingleRequestMessage<Observable<WarpTrade>>>) =>
              message.type === EMoexEventHandlersRequest.SubscribePortfolioTrades && message.payload.id === id,
          ),
          map(({payload: {data}}) => data),
          take(1),
          switchMap(stream$ => stream$),
          tap(portfolioPosition => streamRef.stream.next(portfolioPosition)),
          takeUntil(this.destroyer$),
        )
        .subscribe();

      this.workerConnectionService.sendMessage({
        type: EMoexEventHandlersRequest.SubscribePortfolioTrades,
        payload: {
          id,
          accountTradingData,
        },
      });
    }

    streamRef.subscribers++;

    return streamRef.stream.pipe(
      finalize(() => {
        streamRef.subscribers--;

        if (streamRef.subscribers < 1) {
          portfolioPositionsSubs.unsubscribe();
          streamRef.stream.unsubscribe();
          this.portfolioTradesStreams.delete(accountTradingData.subAccountId);
        }
      }),
    );
  }

  public getPortfolioOrders(accountTradingData: IAccountTradingData): Observable<WarpOrder[]> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetPortfolioOrders,
      payload: {id, accountTradingData},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<WarpOrder[]>>) =>
          message.type === EMoexEventHandlersRequest.GetPortfolioOrders && message.payload.id === id,
      ),
      take(1),
      map((response: IDataEvent<ISingleRequestMessage<WarpOrder[]>>) => {
        return response.payload.data;
      }),
    );
  }

  public getPortfolioStopOrders(accountTradingData: IAccountTradingData): Observable<WarpOrder[]> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetPortfolioStopOrders,
      payload: {id, accountTradingData},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<WarpOrder[]>>) =>
          message.type === EMoexEventHandlersRequest.GetPortfolioStopOrders && message.payload.id === id,
      ),
      take(1),
      map((response: IDataEvent<ISingleRequestMessage<WarpOrder[]>>) => {
        return response.payload.data;
      }),
    );
  }

  public getPortfolioTrades(accountTradingData: IAccountTradingData): Observable<WarpTrade[]> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.GetPortfolioTrades,
      payload: {id, accountTradingData},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<WarpTrade[]>>) =>
          message.type === EMoexEventHandlersRequest.GetPortfolioTrades && message.payload.id === id,
      ),
      take(1),
      map((response: IDataEvent<ISingleRequestMessage<WarpTrade[]>>) => {
        return response.payload.data;
      }),
    );
  }

  public cancelStopOrder(tradeCode: string, orderId: string): Observable<IMoexCancelOrderResponse> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.CancelStopOrder,
      payload: {id, tradeCode, orderId},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<IMoexCancelOrderResponse>>) =>
          message.type === EMoexEventHandlersRequest.CancelStopOrder,
      ),
      take(1),
      map((response: IDataEvent<ISingleRequestMessage<IMoexCancelOrderResponse>>) => response.payload.data),
    );
  }

  public cancelOrder(tradeCode: string, orderId: string): Observable<IMoexCancelOrderResponse> {
    const id = nanoid();
    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.CancelOrder,
      payload: {id, tradeCode, orderId},
    });

    return this.workerConnectionService.messages$.pipe(
      filter(
        (message: IDataEvent<ISingleRequestMessage<IMoexCancelOrderResponse>>) =>
          message.type === EMoexEventHandlersRequest.CancelOrder,
      ),
      take(1),
      map((response: IDataEvent<ISingleRequestMessage<IMoexCancelOrderResponse>>) => response.payload.data),
    );
  }

  public modifyOrder(orderData: IMoexOrderData): Promise<IMoexOrderResponse> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.ModifyOrder,
      payload: {
        data: orderData,
        id,
      },
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<IMoexOrderResponse>>) =>
            message.type === EMoexEventHandlersRequest.ModifyOrder && message.payload.id === id,
        ),
        take(1),
        map(({payload: {data}}) => data),
      ),
    );
  }

  public createOrder(orderData: IMoexOrderData): Promise<IMoexOrderResponse> {
    const id = nanoid();

    this.workerConnectionService.sendMessage({
      type: EMoexEventHandlersRequest.CreateOrder,
      payload: {
        data: orderData,
        id,
      },
    });

    return firstValueFrom(
      this.workerConnectionService.messages$.pipe(
        filter(
          (message: IDataEvent<ISingleRequestMessage<IMoexOrderResponse>>) =>
            message.type === EMoexEventHandlersRequest.CreateOrder && message.payload.id === id,
        ),
        take(1),
        map(({payload: {data}}) => data),
      ),
    );
  }

  public getTrades$(): Observable<Trade[]> {
    throw new Error('Method not implemented.');
  }

  public hasConnect(): boolean {
    return this.isInitialized;
  }

  public terminate(): void {
    this.workerConnectionService.terminate();

    this.isInitialized = false;
    this.instruments$.next([]);
    this.quotesSubscribersCount.clear();

    this.portfolioPositionsStreams.clear();

    this.portfolioStopOrdersStreams.clear();
    this.portfolioOrdersStreams.clear();
    this.portfolioTradesStreams.clear();

    this.chartDataTopics.clear();
    this.tradingStatusDataStreams.clear();
    this.connectionClosed$.next();
  }

  public initialize(level: EConnectionLevel): void {
    this.workerConnectionService.initialize({
      level,
      webWorkerPath: MoexDatafeedService.WORKER_PATH,
      sharedWorkerPath: MoexDatafeedService.SHARED_WORKER_PATH,
    });

    this.isInitialized = true;

    this.workerConnectionService.messages$
      .pipe(
        filter(m => m.type === EMoexEventHandlersReceived.Connected),
        take(1),
        takeUntil(this.destroyer$),
      )
      .subscribe(() => {
        this.finishedInitialize$.next();
      });
  }

  public afterConnect(): void {
    throw new Error('Method not implemented.');
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.terminate();
  }
}
