import {DOCUMENT} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {TranslateService} from '@ngx-translate/core';

import {WINDOW} from '../tokens/window.token';
import {createUrlForNewVersion} from '../utils/create-url-for-new-version.function';

const enum EDesignVersion {
  New = 'new',
  Old = 'old',
}

const enum ECookieName {
  IsNewB2core = 'isNewB2core',
  UiVersion = 'uiVersion',
}

@Injectable({
  providedIn: 'root',
})
export class NewInterfaceInteractionService {
  private readonly window = inject(WINDOW);
  private readonly document = inject(DOCUMENT);
  private readonly translateService = inject(TranslateService);

  public goToNewInterface(): void {
    ALocaleStorage.DESIGN_VERSION.set(EDesignVersion.New);
    this.setCookie(ECookieName.IsNewB2core, 'true');
    this.setCookie(ECookieName.UiVersion, EDesignVersion.New);

    this.reloadPage();
  }

  public syncCurrentState(): void {
    this.setCookie(ECookieName.UiVersion, EDesignVersion.Old);

    const currentVersion = ALocaleStorage.DESIGN_VERSION.get();

    if (!currentVersion) {
      ALocaleStorage.DESIGN_VERSION.set(EDesignVersion.Old);

      return;
    }

    if (currentVersion === EDesignVersion.New) {
      this.goToNewInterface();
    }
  }

  private reloadPage(): void {
    this.window.history.pushState({}, '', createUrlForNewVersion(this.translateService.currentLang, this.window));
    this.window.location.reload();
  }

  private setCookie(key: string, value: string): void {
    this.document.cookie = `${key}=${value}; path=/`;
  }
}
