import {Injectable} from '@angular/core';

import {B2MarginDatafeedService} from '../../datafeed/b2margin-datafeed.service';
import {EEventHandlersReceived} from '../../event-handlers/event-handlers.enum';
import {Level2} from '../../models/level2';
import {AEventHandler} from '../event-handler.abstract';

@Injectable()
export class B2MarginLevel2Service extends AEventHandler<Level2[]> {
  public type = EEventHandlersReceived.Level2;

  constructor(private readonly b2marginDatafeedService: B2MarginDatafeedService) {
    super();
  }

  public handleMessage(resource: Level2[]): void {
    resource.forEach(data => {
      this.b2marginDatafeedService.level2.get(data.symbolWithSeparator)?.stream.next(data);
    });
  }
}
