import {EOrderSide} from '@app/trading-board/enum/moex/order-side';
import {EOrderType} from '@app/trading-board/enum/moex/order-type';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class HistoricalTrade {
  @Expose()
  public id: number;

  @Expose({name: 'client_id'})
  public clientId: number;

  @Expose({name: 'trade_date'})
  public tradeDate: number;

  @Expose({name: 'trade_time'})
  public tradeTime: number;

  @Expose({name: 'trade_num'})
  public tradeNum: string;

  @Expose({name: 'order_num'})
  public orderNum: string;

  @Expose({name: 'asset_isin'})
  public assetIsin: string;

  @Expose({name: 'asset_name'})
  public assetName: string;

  @Expose({name: 'security_code'})
  public securityCode: string;

  @Expose({name: 'sub_acc_an_code'})
  public subAccAnCode: string;

  @Expose({name: 'sub_acc_an_name'})
  public subAccAnName: string;

  @Expose({name: 'sub_acc_code'})
  public subAccCode: string;

  @Expose({name: 'sub_acc_name'})
  public subAccName: string;

  @Expose({name: 'quantity'})
  public quantity: string;

  @Expose({name: 'volume'})
  @Transform(({value}: {value: number}) => value.toString())
  public volume: string;

  @Expose({name: 'currency'})
  public currency: string;

  @Expose({name: 'as_price'})
  @Transform(({value}) => LazyDecimalHelper.from(value))
  public asPrice: LazyDecimalHelper;

  @Expose({name: 'l_size'})
  public lotSize: number;

  @Expose({name: 'l_quantity'})
  public lotQuantity: number;

  @Expose({name: 'settle_code'})
  public settleCode: number;

  @Expose({name: 'trader'})
  public trader: string;

  @Expose({name: 'traded_at'})
  public tradedAt: string;

  @Expose({name: 'order_type'})
  public orderType: EOrderType;

  @Expose()
  @Transform(({value}) => (value ? LazyDecimalHelper.from(value) : null))
  public commission: LazyDecimalHelper | null;

  @Expose({name: 'commission_currency'})
  public commissionCurrency: string | null;

  @Expose()
  public side: EOrderSide;
}
