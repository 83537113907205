<ng-container [formGroup]="group">
  <div class="file-upload" appDir>
    <mat-label *ngIf="field.label">{{ label | async }}</mat-label>

    <app-file-input
      [isHideBackgroundImageAfterFileAdding]="isHideBackgroundImageAfterFileAdding"
      [formControl]="customControl"
      [accept]="acceptTypes"
      [errors]="errors"
      [required]="isRequired && customControl.touched"
    >
    </app-file-input>

    <mat-error *ngFor="let error of group.errors | keys | slice: 0:1">
      {{ getErrorGroup(error) | async }}
    </mat-error>

    <mat-error *ngFor="let error of customControl.errors | keys | slice: 0:1">
      {{ getErrorControl(error) | async }}
    </mat-error>

    <app-file-message [rules]="rules" [acceptTypes]="acceptTypes"></app-file-message>
  </div>
</ng-container>
