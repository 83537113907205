import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ErrorDialogComponent, IErrorDialogData} from '@app/ui/error-dialog/error-dialog.component';
import {Environment} from '@env/environment.entities';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap, timeout} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ErrorsInterceptor implements HttpInterceptor {
  private dialogRef: MatDialogRef<ErrorDialogComponent, IErrorDialogData>;

  constructor(private readonly injector: Injector, private readonly environment: Environment) {}

  private handleResponse(response): void {
    if (response instanceof HttpResponse || response instanceof HttpErrorResponse) {
      if (
        !response ||
        response.status === 0 ||
        response.status === 504 ||
        response.status === 521 ||
        (response.status === 500 && !(response as HttpErrorResponse).error?.status)
      ) {
        this.showDialog();
      }
    }
  }

  private showDialog(): void {
    if (this.dialogRef) {
      return;
    }

    this.dialogRef = this.injector.get(MatDialog).open(ErrorDialogComponent, {
      width: '100%',
      height: '100%',
      closeOnNavigation: true,
      hasBackdrop: false,
      panelClass: ['error-dialog'],
      data: {
        header: of('Something went technically wrong'),
        text: of('We are going to do our best to fix it and get things back to normal'),
        emoji: 'emoji-hammer',
        textAccentButton: of('Refresh page'),
        textPrimaryButton: of('Go back'),
        onAccentButton: (): void => location.reload(),
        onPrimaryButton: (): void => window.history.back(),
      },
    });
  }

  public intercept<Request, Response>(req: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Response>> {
    return next.handle(req).pipe(
      timeout(this.environment.requestTimeoutInMs),
      catchError((err: {name?: string}) => {
        if (err.name === 'TimeoutError') {
          this.showDialog();
        } else {
          this.handleResponse(err);
        }
        return throwError(() => err);
      }),
      tap(r => this.handleResponse(r)),
    );
  }
}

export const ErrorsService = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorsInterceptor,
  multi: true,
};
