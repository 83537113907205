import {SECOND_IN_MS} from '@app/core/constants/common';
import {IResultApi} from '@app/core/contracts/i.result.api';
import {UnauthorizedException} from '@app/core/exceptions/unauthorized-exception';
import {defer, from, NEVER, Observable, of, timer} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {delay, first, map, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {AB2TraderAuth, TAuthHeadersFactory} from './b2trader-auth.abstract';
import {IB2TraderApiData} from './interfaces/b2trader-api-data';
import {IB2TraderTokenResponseDto} from './interfaces/b2trader-token-response.dto';
import {mapTokenResponse} from './mappers/b2trader-token-response.mapper';

export class B2TraderAuthPrivate extends AB2TraderAuth {
  private readonly timestampDelta = 30 * SECOND_IN_MS;

  private readonly authUrl = `${this.apiUrl}/api/v1/platforms/${this.platformType}/auth`;
  private readonly refreshUrl = `${this.apiUrl}/api/v1/platforms/${this.platformType}/refresh`;

  protected refreshTokenRequest$: Observable<IB2TraderApiData | null> | null = null;

  public readonly apiData$ = this.apiDataValue$.pipe(
    switchMap(data => this.refreshTokenRequest$ ?? of(data)),
    switchMap(data => {
      const remain = (data?.expirationTimestamp || 0) - Date.now() - this.timestampDelta;

      if (remain <= 0) {
        if (data) {
          this.refreshTokenRequest$ = this.generateRefreshTokenRequest(data);
          return this.refreshTokenRequest$;
        }
        return of(null);
      }

      return timer(remain).pipe(
        startWith(undefined as number),
        switchMap(res => {
          if (res === undefined) {
            return of(data);
          } else {
            this.refreshTokenRequest$ = this.generateRefreshTokenRequest(data);
            return this.refreshTokenRequest$;
          }
        }),
      );
    }),
  );

  constructor(
    protected readonly apiUrl: string,
    protected readonly platformType: string,
    protected readonly authHeadersFactory?: TAuthHeadersFactory,
  ) {
    super(apiUrl, platformType, authHeadersFactory);
  }

  protected generateRefreshTokenRequest(apiData: IB2TraderApiData): Observable<IB2TraderApiData | null> {
    return this.authHeadersFactory().pipe(
      switchMap(headers =>
        fromFetch<IB2TraderTokenResponseDto | IResultApi>(this.refreshUrl, {
          selector: res => (res.status < 400 ? res.json() : Promise.resolve()),
          method: 'POST',
          body: JSON.stringify({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            refresh_token: apiData.refreshToken,
          }),
          headers: {
            ...headers,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'content-type': 'application/json',
          },
        }),
      ),
      map(refreshResponse => {
        this.refreshTokenRequest$ = null;

        if (this.isB2coreResult(refreshResponse) && this.isUnauthorizedByB2core(refreshResponse)) {
          this.b2coreUnauthorized$.next();
        }

        if (refreshResponse && !this.isB2coreResult(refreshResponse)) {
          const newData = mapTokenResponse(refreshResponse);
          this.apiDataValue$.next(newData);
          return newData;
        }
        this.reset$.next(null);
        return null;
      }),
      shareReplay({refCount: true, bufferSize: 1}),
    );
  }

  protected getApiData(): Observable<IB2TraderApiData | undefined> {
    return this.authHeadersFactory().pipe(
      switchMap(authHeaders =>
        fromFetch<IB2TraderTokenResponseDto | IResultApi>(this.authUrl, {
          selector: res => (res.status < 400 ? res.json() : Promise.resolve()),
          method: 'POST',
          credentials: 'include',
          headers: {...authHeaders},
        }),
      ),
      map(res => {
        if (this.isB2coreResult(res) && this.isUnauthorizedByB2core(res)) {
          this.b2coreUnauthorized$.next();
        }

        if (!res || this.isB2coreResult(res)) {
          return undefined;
        }

        return mapTokenResponse(res);
      }),
    );
  }

  public reset(): void {
    this.apiDataValue$.pipe(first()).subscribe(apiData => {
      if (apiData) {
        this.refreshTokenRequest$ = this.generateRefreshTokenRequest(apiData);
      } else {
        super.reset();
      }
    });
  }

  public clear(): void {
    this.refreshTokenRequest$ = defer(() => this.getApiData());

    super.clear();
  }

  /**
   * Wrapper for fromFetch function, which adds base url and auth header.
   *
   * @param url - Request url.
   * @param options - Request options.
   * @param isSilentOnUnauthorized - When we cannot authorize: if true - returns NEVER, if false - returns undefined.
   * @returns Request result.
   */
  public fromFetch<T>(url: string, options?: RequestInit, isSilentOnUnauthorized = true): Observable<T | undefined> {
    return of(undefined).pipe(
      switchMap(() => {
        if (this.refreshTokenRequest$) {
          return this.refreshTokenRequest$.pipe(switchMap(v => (v ? of(v) : this.apiData$)));
        }
        return this.apiData$;
      }),
      first(),
      switchMap(apiData => {
        if (!apiData) {
          return isSilentOnUnauthorized ? NEVER : of(undefined);
        }

        return this.fromApiFetch(apiData, url, options).pipe(
          switchMap(res => {
            if (res.status !== 401) {
              return from(res.json().catch(err => err as T)) as Observable<T>;
            }

            this.refreshTokenRequest$ = this.generateRefreshTokenRequest(apiData);
            return this.refreshTokenRequest$.pipe(
              delay(300),
              switchMap(() => this.fromFetch<T>(url, options)),
            );
          }),
        );
      }),
    );
  }

  private isB2coreResult(response: IB2TraderTokenResponseDto | IResultApi | undefined): response is IResultApi {
    return response && 'status' in response;
  }

  private isUnauthorizedByB2core(response: IResultApi): boolean {
    return response.status === UnauthorizedException.STATUS_CODE;
  }
}
