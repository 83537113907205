import {DOCUMENT} from '@angular/common';
import {APP_INITIALIZER, Provider} from '@angular/core';
import {Environment} from '@env/environment.entities';

function addCustomerScriptsFactory(environment: Environment, document: Document): () => Promise<void> {
  return async () => {
    const {isAddCustomerScripts, cacheOff} = environment.appBuild;

    if (!isAddCustomerScripts) {
      return;
    }

    const customerScriptsText = await document.defaultView
      .fetch(`/assets/scripts/customer-scripts.js?cacheOff=${cacheOff}`)
      .then(response => response.text());

    const scriptElement = document.createElement('script');
    scriptElement.text = customerScriptsText;

    document.body.append(scriptElement);
  };
}

export const CustomerScriptsProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: addCustomerScriptsFactory,
  multi: true,
  deps: [Environment, DOCUMENT],
};
