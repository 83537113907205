import {HistoryOrderTO, OrderTemplateTO} from '@b2broker/b2margin-trade-models';
import {Expose, Transform} from 'class-transformer';

import {IOrderModelParam} from '../../interfaces/b2margin/order-model-param';
import {DecimalHelper} from '../decimal-helper';
import {AB2marginOrder} from './b2margin-order';

import StatusEnum = HistoryOrderTO.StatusEnum;
import OrderSideEnum = OrderTemplateTO.OrderSideEnum;

export class B2MarginHistoryOrder extends AB2marginOrder<IOrderModelParam<HistoryOrderTO>> {
  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<HistoryOrderTO>}) => raw.orderId)
  public orderId: number;

  @Expose()
  @Transform(({obj: {instrument}}: {obj: IOrderModelParam<HistoryOrderTO>}) => instrument?.symbolWithSeparator)
  public symbolWithSeparator: string;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<HistoryOrderTO>}) =>
    raw.quantity > 0 ? OrderSideEnum.BUY : OrderSideEnum.SELL,
  )
  public side: OrderSideEnum;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<HistoryOrderTO>}) => new Date(raw.createdTime))
  public time: Date;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<HistoryOrderTO>}) => raw.type)
  public type: string;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<HistoryOrderTO>}) => DecimalHelper.from(raw.remainingQuantity).abs())
  public quantity: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam<HistoryOrderTO>}) =>
    DecimalHelper.from(
      Number(raw.fillPrice) || Number(raw.limitPrice) || Number(raw.stopPrice) || 0,
      instrument.priceScale,
    ),
  )
  public price: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IOrderModelParam<HistoryOrderTO>}) => raw.status)
  public status: string;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IOrderModelParam<HistoryOrderTO>}) =>
    instrument ? DecimalHelper.from(raw.quantity).divide(instrument.lotSize).abs() : DecimalHelper.from(0),
  )
  public filledSize: DecimalHelper;

  public isInactive(): unknown {
    return !this.isFilled();
  }

  public isFilled(): unknown {
    return this.status === StatusEnum.COMPLETED;
  }

  public update({raw, instrument}: IOrderModelParam<HistoryOrderTO>): void {
    this.price = DecimalHelper.from(
      Number(raw.fillPrice) || Number(raw.limitPrice) || Number(raw.stopPrice) || 0,
      instrument.priceScale,
    );
    this.status = raw.status;
  }
}
