import {asapScheduler, BehaviorSubject, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {exhaustMap, observeOn} from 'rxjs/operators';

import {IB2TraderApiData} from './interfaces/b2trader-api-data';

export type TAuthHeadersFactory = () => Observable<HeadersInit>;

export abstract class AB2TraderAuth {
  public abstract readonly apiData$: Observable<IB2TraderApiData | undefined>;

  protected abstract getApiData(): Observable<IB2TraderApiData | undefined>;

  public abstract fromFetch<T>(url: string, options?: RequestInit): Observable<T>;

  protected readonly apiDataValue$ = new ReplaySubject<IB2TraderApiData | null>(1);
  protected readonly reset$ = new BehaviorSubject<void>(undefined);

  public readonly b2coreUnauthorized$ = new Subject<void>();

  protected constructor(
    protected readonly apiUrl: string,
    protected readonly platformType: string,
    protected readonly authHeadersFactory?: TAuthHeadersFactory,
  ) {
    this.reset$
      .pipe(
        observeOn(asapScheduler),
        exhaustMap(() => this.getApiData()),
      )
      .subscribe(data => {
        this.apiDataValue$.next(data);
      });
  }

  protected fromApiFetch(apiData: IB2TraderApiData, url: string, options?: RequestInit): Observable<Response> {
    return fromFetch(`${apiData.url}/${url}`, {
      selector: res => of(res),
      ...options,
      headers: {
        ...options?.headers,
        ...apiData.headers,
      },
    });
  }

  public clear(): void {
    this.apiDataValue$.next(null);
  }

  public reset(): void {
    this.apiDataValue$.next(null);
    this.reset$.next();
  }
}
