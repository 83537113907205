import {Injectable, Injector} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {ABaseFullscreenErrorService} from '@app/core/modules/errors/base-fullscreen-error.service';
import {IErrorDialogData} from '@app/ui/error-dialog/error-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SocketErrorService extends ABaseFullscreenErrorService {
  private readonly config: MatDialogConfig<IErrorDialogData> = {
    width: '100%',
    height: '100%',
    closeOnNavigation: true,
    hasBackdrop: false,
    panelClass: ['error-dialog'],
    data: {
      header: this.translateService.get('Pbsr.Services.SocketError.Title'),
      text: this.translateService.get('Pbsr.Services.SocketError.Message'),
      textAccentButton: this.translateService.get('Pbsr.Services.SocketError.RefreshButton'),
      onAccentButton: (): void => location.reload(),
    },
  };

  constructor(private readonly translateService: TranslateService, injector: Injector) {
    super(injector);
  }

  public show(): void {
    this.showDialog(this.config);
  }
}
