import {Expose, Transform} from 'class-transformer';

export class RatesPairs {
  public pairs: {
    [key: string]: {
      rate: {
        scale: number;
        value: string;
      } | null;
    };
  };

  @Expose()
  @Transform(({obj}) => new Date(obj.updated_at))
  public updatedAt: Date;

  @Expose()
  @Transform(({obj}) => new Date(obj.expired_at))
  public expiredAt: Date;
}
