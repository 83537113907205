import {EB2MarginEventType} from '../../../enum/b2margin/b2margin-event-type';
import {B2marginStateStore} from '../../../facades/b2margin-state-store';
import {IB2marginMessage} from '../../../interfaces/b2margin/b2margin-message';
import {IInstrumentVisibility} from '../../../interfaces/b2margin/instrument-visability';
import {b2MarginInternalEventHandlers, AEventHandler} from '../../event-handler.abstract';

export class InstrumentVisibilityInternalEventHandler extends AEventHandler<IB2marginMessage<IInstrumentVisibility[]>> {
  public type = EB2MarginEventType.InstrumentsVisibility;

  constructor(private readonly store: B2marginStateStore) {
    super();
  }

  public handleMessage(resource: IB2marginMessage<IInstrumentVisibility[]>): void {
    if (!resource.body.length) {
      return;
    }

    const {value: visibilities} = this.store.visibleInstrumentIds$;

    resource.body.forEach(i => (i.visible ? visibilities.add(i.instrumentId) : visibilities.delete(i.instrumentId)));
    this.store.visibleInstrumentIds$.next(visibilities);
  }
}

b2MarginInternalEventHandlers.push(InstrumentVisibilityInternalEventHandler);
