export enum EMoexEventHandlersRequest {
  ChartHistory = 'chartHistory',
  SubscribeOrderBook = 'subscribeOrderBook',
  UnsubscribeOrderBook = 'unsubscribeOrderBook',
  SubscribeChart = 'subscribeChart',
  UnsubscribeChart = 'unsubscribeChart',
  GetPortfolioOrders = 'getPortfolioOrders',
  GetPortfolioStopOrders = 'getPortfolioStopOrders',
  GetPortfolioTrades = 'getPortfolioTrades',
  SubscribePortfolioOrders = 'subscribePortfolioOrders',
  SubscribePortfolioStopOrders = 'subscribePortfolioStopOrders',
  SubscribePortfolioTrades = 'subscribePortfolioTrades',
  CancelStopOrder = 'cancelStopOrder',
  CancelOrder = 'cancelOrder',
  SubscribeQuotes = 'subscribeQuotes',
  UnsubscribeQuotes = 'unsubscribeQuotes',
  GetRudataFintoolRefData = 'getRudataFintoolRefData',
  InstrumentQuoteHistory = 'instrumentQuoteHistory',
  SubscribePortfolioPositions = 'subscribePortfolioPositions',
  SubscribePortfolioSummary = 'subscribePortfolioSummary',
  CreateOrder = 'createOrder',
  ModifyOrder = 'modifyOrder',
  GetPortfolioPositions = 'getPortfolioPositions',
  GetSymbolQuotes = 'getSymbolQuotes',
  GetRudataBondCoupons = 'getRudataBondCoupons',
  GetSymbolTicks = 'GetSymbolTicks',
  SubscribeInstrument = 'subscribeInstrument',
  UnsubscribeInstrument = 'unsubscribeInstrument',
}

export enum EMoexEventHandlersReceived {
  ChartHistory = 'chartHistory',
  Connected = 'connected',
  Level2 = 'level2',
  SubscribeChart = 'subscribeChart',
  GetPortfolioTrades = 'getPortfolioTrades',
  SubscribePortfolioOrders = 'subscribePortfolioOrders',
  SubscribePortfolioStopOrders = 'subscribePortfolioStopOrders',
  SubscribePortfolioTrades = 'subscribePortfolioTrades',
  CancelStopOrder = 'cancelStopOrder',
  CancelOrder = 'cancelOrder',
  PortfolioPositions = 'portfolioPositions',
  Accounts = 'accounts',
  Quotes = 'quotes',
  GetPortfolioPositions = 'getPortfolioPositions',
  GetSymbolQuotes = 'getSymbolQuotes',
  SubscribeInstrument = 'subscribeInstrument',
}
