import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormInput} from '@app/core/form/entities/form.input';
import {ErrorsService} from '@app/core/form/services/errors/errors.service';
import {LabelService} from '@app/core/form/services/label.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent extends FormInput implements OnInit {
  public required: boolean;

  constructor(labelsService: LabelService, errorsService: ErrorsService) {
    super(labelsService, errorsService);
  }

  public ngOnInit(): void {
    this.required = this.isRequired();
  }
}
