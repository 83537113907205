import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {ErrorDialogComponent} from '@app/ui/error-dialog/error-dialog.component';
import {Environment} from '@env/environment.entities';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class B2traderRedirectHomeService extends AEventHandler<void> implements OnDestroy {
  private dialogRef: MatDialogRef<ErrorDialogComponent>;

  public type = EB2TraderEventHandlersReceived.RedirectHome;

  constructor(
    private readonly router: Router,
    private readonly environment: Environment,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  public handleMessage(): void {
    if (this.environment.isPublic) {
      this.dialogRef = this.dialog.open<ErrorDialogComponent>(ErrorDialogComponent, {
        width: '100%',
        height: '100%',
        closeOnNavigation: true,
        hasBackdrop: false,
        panelClass: ['error-dialog'],
        data: {
          header: this.translateService.get('ErrorDialog.Common.Header'),
          text: this.translateService.get('ErrorDialog.Common.Text'),
          emoji: 'emoji-hammer',
          textAccentButton: this.translateService.get('ErrorDialog.AccentButton.Refresh'),
          textPrimaryButton: this.translateService.get('ErrorDialog.PrimaryButton.GoBack'),
          onAccentButton: () => location.reload(),
          onPrimaryButton: () => window.history.back(),
        },
      });
    } else {
      void this.router.navigate(['/']);
    }
  }

  public ngOnDestroy(): void {
    this.dialogRef.close();
  }
}
