import {Injectable, Optional} from '@angular/core';
import {Account} from '@app/core/models/account/account';
import {AccountService} from '@app/core/services/account.service';
import {B2MarginDatafeedService} from '@app/trading-board/datafeed/b2margin-datafeed.service';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {EEventHandlersReceived} from '@app/trading-board/event-handlers/event-handlers.enum';
import {AccountCollector} from '@app/trading-board/models/b2margin/account';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';

@Injectable()
export class B2marginAccountService extends AEventHandler<AccountCollector> {
  private readonly walletAccounts = new Map<string, Account>();
  public readonly type = EEventHandlersReceived.Accounts;

  constructor(
    private readonly dataFeed: B2MarginDatafeedService,
    @Optional() private readonly accountService?: AccountService,
  ) {
    super();

    this.accountService?.getAccountsAsync().subscribe(accounts => {
      accounts
        .filter(a => a.isAvailableForTrading)
        .forEach(account => this.walletAccounts.set(account.account_id, account));
    });
  }

  public handleMessage(accounts: AccountCollector): void {
    if (accounts.currentAccount.cashType === this.dataFeed.invalidCashType) {
      const account = accounts.getByCashType(this.dataFeed.validCashType);
      this.dataFeed.selectAccount(account.id);
      return;
    }

    Array.from(accounts).forEach(account => {
      const walletAccount = this.walletAccounts.get(account.walletAccountId);
      if (!walletAccount) {
        return;
      }

      account.trId = walletAccount.id;

      if (account.balance.isZero()) {
        account.balance = DecimalHelper.from(walletAccount?.report.balance.getObj(), account.precision);
      }
    });

    this.dataFeed.accounts$.next(accounts);
  }
}
