import {Casts, CastTypes, Model} from '@app/core/model';

export class AttachmentFeature extends Model {
  public id: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public created_at: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public updated_at: number;
  public filename: string;
  public mime: string;
  public size: string;

  public readonly casts: Casts = {
    id: [CastTypes.Number],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_at: [CastTypes.Number],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_at: [CastTypes.Number],
    filename: [CastTypes.String],
    mime: [CastTypes.String],
    size: [CastTypes.String],
  };
}
