/* eslint-disable @typescript-eslint/naming-convention */
import {Decimal} from '@app/core/decimal';
import {Casts, CastTypes, Model} from '@app/core/model';
import {ReportOptions} from '@app/core/models/account/report.options';

export class Report extends Model {
  private _balance: Decimal;
  private _credit: Decimal;
  private _equity: Decimal;
  private _margin: Decimal;
  private _marginFree: Decimal;
  private _freeFunds: Decimal;
  private _hold: Decimal;
  private _marginLevel: Decimal;
  private _pl: Decimal;

  public id?: number;
  public marginLeverage: number;
  public yield?: number;
  public drawdown?: number;
  public maxdrawdown: number;
  public invalid_at: string;
  public updated_at: string;
  public series_days_profit: number;
  public series_days_loss: number;
  public series_orders_profit: number;
  public series_orders_loss: number;
  public days_profit: number;
  public days_loss: number;
  public orders_profit: number;
  public orders_loss: number;
  public days_max_profit: string;
  public days_max_loss: string;
  public day_profit_loss: number;
  public month_profit_loss: string;
  public week_profit_loss: string;
  public prev_day_profit_loss: string;
  public prev_month_profit_loss: string;
  public prev_week_profit_loss: string;
  public options: ReportOptions;

  public expired: boolean;
  public expired_at: string;

  public readonly casts: Casts = {
    id: [CastTypes.Number, CastTypes.Undefined],
    balance: [Decimal, CastTypes.Null],
    credit: [Decimal],
    equity: [Decimal],
    expired: [CastTypes.Boolean],
    expired_at: [CastTypes.String, CastTypes.Undefined],
    margin: [Decimal],
    marginFree: [Decimal],
    freeFunds: [Decimal],
    hold: [Decimal],
    marginLeverage: [CastTypes.Number],
    yield: [CastTypes.Number, CastTypes.Undefined],
    drawdown: [CastTypes.Number, CastTypes.Undefined],
    maxdrawdown: [CastTypes.Number, CastTypes.Undefined],
    invalid_at: [CastTypes.String],
    updated_at: [CastTypes.String],
    series_days_profit: [CastTypes.Number, CastTypes.Undefined],
    series_days_loss: [CastTypes.Number, CastTypes.Undefined],
    series_orders_profit: [CastTypes.Number, CastTypes.Undefined],
    series_orders_loss: [CastTypes.Number, CastTypes.Undefined],
    days_profit: [CastTypes.Number, CastTypes.Undefined],
    days_loss: [CastTypes.Number, CastTypes.Undefined],
    orders_profit: [CastTypes.Number, CastTypes.Undefined],
    orders_loss: [CastTypes.Number, CastTypes.Undefined],
    days_max_profit: [CastTypes.String, CastTypes.Undefined],
    days_max_loss: [CastTypes.String, CastTypes.Undefined],
    day_profit_loss: [CastTypes.Number, CastTypes.Undefined],
    month_profit_loss: [CastTypes.String, CastTypes.Undefined],
    week_profit_loss: [CastTypes.String, CastTypes.Undefined],
    prev_day_profit_loss: [CastTypes.String, CastTypes.Undefined],
    prev_month_profit_loss: [CastTypes.String, CastTypes.Undefined],
    prev_week_profit_loss: [CastTypes.String, CastTypes.Undefined],
    marginLevel: [CastTypes.Null, Decimal],
    pl: [CastTypes.Null, Decimal],
    options: [CastTypes.Null, ReportOptions],
  };

  public get marginLevel(): Decimal {
    return this._marginLevel ?? new Decimal();
  }

  public set marginLevel(marginLevel: Decimal) {
    this._marginLevel = marginLevel;
  }

  public get pl(): Decimal {
    return this._pl ?? new Decimal();
  }

  public set pl(pl: Decimal) {
    this._pl = pl;
  }

  public get freeFunds(): Decimal {
    return this._freeFunds ? this._freeFunds : new Decimal();
  }

  public set freeFunds(value: Decimal) {
    this._freeFunds = value;
  }

  public get hold(): Decimal {
    return this._hold ? this._hold : new Decimal();
  }

  public set hold(value: Decimal) {
    this._hold = value;
  }

  public get marginFree(): Decimal {
    return this._marginFree ? this._marginFree : new Decimal();
  }

  public set marginFree(value: Decimal) {
    this._marginFree = value;
  }

  public get margin(): Decimal {
    return this._margin ? this._margin : new Decimal();
  }

  public set margin(value: Decimal) {
    this._margin = value;
  }

  public get equity(): Decimal {
    return this._equity ? this._equity : new Decimal();
  }

  public set equity(value: Decimal) {
    this._equity = value;
  }

  public get credit(): Decimal {
    return this._credit ? this._credit : new Decimal();
  }

  public set credit(value: Decimal) {
    this._credit = value;
  }

  public get balance(): Decimal {
    return this._balance ? this._balance : new Decimal();
  }

  public set balance(value: Decimal) {
    this._balance = value;
  }

  public static removeDuplicatedReports(reportsInChronologicalOrder: Report[]): Report[] {
    const latestReportsForAccountId: Record<string, Report> = reportsInChronologicalOrder.reduce((acc, report) => {
      if (report === null) {
        return acc;
      }
      acc[report.id] = report;
      return acc;
    }, {});
    return Object.values(latestReportsForAccountId);
  }
}
