import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {AuthCardModule} from '@app/auth/auth-card/auth-card.module';
import {CodeResendModule} from '@app/code-resend/code-resend.module';
import {FormModule} from '@app/core/form/form.module';
import {OfflineService} from '@app/ui/offline/offline.service';
import {BatchExtendService} from '@app/ui/signing-form/services/batch.extend.service';
import {SigningFormService} from '@app/ui/signing-form/services/signing-form.service';
import {WizardModule} from '@app/wizard/wizard.module';
import {TranslateModule} from '@ngx-translate/core';

import {DialogModule} from '../dialog/dialog.module';
import {SigningFormComponent} from './signing-form.component';
import {GetDocumentsComponent} from './wizards/get-documents/get-documents.component';
import {ResignProfilesComponent} from './wizards/resign-profiles/resign-profiles.component';

@NgModule({
  declarations: [SigningFormComponent, GetDocumentsComponent, ResignProfilesComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    DialogModule,
    TranslateModule,
    FormModule,
    WizardModule,
    CodeResendModule,
    AuthCardModule,
  ],
  providers: [OfflineService, BatchExtendService, SigningFormService],
  exports: [SigningFormComponent],
})
export class SigningFormModule {}
