import {IGroupedInstrumentsData} from '@app/trading-board/interfaces/b2margin/grouped-instrument-data';
import {IInstrumentData, TInstrumentDataMapType} from '@app/trading-board/interfaces/b2margin/instrument-data';
import {Position} from '@app/trading-board/models/b2margin/position';
import {B2MarginInstrument} from '@app/trading-board/models/instrument';
import {LazyDecimalHelper} from '@app/trading-board/models/lazy-decimal/lazy-decimal-helper';
import {Tick} from '@app/trading-board/models/level1';

import {GroupsConfiguration} from '../../consts/instrument-groups-configuration';

const zeroValue = (): LazyDecimalHelper => LazyDecimalHelper.from(0);

function calculatePlChange(
  value: LazyDecimalHelper,
  initialValue: LazyDecimalHelper,
  isNegative: boolean,
): LazyDecimalHelper {
  let change = value.divide(initialValue).minus(1).multiply(100);
  if (isNegative) {
    change = change.negated();
  }
  return change;
}

export function calculateInstrumentData(
  positions: Position[],
  instrument: B2MarginInstrument,
  tick: Tick,
  accountCurrencyRate: number,
): IInstrumentData {
  // Calculate simple values with one loop
  const {commission, swap, lots, initialInstrumentValue, unrealizedPL} = positions.reduce(
    (acc, position) => {
      if (position.totalCommissions) {
        acc.commission = acc.commission.plus(position.totalCommissions);
      }

      if (position.totalFinancing?.isNotNaN()) {
        acc.swap = acc.swap.plus(position.totalFinancing);
      }

      acc.unrealizedPL = acc.unrealizedPL.plus(position.plOpen);
      acc.lots = position.isBuy() ? acc.lots.plus(position.size) : acc.lots.minus(position.size);
      acc.initialInstrumentValue = acc.initialInstrumentValue.plus(position.fillPrice.multiply(position.quantity));

      return acc;
    },
    {
      commission: zeroValue(),
      swap: zeroValue(),
      lots: zeroValue(),
      initialInstrumentValue: zeroValue(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      unrealizedPL: zeroValue(),
    },
  );

  const isBuy = lots.greaterThanZero();

  const currentPrice = isBuy ? tick.bestBid : tick.bestOffer;
  const isPriceIncrease = isBuy ? tick.isBidIncrease : tick.isOfferIncrease;

  const sumQuantity = positions.reduce(
    (acc, position) => (position.isBuy() ? acc.plus(position.quantity) : acc.minus(position.quantity)),
    zeroValue(),
  );
  const instrumentValue = currentPrice.multiply(sumQuantity);
  const value = instrumentValue.multiply(accountCurrencyRate);
  const netPL = unrealizedPL.minus(swap).minus(commission);

  return {
    // Common info
    group: instrument.subtype.split('/')[0],
    symbol: instrument.symbolWithSeparator,
    instrument,
    positions,
    // Calculations
    currentPrice,
    lots,
    value,
    initialInstrumentValue,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unrealizedPL,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    instrumentPLChange: calculatePlChange(instrumentValue, initialInstrumentValue, !isBuy),
    commission,
    swap,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    netPL,
    isBuy,
    isPriceIncrease,
    accountCurrencyRate,
  };
}

export function calculateAssetData(groupName: string, items: IInstrumentData[]): IGroupedInstrumentsData {
  // Calculate simple values with one loop
  const {value, initialValue, unrealizedPL, netPL} = items.reduce(
    (acc, item) => {
      acc.value = acc.value.plus(item.value);
      acc.unrealizedPL = acc.unrealizedPL.plus(item.unrealizedPL);
      acc.netPL = acc.netPL.plus(item.netPL);
      acc.initialValue = acc.initialValue.plus(item.initialInstrumentValue.multiply(item.accountCurrencyRate));

      return acc;
    },
    {
      value: zeroValue(),
      initialValue: zeroValue(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      unrealizedPL: zeroValue(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      netPL: zeroValue(),
    },
  );

  return {
    name: groupName,
    instrumentsData: items,
    // Calculated fields
    value,
    initialValue,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unrealizedPL,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    groupPLChange: calculatePlChange(value, initialValue, value.lessThanZero()),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    netPL,
  };
}

export function calculateTotalData(groups: IGroupedInstrumentsData[]): IGroupedInstrumentsData {
  // Calculate simple values with one loop
  const {value, initialValue, unrealizedPL, netPL} = groups.reduce(
    (acc, item) => {
      acc.value = acc.value.plus(item.value);
      acc.initialValue = acc.initialValue.plus(item.initialValue);
      acc.unrealizedPL = acc.unrealizedPL.plus(item.unrealizedPL);
      acc.netPL = acc.netPL.plus(item.netPL);

      return acc;
    },
    {
      value: zeroValue(),
      initialValue: zeroValue(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      unrealizedPL: zeroValue(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      netPL: zeroValue(),
    },
  );

  return {
    name: 'Total',
    instrumentsData: [],
    // Calculated fields
    value,
    initialValue,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unrealizedPL,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    groupPLChange: calculatePlChange(value, initialValue, value.lessThanZero()),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    netPL,
  };
}

export function reduceInstrumentsData(instrumentsData: IInstrumentData[]): TInstrumentDataMapType {
  return instrumentsData.reduce((acc, data) => {
    const groupName = data.group;

    if (!acc.has(groupName)) {
      acc.set(groupName, []);
    }

    acc.get(groupName).push(data);

    return acc;
  }, new Map() as TInstrumentDataMapType);
}

/**
 * Sort list of GroupedInstrumentsData by index from GroupsConfiguration.
 *
 * @param groups - GroupedInstrumentsData.
 * @returns Sorted GroupedInstrumentsData.
 */
export function sortListOfGroupedInstrumentData(groups: IGroupedInstrumentsData[]): IGroupedInstrumentsData[] {
  return groups.sort((g1, g2) => {
    const index1 = GroupsConfiguration[g1.name]?.index ?? 0;
    const index2 = GroupsConfiguration[g2.name]?.index ?? 0;
    return index1 - index2;
  });
}
