import {Injectable} from '@angular/core';
import {IExceptions} from '@app/core/contracts/i.exceptions';
import {Account} from '@app/core/models/account/account';
import {checkRight, Rights} from '@app/core/models/account/rights';
import {firstValueFrom} from 'rxjs';

import {ConnectionService} from '../connection.service';
import {mapException} from '../exceptions/client.exception';

@Injectable()
export class ExchangeService {
  constructor(private readonly connection: ConnectionService) {}

  public couples(params: {alpha: string}): Promise<IExceptions> {
    return firstValueFrom(this.connection.get('/api/v1/exchange/' + params.alpha).pipe(mapException));
  }

  public exchange(params: {
    amount: string;
    /* eslint-disable @typescript-eslint/naming-convention */
    source_id: number;
    destination_id: number;
    pretend: boolean;
    /* eslint-enable @typescript-eslint/naming-convention */
    calc: number;
  }): Promise<IExceptions> {
    return firstValueFrom(this.connection.post('/api/v1/exchange', params).pipe(mapException));
  }

  public couplesQuote(params: {alpha: string}): Promise<IExceptions> {
    return firstValueFrom(this.connection.get('/api/v1/quote/' + params.alpha).pipe(mapException));
  }

  public convert(params: {
    amount: string;
    /* eslint-disable @typescript-eslint/naming-convention */
    source_id: number;
    destination_id: number;
    pretend: boolean;
    quote_id: string;
    /* eslint-enable @typescript-eslint/naming-convention */
    calc: number;
  }): Promise<IExceptions> {
    return firstValueFrom(this.connection.post('/api/v1/convert', params).pipe(mapException));
  }
}

/**
 * Фильтрация счета по правам RIGHTS_ENABLED RIGHTS_VISIBLE RIGHTS_EXCHANGE.
 *
 * @param account - Account.
 * @returns Boolean.
 */
export function isAccountForExchange(account: Account): boolean {
  return (
    checkRight(account, Rights.RIGHTS_ENABLED | Rights.RIGHTS_VISIBLE | Rights.RIGHTS_EXCHANGE) &&
    checkRight(account.product, Rights.RIGHTS_ENABLED | Rights.RIGHTS_VISIBLE | Rights.RIGHTS_EXCHANGE)
  );
}
