import {EBaseCurrency} from '@app/trading-board/enum/moex/base-currency';
import {DecimalHelper} from '@b2broker/decimal-helper';
import {Expose, Transform, Type} from 'class-transformer';

export class SubAccountMeta implements Record<EBaseCurrency, DecimalHelper> {
  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public [EBaseCurrency.Rub]: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public [EBaseCurrency.Usd]: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public [EBaseCurrency.Eur]: DecimalHelper;

  @Transform(({value}) => DecimalHelper.from(value ?? 0))
  public [EBaseCurrency.Cny]: DecimalHelper;
}

export class PortfolioMeta {
  @Expose({name: 'portfolio_code'})
  public portfolioId: string;

  @Expose({name: 'sub_acc_code'})
  public subAccountId: string;

  @Expose({name: 'sum'})
  @Type(() => SubAccountMeta)
  public summary: SubAccountMeta;
}
