import {EB2TraderOrderTypes} from '@app/trading-board/enum/b2trader/order-types';
import {EStopOrderStatus} from '@app/trading-board/enum/b2trader/stop-order-status';
import {ESide} from '@app/trading-board/enum/side';
import {IB2TraderInstrument} from '@app/trading-board/interfaces/b2trader/instrument';
import {IStopOrder} from '@app/trading-board/interfaces/b2trader/stop-order';
import {IStopOrderModelParam} from '@app/trading-board/interfaces/b2trader/stop-order-model-param';
import {IStopOrdersRequestParams} from '@app/trading-board/interfaces/b2trader/stop-orders-request-params';
import {DecimalHelper} from '@app/trading-board/models/decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class B2traderStopOrder {
  private static readonly DEFAULT_PRECISION = 8;

  public static sortRawOrders(orders: IStopOrder[]): IStopOrder[] {
    return orders.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  public static compare(range: Partial<IStopOrdersRequestParams>, order: B2traderStopOrder): boolean {
    const isLaterThanFrom = range?.from ? range.from.getTime() < order.time.getTime() : true;
    const isEarlierThanTo = range?.to ? order.time.getTime() < range.to.getTime() : true;

    return isLaterThanFrom && isEarlierThanTo;
  }

  public static makeOrderParam(
    resource: Map<number, IStopOrder>,
    instruments: IB2TraderInstrument[],
  ): IStopOrderModelParam[] {
    return B2traderStopOrder.sortRawOrders(Array.from(resource.values())).map(rawOrder => {
      const instrument = instruments.find(({nativeSymbol}) => rawOrder.marketId === nativeSymbol);

      return {raw: rawOrder, instrument};
    });
  }

  @Expose()
  @Transform(({obj: {raw}}: {obj: IStopOrderModelParam}) => raw.orderId)
  public orderId: number;

  @Expose()
  @Transform(({obj: {instrument}}: {obj: IStopOrderModelParam}) => instrument?.symbolWithSeparator)
  public symbolWithSeparator: string;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IStopOrderModelParam}) => raw.side)
  public side: ESide;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IStopOrderModelParam}) => new Date(raw.createdAt))
  public time: Date;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IStopOrderModelParam}) => raw.orderType)
  public orderType: EB2TraderOrderTypes;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IStopOrderModelParam}) => raw.status)
  public status: EStopOrderStatus;

  @Expose()
  @Transform(({obj: {raw}}: {obj: IStopOrderModelParam}) => raw.timeInForce)
  public timeInForce: string;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IStopOrderModelParam}) =>
    DecimalHelper.from(raw.requestedAmount, instrument?.priceScale ?? B2traderStopOrder.DEFAULT_PRECISION),
  )
  public quantity: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IStopOrderModelParam}) =>
    DecimalHelper.from(raw.activationPrice, instrument?.priceScale ?? B2traderStopOrder.DEFAULT_PRECISION),
  )
  public activationPrice: DecimalHelper;

  @Expose()
  @Transform(({obj: {raw, instrument}}: {obj: IStopOrderModelParam}) =>
    DecimalHelper.from(raw.requestedPrice, instrument?.priceScale ?? B2traderStopOrder.DEFAULT_PRECISION),
  )
  public requestedPrice: DecimalHelper;

  public isBuy(): boolean {
    return this.side === ESide.Buy;
  }

  public isSell(): boolean {
    return this.side === ESide.Sell;
  }

  public isStopMarket(): boolean {
    return this.orderType === EB2TraderOrderTypes.Market;
  }

  public isStopLimit(): boolean {
    return this.orderType === EB2TraderOrderTypes.Limit;
  }

  public isWaitingForActivation(): boolean {
    return this.status === EStopOrderStatus.WaitingForActivation;
  }
}
