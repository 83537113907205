import {FormFieldType} from '@app/core/form/entities/form.field';
import {FormConfig} from '@app/core/form/entities/form.wrapper';

export function getCodeFormConfig(codeLength: number): FormConfig {
  return {
    fields: {
      code: {
        label: {
          value: 'Code',
        },
        type: FormFieldType.code,
        mask: {
          key: FormFieldType.code,
          value: codeLength.toString(),
        },
        rules: [['integer'], ['requiredLength', [codeLength.toString()]]],
      },
      action: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        hidden: true,
        initialValue: FormFieldType.code,
      },
    },
  } as FormConfig;
}
