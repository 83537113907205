import {Injectable} from '@angular/core';
import {B2TraderInstrument} from '@app/trading-board/models/instrument';

import {B2TraderDatafeedService} from '../../datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '../../event-handlers/b2trader/b2trader-event-handlers.enum';
import {IInstrument} from '../../interfaces/instrument';
import {AEventHandler} from '../event-handler.abstract';

@Injectable()
export class B2traderInstrumentsService extends AEventHandler<IInstrument[]> {
  public type = EB2TraderEventHandlersReceived.Instruments;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(instruments: B2TraderInstrument[]): void {
    this.b2traderDatafeedService.instruments$.next(instruments);
  }
}
