import {GridsterWidgetSettings} from '@app/core/models/gridster/gridster-widget-settings';
import {ESynchronizationColors} from '@app/trading-board/enum/moex/synchronization-colors';
import {Expose, plainToInstance} from 'class-transformer';
import {IsNumber, IsOptional, IsString} from 'class-validator';

export class OrderBookSettings {
  public static makeInstance(config: GridsterWidgetSettings): OrderBookSettings {
    if (!config) {
      throw new Error('Config is undefined');
    }

    const settings = plainToInstance(OrderBookSettings, config, {excludeExtraneousValues: true});

    if (!settings.groupingStep) {
      settings.groupingStep = 0;
    }

    return settings;
  }

  public static getInstance(): OrderBookSettings {
    return plainToInstance(OrderBookSettings, {groupingStep: 0}, {excludeExtraneousValues: true});
  }

  @Expose()
  @IsNumber()
  public groupingStep: number;

  @Expose()
  @IsString()
  public pair?: string;

  @Expose()
  @IsOptional()
  public color?: ESynchronizationColors;
}
