import {B2traderSignalrHttpClient} from '@app/trading-board/models/b2trader/b2trader-signalr-http-client';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  ISubscription,
} from '@microsoft/signalr';
import {BehaviorSubject, EMPTY, Observable, ReplaySubject, Subject} from 'rxjs';
import {catchError, filter, finalize, switchMap} from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class SignalRWrapper {
  private readonly connected = new BehaviorSubject(false);

  private readonly hub: HubConnection;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private calls: Record<string, ICall<any>> = {};

  public get isConnected(): boolean {
    return this.hub.state === HubConnectionState.Connected;
  }

  constructor(url: string, authHeaders?: HeadersInit) {
    const options: IHttpConnectionOptions = Object.keys(authHeaders).length
      ? {httpClient: new B2traderSignalrHttpClient(authHeaders as Record<string, string>), transport: 1}
      : {};

    this.hub = new HubConnectionBuilder().withUrl(url, options).build();
  }

  private streamValue<T>(methodName: string, ...args: unknown[]): Observable<T> {
    const methodHash = `${methodName}${args.join('!!!')}`;
    let call = this.calls[methodHash];

    if (!call) {
      const stream: ReplaySubject<T> = new ReplaySubject(1);
      call = {
        amount: 0,
        stream,
        subscription: this.hub.stream<T>(methodName, ...args).subscribe(stream),
      };
      this.calls[methodHash] = call;
    }

    call.amount++;

    return call.stream.pipe(
      finalize(() => {
        call.amount--;
        if (call.amount === 0) {
          call.subscription.dispose();
          call.stream.complete();
          delete this.calls[methodHash];
        }
      }),
    );
  }

  public stream<T>(methodName: string, ...args: unknown[]): Observable<T> {
    return this.connected.pipe(
      filter(isConnected => isConnected && this.isConnected),
      switchMap(() => this.streamValue<T>(methodName, ...args)),
      catchError(_ => {
        return EMPTY;
      }),
    );
  }

  public start = (): Promise<void> => this.hub.start().then(() => this.connected.next(true));
  public stop = (): Promise<void> => {
    this.connected.next(false);
    return this.hub.stop();
  };

  public onclose = (callback: () => void): void => {
    this.hub.onclose(callback);
  };
}

export interface ICall<T> {
  amount: number;
  stream: Subject<T>;
  subscription: ISubscription<T>;
}
