<ng-container [formGroup]="formGroup">
  <div class="text-field-wrapper">
    <ng-container
      *ngIf="!mask?.precision"
      [ngTemplateOutlet]="autocompleteData ? inputWithAutocompleteTemplate : inputTemplate"
    ></ng-container>

    <mat-form-field *ngIf="mask?.precision">
      <input
        matInput
        [tooltipIfLongLabel]="label"
        [hasReqiredIfRule]="isRequiredIf"
        [type]="field.type || 'text'"
        [readonly]="field.readonly"
        [formControl]="control"
        [required]="required"
        [appPrecision]="mask.precision"
        [appFieldAttributes]="field.attributes"
        autocomplete="off"
      />
      <mat-hint *ngIf="field.label?.hint" [align]="field.label.hint.position || 'start'">
        {{ getHintLabel() | async }}
      </mat-hint>

      <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
        {{ getError(err) | async }}
      </mat-error>

      <mat-label #label>
        {{ getLabel() | async }}
        <app-required-indicator
          [isShowIndicator]="isRequiredIf"
          [isInvalid]="isShowRequiredIndicator"
        ></app-required-indicator>
      </mat-label>
    </mat-form-field>
  </div>
</ng-container>

<!--#region Templates-->
<ng-template #inputTemplate>
  <mat-form-field>
    <input
      #defaultInput
      matInput
      [tooltipIfLongLabel]="label"
      [hasReqiredIfRule]="isRequiredIf"
      [type]="field.type || 'text'"
      [formControl]="control"
      [required]="required"
      [readonly]="field.readonly"
      [mask]="customMask?.pattern || mask?.pattern || ''"
      [validation]="false"
      [dropSpecialCharacters]="mask?.dropSpecialCharacters"
      [appFieldAttributes]="field.attributes"
      [thousandSeparator]="mask?.thousandSeparator"
      [decimalMarker]="mask?.decimalMarker"
      autocomplete="off"
    />

    <mat-hint *ngIf="field.label?.hint" [align]="field.label.hint.position || 'start'">
      {{ getHintLabel() | async }}
    </mat-hint>

    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>

    <mat-label #label>
      {{ getLabel() | async }}
      <app-required-indicator
        [isShowIndicator]="isRequiredIf"
        [isInvalid]="isShowRequiredIndicator"
      ></app-required-indicator>
    </mat-label>
  </mat-form-field>
</ng-template>

<ng-template #inputWithAutocompleteTemplate>
  <mat-form-field>
    <input
      #autocompleteInput
      matInput
      [matTooltip]="(isShowFieldTooltip$ | async) ? control.value : ''"
      [tooltipIfLongLabel]="label"
      [hasReqiredIfRule]="isRequiredIf"
      [type]="field.type || 'text'"
      [formControl]="control"
      [required]="required"
      [readonly]="field.readonly"
      [matAutocomplete]="auto"
      [appFieldAttributes]="field.attributes"
      autocomplete="off"
    />

    <div #autocompleteInputHelperBlock class="autocomplete-input-helper-block">{{ control.value }}</div>

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="getDisplayValue.bind(this)"
      (closed)="autocompleteMenuClosed()"
      (opened)="autocompleteMenuOpened()"
    >
      <mat-option
        #optionId
        *ngFor="let item of autocompleteData.data; trackBy: trackByIndex"
        [value]="item"
        [matTooltip]="isShowOptionTooltip(optionId) ? item.value : ''"
      >
        <span>{{ item.value }}</span>
      </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="field.label?.hint" [align]="field.label.hint.position || 'start'">
      {{ getHintLabel() | async }}
    </mat-hint>

    <mat-error *ngFor="let err of control.errors | keys | slice: 0:1">
      {{ getError(err) | async }}
    </mat-error>

    <mat-label #label>
      {{ getLabel() | async }}
      <app-required-indicator
        [isShowIndicator]="isRequiredIf"
        [isInvalid]="isShowRequiredIndicator"
      ></app-required-indicator>
    </mat-label>
  </mat-form-field>
</ng-template>
<!--#endregion Templates-->
