import {WarpInstrumentHelper} from '@app/pbsr/helpers/warp-instrument.helper';
import {IPortfolioPosition} from '@app/trading-board/interfaces/moex/portfolio-position';
import {IRawPortfolioPosition} from '@app/trading-board/interfaces/moex/raw-interfaces/raw-portfolio-position';
import {DecimalHelper} from '@b2broker/decimal-helper';
import {Expose, Transform} from 'class-transformer';

export class PortfolioPosition {
  public static adapt(position: IRawPortfolioPosition): IPortfolioPosition {
    const quoteSymbol = WarpInstrumentHelper.getCurrencySymbol(position.symbol) || position.symbol;

    return {
      ...position,
      quoteSymbol,
    };
  }

  @Expose()
  public symbol: string;

  @Expose()
  public brokerSymbol: string;

  @Expose()
  public exchange: string;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public avgPrice: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyUnits: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public openUnits: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public lotSize: DecimalHelper;

  @Expose()
  public shortName: string;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyT0: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyT1: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyT2: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public qtyTFuture: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyT0Batch: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyT1Batch: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyT2Batch: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public qtyTFutureBatch: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qtyBatch: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public openQtyBatch: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public qty: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public open: DecimalHelper;

  @Expose()
  @Transform(({value}) => DecimalHelper.from(value))
  public unrealisedPl: DecimalHelper;

  @Expose()
  public portfolio: string;

  @Expose()
  public isCurrency: boolean;

  @Expose()
  public quoteSymbol: string | undefined;
}
