import {Injectable} from '@angular/core';
import {B2TraderDatafeedService} from '@app/trading-board/datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '@app/trading-board/event-handlers/b2trader/b2trader-event-handlers.enum';
import {AEventHandler} from '@app/trading-board/event-handlers/event-handler.abstract';
import {ChartResponse} from '@app/trading-board/models/b2trader/chart-response';

@Injectable()
export class B2traderSubscribeChartService extends AEventHandler<ChartResponse> {
  public type = EB2TraderEventHandlersReceived.SubscribeChart;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: ChartResponse): void {
    this.b2traderDatafeedService.chartDataTopics.get(resource.symbol)?.subject.next(resource.bar);
  }
}
