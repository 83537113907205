import {Injectable} from '@angular/core';

import {B2TraderDatafeedService} from '../../datafeed/b2trader-datafeed.service';
import {EB2TraderEventHandlersReceived} from '../../event-handlers/b2trader/b2trader-event-handlers.enum';
import {Level2} from '../../models/level2';
import {AEventHandler} from '../event-handler.abstract';

@Injectable()
export class B2traderLevel2Service extends AEventHandler<Level2> {
  public type = EB2TraderEventHandlersReceived.Level2;

  constructor(private readonly b2traderDatafeedService: B2TraderDatafeedService) {
    super();
  }

  public handleMessage(resource: Level2): void {
    this.b2traderDatafeedService.level2.get(resource.symbolWithSeparator)?.stream.next(resource);
  }
}
