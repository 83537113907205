import {productTypeLabelMap} from '@app/core/models/account/const/product-type-label-map';
import {EProductGroupType} from '@app/core/models/account/enum/product-group-type';
import {EProductType} from '@app/core/models/account/enum/product-type';

export const productTypeLabels: Record<number, string> = {
  [EProductType.Personal]: productTypeLabelMap.Personal,
  [EProductType.Real]: productTypeLabelMap.Real,
  [EProductType.Demo]: productTypeLabelMap.Demo,
  [EProductType.Partner]: productTypeLabelMap.Partner,
  [EProductType.Exchange]: productTypeLabelMap.Exchange,
  [EProductType.External]: productTypeLabelMap.External,
};

export const investmentProductTypeLabels: Record<number, string> = {
  [EProductGroupType.B2CopyMaster]: productTypeLabelMap.Master,
  [EProductGroupType.B2CopyFollower]: productTypeLabelMap.Follower,
  [EProductGroupType.PammMaster]: productTypeLabelMap.Master,
  [EProductGroupType.PammFollower]: productTypeLabelMap.Follower,
  [EProductGroupType.MamMaster]: productTypeLabelMap.Master,
  [EProductGroupType.MamFollower]: productTypeLabelMap.Follower,
};
