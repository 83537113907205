import {IResultApi} from '../contracts/i.result.api';
import {LocalStorageWorker} from '../local-storage.worker';
import {Exceptions} from './exceptions';

export class UnauthorizedException extends Exceptions {
  public static readonly STATUS_CODE = 401;

  constructor(body: IResultApi) {
    super(body);
    LocalStorageWorker.clear();
  }
}
